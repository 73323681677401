import { Component, OnInit, ɵConsole } from '@angular/core';
import { Request } from '../../_service/request';
import { LanguageService } from '../../../app/config/language/index';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router'
import { ShareResource } from '../../_service/interface'
import { LocalstorageService } from '../../sys_config/'
import { AddAddressFormData } from '../../models/addAddrFormData';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicesService } from 'src/app/_service/services.service';
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [LanguageService]
})
export class CartComponent implements OnInit {
  selAddress = {};
  pending = false;
  shippmentCost: any;
  serverUrl = this.request.server_url;
  totalCartValue: number = 0;
  totalCartCount: number = 0;
  emptyCartMsg = false;
  country: any;
  countryList: any;
  country_state: any;
  state_city: any;
  city_postal: any;
  logsubmitted = false;
  ifAddressAdded = false;
  cartData: any;
  isCheckoutDisabled = true;
  isSingleAddr = false;
  showAddress = true;
  addrMsg1 = false;
  addrMsg2 = false;
  addressList = [];
  false_me = false
  address_id: any;
  tc: any;
  tid: any;
  value: any;
  selected_address: any;
  deletesuccess = false;
  address_temp: any[];
  addressModeStatus: string;
  is_dandt_checked = false;
  is_proceedEnabled = true;
  checkmsgtxt = false;
  constructor(
    public language: LanguageService,
    private formBuilder: FormBuilder,
    private request: Request,
    private service: ServicesService,
    private router: Router,
    private share: ShareResource,
    private userStorage: LocalstorageService,
    private modalService: NgbModal
  ) {


  }
  lang_dync = this.userStorage.current_language;
  addAddressForm: FormGroup;
  cart: any;
  ngOnInit(): void {
    this.userStorage.updateUserInfo('cart_value', 0);
    this.userStorage.updateUserInfo('cart_count', 0);
    // console.log(this.userStorage.buynow_product);
    if (this.userStorage.buynow_product) {
      let pid = this.userStorage.buynow_product;
      this.userStorage.buynow_product = 0;
      this.buyNow(pid);

    } else {
      this.product_available_location();
      this.getCartData();
      this.is_notdeliverd();
    }
    this.addAddressForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      wcountry: ['', [Validators.required]],
      wstate: ['', [Validators.required]],
      city: [''],
      p_code: [''],

    });

    this.initialCountry();
    this.userStorage.cart_value = this.totalCartValue;
    this.getExistingAddr();

    this.service.sendmessage("my_cart")

    // this.dummycart =this.cart
    // console.log(this.dummycart,"yttttttttttttttttttttyyyyyyyyyyyyyyyyyyyy")

  }
  location = '';



  async product_available_location() {
    this.location = ''
    var temp2 = this.request.get_structure('address_info', 'format');
    // temp.product_id = 2;
    temp2.order_status = "null";
    var get_address = await this.request.post(1, 'address_info', temp2);

    console.log(get_address);
    if (get_address[1].response.data.length > 0) {

      this.addressList = get_address[1].response.data;
      console.log(this.addressList, " ?????? ")
      for (let i of this.addressList) {
        console.log(i.is_main, "ppppp")

        if (i.is_main == true) {

          this.location = i.address_id;
          console.log(this.location, "location is available")
        }
      }

      // if(this.location ){
      //   console.log("location is not updated")
      //   this.service.Deliver_location_required("location is not updated")
      // }
    } else {

    }
    if (this.location) {
      console.log(this.location)
      var temp = this.request.get_structure('available_cart_details', 'format');
      temp.address__id = this.location;
      let addcart = await this.request.post(1, 'available_cart_details', temp);

      console.log(addcart[1].response.data.products, "llllll")

      if (addcart[1].response.data.products != []) {
        for (let i2 of addcart[1].response.data.products) {
          for (let i of this.cart) {
            if (i2.product_id == i.product_id) {
              i.warehouse_id = i2.is_supported
            }

          }
          //   if(i.product_id == addcart)
          //       i.warehouse_id = true

        }
      }
      console.log(this.cart, "fggggggggggggggggggggggggggggg")
      this.is_notdelivarable = false;
      this.checkmsgtxt = false;
      if (this.cart != null) {
        for (let i of this.cart) {
          if (i.warehouse_id == false) {
            this.is_notdelivarable = true;
            this.checkmsgtxt = true;
            console.log(this.cart, "ppppppppppppewwwwwwwwwwwwwwwwwww")
          }
        }
      }

    }

  }

  showAddrLineTwo(addrLine2) {
    if (addrLine2.length === 0)
      return false
    else return true;

  }

  async buyNow(productId) {

    var temp = this.request.get_structure('add_cart', 'format');
    temp.product_id_fs = productId;
    // temp.product_id = 2;
    temp.qty = 1;
    temp.variant_id = 1;

    var is_logged = this.userStorage.getUserInfo(this.userStorage.token);


    let addcart = await this.request.post(1, 'add_cart', temp);
    // console.log(is_logged); console.log(addcart);

    this.getCartData();

  }

  single_producte_id: any;
  pro_detail = [];
  avail_detail = [];
  product_avaiable: any



  async getCartData() {
    var temp = this.request.get_structure('my_cart', 'format');
    this.cartData = await this.request.post(1, 'my_cart', temp);
    console.log(this.cartData);
    if (this.cartData[0] == 200) {
      if (this.cartData[1].response.data.overall_count >= 1) {
        this.cart = this.cartData[1].response.data.products;
        console.log(this.cart, "LLLLLLLLLLLL");

        console.log(this.single_producte_id, "::::::")
        this.totalCartValue = this.cartData[1].response.data.overall_total;
        this.totalCartCount = this.cartData[1].response.data.overall_count;
        this.share.addToCart(this.totalCartCount, this.totalCartValue);
        this.userStorage.updateUserInfo('cart_value', this.totalCartValue);
        this.userStorage.updateUserInfo('cart_count', this.totalCartCount);
        this.row_subTotal(this.cartData[1].response.data.products);
        for (let f1 in this.cart) {
          var set_temp = ""
          var fs_tag = ""
          for (let f2 in this.cart[f1]['variants']) {
            this.value = this.cart[f1]['variants'][f2].value
            if (parseInt(f2) == (this.cart[f1]['variants']).length - 1) {
              fs_tag = ""
            } else {
              fs_tag = " , "
            }
            set_temp += this.value + fs_tag;
          }
          if (set_temp != "" && set_temp != "null") {
            this.cart[f1]['product_name'] = this.cart[f1]['product_name'] + " ( " + set_temp + " )"
          }

          // console.log("res >>", this.cart[f1]['product_name'] + " - (" + set_temp + ")")
        }


      } else if (this.cartData[1].response.data.overall_count <= 0) {
        console.log("no data")
        this.cart = null;
        this.totalCartValue = 0;
        this.emptyCartMsg = true;
        this.isCheckoutDisabled = true;
        this.addrMsg1 = false;
        this.addrMsg2 = false;
      }
    }
    else {
      this.cart = null;
      this.totalCartValue = 0;
      this.emptyCartMsg = true;
      this.isCheckoutDisabled = true;
      this.addrMsg1 = false;
      this.addrMsg2 = false;
    }

  }


  // async getCartData() {
  //   var temp = this.request.get_structure('my_cart', 'format');
  //   this.cartData = await this.request.post(1, 'my_cart', temp);
  //   console.log(this.cartData);

  //   if (this.cartData[0] == 200) {
  //     if (this.cartData[1].response.data.overall_count >= 1) {
  //       this.cart = this.cartData[1].response.data.products;
  //       console.log(this.cart, "LLLLLLLLLLLL");
  //       this.pro_detail = []
  //       for (let i of this.cart) {
  //         console.log(i.warehouse_id, "////")
  //         if (i.warehouse_id == 57) {
  //           this.pro_detail.push(i.product_id)


  //         }

  //       }
  //       console.log(this.pro_detail)
  //       console.log('product id of italy warehouse')
  //       if (this.pro_detail) {

  //         var temp2 = this.request.get_structure('address_info', 'format');
  //         // temp.product_id = 2;
  //         temp2.order_status = "null";
  //         var get_address = await this.request.post(1, 'address_info', temp2);

  //         console.log(get_address);
  //         this.location=''
  //         if (get_address[1].response.data.length > 0) {

  //           this.addressList = get_address[1].response.data;
  //           console.log(this.addressList, " ?????? ")

  //           for (let i of this.addressList) {

  //             console.log(i.is_main, "ppppp")

  //             if (i.is_main == true) {

  //               this.location = i.address_id;
  //               console.log(this.location, "location is available")
  //             }
  //           }

  //           // if(this.location ){
  //           //   console.log("location is not updated")
  //           //   this.service.Deliver_location_required("location is not updated")
  //           // }
  //         } else {

  //         }
  //         if(this.location){
  //           this.avail_detail =[]
  //         var temp = this.request.get_structure('available_cart_details', 'format');
  //         temp.address__id = this.location;
  //         let addcart = await this.request.post(1, 'available_cart_details', temp);
  //         console.log(addcart[1].response.data.products.length > 0,"???????")
  //        if(addcart[1].response.data.products.length > 0){
  //         for (let id of addcart[1].response.data.products) {

  //           this.avail_detail.push(id.product_id)
  //         }
  //         console.log(this.avail_detail,";;;;")

  //         console.log('product id of available product for the location')

  //         if(this.avail_detail.length >= 0 ){
  0.

  //         for (let pr_id of this.avail_detail) {
  //           console.log("----")
  //           if (this.pro_detail.includes(pr_id)) {
  //             console.log('the product is availabe for the location')
  //             this.product_avaiable = true;
  //           }
  //           else {
  //             console.log('this product is not availabe to the location')
  //             this.product_avaiable = false;

  //           }
  //         }
  //       }else{

  //           await console.log('this product is not availabe to the location')
  //             this.product_avaiable = false;
  //       }

  //       }else{
  //         await console.log('this product is not availabe to the location')
  //         this.product_avaiable = false;
  //       }



  //       }


  //       }









  //       console.log(this.single_producte_id, "::::::")
  //       this.totalCartValue = this.cartData[1].response.data.overall_total;
  //       this.totalCartCount = this.cartData[1].response.data.overall_count;
  //       this.share.addToCart(this.totalCartCount, this.totalCartValue);
  //       this.userStorage.updateUserInfo('cart_value', this.totalCartValue);
  //       this.userStorage.updateUserInfo('cart_count', this.totalCartCount);
  //       this.row_subTotal(this.cartData[1].response.data.products);
  //       for (let f1 in this.cart) {
  //         var set_temp = ""
  //         var fs_tag = ""
  //         for (let f2 in this.cart[f1]['variants']) {
  //           this.value = this.cart[f1]['variants'][f2].value
  //           if (parseInt(f2) == (this.cart[f1]['variants']).length - 1) {
  //             fs_tag = ""
  //           } else {
  //             fs_tag = " , "
  //           }
  //           set_temp += this.value + fs_tag;
  //         }
  //         if (set_temp != "" && set_temp != "null") {
  //           this.cart[f1]['product_name'] = this.cart[f1]['product_name'] + " ( " + set_temp + " )"
  //         }

  //         // console.log("res >>", this.cart[f1]['product_name'] + " - (" + set_temp + ")")
  //       }


  //     } else if (this.cartData[1].response.data.overall_count <= 0) {
  //       console.log("no data")
  //       this.cart = null;
  //       this.totalCartValue = 0;
  //       this.emptyCartMsg = true;
  //       this.isCheckoutDisabled = true;
  //       this.addrMsg1 = false;
  //       this.addrMsg2 = false;
  //     }
  //   }
  //   else {
  //     this.cart = null;
  //     this.totalCartValue = 0;
  //     this.emptyCartMsg = true;
  //     this.isCheckoutDisabled = true;
  //     this.addrMsg1 = false;
  //     this.addrMsg2 = false;
  //   }

  // }

  row_subTotal(product_data) {


    product_data.forEach((value, index) => {

      product_data[index].row_total = product_data[index].qty * (product_data[index].new_price ? product_data[index].new_price : product_data[index].price);
      product_data[index].row_total = product_data[index].row_total.toFixed(2);

    });
    
    this.cart = product_data;

  }



  addProd(i) {


    setTimeout(() => {
      this.cart[i].qty += 1;
      this.cart[i].row_total = this.cart[i].new_price * this.cart[i].qty;
      this.totalCartValue = this.totalCartValue + this.cart[i].new_price;
      this.totalCartValue = parseFloat(this.totalCartValue.toFixed(2));
      this.cart[i].row_total = this.cart[i].row_total.toFixed(2);
      this.totalCartCount += 1;
      this.userStorage.updateUserInfo('cart_value', this.totalCartValue);
      this.userStorage.updateUserInfo('cart_count', this.totalCartCount);
      this.share.addToCart(this.totalCartCount, this.totalCartValue);
      this.cart_update(this.cart[i].product_id, this.cart[i].qty, this.cart[i].variant_id);
    }, 500);
  }
  deleteProd(i: any) {
    if (this.cart[i].qty >= 1) {
      this.cart[i].qty -= 1;
      this.cart[i].row_total = (this.cart[i].new_price * this.cart[i].qty).toFixed(2);
      this.totalCartCount -= 1;
      this.totalCartValue -= this.cart[i].new_price;
      this.totalCartValue = parseFloat(this.totalCartValue.toFixed(2));


      setTimeout(() => {
        this.cart_update(this.cart[i].product_id, this.cart[i].qty, this.cart[i].variant_id);
        this.userStorage.updateUserInfo('cart_value', this.totalCartValue);
        this.userStorage.updateUserInfo('cart_count', this.totalCartCount);
        this.share.addToCart(this.totalCartCount, this.totalCartValue);
        this.cart = this.cart.filter(data => {
          if (data.qty !== 0) { return data }
        })
        if (this.cart.length == 0) {
          this.emptyCartMsg = true;
        }
      }, 500);
    }
  }

  async bulkDelete(data: any) {
    console.log(data);
    let quantity = 0
    await this.cart_update(data.product_id, quantity, data.variant_id)
    // setTimeout(()=>{
    //   window.location.reload();
    // }, 500)
    setTimeout(() => {
      window.location.reload();
    }, 10)
    localStorage.setItem("clear_data_tag", '1');
  }

  cartTotalInFloat() {
    return Number(this.totalCartValue).toFixed(2)

  }

  async cart_update(pid: any, qnty: any, varient: any) {
    console.log("called");
    var temp = this.request.get_structure('cart_option', 'format');
    temp.product_id_fs = pid;
    // temp.product_id = 2;
    temp.qty = qnty;
    temp.variant_id = varient;
    var new_cart = await this.request.post(1, 'cart_option', temp);
    console.log(new_cart);
    this.product_available_location();
  }


  async proceedCheckOut() {
    this.pending = true;
    this.userStorage.updateUserInfo('dId', this.selAddress);
    this.share.temp1 = this.totalCartValue;
    this.userStorage.removeUserInfo('cart_value');
    this.userStorage.removeUserInfo('cart_count');
    this.userStorage.removeUserInfo('buycart_count');
    this.userStorage.removeUserInfo('buycart_value');
    console.log("checkout");
    if (this.cart.length > 0) {

    } else {
      return;
    }
    var temp = this.request.get_structure('buyprocess', 'format');
    temp.overall_total = this.totalCartValue;
    temp.address_id = this.userStorage.getUserInfo('dId');
    var checkout = await this.request.post(1, 'buyprocess', temp);
    console.log(checkout, temp);
    if (checkout[0] == 200) {
      this.tid = checkout[1].response.data.order_id;
      this.tc = checkout[1].response.data.sub_total
      console.log(this.tid);
      console.log(this.tc);
      this.userStorage.updateUserInfo('cart_count', 0);
      this.userStorage.updateUserInfo('cart_value', 0);
      localStorage.setItem('cc', 'true');
      this.userStorage.updateUserInfo('tc', this.tc);
      this.userStorage.updateUserInfo('tid', this.tid);
      console.log("pass_0-->", checkout[1].response.data.shipment_cost)
      this.userStorage.updateUserInfo('sc', checkout[1].response.data.shipment_cost);
      this.router.navigate(['/payment']);
      localStorage.setItem('dbuy_tag', 'false')
      this.pending = false;
    }
  }



  async initialCountry() {
    var temp = this.request.get_structure('country_api', 'format');
    // temp.product_id = 4;
    this.country = await this.request.post(1, 'country_api', temp);
    if (this.country[0] == 200) {
      this.countryList = this.country[1].response.data;
      this.countryList.sort(function (a, b) {
        return a.country_name.localeCompare(b.country_name);
      });
    }

    // console.log(this.country[1].response.data);
  }
  async modelChanged(e) {
    if (e.target.value) {
      var temp = this.request.get_structure('country_api', 'format');
      temp.option = 3;
      temp.find = e.target.value;
      this.country_state = await this.request.post(1, 'country_api', temp);
      console.log(this.countryList);
      if (this.country_state[0] == 200) {
        console.log(this.country_state);
        this.country_state = this.country_state[1].response.data;
        console.log(this.country_state);
        this.country_state.sort(function (a, b) {
          return a.regions.localeCompare(b.regions);
        });
      } else {
        this.country_state = [0]
      }
      this.state_city = [];
      this.city_postal = [];
    }
  }
  async modelCity(e) {
    if (e.target.value) {
      var temp = this.request.get_structure('country_api', 'format');
      temp.option = 4;
      temp.find = e.target.value;
      this.state_city = await this.request.post(1, 'country_api', temp);
      if (this.state_city[0] == 200) {
        this.state_city = this.state_city[1].response.data;
        this.state_city.sort(function (a, b) {
          return a.city.localeCompare(b.city);
        });
      }
      this.city_postal = [];
    }
  }
  async modelPin(e) {
    if (e.target.value) {
      var temp = this.request.get_structure('country_api', 'format');
      temp.option = 5;
      temp.find = e.target.value;
      this.city_postal = await this.request.post(1, 'country_api', temp);
      console.log(this.city_postal);
      if (this.city_postal[0] == 200) {
        this.city_postal = this.city_postal[1].response.data;
        this.city_postal.sort(function (a, b) {
          return a.postal_code.localeCompare(b.postal_code);
        });
      }
    }
  }

  onAddAddress() {
    this.ifAddressAdded = !this.ifAddressAdded;
  }

  get ct() { return this.addAddressForm.controls }

  onAddaddressSubmit() {
    this.logsubmitted = true;
    if (this.addAddressForm.invalid) {
      console.log("invalid");
      this.logsubmitted = true;
      return
    }
    console.log(this.addAddressForm.value)
  }

  async receiveAddress(addr: AddAddressFormData) {
    console.log(addr);
    var temp = this.request.get_structure('address_add', 'format');
    temp.address_name = addr.name;
    temp.address1 = addr.addr1;
    temp.address2 = addr.addr2;
    temp.country = addr.country;
    temp.region = addr.state;
    temp.city = addr.city;
    temp.phone_no = parseInt(addr.mobile);
    temp.postal_code = addr.zip;
    temp.delete = false;

    var add_address = await this.request.post(1, 'address_add', temp);
    console.log(temp);
    console.log(add_address);
    if (add_address[0] == 200) {
      this.ifAddressAdded = false;
      // this.handleAddrChange();
      this.getExistingAddr();
    }
  }

  async getExistingAddr() {
    var temp = this.request.get_structure('address_info', 'format');
    // temp.product_id = 2;
    temp.order_status = "null";
    this.isSingleAddr = false;
    var get_address = await this.request.post(1, 'address_info', temp);
    console.log(get_address);
    if (get_address[0] === 200) {
      if (get_address[1].response.data.length >= 1) {
        this.showAddress = true;
        if (get_address[1].response.data.length == 1) {
          this.isSingleAddr = false
          // console.log('pass1 ');
        } else if (get_address[1].response.data.length > 1) {
          this.isSingleAddr = false
          // console.log('pass2 ');
        }
        if (this.totalCartCount >= 1) {
          this.addrMsg1 = false;
          this.addrMsg2 = true;
        } else {
          this.addrMsg2 = false;
          this.addrMsg1 = false;
        }
        this.addressList = get_address[1].response.data;
        this.addressList.forEach((element, index) => {
          if (element.is_main == true) {
            this.isCheckoutDisabled = true;
            this.is_dandt_checked = false
          }
        });

      } else {
        this.addressList = [];
        this.addrMsg2 = false;
        this.addrMsg1 = true;
        this.isCheckoutDisabled = true;
        this.is_dandt_checked = true;
      }
    }
    if (get_address[1]['response']['data'].length !== 0) {
      // console.log(get_address[1]);
      this.address_temp = get_address[1]['response']['data']
      // console.log(this.address_temp);
      // this.addressModeStatus = this.address_temp[0].address_id;
      var add_id = this.addressList;
      for (let i of add_id) {

        if (i.is_main == true) {
          console.log(i.address_id, "MMMMMMMmmm")
          this.product_available_location()
          this.addressModeStatus = i.address_id;
        }
      }



      // console.log(this.addressModeStatus);
      if (this.addressModeStatus == undefined) {
        this.isCheckoutDisabled = true;
      } else {
        this.isCheckoutDisabled = false;
        this.selAddress = this.addressModeStatus
      }
    } else if (get_address[1]['response']['data'].length == 0) {
      this.address_temp = []
    }
    // UPDATE SOON...!
    // for (let f1 in this.address_temp) {
    //   if (this.address_temp[f1].is_main == true) {
    //   this.addressModeStatus = this.address_temp[f1].address_id;
    //   }
    // }


  }

  find_address(id) {
    for (var f1 in this.address_temp) {
      if (this.address_temp[f1]['address_id'] == id) {
        return this.address_temp[f1]
      }
    }
  }



  async handleAddrChange(id, loca) {
    console.log(loca)
    this.location = id
    var value = this.find_address(id)
    this.selAddress = id;
    this.service.your_location_city(loca.city)
    let temp = this.request.get_structure('address_default', 'format');
    // console.log(value);
    // temp.product_id = 2;
    temp.address_id = parseInt(value.address_id);
    let get_address = await this.request.post(1, 'address_default', temp);
    // console.log(get_address, temp);
    if (get_address[0] == 200) {
      // this.is_dandt_checked = false
      // this.is_proceedEnabled=true
    }
    this.isCheckoutDisabled = false;

    this.getExistingAddr();
    this.getCartData();
    this.product_available_location();
    this.is_proceedEnabled = true
    
    // this.is_dandt_checked = true;
    // this.getShippmentCost(value);
  }

  async getShippmentCost(value) {
    // console.log(value);
    let temp = this.request.get_structure('check_shipment_cost', 'format');
    temp.country = value.country;
    temp.postcode = value.postal_code;
    temp.product_id_fs = 0;
    temp.variant_id = 0;
    temp.qty = this.totalCartCount;
    let shippmentCost = await this.request.post(1, 'check_shipment_cost', temp);
    console.log(shippmentCost);
    if (shippmentCost[0] == 200) {
      this.shippmentCost = shippmentCost[1].response.data.price;
      console.log(this.shippmentCost);
      this.userStorage.updateUserInfo('sc', this.shippmentCost);
    } else if (shippmentCost[0] == 400) {
      console.log("shippmentCostError");
    }
  }

  conformdeleteAddress(address, conformdelete) {
    this.modalService.open(conformdelete, { keyboard: false, backdrop: 'static', size: 'sm' })
    this.selected_address = address


  }

  async deleteAddress(conformdelete) {
    var address = this.find_address(this.selected_address)
    console.log(address);
    let temp = this.request.get_structure('delete_address', 'format');
    // temp.product_id = 2;
    temp.address_name = address.address_name;
    temp.phone_no = parseInt(address.address_phone);
    temp.address_id = address.address_id;
    temp.address1 = address.address_line1;
    temp.address2 = address.address_line2;
    temp.country = address.country;
    temp.region = address.address_line1;
    temp.city = address.city;
    temp.postal_code = address.postal_code;
    temp.delete = true;
    console.log(temp);
    let get_address = await this.request.post(1, 'delete_address', temp);
    console.log(get_address);
    if (get_address[0] == 200) {
      this.deletesuccess = true
      setTimeout(() => {
        this.deletesuccess = false;
        this.modalService.dismissAll(conformdelete)
      }, 2000);
    }
    this.getExistingAddr();
    this.getCartData();
    this.isCheckoutDisabled = true;
  }

  ngOnDestroy() {
    this.service.sendmessage('')
  }
  open(tandcpayment) {
    this.modalService.open(tandcpayment, { ariaLabelledBy: 'modal-basic-title' })
  }

  not_deliveriable = false;
  dandt(event) {

    if (event.target.checked == true) {
      if (this.isCheckoutDisabled == false)

        //  if(this.product_avaiable ) {
        this.is_proceedEnabled = false
      this.is_dandt_checked = true;
      //  }else{
      //   this.not_deliveriable = true
      //   setTimeout(() => {
      //     this.not_deliveriable = false
      //   }, 3000);
      //  }


    } else if (event.target.checked == false) {
      this.is_dandt_checked = false;
      this.is_proceedEnabled = true;
    }
  }
  is_notdelivarable = true;
  dummycart: any;
  orginalcart: any;
  async is_notdeliverd() {
    //   this.dummycart =this.cartData[1].response.data.products
    //   console.log(this.dummycart,"yttttttttttttttttttttyyyyyyyyyyyyyyyyyyyy")
    //    console.log(this.cart,"wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww")
    //   if (this.cart[0].warehouse_id == true)
    //  this.is_notdelivarable = false;
    var temp = this.request.get_structure('my_cart', 'format');
    this.dummycart = await this.request.post(1, 'my_cart', temp);
    // console.log(this.dummycart);

    // var temp = this.request.get_structure('my_cart', 'format');
    // this.dummycart = await this.request.post(1, 'my_cart', temp);
    console.log(this.dummycart, "eeeeeeeeeuuuuuuuuuuuuuuuuuuuuuu");
    this.orginalcart = this.dummycart[1].response.data.products
    console.log(this.orginalcart, "welcomeeeeeeeeeeeeeee");
    if (this.orginalcart.warehouse_id == true) {
      this.is_notdelivarable = false;
      console.log(this.orginalcart.warehouse_id, "ppppppppppppewwwwwwwwwwwwwwwwwww")
    }
  }


}
