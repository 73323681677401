import { Component, OnInit, ViewChild } from '@angular/core';
import { LanguageService } from "../config/language/index";
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonsComponent } from '../layouts/inputs/inputs.component'
// import { LoginService } from '../backend/login.service';
import { MustMatch } from '../_helpers/must-match.validator';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Request } from '../_service/request'
import { APIs_Config,LocalstorageService } from '../sys_config';
@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss'],
  providers: [LanguageService, APIs_Config]
})
export class MenubarComponent implements OnInit {
  constructor(private language: LanguageService,
    // private logservice: LoginService,
    private formBuilder: FormBuilder,
    private comps: CommonsComponent,
    private sanitizer: DomSanitizer,
    private router: Router,
    private request: Request,
    private api_config: APIs_Config,
    private userStorage: LocalstorageService,

  ) { }

  lang_dync = this.userStorage.current_language;
  islogin = true;
  isSignup = false;
  signup_data: any;
  login_data: any;
  logindata: any;
  signupdata: any;
  jp: any;
  logoutdata: any;
  logoutload: any;
  loginForm: FormGroup;
  registerForm: FormGroup;
  submitted = false;
  logsubmitted = false;
  error_login: any;
  button_check: any;
  html: SafeHtml;
  isUserLogged = false;
  jQuery: any;
  signup_success: any;
  signup_fail: any;

  @ViewChild('closebutton') closebutton;

  ngOnInit() {
    this.registerForm = this.formBuilder.group({

      firstName: ['', Validators.required],

      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.pattern('^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$')]],
      confirmPassword: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    this.loginForm = this.formBuilder.group({

      lemail: ['', [Validators.required]],
      lpassword: ['', [Validators.required, Validators.pattern('^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$')]],
    });

    if (localStorage.getItem("can01")) {
      this.isUserLogged = true;
    }



    this.button_check = this.comps.button_call('Test', 'button', 'btn btn-primary', 'onSubmit()');
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.button_check);


  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  get lo() { return this.loginForm.controls; }

  async onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      console.log('this.registerForm');
      return;
    }
    else {
      this.login_data = {
        "payload": {
          "sponsor": 0,                  	//default 0
          "account_type": 1,             	//default 1
          "username": this.registerForm.value.firstName,
          "password": this.registerForm.value.password,
          "email": this.registerForm.value.email,
          "country": "null",
          "product_id": 2,
          "company_name": "null",        	//default "null"
          "user_type": 4
        }
      }

      // this.signupdata = await this.logservice.get_api(this.login_data, 'bov3002/88314');



      if (this.signupdata.error) {
        var err = this.signupdata.error;
        console.log(err);
        if (err.response.error == 'user_name_exists') {
          this.signup_fail = this.language.mLparams[this.lang_dync].user_name_exists;
        }
        else {
          this.signup_fail = 'Err! Try Again';
        }

      }
      else if (this.signupdata.response) {
        this.signup_success = this.language.mLparams[this.lang_dync].signup_success;
        this.changelogin();
        this.registerForm.reset();


      }
    }



    // if (th.data.status == 'Signup_Success') {
    //   console.log('Signup_Succesccccccccccs');
    //   this.signup_success = this.language.mLparams[this.lang_dync].signup_success;
    //   console.log(this.signup_success);

    // }
    // else if (th.error) {
    //   console.log(th.error);
    //   this.signup_fail = this.language.mLparams[this.lang_dync].signup_fail;
    // }
    // else {

    // }

  }

  onReset() {
    this.submitted = false;
    this.submitted = true;

    this.registerForm.reset();
  }
  onSignupSubmit(data1) {
    console.log(data1)
  }

  changelogin() {
    this.islogin = !this.islogin;
    this.isSignup = !this.isSignup;
  }

  changesignup() {
    this.islogin = !this.islogin;
    this.isSignup = !this.isSignup;
  }
  async onLoginSubmit() {

    this.logsubmitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {

      console.log('no login');
      return;
    }
    else {


      this.login_data = {
        "payload": {
          "username": this.loginForm.value.lemail,
          "password": this.loginForm.value.lpassword,
          "product_id": 2
        }
      }
      // this.logindata = await this.logservice.get_api(this.login_data, 'bov3001/98310');

      var temp = this.request.get_structure('login_2', 'format');
      temp.username = this.loginForm.value.lemail;
      temp.password = this.loginForm.value.lpassword;
      this.logindata = await this.request.post(1, 'login_2', temp);
 


      if (this.logindata.error) {
        if (this.logindata.error.response.error == "wrong_combination") {
          this.error_login = this.language.mLparams[this.lang_dync].invalid_credentials;
        }
      }

      else {

        localStorage.setItem("can01", this.logindata[1].response.data.token);
        localStorage.setItem("can02", this.logindata[1].response.data.username);
        // localStorage.setItem("can03", this.logindata[1].response.data.username);   //product id
        this.isUserLogged = true;
        this.loginForm.reset();
        // jQuery('#loginModal').modal('hide');

      }

      // this.jp = JSON.stringify(this.logindata);

      // console.log(this.jp);
      // console.log(this.logindata.response.data.username);
    }
  }


  public closeModel() {
    this.closebutton.nativeElement.click();
  }


  public onForget() {

    document.getElementById("openModalButton").click();

  }



  async logout() {

    this.logoutload = {
      "payload": {
        "product_id": 2
      }
    }

    var temp = this.request.get_structure('logout', 'format');
    // temp.product_id = 2;
    if (this.isUserLogged) {
      // this.logoutdata = await this.logservice.get_api(this.logoutload, 'bov3005/58310');

      this.logoutdata = await this.request.post(1, 'logout', temp);
      console.log(this.logoutdata);
      if (this.logoutdata[1].response.data == true) {
        localStorage.removeItem("can01");
        localStorage.removeItem("can02");
        this.isUserLogged = false;
      }
    }
  }

  forgetPassword() {
    console.log('aaa');
    this.router.navigate(['/resetpass']);
    (<HTMLInputElement>document.getElementById('loginModal')).style.display = "none";
    //  (<HTMLInputElement>document.getElementsByClassName('modal-backdrop')).classList.remove("show");


  }

}

