<div class="container loginpg shadow-lg p-3 mb-5 bg-white rounded ">
    <div *ngIf="islogin; else elseBlock">
        <h5 class="modal-title" style="text-align: center;" id="exampleModalLabel">{{this.language.mLparams[this.lang_dync].login}}</h5>
    </div>
    <ng-template #elseBlock>
        <h5 class="modal-title" style="text-align: center;" id="exampleModalLabel">
            {{this.language.mLparams[this.lang_dync].signup}}
        </h5>
    </ng-template>

    <div class="container p-2">
        <div *ngIf="islogin">
            <div *ngIf="signup_success">
                <p class="alert alert-success">{{signup_success}}</p>
            </div>
            <!--  #loginForm="ngForm"  -->
            <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                <div class="form-group">
                    <div *ngIf="error_login">
                        <p class="alert alert-danger">{{ error_login }}</p>
                    </div>
                </div>

                
                <button type="button" (click)="bouser()" class="btn btn-default bologin"><img
                        src="{{baseUrl}}/assets/ducatus_icon.png" height="40px" width="40px"> Login With
                    {{account_name}}
                    account</button>
             
                <div>
                    <p class="ortxt">OR</p>
                </div>
                <!-- <div class="col-md-5 mt-2"> <hr></div> -->
                <!-- </div> -->
                <div class="form-group">
                    <label class="text-center" *ngIf="!boUser">{{
            this.language.mLparams[this.lang_dync].userName
          }}</label>
                    <label class="text-center" *ngIf="boUser">{{
            this.language.mLparams[this.lang_dync].boUserName
          }}</label>
                    <input type="text" formControlName="lemail" class="form-control loginFormInput" [ngClass]="{ 'is-invalid': logsubmitted && lo.lemail.errors }" autofocus />
                    <div *ngIf="logsubmitted && lo.lemail.errors" class="invalid-feedback">
                        <div *ngIf="lo.lemail.errors.required">
                            {{this.language.mLparams[this.lang_dync].requiredfield}}
                        </div>
                        <div *ngIf="lo.lemail.errors.email || lo.lemail.errors.pattern">
                            {{this.language.mLparams[this.lang_dync].validemail}}
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label *ngIf="!boUser">{{
              this.language.mLparams[this.lang_dync].password
            }}</label>
                        <label *ngIf="boUser">{{
              this.language.mLparams[this.lang_dync].bopassword
            }}</label>
                        <input type="password" formControlName="lpassword" class="form-control loginFormInput" [ngClass]="{
                'is-invalid': logsubmitted && lo.lpassword.errors
              }" />
                        <div *ngIf="logsubmitted && lo.lpassword.errors" class="invalid-feedback">
                            <div *ngIf="lo.lpassword.errors.required">
                                {{ this.language.mLparams[this.lang_dync].pass_required }}
                            </div>
                            <div *ngIf="lo.lpassword.errors.minlength">
                                {{ this.language.mLparams[this.lang_dync].pass_length }}
                            </div>
                            <!-- <div *ngIf="lo.lpassword.errors.pattern">
                                {{ this.language.mLparams[this.lang_dync] .password_struct }}
                            </div> -->
                        </div>
                        <!-- <p class="text-muted display-5 text-left mt-2 mb-1 paragraph">{{ this.language.mLparams[this.lang_dync] .password_structure }}
                        </p> -->
                    </div>
                </div>

                <div class="text-center">
                    <!-- the foll button needs to be commented out -->
                    <!-- <button
            type="button"
            class="btn btn-primary float-left"
            (click)="changesignup()"
          >
            sign up
          </button> -->
                    <!-- <button *ngIf="isUserLogged" type="button" class="btn btn-danger float-left" (click)="logout()">
            logout
          </button> -->
                    <button  *ngIf="!boLogin"  type="submit" class="btn loginregbtn float-center pr-5 pl-5 mt-3">
                        {{this.language.mLparams[this.lang_dync].login}}
                    </button>
                    <button *ngIf="boLogin" type="submit" class="btn loginregbtn float-center pr-5 pl-5 mt-3">
                        {{ this.language.mLparams[this.lang_dync] .login }}
    </button>
                </div>
            </form>
            <div class="text-center">
                <span class="d-block mt-4">{{this.language.mLparams[this.lang_dync].notamemb}}
                    <a class="txt_mode_2" style="cursor: pointer;" (click)="changesignup()">{{this.language.mLparams[this.lang_dync].crtacc}}</a>
                    <!-- <a href="#" class="txt_mode_2" (click)="changesignup()">Create an account</a> -->
                </span>

                <span class="d-block mt-3">
                    <button class="btn txt_mode_3" (click)="forgetPassword()">{{this.language.mLparams[this.lang_dync].frgtpwd}}</button>
                </span>
            </div>
        </div>

        <div *ngIf="isSignup">
            <div class="form-group">
                <div *ngIf="signup_success">
                    <p class="alert alert-success">{{signup_success}}</p>
                </div>
                <div *ngIf="signup_fail">
                    <p class="alert alert-danger">{{signup_fail}}</p>
                </div>
            </div>
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                <div class="form-row">
                    <div class="form-group col">
                        <label>{{this.language.mLparams[this.lang_dync].userName}}</label>
                        <input autofocus type="text" formControlName="firstName" class="form-control loginFormInput" [ngClass]="{
                'is-invalid': submitted && f.firstName.errors
              }" (blur)="checkUserName()" (keyup)=reg_change() />
                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">
                                {{this.language.mLparams[this.lang_dync].requiredfield}}
                            </div>
                            <div *ngIf="f.firstName.errors.pattern">
                                {{this.language.mLparams[this.lang_dync].usernamepattern}}
                            </div>
                        </div>
                        <p class="text-muted display-5 mt-2 mb-1 paragraph text-left">
                            {{this.language.mLparams[this.lang_dync].Username_field}}</p>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{this.language.mLparams[this.lang_dync].eml}}</label>
                    <input type="text" formControlName="email" class="form-control loginFormInput" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (blur)="checkUserMail()" (keyup)=reg_change() />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required"> {{this.language.mLparams[this.lang_dync].requiredfield}}
                        </div>
                        <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                            {{this.language.mLparams[this.lang_dync].validemail}}
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label>{{this.language.mLparams[this.lang_dync].pswd}}</label>
                        <input type="password" formControlName="password" class="form-control loginFormInput" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" (keyup)=reg_change() />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">
                                {{this.language.mLparams[this.lang_dync].pass_required}}
                            </div>
                            <div *ngIf="f.password.errors.pattern">
                                {{this.language.mLparams[this.lang_dync].password_struct}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group col">
                        <label> {{this.language.mLparams[this.lang_dync].cpassword}}</label>
                        <input type="password" formControlName="confirmPassword" class="form-control loginFormInput" (keyup)="reg_change($event) " [ngClass]="{
                'is-invalid': submitted && f.confirmPassword.errors
              }" />
                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.confirmPassword.errors.required">
                                {{this.language.mLparams[this.lang_dync].cpass_required}}
                            </div>
                            <div *ngIf="f.confirmPassword.errors.mustMatch">
                                {{this.language.mLparams[this.lang_dync].pwd_match}}
                            </div>
                        </div>
                    </div>
                    <p class="text-muted display-5 text-left mt-2 mb-1 paragraph">
                        {{this.language.mLparams[this.lang_dync].password_structure}}</p>
                </div>
                <div class="form-group form-check">

                    <input type="checkbox" [checked]="AcceptAll" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{
              'is-invalid': submitted && f.acceptTerms.errors
            }" (change)=tc_checkBox($event) />
                    <label for="acceptTerms" class="form-check-label">Accept</label> <a class="txt_mode_3 ml-1" target="_blank" [routerLink]="['/legal']">{{this.language.mLparams[this.lang_dync].trmndcnd}}</a>
                    <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
                        {{this.language.mLparams[this.lang_dync].atcr}}

                    </div>
                </div>
                <div class="text-center">
                    <!-- <button
            type="button"
            class="btn btn-primary float-left"
            (click)="changelogin()"
          >
            Login
          </button> -->
                    <button type="submit" class="btn loginregbtn float-center pr-5 pl-5" [disabled]="buttonState">
                        {{this.language.mLparams[this.lang_dync].register}}
                    </button>
                    <span class="d-block mt-4">{{this.language.mLparams[this.lang_dync].already_mem}}
                        <a class="txt_mode_2" style="cursor: pointer;"
                            (click)="changelogin()">{{this.language.mLparams[this.lang_dync].login}}</a></span>
                    <!-- <button class="btn btn-secondary " type="reset" (click)="onReset()">Cancel</button> -->
                </div>
            </form>

            <!-- <button type="submit" class="btn btn-primary float-right" (click)="onSignupSubmit(signupForm.value)">Submit</button> -->
        </div>
    </div>
</div>