import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/config/language';
import { APIs_Config } from 'src/app/sys_config';
import { Request } from 'src/app/_service';

@Component({
  selector: 'app-all-product-list',
  templateUrl: './all-product-list.component.html',
  styleUrls: ['./all-product-list.component.scss']
})
export class AllProductListComponent implements OnInit {
  
  lang_dync = 'english';
  constructor( 
    public language: LanguageService,
    public config: APIs_Config,
    private request: Request,
    ) { }

@Input()
warehouse:any;



  ngOnInit(): void {
    
    this.selWareHouse = this.warehouse
    this.get_all_product();

  }

   allProduct:any;
   selWareHouse: any;

  async get_all_product(){


    let { format } = this.config.cfg.get_warehouse;
    format.product_id = 2;
    format.warehouse_id = this.warehouse.warehouse_id ;

    console.log(format, 'get_warehouse');
    let temp = await this.request.post(1, 'get_warehouse',format);
    this.allProduct = temp[1].response.data
    console.log(temp, 'All Product List');
  }



}
