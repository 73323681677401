<!-- <div *ngIf="is_spiner">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>

</div> -->


<div [ngClass]="{'disableDiv': model}" *ngIf="!model">
    <div class="container mt-3" *ngIf="!paymentpending">
        <div class="row justify-content-center">
            <div class="col-md-12 p-4 mt-1" style="border: 1px solid rgb(209, 208, 208); background-color: #fff; margin: 10px;
        border-radius: 5px;">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>{{this.language.mLparams[this.lang_dync].SN}}</th>
                                <th>{{this.language.mLparams[this.lang_dync].product}}</th>
                                <th>Product Name</th>
                                <th>{{this.language.mLparams[this.lang_dync].quantity}}</th>
                                <th style="text-align: end;">{{this.language.mLparams[this.lang_dync].priceperunit}}({{this.language.mLparams[this.lang_dync].dollor}})
                                </th>

                                <!-- <th>{{this.language.mLparams[this.lang_dync].shippmentCost}}</th> -->
                                <!-- <th>{{this.language.mLparams[this.lang_dync].shippmentStatus}}</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let orders of payment_prod?.products  ;index as i" [index]="i">
                                <td>{{i+1}}</td>

                                <td><img src="{{serverUrl}}{{orders.image_url}}" height="50px" width="50px" /></td>
                                <td>{{orders.product_name}}</td>
                                <td>{{orders.qty}}</td>

                                <td style="text-align: end;">{{orders.amount_payed | number: '.1-3'}}</td>
                            </tr>
                            <!-- <td>{{orders.order_status | titlecase}}</td>
                </tr>
                 <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td colspan="5">shippment Cost - {{singleOrderData.shipment_cost}}</td>
        </tr> -->
                        </tbody>
                        <thead>
                            <tr>
                                <th>Product cost ($)</th>
                                <th >Shipping cost ($)</th>
                                <th></th>
                                <th></th>
                                <th style="text-align: end;">Total ($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{payment_prod?.amount_payed  | number: '.1-3'}}</td>
                                <td >{{payment_prod?.shipment_cost}}</td>
                                <td></td>
                                <td></td>
                                <td style="text-align: end;">{{payment_prod?.total}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4>{{this.language.mLparams[lang_dync].selectPayment}}</h4>
                <p><strong>{{this.language.mLparams[lang_dync].freedomToChoosePayments}}</strong></p>
                <p>{{this.language.mLparams[lang_dync].line1}}</p>
                <p>{{this.language.mLparams[lang_dync].line2}}
                </p>
                <br>
                <!-- <div>
                    <div class="form-check">
                        <label class="form-check-label" for="ducPayment">
                            <input type="checkbox" class="form-check-input" id="ducPayment"
                               (change)="dandt()" [checked]="is_dandt_checked" [disabled]="is_dandt_checked">
                            <b>{{this.language.mLparams[lang_dync].agrmntmsg}}</b></label>
                    </div>
                </div> -->
                <div style="text-align: center; display: none;">
                    <!-- <img src="assets/DUC@300x.png" height="70px" style="margin-right: 20px;" />
                    <img src="assets/BTC@300x.png" height="70px" style="margin-right: 20px;" />
                    <img src="assets/ETC@300x.png" height="70px" style="margin-right: 20px;" />
                    <img src="assets/LTC@300x.png" height="70px" style="margin-right: 20px;" />
                    <img src="assets/USDC.png" height="70px" style="margin-right: 20px;" />
                    
                    <img src="assets/Paypal@300x.png" height="70px" style="margin-right: 20px;" />
                   
                    <img src="assets/VISA.png" height="70px" style="margin-right: 20px;" />
                    <img src="assets/Mastercard 2.png" height="70px" style="margin-right: 20px;" /> -->
                    <img src="assets/DUC.png" height="100px" style="margin: 10px;" class="paymntducc" />
                    <img src="assets/Crypto.png" height="100px" style="margin: 10px;" class="cryimgs paymntducc" />
                    <img src="assets/PayPal.png" height="100px" style="margin: 10px;" class="paymntducc" />
                    <img src="assets/Credit card v2.png" height="100px" style="margin: 10px;" class="cryimgs paymntducc" />





                </div>

            </div>

        </div>
    </div>

    <div *ngIf="!paymentpending" >
        <!-- [ngClass]="{'disableDiv': is_dandt_checked}" -->
        <div class="container mt-2 mb-5"
            style="align-items: center; border: 2px solid #f3f3f3; background-color: white; border-radius: 5px; padding-bottom: 20px; padding-top: 20px;">
            <div class="row">
                <div class="col-md-12  order-sm-1 order-2 ">
                    <div class="row">
                        <!-- <div class="col-md-12 "> -->
                            <!-- <div class="row p-4 modlrow"
                                style="text-align: center; border-radius: 5px; border: 1px solid #d3d3d3; margin-top: 30px; width: 100%; margin-left: -7px;">
                                <label><input type="radio" (click)="type1()" name="payment" id="paytype1" class="labpay"
                                        [checked]="!isrow1Disabled"></label>

                                <div class="col-lg-5 col-md-6 p-3 paycard" [ngClass]="{'disableDiv': isrow1Disabled}">
                                    <p>Pay Using DUC</p>
                                    <h4 class="paydu">DUC = {{duc_value_amount| number:'.1-2'}}</h4>
                                    
                                    <form [formGroup]="ducvalue">
                                        <div class="form-group mb-3">
                                            <div class="input-group-prepend frmcntrl">
                                                <input required formControlName="ducpercent"
                                                    class="form-control textbox" type="number" name="ducpercent"
                                                    (keyup)="check_enable($event)"
                                                    [ngClass]="{ 'is-invalid': logsubmitted && url0.ducpercent.errors }"
                                                    autofocus placeholder="Enter DUC">
                                                <div [ngClass]="{ 'disableDiv': pay_true}">
                                                    <button class="input-group-text" id="basic-addon1"
                                                        style="margin-left: 5px; cursor: pointer;">
                                                        <div *ngIf="spinner1">
                                                            <span id="duc" (click)="ducclick($event)">check</span>
                                                            
                                                        </div>
                                                        <div *ngIf="!spinner1" class="spinner-border" role="status"
                                                            style="width: 20px; height: 20px;">
                                                            <span class="sr-only">Loading...</span>
                                                           
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div *ngIf="logsubmitted && url0.ducpercent.errors">
                                                <div *ngIf="url0.ducpercent.errors.required" class="text-danger">
                                                    % is Required
                                                </div>
                                                <div *ngIf="url0.ducpercent.errors.pattern" class="text-danger">
                                                    Invalid Duc %
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <p class="text-muted" style="font-size:smaller">Enter the % Value and click Check to
                                        View the equivalent amount in DUC</p>
                                    <button class="btn paybtn" id="duc" (click)="ducPay1($event)"
                                        [disabled]="is_pay1Disabled">PAY</button>
                                    <div class="row mb-n4 scanpay">
                                        <p class="text-muted">(A max of 50% of the Product cost)</p>

                                    </div>
                                </div>

                                <div class="col-lg-5 col-md-5 p-3 paycard" [ngClass]="{'disableDiv': isFiatDisabled}">
                                    <p>Product + Shipping</p>
                                    <h4 class="payfia">Fiat ({{this.language.mLparams[lang_dync].dollor}}) =
                                        {{other_value_amount | number:'.1-2'}}</h4>
                                    
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            {{fiatType}}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" (click)="checkfiat('Paypal')">Paypal</a>
                                            <a class="dropdown-item" (click)="checkfiat('QuantumClearance')">Quantum</a>
                                        </div>
                                    </div>
                                    <button class="btn paybtn mt-3" [disabled]="isfiatbtndisabled"
                                        (click)="fiatRedirect()">
                                        <span *ngIf="!pay_spinner">PAY</span>
                                        <div *ngIf="pay_spinner" class="spinner-border" role="status"
                                            style="width: 20px; height: 20px;">
                                            <span class="sr-only">Loading...</span>
                                            
                                        </div>
                                    </button>
                                </div>
                            </div> -->

                        <!-- </div> -->
                        <!-- <hr> -->
                            <!-- <div class="row p-4"
                                style="text-align: center; border-radius: 5px; border: 1px solid #d3d3d3; margin-top: 30px; width: 100%; margin-left: -7px;">
                                <div class="checkbox">
                                    <label><input type="radio" (click)="type2()" class="mb-1 labpay" name="payment"
                                            id="paytype2"></label>
                                </div>
                                <div class="col-lg-5 col-md-6 p-3 paycard" [ngClass]="{'disableDiv': isrow2Disabled}">
                                    <p>Pay Using DUC</p>
                                    <h4 class="paydu">DUC = {{duc_value_amount2 | number:'.1-2'}}</h4>
                                  
                                    <form [formGroup]="ducvalue2">
                                        <div class="form-group mb-3">
                                            <div class="input-group-prepend frmcntrl">
                                                <input required formControlName="ducpercent2"
                                                    (keyup)="check_enable($event)" type="number"
                                                    class="form-control textbox" placeholder="Enter DUC"
                                                    [ngClass]="{ 'is-invalid': logsubmitted1 && url1.ducpercent2.errors }"
                                                    autofocus>
                                                <button class="input-group-text" id="basic-addon2"
                                                    [ngClass]="{ 'disableDiv': pay_true}"
                                                    style="margin-left: 5px;cursor: pointer;">
                                                    <div *ngIf="spinner3">
                                                        <span id="duc" (click)="ducClick($event)">check</span>
                                                       
                                                    </div>
                                                    <div *ngIf="!spinner3" class="spinner-border" role="status"
                                                        style="width: 20px; height: 20px;">
                                                        <span class="sr-only">Loading...</span>
                                                    
                                                    </div>
                                                </button>
                                            </div>
                                            <div *ngIf="logsubmitted1 && url1.ducpercent2.errors">
                                                <div *ngIf="url1.ducpercent2.errors.required" class="text-danger">
                                                    % is Required
                                                </div>
                                                <div *ngIf="url1.ducpercent2.errors.pattern" class="text-danger">
                                                    Invalid Duc %
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </form>
                                    <p class="text-muted" style="font-size:smaller">Enter the % Value and click Check to
                                        View the equivalent amount in DUC</p>
                                    <button class="btn paybtn" id="duc" (click)="ducPay2($event)"
                                        [disabled]="is_pay1Disabled2">PAY</button>
                                    <div class="row mb-n4 scanpay">
                                        <p class="text-muted">(A max of 50% of the Product cost)</p>

                                    </div>
                                </div>
                              
                                <div class="col-lg-5 col-md-5 p-3 paycard" [ngClass]="{'disableDiv': isCryptoDisabled}">
                                    <p>Select Crypto method</p>
                                    <h4 class="payfia">Crypto</h4>
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            {{cryptotype}}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" (click)="checkCrypto('ETH')">ETH</a>
                                            <a class="dropdown-item" (click)="checkCrypto('BTC')">BTC</a>
                                            <a class="dropdown-item" (click)="checkCrypto('LTC')">LTC</a>
                                            <a class="dropdown-item" (click)="checkCrypto('aaplus')">USDC</a>
                                        </div>
                                    </div>
                                    <button class="btn paybtn mt-3" [disabled]="isCryptoBtnDisabled"
                                        (click)="cryptopay()">PAY</button>
                                </div>
                                 <div class="col-lg-5 col-md-6 p-3 paycard disabox"
                                    [ngClass]="{'disableDiv': isshippingFiatDisabled}">
                                    <p>Shipping</p>
                                    <h4 class="payfia">Fiat ({{this.language.mLparams[lang_dync].dollor}}) =
                                        {{shiping_cost_amount2 | number:'.1-2'}}</h4>
                                     <br> 
                                     <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            {{fiatType2}}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" (click)="checkfiat1('Paypal')">Paypal</a>
                                            <a class="dropdown-item" (click)="checkfiat1('QuantumClearance')">Quantum</a>
                                        </div> 
                                    </div> 
                                    <button class="btn paybtn mt-3" [disabled]="isfiatpay2"
                                        (click)="fiatRedirect1()">PAY
                                        <div *ngIf="pay_spinner2" class="spinner-border" role="status"
                                            style="width: 20px; height: 20px;">
                                            <span class="sr-only">Loading...</span>
                                           
                                        </div>
                                    </button>

                                </div> 
                            </div> -->
                            <!-- <hr> -->

                            <!-- <div class="col-12"> -->
                                <div class="col-lg-5 col-md-4 p-4 paymt  p-3 paycard " style="border: 1px solid rgb(209, 208, 208); margin-top: 15px;
                                border-radius: 5px; text-align: center;">

                                    <div>
                                        <label class="form-check-label form-check paymentselect" >
                                            <input type="radio" class="form-check-input" name="pg" value="paypal"
                                                (click)="open_2(content2_0)"
                                                style="margin-top: 33px; margin-left: -28px; margin-right: auto; display: block; ">
                                            <!-- <img src="assets/DUC, Mastercard and Paypal.svg" class="ducpayimg"
                                                style="margin-bottom: 13px; margin-top: 12px;" /> -->
                                                <!-- <img src="assets/1.4.svg" class="ducpayimg"
                                                style="margin-bottom: 13px; margin-top: 12px;" /> -->
                                                <img src="assets/1.3.svg" class="duccrdpayimg"
                                                style="margin-bottom: 0px; margin-top: 6px;" />
                                                <!-- <img src="assets/DUC, Credit card and Paypal.svg" class="ducpayimg"
                                                style="margin-bottom: 13px; margin-top: 12px; " /> -->
                                                <!-- <img src="assets/DUC, Credit card and Paypal 2.svg" class="ducpayimg"
                                                style="margin-bottom: 13px; margin-top: 12px; margin-left: -30px;" /> -->
                                                
                                                <br>
                                                <p class="ducpattext" style ="font-size: 13px; margin-bottom: 10px;">
                                                    Partial Payment via DUC + Fiat</p>
                                        </label>
                                    </div>
                                </div>
                                <!-- <hr> -->
                                <div  class="col-lg-5 col-md-4 p-4 paymt  p-3 paycard" placement="bottom" ngbTooltip="Crypto Payment link is down for the moment for Code upgradation... We will resume soon, Kindly try other payment options" tooltipClass="my-custom-class" style="border: 1px solid rgb(209, 208, 208); margin-top: 15px;
                                border-radius: 5px; text-align: center;">

                                    <div [ngClass]="{'disableDiv': isCryptoDisabled}"> 
                                        <label class="form-check-label form-check paymentselect " >
                                            <input type="radio" class="form-check-input" name="pg" value="paypal"
                                                (click)="open_3(content3_0)" 
                                                style="margin-top: 33px; margin-left: -28px; margin-right: auto; display: block;">
                                            <img src="assets/DUC and Crypto 4.svg" class="ducpayimg"
                                                style="margin-bottom: 0px; margin-top: 6px;" />
                                                <br>
                                                <p class="ducpattext" style="font-size: 13px; margin-bottom: 10px;">
                                                    Partial Payment via DUC + Crypto</p>
                                        </label>
                                    </div>
                                </div>
                                <!-- <hr> -->
                                <div class="col-lg-3 col-md-4 p-4 paymt p-3 paycard"  placement="bottom" ngbTooltip="Card Payment link is down for the moment for Code upgradation... We will resume soon, Kindly try other payment options" tooltipClass="my-custom-class"
                                    *ngIf="paymentMethods.quantumClearance && quandampay" style="border: 1px solid rgb(209, 208, 208);  margin-top: 15px;
                        border-radius: 5px; text-align: center;">
                                    <div style="margin-bottom: 10px;"  [ngClass]="{'disableDiv': isCryptoDisabled}">
                                        <label class="form-check-label form-check paymentselect" style="padding-bottom: 0px;">
                                            <input type="radio" class="form-check-input " name="pg"
                                                value="quantumClearance" [(ngModel)]="paymentMethod"
                                                (change)="createPayment()"
                                                style="margin-top: 31px; margin-left: -28px; margin-right: auto; display: block;">
                                            <img src="assets/Credit cards - Payment imagenew.svg" class="ducpayimgg" style="margin-bottom: 0px; margin-top: 10px;"  />
                                            <br>
                                                <p class="ducpattext" style="font-size: 13px; ">
                                                    {{this.language.mLparams[lang_dync].chooseToPayCredit}}</p>
                                        </label>
                                    </div>
                                </div>
                                <!-- <hr> -->
                                <div class="col-lg-3 col-md-4 p-4 paymt  p-3 paycard" style="border: 1px solid rgb(209, 208, 208); margin-top: 15px;
                        border-radius: 5px; text-align: center;">

                                    <div *ngIf="paymentMethods.paypal" style="margin-bottom: 4px; margin-top: 4px;">
                                        <label class="form-check-label form-check paymentselect">
                                            <input type="radio" class="form-check-input" name="pg" value="paypal"
                                                [(ngModel)]="paymentMethod" (change)="createPayment()"
                                                style="margin-top: 33px; margin-left: -28px; margin-right: auto; display: block; ">
                                            <img src="assets/Paypal - Payment image 2.svg" class="ducpayimgg"
                                            style="margin-bottom: 0px; margin-top: 7px;" />
                                                <br>
                                                <p class="ducpattext" style="font-size: 13px; margin-bottom: 6px;">
                                                    {{this.language.mLparams[lang_dync].chooseToPayPaypal}}</p>
                                        </label>
                                    </div>
                                </div>
                                <!-- <hr> -->
                                <div class="col-lg-3 col-md-4 p-4 paymt  p-3 paycard"  placement="bottom" ngbTooltip="Crypto Payment link is down for the moment for Code upgradation... We will resume soon, Kindly try other payment options" tooltipClass="my-custom-class"  style="border: 1px solid rgb(209, 208, 208);  margin-top: 15px;
                        border-radius: 5px; text-align: center;">

                                    <div *ngIf="paymentMethods.aaplus">
                                        <div [ngClass]="{'disableDiv': isCryptoDisabled}">
                                            <label class="form-check-label form-check paymentselect" style="margin-bottom: 4px; margin-top: 4px;">
                                                <input type="radio" class="form-check-input" name="pg"
                                                    (click)="open(content)" value="aaplus" [(ngModel)]="paymentMethod"
                                                    (change)="selectPayment() "
                                                    style="margin-top: 30px; margin-left: -28px; margin-right: auto; display: block;">
                                                <img src="assets/Crypto - Payments imagenew.svg" class="ducpayimgg"
                                                    style="margin-bottom: 0px; margin-top: 7px;"/>
                                                <br>
                                                <p class="ducpattext" style="font-size: 13px; margin-bottom: 7px;">
                                                    {{this.language.mLparams[lang_dync].chooseToPayCrypto}}</p>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            <!-- </div> -->


                    </div>
                </div>
                <!-- <div class="col-md-4 col-sm-12 paymentcard order-1" *ngIf="!isrow1Disabled">
                    <div class="row scanpay" *ngIf="initial">
                        <h5>PAYMENT</h5>
                        <div class="row">
                            <div class="col-md-12 col-lg-12">
                                <img src="assets/startpayment.svg" class="mt-5" height="300px" width="" alt="payment"
                                    class="center" />
                            </div>
                        </div>
                        <p>Select any one Payment Method to Pay</p>
                    </div>
                    <div class="row scanpay" *ngIf="isducenabled">
                        <div class="row col-lg-12">
                            <div class=" col-md-12 col-lg-12 ">
                                <p style="margin-left: 25px;">Scan the QR code to make <b>DUC</b> Payment</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <section *ngIf="pend">
                                    <div class="row" style="margin: 20%;">
                                        <div class="col-md-2 col-sm-2">
                                            <div class="spinner-border text-primary" style="text-align: center;"></div>
                                        </div>
                                        <div class="col-md-5 col-sm-2"></div>
                                    </div>
                                </section>
                                <ngx-qrcode *ngIf="!pend" [elementType]="elementType" [value]="wallet">
                                </ngx-qrcode>
                                <h5>Amount: {{Duc_Amount}} DUC</h5>
                                <div class="form-group">
                                    <label for="resource-id">{{this.language.mLparams[lang_dync].wltadrs}}</label>
                                    <div class="input-group">
                                        <input id="resource-id" value="{{walletAddress}}" type="text"
                                            class="form-control" name="id" placeholder="Payment Address"
                                            readonly="readonly">
                                        <button class="input-group-text" (click)="clicktocopy()"
                                            [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-lg-12">
                            <div class="col">
                                <div>
                                    <p class="text-muted display-5 text-left mt-2 mb-1 paragraph "
                                        style="font-size: 11px;">
                                        Kindly confirm us that your payment as been completed
                                    </p>

                                </div>
                                <button class="btn btn-success scanbtn" (click)="ducConform()"
                                    style="margin-left: 34px;">Continue</button>
                            </div>
                        </div>
                    </div>
                    <div class="row scanpay" *ngIf="isfiatenabled">
                        <h5>You Will be Shortly Redirected to Fiat Payment Page</h5>
                    </div>
                </div> -->
                <!-- <div class="col-md-4 col-sm-12 paymentcard order-1" *ngIf="isrow2Disabled2">
                    <div class="row scanpay" *ngIf="initial">
                        <h5>PAYMENT</h5>
                        <br>

                        <div class="row">
                            <div class="col">
                                <img src="assets/startpayment.svg" class="mt-5" height="300px" width="" alt="payment"
                                    class="center" />
                            </div>
                        </div>
                        <p>Select any one Payment Method to Pay row 2</p>
                    </div>
                    <div class="row scanpay" *ngIf="isducenabled">
                        <div class="row col-lg-12">
                            <div class="col">
                                <p style="margin-left: 25px;">Scan to Pay Payment</p>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <section *ngIf="pend">
                                    <div class="row col-lg-12" style="margin: 20%;">
                                        <div class="col-md-2 col-sm-2">
                                            <div class="spinner-border text-primary" style="text-align: center;"></div>
                                        </div>
                                        <div class="col-md-5 col-sm-2"></div>
                                    </div>
                                </section>
                                <ngx-qrcode *ngIf="!pend" [elementType]="elementType" [value]="wallet">
                                </ngx-qrcode>
                                <h5>Amount = {{Duc_Amount2}} DUC</h5>
                                <div class="form-group">
                                    <label for="resource-id">{{this.language.mLparams[lang_dync].wltadrs}}</label>
                                    <div class="input-group">
                                        <input id="resource-id" value="{{walletAddress}}" type="text"
                                            class="form-control" name="id" placeholder="Payment Address"
                                            readonly="readonly">
                                        <button class="input-group-text" (click)="clicktocopy()"
                                            [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-lg-12">
                            <div class="col">
                                <div>
                                    <p class="text-muted display-5 text-left mt-2 mb-1 paragraph"
                                        style="font-size: 11px;">
                                        Kindly confirm us that your payment as been completed
                                    </p>

                                </div>
                                <button class="btn btn-success scanbtn" (click)="ducConform1()"
                                    style="margin-left: 34px;">Continue</button>
                            </div>
                        </div>
                    </div>
                    <div class="row scanpay" *ngIf="iscryptoenabled">
                        <p>Crypto payment</p>
                        <div class="row">
                            <div class="col">
                                <section *ngIf="pend">
                                    <div class="row" style="margin: 20%;">
                                        <div class="col-md-2 col-sm-2">
                                            <div class="spinner-border text-primary" style="text-align: center;"></div>
                                        </div>
                                        <div class="col-md-5 col-sm-2"></div>
                                    </div>
                                </section>
                                <ngx-qrcode *ngIf="!pend" [elementType]="elementType" [value]="wallet">
                                </ngx-qrcode>
                                <h5>Amount = {{Duc_Amount2}}</h5>

                                <div class="form-group">
                                    <label for="resource-id">{{this.language.mLparams[lang_dync].wltadrs}}</label>
                                    <div class="input-group">
                                        <input id="resource-id" value="{{walletAddress}}" type="text"
                                            class="form-control" name="id" placeholder="Payment Address"
                                            readonly="readonly">
                                        <button class="input-group-text" (click)="clicktocopy()"
                                            [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <p class="text-muted display-5 text-left mt-2 mb-1 paragraph"
                                        style="font-size: 11px;">
                                        Kindly confirm us that your payment as been completed
                                    </p>

                                </div>
                                <button class="btn btn-success" (click)="cryptoconform()">Continue</button>
                            </div>
                        </div>
                    </div>
                    <div class="row scanpay" *ngIf="isfiatenabled">
                        <h5>You Will be Shortly Redirected to Fiat Payment Page</h5>
                    </div>

                </div> -->
            </div>
        </div>

        <!-- <hr> -->

        <div class="container mt-3" *ngIf="!paymentpending">
            <!-- <div class="row no-gutters justify-content-center">
        <div class="col-md-10 p-4 m-1 contbg" style="border: 1px solid rgb(209, 208, 208);
        border-radius: 5px;">
            <h4>{{this.language.mLparams[lang_dync].selectPayment}}</h4>
            <p><strong>{{this.language.mLparams[lang_dync].freedomToChoosePayments}}</strong></p>
            <p>{{this.language.mLparams[lang_dync].line1}}</p>
            <p>{{this.language.mLparams[lang_dync].line2}}
            </p>
        </div>
    </div> -->
            <!-- <div class="row no-gutters mb-3 justify-content-center">
        <div class="col-md-6 p-4 m-1 contbg" style="border: 1px solid rgb(209, 208, 208);
        border-radius: 5px;"> -->
            <!-- <div *ngIf="paymentMethods.duc">
                <label class="form-check-label form-check paymentselect"> -->
            <!-- <input type="radio" class="form-check-input" name="pg"
                        data-toggle="modal" data-target="#ducpayment"
                         [(ngModel)]="paymentMethod"> -->

            <!-- <input type="radio" class="form-check-input" name="pg" (click)="open(content1)"
                        [(ngModel)]="paymentMethod" value="duc" (change)="createPayment()">
                    <img src="assets/duc.svg" height="50px" width="50px" />
                    {{this.language.mLparams[lang_dync].chooseTopayInduc}}
                </label>
            </div> -->
            <!-- <br> -->
            <!-- <div *ngIf="paymentMethods.aaplus">
                <div>
                    <label class="form-check-label form-check paymentselect">
                        <input type="radio" class="form-check-input" name="pg" (click)="open(content)" value="aaplus"
                            [(ngModel)]="paymentMethod" (change)="selectPayment()">
                        <img src="assets/logo.png" height="50px" width="120px" />
                        {{this.language.mLparams[lang_dync].chooseToPayCrypto}}
                    </label>
                </div>
            </div> -->
            <!-- <br>
                <div *ngIf="paymentMethods.quantumClearance && quandampay" style="margin-bottom: 10px;">
                    <label class="form-check-label form-check paymentselect">
                        <input  type="radio" class="form-check-input " name="pg" value="quantumClearance" height="60px"
                            width="180px" [(ngModel)]="paymentMethod" (change)="createPayment()"
                            style="margin-top: 10px;"><img src="assets/quantumClearance.svg" />
                    </label>
                </div>
                <br> -->
            <!-- <div class="form-check paymentselect">
                    <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="pg" data-toggle="modal"
                            data-target="#bopaymentmodal" (change)="paymentType($event)" value="bo">
                        <img src="../../../assets/bopayment.png" height="50px" width="50px" /> Utilize your Backoffice
                        Shopping Account Balance
                    </label>
                </div>
                <br> -->
            <!-- <div *ngIf="paymentMethods.paypal">
                    <label class="form-check-label form-check paymentselect">
                        <input type="radio" class="form-check-input" name="pg" value="paypal"
                            [(ngModel)]="paymentMethod" (change)="createPayment()" style="margin-top: 10px;">
                        <img src="assets/PayPal.svg" height="40px" />
                    </label>
                </div> -->

            <!-- </div> -->
            <!-- <div class="col-md-4  mt-1 p-4 contbg" style="border: 1px solid rgb(209, 208, 208);
        border-radius: 5px; margin-bottom: 4px;">
            <h6><strong>{{this.language.mLparams[lang_dync].orderSummary}}</strong></h6>
            <div class="row">
                <div class="col-8">
                    {{this.language.mLparams[lang_dync].prodcost}}
                </div>
                <div class="col-4 text-right">
                    <b>${{paymentamount}}</b>
                </div>
            </div>
            <div class="row">
                <div class="col-8">
                    <p> {{this.language.mLparams[lang_dync].shipngcst}}</p>
                </div>
                <div class="col-4 text-right"><b>{{this.language.mLparams[lang_dync].dollor}} {{shippingamount}}</b>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-8">
                    <h5 class="text-danger">{{this.language.mLparams[lang_dync].orderTotal}}</h5>
                </div>
                <div class="col-4 text-right " style="color: #e3432e;">
                    <b>${{orderTotal}}</b>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-2"></div> -->
        </div>

        <!-- DUC Payment Modal -->
        <!-- <hr> -->

        <ng-template #content1 let-modal>
            <div>
                <div >
                    <div class="modal-header">
                        <h5 *ngIf="shippingPayment" class="modal-title" id="ducpayment">
                            {{this.language.mLparams[lang_dync].DUCPayment}}
                        </h5>
                        <h5 *ngIf="!shippingPayment" class="modal-title" id="ducpayment">
                            {{this.language.mLparams[lang_dync].shippingPayment}}
                        </h5>
                        <button type="button" class="close"  (click)="triggerCloseWarning()" [disabled]="isXdisabled">
                            <span aria-hidden="true">&times;</span> 
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row ">
                            <div *ngIf="closeWarning" class="container mb-2 " style="text-align: center;">
                                <div class="m-3 p-3 border border-danger rounded shadow-lg ">
                                    <p> {{this.language.mLparams[lang_dync].areYouSureToCancelTransaction}}</p><button
                                        class="btn btn-danger btn-sm m-1 " data-dismiss="modal" (click)="closeModal()"
                                        (click)="modal.dismiss('Cross click')"
                                        aria-label="Close">{{this.language.mLparams[lang_dync].yes}}</button>
                                    <button class="btn btn-success btn-sm m-1" data-dismiss="modal"
                                        (click)="notcloseModal()"
                                        aria-label="Close">{{this.language.mLparams[lang_dync].no}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutters" *ngIf="shippingPayment">
                            <div class="col-md-2"></div>
                            <div class="col-md-8">
                                <p class="text-danger text-small">{{this.language.mLparams[lang_dync].noteTxt}}
                                </p>
                                <div class="container" align="center">
                                    <p>{{this.language.mLparams[lang_dync].scanQRtoMake}} <b>DUC</b>
                                        {{this.language.mLparams[lang_dync].payment}}
                                    </p>
                                    <section *ngIf="pending">
                                        <!--Big blue-->
                                        <div class="row" style="margin: 20%;">
                                            <div class="col-md-5 col-sm-2"></div>
                                            <!-- <strong class="text-primary"
                                                style="text-align: left; padding-top: 5px;">Please Wait You Will Be
                                                Redirected Shortly...</strong> -->
                                            <div class="col-md-2 col-sm-2">

                                                <div class="spinner-border text-primary" style="text-align: center;">

                                                </div>
                                            </div>
                                            <div class="col-md-5 col-sm-2"></div>
                                        </div>
                                    </section>
                                    <ngx-qrcode *ngIf="!pending" [elementType]="elementType" [value]="walletAddress">
                                    </ngx-qrcode>
                                    <p>{{this.language.mLparams[lang_dync].sendingOtherDigitalAssetswillResultInPaymentLoss}}
                                    </p>
                                    <p><b>{{this.language.mLparams[lang_dync].pleaseEnsureThatYouEnteredTheFullAmountof}}
                                            <b class="text-danger">DUC {{paymentAmount}} </b>
                                            {{this.language.mLparams[lang_dync].whileMakingthePaymentFromTheApp}}
                                        </b>
                                    </p>

                                    <div class="form-group">
                                        <label
                                            for="resource-id">{{this.language.mLparams[lang_dync].walletAddress}}</label>
                                        <div class="input-group">
                                            <input id="resource-id" [(ngModel)]="walletAddress" type="text"
                                                class="form-control" name="id" placeholder="Payment Address"
                                                readonly="readonly">
                                            <button class="input-group-text" (click)="clicktocopy()"
                                                [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="ducPayment"
                                        [checked]="isducChecked" [disabled]="isContinueDisabled"
                                        (change)="onducConform()">
                                    <label class="form-check-label"
                                        for="ducPayment">{{this.language.mLparams[lang_dync].iConfirmThatIhaveMadeFullPaymentOfDUC}}{{paymentAmount}}
                                        {{this.language.mLparams[lang_dync].toTheAboveAddress}}</label>
                                </div>
                                <div class="text-success" *ngIf="isducChecked">
                                    <p>{{this.language.mLparams[lang_dync].thankyouLine}}{{this.language.mLparams[lang_dync].dollor}}
                                        {{shippingamount}}
                                    </p>
                                </div>
                                <!-- <div class="text-danger" *ngIf="ducProcessErr">
                            <p>Error Paying DUC Payment</p>
                         </div> -->
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                        <div class="container" align="center" *ngIf="!shippingPayment">
                            <div *ngIf="!paymentpending">
                                <p><b>{{this.language.mLparams[lang_dync].selectShippingPayment}}</b></p>
                                <!-- <div class="form-check"
                                    *ngIf="paymentMethods.quantumClearance && paymentMethods.quantumClearance.shipment ">
                                    <label class="form-check-label" *ngIf="!shipement_validation">
                                        <input type="radio" class="form-check-input" name="shipping" height="60px"
                                            width="150px" (change)="createPayment('shipment')"
                                            [(ngModel)]="paymentMethod" value="Quantum Payment"><img
                                            src="assets/Credit_card_Payment_image.svg" class="ducpayimgg" style="margin-bottom: 9px; margin-top: 9px;"/>
                                    </label>
                                </div> -->
                                <div class="form-check" *ngIf="paymentMethods.paypal && paymentMethods.paypal.shipment">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" name="shipping" height="60px"
                                            width="150px" (change)="createPayment('shipment')"
                                            [(ngModel)]="paymentMethod" value="paypal"><img
                                            src="../../../assets/PayPal.svg" height="40px" />
                                    </label>
                                </div>
                            </div>
                            <section *ngIf="paymentpending">
                                <!--Big blue-->
                                <div class="row" style="margin: 20%;">
                                    <div class="col-md-5 col-sm-2"></div>
                                    <strong class="text-primary" style="text-align: left; padding-top: 5px;">Please Wait
                                        You Will Be Redirected Shortly...</strong>
                                    <div class="col-md-2 col-sm-2">

                                        <div class="spinner-border text-primary" style="text-align: center;"></div>
                                    </div>
                                    <div class="col-md-5 col-sm-2"></div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div class="modal-footer" *ngIf="shippingPayment">
                        <div class="float-right">
                            <button class="btn loginregbtn" [disabled]="!isducChecked"
                                (click)="continueShipping()">{{this.language.mLparams[lang_dync].cntnue}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <!-- Duc Payment Modal End -->


        <!-- crypto payment Modal -->

        <ng-template #content let-modal>
            <div>
                <div>
                    <div class="modal-header">
                        <h5 class="modal-title" id="modal-basic-title">{{this.language.mLparams[lang_dync].crptpymnt}}
                        </h5>
                        <button type="button" class="close" (click)=triggerCloseWarning() [disabled]="isXdisabled">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row ">
                            <div *ngIf="closeWarning" class="container mb-2 " style="text-align: center;">
                                <div class="m-3 p-3 border border-danger rounded shadow-lg ">
                                    <p> {{this.language.mLparams[lang_dync].areYouSureToCancelTransaction}}</p><button
                                        class="btn btn-danger btn-sm m-1 " data-dismiss="modal" (click)="closeModal()"
                                        (click)="modal.dismiss('Cross click')"
                                        aria-label="Close">{{this.language.mLparams[lang_dync].yes}}</button>
                                    <button class="btn btn-success btn-sm m-1" data-dismiss="modal"
                                        (click)="notcloseModal()"
                                        aria-label="Close">{{this.language.mLparams[lang_dync].no}}</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="cryptobody1">
                            <div class="row">
                            </div>
                            <div *ngIf="SelectCrypto">
                                <div class="dropdown show d-flex flex-row justify-content-center">
                                    <a class="btn btn-secondary dropdown-toggle" role="button" data-toggle="dropdown"
                                        aria-haspopup="true">
                                        {{this.language.mLparams[lang_dync].selectAsset}}
                                    </a>

                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a class="dropdown-item" *ngFor="let asset of currencies"
                                            (click)="chooseCurrency(asset)">{{asset}}</a>
                                            
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!SelectCrypto">
                                <div class="row no-gutters">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-8">
                                        <p class="text-danger text-small">{{this.language.mLparams[lang_dync].noteTxt}}
                                        </p>
                                        <p>{{this.language.mLparams[lang_dync].scanQRtoMake}}
                                            <b>{{paymentCurrencyCode}}</b>
                                            {{this.language.mLparams[lang_dync].payment}}
                                        </p>
                                        <div class="container" align="center">
                                            <p> {{this.language.mLparams[lang_dync].prdcstin}}
                                                <b>{{paymentCurrencyCode}}:
                                                    {{paymentAmount}}</b>
                                            </p>
                                            <p>{{this.language.mLparams[lang_dync].scnqrcodeonly}}
                                                <b>{{paymentCurrencyCode}}</b>
                                                {{this.language.mLparams[lang_dync].pymnt}}
                                            </p>
                                            <section *ngIf="pending">
                                                <!--Big blue-->
                                                <div class="row" style="margin: 20%;">
                                                    <div class="col-md-5 col-sm-2"></div>
                                                    <!-- <strong class="text-primary"
                                                        style="text-align: left; padding-top: 5px;">Please Wait You Will
                                                        Be Redirected Shortly...</strong> -->
                                                    <div class="col-md-2 col-sm-2">

                                                        <div class="spinner-border text-primary"
                                                            style="text-align: center;">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-5 col-sm-2"></div>
                                                </div>
                                            </section>
                                            <ngx-qrcode *ngIf="!pending" [elementType]="elementType"
                                                [value]="walletAddress">
                                            </ngx-qrcode>
                                            
                                            <div class="form-group">
                                                <label
                                                    for="resource-id">{{this.language.mLparams[lang_dync].wltadrs}}</label>
                                                <div class="input-group">
                                                    <input id="resource-id" value="{{walletAddress}}" type="text"
                                                        class="form-control" name="id" placeholder="Payment Address"
                                                        readonly="readonly">
                                                    <button class="input-group-text" (click)="clicktocopy()"
                                                        [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                                </div>
                                            </div>
                                            <p class="text-danger justify-content-left">
                                                {{this.language.mLparams[lang_dync].sendpymntline}}</p>

                                                <p style="font-size: 12px; margin-top: 10px;"> Please send proof of transfer - (transaction hash and /or screenshot of the transfer) to 
                                                    <a href="mailto:support@remusnation.com" ><b>support@remusnation.com </b></a>for faster processing)</p>
                                            <div class="form-check">
                                                <label class="form-check-label" for="ducPayment">
                                                    <input type="checkbox" class="form-check-input" id="ducPayment"
                                                        [checked]="iscryptoChecked" (click)="oncryptoConform()"
                                                        [disabled]="iscryptoConformDisabled">
                                                    {{this.language.mLparams[lang_dync].cntnueprcedshpng2}}</label>
                                            </div>
                                            <div class="text-success" *ngIf="iscryptoChecked">
                                                <p>{{this.language.mLparams[lang_dync].thankyouLine}}
                                                    </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!cryptobody1">
                        <div class="row" *ngIf="!paymentpending">
                
                
                            <div class="col-sm-12 col-lg-6">
                                <img src="assets/Green-Tick-Transparent-Background.png" class="images_width">
                
                            </div>
                            <div class="col-sm-12 col-lg-6 text-success" style="text-align: center;">
                                <h5>Payment  has been completed</h5>
                                <p>You will be  redirected Shortly ...</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div *ngIf="!cryptobody1">
                        <div class="container" align="center" *ngIf="!paymentpending">
                            <p><b>{{this.language.mLparams[lang_dync].paymntmthdline}}</b></p>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="shipping" height="60px"
                                        width="150px" (change)="createPayment('shipment')" [(ngModel)]="paymentMethod"
                                        value="quantumClearance"><img src="assets/Credit_card_Payment_image.svg" />
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" name="shipping" height="60px"
                                        width="150px" (change)="createPayment('shipment')" [(ngModel)]="paymentMethod"
                                        value="paypal"><img src="assets/PayPal.svg" height="40px" />
                                </label>
                            </div>
                        </div>
                        <section *ngIf="paymentpending">
                      
                            <div class="row" style="margin: 20%;">
                                <div class="col-md-5 col-sm-2"></div>
                                <strong class="text-primary" style="text-align: left; padding-top: 5px;">Please Wait You
                                    Will Be Redirected Shortly...</strong>
                                <div class="col-md-2 col-sm-2">

                                    <div class="spinner-border text-primary" style="text-align: center;"></div>
                                </div>
                                <div class="col-md-5 col-sm-2"></div>
                            </div>
                        </section>
                    </div> -->
                    <div class="modal-footer" *ngIf="cryptoshipping">
                        <div class="float-right">
                            <button class="btn loginregbtn"
                                (click)="continuecryptoShipping(content)">{{this.language.mLparams[lang_dync].cntnue}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <!-- crypto payment end -->

        <!-- bo payment modal -->

        <div class="modal fade" id="bopaymentmodal" tabindex="-1" role="dialog" aria-labelledby="bopaymentmodalTitle"
            aria-hidden="true" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="bopaymentmodalTitle">{{this.language.mLparams[lang_dync].bckofc}}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" (click)="closeModal()"
                            aria-label="Close" [disabled]="isXdisabled">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-{{result.class}}" role="alert">
                            {{result.msg}}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            data-dismiss="modal">{{this.language.mLparams[lang_dync].cls}}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- bo payment modal end -->

        <section *ngIf="paymentpending">
            <!--Big blue-->
            <div class="row" style="margin: 20%;">
                <div class="col-md-5 col-sm-2"></div>
                <strong class="text-primary" style="text-align: left; padding-top: 5px;">Please Wait You Will Be
                    Redirected Shortly...</strong>
                <div class="col-md-2 col-sm-2">

                    <div class="spinner-border text-primary" style="text-align: center;"></div>
                </div>
                <div class="col-md-5 col-sm-2"></div>
            </div>
        </section>
    </div>
</div>

<div *ngIf="model" style="height: 650px;"></div>

<ng-template #content_to_notdel let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delivery Terms</h4>

    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <!-- <div style="height: 100px;"> </div> -->
                <div class="row">
                    <div class="col">
                        <img src="assets/Not deliverable location.png" height="300px">
                    </div>
                </div>
                <label for="dateOfBirth">
                    <h1>! Sorry</h1>
                    <!-- <h5>This product is not deliveriable to your location, Please change your loaction</h5> -->
                    <h5>Delivery of this Product is not available to your selected location</h5>
                </label>
                <!-- <div class="input-group">
            <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>
            </div>
          </div> -->




            </div>
        </form>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div> -->
    <button class="btn btn-danger loginregbtn close" aria-label="Close" (click)="cancel_(content_to_notdel)">
        Go Back
    </button>
</ng-template>

<button class="btn btn-lg btn-outline-primary" id="not_deliveriable" (click)="open_notdeliver(content_to_notdel)"
    style="display: none;">Launch demo modal</button>

<section *ngIf="paymentpending">
    <!--Big blue-->
    <div class="row" style="margin: 20%;">
        <div class="col-md-5 col-sm-2"></div>
        <strong class="text-primary" style="text-align: left; padding-top: 5px;">Please Wait You Will Be Redirected
            Shortly...</strong>
        <div class="col-md-2 col-sm-2">
            <div class="spinner-border text-primary" style="text-align: center;"></div>
        </div>
        <div class="col-md-5 col-sm-2"></div>
    </div>
</section>




<div class="modal fade" id="content2_0" tabindex="-1" role="dialog" aria-labelledby="bopaymentmodalTitle"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="bopaymentmodalTitle">{{this.language.mLparams[lang_dync].bckofc}}
                </h5>
                <button type="button" class="close" data-dismiss="modal" (click)="closeModal()" aria-label="Close"
                    [disabled]="isXdisabled">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="alert alert-{{result.class}}" role="alert">
                    {{result.msg}}
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"
                    data-dismiss="modal">{{this.language.mLparams[lang_dync].cls}}</button>
            </div>
        </div>
    </div>
</div>


















<!------parcial pay by due and fiet-->

<ng-template #content2_0 let-modal>
    <div class="modal-header">

        <h4 class="modal-title" id="modal-basic-title">Partial Payment via <span *ngIf="duc_payment">
                <img src="assets/DUC with text.svg" width="80px" height="40px"> </span><span *ngIf="!duc_payment"> 
                <img src="assets/visa.svg" width="80px" height="40px"><img src="assets/mastercard.svg" width="80px"
                    height="40px">
            </span></h4>
        <a type="button" class="close" aria-label="Close" (click)="close_content2_0()">
            <span aria-hidden="true">&times;</span>
        </a>

    </div>
    <div *ngIf="closeWarning" class="container mb-2 " style="text-align: center;">
        <div class="m-3 p-3 border border-danger rounded shadow-lg ">
            <p> {{this.language.mLparams[lang_dync].areYouSureToCancelTransaction}}</p><button
                class="btn btn-danger btn-sm m-1 " data-dismiss="modal" (click)="modal.dismiss('Cross click')"(click)="closeModal2()"
              
                aria-label="Close">{{this.language.mLparams[lang_dync].yes}}</button>
            <button class="btn btn-success btn-sm m-1" data-dismiss="modal" (click)="notcloseModal()"
                aria-label="Close">{{this.language.mLparams[lang_dync].no}}</button>
        </div>
    </div>
    <div class="modal-body">


        <div class="row p-4 modlrow"
            style="text-align: center; border-radius: 5px; border: 1px solid #d3d3d3; margin-top: 30px; width: 100%; margin-left: -7px;">
            <!-- <label>
                <input type="radio" (click)="type1()" name="payment" id="paytype1" class="labpay"
                    [checked]="!isrow1Disabled"></label> -->

            <div class="col-lg-5 col-md-12 p-3 paycard" [ngClass]="{'disableDiv': isrow1Disabled23}" *ngIf="duc_payment">
                <p>Pay Using DUC</p>
                <h4 class="paydu">DUC = {{duc_value_amount | number:'1.0-0'}}</h4>
                <!-- <br> -->
             
                <form [formGroup]="ducvalue">
                    <div class="form-group mb-3">
                        <div class="input-group-prepend frmcntrl">
                            <input required formControlName="ducpercent" class="form-control textbox" type="number"
                                name="ducpercent" (keyup)="check_enable($event)"  onKeyPress="if(this.value.length==2) return false;"
                                [ngClass]="{ 'is-invalid': logsubmitted && url0.ducpercent.errors }" autofocus
                                placeholder="Enter DUC">
                                <p style="margin: 7px;">%</p>
                            <div [ngClass]="{ 'disableDiv': pay_true}">
                               
                                <button class="input-group-text" id="basic-addon1" (click)="ducclick($event)"
                                    style="margin-left: 0px; cursor: pointer;">
                                    <div *ngIf="spinner1">
                                        <span id="duc">Check</span>
                                        <!-- </div> -->
                                    </div>
                                    <div *ngIf="!spinner1" class="spinner-border" role="status"
                                        style="width: 20px; height: 20px;">
                                        <span class="sr-only">Loading...</span>
                                        <!-- </div> -->
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="logsubmitted && url0.ducpercent.errors">
                            <div *ngIf="url0.ducpercent.errors.required" class="text-danger">
                                % is Required
                            </div>
                            <div *ngIf="url0.ducpercent.errors.pattern" class="text-danger">
                                Invalid Duc %
                            </div>
                        </div>
                    </div>
                </form>
                <p class="text-muted" style="font-size:smaller">Enter the % Value and click Check to
                    View the equivalent amount in DUC</p>
                <button class="btn paybtn" id="duc" (click)="ducPay1($event)" [disabled]="is_pay1Disabled">PAY</button>
                <div class="row mb-n4 scanpay">
                    <p class="text-muted">(A max of 50% of the Product cost)</p>

                </div>
            </div>

            <div class="col-lg-5 col-md-12 p-3 paycard" [ngClass]="{'disableDiv': isFiatDisabled}" *ngIf="!duc_payment">
                <p>Balance Product Amount + Shipping Cost</p>
                <h4 class="payfia">Fiat ({{this.language.mLparams[lang_dync].dollor}}) =
                    {{other_value_amount | number:'.1-2'}}</h4>
                <!-- <br> -->
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{fiatType}}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="checkfiat('Paypal')">Paypal</a>
                        <!-- <a class="dropdown-item" (click)="checkfiat('QuantumClearance')">Credit Card</a> -->
                    </div>
                </div>
                <br>
                <p style="font-size: 13px;">Paid Product amount via DUC  : $ {{duc_value_amount_inUSD| number:'.1-2'}}</p>
                    <!-- <p style="font-size: 13px;">Remining Product amount &nbsp; : {{other_value_amount| number:'.1-2'}}$  </p> -->
                    <p style="font-size: 13px;">Shipping Cost &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : $ {{shipping_cost| number:'.1-2'}} </p>
                    <p style="font-size: 13px;">Total to pay&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp; &nbsp;  : $ {{other_value_amount| number:'.1-2'}} </p>
                <button class="btn paybtn mt-3" [disabled]="isfiatbtndisabled" (click)="fiatRedirect()">
                    <span *ngIf="!pay_spinner">PAY</span>
                    <div *ngIf="pay_spinner" class="spinner-border" role="status" style="width: 20px; height: 20px;">
                        <span class="sr-only">Loading...</span>
                        <!-- </div> -->
                    </div>
                </button>
            </div>
            <div class="col-lg-6 col-md-12 p-3 paycard " *ngIf="!isrow1Disabled">
                <div class="row scanpay" *ngIf="initial">
                    <h5>PAYMENT</h5>
                    <div class="row">
                        <div class="col-md-12 col-lg-12">
                            <img src="assets/startpayment.svg" class="mt-5" height="250px" width="" alt="payment"
                                class="center" />
                        </div>
                    </div>
                    <p>Select any one Payment Method to Pay</p>
                </div>
                <div class="row scanpay" *ngIf="isducenabled">
                    <div class="row col-lg-12">
                        <div class=" col-md-12 col-lg-12 ">
                            <p class="text-danger" style="font-size: 12px;">
                                {{this.language.mLparams[lang_dync].noteTxt}}
                            </p>

                            <p style="margin-left: 25px; margin-top: 0px; font-size: 12px;">
                                {{this.language.mLparams[lang_dync].scanQRtoMake}} <b>DUC</b>
                                {{this.language.mLparams[lang_dync].payment}}
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <section *ngIf="pend">
                                <div class="row">
                                    <div class="col-md-2 col-sm-2">
                                        <div class="spinner-border text-primary" style="text-align: center;"></div>
                                    </div>
                                    <div class="col-md-5 col-sm-2"></div>
                                </div>
                            </section>
                            <ngx-qrcode *ngIf="!pend" [elementType]="elementType" [value]="wallet">
                            </ngx-qrcode>
                            <p style="font-size: 12px;">
                                {{this.language.mLparams[lang_dync].sendingOtherDigitalAssetswillResultInPaymentLoss}}
                            </p>
                            <p style="font-size: 12px;">
                                <b>{{this.language.mLparams[lang_dync].pleaseEnsureThatYouEnteredTheFullAmountof}} <b
                                        class="text-danger">{{Duc_Amount}}
                                        DUC </b> {{this.language.mLparams[lang_dync].whileMakingthePaymentFromTheApp}}</b>
                            </p>
                            <div class="form-group">
                                <label for="resource-id">{{this.language.mLparams[lang_dync].wltadrs}}</label>
                                <div class="input-group">
                                    <input id="resource-id" value="{{walletAddress}}" type="text" class="form-control"
                                        name="id" placeholder="Payment Address" readonly="readonly">
                                    <button class="input-group-text" (click)="clicktocopy()"
                                        [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                </div>
                                <p style="font-size: 12px; margin-top: 10px;"> Please send proof of transfer -
                                    (transaction hash and /or screenshot of the transfer) to
                                    <a href="mailto:support@remusnation.com"><b>support@remusnation.com </b></a>for
                                    faster processing)</p>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="ducPayment"
                                            [checked]="isducChecked1" [disabled]="isContinueDisabled1"
                                            (change)="onducConform1()">
                                        <label class="form-check-label"
                                            for="ducPayment">{{this.language.mLparams[lang_dync].iConfirmThatIhaveMadeFullPaymentOfDUC}}
                                            {{Duc_Amount}} {{this.language.mLparams[lang_dync].duc}}
                                            {{this.language.mLparams[lang_dync].toTheAboveAddress}}</label>
                                    </div>
                                    <div class="text-success" *ngIf="isducChecked1">
                                        <p>{{this.language.mLparams[lang_dync].thankyouLine}}
                                        </p>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="row col-lg-12">
                        <div class="col">
                            <div>
                                <!-- <p class="text-muted display-5 text-left mt-2 mb-1 paragraph " style="font-size: 11px;">
                                    Kindly confirm us that your payment as been completed
                                </p> -->

                            </div>
                            <button class="btn btn-success scanbtn" [disabled]="!isducChecked1" (click)="ducConform()"
                                style="margin-left: 34px;">Continue</button>
                        </div>
                    </div>
                </div>
                <div class="row scanpay" *ngIf="isfiatenabled">
                    <h5>You Will be Shortly Redirected to Fiat Payment Page</h5>
                </div>
            </div>
        </div>
        <!-- <div class="col-12 col-sm-12 paymentcard order-1" *ngIf="!isrow1Disabled">
            <div class="row scanpay" *ngIf="initial">
                <h5>PAYMENT</h5>
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <img src="assets/startpayment.svg" class="mt-5" height="300px" width="" alt="payment"
                            class="center" />
                    </div>
                </div>
                <p>Select any one Payment Method to Pay</p>
            </div>
            <div class="row scanpay" *ngIf="isducenabled">
                <div class="row col-lg-12">
                    <div class=" col-md-12 col-lg-12 ">
                        <p style="margin-left: 25px;">Scan the QR code to make <b>DUC</b> Payment</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <section *ngIf="pend">
                            <div class="row" style="margin: 20%;">
                                <div class="col-md-2 col-sm-2">
                                    <div class="spinner-border text-primary" style="text-align: center;"></div>
                                </div>
                                <div class="col-md-5 col-sm-2"></div>
                            </div>
                        </section>
                        <ngx-qrcode *ngIf="!pend" [elementType]="elementType" [value]="wallet">
                        </ngx-qrcode>
                        <h5>Amount: {{Duc_Amount}} DUC</h5>
                        <div class="form-group">
                            <label for="resource-id">{{this.language.mLparams[lang_dync].wltadrs}}</label>
                            <div class="input-group">
                                <input id="resource-id" value="{{walletAddress}}" type="text" class="form-control"
                                    name="id" placeholder="Payment Address" readonly="readonly">
                                <button class="input-group-text" (click)="clicktocopy()"
                                    [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row col-lg-12">
                    <div class="col">
                        <div>
                            <p class="text-muted display-5 text-left mt-2 mb-1 paragraph " style="font-size: 11px;">
                                Kindly confirm us that your payment as been completed
                            </p>

                        </div>
                        <button class="btn btn-success scanbtn" (click)="ducConform()"
                            style="margin-left: 34px;">Continue</button>
                    </div>
                </div>
            </div>
            <div class="row scanpay" *ngIf="isfiatenabled">
                <h5>You Will be Shortly Redirected to Fiat Payment Page</h5>
            </div>
        </div> -->
    </div>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div> -->
</ng-template>

<!---partical paymet by due and crypto-->

<ng-template #content3_0 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Partial Payment via <span *ngIf="duc_payment2"><img
                    src="assets/DUC with text.svg" width="80px" height="40px">
            </span> <span *ngIf="!duc_payment2"> <img src="assets/Crypto - Payment image.svg" width="80px"
                    height="40px"> </span></h4>
        <a type="button" class="close" aria-label="Close" (click)="close_content3_0()">
            <span aria-hidden="true">&times;</span>
        </a>

    </div>
    <div *ngIf="closeWarning" class="container mb-2 " style="text-align: center;">
        <div class="m-3 p-3 border border-danger rounded shadow-lg ">
            <p> {{this.language.mLparams[lang_dync].areYouSureToCancelTransaction}}</p><button
                class="btn btn-danger btn-sm m-1 " data-dismiss="modal"(click)="modal.dismiss('Cross click')" (click)="closeModal3()"
                
                aria-label="Close">{{this.language.mLparams[lang_dync].yes}}</button>
            <button class="btn btn-success btn-sm m-1" data-dismiss="modal" (click)="notcloseModal()"
                aria-label="Close">{{this.language.mLparams[lang_dync].no}}</button>
        </div>
    </div>
    <div *ngIf="!success">
        <div class="modal-body">
            <div class="row p-4"
                style="text-align: center; border-radius: 5px; border: 1px solid #d3d3d3; margin-top: 30px; width: 100%; margin-left: -7px;">
                <div class="checkbox">
                    <!-- <label><input type="radio" (click)="type2()" class="mb-1 labpay" name="payment"
                            id="paytype2"></label> -->
                </div>
                <div class="col-lg-5 col-md-12 p-3 paycard" [ngClass]="{'disableDiv': isrow2Disabled}"
                    *ngIf="duc_payment2">
                    <p>Pay Using DUC</p>
                    <h4 class="paydu">DUC = {{duc_value_amount2 | number:'1.0-0'}}</h4>
                    <!-- <br> -->
                    <form [formGroup]="ducvalue2">
                        <div class="form-group mb-3">
                            <div class="input-group-prepend frmcntrl">
                                <input required formControlName="ducpercent2" (keyup)="check_enable($event)" onKeyPress="if(this.value.length>2) return false;"
                                    type="number" class="form-control textbox" placeholder="Enter DUC" onKeyPress="if(this.value  > 50) return false;"
                                    [ngClass]="{ 'is-invalid': logsubmitted1 && url1.ducpercent2.errors }" autofocus>
                                    <p style="margin: 7px;">%</p>
                                <button class="input-group-text" id="basic-addon1" (click)="ducClick($event)" [ngClass]="{ 'disableDiv': pay_true}"
                                    style="margin-left: 0px;cursor: pointer;">
                                    <div *ngIf="spinner3">
                                        <span id="duc" >Check</span>
                                    </div>
                                    <div *ngIf="!spinner3" class="spinner-border" role="status"
                                        style="width: 20px; height: 20px;">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </button>
                            </div>
                            <div *ngIf="logsubmitted1 && url1.ducpercent2.errors">
                                <div *ngIf="url1.ducpercent2.errors.required" class="text-danger">
                                    % is Required
                                </div>
                                <div *ngIf="url1.ducpercent2.errors.pattern" class="text-danger">
                                    Invalid Duc %
                                </div>
                            </div>
                        </div>
                    </form>
                    <p class="text-muted" style="font-size:smaller">Enter the % Value and click Check to
                        View the equivalent amount in DUC</p>
                    <button class="btn paybtn" id="duc" (click)="ducPay2($event)"
                        [disabled]="is_pay1Disabled2">PAY</button>
                    <div class="row mb-n4 scanpay">
                        <p class="text-muted">(A max of 50% of the Product cost)</p>

                    </div>
                </div>
                <!-- <p>(Upto 50% max)</p> -->
                <div class="col-lg-5 col-md-12 p-3 paycard" [ngClass]="{'disableDiv': isCryptoDisabled}"
                    *ngIf="!duc_payment2">
                    <p>Select Crypto method</p>
                    <p>Balance Product Amount + Shipping Cost</p>
                    <h4 class="payfia">Crypto</h4>
                    <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{cryptotype}}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" (click)="checkCrypto('ETH')">ETH</a>
                            <a class="dropdown-item" (click)="checkCrypto('BTC')">BTC</a>
                            <a class="dropdown-item" (click)="checkCrypto('LTC')">LTC</a>
                            <a class="dropdown-item" (click)="checkCrypto('aaplus')">USDC</a>
                        </div>
                    </div>
                    <br>
                    <p style="font-size: 13px;">Paid Product amount via DUC  : $ {{duc_value_amount_inUSD2  | number:'.1-2'}}</p>
                    <p style="font-size: 13px;">Remining Product amount &nbsp; : $ {{balance_mount  | number:'.1-2'}}  </p>
                    <p style="font-size: 13px;">Shipping Cost &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : $ {{shiping_cost_amount2  | number:'.1-2'}} </p>
                    <p style="font-size: 13px;">Total to pay&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp; &nbsp;  : $ {{balance_mount + shiping_cost_amount2 | number:'.1-2'}} </p>
                    <button class="btn paybtn mt-3" [disabled]="isCryptoBtnDisabled" (click)="cryptopay()">PAY</button>
                </div>
                <!-- <div class="col-lg-5 col-md-6 p-3 paycard disabox"
            [ngClass]="{'disableDiv': isshippingFiatDisabled}">
            <p>Shipping</p>
            <h4 class="payfia">Fiat ({{this.language.mLparams[lang_dync].dollor}}) =
                {{shiping_cost_amount2 | number:'.1-2'}}</h4>
             <br> 
             <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button"
                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    {{fiatType2}}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" (click)="checkfiat1('Paypal')">Paypal</a>
                    <a class="dropdown-item" (click)="checkfiat1('QuantumClearance')">Quantum</a>
                </div> 
            </div> 
            <button class="btn paybtn mt-3" [disabled]="isfiatpay2"
                (click)="fiatRedirect1()">PAY
                <div *ngIf="pay_spinner2" class="spinner-border" role="status"
                    style="width: 20px; height: 20px;">
                    <span class="sr-only">Loading...</span>
                   
                </div>
            </button>

        </div> -->
                <div class="col-lg-6 col-md-12 col-sm-12 p-3 paycard" *ngIf="isrow2Disabled2">
                    <div class="row scanpay" *ngIf="initial">
                        <h5>PAYMENT</h5>
                        <br>

                        <div class="row">
                            <div class="col">
                                <img src="assets/startpayment.svg" class="mt-5" height="250px" width="" alt="payment"
                                    class="center" />
                            </div>
                        </div>
                        <p>Select any one Payment Method to Pay</p>
                    </div>
                    <div class="row scanpay" *ngIf="isducenabled">
                        <div class="row col-lg-12">
                            <div class="col">
                                <p class="text-danger" style="font-size: 12px;">
                                    {{this.language.mLparams[lang_dync].noteTxt}}
                                </p>

                                <p style="margin-left: 25px; margin-top: 0px; font-size: 12px;">
                                    {{this.language.mLparams[lang_dync].scanQRtoMake}} <b>DUC</b>
                                    {{this.language.mLparams[lang_dync].payment}}
                                </p>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <section *ngIf="pend">
                                    <div class="row col-lg-12" style="margin: 20%;">
                                        <div class="col-md-2 col-sm-2">
                                            <div class="spinner-border text-primary" style="text-align: center;"></div>
                                        </div>
                                        <div class="col-md-5 col-sm-2"></div>
                                    </div>
                                </section>
                                <ngx-qrcode *ngIf="!pend" [elementType]="elementType" [value]="wallet">
                                </ngx-qrcode>
                                <p style="font-size: 12px;">
                                    {{this.language.mLparams[lang_dync].sendingOtherDigitalAssetswillResultInPaymentLoss}}
                                </p>
                                <p style="font-size: 12px;">
                                    <b>{{this.language.mLparams[lang_dync].pleaseEnsureThatYouEnteredTheFullAmountof}}
                                        <b class="text-danger">{{Duc_Amount2}}
                                            DUC </b> {{this.language.mLparams[lang_dync].whileMakingthePaymentFromTheApp}}</b>
                                </p>
                                <div class="form-group">
                                    <label for="resource-id">{{this.language.mLparams[lang_dync].wltadrs}}</label>

                                    <div class=" col-12 ">

                                        <div class=" input-group ">
                                            <input id="resource-id" value="{{walletAddress}}" type="text"
                                                class="form-control" name="id" placeholder="Payment Address"
                                                readonly="readonly">
                                            <button class="input-group-text" (click)="clicktocopy()"
                                                [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                        </div>

                                    </div>
                                    <p style="font-size: 12px; margin-top: 10px;"> Please send proof of transfer -
                                        (transaction hash and /or screenshot of the transfer) to
                                        <a href="mailto:support@remusnation.com"><b>support@remusnation.com </b></a>for
                                        faster processing)</p>
                                </div>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="ducPayment"
                                        [checked]="isducChecked2" [disabled]="isContinueDisabled2"
                                        (change)="onducConform2()">
                                    <label class="form-check-label"
                                        for="ducPayment">{{this.language.mLparams[lang_dync].iConfirmThatIhaveMadeFullPaymentOfDUC}}
                                        {{Duc_Amount2}} {{this.language.mLparams[lang_dync].duc}}
                                        {{this.language.mLparams[lang_dync].toTheAboveAddress}}</label>
                                </div>
                                <div class="text-success" *ngIf="isducChecked2">
                                    <p>{{this.language.mLparams[lang_dync].thankyouLine}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row col-lg-12">
                            <div class="col">
                                <!-- <div>
                                    <p class="text-muted display-5 text-left mt-2 mb-1 paragraph"
                                        style="font-size: 11px;">
                                        Kindly confirm us that your payment as been completed
                                    </p>

                                </div> -->
                                <button class="btn btn-success scanbtn" [disabled]="!isducChecked2" (click)="ducConform1()"
                                    style="margin-left: 34px;">Continue</button>
                            </div>
                        </div>
                    </div>
                    <div class="row scanpay" *ngIf="iscryptoenabled">
                        <p class="text-danger" style="font-size: 12px;">{{this.language.mLparams[lang_dync].noteTxt}}
                        </p>

                        <p style="margin-left: 25px; margin-top: 0px; font-size: 12px;">
                            {{this.language.mLparams[lang_dync].scanQRtoMake}} <b>Crypto payment</b>
                            {{this.language.mLparams[lang_dync].payment}}
                        </p>

                        <!-- <p>Crypto payment</p> -->
                        <div class="row">
                            <div class="col">
                                <section *ngIf="pend">
                                    <div class="row" style="margin: 20%;">
                                        <div class="col-md-2 col-sm-2">
                                            <div class="spinner-border text-primary" style="text-align: center;"></div>
                                        </div>
                                        <div class="col-md-5 col-sm-2"></div>
                                    </div>
                                </section>
                                <ngx-qrcode *ngIf="!pend" [elementType]="elementType" [value]="wallet">
                                </ngx-qrcode>
                                <p style="font-size: 12px;">
                                    {{this.language.mLparams[lang_dync].sendingOtherDigitalAssetswillResultInPaymentLoss}}
                                </p>
                                <p style="font-size: 12px;">
                                    <b>{{this.language.mLparams[lang_dync].pleaseEnsureThatYouEnteredTheFullAmountof}}
                                        <b class="text-danger">{{Duc_Amount2}} {{cryptotype}}
                                        </b> {{this.language.mLparams[lang_dync].whileMakingthePaymentFromTheApp}}</b>
                                </p>
                                <!-- <h5>Amount = {{Duc_Amount2}}</h5> -->

                                <div class="form-group">
                                    <label for="resource-id">{{this.language.mLparams[lang_dync].wltadrs}}</label>

                                    <div class="input-group ">
                                        <input id="resource-id" value="{{walletAddress}}" type="text"
                                            class="form-control" name="id" placeholder="Payment Address"
                                            readonly="readonly">
                                        <button class="input-group-text" (click)="clicktocopy()"
                                            [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                    </div>

                                </div>
                                <p style="font-size: 12px; margin-top: 10px;"> Please send proof of transfer -
                                    (transaction hash and /or screenshot of the transfer) to
                                    <a href="mailto:support@remusnation.com"><b>support@remusnation.com </b></a>for
                                    faster processing)</p>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="ducPayment"
                                        [checked]="isducChecked3" [disabled]="isContinueDisabled3"
                                        (change)="onducConform3()">
                                    <label class="form-check-label"
                                        for="ducPayment">{{this.language.mLparams[lang_dync].iConfirmThatIhaveMadeFullPaymentOfDUC}}
                                        {{Duc_Amount2}} {{cryptotype}} {{this.language.mLparams[lang_dync].toTheAboveAddress}}</label>
                                </div>
                                <div class="text-success" *ngIf="isducChecked3">
                                    <p>{{this.language.mLparams[lang_dync].thankyouLinecrypto}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div>
                                    <!-- <p class="text-muted display-5 text-left mt-2 mb-1 paragraph"
                                        style="font-size: 11px; text-align: center;">
                                        Kindly confirm us that your payment as been completed
                                    </p> -->

                                </div>
                                <button class="btn btn-success" [disabled]="!isducChecked3" (click)="cryptoconform(content3_0)">Continue</button>
                            </div>
                        </div>
                    </div>
                    <div class="row scanpay" *ngIf="isfiatenabled">
                        <h5>You Will be Shortly Redirected to Fiat Payment Page</h5>
                    </div>

                </div>
            </div>
            <!-- <div class="col-12 col-sm-12 paymentcard order-1" *ngIf="isrow2Disabled2">
                <div class="row scanpay" *ngIf="initial">
                    <h5>PAYMENT</h5>
                    <br>

                    <div class="row">
                        <div class="col">
                            <img src="assets/startpayment.svg" class="mt-5" height="300px" width="" alt="payment"
                                class="center" />
                        </div>
                    </div>
                    <p>Select any one Payment Method to Pay row 2</p>
                </div>
                <div class="row scanpay" *ngIf="isducenabled">
                    <div class="row col-lg-12">
                        <div class="col">
                            <p style="margin-left: 25px;">Scan to Pay Payment</p>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <section *ngIf="pend">
                                <div class="row col-lg-12" style="margin: 20%;">
                                    <div class="col-md-2 col-sm-2">
                                        <div class="spinner-border text-primary" style="text-align: center;"></div>
                                    </div>
                                    <div class="col-md-5 col-sm-2"></div>
                                </div>
                            </section>
                            <ngx-qrcode *ngIf="!pend" [elementType]="elementType" [value]="wallet">
                            </ngx-qrcode>
                            <h5>Amount = {{Duc_Amount2}} DUC</h5>
                            <div class="form-group">
                                <label for="resource-id">{{this.language.mLparams[lang_dync].wltadrs}}</label>

                                <div class=" col-12 ">
                                    <div class="col-1"></div>
                                    <div class=" input-group col-10">
                                        <input id="resource-id" value="{{walletAddress}}" type="text"
                                            class="form-control" name="id" placeholder="Payment Address"
                                            readonly="readonly">
                                        <button class="input-group-text" (click)="clicktocopy()"
                                            [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                    </div>
                                    <div class="col-1"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row col-lg-12">
                        <div class="col">
                            <div>
                                <p class="text-muted display-5 text-left mt-2 mb-1 paragraph" style="font-size: 11px;">
                                    Kindly confirm us that your payment as been completed
                                </p>

                            </div>
                            <button class="btn btn-success scanbtn" (click)="ducConform1()"
                                style="margin-left: 34px;">Continue</button>
                        </div>
                    </div>
                </div>
                <div class="row scanpay" *ngIf="iscryptoenabled">
                    <p>Crypto payment</p>
                    <div class="row">
                        <div class="col">
                            <section *ngIf="pend">
                                <div class="row" style="margin: 20%;">
                                    <div class="col-md-2 col-sm-2">
                                        <div class="spinner-border text-primary" style="text-align: center;"></div>
                                    </div>
                                    <div class="col-md-5 col-sm-2"></div>
                                </div>
                            </section>
                            <ngx-qrcode *ngIf="!pend" [elementType]="elementType" [value]="wallet">
                            </ngx-qrcode>
                            <h5>Amount = {{Duc_Amount2}}</h5>

                            <div class="form-group">
                                <label for="resource-id">{{this.language.mLparams[lang_dync].wltadrs}}</label>
                                <div class="col-2"></div>
                                <div class="input-group col-8">
                                    <input id="resource-id" value="{{walletAddress}}" type="text" class="form-control"
                                        name="id" placeholder="Payment Address" readonly="readonly">
                                    <button class="input-group-text" (click)="clicktocopy()"
                                        [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                </div>
                                <div class="col-2"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div>
                                <p class="text-muted display-5 text-left mt-2 mb-1 paragraph"
                                    style="font-size: 11px; text-align: center;">
                                    Kindly confirm us that your payment as been completed
                                </p>

                            </div>
                            <button class="btn btn-success" (click)="cryptoconform()">Continue</button>
                        </div>
                    </div>
                </div>
                <div class="row scanpay" *ngIf="isfiatenabled">
                    <h5>You Will be Shortly Redirected to Fiat Payment Page</h5>
                </div>

            </div> -->
        </div>


    </div>
    <div *ngIf="success2">
        <div class="row">


            <div class="col-sm-12 col-lg-6">
                <img src="assets/Green-Tick-Transparent-Background.png" class="images_width">

            </div>
            <div class="col-sm-12 col-lg-6 text-success" style="text-align: center;">
                <h5>Payment  has been completed</h5>
                <p>You will be  redirected Shortly ...</p>
            </div>
        </div>
    </div>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div> -->
</ng-template>
<!-- 
<input name="somename"
    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
    type = "number"
    maxlength = "6"
 /> -->


 <!-- <input type="number"  name="name"  onKeyPress="if(this.value.length==2) return false;"> -->