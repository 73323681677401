<nav class="navbar navbar-expand-md navbar-dark bg-dark bg-dk">
    <div class="container">

        <a *ngIf="!Dshop_footer" class="navbar-brand n-brnd" routerLink="/">
            <img class="logo" src="assets\images\logo\Remus Nation - Logo (new).svg" alt="logo">
        </a>
        <a *ngIf="Dshop_footer" class="navbar-brand n-brnd" routerLink="/">
            <img class="logo" src="assets\images\logo\Remus Nation - Logo (new).svg" alt="logo">
        </a>
    </div>
</nav>
<nav class="navbar-expand-md bg-light navbar-dark bg-lyt">

    <div class="container-fluid">
        <div class="navrow nave-rowline">
            <div class="container">
                <ul class="navbar-nav ">
                    <li>
                        <!-- <a class="nav-link nav-lnk" href="#about">ABOUT US</a> -->
                        <a *ngIf="!Dshop_footer" class="homebutton1" routerLink="/">{{this.language.mLparams[this.lang_dync].hme}}</a>
                        <a *ngIf="Dshop_footer" class="homebutton1" routerLink="/">{{this.language.mLparams[this.lang_dync].hme}}</a>
                    </li>
                    <!-- <li>
                    <a class="h-line pl-2 pl-md-0 ml-0 ml-md-2"></a>
                </li> -->
                    <li class="nav-item pl-2 pl-md-0 ml-0 ml-md-2">
                        <!-- <a class="nav-link nav-lnk nv-lk2" href="#about">CONTACT US</a> -->
                    </li>
                </ul>
            </div>

        </div>

    </div>

</nav>


<div class="row banner">
    <div class="container">
    <div class="text-white" style="font-weight:600; font-size: 2.5rem; top: 86%; position: absolute;">{{this.language.mLparams[this.lang_dync].deliveryline1}}</div>
    </div>
    </div>
<!-- <section class="banner">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
            </div>
        </div>
    </div>
    <div class="container">
        <div class="contents text-center text-md-left">
            <h1 class="text-white" style="margin-top: 34%;font-weight: 600">{{this.language.mLparams[this.lang_dync].deliveryline1}}</h1>
        </div>
    </div>
</section> -->


<section class="connect-section pt-4 pt-md-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">


                <h2 class="cookies-heading">{{this.language.mLparams[this.lang_dync].deliveryline2}}</h2>
                <p class="par"><b>{{this.language.mLparams[this.lang_dync].lstupdt}}</b></p>

                <ol type="1">
                    <li class="cooky">{{this.language.mLparams[this.lang_dync].deliveryline3}}<a href="mailto:myorder@remusnation.com">myorder@remusnation.com</a> {{this.language.mLparams[this.lang_dync].deliveryline4}}</li>


                    <li class="cooky">{{this.language.mLparams[this.lang_dync].deliveryline5}}</li>


                    <li class="cooky">{{this.language.mLparams[this.lang_dync].deliveryline6}}</li>


                    <li class="cooky">{{this.language.mLparams[this.lang_dync].deliveryline7}} <a href="mailto:support@remusnation.com">suport@remusnation.com</a></li>

                    <li class="cooky">{{this.language.mLparams[this.lang_dync].deliveryline8}}</li>

                    <li class="cooky">{{this.language.mLparams[this.lang_dync].deliveryline9}}</li>

                    <li class="cooky">{{this.language.mLparams[this.lang_dync].deliveryline10}}</li>

                    <li class="cooky">{{this.language.mLparams[this.lang_dync].deliveryline11}} <br></li>

                </ol>

                <p class="par"><b>{{this.language.mLparams[this.lang_dync].deliveryline12}}</b></p>





                <h2 class="cookies-heading">{{this.language.mLparams[this.lang_dync].deliveryline13}}</h2>

                <ol type="1">
                    <li class="cooky">{{this.language.mLparams[this.lang_dync].deliveryline14}} <a href="mailto:myorder@remusnation.com">myorder@remusnation.com</a> {{this.language.mLparams[this.lang_dync].deliveryline15}}
                    </li>


                    <li class="cooky">{{this.language.mLparams[this.lang_dync].deliveryline16}}</li>


                    <li class="cooky">{{this.language.mLparams[this.lang_dync].deliveryline17}} </li>

                </ol>


                <p class="par"><b>{{this.language.mLparams[this.lang_dync].deliveryline18}}</b></p>

            </div>
        </div>
    </div>
</section>
<app-footer *ngIf="Dshop_footer"></app-footer>