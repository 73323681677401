import { Component, OnInit, ɵConsole } from '@angular/core';
import { FormControl, FormGroup, Validator, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../backend/login.service';
import { MustMatch } from '../_helpers/must-match.validator';
import { Request } from '../_service/index';
import { APIs_Config, LocalstorageService } from '../sys_config'
import { LanguageService } from '../config/language';
import { ShareResource } from 'src/app/_service/interface'

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss'],
  providers: [LanguageService]
})
export class ResetpasswordComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    private logservice: LoginService,
    private router: Router,
    private request: Request,
    public config: APIs_Config,
    public userStorage: LocalstorageService,
    public language: LanguageService,
    private share: ShareResource,

  ) { }


  isotptrue = false;
  passwordChange = true;
  // passwordChanges  = true;
  setpasswordFrom: any;
  submitted = false;
  email_id: any;
  email_otp: any;
  changepassword: any;
  otp_check: any;
  email_check: any;
  invalid_otp: any = false;
  email_notexists: any = false;
  lang_dync = this.userStorage.current_language;
  timerOn: any;
  remaining: any;
  successmsg: any;
  isResendDisabled = true;
  forgotpasswordFrom = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })

  get email() { return this.forgotpasswordFrom.get('email') }

  forgotpwdotpForm = new FormGroup({
    otp: new FormControl('', [Validators.required, Validators.minLength(6)])
  })

  get otp() { return this.forgotpwdotpForm.get('otp') }




  ngOnInit(): void {
    this.setpasswordFrom = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$')]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }


  get st() { return this.setpasswordFrom.controls; }

  async onSubmitForgotemail(data) {

    if (data == 1) {
      this.email_id = localStorage.getItem('t_email');
    } else {
      this.email_id = data.email;
    }

    var email_data = {
      "payload": {
        "email": this.email_id
      }
    }

    var temp = this.request.get_structure('forget_password', 'format');
    temp.email = this.email_id;

    this.email_check = await this.request.post(1, 'forget_password', temp);
    console.log(this.email_check);

    if (this.email_check[0] == 200) {
      localStorage.setItem("t_email", this.email_id)
      var success = this.email_check[1].response.data
      if (data != 1) {
        this.isotptrue = !this.isotptrue;
      }

      // this.callmycount()
      // setTimeout(() => {
      this.isResendDisabled = false;
      // }, 100);
    }
    else if (this.email_check[0] == 400) {

      if (this.email_check[1].response.error == "error_email_not_send") {
        this.email_notexists = 'Email failed to send';
        this.email_check = this.email_check[1].response.error;//'Invalid OTP';
      }
      else if (this.email_check[1].response.error == "email_does_not_exsists") {
        this.email_notexists = this.language.mLparams[this.lang_dync].email_not_exists;
        this.email_check = this.email_check[1].response.error;//'Invalid OTP';
      }
      else {
        console.log(this.email_check);
        this.email_notexists = this.language.mLparams[this.lang_dync].email_not_exists;
        this.email_check = this.email_check[1].response.error;//'Invalid OTP';
      }

    }
    else {
      console.log(this.email_check);

      this.email_notexists = this.language.mLparams[this.lang_dync].email_not_exists;
      this.email_check = this.email_check.error.response.error;//'Invalid OTP';
    }
  }

  get lo() { return this.setpasswordFrom.controls; }

  async onSubmitForgototp(data) {

    this.email_otp = data.otp;
    var temp = this.request.get_structure('send_otp', 'format');
    temp.email = this.email_id;
    temp.otp = this.email_otp;

    this.otp_check = await this.request.post(1, 'send_otp', temp);
    console.log(this.otp_check);


    if (this.otp_check[0] == 200) {
      var success_otp = this.otp_check[1].response.data;
      this.passwordChange = !this.passwordChange;
      var userData = {
        token: this.otp_check[1].response.data,
      }
      this.userStorage.setUserinfo(userData['token']);
      console.log(userData,"temp");
    }
    else if (this.otp_check[0] = 400) {
      if (this.otp_check[1].response.error == "invalid_otp") {
        this.invalid_otp = this.language.mLparams[this.lang_dync].invalidotp;
      }
      else {
        this.invalid_otp = this.language.mLparams[this.lang_dync].invalidotp;
      }
    }
    else {
      this.invalid_otp = this.language.mLparams[this.lang_dync].invalidotp;
    }
  }

  async onSubmitForgotpwd(data) {

    this.submitted = true;

    // stop here if form is invalid
    if (this.setpasswordFrom.invalid) {
      return;
    }


    // display form values on success
    var password_data = {
      "payload": {
        "email": this.email_id,
        "otp": this.email_otp,
        "new_password": this.setpasswordFrom.value.password
      }

    }
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.setpasswordFrom.value, null, 4));
    var temp = this.request.get_structure('reset_pwd', 'format');
    temp.email = this.email_id;
    temp.otp = this.email_otp;
    temp.new_password = this.setpasswordFrom.value.password;
    this.changepassword = await this.request.post(1, 'reset_pwd', temp);
    console.log(this.changepassword);
    // this.changepassword = await this.logservice.get_api(password_data, 'bov3001/98313');
    if (this.changepassword[0] == 200) {
      this.successmsg = "Your Password is Successfully reset. You would be logged out now. Please login again with the new password.";
      var update_success = this.changepassword[1].response.data;
      if (update_success) {
        this.userStorage.removeUserAllInfo();
        setTimeout(() => {
          this.router.navigate(['/']);
          this.successmsg = '';
        }, 3000);
      }

    }
    else {
      var password_failed = 'Password failed to update';
    }

  }
}
