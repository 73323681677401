<nav class="navbar navbar-expand-md navbar-dark bg-dark bg-dk nave-cont">
    <div class="container">

        <a *ngIf="!Dshop_footer" class="navbar-brand n-brnd" routerLink="/">
            <img class="logo" src="assets\images\logo\Remus Nation - Logo (new).svg" alt="logo">
        </a>
        <a *ngIf="Dshop_footer" class="navbar-brand n-brnd" routerLink="/">
            <img class="logo" src="assets\images\logo\Remus Nation - Logo (new).svg" alt="logo">
        </a>
    </div>
</nav>
<nav class="navbar-expand-md bg-light navbar-dark bg-lyt">

    <div class="container-fluid">
        <div class="navrow nave-rowline">
            <div class="container">
                <ul class="navbar-nav ">
                    <li>
                        <!-- <a class="nav-link nav-lnk" href="#about">ABOUT US</a> -->
                        <a *ngIf="!Dshop_footer" class="homebutton1" routerLink="/">{{this.language.mLparams[this.lang_dync].hme}}</a>
                        <a *ngIf="Dshop_footer" class="homebutton1" routerLink="/">{{this.language.mLparams[this.lang_dync].hme}}</a>
                    </li>
                    <!-- <li>
                    <a class="h-line pl-2 pl-md-0 ml-0 ml-md-2"></a>
                </li> -->
                    <li class="nav-item pl-2 pl-md-0 ml-0 ml-md-2">
                        <!-- <a class="nav-link nav-lnk nv-lk2" href="#about">CONTACT US</a> -->
                    </li>
                </ul>
            </div>

        </div>

    </div>

</nav>
<!-- <div class="container"> -->

    <div class="row banner">
        <div class="container">
        <div class="text-white" style="font-weight:600; font-size: 2.5rem; top: 86%; position: absolute;">{{this.language.mLparams[this.lang_dync].cookieline1}}</div>
            <!-- <img src="assets/dshop-black.png" class="img-fluid" alt=""> --> 
            <!-- <h2>{{this.language.mLparams[lang_dync].bannerTxt}}</h2> -->
            <!-- <p>{{this.language.mLparams[lang_dync].bannerSubTxt}}</p> -->
        </div>
    <!-- </div> -->
    </div>


<!-- <section class="banner">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
            </div>
        </div>
    </div>
    <div class="container">
        <div class="contents text-center text-md-left">
            <h1 class="" style="margin-top: 34%;font-weight:600;">{{this.language.mLparams[this.lang_dync].cookieline1}}</h1>
        </div>
    </div>
</section> -->


<section class="connect-section pt-4 pt-md-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="cookies-heading">{{this.language.mLparams[this.lang_dync].cookieline1}}</h2>

                <p class="par"><b>{{this.language.mLparams[this.lang_dync].lstupdt}}</b></p>

                <p class="par"> {{this.language.mLparams[this.lang_dync].cookieline4}}</p>

                <p class="par"> {{this.language.mLparams[this.lang_dync].cookieline5}}</p>


                <h1 class="cookies-bold">{{this.language.mLparams[this.lang_dync].cookieline6}}</h1>


                <h2 class="cookies-heading">{{this.language.mLparams[this.lang_dync].cookieline7}}</h2>

                <p class="par">{{this.language.mLparams[this.lang_dync].cookieline8}} </p>

                <p class="par">{{this.language.mLparams[this.lang_dync].cookieline9}}</p>


                <h2 class="cookies-heading">{{this.language.mLparams[this.lang_dync].cookieline10}}</h2>

                <p class="par">{{this.language.mLparams[this.lang_dync].cookieline11}} </p>

                <ol type="a">
                    <li class="cooky"><b>{{this.language.mLparams[this.lang_dync].cookieline12}} </b>{{this.language.mLparams[this.lang_dync].cookieline13}}</li>
                    <li class="cooky"><b>{{this.language.mLparams[this.lang_dync].cookieline14}}</b> {{this.language.mLparams[this.lang_dync].cookieline15}} </li>
                    <li class="cooky"><b>{{this.language.mLparams[this.lang_dync].cookieline16}}</b> {{this.language.mLparams[this.lang_dync].cookieline17}}</li>
                    <li class="cooky"><b>{{this.language.mLparams[this.lang_dync].cookieline18}}</b> {{this.language.mLparams[this.lang_dync].cookieline19}} <a href="https://www.remusnation.com">https://www.remusnation.com</a></li>
                </ol>



                <h1 class="cookies-bold">{{this.language.mLparams[this.lang_dync].cookieline20}}</h1>

                <h2 class="cookies-heading">{{this.language.mLparams[this.lang_dync].cookieline21}}</h2>

                <p class="par">{{this.language.mLparams[this.lang_dync].cookieline22}}</p>

                <p class="par"><b>{{this.language.mLparams[this.lang_dync].cookieline23}}</b></p>

                <h3 class="sub-text">{{this.language.mLparams[this.lang_dync].cookieline24}}</h3>

                <p class="par"><b>{{this.language.mLparams[this.lang_dync].cookieline25}}</b> {{this.language.mLparams[this.lang_dync].cookieline26}}</p>
                <p class="par"><b>{{this.language.mLparams[this.lang_dync].cookieline27}}</b> {{this.language.mLparams[this.lang_dync].cookieline28}}</p>
                <p class="par"><b>{{this.language.mLparams[this.lang_dync].cookieline29}}</b> {{this.language.mLparams[this.lang_dync].cookieline30}}</p>

                <h3 class="sub-text">{{this.language.mLparams[this.lang_dync].cookieline33}}</h3>

                <p class="par"><b>{{this.language.mLparams[this.lang_dync].cookieline25}}</b> {{this.language.mLparams[this.lang_dync].cookieline31}}</p>
                <p class="par"><b>{{this.language.mLparams[this.lang_dync].cookieline27}}</b> {{this.language.mLparams[this.lang_dync].cookieline28}}</p>
                <p class="par"><b>{{this.language.mLparams[this.lang_dync].cookieline29}}</b> {{this.language.mLparams[this.lang_dync].cookieline32}}</p>



                <h1 class="cookies-bold">{{this.language.mLparams[this.lang_dync].cookieline34}}</h1>

                <p class="par">{{this.language.mLparams[this.lang_dync].cookieline35}}</p>
                <p class="par">{{this.language.mLparams[this.lang_dync].cookieline36}}</p>
                <p class="par">{{this.language.mLparams[this.lang_dync].cookieline37}}</p>



                <ol type="a">
                    <li class="cooky">{{this.language.mLparams[this.lang_dync].cookieline38}}
                        <a href="https://support.google.com/accounts/answer/32050">{{this.language.mLparams[this.lang_dync].cookieline39}}</a> </li>


                    <li class="cooky">{{this.language.mLparams[this.lang_dync].cookieline40}}
                        <a href="http://support.microsoft.com/kb/278835">{{this.language.mLparams[this.lang_dync].cookieline39}}</a> </li>

                    <li class="cooky">{{this.language.mLparams[this.lang_dync].cookieline41}}
                        <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">{{this.language.mLparams[this.lang_dync].cookieline39}}</a> </li>

                    <li class="cooky">{{this.language.mLparams[this.lang_dync].cookieline42}}
                        <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">{{this.language.mLparams[this.lang_dync].cookieline39}}</a> </li>
                </ol>


                <p class="par">{{this.language.mLparams[this.lang_dync].cookieline43}}</p>

                <h2 class="cookies-heading">{{this.language.mLparams[this.lang_dync].cookieline44}}</h2>

                <p class="par">{{this.language.mLparams[this.lang_dync].cookieline45}}</p>

                <ol type="a">
                    <li class="cooky">{{this.language.mLparams[this.lang_dync].cookieline46}} <a href="https://www.networkadvertising.org/">https://www.networkadvertising.org/</a></li>
                </ol>
                <h2 class="cookies-heading">{{this.language.mLparams[this.lang_dync].cookieline47}}</h2>

                <p class="par">{{this.language.mLparams[this.lang_dync].cookieline48}}</p>

                
                <ol type="a">
                    <li class="cooky">{{this.language.mLparams[this.lang_dync].cookieline49}} <a href="mailto:contact@remusnation.com">contact@remusnation.com</a></li>
                </ol>

            </div>
        </div>
    </div>
</section>
<app-footer *ngIf="Dshop_footer"></app-footer>