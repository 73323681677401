import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ShareResource } from 'src/app/_service/interface';
import { LanguageService } from '../../config/language/index';
import { Request } from '../../_service/request'
import { LocalstorageService } from '../../sys_config/localstorage.service';

@Component({
  selector: 'app-pp-payment',
  templateUrl: './pp-payment.component.html',
  styleUrls: ['./pp-payment.component.scss'],
  providers: [LanguageService]
})
export class PpPaymentComponent implements OnInit {

  constructor(
    private activeRoute: ActivatedRoute,
    private request: Request,
    private userStorage: LocalstorageService,
    public language: LanguageService,
    private router: Router,

  ) { }
  token = "";
  referenceId = "";
  payment_id = "";
  status = "";
  payerID = "";
  pp_success = '';
  pp_failed = '';
  pp_pending = '';
  paypal_cancel = '';
  paypal_fail = '';
  pending = true;
  payment_gateway: any;
  lang_dync = this.userStorage.current_language;
  pay_status: any;
  shippingCost: any;
  prdamount: any;
  total: any;
  invoice_id: any;
  parcialpay = false
  fiatpay: any;
  status_name:any;
  
  ngOnInit() {
    localStorage.setItem('dbuy_tag', "false");
    console.log(this.router.routerState.snapshot.url)
    var str = this.router.routerState.snapshot.url;
    var res = str.split("=");
    console.log(res[1],"|||||||||||")
  


    this.activeRoute.params.subscribe(data => {
      console.log(data.refId,"LLLLLLLLLLLLL");
      this.sample(data.refId)
      var pay_method = localStorage.getItem("pay_type1")
      if(data.refId != "cancel"){
        
        this.referenceId = data.refId;
        console.log("LLKKKKK")

      }else if(res[1]){ 
        this.referenceId = res[1];
        var res2 = res[0].split("/")
        var res3 = res2[2].split("?")
        console.log(res3,"|+++")
        this.status_name = res3[0]
        if(res3[0] == "cancel"){
          this.status_name = "cancelled"
        }

      }


      
      console.log(this.referenceId);
      this.status_update();

      console.log("11 >>",localStorage.getItem('pay_type1'))
      console.log("11 >>",localStorage.getItem('pay_type2'))
      let type1 = localStorage.getItem('pay_type1')
      let type2 = localStorage.getItem('pay_type2')
      console.log(type1, type2);

      if(type1 == "Paypal" || type1 == "quantumClearance") {
        this.fiatpay = true;
      }
      if(type1 == "paypal_parcialpayment_method1") {
        this.parcialpay = true;
      }
      // if(type1 == "DUC" || type1 == "Crypto") {

      // }

     else if(type1 == "DUC" && type2 == "Paypal" || type1 == "DUC" && type2 == "quantumClearance"){

        this.fiatpay = false;
      }

      else if(type1 == "Crypto" && type2 == "Paypal" || type1 == "Crypto" && type2 == "quantumClearance"){

        this.fiatpay = false;
      }
      // localStorage.removeItem('pay_type1');
      // localStorage.removeItem('pay_type2');

      // if (type2 == "paypal" || type2 == "quantumClearance") {
      //   console.log(">>", true);
      //   this.fiatpay = true;
      // } else if (type1 == "DUC" || type1 == "Crypto") {
      //   console.log(">>", false);
      //   this.fiatpay = false;
      // } else if (type1 == "Paypal" || type1 == "quantumClearance") {
      //   this.fiatpay = true;
      // } else {
      //   console.log("error")
      // }
    });
    this.getCostDetails();
  }

  async sample(id){
    let temp = this.request.get_structure('quantum_credit_card', 'format');
    temp.data.id = id
    let data = await this.request.post(1, 'quantum_credit_card', temp);
    console.log(data,"koko")
  }
  parcial_amount:any;
  parcial_ref_id:any;
  async getCostDetails() {
    let temp = this.request.get_structure('get_single_order', 'format');
    temp.invoice_id = this.userStorage.getUserInfo('tid').toString();
    console.log(temp);
    let data = await this.request.post(1, 'get_single_order', temp);
    console.log(data);
    this.shippingCost = data[1].response.data.shipment_cost;
    this.prdamount = data[1].response.data.amount_payed;
    this.total = data[1].response.data.total;
    this.invoice_id = data[1].response.data.invoice_id;
    this.parcial_amount = data[1].response.data.payments[0].amount;
    this.parcial_ref_id = data[1].response.data.payments[0].ref_id
    console.log(")))))))")
  }

  parcial_amount2:any
  total_parcialpay:any

  async status_update() {
    let temp = this.request.get_structure('get_payment_status', 'format');
    // temp.product_id = 2;
    // temp.payment_id = this.payment_id;
    // temp.payment_id=5;
    temp.referenceId = this.referenceId;
    temp.status = this.status_name;
    // temp.payer_id = this.payerID;
    // temp.token = this.token;
    // temp.trans_id = this.userStorage.getUserInfo('trans_id')
    console.log(temp);
    let status = await this.request.post(1, 'get_payment_status', temp);
    console.log(status);
    this.pay_status = status[1]['response']['data']['paymentDetails'];
    this.total_parcialpay =  this.pay_status.payment_amount;
    console.log(this.pay_status,"PPPPPPPPPPP")
    // this.userStorage.removeUserInfo('cart_value');
    // this.userStorage.removeUserInfo('cart_count');
    if (status[0] == 200) {
      if (status[1].response.data.paymentDetails.status == "success") {
        this.pp_success = this.language.mLparams[this.lang_dync].paypal_success;
        this.pending = false;
        temp.trans_id = this.userStorage.removeUserInfo('tid');
      } else if (status[1].response.data.paymentDetails.status == "pending") {
        this.pp_pending = this.language.mLparams[this.lang_dync].paypal_pending;
        this.pending = false;
        this.payment_gateway = status[1].response.data.paymentDetails.payment_gateway
        // temp.trans_id = this.userStorage.removeUserInfo('tid');
      } else if (status[1].response.data.paymentDetails.status == "cancelled") {
        this.parcial_amount2 = status[1].response.data.paymentDetails.amount;
        this.parcial_ref_id = status[1].response.data.paymentDetails.ref_id;
        this.paypal_cancel = this.language.mLparams[this.lang_dync].paypal_cancelled;
        this.pending = false;
        this.payment_gateway = status[1].response.data.paymentDetails.payment_gateway
        // temp.trans_id = this.userStorage.removeUserInfo('tid');
        console.log("}}}}}}}}")
      } else if (status[1].response.data.paymentDetails.status == "failed") {
        this.paypal_fail = this.language.mLparams[this.lang_dync].paypal_failed;
        this.pending = false;
        this.payment_gateway = status[1].response.data.paymentDetails.payment_gateway
        // temp.trans_id = this.userStorage.removeUserInfo('tid');
      }
    }
    else if (status[0] == 400) {
      console.log('status');
      this.pending = false;
      this.pp_failed = this.language.mLparams[this.lang_dync].paypal_failed;

    }
  }

  async cancelRetry() {
    let temp = this.request.get_structure('get_single_order', 'format');
    console.log(temp)
    temp.invoice_id = this.pay_status.purchase_id.toString();

    console.log(temp);
    let data = await this.request.post(1, 'get_single_order', temp);
    console.log(data);
    console.log(this.pay_status,":")
    let value = data[1].response.data
    this.userStorage.updateUserInfo('tid', value.purchase_id);
    this.userStorage.updateUserInfo('tc', value.amount_payed);
    this.userStorage.updateUserInfo('sc', value.shipment_cost);
    if (this.pay_status.payment_flag == "both") {
      console.log('both');
      this.userStorage.updateUserInfo('tid', this.pay_status.purchase_id);
      this.router.navigate(['/payment'])
    } else if (this.pay_status.payment_flag == "shipment") {
      this.userStorage.updateUserInfo('tid', this.pay_status.purchase_id);
      console.log("shippment");
      localStorage.setItem('pay_type', 'shipment')
      this.router.navigate(['/repayment'])
    } else if (this.pay_status.payment_flag == "payment") {
      this.userStorage.updateUserInfo('tid', this.pay_status.purchase_id);
      console.log("shippment");
      localStorage.setItem('pay_type', 'payment')
      this.router.navigate(['/repayment'])
    }else if (this.pay_status.payment_flag == "payment_2") {
      this.userStorage.updateUserInfo('tid', this.pay_status.purchase_id);
      console.log("shippment");
      localStorage.setItem('pay_type', 'payment_2')
      this.router.navigate(['/repayment'])
    }
  }
}
