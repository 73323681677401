<div class="container product" *ngIf="product_numbers">
    <div class="row prod" >
        <!-- <div class="col-1"></div> -->
        <div class="col-lg-2 col-md-2 col-sm-12 spacing producthover" *ngFor="let cat of arr1">
            <!-- class="tags many" data-awards="" -->
            <div (click)="singlepage(cat)">
                <img src="{{server_url}}{{cat.image_url}}" class="img-fluid samesize" alt="product">
                <div class="cat" placement="bottom" ngbTooltip="{{cat.product_name}}" tooltipClass="my-custom-class">
                    {{cat.product_name}}</div>
                <p class="price">$ {{cat.new_price}}</p>
            <!-- </div> -->
            <ngb-rating [max]="5" [(rate)]="prd_star" [readonly]="true"></ngb-rating>
            <div class="btn btn-primary pl-buynow" (click)="singlepage(cat)">

                {{this.language.mLparams[this.lang_dync].vew}}</div>
        </div>
        <!-- <div class="col-1"></div> -->
    </div>
    <div class="container">
    <div class="row prod">
        <!-- <div class="col-1"></div> -->
        <div class="col-lg-2 col-md-2 col-sm-12 spacing producthover" *ngFor="let cat of arr2">
            <!-- class="tags many" data-awards="" -->
            <div (click)="singlepage(cat)">
                <img src="{{server_url}}{{cat.image_url}}" class="img-fluid samesize" alt="product">
                <div class="cat" placement="bottom" ngbTooltip="{{cat.product_name}}" tooltipClass="my-custom-class">
                    {{cat.product_name}}</div>
                <p class="price">$ {{cat.new_price}}</p>
            </div>
            <ngb-rating [max]="5" [(rate)]="prd_star" [readonly]="true"></ngb-rating>
            <div class="btn btn-primary pl-buynow" (click)="singlepage(cat)">

                {{this.language.mLparams[this.lang_dync].vew}}</div>
            <!-- <div class="col-1"></div> -->
        </div>
    </div>
</div>
</div>
<!-- <div>
    <button (click)="get_voucher()">voucher</button>
</div> -->