import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesService} from '../_service/services.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit {

  constructor(
    private activeRoute: ActivatedRoute ,
    private router:Router,
    private service:ServicesService,
  ) { }
  Dshop_footer:boolean;

  ngOnInit(): void {
    // document.body.style.cursor = "url(http://bringerp.free.fr/Files/RotMG/cursor.gif), pointer"
    // const src = 'http://bringerp.free.fr/Files/RotMG/cursor.gif';
// const docStyle = document.body.style;
// document.querySelector('img').addEventListener('click', () => {
//   if (!docStyle.cursor) docStyle.cursor = `url('${src}'), default`;
//   else docStyle.cursor = null;
// });

    this.Dshop_footer = true;
    console.log(this.router.routerState.snapshot.url)
    if(this.router.routerState.snapshot.url == "/deliverypolicy"){
      this.Dshop_footer = false;
    }
    else if(this.router.routerState.snapshot.url == "/cookies"){
      this.Dshop_footer = false;
    }
    else if(this.router.routerState.snapshot.url == "/legal"){
      this.Dshop_footer = false;
    }
    else{ 
      this.Dshop_footer = true;
    }
  }
  get_me(){
    this.service.give_cat("jjjjjjjjjjjjjjjj")
    
  }

  onActivate(event) {
    // window.scroll(0,0);
    document.body.scrollTop = 0;
  
    if(this.router.routerState.snapshot.url == "/deliverypolicy"){
      this.Dshop_footer = false;
      console.log("11111")
    }
    else if(this.router.routerState.snapshot.url == "/cookies"){
      this.Dshop_footer = false;
    }
    else if(this.router.routerState.snapshot.url == "/legal"){
      this.Dshop_footer = false;
    }else{
      console.log("2222")
      this.Dshop_footer = true;
    }
 
}
}
