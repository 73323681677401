import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LanguageService } from 'src/app/config/language';
import { APIs_Config, LocalstorageService } from 'src/app/sys_config';
import { Request } from 'src/app/_service';

import { CatagoriesId } from './catagoriesID.componenet';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  providers: [LanguageService]
})
export class CategoriesComponent implements OnInit {



  @Output()
  IdEmitter = new EventEmitter<any>();

  @Output()
  NameEmitter = new EventEmitter<any>();

  @Input()
  add_cat:string;

  constructor(

    private request: Request,
    public config: APIs_Config,
    public language: LanguageService,
    public userStorage: LocalstorageService,
  ) { }


  allcategories: any[];

  lang_dync = this.userStorage.current_language;
  catregories: any;
  Finalcatregories: any;
  category_name = "";

  ngOnInit(): void {
    this.getCategories()
    console.log(this.add_cat)
  }

  endcatregories = true;

  async getCategories() {
    let { format } = this.config.cfg.category_list;
    format.product_id = this.config.site_id;
    console.log(format);
    let temp = await this.request.post(1, 'category_list', format);
    console.log(temp + 'Add log partner request')

    this.catregories = temp[1].response.data


    console.log(this.catregories)
  }

  addCategories = 'Click the category';
  addCategories_id = "null";



  Category_1(i, date) {
    console.log(i)
    console.log(i.category_id)
    this.addCategories = i.category_name;
    this.addCategories_id = i.category_id;
    console.log(this.addCategories + "this.addCategories")
    this.catregories = i.array_to_json
    if (i.array_to_json == null) {
      this.endcatregories = true
      this.IdEmitter.emit(i.category_id);
      this.NameEmitter.emit(i.category_name);
      this.Finalcatregories = i.category_name
      console.log(this.Finalcatregories + "ended")

    }
    else if (i.array_to_json[0].array_to_json == null) {
      this.endcatregories = false
      console.log("ended123")
    }
    else if (i[1].array_to_json[0].array_to_json == null) {
      this.endcatregories = false
      console.log("ended123")
    }

  }


  refresh() {
    this.ngOnInit();

    this.addCategories = 'Click the category';
  }
  image_url= "";

   async add_category(){
    
    console.log(this.category_name)
    console.log(this.addCategories_id)
    
    if(this.addCategories_id == 'null'){
      var master_id = "0"
    }else {
      master_id = this.addCategories_id;
    }
    console.log(master_id);
    let { format } = this.config.cfg.add_category;
    
    format.master_category_id = parseInt(master_id);
    format.system_product_id = this.config.site_id;
    // format.master_category_id = 0;
    format.category_name = this.category_name;
    format.image_url    = this.image_url;
    console.log(format);
    let temp = await this.request.post(1, 'add_category', format);
    console.log(temp)
    this.category_name = '';
  }

  add_category_but = false
  
  add_category_button(){
    this.add_category_but = !this.add_category_but;

  }


  uploadthefile(event) {
    var selectedFile = <File>event.target.files[0];
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.onload = function () { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsDataURL(selectedFile);

    });
  }

  image1 :any;


   async images_needed(event){
    if(event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg" ){
   
      var image = event.target.files[0]
     
      console.log(image)
  
      var name = event.target.files[0].name
  
      this.image1 = await this.uploadthefile(event)
      console.log(this.image1)
      var encoded = this.image1.split(',');
      
      console.log(encoded + ' this.encoded')
      var fileencoded = encoded[1];
      console.log(fileencoded + '  this.fileencoded')



      let { format } = this.config.cfg.file_upload;
      format.file_name = 'name123';
      format.image_name = 'side view123654';
      format.product_name = this.category_name;
      format.file_base64 = fileencoded;
      format.is_main = true
      console.log(format)
      let temp = await this.request.post(1, 'file_upload', format);
      console.log(temp)
      console.log(temp[1].response.data.file_path)
      this.image_url = temp[1].response.data.file_path
      
    }
  }
}
