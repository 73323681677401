<div class="container mt-5 mb-5"
    style="align-items: center; border: 2px solid #f3f3f3; background-color: white; border-radius: 5px;">
    <div class="row">
        <div class="col-md-8 order-sm-1 order-2">
            <div class="row">
                <div class="col-md-12">
                    <div class="row p-4" style="text-align: center;">
                        <label><input type="radio" (click)="type1()" name="payment" id="paytype1"
                                [checked]="!isrow1Disabled"></label>
                        <div class="col-md-6 p-3 paycard" [ngClass]="{'disableDiv': isrow1Disabled}">
                            <p>Pay the product cost in DUC(%)</p>
                            <h4>DUC - 0</h4>
                            <!-- <br> -->
                            <form [formGroup]="ducvalue" (ngSubmit)="ducclick()">
                                <div class="form-group mb-3">
                                    <input required formControlName="ducpercent" class="form-control textbox"
                                        type="number" name="ducpercent"
                                        [ngClass]="{ 'is-invalid': logsubmitted && url.ducpercent.errors }" autofocus
                                        placeholder="Enter DUC">
                                    <div *ngIf="logsubmitted1 && url.ducpercent.errors">
                                        <div *ngIf="url.ducpercent.errors.required" class="text-danger">
                                            % is Required
                                        </div>
                                        <div *ngIf="url.ducpercent.errors.pattern" class="text-danger">
                                            Invalid Duc %
                                        </div>
                                    </div>
                                    <button *ngIf="spinner1" class="btn paybtn mt-3" type="submit"
                                        [disabled]="!ducvalue.valid">Check
                                        value</button>
                                    <div *ngIf="!spinner1" class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </form>
                            <button class="btn paybtn" (click)="ducPay1()">PAY</button>
                            <div class="row mb-n4 scanpay">
                                <p class="text-muted">(Upto 50% max)</p>
                            </div>
                        </div>
                        <div class="col-md-5 p-3 paycard" [ngClass]="{'disableDiv': isFiatDisabled}">
                            <p>Product + Shipping</p>
                            <h4>Fiat - 0</h4>
                            <!-- <br> -->
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{fiatType}}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="checkfiat('Paypal')">Paypal</a>
                                    <a class="dropdown-item" (click)="checkfiat('Quantum')">Quantum</a>
                                </div>
                            </div>
                            <button class="btn paybtn mt-3" [disabled]="isfiatbtndisabled"
                                (click)="fiatRedirect()">PAY</button>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="col-md-12">
                    <div class="row p-4" style="text-align: center;">
                        <div class="checkbox">
                            <label><input type="radio" (click)="type2()" class="mb-1" name="payment"
                                    id="paytype2"></label>
                        </div>
                        <div class="col-md-6 p-3 paycard" [ngClass]="{'disableDiv': isrow2Disabled}">
                            <p>Pay product cost in DUC (%)</p>
                            <h4>DUC - 0</h4>
                            <!-- <br> -->
                            <form [formGroup]="ducvalue2" (ngSubmit)="ducClick()">
                                <div class="form-group mb-3">
                                    <input required formControlName="ducpercent2" type="number"
                                        class="form-control textbox" placeholder="Enter DUC"
                                        [ngClass]="{ 'is-invalid': logsubmitted1 && url1.ducpercent2.errors }"
                                        autofocus>
                                    <div *ngIf="logsubmitted1 && url1.ducpercent2.errors">
                                        <div *ngIf="url1.ducpercent2.errors.required" class="text-danger">
                                            % is Required
                                        </div>
                                        <div *ngIf="url1.ducpercent2.errors.pattern" class="text-danger">
                                            Invalid Duc %
                                        </div>
                                    </div>
                                    <button *ngIf="spinner3" class="btn paybtn mt-3" type="submit"
                                        [disabled]="!ducvalue2.valid">Check
                                        Value</button>
                                    <div *ngIf="!spinner3" class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </form>
                            <button class="btn paybtn" (click)="ducPay2()">PAY</button>
                            <div class="row mb-n4 scanpay">
                                <p class="text-muted">(Upto 50% max)</p>
                            </div>
                        </div>
                        <!-- <p>(Upto 50% max)</p> -->
                        <div class="col-md-5 p-3 paycard" [ngClass]="{'disableDiv': isCryptoDisabled}">
                            <p>Select Crypto method</p>
                            <h4>Crypto</h4>
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{cryptotype}}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="checkCrypto('ETH')">ETH</a>
                                    <a class="dropdown-item" (click)="checkCrypto('BTC')">BTC</a>
                                    <a class="dropdown-item" (click)="checkCrypto('LTC')">LTC</a>
                                    <a class="dropdown-item" (click)="checkCrypto('USDC')">USDC</a>
                                </div>
                            </div>
                            <button class="btn paybtn mt-3" [disabled]="isCryptoBtnDisabled"
                                (click)="cryptopay()">PAY</button>
                        </div>
                        <div class="col-md-6 p-3 paycard" [ngClass]="{'disableDiv': isshippingFiatDisabled}" style="margin-left: 17px;">
                            <p>Shipping</p>
                            <h4>Fiat - 0</h4>
                            <!-- <br> -->
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{fiatType2}}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="checkfiat1('Paypal')">Paypal</a>
                                    <a class="dropdown-item" (click)="checkfiat1('Quantum')">Quantum</a>
                                </div>
                            </div>
                            <button class="btn paybtn mt-3" [disabled]="isfiatpay2"
                                (click)="fiatRedirect1()">PAY</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-12  paymentcard order-1" *ngIf="!isrow1Disabled">
            <div class="row scanpay" *ngIf="initial">
                <h5>PAYMENT</h5>
                <div class="row ">
                    <div class="col-md-12 col-lg-12 col-sm-12 ">
                        <img src="assets/startpayment.svg" class="mt-5" height="300px" width="" alt="payment" class="center" />
                    </div>
                </div>
                <p>Select any one Payment Method to Pay</p>
            </div>
            <div class="row scanpay" *ngIf="isducenabled">
                <div class="row col-lg-12">
                    <div class=" col-md-12 col-lg-12 ">
                        <p style="margin-left: 25px;">Scan to Pay Payment</p>

                    </div>
                   
                </div>
                
                
                <div class="row">
                    <div class="col-lg-12">
                        <section *ngIf="pending">
                            <div class="row" style="margin: 20%;">
                                <div class="col-md-2 col-sm-2">
                                    <div class="spinner-border text-primary" style="text-align: center;"></div>
                                </div>
                                <div class="col-md-5 col-sm-2"></div>
                            </div>
                        </section>
                        <ngx-qrcode *ngIf="!pending" [elementType]="elementType" [value]="walletAddress">
                        </ngx-qrcode>
                    </div>
                </div>
                <div class="row col-lg-12">
                    <div class="col">
                        <button class="btn btn-success scanbtn" (click)="ducConform()" style="margin-left: 34px;">Continue</button></div>
                </div>
            </div>
            <div class="row scanpay" *ngIf="isfiatenabled">
                <h5>You Will be Shortly Redirected to Fiat Payment Page</h5>
            </div>
        </div>
        <div class="col-md-4 col-sm-12 paymentcard order-1" *ngIf="!isrow2Disabled">
            <div class="row scanpay" *ngIf="initial">
                <h5>PAYMENT</h5>
                
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 ">
                        <img src="assets/startpayment.svg" class="mt-5" height="300px" width="" alt="payment" class="center" />
                    </div>
                </div>
                <p>Select any one Payment Method to Pay row 2</p>
            </div>
            <div class="row scanpay" *ngIf="isducenabled">
                <div class="row col-lg-12">
                    <div class="col">
                        <p style="margin-left: 25px;">Scan to Pay Payment</p>

                    </div>
                </div>
                
                <div class="row">
                    <div class="col">
                        <section *ngIf="pending">
                            <div class="row col-lg-12" style="margin: 20%;">
                                <div class="col-md-2 col-sm-2">
                                    <div class="spinner-border text-primary" style="text-align: center;"></div>
                                </div>
                                <div class="col-md-5 col-sm-2"></div>
                            </div>
                        </section>
                        <ngx-qrcode *ngIf="!pending" [elementType]="elementType" [value]="walletAddress">
                        </ngx-qrcode>
                    </div>
                </div>
                <div class="row col-lg-12">
                    <div class="col">
                        <button class="btn btn-success scanbtn" (click)="ducConform1()" style="margin-left: 34px;">Continue</button></div>
                </div>
            </div>
            <div class="row scanpay" *ngIf="iscryptoenabled">
                <p>Crypto payment</p>
                <div class="row">
                    <div class="col">
                        <section *ngIf="pending">
                            <div class="row" style="margin: 20%;">
                                <div class="col-md-2 col-sm-2">
                                    <div class="spinner-border text-primary" style="text-align: center;"></div>
                                </div>
                                <div class="col-md-5 col-sm-2"></div>
                            </div>
                        </section>
                        <ngx-qrcode *ngIf="!pending" [elementType]="elementType" [value]="walletAddress">
                        </ngx-qrcode>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button class="btn btn-success" (click)="cryptoconform()">Continue</button>
                    </div>
                </div>
            </div>
            <div class="row scanpay" *ngIf="isfiatenabled">
                <h5>You Will be Shortly Redirected to Fiat Payment Page</h5>
            </div>
        </div>
    </div>
</div>

