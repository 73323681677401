<div class="container">
  <div class="row saleproduct" *ngIf="sub_cat != ''">
    <div class="col text-muted" style="font-size: small;">
      {{main_cat}} > {{sub_cat}}
    </div>
  </div>
  <div class="row saleproduct">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div>
        <!-- class="item promoted" data-awards="" -->

        <div id="product" class="carousel slide" data-interval="false">

          <div class="carousel-inner" role="listbox">
            <div class="carousel-item active" *ngFor="let item of product?.products.images; let index = index"
              [class.active]="index == 0">
              <div class="zoom">
                <img src="{{server_url}}{{item.image_url}}" alt="" class="img-fluid samesizesingle">
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#product" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#product" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div id="thumbCarousel" *ngFor="let item of product?.products.images;let index = index">
              <div data-target="#product" [attr.data-slide-to]="index" class="thumb active">
                <img src="{{server_url}}{{item.image_url}}" alt="XZ" style="width: 50px; height: 50px">
              </div>
            </div>


          </div>

        </div>

      </div>

    </div>

    <div class="col-sm-6">

      <div class="desc-amt">
        <p class="product_description">
          {{product?.products.description }}
        </p>
        <div class="dolar">
          <h3> {{product?.products.product_name | titlecase}} <span> </span></h3>
          <span class=d-ll>$ {{product?.products.new_price}} </span>
          <span class=d-l2 *ngIf="get_condition"><s>$ {{product?.products.price}}</s> </span><br>
          <!-- <section>
                      <label for="star-1">
            <svg width="255" height="240" viewBox="0 0 51 48">
              <path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z" />
            </svg>
          </label>
                      <label for="star-2">
            <svg width="255" height="240" viewBox="0 0 51 48">
              <path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z" />
            </svg>
          </label>
                      <label for="star-3">
            <svg width="255" height="240" viewBox="0 0 51 48">
              <path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z" />
            </svg>
          </label>
                      <label for="star-4">
            <svg width="255" height="240" viewBox="0 0 51 48">
              <path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z" />
            </svg>
          </label>
                      <label for="star-5">
            <svg width="255" height="240" viewBox="0 0 51 48">
              <path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z" />
            </svg>
          </label>
                  </section> -->

        </div>




        <div class="checkbox">
          <div class="row quan">
            <div class="col-sm-4 pd-i">
              <input type="checkbox" class="i-p c-b" id="Unchecked" [checked]="product?.products.qty_left"
                [disabled]="true">&nbsp;&nbsp;
              <p *ngIf="product?.products.qty_left">{{this.language.mLparams[this.lang_dync].instock}}</p>
              <div *ngIf="!product?.products.qty_left">
                <p class="text-danger" *ngIf="!out_of_stack">
                  <b>{{this.language.mLparams[this.lang_dync].outfstock}}</b>
                </p>
                <div class="spinner-border" role="status" *ngIf="out_of_stack">
                  <span class="visually-hidden"></span>
                </div>

              </div>

            </div>
          </div>
        </div>


        <ngb-rating [max]="5" [(rate)]="currentRate" [readonly]="true"></ngb-rating>
        <div class="quantity">
          <div class="row">
            <div class="col-6 q-wb">
              <h6 class="qty">{{this.language.mLparams[this.lang_dync].quantity}}</h6>&nbsp;&nbsp;
              <!-- <button class="inc" (click)="decrement()" [disabled]='is_disable'>-</button> -->
              <img src="assets/Remove.svg" alt="" class="" (click)="decrement()" width="25px" height="25px"
                style="margin: 5px; cursor: pointer;">
              <span>{{counter}}</span>
              <!-- <button class="dec" [disabled]='is_disable' (click)="increment()">+</button> -->
              <img src="assets/Add.svg" alt="" class="" (click)="increment()" width="25px" height="25px"
                style="margin: 5px; cursor: pointer;">


            </div>
          </div>
        </div>
        <div *ngIf="tag == 1">
          <div *ngFor="let item of all_variants | keyvalue">
            <div class="set" *ngIf="all_variants[item.key]">
              <span>{{item.key | titlecase}}:</span>
              <ul class="size-list" *ngFor="let vari of all_variants[item.key];">
                <li><button class="set_tag_{{item.key }} btn" id="{{item.key}}_{{vari}}"
                    [ngClass]="checkFilter(item.key,vari)" (click)="updateVariant(item.key,vari)">{{vari }}</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p *ngIf="!product?.products.qty_left" class=""><b>{{this.language.mLparams[this.lang_dync].note}}</b>
          {{this.language.mLparams[this.lang_dync].choseodrvrint}}
        </p>
        <!-- <ul class="colors-list" *ngFor="let color of all_variants;">
          <li><a (click)="updateVariant('color',color)">{{color | json}}</a></li>            
        </ul> -->
        <!-- <div class="block-select clearfix">
        <form>
          <div class="select-color">           
            <select class="color">                                  
                  <option *ngFor="let item of product.types">                   
                    {{Colour.typ}}                                       
                  </option>             
            </select>
          </div>
          <div class="select-size">
            <span>Size</span>
            <select class="size">
              <option>8</option>

            </select>
          </div>
        </form>
      </div> -->

        <br>
        <br>
        <br>

        <div class="row buttons">
          <div class="col-12">
            <div class="row">
              <div class="col-6">
                <button class="btn  ac" (click)="buyNow(product.product_id)"
                  [disabled]='!out_of_stack2'>{{this.language.mLparams[this.lang_dync].bynow}}</button>
              </div>
              <div class="col-6">
                <button class="btn  btn-lg bn" (click)="addToCart(product)"
                  [disabled]='!out_of_stack2 '>{{this.language.mLparams[this.lang_dync].add2crt}}</button>
                <!-- cart_count_more -->
              </div>

              <div class="col-12 note" style="margin-top: 25px;" *ngIf="notes">
                <div  *ngIf="notes != '' ">
                  <p><b>Note&nbsp;:</b> &nbsp;{{notes}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <br>
      <br>
      <br>
      <div class="row sucesscart">
        <ngb-toast *ngIf="show" [delay]="1500">
          <div style="text-align:center;">{{this.language.mLparams[this.lang_dync].addsuccsfly}}</div>
        </ngb-toast>
      </div>

    </div>
  </div>
  <!--review-->
  <div class="row tab_product mb-3">
    <div class="col-12">
      <div class="tab">
        <button class="tablinks {{buttons[0]}}"
          (click)="open_tab(0)">{{this.language.mLparams[this.lang_dync].descptn}}</button>
        <!-- onclick="openCity(event, 'Discription')" -->
        <button class="tablinks {{buttons[1]}}"
          (click)="open_tab(1)">{{this.language.mLparams[this.lang_dync].spectn}}</button>
        <!-- onclick="openCity(event, 'Specification')" -->
        <button *ngIf="is_login" class="tablinks {{buttons[2]}}" (click)="open_tab(2)">Q&A</button>
        <!-- onclick="openCity(event, 'Q&A')" -->

        <!-- <button class="tablinks {{buttons[3]}}" (click)="open_tab(3)">Reviews</button> -->
        <!-- onclick="openCity(event, 'Reviews')" -->
      </div>

      <div id="Discription" class="tabcontent" [style.display]="tabs[0]" style="color:black;">
        <!-- <span>{{product?.products.description}}</span>
              <div style="height: 20px;"></div> -->
        <table class="table specification ">
          <tbody>
            <tr>
              <td>
                <span>{{product?.products.description}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div id="Specification" class="tabcontent" [style.display]="tabs[1]" style="color:black;">
        <table class="table specification">
          <tbody>
            <tr>
              <td>
                <span *ngIf="product?.products.bullet_pts[0]">{{product?.products.bullet_pts[0]}}</span>
                <span *ngIf="product?.products.bullet_pts[1]">{{product?.products.bullet_pts[1]}}</span>
                <span *ngIf="product?.products.bullet_pts[2]">{{product?.products.bullet_pts[2]}}</span>
              </td>
            </tr>
            <tr *ngIf="all_variants.color">
              <td>{{this.language.mLparams[this.lang_dync].clr}}</td>
              <td>{{all_variants.color}}</td>
            </tr>
            <tr *ngIf="all_variants.size">
              <td>{{this.language.mLparams[this.lang_dync].size}}</td>
              <td> {{all_variants.size}} </td>

            </tr>
          </tbody>
        </table>
      </div>

      <div id="Q&A" class="tabcontent" [style.display]="tabs[2]" *ngIf="is_login">
        <form class="single_product_qa" [formGroup]="faqForm" (ngSubmit)="faqSubmit()">
          <h4>{{this.language.mLparams[this.lang_dync].cstmrqn}}</h4>
          <div class="row d-flex ">
            <div class="col-md-4">
              <label class="sr-only">{{this.language.mLparams[this.lang_dync].yreview}}</label>
              <input required formControlName="faqinput" class="form-control mb-2" placeholder="Write your question"
                [ngClass]="{ 'is-invalid': faqsubmitted && faq.faqinput.errors }">
              <div *ngIf="faq.faqinput.invalid && (faq.faqinput.dirty || faq.faqinput.touched)">
                <div *ngIf="faq.faqinput.errors.required" class="text-danger">
                  {{this.language.mLparams[this.lang_dync].faqerr}}
                </div>
                <div *ngIf="faq.faqinput.errors.minlength" class="text-danger">
                  {{this.language.mLparams[this.lang_dync].msthve6char}}
                </div>
              </div>
            </div>
            <div class="col-auto">
              <button type="submit"
                class="btn btn-primary mb-2">{{this.language.mLparams[this.lang_dync].sbmt}}</button>
            </div>
          </div>
        </form>
        <p class="text-success">{{faqMsg}}</p>
        <div class="row" *ngFor="let faq of qanda">
          <div class="col">
            <h6>
              Q: {{faq.question}}
            </h6>
            <p>A: {{faq.answer}}</p>
          </div>
        </div>
      </div>


      <!-- <div id="Reviews" class="tabcontent" [style.display]="tabs[3]">
        <form *ngIf="can_add_review" [formGroup]="reviewForm"
          class="col-lg-12 col-md-12 col-sm-12 single_product_review" (ngSubmit)="submitReview()">
          <h4>{{this.language.mLparams[this.lang_dync].cstmrrvew}}</h4>
          <div class="form-row ">
            <div class="col-lg-12 col-md-12 col-sm-12" style=" margin-left: -5px; margin-top: 8px;">
              <ngb-rating style="outline:none;" [max]="5" [(rate)]="reviewRate" [readonly]="false"
                (rateChange)="getstar(reviewRate)"></ngb-rating>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12" [ngClass]="{'disableDiv': isreviewdisabled}">
              <label class="sr-only">{{this.language.mLparams[this.lang_dync].yreview}}</label>
              <textarea required class="text-area col-lg-12 col-md-12 col-sm-12" cols="" rows="5"
                placeholder="Write your review" formControlName="textArea"></textarea>
              <div *ngIf="logsubmitted && url.textArea.errors">
                <div *ngIf="url.textArea.errors.required" class="text-danger">
                  % is Required
                </div>
                <div *ngIf="url.textArea.errors.maxlength" class="text-danger">
                  Max Length is Exceeded
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <button type="submit" [disabled]="!reviewForm.valid"
                class="btn btn-primary mt-2 mb-2">{{this.language.mLparams[this.lang_dync].sbmt}}</button>
            </div>
          </div>
        </form>
        <p *ngIf="reviewSubmitSuccess" class="text-success">Your Review is submitted succesfully</p>

        <div *ngIf="!reviewdata">No Review</div>
        <div class="col-lg-12 col-md-12 col-sm-12" *ngFor="let data of reviewdata">
          <ngb-rating style="outline:none;" [max]="5" [(rate)]="data.stars" [readonly]="true"></ngb-rating>
          <p>{{data.review}}</p>
        </div>
      </div> -->
    </div>
  </div>



  <p style="margin-top:3vh; margin-bottom: 0px">{{this.language.mLparams[this.lang_dync].rltdprdct}}</p>
  <div class="prod-ct"></div>

  <div class="" style="align-items: center; margin-top: 33px;">
    <div class="row product prod">
      <div class="col-lg-2 col-md-2 col-sm-12 spacing producthover" *ngFor="let cat of related_products">
        <div (click)="singlepage(cat)">
          <img src="{{server_url}}{{cat.image_url}}" class="img-fluid samesize" alt="product">
          <div class="cat tabdevice" placement="bottom" ngbTooltip="{{cat.product_name}}"
            tooltipClass="my-custom-class">{{cat.product_name}}</div>
          <p class="price">$ {{cat.new_price}}</p>
        </div>
        <ngb-rating [max]="5" [(rate)]="prd_star" [readonly]="true"></ngb-rating>
        <br>
        <div class="btn btn-primary pl-buynow" (click)="singlepage(cat)">{{this.language.mLparams[this.lang_dync].vew}}
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="prod-img">
  <div class="row">

    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row">
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block  w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block  w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block  w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block  w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block  w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block  w-100" alt="...">
            </div>
            <div class="col-sm-2">
              <img src="assets/ny.jpg" class="d-block w-100" alt="...">
            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>


    <div class="col-sm-2" *ngFor="let item of reponse_data.response.data.related_products">
      <img src="{{item.img}}" class="pict">
      <div class="pc-p">
        <h4>{{item.product_name}}</h4>
        <h6 class="f-zs">{{item.price}}</h6>
       <section>
        <label for="star-1">
          <svg width="255" height="240" viewBox="0 0 51 48">
            <path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z" />
          </svg>
        </label>
        <label for="star-2">
          <svg width="255" height="240" viewBox="0 0 51 48">
            <path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z" />
          </svg>
        </label>
        <label for="star-3">
          <svg width="255" height="240" viewBox="0 0 51 48">
            <path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z" />
          </svg>
        </label>
        <label for="star-4">
          <svg width="255" height="240" viewBox="0 0 51 48">
            <path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z" />
          </svg>
        </label>
        <label for="star-5">
          <svg width="255" height="240" viewBox="0 0 51 48">
            <path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z" />
          </svg>
        </label>
      </section> 
      </div>
    </div>

  </div>
</div>-->




  <!---->


</div>