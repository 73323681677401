import { Component, OnInit } from '@angular/core';
import { FormGroup, Validator, FormBuilder, Validators, Form } from '@angular/forms'

@Component({
  selector: 'app-partpayment',
  templateUrl: './partpayment.component.html',
  styleUrls: ['./partpayment.component.scss']
})
export class PartpaymentComponent implements OnInit {

  constructor(private formBuilder : FormBuilder,) { }

  spinner1 = true;
  spinner2 = true;
  spinner3 = true;
  isrow1Disabled = false;
  isrow2Disabled = true;
  isrow3Disabled = true;
  isFiatDisabled = true;
  isCryptoDisabled = true;
  isshippingFiatDisabled = true;
  fiatType = "Select Payment"
  cryptotype = "Select Crypto"
  walletAddress = "1234567890";
  pending = false;
  initial = true;
  isducenabled = false;
  isfiatenabled = false;
  isfiatbtndisabled = true;
  isCryptoBtnDisabled = true;
  iscryptoenabled = false;
  isfiatpay2 = true;
  ducValue:any;
  ducvalue: FormGroup;
  ducvalue2: FormGroup;
  logsubmitted = false;
  logsubmitted1 = false;
  fiatType2 = "Select Payment"
  ngOnInit(): void {

    this.ducvalue = this.formBuilder.group({
      ducpercent : ['',[Validators.required]]
    })

    this.ducvalue2 = this.formBuilder.group({
      ducpercent2 : ['',[Validators.required]]
    })
  }

  get url() { return this.ducvalue.controls; }
  get url1() { return this.ducvalue2.controls; }

  initialstate() {
    this.isrow1Disabled = false;
    this.isrow2Disabled = true;
    this.isrow3Disabled = true;
    this.isFiatDisabled = true;
    this.isCryptoDisabled = true;
    this.isshippingFiatDisabled = true;
    this.initial = true;
    this.isducenabled = false;
    this.isfiatenabled = false;
    this.fiatType2 = "Select Payment"
    this.fiatType = "Select Payment"
    this.cryptotype = "Select Crypto"
    this.ducvalue = this.formBuilder.group({
      ducpercent : ['',[Validators.required]]
    })

    this.ducvalue2 = this.formBuilder.group({
      ducpercent2 : ['',[Validators.required]]
    })
  }

  ducclick() {
    // console.log(data);
    // if(data <= 50){
    //   console.log("valid");
    // }else if(data > 50){
    //   console.log("invalid");
    // }else if(data == undefined || null){
    //   console.log("no data")
    // }
    this.logsubmitted = true;
    this.spinner1 = false;
    setTimeout(() => {
      this.spinner1 = true;
    }, 2000);
    if(this.ducvalue.invalid){
      return;
    }else{
      console.log(this.ducvalue.value);
    }
  }

  // fiatClick() {
  //   this.spinner2 = false;
  //   setTimeout(() => {
  //     this.spinner2 = true;
  //   }, 2000);
  // }

  ducClick() {
    this.spinner3 = false;
    setTimeout(() => {
      this.spinner3 = true;
    }, 2000);
    this.logsubmitted1 = true;
    if(this.ducvalue2.invalid){
      return;
    }else{
      console.log(this.ducvalue2.value);
    }
  }

  type1() {
    if (this.isrow1Disabled == true) {
      this.isrow1Disabled = false;
      this.isrow2Disabled = true;

      this.initialstate()
    }
  }

  type2() {
    if (this.isrow2Disabled == true) {
      this.isrow2Disabled = false;
      this.isrow1Disabled = true;
      this.isrow3Disabled = true;
      this.isFiatDisabled = true;
      this.isCryptoDisabled = true;
      this.isshippingFiatDisabled = true;
      this.initial = true;
      this.isducenabled = false;
      this.isfiatenabled = false;
      this.fiatType2 = "Select Payment"
      this.fiatType = "Select Payment"
      this.cryptotype = "Select Crypto"
          this.ducvalue = this.formBuilder.group({
      ducpercent : ['',[Validators.required]]
    })

    this.ducvalue2 = this.formBuilder.group({
      ducpercent2 : ['',[Validators.required]]
    })
    }
  }

  ducPay1() {
    if (this.isFiatDisabled == true) {
      this.initial = false;
      this.pending = true;
      this.isducenabled = true;
      setTimeout(() => {
        this.pending = false;
      }, 500);
    }
  }

  ducConform() {
    this.isFiatDisabled = false;
  }

  ducConform1() {
    this.isCryptoDisabled = false;
  }

  cryptoconform() {
    this.isshippingFiatDisabled = false;
  }

  ducPay2() {
    if (this.isCryptoDisabled == true) {
      // this.isCryptoDisabled = false;
      this.initial = false;
      this.pending = true;
      this.isducenabled = true;
      setTimeout(() => {
        this.pending = false;
      }, 500);
    }
  }

  cryptopay() {
    if (this.isCryptoDisabled == false) {
      this.isducenabled = false;
      this.iscryptoenabled = true;
    }
  }

  checkfiat(data) {
    console.log(data);
    this.fiatType = data
    if (data == "Paypal" || "Quantum") {
      console.log('called')
      this.isfiatbtndisabled = false;
    }
  }

  checkfiat1(data) {
    console.log(data);
    this.fiatType2 = data;
    if (data !== "") {
      this.isfiatpay2 = false;
    }
  }

  fiatRedirect() {
    this.isducenabled = false;
    this.isfiatenabled = true;
    setTimeout(() => {
      this.initialstate()
    }, 2000);
  }

  fiatRedirect1() {
    console.log("called")
    this.iscryptoenabled = false;
    this.isfiatenabled = true;
    setTimeout(() => {
      this.initialstate()
    }, 2000);
  }

  checkCrypto(data) {
    console.log(data)
    this.cryptotype = data
    if (data != "") {
      this.isCryptoBtnDisabled = false;
    }
  }
}
