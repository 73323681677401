<div class="container-fluid static_banner">

    <div class="row banner background">
        <div class="container">
        <div class="static_banner_content">
            <img src="assets/dshop-black.png" class="img-fluid" alt="">
            <h2>{{this.language.mLparams[lang_dync].bannerTxt}}</h2>
            <p>{{this.language.mLparams[lang_dync].bannerSubTxt}}</p>
        </div>
    </div>
    </div>

</div>


<app-categorylist [categorylimit]="categorylimit" *ngIf="cat_site_id"></app-categorylist>
<app-productlist [productlimit]="productlimit" *ngIf="prd_site_id"></app-productlist>
<div class="container product capduc">
    <div class=" home-banner">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-cap">
                    <h4>{{this.language.mLparams[lang_dync].ducatusCap}}</h4>
                    <a class="btn btn-primary primec" (click)="singlepage()">{{this.language.mLparams[lang_dync].shopNow}}</a>
                </div>
            </div>
        </div>
    </div>
</div>