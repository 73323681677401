<div class="container">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 spacing">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" class="text-center ">{{this.language.mLparams[this.lang_dync].hashsymbol}}</th>
                        <th scope="col" class="text-left ">{{this.language.mLparams[this.lang_dync].productname}}</th>

                        <th scope="col" class="text-left ">{{this.language.mLparams[this.lang_dync].productdesc}}</th>
                        <th scope="col" class="text-center ">{{this.language.mLparams[this.lang_dync].categorie}}</th>
                        <th scope="col" class="text-center ">{{this.language.mLparams[this.lang_dync].stat}}</th>
                    </tr>
                </thead>

                <tbody>

                    <tr *ngFor='let product of allProduct; index as i'>

                        <td scope="col" class="text-center "> {{product?.product_id}}</td>
                        <td scope="col"> {{product?.product_name | titlecase}}</td>
                        <td scope="col"> {{product?.description | slice:0:100 }}</td>
                        <td scope="col" class="text-center ">{{product?.category_name | titlecase}}</td>
                        <td scope="col" class="text-center " *ngIf="product?.admin_approved" style="color: rgb(88, 88, 245);"><b>{{
                                language.mLparams[lang_dync].addProduct.Approved
                             }}</b></td>
                        <td scope="col" class="text-center " *ngIf="!product?.admin_approved" style="color: rgb(250, 149, 149)"><b>{{
                                language.mLparams[lang_dync].addProduct.Pending
                            }}</b></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>