<div *ngIf="!pending">
    <div class="container">
        <div *ngIf="is_invalid" class="alert alert-warning alert-dismissible fade show" role="alert">
            <strong>{{language.mLparams[this.lang_dync].attention}}! </strong>
            {{language.mLparams[this.lang_dync].addresrequired}}

        </div>
    
        <div class="alert alert-danger" *ngIf="is_validdirect" style="margin-top: 10px;">
            <strong>{{this.language.mLparams[this.lang_dync].eror}} </strong> &nbsp;{{this.language.mLparams[this.lang_dync].somethingwrong}}
        </div>

        <br>
        <div class="container" style="align-items: center; background-color:#fff;">

            <div class="row no-gutters" *ngIf="!noAddress">

                <div class="col-md-9 col-sm-12 col-lg-5 addressCard m-2 p-2 wrapTxtWithinDiv"
                    *ngFor="let f1 of addressList">
                    <label style="padding: 0 10px 0 20px">
                        <!-- <address> -->
                        <!-- <input type="radio"  [checked]="f1.is_main"  name="f1.address_id" [(ngModel)]="addressModeStatus" [value]="f1.address_id"
                            (click)="addressChange(f1.address_id)"
                            style="margin-right: 5px;"><b>{{language.mLparams[this.lang_dync].deliveraddress}}</b> -->

                        <input type="radio" [checked]="f1.is_main" name="f1.address_id" [value]="f1.address_id"
                            (click)="addressChange(f1.address_id)"
                            style="margin-right: 5px;" ><b>{{language.mLparams[this.lang_dync].deliveraddress}}</b>

                        <br><br>
                        <p class="m-1 p-0" *ngIf="f1.is_main " style="color:gray;"><b>Default location ✓</b><br></p>

                        <p class="m-1 p-0"><b>{{f1.address_name | titlecase}}</b></p>
                        <p class="m-1 p-0">{{f1.address_phone}}</p>
                        <p class="m-1 p-0"> {{f1.address_line1}}</p>
                        <p class="m-1 p-0" *ngIf="f1.address_line2"> {{f1.address_line2}}
                        <p class="m-1 p-0"> {{f1.city}}
                        <p class="m-1 p-0"> {{f1.region}}
                        <p class="m-1 p-0"> {{f1.country}}
                        <p class="m-1 p-0"> {{f1.postal_code}}
                            <br>



                            <!-- <b>{{f1.address_name}}</b><br>
                        {{f1.address_line1}}<br>
                        {{f1.address_line2}}<br>
                        {{f1.city}}<br>
                        {{f1.region}}<br>
                        {{f1.country}}<br>
                        {{f1.postal_code}}<br> -->
                            <!-- </address> -->
                            <!-- <input type="radio" name="f1.address_id" [(ngModel)]="addressModeStatus" [value]="f1.address_id"
                    (click)="addressChange(f1.address_id)"><b>{{language.mLparams[this.lang_dync].deliveraddress}}</b> -->
                    </label>
                </div>

            </div>
            <div>
                <div class="container" style="align-items: center;" *ngIf="noAddress">
                    <h3>{{this.language.mLparams[this.lang_dync].noaddrssfound}}</h3>
                </div>
                <div class="col-md-3">
                    <div class="container mt-5" style="padding: 4rem; margin-left: 2rem;" *ngIf="AddressAddBtn">
                        <a id="#add_address"><button class="btn cartplusbtn" data-toggle="tooltip"
                                data-placement="top" title="{{language.mLparams[this.lang_dync].addDeliveryAddress}}"
                                (click)="onAddaddress()">
                                <i class="fa fa-plus buyplus"></i></button></a>
                    </div>
                </div>
            </div>

            <!--  <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-2 col-sm-4">
            <div *ngIf="AddressAddBtn" class="container" style="align-items: center;">
                <button class="btn btn-primary" (click)="onAddaddress()">Add Address</button>
            </div>
        </div>
       <div class="col-md-2 col-sm-4">
            <section *ngIf="pending">
                <div class="row" style="margin: 20%;">
                    <div class="col-md-5 col-sm-2"></div>
                    <div class="col-md-2 col-sm-2">
                        <div class="spinner-border text-primary" style="text-align: center;"></div>
                    </div>
                    <div class="col-md-5 col-sm-2"></div>
                </div>
            </section>
            <div class="container" style="align-items: center;">
                <button class="btn btn-success checkbtn" [disabled]="is_disabled" *ngIf="!pending"
                    (click)="proceedtocheckout()">Proceed to checkout</button>
            </div>
        </div>


    </div> -->
            <div class="container">
                <div class="row float-right no-gutters" *ngIf="!AddressAddBtn">
                    <button type="button" class="close" (click)="onAddaddress()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!-- <div class="row "> -->

                <!-- </div> -->
                <div  *ngIf="!AddressAddBtn" class="pb-5" id="add_address">
                    <app-add-address (addrEmitter)="addAddress($event)" [showMobile]="true" [formTitle]="'Add Address'">
                    </app-add-address>
                </div>
            </div>
            <div [ngClass]="{'disableDiv': !addressModeStatus_is_true}">
            <div class="col-md-12 col-sm-12">
                <div class="form-check">
                    <label class="form-check-label" for="ducPayment">
                        <input type="checkbox" class="form-check-input" id="ducPayment"
                           (change)="dandt($event)" [checked]="is_dandt_checked" [disabled]="is_dandt_checked">
                        <b><p>{{this.language.mLparams[lang_dync].argmntmsg1}}</p></b>
    
                    </label>
                    <b><p style="font-size: small;">{{this.language.mLparams[lang_dync].argmntmsg2}}<button class="termcndclr" (click)="open(tandcpayment)" data-toggle="modal" data-target="#tandcpayment" >{{this.language.mLparams[lang_dync].argmntmsg4}}</button>{{this.language.mLparams[lang_dync].argmntmsg5}}</p></b>
                    
                </div>
            </div>

            <div class="col-md-12 text-right">
                <button [disabled]="is_proceedEnabled" type="button" class="btn loginregbtn" style="margin: 1rem;"
                    (click)="proceedtocheckout(content_to_notdel_direct)">{{this.language.mLparams[this.lang_dync].proceedTOCheckout}}</button>
            </div>
        </div>

        </div>

    </div>
</div>
    <div style="margin-bottom: 200px;"></div>


    <section *ngIf="pending">
        <div class="row" style="margin: 20%;">
            <div class="col-md-5 col-sm-2"></div>
            <strong class="text-primary" style="text-align: left; padding-top: 5px;">Please Wait You Will Be Redirected Shortly...</strong>
            <div class="col-md-2 col-sm-2">              
                <div class="spinner-border text-primary" style="text-align: center;"></div>
            </div>
            <div class="col-md-5 col-sm-2"></div>
        </div>
    </section>


    <!---------------------------terms and condition for payent model start---------------------------->
<ng-template #tandcpayment let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" ngbAutofocus >Terms and Condition</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
          <div>
            <b><p >{{this.language.mLparams[lang_dync].argmntmsg3}}</p></b>
          </div>
      </form>
    </div>
  </ng-template>
<!---------------------------terms and condition for payent model end---------------------------->

<ng-template #content_to_notdel_direct let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delivery Terms</h4>
        <a type="button" class="close" aria-label="Close" (click) ="off_pending(content_to_notdel_direct)" >
            <span aria-hidden="true">&times;</span>
        </a>
    
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <!-- <div style="height: 100px;"> </div> -->
                <div class="row">
                    <div class="col">
                        <img src="assets/Not deliverable location.png" height="300px">
                    </div>
                </div>
                <label for="dateOfBirth">
                    <h3>! Sorry</h3>
                    <!-- <h5>This product is not deliveriable to your location, Please change your loaction</h5> -->
                    <h6>Delivery for the  Product is not available to your selected location</h6>
                                    </label>
                <!-- <div class="input-group">
            <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button"></button>
            </div>
          </div> -->




            </div>
        </form>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div> -->
<!--     
        Go Back
    </button> -->
</ng-template>