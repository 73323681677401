import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AddAddressFormData } from 'src/app/models/addAddrFormData';
// import { AddAddressFormParams } from 'src/app/models/addAddrFormParams';
import { LanguageService } from '../../config/language';
import { Request } from '../../_service/request';
import { APIs_Config } from '../../sys_config/api_path_config';
import { LocalstorageService } from '../../sys_config/localstorage.service';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
  providers: [LanguageService],
})
export class AddAddressComponent implements OnInit {
  lang_dync = this.userStorage.current_language;

  @Input()
  showMobile: boolean;

  @Input()
  showDelivery:boolean;

  @Input()
  formTitle: string;

  @Output()
  addrEmitter = new EventEmitter<AddAddressFormData>();

  // formData: AddAddressFormData;

  selCountry = [];
  selState = [];
  formData = {};
  countryList: any;
  stateList: any;
  formInfo: any;

  constructor(public language: LanguageService, private request: Request,private userStorage: LocalstorageService, ) { }
  ngOnInit(): void {

    console.log("add address initiated")
   
    this.formInfo = {
      name: '',
      mobile: '',
      addr1: '',
      addr2: '',
      country: '',
      state: '',
      city: '',
      zip: '',
      isNameErr: false,
      isMobileErr: false,
      isAddr1Err: false,
      isCountryErr: false,
      isStateErr: false,
      isCityErr: false,
      isZipErr: false,
      isSaveBtnEnabled: false,
    };

    // this.showMobile = false;
    // this.formTitle = 'Add Warehouse address';
    this.initialCountry();

  }

  async initialCountry() {
    console.log("initialcCountry")
    let temp = this.request.get_structure('country_api', 'format');
    // temp.product_id = 4;
    temp.option=2;
    temp.find="null";
    let countries = await this.request.post(1, 'country_api', temp);
    console.log(countries, "countries");
    if (countries[0] == 200) {
      this.countryList = countries[1].response.data.sort((a, b) => {
        return this.sortStringInJson(a, b, 'country_name');
      });
    }
  }

  sortStringInJson(a, b, key) {
    let nameA = a[key].toUpperCase();
    let nameB = b[key].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // if this line is seen, names must be equal
    return 0;
  }

  getSelCountry = (countryName) => {
    return this.countryList.filter((data) => {
      if (data.country_name.toUpperCase() === countryName.toUpperCase())
        return data;
    });
  };

  getSelState = (stateName) => {
    return this.stateList.filter((data) => {
      if (data.regions.toUpperCase() === stateName.toUpperCase()) return data;
    });
  };

  async onCountrySelect(e) {
    if (e.target.value) {
      // console.log(e.target.value);
      let temp = this.request.get_structure('country_api', 'format');
      temp.option = 3;
      temp.find = e.target.value;

      this.selCountry = this.getSelCountry(e.target.value);
      if (this.selCountry.length !== 0) { this.formInfo.isCountryErr = false; }
      else this.formInfo.isCountryErr = true;
      // console.log('selected Country', this.selCountry);

      let states = await this.request.post(1, 'country_api', temp);
      // console.log(states);
      if (states[0] === 200) {
        this.stateList = states[1].response.data.sort((a, b) => {
          return this.sortStringInJson(a, b, 'regions');
        });
      }
    }
  }

  onStateSelect(e) {
    if (e.target.value) {
      this.selState = this.getSelState(e.target.value);
      if (this.selState.length !== 0)
        this.formInfo.isStateErr = false;
      else this.formInfo.isStateErr = true;
      // console.log('selected State', this.selState);
    }
  }

  valForm = (name, addr1, city, zip, mobile?) => {
    if (this.selCountry.length === 0) {
      this.formInfo.isCountryErr = true;
    }
    if (this.selState.length === 0) {
      this.formInfo.isStateErr = true;
    }
    if (!name) {
      this.formInfo.isNameErr = true;
    }
    if (!addr1) {
      this.formInfo.isAddr1Err = true;
    }
    if (!city) {
      this.formInfo.isCityErr = true;
    }
    if (!zip) {
      this.formInfo.isZipErr = true;
    }
    if (typeof mobile === 'string')
      if (!mobile) {
        this.formInfo.isMobileErr = true;
      }
  };


  showMobileField() {
    if (this.showMobile) {
      return 'justify-content-center';
    } else return 'justify-content-start';
  }

  hideDiv() {
    if (this.showMobile) return 'hideDiv';
  }

  hideDivWithMob() {
    if (!this.showMobile) return 'hideDivWithMob';
  }

  hidedeliver() {
    if(this.showDelivery){
      
      return true;
    }
    return 'hideDivWithMob';
  }

  handleSaveWithMob(name, addr1, addr2, city, zip, mobile?) {
    // console.log('withmob Called');
    this.valForm(name, addr1, city, zip, mobile);
    if (this.showMobile) {
      if (
        !this.formInfo.isNameErr &&
        !this.formInfo.isMobileErr &&
        !this.formInfo.isAddr1Err &&
        !this.formInfo.isCityErr &&
        !this.formInfo.isZipErr &&
        !this.formInfo.isCountryErr &&
        !this.formInfo.isStateErr
      ) {
        this.addrEmitter.emit({
          title: this.formTitle,
          name,
          addr1,
          addr2,
          country: this.selCountry[0]['country_name'],
          city,
          state: this.selState[0]['regions'],
          zip,
          mobile,
        });
        // console.log(name, mobile, addr1, addr2, city, zip);
      }
    }
  }

  handleSaveWOMob(name, addr1, addr2, city, zip) {
    this.valForm(name, addr1, city, zip);

    if (!this.showMobile) {
      if (
        !this.formInfo.isNameErr &&
        !this.formInfo.isAddr1Err &&
        !this.formInfo.isCityErr &&
        !this.formInfo.isZipErr &&
        !this.formInfo.isCountryErr &&
        !this.formInfo.isStateErr
      ) {
        this.addrEmitter.emit({
          title: this.formTitle,
          name,
          addr1,
          addr2,
          country: this.selCountry[0]['country_name'],
          city,
          state: this.selState[0]['regions'],
          zip,
        });
        // console.log(name, addr1, addr2, city, zip);
      }
    }
  }

  onNameFocus() {
    this.formInfo.isNameErr = false;
  }

  onNameBlur(nameValue?) {
    if (nameValue === '') {
      this.formInfo.isNameErr = true;
    }
  }

  onNameChange(nameValue) {
    // console.log(nameValue);
    this.formInfo.isNameErr = false;
    this.formInfo.name = nameValue;
  }

  onMobileFocus() {
    this.formInfo.isMobileErr = false;
  }

  onMobileBlur(mobileValue) {
    if (mobileValue === '') {
      this.formInfo.isMobileErr = true;
    }
  }

  onMobileChange(mobileValue) {
    // console.log(mobileValue);
    this.formInfo.isMobileErr = false;
    this.formInfo.mobile = mobileValue;
  }

  onCityFocus() {
    this.formInfo.isCityErr = false;
  }

  onCityBlur(cityValue) {
    if (cityValue === '') {
      this.formInfo.isCityErr = true;
    }
  }

  onCityChange(cityValue) {
    // console.log(cityValue);
    this.formInfo.isCityErr = false;
    this.formInfo.city = cityValue;
  }

  onAddr1Focus() {
    this.formInfo.isAddr1Err = false;
  }

  onAddr1Blur(addr1Value) {
    if (addr1Value === '') {
      this.formInfo.isAddr1Err = true;
    }
  }

  onAddr1Change(addr1Value) {
    // console.log(addr1Value);
    this.formInfo.isAddr1Err = false;
    this.formInfo.addr1 = addr1Value;
  }
  onAddr2Change(addr2Value) {
    // console.log(addr1Value);
    // this.formInfo.isAddr1Err = false;
    this.formInfo.addr2 = addr2Value;
  }

  onCodeFocus() {
    this.formInfo.isZipErr = false;
  }

  onCodeBlur(code) {
    if (!code) {
      this.formInfo.isZipErr = true;
    }
  }

  onCodeChange(zipCode) {
    console.log(zipCode);
    if(zipCode.length > 10){
      this.formInfo.isZiplenErr = true;
    }else if(zipCode.length < 10) { 
      this.formInfo.isZiplenErr = false;
    }
    this.formInfo.isZipErr = false;
    this.formInfo.zip = zipCode;
  }
}





