import { Component, OnInit } from '@angular/core';
// import { ClipboardService } from 'ngx-clipboard';
import { ModeServices } from '../../config/modes/index'
import { Request } from '../../_service/request';
import { ShareResource } from '../../_service/interface';
import { LocalstorageService } from '../../sys_config/';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LanguageService } from './../../config/language/index'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as CryptoJS from 'crypto-js';
import { Config } from '../../sys_config/config';
import { APIs_Config } from '../../sys_config/api_path_config';
import { FormGroup, Validator, FormBuilder, Validators, Form, MaxLengthValidator } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { findLast } from '@angular/compiler/src/directive_resolver';
import { ServicesService } from 'src/app/_service/services.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [LanguageService, ModeServices],
})
export class PaymentComponent implements OnInit {

  is_crypto_select_disable= false;
  exchangeRate: any;
  asset: any;
  orderTotal: any;
  SelectCrypto = true;
  shippingPayment = true;
  cryptoshipping = false;
  site_id: number = 2;
  duc_data: any;
  isducChecked = false;
  iscryptoChecked = false;
  iscryptoConformDisabled = true;
  directbuyamount: number = this.userStorage.getUserInfo('buycart_value') ? this.userStorage.getUserInfo('buycart_value') : 0;
  directbuycount: number = this.userStorage.getUserInfo('buycart_count') ? this.userStorage.getUserInfo('buycart_count') : 0;
  paymentamount: number = this.userStorage.getUserInfo('tc') ? this.userStorage.getUserInfo('tc') : this.directbuyamount;
  ducProcessErr = false;
  closeWarning = false;
  shippingamount: any;
  cryptobody1 = true;
  cart_id: any;
  trans_id: any;
  pending = true;
  paymentpending = false;
  isContinueDisabled = true;
  isducChecked1 = false;
  isContinueDisabled1 = false;
  isducChecked2 = false;
  isContinueDisabled2 = false;
  isducChecked3 = false;
  isContinueDisabled3 = false;
  isXdisabled = false;
  elementType = 'url';
  is_dandt_checked = false;
  result = {
    "class": "",
    "msg": ""
  };
  amount: any;
  values: any;
  copytext: string = "Copy";
  crypto = [
    { id: 1, name: "BTC" },
    { id: 2, name: "USDC" },
    { id: 3, name: "ETH" },
  ]
  paymentMethods: any = []
  paymentMethod: any = '';
  paymentCurrencyCode: any = '';
  txnNote: any = '';
  type: any = ''; // PAYMENT, SHIPMENT , BOTH
  bankCode: any = '';
  lang_dync = this.userStorage.current_language;
  walletAddress: any = '';
  paymentAmount: any = '';
  url: any = '';
  paymentReferenceId: any
  currencies: any = []
  secretKey = this.config.cfg.prdt_key.prdt;
  createPaymentObject: any = {
    paymentDetails: {

    },
    isFormPost: false,
    url: '',
    referenceId: '',
    checkStatusFrequently: ''
  }
  spinner1 = true;
  spinner2 = true;
  spinner3 = true;
  isrow1Disabled = false;
  isrow2Disabled = false;
  isrow2Disabled2 = true
  isrow3Disabled = true;
  isFiatDisabled = true;
  is_pay1Disabled = true;
  isCryptoDisabled = true;
  isshippingFiatDisabled = true;
  fiatType = "Select Payment"
  cryptotype = "Select Crypto"
  wallet = "1234567890";
  pend = false;
  initial = true;
  isducenabled = false;
  isfiatenabled = false;
  isfiatbtndisabled = true;
  isCryptoBtnDisabled = true;
  iscryptoenabled = false;
  isfiatpay2 = true;
  ducValue: any;
  ducvalue: FormGroup;
  ducvalue2: FormGroup;
  logsubmitted = false;
  logsubmitted1 = false;
  fiatType2 = "Select Payment"
  serverUrl = this.request.server_url;

  constructor(
    private request: Request,
    private share: ShareResource,
    private modes: ModeServices,
    private api_config: APIs_Config,
    // private clipboardService: ClipboardService,
    private userStorage: LocalstorageService,
    public language: LanguageService,
    private router: Router,
    private modalService: NgbModal,
    private config: Config,
    private formBuilder: FormBuilder,
    private service :ServicesService
  ) { }

  bo_data: any;
  pp_data: any;
  current_cart_value: number;

  ngOnInit(): void {
    console.log("{{{{{{{{")
    if (localStorage.getItem('pay_type1')) {
      localStorage.removeItem('pay_type1');
    }
    if (localStorage.getItem('pay_type2')) {
      localStorage.removeItem('pay_type2');
    }
    if (localStorage.getItem('cc') == 'true') {
      window.location.reload();
      localStorage.setItem('cc', 'false');
    }
    var tag_staus = localStorage.getItem('dbuy_tag');
    console.log(tag_staus)
    if (tag_staus == 'true') {
      var direct_data = JSON.parse(localStorage.getItem('direct_data'));
      this.shippingamount = direct_data.sc;
      this.paymentamount = direct_data.price;
      console.log(this.shippingPayment);

    } else {
      this.paymentamount = this.userStorage.getUserInfo('tc') ? this.userStorage.getUserInfo('tc') : this.directbuyamount;
      this.shippingamount = this.userStorage.getUserInfo('sc');
    }

    if (this.directbuyamount == 0 && this.directbuycount == 0 && this.paymentamount != 0) {
    }
    if (this.paymentamount == 0) {
    }
    this.orderTotal = this.paymentamount + this.shippingamount;
    this.orderTotal = this.orderTotal.toFixed(2);

    this.getPayment();

    if (this.orderTotal > 1) {
      console.log("lesser then one")
      this.quandampay = true;
    } else {
      console.log("morethen then one")
      this.quandampay = false;
    }

    this.ducvalue = this.formBuilder.group({
      ducpercent: ['', [Validators.required]]
    })

    this.ducvalue2 = this.formBuilder.group({
      ducpercent2: ['', [Validators.required, ]]
    })
    this.ducvalue.value.ducpercent = '';

    this.validation_shipment();
    this.getSingleOrderDetails();

  }


  payment_prod: any;

  is_spiner = true;
  model = false;

  async getSingleOrderDetails() {

    let temp = this.request.get_structure('get_single_order', 'format');
    temp.invoice_id = JSON.stringify(this.userStorage.getUserInfo('tid'));
    console.log(temp, "aaaaaaaa");
    let order = await this.request.post(1, 'get_single_order', temp);
    this.payment_prod = order[1].response.data;

    if (this.payment_prod) {
      this.is_spiner = false
    }

    if (this.payment_prod.shipment_cost == 0.01) {
      this.model = true;

      document.getElementById('not_deliveriable').click()
    }
    console.log(order, "aaaaaaaa")

  }



  async aaa() {

    let temp = this.request.get_structure('get_single_order', 'format');
    temp.invoice_id = JSON.stringify(this.userStorage.getUserInfo('tid'));
    console.log(temp, "aaaaaaaa");
    let order = await this.request.post(1, 'get_single_order', temp);
    this.payment_prod = order[1].response.data;
    console.log(order, "aaaaaaaa")
  }





  get url0() { return this.ducvalue.controls; }
  get url1() { return this.ducvalue2.controls; }

  shipement_validation = true;


  validation_shipment() {
    if (this.shippingamount > 1) {
      this.shipement_validation = false;
    } else {
      this.shipement_validation = true;
    }

  }

  quandampay = true;
  async getPayment() {
    try {
      let temp = this.request.get_structure('get_payment_method', 'format');
      let paymentDetails = await this.request.post(1, 'get_payment_method', temp);
      paymentDetails = this.getResponse(paymentDetails)
      this.paymentMethoOrder(paymentDetails)
    } catch (error) {
      console.log(error)
    }
  }

  paymentMethoOrder(result) {
    let res = {}
    for (let entry of result.paymentMethods) {
      let currencies = []
      res[entry.payment_gateway_name] = {
        shipment: entry.shipment
      }
      for (let currency of entry.currencies) {
        currencies.push(currency.currency_code)
      }
      res[entry.payment_gateway_name].currencies = currencies
    }
    this.paymentMethods = res
    console.log(this.paymentMethod, "ppppppppp")

    // console.log(this.paymentMethods)
  }
  selectPayment() {
    this.paymentCurrencyCode = ''
    this.currencies = this.paymentMethods[this.paymentMethod]['currencies']
    console.log(this.currencies)
  }

  async chooseCurrency(currencyCode) {
    this.paymentCurrencyCode = currencyCode;
    this.SelectCrypto = false;
    await this.createPayment('both')
  }

  async createPayment(type) {
    try {
      this.pending = true;
      this.walletAddress = ''
      this.paymentAmount = ''
      let temp = this.request.get_structure('create_payment', 'format');

      console.log(this.paymentMethod, "ppppppppp")
      if (this.paymentMethod == "paypal" || this.paymentMethod == "quantumClearance") {
        this.paymentpending = true;
      }
      temp.paymentMethod = this.paymentMethod
      temp.paymentCurrencyCode = this.paymentCurrencyCode
      temp.txnNote = this.txnNote
      temp.bankCode = this.bankCode

      if (type)
        temp.type = type
      else
        temp.type = (this.paymentMethod == 'paypal' || this.paymentMethod == 'quantumClearance') ? 'both' : type
      temp.order_id = this.userStorage.getUserInfo('tid');
      let createPayment = await this.request.post(1, 'create_payment', temp);
      console.log(createPayment)
      let ref_id = createPayment[1].response.data.referenceId;
      ref_id = this.userStorage.updateUserInfo('ref_id', ref_id);
      console.log(ref_id);
      this.createPaymentObject = this.getResponse(createPayment)
      console.log(createPayment, "response");

      if (createPayment[0] == 200) {
        this.isContinueDisabled = false;
        this.iscryptoConformDisabled = false;
      }
      else if (createPayment[0] == 400) {
        this.isContinueDisabled = true;
      }
      this.pending = false;
      if (this.paymentMethod == 'duc' || this.paymentMethod == 'aaplus') {
        this.iscryptoChecked = false;
        if (this.paymentMethod == 'duc') {
          localStorage.setItem('pay_type1', 'DUC');
          this.walletAddress = this.createPaymentObject.paymentDetails.walletAddress
          // this.paymentAmount = Math.round(this.createPaymentObject.paymentDetails.amount)
          let payAmount = this.createPaymentObject.paymentDetails.amount;
          console.log(payAmount);
          this.paymentAmount = parseFloat(payAmount).toFixed(2);
          console.log(this.paymentAmount);
        } else {
          localStorage.setItem('pay_type1', 'Crypto');
          this.walletAddress = this.createPaymentObject.paymentDetails.walletAddress
          this.paymentAmount = this.createPaymentObject.paymentDetails.amount
        }
      } else if (this.paymentMethod == 'paypal' || this.paymentMethod == 'quantumClearance') {
        // if (this.paymentMethod == 'paypal') {
        //   localStorage.setItem('pay_type2', 'Paypal');
        // } else {
        //   localStorage.setItem('pay_type2', 'quantumClearance');
        // }

        var paytype1 = localStorage.getItem('pay_type1')

        if (paytype1 == undefined && this.paymentMethod == 'paypal') {
          localStorage.setItem('pay_type1', 'Paypal');
        } else if (paytype1 == undefined && this.paymentMethod == 'quantumClearance') {
          localStorage.setItem('pay_type1', 'Paypal');
        } else {
          localStorage.setItem('pay_type2', this.paymentMethod);
        }
        this.url = this.createPaymentObject.url
        window.location.href = this.url
        this.paymentpending = false;
      }
    } catch (error) {
      this.pending = true;
      console.log(error)
    }
  }



  getResponse(responseBody) {
    if (responseBody[0] == 200) {
      return responseBody[1]['response']['data']
    }
    else {
      throw new Error(responseBody[1]['response']['error'])
    }
  }



  // copy(text: string){
  //   this.clipboardService.copy(text)
  // }

  // paymentType(event) {
  //   this.values = '';
  //   this.amount = '';
  //   console.log(this.current_cart_value);
  //   console.log(event.target.value);
  //   if (event.target.value == "paypal") {
  //     this.pp_buyprocess();
  //   }
  //   else if (event.target.value == "duc") {
  //     this.duc_process();
  //   } else if (event.target.value == "crypto") {

  //   } else if (event.target.value == "bo") {
  //     this.bo_buyprocess();
  //   }
  // }

  // async bo_buyprocess() {
  //   var temp = this.request.get_structure('bo_process', 'format');
  //   // temp.product_id = 2;
  //   temp.amount = this.paymentamount;
  //   temp.order_id = this.userStorage.getUserInfo('tid');
  //   this.bo_data = await this.request.post(1, 'bo_process', temp);
  //   console.log(this.bo_data);

  //   if (this.bo_data[0] == 200) {
  //     this.userStorage.removeUserInfo('cart_count');
  //     this.userStorage.removeUserInfo('cart_value');
  //     this.userStorage.removeUserInfo('buycart_value');
  //     this.userStorage.removeUserInfo('buycart_count');
  //     // this.userStorage.removeUserInfo('tid');
  //     this.share.addToCart(0, 0);
  //     this.result['class'] = "success";
  //     this.result['msg'] = "Success";
  //   }
  //   else if (this.bo_data[0] == 400) {
  //     this.result['class'] = "danger";
  //     this.result['msg'] = "Insufficient Balance";
  //   }
  // }


  async proceedCheckOut() {  //for direct buy
    var temp = this.request.get_structure('buyprocess', 'format');
    temp.overall_total = this.directbuyamount;
    temp.address_id = this.userStorage.getUserInfo('dId');
    // temp.product_id = 2;
    var checkout = await this.request.post(1, 'buyprocess', temp);
    console.log('checkout', checkout);
    let tid = checkout[1].response.data.order_id; console.log('tid', tid);
    this.userStorage.updateUserInfo('tid', tid);
  }



  // async pp_buyprocess() {
  //   var totalamount = this.userStorage.getUserInfo('cart_value') + this.shippingamount;
  //   if (this.directbuyamount != 0) {
  //     totalamount = this.userStorage.getUserInfo('buycart_value') ? this.userStorage.getUserInfo('buycart_value') : 0;
  //     totalamount = totalamount + this.shippingamount;
  //   }
  //   totalamount = totalamount.toFixed(2);
  //   console.log(totalamount);
  //   let temp = this.request.get_structure('pp_payment', 'format');
  //   // temp.product_id = 2;
  //   // temp.shipping_address = this.shippingamount;
  //   console.log(typeof (totalamount));
  //   temp.amount = {
  //     "total": parseFloat(totalamount),//this.share.temp1,
  //     "currency": "USD",
  //   };
  //   // temp.amount={"total":0.2, "currency":"USD"}
  //   temp.order_id = this.userStorage.getUserInfo('tid');
  //   temp.return_url = "http://localhost:4200/pp";
  //   temp.cancel_url = "http://localhost:4200/pp";
  //   console.log(temp, "paymemnt request");
  //   this.pp_data = await this.request.post(1, 'pp_payment', temp);
  //   console.log(this.pp_data);
  //   // console.log(this.pp)

  //   if (this.pp_data[0] == 200) {
  //     this.userStorage.removeUserInfo('cart_count');
  //     this.userStorage.removeUserInfo('cart_value');
  //     this.userStorage.removeUserInfo('buycart_value');
  //     this.userStorage.removeUserInfo('buycart_count');
  //     // this.userStorage.removeUserInfo('tid');
  //     this.userStorage.updateUserInfo('trans_id', this.pp_data[1].response.data.trans_id)
  //     this.share.addToCart(0, 0);
  //     this.router.navigate(["/"]).then(result => { window.location.href = this.pp_data[1].response.data.redirect_url });
  //     console.log(this.pp_data[1].response.data.redirect_url);
  //   } else {
  //     console.log("error");
  //   }
  // }


  clicktocopy() {
    this.copytext = "Copied";
    setTimeout(() => {
      this.copytext = "Copy";
    }, 15000)
  }

  // async onSelectAsset(data) {
  //   console.log(data);
  //   this.SelectCrypto = false;
  //   this.asset = data.name;
  //   var temp = this.request.get_structure('aaplus', 'format');
  //   // temp.product_id = 2;
  //   temp.instrument = this.asset;
  //   temp.amount = this.paymentamount;
  //   temp.order_id = this.userStorage.getUserInfo('tid');
  //   console.log(temp);
  //   var status_aap = await this.request.post(1, 'aaplus', temp);
  //   console.log(status_aap);
  //   if (status_aap[0] == 200) {
  //     this.pending = false;
  //     this.values = status_aap[1].response.data.status.address;
  //     this.amount = status_aap[1].response.data.status.cost.toFixed(8);
  //     this.iscryptoChecked = false;
  //   } else if (status_aap[0] == 400) {
  //     this.pending = false;
  //   }


  // }

  async closeModal() {

    if ((this.iscryptoChecked == false && this.paymentMethod == "aaplus") || (this.isducChecked == false && this.paymentMethod == "duc")) {
      if ((this.shippingPayment != false && this.paymentMethod == "duc") ||
        (this.cryptobody1 !== false && this.paymentMethod == "aaplus")) {
        let temp = this.request.get_structure('cancel_payment', 'format');
        temp.referenceId = this.userStorage.getUserInfo('ref_id');
        console.log(temp);
        let cancel = await this.request.post(1, 'cancel_payment', temp);
        console.log(cancel);
      }
    }
    this.paymentMethod = "",
      this.iscryptoChecked = false;
    this.iscryptoConformDisabled = true;
    this.SelectCrypto = true;
    this.shippingPayment = true;
    this.cryptoshipping = false;
    this.walletAddress = '';
    this.pending = true;
    this.amount = '';
    this.values = '';
    this.paymentCurrencyCode = ''
    this.cryptobody1 = true
    this.isXdisabled = false;
    this.isContinueDisabled = true;
    this.isducChecked = false;
    this.shippingPayment = true;
    this.paymentAmount = '';
        // this.cryptobody1 = !this.cryptobody1;
    // this.ducProcessErr = true;
    this.userStorage.removeUserInfo('ref_id');
    setTimeout(() => {
      this.closeWarning = false;
    }, 500);
  }

  async open_2(content2_0) {
    this.initial = true
    this.isrow2Disabled2 = false
    this.initial = true;
    this.isrow1Disabled23 = false
    this.isrow1Disabled = false
    this.isrow1Disabled = false;
    this.isrow2Disabled = false;
    this.closeWarning = false
    this.isducenabled = false
    this.duc_payment = true
    console.log("ssssssss")
    this.modalService.open(content2_0, { centered: true, size: 'xl', backdrop: 'static', keyboard: false })
  }
  async open_3(content3_0) {
   
    this.initial = true
    this.isCryptoDisabled = false
    this.isducenabled = false
    this.isrow2Disabled = false;
    this.duc_payment2 = true;
    this.closeWarning = false
    this.isrow2Disabled2 = true
    this.iscryptoenabled = false
    this.isCryptoDisabled = true
    console.log("ssssssss")
    this.modalService.open(content3_0, { centered: true, size: 'xl', backdrop: 'static', keyboard: false })
  }

  continueShipping() {
    this.shippingPayment = false;
    this.paymentCurrencyCode = ''
    this.isducChecked = false;
  }

  close_content3_0() {
    this.closeWarning = true
  }
  close_content2_0() {
    this.closeWarning = true
  }

  // shippingpaymentType(event) {
  //   console.log(event.target.value);
  //   this.pp_shippingprocess();
  // }

  // async pp_shippingprocess() {
  //   var temp = this.request.get_structure('pp_payment', 'format');
  //   // temp.product_id = 2;
  //   // temp.shipping_cost = this.shippingamount;
  //   console.log(this.shippingamount);
  //   temp.amount = {
  //     "total": this.shippingamount,
  //     "currency": "USD",
  //   };
  //   temp.order_id = this.userStorage.getUserInfo('tid');
  //   temp.return_url = "http://localhost:4200/pp";
  //   temp.cancel_url = "http://localhost:4200/pp";
  //   console.log(temp);
  //   this.pp_data = await this.request.post(1, 'pp_payment', temp);
  //   console.log(this.pp_data);

  //   if (this.pp_data[0] == 200) {
  //     this.userStorage.removeUserInfo('cart_count');
  //     this.userStorage.removeUserInfo('cart_value');
  //     this.userStorage.removeUserInfo('buycart_value');
  //     this.userStorage.removeUserInfo('buycart_count');
  //     // this.userStorage.removeUserInfo('tid');
  //     this.share.addToCart(0, 0);
  //     this.router.navigate(["/"]).then(result => { window.location.href = this.pp_data[1].response.data.redirect_url });
  //     console.log(this.pp_data[1].response.data.redirect_url);
  //   } else {
  //     console.log("error");
  //   }
  // }

  // async duc_process() {
  //   console.log("duc pay")
  //   var temp = this.request.get_structure('duc_payment', 'format');
  //   // temp.product_id = 2;
  //   // temp.amount = this.userStorage.getUserInfo('cart_value');
  //   console.log(this.paymentamount);
  //   console.log(this.exchangeRate);
  //   temp.amount = this.paymentamount / this.exchangeRate;
  //   temp.amount = Math.round(temp.amount);
  //   console.log(temp.amount);
  //   temp.order_id = this.userStorage.getUserInfo('tid');
  //   var duc_payment = await this.request.post(1, 'duc_payment', temp);
  //   // console.log(temp);
  //   console.log(duc_payment);
  //   if (duc_payment[0] == 200) {
  //     this.pending = false;
  //     this.duc_data = duc_payment[1].response.data;
  //     console.log(this.duc_data);
  //     this.values = this.duc_data.duc_address;
  //     this.amount = Math.round(this.duc_data.formatted_amount)
  //     this.cart_id = this.duc_data.cart_id;
  //     this.trans_id = this.duc_data.trans_id;
  //     this.isContinueDisabled = false;
  //   }
  // }

  async getExchangeRate() {
    let temp = this.request.get_structure('check_duc_exchange_rate', 'format');
    // temp.product_id = 2;
    let rate = await this.request.post(1, 'check_duc_exchange_rate', temp);
    this.exchangeRate = rate[1].response.data.USD
    console.log(this.exchangeRate);
    return this.exchangeRate;
  }

  async onducConform() {
    let temp = this.request.get_structure('conform_payment_status', 'format');
    temp.referenceId = this.userStorage.getUserInfo('ref_id');
    console.log(temp);
    let duc_check = await this.request.post(1, 'conform_payment_status', temp);
    console.log(duc_check);
    if (duc_check[0] == 200) {
      this.isducChecked = true
      this.isContinueDisabled = true;
    } else if (duc_check[0] == 400) {
      this.ducProcessErr = true;
      this.isContinueDisabled = true;
    }
  }

  onducConform1() {
    this.isducChecked1 = true;
    this.isContinueDisabled1 = true;
   this.isrow1Disabled23 = true
   this.isrow2Disabled2 = true;

    
  }

  isrow1Disabled23= false
  onducConform2() {
    this.isducChecked2 = true;
    this.isContinueDisabled2 = true;
    this.isrow2Disabled = true;
  }

  onducConform3() {
    this.isducChecked3 = true;
    this.isContinueDisabled3 = true;
    this.isCryptoDisabled = true
  }


  success = false
  async oncryptoConform() {
    console.log('crypto conform');
    let temp = this.request.get_structure('conform_payment_status', 'format');
    temp.referenceId = this.userStorage.getUserInfo('ref_id');
    console.log(temp);
    let crypto_status = await this.request.post(1, 'conform_payment_status', temp);
    console.log(crypto_status);
    this.iscryptoChecked = true;
    this.iscryptoConformDisabled = true;
    this.cryptoshipping = !this.cryptoshipping;

  }

  continuecryptoShipping(content) {
    this.cryptobody1 = !this.cryptobody1;
    this.cryptoshipping = false;
    this.paymentCurrencyCode = '';
    this.isContinueDisabled = true;
    setTimeout(() => {
      this.modalService.dismissAll(content)
      this.service.payment_init("paymentInitiated")
      this.router.navigate(['/buyer/'])
    }, 3000);
    
    
  }

  triggerCloseWarning() {
    console.log("Oooo")
    this.closeWarning = true;

    this.isXdisabled = true;
    setTimeout(() => {
      this.closeWarning = false;
      this.isXdisabled = false;
   
    }, 3000);
  }
  close_3min = true
  notcloseModal() {
    this.closeWarning = false;
    this.isXdisabled = false;
  }
  closeModal2() {
    this.ducvalue = this.formBuilder.group({
      ducpercent: ["", [Validators.required]]
    })

    this.ducvalue2 = this.formBuilder.group({
      ducpercent2: ["", [Validators.required]]
    })
    this.duc_value_amount = 0
    this.logsubmitted1 = false;
    this.logsubmitted = false
    this.duc_value_amount2 = 0
    this.isducChecked1 = false;
    this.isContinueDisabled1 = false;
    this.isFiatDisabled = true;
    this.closeWarning= false
    // this.duc_payment = true;
  }

  closeModal3() {
    this.ducvalue = this.formBuilder.group({
      ducpercent: ["", [Validators.required]]
    })

    this.ducvalue2 = this.formBuilder.group({
      ducpercent2: ["", [Validators.required]]
    })
    this.duc_value_amount = 0
    this.duc_value_amount2 = 0
    this.isducChecked2 = false;
    this.isContinueDisabled2 = false;
    this.logsubmitted1 = false
    this.isducChecked3 = false;
    this.isContinueDisabled3 = false;
    this.isFiatDisabled = true;
    this.closeWarning = false
    this.is_pay1Disabled2 =true
    // this.duc_payment = true;
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg', backdrop: 'static', keyboard: false })
  }

  initialstate() {
    this.isrow1Disabled = false;
    this.isrow2Disabled = true;
    this.isrow3Disabled = true;
    this.isFiatDisabled = true;
    this.isCryptoDisabled = true;
    this.isshippingFiatDisabled = true;
    this.initial = true;
    this.isducenabled = false;
    this.isfiatenabled = false;
    this.fiatType2 = "Select Payment"
    this.fiatType = "Select Payment"
    this.cryptotype = "Select Crypto"
    this.ducvalue = this.formBuilder.group({
      ducpercent: ['', [Validators.required]]
    })

    this.ducvalue2 = this.formBuilder.group({
      ducpercent2: ['', [Validators.required]]
    })
  }

  duc_value_amount = 0;
  other_value_amount = 0;
  duc_value_amount_inUSD = 0;
  duc_value_amount2 = 0;
  shiping_cost_amount2 = 0;
  duc_value_amount_inUSD2 = 0;
  shiping_cost


  async click_are(data, payment) {

    var temp = this.request.get_structure('create_paASAS', 'format');

    temp.paymentMethod = payment
    temp.order_id = this.userStorage.getUserInfo('tid');
    temp.cost_of_pro = data
    temp.type = "both"

    // temp.product_id = 2;
    console.log(temp, "PPPPP")
    var temp = await this.request.post(1, 'create_paASAS', temp);
    console.log(this.paymentMethod,
      this.paymentCurrencyCode,
      this.txnNote,
      this.bankCode)
    console.log(temp, "PPPPP")
    if (temp[0] == 200) {
      this.duc_value_amount = temp[1].response.data.DUC;
      this.other_value_amount = temp[1].response.data.Other;
      this.duc_value_amount_inUSD = temp[1].response.data.DUC_in_usd;
      this.shipping_cost = temp[1].response.data.shiping_cost;
     
      this.spinner1 = true;
      this.is_pay1Disabled = false;
    } else if (temp[0] == 400) {
      this.spinner1 = true;
      this.is_pay1Disabled = true;
    }

  }

  shipping_cost:any
  async click_are2(data, payment) {
    console.log(data, payment)
    var temp = this.request.get_structure('create_paASAS', 'format');
    temp.paymentMethod = payment
    temp.order_id = this.userStorage.getUserInfo('tid');
    temp.cost_of_pro = data
    temp.type = "shipment"
    // temp.product_id = 2;
    console.log(temp, "PPPPP")
    var temp = await this.request.post(1, 'create_paASAS', temp);
    console.log(this.paymentMethod,
      this.paymentCurrencyCode,
      this.txnNote,
      this.bankCode)

    console.log(temp, "PPPPP")
    if (temp[0] == 200) {
      this.is_pay1Disabled2 = false;
      this.spinner3 = true;
      this.duc_value_amount2 = temp[1].response.data.DUC;
      this.shiping_cost_amount2 = temp[1].response.data.shiping_cost;
      this.duc_value_amount_inUSD2 = temp[1].response.data.DUC_in_usd;
      this.balance_mount = temp[1].response.data.Other;
      this.spinner1 = true;
    }

  }
balance_mount:any

  async click_are3(data) {
    console.log(data)
    var temp = this.request.get_structure('create_paASAS', 'format');
    temp.paymentMethod = data
    temp.order_id = this.userStorage.getUserInfo('tid');
    temp.cost_of_pro = 50
    temp.type = "shipment"
    // temp.product_id = 2;
    console.log(temp, "PPPPP")
    var temp = await this.request.post(1, 'create_paASAS', temp);
    console.log(this.paymentMethod,
      this.paymentCurrencyCode,
      this.txnNote,
      this.bankCode)
    console.log(temp, "PPPPP")
    if (temp[0] == 200) {

      this.spinner1 = true;
    }

  }


  ducclick(data) {
    // console.log(data);
    // if(data <= 50){
    //   console.log("valid");
    // }else if(data > 50){
    //   console.log("invalid");
    // }else if(data == undefined || null){
    //   console.log("no data")
    // }
    this.logsubmitted = true;
    this.spinner1 = false;
 
    // setTimeout(() => {
    //   this.spinner1 = true;
    // }, 2000);
    if (this.ducvalue.invalid) {
      return setTimeout(() => {
        this.spinner1 = true;
  
      }, 1000);
      ;
    } else {
      console.log(this.ducvalue.value.ducpercent);
      this.click_are(this.ducvalue.value.ducpercent, data.target.id)
    }
  }

  // fiatClick() {
  //   this.spinner2 = false;
  //   setTimeout(() => {
  //     this.spinner2 = true;
  //   }, 2000);
  // }
  is_pay1Disabled2 = true;
  ducClick(data) {
    console.log(data)
    this.spinner3 = false;
    // setTimeout(() => {
    //   this.spinner3 = true;
    // }, 2000);
    this.logsubmitted1 = true;
    if (this.ducvalue2.invalid) {
      return setTimeout(() => {
 
        this.spinner3 = true
      }, 1000);
    } else {
      console.log(this.ducvalue2.value);
      this.click_are2(this.ducvalue2.value.ducpercent2, data.target.id)
    }
  }

  type1() {
    if (this.isrow1Disabled == true) {
      this.isrow1Disabled = false;
      this.isrow2Disabled = true;
      this.isrow2Disabled2 = false

      this.initialstate()
    }
  }

  type2() {
    if (this.isrow2Disabled == true) {
      this.isrow2Disabled = false;
      this.isrow2Disabled2 = true
      this.isrow1Disabled = true;
      this.isrow3Disabled = true;
      this.isFiatDisabled = true;
      this.isCryptoDisabled = true;
      this.isshippingFiatDisabled = true;
      this.initial = true;
      this.isducenabled = false;
      this.isfiatenabled = false;
      this.fiatType2 = "Select Payment"
      this.fiatType = "Select Payment"
      this.cryptotype = "Select Crypto"
      this.ducvalue = this.formBuilder.group({
        ducpercent: ['', [Validators.required]]
      })

      this.ducvalue2 = this.formBuilder.group({
        ducpercent2: ['', [Validators.required]]
      })
    }
  }
  pay_true = false;

  ducPay1(data) {

    console.log(data.target.id)
    if (this.isFiatDisabled == true) {
      this.is_pay1Disabled = true;
      this.pending = true;
      this.pay_true = true
      setTimeout(() => {
        this.pending = false;
      }, 500);
      this.duc_payment_fun(data.target.id, this.ducvalue.value.ducpercent)
    }
  }


  Duc_Amount: any;
  Duc_Address: any;
  pay_spinner = false;
  reference_payment_id1 = '';


  async duc_payment_fun(data, amount) {
    var temp = this.request.get_structure('create_duc_payment', 'format');
    temp.product_id = this.api_config.site_id
    temp.paymentMethod = data
    temp.order_id = this.userStorage.getUserInfo('tid');
    temp.amount = amount;
    temp.bankCode = ''
    temp.paymentCurrencyCode = ''
    temp.txnNote = ''
    temp.type = 'both'
    temp.type = 'both'
    temp.referenceid = this.reference_payment_id1;
    // temp.product_id = 2;
    console.log(temp, "PPPPP")

    var temp = await this.request.post(1, 'create_duc_payment', temp);
    console.log(temp, "PPPPP")
    if (temp[0] == 200) {
      this.walletAddress = '';
      this.url = '';
      if (data == 'paypal' || data == 'quantumClearance') {
        this.isducenabled = false;
        localStorage.setItem('pay_type1', 'paypal_parcialpayment_method1');
        console.log(this.reference_payment_id1)
        this.reference_payment_id1 = temp[1].response.data.referenceId;
        this.wallet = temp[1]['response']['data']['paymentDetails']['walletAddress']
        this.Duc_Amount = temp[1]['response']['data']['paymentDetails']['amount']
        await console.log(this.url = temp[1]['response']['data']['url'])
        this.url = temp[1]['response']['data']['url']
        if (this.url) {

          this.ducvalue = this.formBuilder.group({
            ducpercent: [0, [Validators.required]]
          })

          window.location.href = this.url

        }
      } else {
        this.isducenabled = true;
        this.initial = false;
        console.log(this.reference_payment_id1)
        this.reference_payment_id1 = temp[1].response.data.referenceId;
        this.walletAddress = temp[1]['response']['data']['paymentDetails']['walletAddress']
        this.wallet = temp[1]['response']['data']['paymentDetails']['walletAddress']
        this.Duc_Amount = temp[1]['response']['data']['paymentDetails']['amount']
        await console.log(this.url = temp[1]['response']['data']['url'])
        this.url = temp[1]['response']['data']['url']
        if (this.url) {

          window.location.href = this.url
        }
      }
    } else if (temp[0] == 400) {
      console.log(temp)
      setTimeout(() => {
        this.initialstate()
      }, 2000)
    }

  }
  duc_payment2 = true;

  async ducConform() {
    this.initial = true;
    console.log("{{{{{{{{{{")
    this.isducenabled = false;
    
    this.duc_payment = false
    this.isFiatDisabled = false;
    this.isrow1Disabled = true;
    this.is_pay1Disabled = true;
    var temp = this.request.get_structure('conform_payment_status', 'format');
    temp.product_id = this.api_config.site_id
    temp.referenceId = this.reference_payment_id1
    var temp = await this.request.post(1, 'conform_payment_status', temp);
    console.log(temp)
    this.isrow1Disabled =false

  }

  ducConform1() {

    this.isCryptoDisabled = false;
    this.initial = true;
    this.isducenabled = false;
    this.isrow2Disabled = true;
    this.isrow2Disabled2 = true;
    this.duc_payment2 = false;
    console.log("rrrrrrrrrrrrrrrrrrrrr")

  }
  duc_payment= true;
  success2 = false
  cryptoconform(content3_0) {
    console.log("eeeeeeeeeeeeeeee")
    this.isshippingFiatDisabled = false;
    this.isCryptoDisabled = true;
    this.success2 = true
    this.success = true
    setTimeout(() => {
      
      this.success2 = false
      this.modalService.dismissAll(content3_0)
      this.service.payment_init("paymentInitiated")
      this.router.navigate(["/buyer"])
     
    }, 3000);
  }

  ducPay2(data) {
    if (this.isCryptoDisabled == true) {
      // this.isCryptoDisabled = false;
      this.is_pay1Disabled2 = true
      this.pending = true;

      setTimeout(() => {
        this.pending = false;
      }, 500);
      this.create_payment_2methode(data.target.id, this.ducvalue2.value.ducpercent2)
    }

  }

  Duc_Amount2 = 0;
  reference_payment_id2 = '';

  async create_payment_2methode(data, amount) {
    var temp = this.request.get_structure('create_duc_payment2_method', 'format');
    temp.product_id = this.api_config.site_id
    temp.paymentMethod = data
    temp.order_id = this.userStorage.getUserInfo('tid');
    temp.amount = amount;
    temp.bankCode = ''
    temp.paymentCurrencyCode = ''
    temp.txnNote = ''
    temp.type = 'both'
    temp.referenceid = this.reference_payment_id2
    // temp.product_id = 2;
    console.log(temp, "PPPPP")
    var temp = await this.request.post(1, 'create_duc_payment2_method', temp);
    console.log(temp, "PPPPP")
    this.walletAddress = '';
    if (temp[0] == 200) {
      this.initial = false;
      this.url = '';
      if (data != 'duc' && data != 'paypal') {
        this.iscryptoenabled = true; this.isducenabled = false;
        this.walletAddress = temp[1]['response']['data']['paymentDetails']['walletAddress']
        this.wallet = temp[1]['response']['data']['paymentDetails']['walletAddress']
        this.Duc_Amount2 = temp[1]['response']['data']['paymentDetails']['amount']
        await console.log(this.url = temp[1]['response']['data']['url'])
        this.url = temp[1]['response']['data']['url']
        if (this.url) {

          window.location.href = this.url
        }
      }
      else if (data == 'duc') {
        this.isducenabled = true; this.iscryptoenabled = false;
        this.reference_payment_id2 = temp[1].response.data.referenceId
        this.walletAddress = temp[1]['response']['data']['paymentDetails']['walletAddress']
        this.wallet = temp[1]['response']['data']['paymentDetails']['walletAddress']
        this.Duc_Amount2 = temp[1]['response']['data']['paymentDetails']['amount']
        await console.log(this.url = temp[1]['response']['data']['url'])
        this.url = temp[1]['response']['data']['url']
        if (this.url) {

          window.location.href = this.url
        }
      } else if (data == 'paypal' || data == 'quantumClearance') {
        this.walletAddress = temp[1]['response']['data']['paymentDetails']['walletAddress']
        this.wallet = temp[1]['response']['data']['paymentDetails']['walletAddress']
        this.Duc_Amount2 = temp[1]['response']['data']['paymentDetails']['amount']
        await console.log(this.url = temp[1]['response']['data']['url'])
        this.url = temp[1]['response']['data']['url']
        if (this.url) {
          this.ducvalue2 = this.formBuilder.group({
            ducpercent2: [0, [Validators.required]]
          })
          window.location.href = this.url
        }
      }


    }
  }

  check_enable(data) {

  console.log(data.target.value)
  this.is_pay1Disabled2 = true;
  this.is_pay1Disabled = true
  this.pay_true = true;
  if (data.target.value <= 50 && data.target.value > 0) {
    console.log("win")

    this.pay_true = false;
  } else if (data.target.value > 50 && data.target.value < 0 ) {
    console.log("loss")
    this.pay_true = true;
  
  }



    
  }

  cryptopay() {
    if (this.isCryptoDisabled == false) {
      this.initial = true;
      this.isCryptoBtnDisabled = true;
      this.iscryptoenabled = false
      this.create_payment_2methode(this.payment_meth_cry, this.ducvalue2.value.ducpercent2s)

    }
  }

  currnt_pay_flag = '';
  checkfiat(data) {
    console.log(data);
    if(data == 'QuantumClearance'){
    this.fiatType = "Credit Card"
  }else{
     this.fiatType = data;
  }
    if (data == "Paypal" || "QuantumClearance") {
      console.log('called')
      this.isfiatbtndisabled = false;
      if (data == "Paypal") {
        this.currnt_pay_flag = 'paypal';
      } else if (data == "QuantumClearance") {
        this.currnt_pay_flag = 'quantumClearance';
      }

      // this.duc_payment_fun(data,this.other_value_amount)
    }
  }

  paypal_meth_to_pay: any

  checkfiat1(data) {
    console.log(data);
    this.paypal_meth_to_pay = '';
    this.fiatType2 = data;
    if (data == "Paypal" || "QuantumClearance") {
      this.isfiatpay2 = false;
      if (data == "Paypal") {
        this.paypal_meth_to_pay = 'paypal';
      } else if (data == "QuantumClearance") {
        this.paypal_meth_to_pay = 'quantumClearance';
      }
      // this.create_payment_2methode(this.paypal_meth_to_pay, this.ducvalue2.value.ducpercent2)

    }
  }

  fiatRedirect() {
    this.isducenabled = false;
    this.initial = false
    this.isfiatenabled = true;
    // setTimeout(() => {
    //   this.initialstate()
    // }, 2000);
    this.pay_spinner = true;
    this.duc_payment_fun(this.currnt_pay_flag, this.other_value_amount)



  }
  pay_spinner2 = false;
  fiatRedirect1() {
    console.log("called")
    this.iscryptoenabled = false;
    this.pay_spinner2 = true
    this.isfiatenabled = true;
    setTimeout(() => {
      this.initialstate()
    }, 2000);
    this.create_payment_2methode(this.paypal_meth_to_pay, this.ducvalue2.value.ducpercent2)
  }

  payment_meth_cry = ''
  checkCrypto(data) {
    this.payment_meth_cry = ''
    console.log(data)
    if(data == "aaplus"){
    this.cryptotype = "USDC"
  }else{
    this.cryptotype = data
  }
    if (data != "") {
      this.payment_meth_cry = data
      this.isCryptoBtnDisabled = false;
      this.click_are3(data)
      // this.create_payment_2methode(this.payment_meth_cry, this.ducvalue2.value.ducpercent2s)
    }
  }

  duc_value: any

  async open_notdeliver(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'mg', backdrop: 'static', keyboard: false })
  }

  cancel_(content_to_notdel) {

    this.modalService.dismissAll(content_to_notdel)
    this.router.navigate(["/direct"])
  }



  // dandt(){
  //   this.is_dandt_checked = true;
  // }
}
