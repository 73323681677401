<div class="container">
  <h4 style="padding-left: 15px; padding-top: 15px; padding-bottom: 15px;" >{{this.language.mLparams[lang_dync].rstpwd}}</h4>
  <div *ngIf="passwordChange" style=" margin-bottom: 40px;">
    <div class="col-md-6" *ngIf="isotptrue == false">
      <div *ngIf="email_notexists">
        <p class="alert alert-danger">{{email_notexists}}</p>
      </div>
      <form [formGroup]="forgotpasswordFrom" (ngSubmit)="onSubmitForgotemail(forgotpasswordFrom.value)">
        <div class="form-group">
          <label>{{this.language.mLparams[lang_dync].entrrgstrmailadd}}</label>
          <input type="email" required class="form-control" formControlName="email" autofocus>
          <span *ngIf="email.invalid && email.touched" class="text-danger">{{this.language.mLparams[lang_dync].mailinvld}}</span>
        </div>

        <button type="submit" class="btn addressavebtn" [disabled]="email.invalid" >{{this.language.mLparams[lang_dync].sbmt}}</button>
      </form>
     
    </div>
    <div class="col-md-6" *ngIf="isotptrue">
      <div *ngIf="invalid_otp">
        <p class="alert alert-warning">{{invalid_otp}}</p>
      </div>
      <form [formGroup]="forgotpwdotpForm">
        <div class="form-group">
          <p>{{this.language.mLparams[lang_dync].onetimepwdmsg1}}
         <b>{{email_id}}</b> {{this.language.mLparams[lang_dync].onetimepwdmsg2}}</p>
          <label>{{this.language.mLparams[lang_dync].entrotp}}</label>
          <input type="number" required maxlength="6"  class="form-control" formControlName="otp">
          <span *ngIf="otp.invalid && otp.touched">{{this.language.mLparams[lang_dync].optinvld}}</span>
        </div>
      </form>
      <button class="btn addressavebtn" [disabled]="otp.invalid"
        (click)="onSubmitForgototp(forgotpwdotpForm.value)">{{this.language.mLparams[lang_dync].sbmt}}</button>
        
        
        <button class="btn addressavebtn" style="margin-left: 10px;" [disabled]="isResendDisabled" (click)="onSubmitForgotemail(1)">{{this.language.mLparams[lang_dync].rsnd}}</button> 
    </div>
    <div><span id="timer" style="color: black;"></span></div>


  </div>


  <div class="col-md-6" *ngIf="passwordChange == false">
    <div *ngIf="email_notexists">
      <p class="alert alert-danger">{{password_failed}}</p>
    </div>
    <form [formGroup]="setpasswordFrom" (ngSubmit)="onSubmitForgotpwd()">
      <div class="form-row">
        <div class="form-group col">
          <label>{{this.language.mLparams[lang_dync].password}}</label>
          <input type="password" formControlName="password" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && st.password.errors }" />
          <div *ngIf="submitted && st.password.errors" class="invalid-feedback">
            <div *ngIf="st.password.errors.required">{{this.language.mLparams[lang_dync].pass_required}}</div>

            <div *ngIf="st.password.errors.pattern">{{this.language.mLparams[lang_dync].ptrnmsng}}</div>

          </div>
        </div>
        <div class="form-group col">
          <label>{{this.language.mLparams[lang_dync].cpassword}}</label>
          <input type="password" formControlName="confirmPassword" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && st.confirmPassword.errors }" />
          <div *ngIf="submitted && st.confirmPassword.errors" class="invalid-feedback">
            <div *ngIf="st.confirmPassword.errors.required">{{this.language.mLparams[lang_dync].cpass_required}}</div>
            <div *ngIf="st.confirmPassword.errors.mustMatch">{{this.language.mLparams[lang_dync].pwdmstmtch}}</div>
          </div>
        </div>
      </div>
      <p class="text-success" *ngIf="successmsg">{{successmsg}}</p>
      <button type="submit" class="btn addressavebtn">{{this.language.mLparams[lang_dync].sbmt}}</button>
    </form>
  </div>
  <div style="padding-top: 297px; margin-bottom: 70px;"></div>

</div>
