import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../config/language/index';
import { Request } from '../../_service/request'
import { LocalstorageService } from '../../sys_config/localstorage.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [LanguageService]
})
export class RegistrationComponent implements OnInit {

  constructor(
    private userStorage: LocalstorageService,
    public language: LanguageService,
  ) { }
  response_color:any;
  response_message:string;
  returns:string;
  lang_dync = this.userStorage.current_language;
  ngOnInit(): void {
    this.response_color="#8bc34a";
    this.response_message="Congratulations, your account has been successfully created."
    this.returns = "Success";
  }
 

}
