import { Component, Input, OnInit } from '@angular/core';
import { Request } from '../../_service/request'
import { Config } from '../../sys_config/index';
import { LanguageService } from '../../config/language/index';
import { LocalstorageService } from '../../sys_config/localstorage.service';
import { Layouts } from '../../sys_config/index';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ShareResource } from 'src/app/_service/interface';
import * as $ from 'jquery';

@Component({
  selector: 'app-productlist',
  templateUrl: './productlist.component.html',
  styleUrls: ['./productlist.component.scss'],
  providers: [LanguageService, Layouts]
})

export class ProductlistComponent implements OnInit {


  constructor(
    private request: Request,
    private config: Config,
    public language: LanguageService,
    private userStorage: LocalstorageService,
    private layout: Layouts,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private share: ShareResource

  ) { }
  @Input() productlimit: number;


  productdata: any;
  product_numbers = new Array();
  product: any;
  prd_star: any = 3;
  gridlimit: any;
  addcart: any;
  buyNowClick: any;
  arr1 = [];
  arr2 = [];
  lang_dync = this.userStorage.current_language;
  server_url = this.request.server_url;
  async ngOnInit() {
    this.activeRoute.queryParams.subscribe(params => {
      let productid = params['cats'];
      let color = params['color'];

      // console.log(productid);// OUTPUT 1534
      // console.log(color);// OUTPUT red
    });

    // this.activatedRoute.fragment.subscribe((fragment: string) => {
    //   console.log(fragment);// OUTPUT ?productid=1543&color=red 
    // })




    this.gridlimit = this.layout.limits.grids.product;


    var temp = this.request.get_structure('product_list', 'format');
    // temp.product_id = 1;
    this.product = [];
    this.productdata = await this.request.post(0, 'product_list', temp);


    if (this.productdata[1].response.data != null) {
      this.product_numbers = [];

      this.product = this.productdata[1].response;


      if (this.productlimit) {

        if (this.productdata[0] == 200) {
          // this.product[0].products = this.product[0].products.slice(0, this.productlimit);

          this.product = this.product.data.products.slice(0, this.productlimit);
          this.arr1 = [];
          this.arr2 = [];
          this.product.map((data, index) => {
            if (index >= 0 && index <= 4) {
              this.arr1.push(data);
            }
            if (index > 4 && index < 10) {
              this.arr2.push(data);
            }

          })

        }
      }
      else if (this.gridlimit) {

        if (this.productdata[0] == 200) {
          this.product = this.product.data.products.slice(0, this.gridlimit);
        }
      }
      // console.log(this.product);
      // if (this.product.data.products.length > 0) {

      //  // this.product_iteration_custom(this.product.data.products);
      // }
    }

    if (this.userStorage.buynow_product) {
      let pid = this.userStorage.buynow_product;
      this.userStorage.buynow_product = 0;
      this.buyNow(pid);
    }
  }

  cat_iteration(prd_category) {
    var iterated_product_list = {};
    var i = 1;

    prd_category.forEach(value => {
      if (value.products) {
        this.product_iteration_custom(value.products);
      }
    });
  }


  product_iteration_custom(prdds) {
    prdds.forEach(value => {
      console.log(value);
      if (value.product_id) {
        this.product_numbers.push(value);
      }
    });

  }

  async buyNow(productId) {

    var temp = this.request.get_structure('add_cart', 'format');
    temp.product_id_fs = productId;
    // temp.product_id = 2;
    temp.qty = 1;
    temp.variant_id = 1;

    var is_logged = this.userStorage.getUserInfo(this.userStorage.token);
    if (is_logged) {
      this.addcart = await this.request.post(1, 'add_cart', temp);
      if (this.addcart[0] == 200) {
        if (this.addcart[1].response.data.overall_product_count) {
          this.router.navigate(['/cart'])
        }
      }
      else { console.log(this.addcart); }
    }
    else {
      this.userStorage.buynow = true;
      this.userStorage.buynow_product = productId;
      this.buyNowClick = this.share.set_buyNow();
      $('#login_button').click();
      console.log('Not logged');
    }

  }

  /** Single Product page redirect with data*/
  singlepage(product) {
    console.log(product);
    this.router.navigateByUrl("/product?pid=" + product.product_id);
  }
  /** Single Product page redirect with data*/





  async get_voucher() {
    let temp = this.request.get_structure('get_voucher', 'format');
    console.log(temp);
    let data = await this.request.post(0, 'get_voucher', temp);
    console.log(data);
  }
}
