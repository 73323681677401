import { Injectable } from '@angular/core';

@Injectable()
export class Layouts {

    limits = {
        landing: {
            category: 3,
            product: 10
            ,
        },
        grids: {
            category: 3,
            product: 20
        },
        length: {
            gridslist: 12
        },
        related_products:{
            product : 5
        },
        faq: {
            qanda : 3
        },
        review : 3,
        catlimit : 10,

    };



}