<div class="container">
    <div class="row">
        <div class="col">
            <div class="text-center">
                <h2 style="font-family: bahnschrift;" *ngIf="!add_cat">{{this.language.mLparams[this.lang_dync].selectproductcat}}</h2>
                <h2 style="font-family: bahnschrift; margin-top: 10px;" *ngIf="add_cat">Add new Category</h2>
            </div>
            <br>
            <div class="card " style="border-color:#9E9D9D ;">

                <!-- <button class="btn btn text-center  " style="width: 100%; height: 60px; cursor: context-menu;margin-top: 5px;"> 
                <span >
                    
                        <strong >
                            {{addCategories}}
                        </strong>  
                   
                </span>

                <img 
                class="addPointer  left" [src]="'assets/back icon.svg'" width="30px" height="30px" (click)="refresh()" />

                <button *ngIf="add_cat" class="addPointer  right btn btn" style="background-color:#F5F5F5 ;" (click)="add_category_button()" > <img 
                    class="addPointer  left " [src]="'assets/add button.svg'" width="20px" height="20px" style="margin-top: 2px;"  />
                    &nbsp;&nbsp;Add new Category</button>
                </button> -->


                <div *ngIf="!add_cat">
                    <button class="btn btn text-center  " style="width: 100%; height: 60px; cursor: context-menu;">
                        <span>

                            <strong>
                                {{addCategories}}
                            </strong>

                        </span>

                        <img *ngIf="admin" class="addPointer  left" [src]="'assets/back icon.svg'" width="30px"
                            height="30px" (click)="refresh()" />

                    </button>
                </div>
                <div *ngIf="add_cat">
                    <button class="btn btn text-center  " style="width: 100%; height: 60px; cursor: context-menu;">
                        <span>

                            <strong>
                                {{addCategories}}
                            </strong>

                        </span>

                        <img class="addPointer  left" [src]="'assets/back icon.svg'" width="30px" height="30px"
                            (click)="refresh()" />

                        <a *ngIf="add_cat" class="addPointer   right btn btn"  id="#add_cat" style="background-color:#F5F5F5 ;"
                            (click)="add_category_button()"> <img class="addPointer  left "
                                [src]="'assets/add button.svg'" width="20px" height="20px" style="margin-top: 2px;" />
                            &nbsp;&nbsp;<span *ngIf="!add_category_but">Add category</span>
                            <span *ngIf="add_category_but">Close</span></a>
                    </button>
                </div>

                <hr>
                <div *ngFor="let cate of catregories">

                    <button class="btn btn" style="width: 100%; height: 40px;"
                        (click)="Category_1(cate,$event)">{{cate.category_name}}
                        <img *ngIf="cate?.array_to_json" class="addPointer  right" [src]="'assets/next icon.svg'"
                            width="30px" height="30px" />

                        <img *ngIf="!cate?.array_to_json" class="addPointer  right"
                            [src]="'assets/check_circle-black-18dp.svg'" width="30px" height="30px" />
                    </button>
                    <hr>
                </div>
                <div *ngIf="add_category_but">
                    <button class="btn btn" style="width: 100%; height: 60px;">
                        <div id="add_cat">
                            <div class="row">

                                <div class="col-6">
                                    <input class="form-control margin text-center"
                                        style="width: 50%;align-items: center; float: right;"
                                        [(ngModel)]="category_name" placeholder="Add new Category" autofocus>

                                </div>

                                <div class="col-4"><input type="file" (change)="images_needed($event)"></div>
                                <div class="col-2">
                                    <button (click)="add_category()" class="btn loginregbtn">Add New</button>
                                </div>

                            </div>
                        </div>
                    </button>
                </div>
                <!-- <div>
                    <button class="btn btn" style="width: 100%; height: 60px;" >{{Finalcatregories}}</button>
                </div> -->


            </div>

        </div>
    </div>
</div>
<br>