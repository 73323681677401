<ng-template #modal backdrop="static" centered>
  <div class="modal-header">
    <h5 class="modal-title" ngbAutofocus>{{ modalConfig.modalTitle }}</h5>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <button
      (click)="close()"
      class="mr-auto btn btn-danger"
      *ngIf="
        modalConfig.showCloseButton || modalConfig.showCloseButton === undefined
      "
      [disabled]="modalConfig.disableCloseButton"
    >
      {{ modalConfig.closeButtonLabel }}
    </button>
    <button
      (click)="confirm()"
      class="float-right btn btn-success"
      *ngIf="
        modalConfig.showConfirmButton ||
        modalConfig.showConfirmButton === undefined
      "
      [disabled]="modalConfig.disableConfirmButton"
    >
      {{ modalConfig.confirmButtonLabel }}
    </button>
    
  </div>
</ng-template>
