<div class="col-md-6">
    <form [formGroup]="getTrackingUrlform" (ngSubmit)="onEnteredTrackingUrl()">
        <div class="form-group">
            <label>Enter Name</label>
            <input required formControlName="trackingName" class="form-control" name="getducaddr"
            [ngClass]="{ 'is-invalid': logsubmitted && url.trackingName.errors }" autofocus >
            <div *ngIf="logsubmitted && url.trackingName.errors">
                <div *ngIf="url.trackingName.errors.required" class="text-danger">
                    Name is Required
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>Enter Tracking URL</label>
            <input required formControlName="trackingUrl" class="form-control" name="getducaddr"
            [ngClass]="{ 'is-invalid': logsubmitted && url.trackingUrl.errors }" >
            <div *ngIf="logsubmitted && url.trackingUrl.errors">
                <div *ngIf="url.trackingUrl.errors.required" class="text-danger">
                    URL is Required
                </div>
            </div>
        </div>
        <p class="text-muted">This url is send to customer</p>
        <button type="submit" class="btn btn-primary ">confirm</button>
    </form>
</div>