import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartComponent } from './cart/cart/cart.component';
import { CategoryComponent } from './category/category.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { PagenotfoundComponent } from './layouts/pagenotfound/pagenotfound.component';
import { MenubarComponent } from './menubar/menubar.component';
import { RegistrationComponent } from './others/registration/registration.component';
import { CategorylistComponent } from './pages/categorylist/categorylist.component';
import { OrganisedComponent } from './pages/organised/organised.component';
import { ProductlistComponent } from './pages/productlist/productlist.component';
import { AdminpanelComponent } from './panels/adminpanel/adminpanel.component';
import { BuyerdashboardComponent } from './panels/buyerdashboard/buyerdashboard.component';
import { PaymentComponent } from './payment/payment/payment.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { SellerRegComponent } from './seller-reg/seller-reg.component';
import { PpPaymentComponent } from './layouts/pp-payment/pp-payment.component'
import { AddprouctComponent } from './layouts/addProduct/addprouct/addprouct.component'
import { AuthGuardService } from './_service/auth-guard.service'
import { SingleproductComponent } from './pages/singleproduct/singleproduct.component';
import { VerificationProcessComponent } from './others/verification-process/verification-process.component';
import { DirectbuyComponent } from './custom/directbuy/directbuy.component';
import { LoginComponent } from './pages/login/login.component';
import { CategoryProductsListComponent } from './pages/category-products-list/category-products-list.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CookiePolicyComponent } from './layouts/cookie-policy/cookie-policy.component';
import { LegalComponent } from './layouts/legal/legal.component';
import { DeliveryPolicyComponent } from './layouts/delivery-policy/delivery-policy.component';
import { PaymentMessageComponent } from './layouts/payment-message/payment-message.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { RepaymentComponent } from './payment/repayment/repayment.component';
import { PartpaymentComponent } from './payment/partpayment/partpayment.component';
import { VoucherComponent } from './pages/voucher/voucher.component';
const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'cookies', component: CookiePolicyComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'deliverypolicy', component: DeliveryPolicyComponent },
  // {
  //   path: '', component: MainDashboardComponent,
  //   children: [
  //     { path: '', component: OrganisedComponent },
  //     { path: 'resetpass', component: ResetpasswordComponent },
  //     { path: 'authsuccess', component: RegistrationComponent },
  //     { path: 'authfailed', component: RegistrationComponent },
  //     { path: 'category', component: CategoryComponent },
  //     { path: 'seller', component: SellerRegComponent, canActivate: [AuthGuardService] },
  //     { path: 'footer', component: FooterComponent, canActivate: [AuthGuardService] },
  //     { path: 'header', component: HeaderComponent, canActivate: [AuthGuardService] },  
  //     { path: 'admin', component: AdminpanelComponent, canActivate: [AuthGuardService] },
  //     { path: 'mbar', component: MenubarComponent },
  //     { path: 'category_product', component: CategoryProductsListComponent},
  //     { path: 'categories', component: CategorylistComponent},
  //     { path: 'products', component: ProductlistComponent},
  //     { path: 'buyer', component: BuyerdashboardComponent, canActivate: [AuthGuardService] },
  //     { path: 'cart', component: CartComponent, canActivate: [AuthGuardService] },
  //     { path: 'repayment', component: RepaymentComponent },
  //     { path: 'payment', component: PaymentComponent, canActivate: [AuthGuardService] },
  //     { path: 'payment_confirmation/:refId', component: PpPaymentComponent },
  //     { path: 'addprod', component: AddprouctComponent },
  //     { path: 'login', component: LoginComponent },
  //     { path: 'product', component: SingleproductComponent },
  //     { path: 'verification_process', component: VerificationProcessComponent },
  //     { path: 'direct', component: DirectbuyComponent, canActivate: [AuthGuardService] },
  //     { path: 'paymentmsg', component: PaymentMessageComponent },
  //     { path: 'cookies', component: CookiePolicyComponent },
  //     { path: 'legal', component: LegalComponent },
  //     { path: 'deliverypolicy', component: DeliveryPolicyComponent },
  //     { path: 'payments', component: PartpaymentComponent },
  //     { path: 'dsv-buy-duc', component: VoucherComponent },
  //     { path: '**', component: PagenotfoundComponent }
  //   ]
  // },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
