import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { LocalstorageService } from './sys_config/localstorage.service';
import { Request } from './_service/request'
import { ShareResource } from 'src/app/_service/interface'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { param } from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'remus-v1';
  color = 'theme1';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  isUserLogged: boolean = false;
  logoutdata: any;
  cart_data: any;
  constructor(
    private activeRoute: ActivatedRoute,
    private idle: Idle,
    private keepalive: Keepalive,
    private userStorage: LocalstorageService,
    private request: Request,
    private share: ShareResource,
    private router: Router,
    private modalService: NgbModal
  ) {

    // sets an idle timeout of 30 mins, for testing purposes.
    idle.setIdle(1800);
    // sets a timeout period of 60 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.idleState);
      if (this.userStorage.getUserInfo(this.userStorage.token)) {
        this.logout()
      }
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
      if (this.userStorage.getUserInfo(this.userStorage.token)) {
        document.getElementById('autologout').click()
      }
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will be logged out in ' + countdown + ' seconds!'
      console.log(this.idleState);
    });

    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();

  }
  landing: any;
  landingPage: any;
  ngOnInit() {

    // let sessionLogout
    //   this.open(sessionLogout)
    this.activeRoute.queryParams.subscribe(params => {
      this.landing = params['/'] ? this.landingPage = true : this.landingPage = false;
    });
  }

  reset() {
    // console.log("reset called")
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  open(sessionLogout) {
    this.modalService.open(sessionLogout)
  }


  async logout() {
    console.log('logout called');
    if (this.userStorage.getUserInfo(this.userStorage.token)) {
      this.isUserLogged = true;
    }
    var temp = this.request.get_structure('logout', 'format');
    // temp.product_id = 2;
    if (this.isUserLogged) {
      this.logoutdata = await this.request.post(1, 'logout', temp);
      this.share.addToCart(0, 0);
      this.cart_data = this.share.getItems();
      console.log(this.logoutdata);
      if (this.logoutdata[1].response.data == true) {
        this.router.navigate(['/'])
        this.userStorage.removeUserAllInfo();
        localStorage.setItem('tag_load', 'false')
        this.isUserLogged = false;
        localStorage.removeItem('nickname');
        localStorage.removeItem('direct_data');
        localStorage.removeItem('type');
        localStorage.removeItem('Category_id');
        localStorage.removeItem('userdata');
        localStorage.setItem('dbuy_tag', 'false')
        localStorage.removeItem('pay_type2');
        localStorage.removeItem('cc');
        localStorage.removeItem('pay_type1');
        localStorage.removeItem('pay_type');
        localStorage.removeItem('dbuy_tag');
        localStorage.removeItem('clear_data_tag');
      }
    }
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }


  onActivate(event) {
    // window.scroll(0,0);
    document.body.scrollTop = 0;
 
}

}
