import { Injectable } from '@angular/core';

@Injectable()
export class LanguageService {
    test = true

    mLparams = {
        "english": {
            //dsv activation code start



            //dsv activation code end
            "entrotp": "Enter OTP",
            "onetimepwdmsg2": "& is valid for 180 seconds only",
            "onetimepwdmsg1": "A One Time Password (OTP) has been sent to your registered email id",
            //repymnt page strt
            "repyline8": "Thank you for the payment. We would communicate as soon as we receive at our end.",
            "repyline7": "Click continue to proceed payment",
            "repyline6": "Sending other digital assets will results in payment loss. Please ensure full payment",
            "wtadrs": "Wallet Address",
            "repyline5": "Product Cost in",
            "repyline4": "Crypto Payment",
            "repyline3": "towards Shipment Cost",
            "repyline2": "USD",
            "repyline1": "Please choose a payment option for fulfilling an amount of",
            //repaymnt page end
            "cntnueprcedshpng": "Click continue to proceed shipping payment",
            "cntnueprcedshpng2": "Please confirm that the payment has been completed",
            "sendpymntline": "Sending other digital assets will results in payment loss. Please ensure full payment",
            "scnqrcodeonly": "Scan the QR code to make only",
            "prdcstin": "Product Cost in",
            "shipngcst": "Shipping Cost",
            "prodcost": "Product Cost",
            "adrsdltsucs": "Address Deleted Succesfully",
            "clickdltadrs": "Click Confirm to Delete Selected Address",
            "dltadrs": "Delete Address",
            "zipcode": "Zipcode",
            "region": "Region",
            "adrsline2": "Address Line 2",
            "adrsline1": "Address Line 1",
            "mbleno": "Mobile No",
            "shppaymntmsg2": " Your Payment is not initiated click paynow to continue Payment",
            "shppaymntmsg1": "Your Shipping Payment is not initiated click paynow to continue Payment",
            "oldpasswd": "Old Password",
            "pswreset": "Please enter your Old Password followed by your New Password to reset your account Password",
            "clickcnfchngest": "Click Confirm to change status",
            "serviceidreq": " Service Id is Required",
            "urlreq": " URL is Required",
            "trkngurl": "Tracking URL",
            "lpartnerreq": "Logistic partner Required *",
            "proddetails": "product Details",
            "itmprpage25": "25 items per page",
            "itmprpage10": "10 items per page",
            "whousename": "WareHouse Name",
            "pqnty": "Product Quantity",
            "rltdprdct": "RELATED PRODUCTS",
            "cstmrrvew": "Customer review?",
            "msthve6char": "Must have min 6 characters.",
            "rvwcntempty": "Reviews cannot be empty",
            "yreview": "Your Review",
            "cstmrqn": "Customer question?",
            "size": "Size",
            "clr": "Color",
            "spectn": "Specification",
            "descptn": "Description",
            "addsuccsfly": "Added successfully",
            "add2crt": "ADD TO CART",
            "bynow": "BUY NOW",
            "choseodrvrint": ": choose another variant.",
            "note": "Note",
            "outfstock": "OUT OF STOCK",
            "instock": "IN STOCK",
            "vew": "View",
            "tnq": "Thank You!!",
            "cntueshpng": "Continue Shopping",
            "cntnueshpngcat": "Please continue shopping with other available categories.",
            "replenshcat": "Hey, we are replenishing products in this category.",
            "cntue": "Continue",
            //pp-payment modify start
            "ppline1": "Your Order is Received. This is the Invoice ID for reference. We have received payment of ",
            "ppline2": "towards purchase vide invoice id ",
            "ppline3": ". We would keep you notified by email to your registered email id on the shipment status of this Order.",
            "ppline4": "Your payment of",
            "ppline5": "towards Shipment is Pending. We would notify you on the status of this transaction to your registered email. You may also check the status of the same in your",
            "pplinepp": "towards Partial payment is Pending. We would notify you on the status of this transaction to your registered email. You may also check the status of the same in your",

            "ppline6": "Profile",
            "ppline7": "– My Orders Section. Continue Shopping",
            "ppline8": "towards purchase vide invoice id",
            "ppline9": "is Pending. We would notify you on the status of this transaction to your registered email. You may also check the status of the same in your",
            "ppline10": "Oops !! The transaction for an amount of",
            "ppline11": "towards Shipment isn’t successful. You may please retry the same.",
            "ppline12": "is unsuccessful. You may please retry the same below.",
            "notes": "Notes",
            //pp-payment modify end
            //header modify start
            "eml": "Email",
            "pswd": "Password",
            "acpt": "Accept",
            "trmndcnd": "Terms & Conditions",
            "merchandise": "Categories",
            //hader modify end
            //landing-page modify start
            "landline1": "Greater Reach",
            "landline2": "- The ability to expand new markets that are open to 'going cashless' in the exchange of goods",
            "landline3": "Limitless Variety",
            "landline4": "- A wider range of products and brands co-exist profitably in one global portal",
            "landline5": "Seamless Transactions",
            "landline6": "- The ease and convenience of online shopping - anywhere, anytime transactions.",
            "landline7": "RemusNation is an e-Commerce portal that brings people and businesses together to thrive in the new digital economy. An integral part of the Ducatus Cashless Economy, RemusNation is one of a growing number of affiliate businesses designed to promote a new, cashless lifestyle.",
            "landline8": "A global venue for both big and small entrepreneurs, RemusNation offers greater opportunities for businesses to compete in a borderless market. Through RemusNation, it is possible for consumers and business owners to transact using various forms of cashless payments.",
            //landing-page modify end
            //all-product page modify start
            "hashsymbol": "#",
            //all-product page modify end
            //add-product page modify start
            "addtypevarient": "Add the type of Variants",
            "useseprtvarient": "Plz use ( , ) To separateing the Variants",
            //add-product page modify end
            //cart page modify start
            "dltaddress": "Delete Address",
            "clkdltaddress": "Click Confirm to Delete Selected Address",
            "addrsdltsucss": "Address Deleted Succesfully",
            "cnfrm": "Confirm",
            //cart page modify end
            //delivery policy text start
            "deliveryline1": "Delivery Policy",
            "deliveryline2": "Shipping",
            // "deliveryline3":"Orders will be delivered to the buyer's registered and verified address. For deliveries to a different address, buyers are required to send a request via email:",
            "deliveryline4": "Requests should be sent no later than 12 hours after placing the order. The RemusNation team will then contact the buyer for further verification",
            // "deliveryline5":"The Seller will always bear the risk for the delivery until the buyer has signed for the parcel.",
            // "deliveryline6":"Shipping will be initiated as soon as possible after the full payment has been received. Delivery for items in stock listed as available for immediate delivery, will normally date. In extraordinary cases with circumstances outside of Remus Nation's control such as, but not limited to, delays from our suppliers, transportation delays and so forth, Remus Nation reserves the right to initiate the delivery within 60 days of the placed order. Remus Nation insures all deliveries from its suppliers. In the unlikely event that the items are damaged or missing in transit from its suppliers, Remus Nation reserves the right be initiated within three (3) weekdays upon receiving payment. For items listed with a specific delivery date, delivery will normally be initiated within 7 business days of the listed delivery of further delay of the delivery or repayment upon settlement of the claim with its insurer.",
            // "deliveryline7":"In the unlikely event that a parcel has not reached the buyer within 21 days from the time the buyer received the e-mail notification of the order being sent, the buyer must contact Remus Nation.",
            // "deliveryline8":"If the parcel is damaged or opened upon delivery, the buyer must notify the courier/post office immediately and make a note of the damages on the delivery receipt before contacting Remus Nation.",
            "deliveryline3": "Orders will be delivered to the buyer’s registered and verified address within their territory. For deliveries to a different address, buyers are required to send a request via email: ",
            // "deliveryline4":"Requests should be sent no later than 12 hours after placing the order. The RemusNation team will then contact the buyer for further verification.",
            "deliveryline5": "The Seller will always carry the risk of the delivery until the buyer has signed for the parcel.",
            "deliveryline6": "Shipping will be initiated as soon as possible after the full payment has been received. Delivery for items in stock listed as available for immediate delivery, will normally be initiated within three (3) working days upon receiving payment. For items listed with a specific delivery date, delivery will normally be initiated within 7 working days of the listed delivery date. In extraordinary cases with circumstances outside of RemusNation’s control such as, but not limited to, delays from our suppliers, transportation delays and so forth, RemusNation reserves the right to initiate the delivery within 60 days of the placed order. RemusNation insures all deliveries from its suppliers. In the unlikely event that the items are damaged or missing in transit from its suppliers, RemusNation reserves the right of further delay of the delivery or repayment upon settlement of the claim with its insurer.",
            "deliveryline7": "In the unlikely event that a parcel has not reached the buyer within 21 days from the time the buyer received the e-mail notification of the order being sent, the buyer must contact RemusNation at",
            "deliveryline8": "If the parcel is damaged or opened upon delivery, the buyer must notify the courier/post office immediately and make a note of the damages on the delivery receipt before contacting RemusNation.",
            "deliveryline9": "If the buyer orders several items with different delivery times, the items will be delivered at the time when all items are available for delivery.",
            "deliveryline10": "In case a shipment for a pre-paid order is returned to RemusNation, RemusNation will keep the parcel for 1 month during which time the buyer can make claim for the parcel. RemusNation will charge shipping costs to resend the parcel. If the parcel is unclaimed following 1 month, ownership of the items in the parcel will accrue to RemusNation.",
            "deliveryline11": "The buyer is responsible for any import costs such as, but not limited to, import taxes, import duties, import tariffs or import declarations where applicable.",
            "deliveryline12": "RemusNation does not take any responsibility for losses or damages in regards to uncollected or returned parcels.",
            // "deliveryli1":"RemusNation does not take any responsibility for losses or damages in regard to uncollected or returned parcels.",
            // "deliveryline12":"Remus Nation does not take any responsibility for losses or damages in regards to uncollected or returned parcels.",
            "deliveryline13": "Self-Collection",
            "deliveryline14": "Buyers can choose to pick up their online purchases in person from our regional offices in Italy, Vietnam, Dubai and Singapore. To arrange for self-collection, please send a request via",
            "deliveryline15": " For verification purposes, the buyer is required to present a valid ID.",
            "deliveryline16": "Buyers can also appoint an authorized representative to collect their orders, provided that sufficient identification including a copy of the buyer's identification and a signed letter of authorization is presented. RemusNation requires the buyer or his/her authorized representative to sign an acknowledgement upon receiving the items. RemusNation reserves the right to refuse to hand over the items, in the event that the identity of the buyer or his/her authorized representative or their relationship is not verified, or in the event the payment for the items ordered is not confirmed.",
            "deliveryline17": "In the unlikely event that the items are damaged or missing in transit from its suppliers, RemusNation reserves the right of further delay of the delivery or repayment upon settlement of the claim with its insurer. All items must be picked up by the buyer no later than seven (7) days after the later of a) the order placement if the payment will be settled at the time of collection, or b) the payment confirmation e-mail in the case where the order has been pre-paid.",
            "deliveryline18": "If for any reason the buyer or the buyer’s authorized representative is unable to pick up the order within this period, the buyer can (assuming valid reasons), contact RemusNation to request an extension, or to arrange another delivery option.",
            "deliveryline19": "(Last Updated: February 2021)",
            "deliveryline20": "",
            "deliveryline21": "",
            "deliveryline22": "",
            "deliveryline23": "",
            "deliveryline24": "",
            "deliveryline25": "",
            "deliveryline26": "",
            "deliveryline27": "",
            "deliveryline28": "",
            "deliveryline29": "",
            "deliveryline30": "",




            //delivery policy text end

            //cookie policy text start
            "cookieline1": "Cookies Policy",
            // "cookieline2":"Last updated:",
            // "cookieline3":"January 09, 2020",
            "cookieline4": "This Cookies Policy explains what cookies are and how we use them. Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the information stored in and obtained from cookies. For further information on how we use, store and keep your personal data secure, please see our Privacy Policy.",

            "cookieline5": "We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the cookies we use.",

            "cookieline6": "Definitions",
            // "cookieline7":"Interpretation",
            // "cookieline8":"The words of which the initial letter is capitalized have meanings defined under the following conditions.",
            // "cookieline9":"The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.",
            // "cookieline10":"Definitions",
            "cookieline11": "For the purposes of this Cookies Policy, the following definitions shall have the same meaning regardless of whether they appear in singular or in plural:",
            "cookieline12": "Company",
            "cookieline13": "(referred to as either 'the Company', 'We', 'Us' or 'Our' in this Cookies Policy) refers to Remus Pte Ltd. 4 Battery Road #25-01 Bank of China Building Singapore 049908",
            "cookieline14": "You",
            "cookieline15": "You means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable.",
            "cookieline16": "Cookies",
            "cookieline17": "means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.",
            "cookieline18": "Website",
            "cookieline19": "refers to RemusNation, accessible from",
            "cookieline20": "The use of the Cookies",
            "cookieline21": "Type of Cookies We Use",
            "cookieline22": "Cookies can be 'Persistent' or 'Session' Cookies. Persistent Cookies remain on your personal computer or mobile device when you go offline, while Session Cookies are deleted as soon as you close your web browser.",

            "cookieline23": "We use both session and persistent Cookies for the purposes set out below :",
            "cookieline24": "Necessary / Essential Cookies",
            "cookieline25": "Type:",
            "cookieline26": "Session Cookies",
            "cookieline27": "Administered by:",
            "cookieline28": "Us",
            "cookieline29": "Purpose:",
            "cookieline30": "These cookies are essential to provide you with services available through the website and to enable you to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.",

            "cookieline31": "Persistent Cookies",
            "cookieline32": "These cookies allow us to remember choices you make when you use the website, such as remembering your login details or language preference. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use the Website.",

            "cookieline33": "Functionality Cookies",

            "cookieline34": "Your Choices Regarding Cookies",

            "cookieline35": "If you prefer to avoid the use of cookies on the website, first you must disable the use of cookies in your browser and then delete the cookies saved in your browser associated with this website. You may use this option for preventing the use of cookies at any time.",

            "cookieline36": "If you do not accept our cookies, you may experience some inconvenience in your use of the website and some features may not function properly.",

            "cookieline37": "If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.",

            "cookieline38": "For the Chrome web browser, please visit this page from Google:",

            "cookieline39": "click here",

            "cookieline40": "For the Internet Explorer web browser, please visit this page from Microsoft:",

            "cookieline41": "For the Firefox web browser, please visit this page from Mozilla:",

            "cookieline42": "For the Safari web browser, please visit this page from Apple:",

            "cookieline43": "For any other web browser, please visit your web browser's official web pages.",


            "cookieline44": "More Information about Cookies",
            "cookieline45": "You can learn more about cookies at the following third-party websites:",
            "cookieline46": "Network Advertising Initiative: ",
            "cookieline47": "Contact Us",
            "cookieline48": "If you have any questions about this Cookies Policy, you can contact us:",
            "cookieline49": "By email: ",
            "cookieline50": "",
            "cookieline51": "",






            //cookie policy text end

            //legal page text start
            "legalline54": "We take fraudulent transactions very seriously and acquire the services of various credit reporting agencies as well as local law enforcement agencies in the event a fraudulent transaction occurs.",

            "legalline53": "Fraud Warning",

            "legalline52": "If your order has not shipped yet, you can cancel it at any time. Please e-mail us for your cancellation request. An email confirmation will be sent to you upon receipt of your order.",

            "legalline51": "Confirmation / Change / Cancellation of Order",

            "legalline50": "If for any reason you have a problem, please feel free to contact us. If an exchange is requested, we reserve the right to find an acceptable replacement or refund your money.",
            // "legalline49":"be required to pay for any postage associated with the return.",

            "legalline48": "We provide all our customers with an exchange policy on everything we sell including all certified merchandise. This right is limited to three (3) days from the date on which the customer receives their items and notifies us, provided they are kept in their original packaging. On returning related items, the customer may (at discretion of the company) be required to pay for any postage associated with the return.",

            "legalline47": "We guarantee your satisfaction at",

            "legalline46": "Return Policy:",

            "legalline45": " or its agents or employees are advised of the possibility of such damages, losses and expenses. This exclusion clause shall effect to the fullest extent permitted by law. If you are dissatisfied with any portion of this website, or with any of these Terms and Conditions of use, your sole and exclusive remedy is to discontinue using this website.",
            "legalline44": "even if",

            "legalline43": "any services, products, information, date, software or other material obtained from this website or from any other website linked to this website,",

            "legalline42": "any use of or access to any other website linked to this website;",

            "legalline41": "any system, server or connection failure, error, omission, interruption, delay in transmission, or computer failure;",

            "legalline40": "any access, use or the delay or inability to access or use this website, or reliance on the Materials and/or any other information contained in this website;",
            "legalline39": "shall in no event be liable for any damages, loss or expense including without limitation, special damage, or consequential damage, or economic loss arising from or in connection with:",

            "legalline38": "may, at any time, make modifications, improvements and/or changes to these Terms and Conditions and the material (names, images, pictures, logos and icons) displayed on the website or products and services referred to within, with or without notice. Advice received via this website should not be relied upon for personal, legal or financial decisions and you should consult an appropriate professional for specific advice tailored to your situation.",

            "legalline37": "Also, changes are periodically added to the material contained herein. These corrections and changes may be incorporated into the website at a later date.",
            "legalline36": "This website may include publications with technical inaccuracies or typographical errors that will be corrected as they are discovered at the discretion of the web team/admin of ",

            "legalline35": "No warranty of any kind, implied, expressed or statutory, including but not limited to the warranties of non-infringement of the third party's rights, title, satisfactory quality, merchantability, fitness for any particular purpose and freedom from any computer virus or other malicious, destructive or corrupting code, programme or macro, is given in conjunction with the information and materials contained in or linked to this website.",

            "legalline34": "The Material contained in this website, (including text, images, graphics, links, sound files, animation files, video files or other items and their arrangement) are provided 'as is', 'as available'.",

            "legalline33": "Liability Disclaimers",

            "legalline32": "The information, materials, data and arrangement ('Material') herein contained in this Website and the Terms and Conditions and descriptions that appear are subject to change at the sole discretion of",

            "legalline31": "Modification of Web Site and Terms and Conditions",

            "legalline30": "or the party owning them.",
            "legalline29": "or its affiliates. These may not be used without written permission of ",
            "legalline28": " or its affiliates that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by",
            "legalline27": " owns the trademarks, logos and service mark contained in this website. All other trademarks not owned by ",
            "legalline26": "Remus Pte Ltd., the owner of this website ",
            "legalline25": "Copyright and Trademark",
            "legalline24": "RemusNation reserves the right to refuse response to any source of scam that may be destructive to this website and our business.",
            "legalline23": "All contents in this website, whether original or submitted to RemusNation, are subject to change without prior notice.",
            "legalline22": "you acknowledge that you have read and understood these Terms and Conditions and you agree to be bound by them.",
            "legalline21": "By accessing, viewing and/or ordering from",
            "legalline20": "The Terms and Conditions Agreement, Disclaimer and Privacy Policy apply to all users of",

            "legalline19": "While using the website, and/or our services and/or our products we may ask you to provide us Personal Data that can be used to contact or identify you. “Personal Data” is defined to mean data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which an organisation has or is likely to have access provided that to the extent you reside in, or are otherwise subject to the personal data laws of, a jurisdiction the relevant laws or other applicable rules of which provide for a different definition of personal data, the definition of “Personal Data” hereunder shall be construed to also encompass any other types of data that are included in such different definition. The Personal Data that we may collect from you includes, but is not limited to, your salutation, name, company, designation, industry, gender, country of residence, contact number and one or more email addresses.",
            "legalline18": "Personal Data:",
            "legalline17": "2. Data we collect",
            "legalline16": "By using the Site and/or our other services and/or products, you express your agreement with this Policy and the conditions of processing your Personal Data contained herein; if you do not agree with these conditions, you should not use the website and/or our other services and/or products.",
            "legalline15": "Data Protection Officer",
            "legalline14": "Attention:",

            "legalline13": "If you, at any time, have any queries on this Privacy Policy or any other queries in relation to how we may manage, process and protect your personal data, please do not hesitate to contact our Data Protection Officer (whose contact details are set out below).",
            "legalline12": "In this Privacy Policy, 'we', 'us', 'our' shall mean Remus Pte. Ltd. having its registered office in Singapore (as Controller according to the European General Data Protection Regulation ('GDPR') and its affiliates and related companies.",

            "legalline11": "This Privacy Policy is designed to inform you of our policies regarding the collection, use and disclosure of Personal Data we receive from you, as well as to assist you in making an informed decision before providing us with any of your Personal Information.",
            "legalline10": "1. Introduction",

            // "legl1":"Terms and Conditions",
            // "legl2":"The Terms and Conditions Agreement, Disclaimer and Privacy Policy apply to all users of ",
            // "legl3":"By accessing, viewing and/or ordering from ",
            // "legl4":", you acknowledge that you have read and understood these Terms and Conditions and you agree to be bound by them. ",

            "legalline9": " The design and layout of this website is protected by intellectual property and other laws and may not be copied or reproduced in whole or in part. No logo, graphic, sound, image, text or any other content from this website may be copied, reproduced or transmitted unless expressly permitted in writing by the company.",
            "legalline8": "Modification of any of the contents or use of the contents for any other purpose will be a violation of the company's copyright and other intellectual property rights. Graphics and images on this website are protected by copyright and may not be reproduced or appropriated in any manner without the written permission of the respective copyright owners. ",
            "legalline7": "Except as otherwise provided, no part of the contents of this website shall be reproduced, republished, uploaded, posted, transmitted or otherwise distributed in any way, without the prior written permission of the company. ",
            "legalline6": "Contents",
            "legalline5": "The materials on this website, including the information available on or through this website (the 'contents') are protected by copyright, trademark and other forms of proprietary rights. All rights, title and interest in the contents are owned by, licensed to or controlled by the company.",
            "legalline4": "Proprietary Rights",
            "legalline3": "4 Battery Road #25-01 Bank of China Building Singapore 049908",
            "legalline2": "Remus Pte Ltd",
            "legalline1": "The RemusNation website is owned by:",
            "trmcndtn": "TERMS & CONDITIONS",
            "prpolicy": "PRIVACY POLICY",
            "imprsm": "IMPRESSUM",
            "legl": "Legal",
            "hme": "HOME",
            "lstupdt": "(Last Updated: February 2021)",
            //legal page text end
            "pwdmstmtch": "Passwords must match",
            "ptrnmsng": "Pattern Missing",
            "rsnd": "Re-send",
            "optinvld": "OTP is invalid",
            "sbmt": "Submit",
            "mailinvld": "Email is invalid",
            "entrrgstrmailadd": "Enter your registerd  E-mail Address",
            "rstpwd": "Reset Password",
            "recvline": " We would communicate as soon as we receive at our end.",
            "tnkforpymnt": "Thank you for the payment.",
            "cls": "Close",
            "bckofc": "Backoffice Payment",
            "paymntmthdline": "Please select payment method for shipping Payment",
            "wltadrs": "Wallet Address",
            "crptpymnt": "Crypto Payment",
            "proupdt": "Profile update",
            "pynw": "Pay Now",
            "shpmnt": "Shipment",
            "prdt": "Product",
            "rpay": "Repay",
            "fild": "Failed",
            "pymnt": "Payment",
            "stsupdtdsucss": "Status updated succesfully",
            "pymntstus": "Payment Status",
            "delvadd": "Delivery Address",
            "cnpwd": "Confirm Password",
            "nwpwd": "New Password",
            "noadrfnd": "No Address Found",
            "mbl": "Mobile",
            "emil": "Email",
            "usrnme": "User Name",
            "ordtus": "Order Status",
            "pyumntstus": "Payment Status",
            "ordtails": "Order Details",
            "dte": "Date",
            "delete": "Delete"
            , "pndng": "Pending",
            "aprvd": "Approved",
            "sndmail": "Send Mail",
            "rtry": "Retry",
            "cntnue": "Continue",
            "frgtpwd": "Forgot Password?",
            "crtacc": "Create an account",
            "notamemb": "Not a member?",
            "signup": "Signup",
            "or":"OR",
            // "login":"Login",
            "lginwith":"Login With",
            "myaccount": "My Account",
            "account":"account",
            "remusoffers": "RemusNation Offers",
            "abtus": "About Us",
            "dmarketline4": "payment options.",
            "dmarketline3": "flexibility of various cashless",
            "dmarketline2": "marketplace and enjoy the",
            "dmarketline1": "Shop from a worldwide",
            "dbayline4": "platform.",
            "dbayline3": "a cashless community",
            "dbayline2": "awesome deals from others on",
            "dbayline1": "Sell your own products or view",
            "dshopline5": "merchandise.",
            "dshopline4": " products and Ducatus",
            "dshopline3": "collection of modern lifestyle",
            "dshopline2": "Choose from our exclusive",
            "dshopline1": "Ducatus is a lifestyle.",
            "contactus": "CONTACT US",
            "aboutus": "ABOUT US",
            "delvAddrNote": "Deliver to this Address",
            "addNewShmtAddr": "Add New Delivery Address",
            "invntry": "Inventory",
            "avilbleunit": "Available Units",
            "ordrstatus": "Order Status",
            "pymntmthod": "Payment Methods",
            "ordron": "Ordered On",
            "lactive": "Last Active",
            "totalpvalue": "Total Purchase Value",
            "buyrname": "Buyer Name",
            "catgry": "Category",
            "sellr": "Seller",
            "pric": "Price",
            "lstmodified": "Last Modified",
            "sendmaillnk": "Send Email Link",
            "aprvstatus": "Approve Status",
            "aprveon": "Approved on",
            "sellemail": "Seller Email",
            "odrvalue": "Order Value",
            "sellname": "Seller Name",
            "sellid": "Seller Id",
            "prodname": "Product Name",
            "prodid": "Product Id",
            "buyerName": "Buyer Name",
            "faq_id": "Faq Id",
            "question": "Question",
            "answer": "Answer",
            "orderid": "Order Id",
            "paymentstatus": "Payment Status",
            "buyeremail": "Buyer Email",
            "buyerid": "Buyer Id",
            "dateandtime": "Date & Time",
            "invoiceid": "Invoice ID",
            "emailreq": "Email is required",
            "emailmustvalid": "Email must be a valid email address",
            "lftlnk": "Left Link 2",
            "leftlink": "Left Link 1",
            "navbar": "Navbar",
            "clickretrypayment": "Click retry to pay the Payment",
            "retry": "Retry",
            "continue": "Continue",
            "pagereqnotfount": "the page you requested could not found",
            "plsaddinmyprof": "Please add Address in My Profile",
            "noaddrssfound": "No Address Found",
            "somethingwrong": "Some thing went wrong.",
            "eror": "Error!",
            "selectproductcat": "Select a product category",
            "stat": "Status",
            "categorie": "Categorie",
            "productdesc": "Product Description",
            "productname": "Product Name",
            "id": "Id",
            "kg": ":KG",
            "image": "Image",
            "loading": "Loading...",
            "choosefile": "Choose file.",
            "select": "Select",
            "plsaddtxt": "Please Add the Product Name to add the image*",
            "addimage": "Add Image .",
            "setasmain": "Set as Main",
            "totalprice": "Total Price :",
            "flaterate": "Flat",
            "discountpercent": "in %",
            "none": "None",
            "categoryname": "Category Name :",
            "buynow": "Buy Now",
            "remove": "Remove",
            "noPrds": "No Related Products are Available Now",
            "Username_field": "Username can contain alphabets and numbers . Should be between 6 to 32 characters in length.",
            "remusAddress1": "Remus Pte Ltd",
            "remusAddress3": "4 Battery Road #25-01",
            "remusAddress2": "Bank Of China Building",
            "remus_pincode": "Singapore 049908",
            "dollor": "$",
            "userName": "E-mail/Username",
            "signupUserName": "Username",
            "boUserName": "Backoffice Email/Username",
            "password": "Password",
            "bopassword": "Backoffice Password",
            "cpassword": "Confirm Password",
            "blacklisted_token": "expired token",
            "password_struct": "Password Mismatched",
            "password_structure": "Password must contain at least 8 characters that should be a combination of at least 1 special character,1 Uppercase & Lowercase Alphabet and atleast 1 Numeral. Empty spaces are not allowed",
            "pass_length": "Password must be at least 8 characters",
            "pass_required": "Password is required",
            "cpass_required": "Confirm password is required",
            "invalid_credentials": "Invalid Credentials",
            "email_not_verified": "Email Not Verified",
            "server_under_maintenance": "BO Server is under maintenance/not reachable at this moment, Pls try after some time",
            "activation_success": "Activation success.",
            "signup_success": "One More Step !! Please check your email for an activation mail and click on the activation link to complete registration.",
            "signup_fail": "Signup failed, Kindly try again",
            "profileSuccess": "Profile updated Succesfully",
            "profileFailed": "Profile update Failed, Please Fill all Fields",
            "file_validatore": "Please add Valid image in PNG or JPEG",
            // footer txt
            "info": "Info",
            "about_us": "About us",
            "Policy": "Policy",
            "Cookie_Policy": "Cookie Policy",
            "Legal": "Legal",
            "Delivery_Policy": "Delivery Policy",
            "Contact_with_Us": "Contact with Us",
            "Coming_Soon": "Coming Soon!",
            "Get_To_Know_Us": "Get To Know Us",
            // footer txt end
            "user_name_exists": "User email is already exists",
            "email_allready_found": "User email is already exists",
            "usernameexists": "Username is already exists",
            "usernamepattern": "Username is should contains 6 to 32 Alphanumerics",
            "attention": "Attention",
            "requiredfield": "Field is required",
            "Your_Cart": "Your Cart",
            "my_profile": "My Profile",
            "admin_dash": "Dashboard",
            "become_seller": "Become Seller",
            "Enter_Your_Duc_Wallet_Address": "Enter Your Duc Wallet Address",
            "becomeSeller": "Become Seller",
            "addresrequired": "Address is required",
            "paymentLossMsg": "*Payments would be made to this wallet address. Any wrong wallet address would mean permanent loss of income.",
            "Confirm": "Confirm",
            "invalidducaddr": "Invalid DUC Wallet Address",
            "chooseReason": "Choose a reason",
            "comments": "Comments",
            "inappropriateDetails": "Inapropriate Details",
            "photoNotMatching": "Photo Not Matching",
            "notProperlyScanned": "Not Properly Scanned",
            "others": "Others",
            "confirm": "Confirm",
            "productPhotoDoesNotMeetStandards": "Product Photo Does Not Meet Standards",
            "notEnoughProductInformation": "Not Enough Product Information",
            "inAppropriateCategory": "Inappropriate Category",
            "writeCustomReason": "Write a Custom Reason",
            "reasonforDecline": "Reason For Declining",
            "Choose": "Choose...",
            "login": "Login",
            "logout": "Logout",
            "shopnow": "Shop Now",
            "no_category": "No Categories found",
            "no_product": "No Products found",
            "name": "Name",
            "firstname": "First Name",
            "lastname": "Last Name",
            "gender": "Gender",
            "validemail": "Email address should be valid",
            "address1": "Address 1",
            "address2": "Address 2",
            "city": "City",
            "state": "State",
            "country": "Country",
            "postcode": "Postal Code",
            "phonenumber": "Phonenumber",
            "save": "Save",
            "edit": "Edit",
            "my_orders": "My Orders",
            "prof_info": "Profile Information",
            "delv_add": "Delivery Address",
            "resetpwd": "Reset Password",
            "email_not_exists": "Email doesn't exists",
            "invalidotp": "Invalid OTP",
            "locationSelect": "Search Products on your location",
            "srchitm":"Search item...",
            "select_location": " Select Location",
            "paymentStatus": "Payment Status",
            "error_poup3": "Sucessfully Changed ",
            "error_poup4": "Old Password is Wrong",
            addrForm: {
                name: 'Name *',
                addr1: 'Address Line 1 *',
                addr2: 'Address Line 2',
                country: 'Country *',
                state: 'Region *',
                city: 'City *',
                code: 'Zipcode *',
                mobile: 'Mobile Number *',
                saveBtnText: "Save",
                namePlcHolder: "Enter Name",
                addr1PlcHolder: "123, New Street",
                addr2PlcHolder: "New Apartment",
                zipPlcHolder: "123456",
                cityPlcHolder: "New York",
                wareHouseTitle: "Add New Warehouse",
                mobilePlcHolder: "6512345678",
                nameErr: "Name is Required",
                mobileErr: "Mobile Number is Required",
                addr1Err: "Address Line is Required",
                selectErr: "Choose a valid option",
                // conditionsText: "* - Required Fields",   
                cityErr: "City is Required",
                zipErr: "Zip/Postal Code is Required",
                ziplenErr: "Zip code length exceeded",

            },
            addProduct: {
                title: 'Product Title * :',
                description: 'Product Description * :',
                product_descriptionReq: 'Product description is Required *',
                specifications: 'Key Specifications :',
                specifications_erro: 'Key Specifications is required*',
                itemCondition: 'Condition :',
                used: 'Used',
                new: 'New',
                isSet: 'Product available as ',
                unit: 'Units',
                set: 'Sets',
                unitsPerSet: 'Units Per Set',
                variantsInfo: 'Variants Info :',
                noVariants: 'No Variants for this product',
                hasVariants: 'Product has variants',
                variantDetails: "Variants' Details *",
                creteProductlist: 'Create Product List',
                variantName1PlcHoldr: 'Colour',
                variant1ValPlcHoldr: 'Blue, Red, Yellow',
                variantName2PlcHoldr: 'Size',
                variant2ValPlcHoldr: 'L, XL, M, S',
                createPrdctList: 'Create Product List',
                price: 'Price',
                sku: 'Notes',
                discount: 'Discount',
                discType1: 'Flat',
                discType2: 'Percentage',
                name: 'Name',
                type: 'Type',
                DiscountedPrice: 'Total',
                Warehouse: 'Warehouse',
                AvailableStock: 'Stock',
                ProductGroup: 'Groups',
                weight: 'Weight  :',
                weight1: 'Product Weight is Required ',
                AddImage: 'Add Product Image :',
                ImageReq: 'Product Image is Required',
                categorie: 'Add Product',
                AllProduct: 'List Of Product',
                Approved: 'Approved',
                Pending: 'Pending',
                Price_error: 'Error!  Please enter the valide Price*',
                Image_error: 'Error!  Please set Display Image for your product*',
                Stock_error: 'Error!  Please enter the valide Stock*',
                Group_error: 'Error!  Please add the Product Group*',
                valide_error: 'Error!  Please fill the empty inbox *',
                Sucessfully_add: "Product successfully added",
            },
            manageWareHouse: {
                manage: 'Manage',
                manageWarehouse: "Manage Warehouse",
                addWareHouse: 'Add New WareHouse',
                logPartnerTabHead: 'Logistic Partners',
                prdctGrpTabHead: 'Product Groups',
                locGrpTabHead: 'Location Groups',
                shpngZoneTabHead: 'Shipping Zones',
                shpngCostTabHead: 'Shipping Costs',
                addLogisticPartner: 'Add New Logistic Partner',
                addProductMsg: 'Prodect Added Successfully',
                productGrpDelRespMsg: "Product Group Deleted Successfully",
                trackingUrlLabel: 'Tracking URL',
                action: 'Action',
                addPGRespMsg: "Product Group Added Successfully",
                nameErr: 'Name is Required',
                urlErr: 'Tracking Url is Required',
                prdctGrpName: "Product Group Name",
                locGrpName: "Location Group Name",
                urlInfo:
                    'Plese enter url where customers can track the shipment based on the AWB #.',
                urlPlaceholder: 'https://www.fedex.com/apps/fedextrack',
                lpRespMsg: 'Logistic Partner Added Successfully',
                delWarning1: 'Are you sure you want delete',
                delWarning2: 'from your warehouse ?',
                delWarning3: ' This cannot be undone !!',
                delLogPartnertitle: 'Delete Logistics Partner',
                logPrtnrDelRespMsg: 'Logistic Partner Deleted Successfully',
                addProductGroup: 'Add New Product Group',
                addPGInfo1:
                    "Group your products based on similar shipping costs. This could be based on weight or volume as per your logistics partner's terms.  As an example, a T-Shirt & a diary could have the same shipping cost. So, you might group them as 'Light Weight Minimum Group' ",
                addLocGroup: "Add New Location Group",
                chooseCountries: "Choose Countries",
                chooseRegions: "Choose Regions",
                addLocInst1: "If you are supporting a whole country, please choose those countries from the 'Choose Countries' list. If you would be only supporting a region or group of regions within a country, select those regions from the 'Choose Regions' List.",
                addLocInst2: " Please make sure to select only the countries that you can service or deliver to. If, for any reason, you are unable to service any of the locations you have chosen, RemusNation will not take any responsibility nor will be involved in settling any delivery issue that may arise between you and the buyer.",
                avlblCountries: "Available ",
                chosenCountries: "Selected ",
                locGroupNamePlcHolder: "Southern America",
                locGroupNameLbl: "Enter a Name for your Location Group",
                locGrpNameErr: "Oops..It seems you have forgot to add a name !!",
                emptyLocErr: "Choose a Country ",
                locGrpSucMsg: "Location Group Added Successfully",
                countries: "Countrie(s)",
                regions: "Regions(s)",
                shippingCost: "Shipping Cost($)",
                shippingRules: "Configure New Shipping Cost($)",
                shippingCostTab: "Shipping Cost($)",
                delPrdctGrpTitle: 'Delete Product Group',
                delLocation: 'Delete Location Group'
            },
            "email_exists": "User email is already exists",
            "pwd_match": "Passwords must be same",
            "atcr": "Accept T&C is required",
            "register": "Register",
            "already_mem": "Already a member?",
            "paypal_failed": "payment failed",
            "paypal_success": "payment successfully done",
            "paypal_pending": "Payment is under processing",
            "paypal_cancelled": "Payment Cancelled",
            "mver_success": "Mail successfully verified ",
            "mver_failed": "Mail verification failed",
            "backoffice": "Backoffice",
            // cart txt
            "remus": "REMUSNATION",
            "cart": "My cart",
            "cartIsEmpty": "Your Cart is Empty",
            "allRightsReserved": "All Rights Reserved.",
            "site_name": "D-SHOP",
            "images": "Images",
            "productName": "Product Name",
            "price": "Price",
            "quantity": "Quantity",
            "total": "Total",
            "cartTotal": "Cart Total",
            "proceedTOCheckout": "Proceed To Checkout",
            "addDeliveryAddress": "Add Delivery Address",
            "addAddress": "Add Address",
            "deliveraddress": "Deliver to this address",
            "makedefault": "Make this default",
            // "delete": "Delete",
            "selectAddrDesc": "*Select (any) one address to proceed checkout",
            "addAddrDesc": "*Add Delivery address to Proceed checkout",
            // cart txt end

            // banner txt
            "bannerTxt": "Fancy the sporty and stylish look?",
            "bannerSubTxt": "We have the gear for you!",
            // banner txt end

            "ducatusCap": "Ducatus cap",
            "shopNow": "Shop Now",

            // admin panel
            "pendingSeller": "Pending Seller",
            "error_poup": "Error!  Old password & New password are Same Try Again",
            "error_poup2": "Please Enter the Old Password",
            "pendingProduct": "Pending Product",
            "allOrders": "All Orders",
            "allSellers": "All Sellers",
            "allProducts": "All Products",
            "allBuyers": "All Buyers",
            "yourOrders": "Your Orders",
            "yourProducts": "Your Products",
            "sellerId": "Seller ID",
            "sellerName": "Seller Name",
            "sellerEmail": "Seller Email",
            "appliedOn": "Applied On",
            "viewDetails": "View Details",
            "action": "Action",
            "accept": "Accept",
            "decline": "Decline",
            "productId": "Product Id",
            "invoiceId": "Invoice Id",
            "datetime": "Date & Time",
            "buyerId": "Buyer Id",
            "buyerEmail": "Buyer Email",
            "invoice(DUC)": "Invoice (DUC)",
            "invoice(USD)": "Invoice (USD)",
            "paymentStauts": "Payment Status",
            "orderId": "Order Id",
            "orderValue": "Order Value",
            "orderStatus": "Order Status",
            "status": "Status",
            "orderDate": "Order Date",
            "shipped": "Shipped",
            "canceled": "Canceled",
            "delivered": "Delivered",
            "pending": "Pending",
            "oupSuccess": "Order status changed successfully",
            "oupFailed": "Order status failed to change",
            "queries": "Queries",
            "noPendingProduct": "No pending Products",
            "addPrdSuccess": "Product Added Successfully",
            // admin panel end   
            // buyer panel 
            "viewdetails": "View Details",
            "SN": "S.no",
            "orderDetails": "Order Details",
            "helpandSupport": "Help and Support",
            "totalInvoiceValue": "Total invoice value",
            "product": "Product",
            "priceperunit": "Price Per unit",
            "shippmentCost": "Shipment Cost",
            "shippmentStatus": "Shipment Status",
            "myorderdesclaimer": "NOTE : Payments with pending status would be considered as failed if the payments are not received within 24 hours from the initiation of the payment.",
            // buyer panel end
            "faqsucc": "Questions added Succesfully",
            "faqerr": "Q&A Cannot be added",
            // payment 
            // "agrmntmsg":"Total Paid amount does not include duties and taxes (please refer to the Terms and Conditions for more information). Any import duties, taxes or fees due at the time of delivery, as required in the specific country of destination, are the sole responsibility of the receiving customer. By clicking BUY/PURCHASE, you agree to this condition.",

            "argmntmsg1": "Total Paid amount does not include duties and taxes.",
            "argmntmsg2": "(please refer to the",
            "argmntmsg4": "T&C",
            "argmntmsg5": "for more information)",
            "argmntmsg3": "Any import duties, taxes or fees due at the time of delivery, as required in the specific country of destination, are the sole responsibility of the receiving customer. By clicking Proceed to Checkout(Buy/Purchase), you agree to this condition.",


            "selectPayment": "Select Your Payment Method",
            "freedomToChoosePayments": "Freedom to choose your payments !!",
            "line1": "You can now make payments using selected Crypto Currencies also, other than standard Fiat Currencies.",
            "line2": "Up to 50% of Product Costs may be paid with DUC. Balance plus Shipping may only be paid using any other of the payment modes",
            "chooseTopayInduc": "Choose to Pay with DUC",
            "chooseToPayCredit": "Choose to Pay with Credit Card",
            "chooseToPayPaypal": "Choose to Pay with Paypal",
            "chooseToPayCrypto": "Choose to Pay with BTC, ETH, LTC or USDC",
            "orderSummary": "Order Summary",
            "orderTotal": "Order Total",
            "DUCPayment": " DUC Payment",
            "shippingPayment": "Shipping Payment",
            "areYouSureToCancelTransaction": "Are you sure, you want to cancel this transaction ?",
            "yes": "Yes",
            "no": "No",
            "noteTxt": "NOTE: Do not press Refresh/Back button on your browser while transaction is processing that will result transaction getting cancelled and might result in financial loss.",
            "scanQRtoMake": "Scan the QR code to make",
            "payment": "Payment",
            "sendingOtherDigitalAssetswillResultInPaymentLoss": "Sending other digital assets will results in payment loss.",
            "pleaseEnsureThatYouEnteredTheFullAmountof": "Please ensure that you enter the full amount of ",
            "whileMakingthePaymentFromTheApp": "while making the payment from the App.",
            "walletAddress": "Wallet Address",
            "iConfirmThatIhaveMadeFullPaymentOfDUC": "I confirm that I have made full payment of ",
            "toTheAboveAddress": " to the above address.",
            "thankyouLine": "Thank you for the payment. We would communicate as soon as we receive at our end.",
            "thankyouLineduc": "Thank you for the payment. We would communicate as soon as we receive at our end. Please continue to pay the Shipping cost",
            "selectShippingPayment": "Please select payment method for shipping Payment",
            "thankyouLinecrypto": "Thank you for the payment. We would communicate as soon as we receive at our end.",
            "selectAsset": "Select Asset",
            // payment end
            "Product_Title": 'Product Title is Required *',
            "Product_group": 'Product Group is Required *',
            "Product_stack": 'Product Group is Required *',
            "Product_price": 'Valide price is Requiired *',
            // payment verification
            "shippingCostsuccessline1": "Thank You !! We have received your payment of ",
            "shippingCostsuccessline2": "towards Shipment Cost. We would notify you on the Order Status as soon as we receive your Product Payment of ",
            // edit address
            "editSuccess": "Address Updated Succesfully",
            "editFailed": "Address Update Failed",
            // edit address end
            "duc": "DUC",
        },
        "french": {
            "userName": "this is french",
            "blacklisted_token": "expired token",
            "requiredfield": "Champ requis",
            "shopnow": "Achetez maintenant",
            "email_exists": "User email is already exists",
        }
    }

}