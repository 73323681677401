import { Component, OnInit, ViewChild } from '@angular/core';

import { APIs_Config } from '../../sys_config/api_path_config';
import { Request } from '../../_service/request';
import { AddAddressFormData } from '../../models/addAddrFormData';
import { FormGroup, Validator, FormBuilder, Validators } from '@angular/forms';
import { ModalComponent } from '../../custom/yes-or-no-modal/yes-or-no-modal.component';
import { ModalConfig } from '../../custom/yes-or-no-modal/modal.config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/config/language';
import { LocalstorageService } from '../../sys_config/';


@Component({
  selector: 'app-manage-warehouse',
  templateUrl: './manage-warehouse.component.html',
  styleUrls: ['./manage-warehouse.component.scss'],
  providers: [LanguageService],
})
export class ManageWarehouseComponent implements OnInit {
  @ViewChild('testModal') private confModal: ModalComponent;

  @ViewChild('editLPModal') private editLpModalComp: ModalComponent;

  @ViewChild('productDel') private delProduct: ModalComponent;

  @ViewChild('LocationDel') private delLocation: ModalComponent;

  // Location Groups States
  avlblCountries = [];
  avlblRegions = [];
  nonAvlblCountries = [];
  nonAvlblRegions = [];
  selCountries = [];
  selRegions = [];
  countryForRegionSel = '';
  locGrpNameErr = false;
  locGrpName = '';
  isLocGroupSubmitted = false;
  showLocGrpRespMsg = false;
  allLocGrps = [];
  lang_dync = this.userStorage.current_language;


  public confLogPartnerDelModalConfig: ModalConfig = {
    modalTitle: this.language.mLparams[this.lang_dync].manageWareHouse
      .delLogPartnertitle,
    onDismiss: () => {
      return true;
    },
    onClose: () => {
      return true;
    },
    closeButtonLabel: 'Cancel',
    confirmButtonLabel: 'Confirm',
    showConfirmButton: true,
    showCloseButton: true,
    disableCloseButton: false,
    disableConfirmButton: false,
    onConfirm: () => {
      this.delLogisticPartner();
    },
  };

  public editLPModalConfig: ModalConfig = {
    modalTitle: this.language.mLparams[this.lang_dync].manageWareHouse
      .delLogPartnertitle,
    onDismiss: () => {
      return true;
    },
    onClose: () => {
      return true;
    },
    closeButtonLabel: 'Cancel',
    confirmButtonLabel: 'Confirm',
    showConfirmButton: true,
    showCloseButton: true,
    disableCloseButton: false,
    disableConfirmButton: false,
    onConfirm: () => {
      this.delLogisticPartner();
    },
  };

  public confLogProductDelModalConfig: ModalConfig = {
    modalTitle: this.language.mLparams[this.lang_dync].manageWareHouse
      .delPrdctGrpTitle,
    onDismiss: () => {
      return true;
    },
    onClose: () => {
      return true;
    },
    closeButtonLabel: 'Cancel',
    confirmButtonLabel: 'Confirm',
    showConfirmButton: true,
    showCloseButton: true,
    disableCloseButton: false,
    disableConfirmButton: false,
    onConfirm: () => {
      this.delProductGroup();
    },
  };


  public confLogLocationDelModalConfig: ModalConfig = {
    modalTitle: this.language.mLparams[this.lang_dync].manageWareHouse
      .delLocation,
    onDismiss: () => {
      return true;
    },
    onClose: () => {
      return true;
    },
    closeButtonLabel: 'Cancel',
    confirmButtonLabel: 'Confirm',
    showConfirmButton: true,
    showCloseButton: true,
    disableCloseButton: false,
    disableConfirmButton: false,
    onConfirm: () => {
      this.dellocation()
    },
  };

  testPartner = { name: 'fedex' };

  wareHouses = [];
  logisticPartners = [];
  productGroups = [];
  locationGrps = [];
  shippingZones = [];
  shippingCosts = [];

  showMobile = false;
  Delivery_team = true;

  selWareHouse: any;
  selLogPartner: any;
  selPrdctGrp: any;
  selLocGrp: any;

  showAddResMsg = false;
  addResMsg = '';
  showLPAddRespMsg = false;
  showLPDelRespMsg = false;

  showPGDelResMsg = false;
  showPGAddRespMsg = false;

  showWareHouses = true;
  showAddWareHouseForm = false;
  showManageWareHouse = false;

  isLogFormSubmitted = false;
  isEditLPFormSubmitted = false;
  getLogisticPartnerForm: FormGroup;
  /////


  getProductGroupForm: FormGroup;
  editLPForm: FormGroup;

  constructor(
    public language: LanguageService,
    private request: Request,
    public config: APIs_Config,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private userStorage: LocalstorageService,
  ) { }

  get url() {
    return this.getLogisticPartnerForm.controls;
  }

  get lPForm() {
    return this.editLPForm.controls;
  }

  ngOnInit(): void {
    console.log(this.confLogPartnerDelModalConfig);
    this.getLogisticPartnerForm = this.formBuilder.group({
      trackingName: ['', [Validators.required]],
      trackingUrl: ['', [Validators.required]],
    });

    this.getProductGroupForm = this.formBuilder.group({
      productName: ['', [Validators.required]],
    });
    ////////


    this.getWarehouses();
    this.getProductData();
  }

  async getProductData() {
    let temp = await this.request.post(1, 'get_product_data_of_seller', {
      product_id: 2,
      seller_id: 0,
    });
    console.log(temp, 'get product data result');
  }

  onLogPartnerFormSubmit() {
    this.isLogFormSubmitted = true;
    if (this.getLogisticPartnerForm.invalid) {
      return;
    } else {
      this.addLogisticPartner(this.getLogisticPartnerForm.value);

      // console.log(this.getLogisticPartnerForm.value);
    }
  }

  onLogProductGroupSubmit() {
    if (this.getProductGroupForm.invalid) {
      this.isLogFormSubmitted = true;

      return;
    } else {
      this.isLogFormSubmitted = false;
      console.log(this.getProductGroupForm.value);
      this.addLogisticProduct(this.getProductGroupForm.value);
    }
  }

  onEditLPFormSubmit() {
    this.isEditLPFormSubmitted = true;
    if (this.editLPForm.invalid) {
      return;
    } else {
      // this.addLogisticPartner(this.getLogisticPartnerForm.value);

      console.log(this.editLPForm.value);
    }
  }

  // ******************MAnaging Logistic Parttners ************************

  async getLogisticPartners() {
    let { format } = this.config.cfg.get_logistic_partner;

    format.warehouse_id = this.selWareHouse.warehouse_id;
    console.log(format, 'gte log partner request');

    let temp = await this.request.post(1, 'get_logistic_partner', format);
    console.log(temp, 'log partners list');
    if (temp[0] === 200) {
      this.logisticPartners = [...temp[1].response.data];
    }
  }

  ////////////////////////////////////////////////productGroups/////////////////////////////////////////////////////////////////

  async getProductDetails() {
    let { format } = this.config.cfg.get_product_group;

    format.warehouse_id = this.selWareHouse.warehouse_id;
    console.log(format, 'Product List');

    let temp = await this.request.post(1, 'get_product_group', format);
    console.log(temp, 'Product temp');
    if (temp) {
      this.productGroups = [...temp[1].response.data];
      console.log(this.productGroups + 'the productGroups');
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  async addLogisticPartner(data) {
    let { format } = this.config.cfg.create_logistic_partner;
    format.logistic_partner_id = 0;
    format.delete = false;
    format.warehouse_id = this.selWareHouse.warehouse_id;
    format.tracking_url = data.trackingUrl;
    format.logistic_partner_name = data.trackingName;

    console.log(format, 'add log partner request');
    let temp = await this.request.post(1, 'create_logistic_partner', format);

    console.log('add log partner response', temp);

    if (temp[0] === 200) {
      if (temp[1].response.data) {
        this.showLPAddRespMsg = true;
      }
      setTimeout(() => {
        this.showLPAddRespMsg = false;
        document.getElementById('logisticPartnerModalClose').click();
        this.isLogFormSubmitted = false;
        this.getLogisticPartners();
      }, 3000);
    }
    console.log(temp, 'add logpartner reply');
  }

  async delLogisticPartner() {
    let { format } = this.config.cfg.create_logistic_partner;
    format.logistic_partner_id = this.selLogPartner.logistic_partner_id;
    format.delete = true;
    format.warehouse_id = this.selWareHouse.warehouse_id;
    format.tracking_url = this.selLogPartner.tracking_url;
    format.logistic_partner_name = this.selLogPartner.logistic_partner_name;

    console.log(format);
    let temp = await this.request.post(1, 'create_logistic_partner', format);
    console.log(temp);

    if (temp[0] === 200) {
      if (temp[1].response.data) {
        this.showLPDelRespMsg = true;
      }
      setTimeout(() => {
        this.showLPDelRespMsg = false;

        this.closeConfLogPartnerDelModal();
        this.getLogisticPartners();

        // document.getElementById("logisticPartnerModalClose").click();
        // this.isLogFormSubmitted = false;
      }, 3000);
    }
    // console.log(temp, 'del logpartner reply');
  }

  async openConfLogPartnerDelModal(data) {
    console.log(data);
    this.selLogPartner = { ...data };
    return await this.confModal.open();
  }

  async closeConfLogPartnerDelModal() {
    this.getLogisticPartners();
    return await this.confModal.close();
  }

  ///////////////////kevin/////////////////////////////////////////////////////////////////////////////

  async delProductGroup() {
    let { format } = this.config.cfg.delete_product_group;
    // format.logistic_partner_id = this.selLogPartner.logistic_partner_id;
    // format.delete = true;
    // format.warehouse_id = this.selWareHouse.warehouse_id;
    // format.tracking_url = this.selLogPartner.tracking_url;
    // format.logistic_partner_name = this.selLogPartner.logistic_partner_name;
    format.product_group_id = this.selPrdctGrp.product_group_id;
    format.warehouse_id = this.selPrdctGrp.warehouse_id;
    format.product_group_name = this.selPrdctGrp.product_group_name;
    format.products_id_list = [];
    format.delete = true;

    console.log(format + 'Formate of the delete function');
    let temp = await this.request.post(1, 'delete_product_group', format);
    console.log(temp);

    if (temp[0] === 200) {
      if (temp[1].response.data) {
        this.showPGDelResMsg = true;
      }
      setTimeout(() => {
        this.showPGDelResMsg = false;

        this.closeConfLogProductGroupModal();
        this.getProductDetails();

        // document.getElementById("logisticPartnerModalClose").click();
        // this.isLogFormSubmitted = false;
      }, 2000);
    }
    // console.log(temp, 'del logpartner reply');
  }

  async openConfLogProductGroupModal(data) {
    this.selPrdctGrp = { ...data };
    console.log(this.selPrdctGrp);
    return await this.delProduct.open();
  }


  checkSelPrdctGrpr() {
    if (this.selPrdctGrp) {
      if (Object.keys(this.selPrdctGrp).length !== 0) return true;
      return false;
    } else return false;
  }

  async closeConfLogProductGroupModal() {
    this.getProductDetails();
    return await this.delProduct.close();
  }

  ////////////////////////////////////////////////////

  checkSelLogPartner() {
    if (this.selLogPartner) {
      if (Object.keys(this.selLogPartner).length !== 0) return true;
      return false;
    } else return false;
  }

  // ***************Manage Warehouses***************************//

  async getWarehouses() {
    let temp = await this.request.post(
      1,
      'get_warehouse',
      this.config.cfg.get_warehouse.format
    );
    console.log(temp, 'get wh reply');

    if (temp[0] === 200) {
      this.wareHouses = temp[1].response.data;
      console.log(this.wareHouses);
    }
  }

  async getLctnGrps() { }

  addWareHouse = async (addr: AddAddressFormData) => {
    let { format } = this.config.cfg.add_warehouse;



    format.warehouse_name = addr.name;
    format.address_line1 = addr.addr1;
    format.address_line2 = addr.addr2;
    format.country = addr.country;
    format.region = addr.state;
    format.city = addr.city;
    format.postal_code = addr.zip;
    format.delete = false;
    format.warehouse_id = 0;
    format.shippit_enabled = true
 

    console.log('warehouse add address', format);

    let res = await this.request.post(1, 'add_warehouse', format);

    if ((res[0] = 200)) {
      this.showAddResMsg = true;
      this.addResMsg = res[1].response.data;
      // console.log(this.addResMsg,"b4 timo" )
      setTimeout(() => {
        this.showAddResMsg = false;
        this.addResMsg = '';
        // console.log(this.addResMsg,"a4 timo" )
        this.getWarehouses();
        setTimeout(() => {
          this.showAddWareHouseForm = false;
          this.showWareHouses = true;
          this.showManageWareHouse = false;
        }, 200);
      }, 3000);
    }
    console.log(res, 'addwarehouse result');
  };

  handleAddWareHouse = () => {
    console.log('add clicked');
    this.showWareHouses = false;
    this.showAddWareHouseForm = true;
    this.showManageWareHouse = false;
  };

  receiveAddr(addr: AddAddressFormData) {
    this.addWareHouse(addr);

    console.log(addr);
  }

  handleManageWareHouse = (data) => {
    this.selWareHouse = { ...data };
    this.getLogisticPartners();
    // this.getProductDetails();
    // this.getLocationGroups();
    // this.testShpngCost();
    // this.getShippingCosts();
    // this.renderShppngCostHelper();
    // this.get_all_product();
    console.log(data);
    this.showWareHouses = false;
    this.showAddWareHouseForm = false;
    this.showManageWareHouse = true;
  };


  onNavChange = (data) => {
    //  this.getProductDetails();
    //   this.getLogisticPartners();
    //   this.getLocationGroups();
    //   this.testShpngCost();
    //   this.getShippingCosts();
    //   this.renderShppngCostHelper();
    //   this.get_all_product();
    console.log(data, 'on nav change');

    if (data === '2') {
      this.getProductDetails();
    }
    if (data === '1') {
      this.getLogisticPartners();
    }
    if (data === '3') {
      this.getLocationGroups();
    }
    if (data === '4') {
      this.testShpngCost();
      this.getShippingCosts();
      this.renderShppngCostHelper();

    } if (data === '5') {
      this.get_all_product();
    }



    // if (data === 'ngb-nav-1') {
    //   // product group selected
    //   // this.getPrdctGroups();
    //   this.getProductDetails();
    // }

    // if (data === 'ngb-nav-0') {
    //   this.getLogisticPartners();
    // }

    // if (data === 'ngb-nav-2') {
    //   this.getLocationGroups();
    // }
    // if (data === 'ngb-nav-3') {
    //   this.testShpngCost();
    //   this.getProductDetails();
    //   this.getLogisticPartners();
    //   this.getLocationGroups();
    //   this.getShippingCosts();
    //   this.renderShppngCostHelper();
    //   // this.getAllDetailsforcost();



    // } if (data === 'ngb-nav-4') {
    //   this.get_all_product();
    // }
  };

  async getPrdctGroups() {
    let { format } = this.config.cfg.get_product_group;

    format.warehouse_id = this.selWareHouse.warehouse_id;
    console.log(this.selWareHouse, format, 'b4Prdct Lists');
    let temp = await this.request.post(1, 'get_product_group', format);
    console.log(temp, 'product Grp lists');
    if (temp[0] === 200) {
      this.productGroups = [...temp[1].response.data];
    }
  }

  handleManageLogPartner = (data) => {
    console.log(data);
  };

  editLogisticPartner = async (data) => {
    this.selLogPartner = { ...data };
    return await this.editLpModalComp.open();
  };



  async addLogisticProduct(data) {
    let { format } = this.config.cfg.create_product_group;

    format.warehouse_id = this.selWareHouse.warehouse_id;
    format.product_group_name = data.productName;
    format.product_group_id = 0;
    format.products_id_list = [];
    format.delete = false;

    console.log(format, 'Format list ');
    let temp = await this.request.post(1, 'create_product_group', format);
    console.log(temp, 'temp');

    if (temp[0] === 200) {
      if (temp[1].response.data) {
        this.showPGAddRespMsg = true;
      }
      setTimeout(() => {
        this.getProductDetails();
        this.showPGAddRespMsg = false;
        document.getElementById('logisticPartnerModalClose').click();
        this.isLogFormSubmitted = false;
      }, 2000);
    }
    console.log(temp, 'Add logpartner reply');
  }



  // 



  //<--------------------    Location Groups----------------------------->

  handleAddNewLocGroup() {
    console.log('loc group clicked');
    this.getLocationGroups();
  }

  sortStringInJson(a, b, key) {
    let nameA = a[key].toUpperCase();
    let nameB = b[key].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // if this line is seen, names must be equal
    return 0;
  }

  async getLocationGroups() {
    let { format } = this.config.cfg.get_location_group;
    format.warehouse_id = this.selWareHouse.warehouse_id;
    console.log(format)
    let resp = await this.request.post(1, 'get_location_group', format);
    console.log('get location group resp', format, resp);

    if (resp[0] === 200) {
      if (resp[1].response.data.length !== 0) {
        this.allLocGrps = [...resp[1].response.data];
        resp[1].response.data.map((data) => {
          this.nonAvlblCountries = [
            ...this.nonAvlblCountries,
            ...data.countries,
          ];
          this.nonAvlblRegions = [...this.nonAvlblRegions, ...data.regions];
          this.allLocGrps = resp[1].response.data
            .filter((data) => {
              if (data.warehouse_id === this.selWareHouse.warehouse_id) {
                return data;
              }
            })
            .sort((a, b) => {
              return this.sortStringInJson(a, b, 'location_group_name');
            })
            .map((data) => {
              if (data.country_names === null) {
                data.country_names = [];
              }
              if (data.region_name === null) {
                data.region_name = [];
              }
              return data;
            });
        });
      }
    }

    this.getCountryList();
  }

  async getCountryList() {
    let { format } = this.config.cfg.country_api;
    format.option = 2;
    format.find = 'null';
    let temp = await this.request.post(1, 'country_api', format);
    console.log(temp, 'countries');

    if ((temp[0] = 200)) {
      let allCountries = [...temp[1].response.data];
      this.avlblCountries = allCountries
        // .filter((country) => {
        //   if (!this.nonAvlblCountries.includes(country.country_id))
        //     return country;
        // })
        // .sort((a, b) => {
        //   return this.sortStringInJson(a, b, 'country_name');
        // });
    }
  }


  Location :any;
  Filter(event){
    if(this.Location!=""){
      console.log(event)
      console.log(this.avlblCountries )
      this.avlblCountries = this.avlblCountries.filter(res=>{
         res.location.toLowerCase().match(this.Location.toLocaleLowerCase());
        console.log(this.avlblCountries )
      })
    }else if(this.Location==""){
      this.ngOnInit();
    }
  } 

  handleAvlblCountryClick = async (country) => {
    console.log(country, 'avlbl clicked');
    this.selCountries = [...this.selCountries, country];
    this.selCountries.sort((a, b) => {
      return this.sortStringInJson(a, b, 'country_name');
    });
    this.avlblCountries = this.avlblCountries
      .filter((data) => {
        if (data.country_id !== country.country_id) return data;
      })
      .sort((a, b) => {
        return this.sortStringInJson(a, b, 'country_name');
      });
    this.avlblRegions = [];

    let { format } = this.config.cfg.country_api;
    format.option = 6;
    format.find = country.country_name;
    let temp = await this.request.post(1, 'country_api', format);
    console.log(temp, 'statelist');

    if (temp[0] === 200) {
      let regions = [...temp[1].response.data];
      if (regions.length !== 0) {
        let regionIds = regions.map((data) => data.region_id);

        this.selRegions = this.selRegions
          .filter((data) => {
            if (!regionIds.includes(data.region_id)) return data;
          })
          .sort((a, b) => {
            return this.sortStringInJson(a, b, 'regions');
          });
      }
    }
  };

  handleSelCountryClick = (country) => {
    console.log(country, 'selclicked');
    this.avlblCountries = [...this.avlblCountries, country];
    this.avlblCountries.sort((a, b) => {
      return this.sortStringInJson(a, b, 'country_name');
    });
    this.selCountries = this.selCountries
      .filter((data) => {
        if (data.country_id !== country.country_id) return data;
      })
      .sort((a, b) => {
        return this.sortStringInJson(a, b, 'country_name');
      });
  };

  async onCountryForRegionSelect(e) {
    if (e.target.value) {
      if (e.target.value !== this.language.mLparams[this.lang_dync].Choose) {
        this.countryForRegionSel = e.target.value;
        let { format } = this.config.cfg.country_api;
        format.option = 6;
        format.find = e.target.value;
        let temp = await this.request.post(1, 'country_api', format);
        console.log(temp, 'statelist');
        if (temp[0] === 200) {
          let selRegionsId = this.selRegions.map((data) => data.region_id);
          this.avlblRegions = [...temp[1].response.data];
          this.avlblRegions = this.avlblRegions
            .filter((data) => {
              if (!this.nonAvlblRegions.includes(data.region_id)) {
                return data;
              }
            })
            .filter((data) => {
              if (!selRegionsId.includes(data.region_id)) return data;
            })
            .sort((a, b) => {
              return this.sortStringInJson(a, b, 'regions');
            });
        }
      }
    }
  }

  handleAvlblRegionClick(region) {
    this.avlblRegions = this.avlblRegions
      .filter((data) => {
        if (data.regions !== region.regions) return data;
      })
      .sort((a, b) => {
        return this.sortStringInJson(a, b, 'regions');
      });
    this.selRegions = [...this.selRegions, region];
    this.selRegions.sort((a, b) => {
      return this.sortStringInJson(a, b, 'regions');
    });
  }

  async handleSelRegionsClick(region) {
    this.selRegions = this.selRegions
      .filter((data) => {
        if (data.region_id !== region.region_id) return data;
      })
      .sort((a, b) => {
        return this.sortStringInJson(a, b, 'regions');
      });
    let { format } = this.config.cfg.country_api;
    format.option = 6;
    format.find = this.countryForRegionSel;
    let temp = await this.request.post(1, 'country_api', format);
    console.log(temp, 'statelist');

    if (temp[0] === 200) {
      let regions = [...temp[1].response.data];
      if (regions.length !== 0) {
        let regionsId = regions.map((regData) => regData.region_id);

        if (regionsId.includes(region.region_id)) {
          this.avlblRegions = [...this.avlblRegions, region];
          this.avlblRegions.sort((a, b) => {
            return this.sortStringInJson(a, b, 'regions');
          });
        }
      }
    }
  }

  onLocGrpNameChange = (e) => {
    if (e.target.value) {
      this.locGrpName = e.target.value;
    }
  };

  onLocGrpNameFocus = (e) => {
    this.locGrpNameErr = false;
  };

  onLocGrpNameBlur = (value) => {
    console.log('blur', value);
    if (!value || value === '') {
      this.locGrpNameErr = true;
    }
  };

  async saveLocGroup() {
    console.log('loc group saved');
    let emptyLocErr =
      this.selCountries.length === 0 && this.selRegions.length === 0;

    if (!this.locGrpNameErr && !emptyLocErr) {
      this.isLocGroupSubmitted = true;

      let { format } = this.config.cfg.create_location_group;
      format.location_product_id = 0;
      (format.countries_list = this.selCountries.map(
        (data) => data.country_id
      )),
        (format.warehouse_id = this.selWareHouse.warehouse_id);
      (format.location_group_name = this.locGrpName),
        (format.regions_list = this.selRegions.map((data) => data.region_id)),
        (format.delete = false);
      console.log(format)
      let temp = await this.request.post(1, 'create_location_group', format);
      console.log(temp, format, 'add location group response');

      if (temp[0] === 200) {
        if (temp[1].response.data) {
          this.showLocGrpRespMsg = true;
        }
        setTimeout(() => {
          this.getLocationGroups();
          this.avlblCountries = [];
          this.avlblRegions = [];
          this.nonAvlblCountries = [];
          this.nonAvlblRegions = [];
          this.selCountries = [];
          this.selRegions = [];
          this.countryForRegionSel = '';
          this.locGrpNameErr = false;
          this.locGrpName = '';
          this.isLocGroupSubmitted = false;
          this.showLocGrpRespMsg = false;
          document.getElementById('addLocGrpModClose').click();
        }, 2500);
      }
    }
  }

  Deletelocation: any

  async openConfLocGrpDelModal(locGrp) {
    console.log(locGrp);

    this.Deletelocation = locGrp
    console.log(this.Deletelocation)
    await this.delLocation.open();




  }

  async dellocation() {
    let { format } = this.config.cfg.create_location_group;
    format.location_product_id = this.Deletelocation.location_group_id;
    (format.countries_list = this.Deletelocation.countries),
      (format.warehouse_id = this.selWareHouse.warehouse_id);
    (format.location_group_name = this.Deletelocation.location_group_name),
      (format.regions_list = this.Deletelocation.regions),
      (format.delete = true);
    console.log(format)
    let temp = await this.request.post(1, 'create_location_group', format);
    console.log(temp, 'add location group response');

    this.Deletelocation = []
    if (temp[0] === 200) {
      this.showPGDelResMsg = true
      setTimeout(() => {
        this.showPGDelResMsg = false
        this.delLocation.close();
        this.allLocGrps = [];
        this.getLocationGroups();
        this.testShpngCost();
      }, 3000);
    } else { return this.delLocation.close(); }

  }

  async getShippingCosts() {
    console.log("get shpng costs")
    let { format } = this.config.cfg.get_shipping_costs;

    format.warehouse_id = this.selWareHouse.warehouse_id;
    let temp = await this.request.post(1, 'get_shipping_costs', format)
    console.log(temp, "get shpping costs response", format)


    let lpFormat = this.config.cfg.category_list.format;
    lpFormat.product_id = 3;
    let lpCat = await this.request.post(1, 'category_list', lpFormat)
    console.log(lpCat, "categories");

    let allCats = [...lpCat[1].response.data];

    // let subcats = this.getSubCategories(1, allCats);











  }

  // 

  renderShppngCostHelper() { }

  async testShpngCost() {
    this.cost_of_Product = []
    let format = {
      "product_id": 2,
      "warehouse_id": this.selWareHouse.warehouse_id,
    }
    let temp = await this.request.post(1, 'get_shipping_costs', format)
    console.log(temp, "get shipping cost rewsp");
    var costDetails = temp[1].response.data
    for (let cost of costDetails) {
      this.cost_of_Product.push({
        location_group_id: cost.location_group_id,
        location_group_name: cost.location_group_name,
        product_group_id: cost.product_group_id,
        product_group_name: cost.product_group_name,
        shipping_costs_id: cost.shipping_costs_id,
        shippment_cost: cost.shippment_cost,
        Edit: false
      })
    }
    console.log('qwertyuiop')
    console.log(this.cost_of_Product)

  }

  cost_of_Product: any[] = [];

  // async getAllDetailsforcost(){

  //   this.cost_of_Product = [];
  //    await this.getProductDetails();
  //    await this.getLogisticPartners();
  //     var productGroup = this.productGroups;
  //     var locationGroup  = this.allLocGrps;

  //     let index = 0;
  //     let cost = 0;
  //     let edit = false;

  //   for(let pro of  productGroup){
  //     // this.cost_of_Product = pro.product_group_name
  //     for(let loc of  locationGroup){
  //       // this.cost_of_Product = loc.location_group_name
  //       this.cost_of_Product.push({
  //         Index : ++index,
  //         productName:pro.product_group_name,
  //         locationName:loc.location_group_name,
  //         ShippingCost:cost,
  //         Edit:edit
  //       })

  //     }
  //   }
  //   console.log(locationGroup)
  //   console.log('44444444444444444444')
  //   console.log(this.cost_of_Product)
  // }



  savecost = true;



  editfunction(data) {

    console.log(data);
    let index = this.cost_of_Product.indexOf(data);
    this.cost_of_Product[index].Edit = true;

    console.log(this.cost_of_Product)
  }

  addcost = 'Add Cost';

  async savefunction(data) {

    console.log(data);
    let index = this.cost_of_Product.indexOf(data);
    var data1 = this.cost_of_Product[index].Edit = false;
    console.log(data1)
    var LocationId = data.location_group_id;
    var LocationName = data.location_group_name;
    var ProductId = data.product_group_id;
    var ProductName = data.product_group_name;
    var CostId = data.shipping_costs_id;
    var Cost = data.shippment_cost;

    let { format } = this.config.cfg.create_shipping_costs;
    // format.delete = false;
    // format.location_group_id = LocationId;
    // format.product_group_id = ProductId;
    format.product_id = 2;
    format.shipping_cost_id = CostId;
    // format.shippment_config = {
    //   "product_group_id": ProductId,
    //   "location_group_id": LocationId,
    //   "shippment_cost": Cost
    // };
    format.shipping_costs = Cost;
    console.log(format)

    // var f1 = {
    //   "product_id": 2,
    //   "shipping_cost_id": 1,
    //   "shippment_config": {
    //     "product_group_id": 1,
    //     "location_group_id": 112,
    //     "shippment_cost": 1.23
    //   }
    // }
    let tem = await this.request.post(1, 'create_shipping_costs', format)
    console.log(tem, "categories");



  }

  saveall() {

    var num = Math.pow(8, 2);
    console.log(num)
  }

  allProduct: any[];

  async get_all_product() {
    let { format } = this.config.cfg.get_warehouse;
    format.product_id = 2;
    format.warehouse_id = 5;

    console.log(format, 'get_warehouse');
    let temp = await this.request.post(1, 'get_warehouse', format);
    this.allProduct = temp[1].response.data
    console.log(temp, 'All Product List');
  }

  category_ID: any;

  geting_category(event) {
    console.log(event + "manage ware houe geted")
    this.category_ID = event
  }

  back() {

    this.showWareHouses = true;
    this.showAddWareHouseForm = false;
    if (this.category_ID) {
      var r = confirm("Are you ok to cancel");
      if (r == true) {
        this.showManageWareHouse = false;
      } else {
        this.showManageWareHouse = true;
        this.showWareHouses = false;
        this.showAddWareHouseForm = false;
      }
    } else {
      this.showManageWareHouse = false;

    }
    this.category_ID = ''
  }

  drop_down() {
    console.log("llll")
    document.getElementById('dropdownMenuButton').click()
  }
  shipt_changes: any
  stage: any
  Shipt_Changes(data, need) {

    console.log(data)
    if (need == 'none') {

      this.shipt_changes = true;
      console.log(data);
      let index = this.cost_of_Product.indexOf(data);
      this.cost_of_Product[index].shippment_cost = null;
      console.log(this.cost_of_Product)
    } else if (need == 'shipit') {

      this.shipt_changes = true;
      console.log(this.stage);
      let index = this.cost_of_Product.indexOf(this.stage);
      this.cost_of_Product[index].shippment_cost = "Shipit";
      
     
    } else {
      this.stage = []
      this.stage = data
      this.modalService.open(need, { size: 'sm', backdrop: 'static', keyboard: false, scrollable: true })

    }
    console.log(this.cost_of_Product)



  }

  Shipt_Changes2(data,need){
    console.log("sadsadfdaf")
    if(need == 'none'){
      this.edit_shippit(data , 'None')
      
    }else{
      this.stage = [];
      this.stage = data
      this.modalService.open(need, { size: 'sm', backdrop: 'static', keyboard: false, scrollable: true })
    }
  }

  Shipt_Changes3(){
    console.log(this.stage)
    this.edit_shippit(this.stage,'shippit')

  }

  async edit_shippit(data, Need){
    console.log(data,"pppp")
    let { format } = this.config.cfg.add_warehouse;



    format.warehouse_name = data.warehouse_name;
    format.address_line1 = data.address_line1;
    format.address_line2 = data.address_line2;
    format.country = data.country;
    format.region = data.region;
    format.city = data.city;
    // format.postal_code = data.postal_code;
    format.delete = false;
    format.warehouse_id = data.warehouse_id;
    if(Need == 'None'){
      format.shippit_enabled = false
    }else if(Need == 'shippit'){
      format.shippit_enabled = true
    }
    
 

   console.log('warehouse add address', format);

  let res = await this.request.post(1, 'add_warehouse', format);
  if(res[0]== 200){
    this.getWarehouses();
    console.log(res)
  }


  }

 





}
