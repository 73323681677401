<div class="row">
    <div class="col-md-9 m-1 p-1">
      <input #rowData class="form-control" type="text"  [value]="data" placeholder=""  (blur)="onInputBlur(rowData.value)"/>
    </div>
    <div class="col-md-1 m-1 p-1">
      <strong><button class="btn btn-outline-success"  (click)="handleAddRow(rowData.value)" [disabled]="initValues.isDelDisabled">+</button></strong>  
      </div>
      <div class="col-md-1 m-1 p-1">
        <strong><button class="btn btn-outline-danger" (click)="handleDelRow()" [disabled]="initValues.isAddDisabled">-</button></strong>  
        </div>
  </div>
