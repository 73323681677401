import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  // For console Please use this ***
  if (window) {
    window.console.log = function () { };
  }
  // For console Please use this ***
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


