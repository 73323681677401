<!-- <div class="card">
    <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;">
        <i class="checkmark">✓</i>
    </div>
    <h1>Success</h1>
    <p>We received your purchase request;<br /> we'll be in touch shortly!</p>
</div> -->


<section *ngIf="pending">
    <!--Big blue-->
    <div class="row" style="margin: 20%;">
        <div class="col-md-5 col-sm-2"></div>
        <div class="col-md-1 col-sm-2"></div>
        <div class="col-md-1 col-sm-2">
            <div class="spinner-border text-primary" style="text-align: center;"></div>
        </div>
        <div class="col-md-5 col-sm-2"></div>
    </div>

</section>
<section style="margin:10%;">
    <div class="rt-container">
        <div class="col-rt-12">
            <div class="Scriptcontent">
                <div *ngIf="pp_success">
                    <div class="row justify-content-center">
                        <div class="col-md-9">
                            <div class="card shadow-lg bg-white rounded">
                                <div class="card-body">
                                    <div class="text-center">
                                        <img src="assets/Transactionsuccess.svg" height="200px" width="200px">
                                    </div>
                                    <p class="card-text" *ngIf="!fiatpay">
                                        {{this.language.mLparams[lang_dync].shippingCostsuccessline1}}<b>{{this.language.mLparams[lang_dync].dollor}}{{shippingCost}}</b>
                                        {{this.language.mLparams[lang_dync].shippingCostsuccessline2}}<b>{{this.language.mLparams[lang_dync].dollor}}{{prdamount}}</b>
                                    </p>
                                    <p class="card-text" *ngIf="fiatpay">{{this.language.mLparams[lang_dync].ppline1}}<b>{{this.language.mLparams[lang_dync].dollor}}{{total}}</b>
                                        {{this.language.mLparams[lang_dync].ppline2}}<b>{{invoice_id}}</b> {{this.language.mLparams[lang_dync].ppline3}}</p>
                                    <div class="text-center ">
                                        <a [routerLink]="['/']" id="contBtn"><b> {{this.language.mLparams[lang_dync].cntnue}}</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="pp_pending">
                    <div class="row justify-content-center">
                        <div class="col-md-9">
                            <div class="card shadow-lg bg-white rounded">
                                <div class="card-body">
                                    <div class="text-center mb-3">
                                        <img height="200px" width="200px" src="assets/Transactionpending.svg"></div>
                                        <div *ngIf="!parcialpay">
                                        <p *ngIf="!fiatpay">{{this.language.mLparams[lang_dync].ppline4}}
                                            <b>{{this.language.mLparams[lang_dync].dollor}}{{shippingCost}}</b> {{this.language.mLparams[lang_dync].ppline5}} <a href="/buyer" >{{this.language.mLparams[lang_dync].ppline6}}</a> {{this.language.mLparams[lang_dync].ppline7}}</p>
                                        <p *ngIf="fiatpay">
                                            {{this.language.mLparams[lang_dync].ppline4}} <b>{{this.language.mLparams[lang_dync].dollor}}{{total}}</b>
                                            {{this.language.mLparams[lang_dync].ppline8}} <b>{{invoice_id}}</b> {{this.language.mLparams[lang_dync].ppline9}} <a href="/buyer" >{{this.language.mLparams[lang_dync].ppline6}}</a>
                                            {{this.language.mLparams[lang_dync].ppline7}}
                                        </p>
                                    </div>
                                    <div *ngIf="parcialpay">
                                     <p>{{this.language.mLparams[lang_dync].ppline4}}
                                            <b>{{this.language.mLparams[lang_dync].dollor}}{{total_parcialpay}}</b>  {{this.language.mLparams[lang_dync].pplinepp}}<a href="/buyer" >{{this.language.mLparams[lang_dync].ppline6}}</a> {{this.language.mLparams[lang_dync].ppline7}}</p>
                                     
                                       
                                    </div>
                                        <div class="text-center">
                                            <a [routerLink]="['/']" id="contBtn_pending"><b>{{this.language.mLparams[lang_dync].cntnue}}</b></a>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div *ngIf="paypal_fail">
                    <div class="row justify-content-center">
                        <div class="col-md-9">
                            <div class="card shadow-lg bg-white rounded">
                                <div class="card-body">
                                    <div class="text-center  mb-3">
                                        <img height="200px" width="200px" src="assets/Transactionfailed.svg">
                                    </div>
                                    <div *ngIf="!parcialpay">
                                        <p *ngIf="!fiatpay">{{this.language.mLparams[lang_dync].ppline10}}
                                            <b>{{this.language.mLparams[lang_dync].dollor}}{{shippingCost}}</b> {{this.language.mLparams[lang_dync].ppline11}}
                                        </p>
                                        <p *ngIf="fiatpay">
                                            {{this.language.mLparams[lang_dync].ppline10}} <b>{{this.language.mLparams[lang_dync].dollor}}{{total}}</b> {{this.language.mLparams[lang_dync].ppline8}}
                                            <b>{{invoice_id}}</b> {{this.language.mLparams[lang_dync].ppline12}}
                                        </p>
                                        
                                    </div>
                                    <div>
                                        <p *ngIf="parcialpay">
                                            {{this.language.mLparams[lang_dync].ppline10}} <b>{{this.language.mLparams[lang_dync].dollor}}{{parcial_amount}}</b> {{this.language.mLparams[lang_dync].ppline8}}
                                            <b>{{invoice_id}}</b> {{this.language.mLparams[lang_dync].ppline12}}
                                        </p>
                                       
                                    </div>
                                    <div class="text-center">

                                        <a style="cursor: pointer;" (click)="cancelRetry()"
                                            id="contBtn_failed"><b>{{this.language.mLparams[lang_dync].rtry}}</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="paypal_cancel">
                    <div class="row justify-content-center">
                        <div class="col-md-9">
                            <div class="card shadow-lg bg-white rounded">
                                <div class="card-body">
                                    <div class="text-center  mb-3">
                                        <img height="200px" width="200px" src="assets/Transactionfailed.svg"></div>
                                <div *ngIf="!parcialpay"> 
                                    <p *ngIf="!fiatpay">{{this.language.mLparams[lang_dync].ppline10}}
                                        <b>{{this.language.mLparams[lang_dync].dollor}}{{shippingCost}}</b> {{this.language.mLparams[lang_dync].ppline11}}
                                    </p>
                                    <p *ngIf="fiatpay">
                                        {{this.language.mLparams[lang_dync].ppline10}}
                                        <b>{{this.language.mLparams[lang_dync].dollor}}{{total}}</b> {{this.language.mLparams[lang_dync].ppline8}}
                                        <b>{{invoice_id}}</b> {{this.language.mLparams[lang_dync].ppline12}}
                                    </p>
                                </div>
                                    <div>
                                        <p *ngIf="parcialpay">
                                            {{this.language.mLparams[lang_dync].ppline10}} <b>{{this.language.mLparams[lang_dync].dollor}}{{parcial_amount2}}</b> {{this.language.mLparams[lang_dync].ppline8}}
                                            <b>{{parcial_ref_id}}</b> {{this.language.mLparams[lang_dync].ppline12}}
                                        </p>
                                 
                                    </div>
                                    <div class="text-center">
                                        <a style="cursor: pointer;" (click)="cancelRetry()"
                                            id="contBtn_failed"><b>{{this.language.mLparams[lang_dync].rtry}}</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>