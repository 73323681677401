<div class="container" *ngIf="category">
    <div class="row product-category">
        <div class="col-lg-4 col-md-4 col-sm-12" *ngFor="let cat of category">
            <div class="inner-col" >
                <img src="{{server_url}}{{cat.image_url_1}} " class="img" alt="{{cat.category_name}}">
                <div class="content">
                    <h2 class="procat-heading">{{cat.category_name}}</h2>
                    <!-- <a [routerLink]="['products']" [queryParams]="{cats:cat.category_id}" class="btn btns primec"
                        size="sm">{{this.language.mLparams[this.lang_dync].shopnow}}</a> -->
                    <a (click)="category_ls(cat)" class="btn btns primec"
                        size="sm">{{this.language.mLparams[this.lang_dync].shopnow}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
<br>


<section *ngIf="!category">
    <!--Big blue-->
    <div class="row" style="margin: 20%;">
        <div class="col-md-5 col-sm-2"></div>
        <strong class="text-primary" style="text-align: left; padding-top: 5px;">Exploring Products...</strong>
        <div class="col-md-2 col-sm-2">
            <div class="spinner-border text-primary" style="text-align: center;"></div>
        </div>
        <div class="col-md-5 col-sm-2"></div>
    </div>
</section>