import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../../app/config/language/index';
import { LocalstorageService } from '../../sys_config/';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-delivery-policy',
  templateUrl: './delivery-policy.component.html',
  styleUrls: ['./delivery-policy.component.scss'],
  providers: [LanguageService]
})
export class DeliveryPolicyComponent implements OnInit {

  constructor(
    public language: LanguageService,
    private userStorage: LocalstorageService,
    private activeRoute: ActivatedRoute ,
    private router:Router
  ) { }
  lang_dync = this.userStorage.current_language;
  Dshop_footer = false;
  ngOnInit(): void {
    console.log(this.router.routerState.snapshot.url)
    if(this.router.routerState.snapshot.url == "/deliverypolicy"){
      this.Dshop_footer = true;
    }else{
      this.Dshop_footer = false;
    }
  }

}
