import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Request } from '../../_service/request'
import { APIs_Config, Layouts, LocalstorageService } from '../../sys_config/index';
import { areAllEquivalent } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ServicesService } from 'src/app/_service/services.service';
import { LanguageService } from 'src/app/config/language';


@Component({
  selector: 'app-category-products-list',
  templateUrl: './category-products-list.component.html',
  styleUrls: ['./category-products-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Layouts, LanguageService],
})
export class CategoryProductsListComponent implements OnInit, OnDestroy {

  totalrecode: number;
  page: number = 1;
  emptycat = true;
  constructor(

    private _requect: Request,
    public config: APIs_Config,
    private service: ServicesService,
    public layout: Layouts,
    private router: Router,
    public language: LanguageService,
    private userStorage: LocalstorageService,
  ) { }

  Product_list: any;
  cat_pending = true;
  category_id: any;
  server_url = this._requect.server_url;
  emptyprdMsg = false;
  cat_name: any;
  sub_cat_name: any;
  DATA: any;
  lang_dync = this.userStorage.current_language;
  prd_star: any = 4;
  product: any[];
  Items = this.layout.limits.length.gridslist;

  ngOnInit(): void {

    this.DATA = localStorage.getItem('Category_id');
    this.getuser(this.DATA)

  }
  async getuser(data) {
    let { format } = this.config.cfg.product_list2;
    format.product_id = 2,
      format.category_id = parseInt(data),
      console.log(format);
    let temp = await this._requect.post(0, 'product_list2', format);
    console.log(temp)
    if (temp[0] == 200) {
      if (temp[1].response.data.products.products.length !== 0) {
        this.product = temp[1].response.data.products.products;
        this.cat_name = temp[1].response.data.products.main_category;
        if (temp[1].response.data.products.main_category != temp[1].response.data.products.sub_category) {
          this.sub_cat_name = temp[1].response.data.products.sub_category
        }else {
          this.sub_cat_name = '';
        }
        this.emptycat = false
        this.emptyprdMsg = false;
        console.log(this.product)
        this.cat_pending = false;
        this.service.set_cat_name(this.cat_name)
      } else if (temp[1].response.data.products.products.length == 0) {
        this.cat_name = temp[1].response.data.products.category_name;
        this.service.set_cat_name(this.cat_name)
        this.emptyprdMsg = true;
        this.cat_pending = false;
      }
    }
  }

  startindex = 0;
  endindex = 1;

  getpadination(length) {

    console.log(length)

    return new Array(length / 1);
  }
  updateindex(index) {
    this.startindex = index * 1;
    this.endindex = this.startindex + 1
  }


  /** Single Product page redirect with data*/
  singlepage(product) {
    console.log(product);
    this.router.navigateByUrl("/product?pid=" + product.product_id);
  }

  ngOnDestroy() {
    this.service.set_cat_name('Categories')
  }

  /** Single Product page redirect with data*/
}
