import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LanguageService } from 'src/app/config/language';
import { LocalstorageService } from 'src/app/sys_config';
import { Request } from '../../_service/request'

@Component({
  selector: 'app-verification-process',
  templateUrl: './verification-process.component.html',
  styleUrls: ['./verification-process.component.scss'],
  providers: [LanguageService]
})
export class VerificationProcessComponent implements OnInit {

  constructor(
    private activeRoute: ActivatedRoute,
    private request: Request,
    private userStorage: LocalstorageService,
    private language: LanguageService
  ) { }

  activate_id = '';
  mail_success = '';
  mail_failed = '';
  pending = true;
  lang_dync = this.userStorage.current_language;
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
      this.activate_id = params['activate'];
      this.activation();
      console.log("::::::::::::::::::::::::::::::")
    });
  }

  async activation() {
    var temp = this.request.get_structure('email_activation', 'format');
    // temp.product_id = 2;
    console.log(this.activate_id);
    temp.token = this.activate_id;
    var status = await this.request.post(1, 'email_activation', temp);
    console.log(status);
    if (status[0] == 200) {
      this.mail_success = this.language.mLparams[this.lang_dync].mver_success;
      this.pending = false;
    }
    else if (status[0] == 400) {
      this.pending = false;
      this.mail_failed = this.language.mLparams[this.lang_dync].mver_failed;
    }
  }
}
