import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/config/language';
import { LocalstorageService } from 'src/app/sys_config';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss'],
  providers: [LanguageService]
})
export class PagenotfoundComponent implements OnInit {

  constructor(
    public language: LanguageService,
    private userStorage: LocalstorageService
  ) {
    
   }

   lang_dync = this.userStorage.current_language;
  ngOnInit(): void {
  }

}
