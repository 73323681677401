<body class="scrool">
    <div class="layout-body">
        <div>
            <nav class="navbar navbar-expand-md navbar-dark bg-dark bg-dk">
                <div class="container">

                    <a style="border: none; outline: none;" class="navbar-brand n-brnd" routerLink="/">
                        <img class="logo" src="assets\images\logo\Remus Nation - Logo (new).svg" alt="logo">
                    </a>
                </div>
            </nav>




            <nav class="navbar-expand-md bg-light navbar-dark bg-lyt">

                <div class="container">
                    <div class="navrow nave-rowline">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item pl-2 pl-md-0 ml-0 ml-md-2">
                                <a style="border: none; outline: none;" class="nav-link nav-lnk" href="#about">{{this.language.mLparams[this.lang_dync].aboutus}}</a>
                            </li>
                            <li>
                                <a  class="h-line pl-2 pl-md-0 ml-0 ml-md-2"></a>
                            </li>
                            <li class="nav-item pl-2 pl-md-0 ml-0 ml-md-2">
                                <a style="border: none; outline: none;" class="nav-link nav-lnk nv-lk2" href="mailto:contact@remusnation.com">{{this.language.mLparams[this.lang_dync].contactus}}</a>
                            </li>
                        </ul>

                    </div>

                </div>

            </nav>
        </div>

        <!--------------------------------------------------------------------------------------------------------------->

        <div>



            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">


                <div class="carousel-inner ">
                    <div class="carousel-item  active cslide1 ">
                        <!-- <img class="d-block w-100 " src="assets\images\banner\D shop banner(1920x626).jpg" alt="First slide"> -->
                        <!-- <div class="slider1"></div> -->
                        <div class="carousel-caption ">
                            <div class="col col-lg-4 col-md-4 col-sm-4" id="dshop-butt">

                                <a style="border: none; outline: none;"  href="https://remusnation.com/dshop/"  target="_blank"><img class=" shadbtn-size s-btn1 dslogo" src="assets\images\banner\d shop btn (white).png"></a>
                            </div>
                            <div class="container">
                               
                            </div>
                            <div class="container">
                                <p class="slide-item"><img class="s-item" src="assets\images\logo\D-Shop (288x58).svg"></p>
                                <p class="slide">{{this.language.mLparams[this.lang_dync].dshopline1}}<br>{{this.language.mLparams[this.lang_dync].dshopline2}} <br>{{this.language.mLparams[this.lang_dync].dshopline3}} <br>{{this.language.mLparams[this.lang_dync].dshopline4}}<br>{{this.language.mLparams[this.lang_dync].dshopline5}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item  cslide2">
                        <!-- <img class="d-block w-100  " src="assets\images\banner\D bay banner(1920x626).jpg" alt="Third slid"> -->
                        <div class="carousel-caption ">

                            <div class="col col-lg-4 col-md-4 col-sm-4" id="dshop-butt">

                                <a style="border: none; outline: none;"href="https://remusnation.com/dbay/"  target="_blank"><img class=" shadbtn-size s-btn1" src="assets\images\banner\d bay btn (white).png"></a>
                            </div>


                            <div class="container">
                                <p class="slide-item"><img class="s-item" src="assets\images\logo\D-Bay (288x58).svg"></p>
                                <p class="slide">{{this.language.mLparams[this.lang_dync].dbayline1}}<br>{{this.language.mLparams[this.lang_dync].dbayline2}} <br>{{this.language.mLparams[this.lang_dync].dbayline3}} <br>{{this.language.mLparams[this.lang_dync].dbayline4}}
                                    </p>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item  cslide3">
                        <!-- <img class="d-block w-100  " src="assets\images\banner\D market banner(1920x626).jpg" alt="Second slide"> -->

                        <div class="carousel-caption ">


                            <div class="col col-lg-4 col-md-4 col-sm-4" id="dshop-butt">
                                <a class="has-tipline" data-tip="COMING SOON!"><img class=" shadbtn-size s-btn1" src="assets\images\banner\d market btn (white).png"></a>
                            </div>


                            <div class="container">
                                <p class="slide-item"><img class="s-item" src="assets\images\logo\D-Market (288x58).svg"></p>
                                <p class="slide">{{this.language.mLparams[this.lang_dync].dmarketline1}}<br>{{this.language.mLparams[this.lang_dync].dmarketline2}} <br>{{this.language.mLparams[this.lang_dync].dmarketline3}} <br>{{this.language.mLparams[this.lang_dync].dmarketline4}}
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>


                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>


            </div>
            <!-- <img class="d-block w-100 shaodw" src="assets\images\shadow\Banner shaodw 1.png"> -->
            <!-- <div class="container-fluid"> -->
            <img class="shade-block shade-100" src="assets\images\shadow\Slider shadow 2.jpg">
            <div class="container c-btnrow mblbtn">
                <div class="row btnrow" style="text-align: center;">
                    <div class="col col-lg-4 col-md-4 col-sm-4" id="dshop-butt">

                        <a style="border: none; outline: none; cursor: unset;" href="https://remusnation.com/dshop/" target="_blank">
                            <img style="cursor: pointer;" class="shadbtn-size s-btn " src="assets\images\slider\d shop.png"></a>

                    </div>


                    <div class="col col-lg-4 col-md-4 col-sm-4" id="dbay-butt">

                        <a style="border: none; outline: none; cursor: unset;" href="https://remusnation.com/dbay/" target="_blank">
                            <img style="cursor: pointer;" class="shadbtn-size s-btn " src="assets\images\slider\d bay.png"></a>

                    </div>



                    <div class="col col-lg-4 col-md-4 col-sm-4" id="dmarket-butt">

                        <a style="border: none; outline: none; cursor: unset;" class="has-tipline" data-tip="COMING SOON!">
                            <img style="cursor: pointer;" class="shadbtn-size s-btn" src="assets\images\slider\d market.png"></a>

                    </div>

                </div>
            </div>





            <!-- </div> -->

            <!-- </div> -->





        </div>

        <!-------------------------------------------------------------------------------------------------------------------------------------->

        <div>
            <section id="about">
                <div class="container abtcont">

                    <div class="row aboutrow" style="overflow-x: hidden;">
                        <div class="col-lg-2 col-md-2 col-sm-2 ">
                            <img class="about" src="assets\images\icons\About us.svg">
                        </div>

                        <div class="col-lg-10 col-md-10 col-sm-10 ">
                            <h2 class="head">{{this.language.mLparams[this.lang_dync].abtus}}</h2>
                            <div class="paras">
                                <p class="abt">{{this.language.mLparams[this.lang_dync].landline7}}</p><br>
                                <p class="abt">{{this.language.mLparams[this.lang_dync].landline8}}</p>

                            </div>


                        </div>
                    </div>
                </div>
            </section>

            <div class="offer">
                <div class="container">
                    <h2 class="heading1">{{this.language.mLparams[this.lang_dync].remusoffers}}</h2>

                    <div class="row">


                        <div class=" col-sm-12 col-md-12 col-lg-12">
                            <div class="remusoffer">

                                <ul class="list-stylee">
                                    <li class="icon-1">
                                        <img class="icon1" src="assets\images\icons\Icon 1 - Remusnation offers.svg">
                                        <p class="lines"><b class="boldtxt">{{this.language.mLparams[this.lang_dync].landline1}}</b> {{this.language.mLparams[this.lang_dync].landline2}}</p>
                                    </li>
                                    <li class="icon-1">
                                        <img class="icon1" src="assets\images\icons\Icon 2 - Remusnation offers.svg">
                                        <p class="lines"><b class="boldtxt">{{this.language.mLparams[this.lang_dync].landline3}}</b> {{this.language.mLparams[this.lang_dync].landline4}}</p>
                                    </li>
                                    <li class="icon-1">
                                        <img class="icon1" src="assets\images\icons\Icon 3 - Remusnation offers.svg">
                                        <p class="lines"><b class="boldtxt">{{this.language.mLparams[this.lang_dync].landline5}}</b> {{this.language.mLparams[this.lang_dync].landline6}}</p>
                                    </li>
                                </ul>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</body>

