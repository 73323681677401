import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../../app/config/language/index';
import { LocalstorageService } from '../../sys_config/'

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
  providers: [LanguageService]
})
export class CookiePolicyComponent implements OnInit {

  constructor(
    public language: LanguageService,
    private userStorage: LocalstorageService,
    private activeroute: ActivatedRoute,
    private router: Router
  ) { }
  lang_dync = this.userStorage.current_language;
    Dshop_footer = false
  ngOnInit(): void {

    console.log(this.router.routerState.snapshot.url)
    if(this.router.routerState.snapshot.url == "/cookies"){
      this.Dshop_footer = true;
 
    }else {
      this.Dshop_footer = false;
    }

  }

}
