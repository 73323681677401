import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { Config } from '../sys_config/config';

import { Encryption } from '../_service/encryption';


@Injectable()
export class LocalstorageService {

  constructor(private config: Config,
    private Encryption: Encryption) { }

  storage_name = "system1";
  lang_json = {
    '0': 'english',
    '1': 'english',
    '2': 'french'
  }
  token = "token";
  current_language = this.lang_json[this.getUserInfo('lang_id')] ? this.lang_json[this.getUserInfo('lang_id')] : 'english';

  cart_value = this.getUserInfo('cart_value') ? this.getUserInfo('cart_value') : 0;
  cart_count = this.getUserInfo('cart_count') ? this.getUserInfo('cart_count') : 0;
  buynow = false;
  searchCount = 5;
  buynow_product: number;
  pageSize = 25;
  page = 1;
  secretKey = this.config.cfg.prdt_key.prdt;
  encryptedData: string

  setUserinfo(user: any) {
    try {
      if (this.config.cfg.env_mode.default == 'production') {
        // console.log("encrypted");
        // this.encryptedData = CryptoJS.AES.encrypt(JSON.stringify(user), this.secretKey).toString()
        // localStorage.setItem(this.storage_name, JSON.stringify(this.encryptedData));




        localStorage.setItem(this.storage_name, this.Encryption.encode(JSON.stringify(user)));

      } else if (this.config.cfg.env_mode.default == 'development') {
        localStorage.setItem(this.storage_name, JSON.stringify(user));
      }
      return true;
    } catch (error) {
      return false;
    }
  }


  getUserInfo(key: any) {
    try {
      if (this.config.cfg.env_mode.default == 'production') {


        var userdata = this.Encryption.decode(localStorage.getItem(this.storage_name));
        // var userdata = localStorage.getItem(this.storage_name);

        var obj = JSON.parse(userdata);
        // let bytes = CryptoJS.AES.decrypt(parsed_data, this.secretKey)
        // var obj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


        // console.log(obj);
      } else if (this.config.cfg.env_mode.default == 'development') {
        var parsed_data = localStorage.getItem(this.storage_name);
        var obj = JSON.parse(parsed_data);
        // console.log(obj)
      }
      return obj[key];
    } catch (error) {
      return false;
    }
  }

  getUserAllInfo() {
    if (this.config.cfg.env_mode.default == 'production') {
      var userInfos = [];

      var userdata = this.Encryption.decode(localStorage.getItem(this.storage_name));
      // var userdata = localStorage.getItem(this.storage_name);

      var obj = JSON.parse(userdata);
      for (var i = 0, len = localStorage.length; i < len; i++) {
        var key = localStorage.key(i);
        var value = localStorage[key];
        userInfos.push(obj[key]);
      }
      return userInfos;
    }
    else if (this.config.cfg.env_mode.default == 'development') {
      var userInfos = [];
      var userdata = localStorage.getItem(this.storage_name);
      var parsed_data = JSON.parse(userdata);
      for (var i = 0, len = localStorage.length; i < len; i++) {
        var key = localStorage.key(i);
        var value = localStorage[key];
        userInfos.push(parsed_data[key]);
      }
      return userInfos;
    }
  }

  updateUserInfo(key: any, data: any) {//for specific user data by key

    // console.log("0 >>", key, data);


    if (this.config.cfg.env_mode.default == 'development') {
      try {
        var userdata = localStorage.getItem(this.storage_name);
        var parsed_data = JSON.parse(userdata);
        parsed_data[key] = data;
        localStorage.setItem(this.storage_name, JSON.stringify(parsed_data));
        return true;
      } catch (error) {
        return null;
      }
    } else if (this.config.cfg.env_mode.default == 'production') {

      try {
        var userdata = this.Encryption.decode(localStorage.getItem(this.storage_name));
        // var userdata = localStorage.getItem(this.storage_name);

        if (userdata != 'encryption_error' || userdata.length != 0) {

          var obj = JSON.parse(userdata);

          obj[key] = data;

          localStorage.setItem(this.storage_name, this.Encryption.encode(JSON.stringify(obj)));

        }
      } catch (e) {

        // console.log(">>>IN", userdata.length, key, data, e)
        var temp = {}
        temp[key] = data
        localStorage.setItem(this.storage_name, this.Encryption.encode(JSON.stringify(temp)))
      }


    }
  }

  removeUserInfo(key: any) {//for specific user data by key
    try {

      var userdata = this.Encryption.decode(localStorage.getItem(this.storage_name));
      // var userdata = localStorage.getItem(this.storage_name);

      var parsed_data = JSON.parse(userdata);
      delete parsed_data[key];

      localStorage.setItem(this.storage_name, this.Encryption.encode(JSON.stringify(parsed_data)));

      return true;
    } catch (error) {
      return null;
    }
  }

  removeUserAllInfo() {
    var _current_lang = this.getUserInfo('lang_id');
    localStorage.removeItem(this.storage_name);
    this.setUserinfo({ 'lang_id': _current_lang });
    return true;
  }





}