import { Component, OnInit } from '@angular/core';
import { FormGroup, Validator, FormBuilder, Validators } from '@angular/forms'
import { APIs_Config, Config, LocalstorageService } from '../../sys_config'
import { Request } from '../../_service/request'


@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})
export class VoucherComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public config: APIs_Config,
    private pr_config:Config,
    private request: Request,) { }
  getVoucherDetails: FormGroup;
  logsubmitted = false;
  act_success : any;
  act_error: any;
  ngOnInit(): void {
    this.getVoucherDetails = this.formBuilder.group({
      input1: ['', [Validators.required]],
      input2: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9.]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    })
  }

  get voc() { return this.getVoucherDetails.controls }

  async onsubmitVoucher() {
    this.logsubmitted = true
    console.log("called")
    if (this.getVoucherDetails.invalid) {
      return;
    } else {
      console.log(this.getVoucherDetails.value);
      let temp = this.request.get_structure('get_voucher','format');
      temp.act_key = this.getVoucherDetails.value.input1;
      temp.email = this.getVoucherDetails.value.input2;
      temp =await this.request.post(1,'get_voucher',temp);
      console.log(temp);

      if(temp[0] == 400 ){
        // if(this.pr_config.cfg.env_mode.default == 'production')
        // {
        //   var data = JSON.parse(temp[1].response)
        //   this.act_error = data.data
        // }else{
          this.act_error = temp[1].response.data
        // }
   
        setTimeout(() => {
          this.act_error=''
          this.logsubmitted = false
          this.getVoucherDetails = this.formBuilder.group({
            input1: [''],
            input2: [''],
          })
        }, 3000);
       
      }
      if(temp[0] == 200){
        this.act_success =temp[1].response.data
        setTimeout(() => {
          this.act_success=''
          this.logsubmitted = false
          this.getVoucherDetails = this.formBuilder.group({
            input1: [''],
            input2: [''],
          })
        }, 3000);
        
      }
    }
    // if(this.getVoucherDetails[0]==200){

    // }
    // if(this.getVoucherDetails[0]==400){
    //   var err=this.getVoucherDetails[0].response.error;

    //   if(err="exist"){
    //     this.act_error=
    //   }

      
    // }
  }
}
