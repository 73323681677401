<div *ngIf="field2">
  <div class="row no-gutters">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-3 text-success text-center button">
          <svg width="2rem" height="2rem" viewBox="0 0 16 16" class="bi bi-check2-circle" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            <path fill-rule="evenodd"
              d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z" />
          </svg>
          Confirm Mobile Number
        </div>
        <div class="col-md-3 buttonActive">
          <svg width="2rem" height="2rem" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
          </svg>
          Store Name and Address
        </div>
        <div class="col-md-3 button text-danger">
          <svg width="2rem" height="2rem" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
          </svg>
          Taxes and Documents
        </div>
        <div class="col-md-3 button text-danger">
          <svg width="2rem" height="2rem" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
          </svg>
          Seller Dashboard
        </div>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>



  <!-- <form [formGroup]="sellerregForm" (ngSubmit)="onEnterStoreDetails()">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <h5>Store Name & Address</h5>
            <div class="row no-gutters">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Store Name</label>
                  <input required formControlName="storename" class="form-control" name="storename">
                  <div *ngIf="logsubmitted && f.storename.errors" class="invalid-feedback">
                    <div *ngIf="f.storename.errors.required">
                      Email is required
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr>

            <h5>Enter Your Store Address</h5>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Address Line 1</label>
                  <input required formControlName="addressname1" class="form-control mediaInput" name="addressForm1">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Address Line 2</label>
                  <input required formControlName="addressname2" class="form-control mediaInput" name="addressForm2">
                </div>
              </div>
            </div>
          </div>

          <br>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Country</label>
                <select placeholder="Select Country" class="custom-select mediaInput" name="wcountry" 
                  (change)="modelChanged($event)">
                  <option value="">Choose Country</option>
                  <option *ngFor="let list of countryList" class="opt" [value]="list.country_name">
                    {{list.country_name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>State</label>
                <select placeholder="Select State" class="custom-select mediaInput" name="cstate"
                  (change)="modelCity($event)">
                  <option value="">Choose State</option>
                  <option *ngFor="let list of country_state" class="opt" [value]="list.region">
                    {{list.region}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>City</label>
                <select placeholder="Select City" class="custom-select mediaInput" name="cscity" id="cscity"
                  (change)="modelPin($event)">
                  <option value="">Choose City</option>
                  <option *ngFor="let list of state_city" class="opt" [value]="list.city">
                    {{list.city}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Postal code</label>
                <select placeholder="Select City" class="custom-select mediaInput" name="cspost" >
                  <option value="">Choose Postal Code</option>
                  <option *ngFor="let list of city_postal" class="opt" [value]="list.postal_code">
                    {{list.postal_code}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="container" align="center">
      <button type="submit" class="btnPrimary text-center mb-4">save and continue</button>
    </div>
  </form> -->
  <app-add-address (addrEmitter)="receiveAddress($event)" [showMobile]="false" [formTitle]="'Add Shipping Address'">
  </app-add-address>
  <div class="container" align="center">
    <button type="submit" (click)="onEnterStoreDetails()" class="btnPrimary text-center mb-4">save and continue</button>
  </div>
</div>



<div *ngIf="field3">
  <div class="row no-gutters mb-5 border-bottom">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <div class="row">
        <div class="col-md-3 text-success text-center button">
          <svg width="2rem" height="2rem" viewBox="0 0 16 16" class="bi bi-check2-circle" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            <path fill-rule="evenodd"
              d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z" />
          </svg>
          Confirm Mobile Number
        </div>
        <div class="col-md-3 text-success text-center button">
          <svg width="2rem" height="2rem" viewBox="0 0 16 16" class="bi bi-check2-circle" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
            <path fill-rule="evenodd"
              d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z" />
          </svg>
          Store Name and Address
        </div>
        <div class="col-md-3 buttonActive">
          <svg width="2rem" height="2rem" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
          </svg>
          Taxes and Documents
        </div>
        <div class="col-md-3 button text-danger">
          <svg width="2rem" height="2rem" viewBox="0 0 16 16" class="bi bi-dash-circle" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
          </svg>
          Seller Dashboard
        </div>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>
  <form #taxForm="ngForm">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <h5>EnterYour Tax Details below</h5>
        <div class="row">
          <div class="col-md-6 d-lg-static d-md-block text-center">
            <div class="form-group">
              <label>Tax number 1</label>
              <input required #taxnum1="ngModel" ngModel class="form-control mediaInput" name="taxnum1">

            </div>
          </div>
          <div class="col-md-6 d-lg-static d-md-block text-center">
            <div class="form-group">
              <label>Tax number 2</label>
              <input required #taxnum2="ngModel" ngModel class="form-control mediaInput" name="taxnum2">

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 d-lg-static d-md-block text-center">
            <div class="form-group">
              <label>Tax number 3</label>
              <input required #taxnum3="ngModel" ngModel class="form-control mediaInput" name="taxnum3">
            </div>
          </div>
          <div class="col-md-6 d-lg-static d-md-block text-center">
            <div class="form-group">
              <label>Tax number 3</label>
              <input required #taxnum4="ngModel" ngModel class="form-control mediaInput" name="taxnum4">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </form>
  <hr>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <div>
        <label for="backImage">Document 1: </label>
        <input (change)="onFileSelected($event,'one')" type="file" id="one" name="one">
        <span *ngIf="span_one" class=" text-success">
          <svg _ngcontent-gwf-c108="" width="2rem" height="2rem" viewBox="0 0 16 16" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" class="bi bi-check2-circle">
            <path _ngcontent-gwf-c108="" fill-rule="evenodd"
              d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z">
            </path>
            <path _ngcontent-gwf-c108="" fill-rule="evenodd"
              d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z">
            </path>
          </svg>

        </span>

      </div>
      <div>
        <label for="backImage">Document 2: </label>
        <input (change)="onFileSelected($event,'two')" type="file" id="two" name="two">
        <span *ngIf="span_two" class=" text-success">
          <svg _ngcontent-gwf-c108="" width="2rem" height="2rem" viewBox="0 0 16 16" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" class="bi bi-check2-circle">
            <path _ngcontent-gwf-c108="" fill-rule="evenodd"
              d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z">
            </path>
            <path _ngcontent-gwf-c108="" fill-rule="evenodd"
              d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z">
            </path>
          </svg>
        </span>

      </div>
      <div>
        <label for="backImage">Document 3: </label>
        <input (change)="onFileSelected($event,'three')" type="file" id="three" name="three">
        <span *ngIf="span_three" class=" text-success">
          <svg _ngcontent-gwf-c108="" width="2rem" height="2rem" viewBox="0 0 16 16" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" class="bi bi-check2-circle">
            <path _ngcontent-gwf-c108="" fill-rule="evenodd"
              d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z">
            </path>
            <path _ngcontent-gwf-c108="" fill-rule="evenodd"
              d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z">
            </path>
          </svg>
        </span>


        <div *ngIf="error_message">
          {{error_message}}
        </div>


        <!-- <button class="btn btn-primary"  >Upload</button> -->
      </div>
      <div>
      </div>
    </div>
  </div>
  <div class="container" align="center">
    <button type="submit" class="btnPrimary" (click)="onEnterTaxDetails(taxForm.value)">save and continue</button>
  </div>
  <div *ngIf="field4">
    <h5>this is field 4</h5>
  </div>


  <!-- 
<div>
  <label for="backImage">Back Image: </label>
  <input (change)="onFileSelected($event,'one')" type="file" id="backImage">
  <button class="btn btn-primary"  >Upload</button>
</div>
<div>
  <label for="backImage">Back Image: </label>
  <input (change)="onFileSelected($event,'two')" type="file" id="backImage">
  <button class="btn btn-primary"  >Upload</button>
</div>
<div>
  <label for="backImage">Back Image: </label>
  <input (change)="onFileSelected($event,'three')" type="file" id="backImage">
  <button class="btn btn-primary"  >Upload</button>
</div> -->