<div class="container">
    <div class="row">
        <div class="col-md-3 adminSidebar" style="margin-top: 1%;">
            <div class="nav flex-column nav-pills p-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <a class="nav-link active dashboadrbtn" align="center" id="profileinformation-tab" data-toggle="pill"
                    (click)="getProfileData()" href="#profileinformation" role="tab" aria-controls="profileinformation"
                    aria-selected="true">
                    {{this.language.mLparams[this.lang_dync].prof_info}}</a>

                <a class="nav-link dashboadrbtn" align="center" id="deliveryaddress-tab" data-toggle="pill"
                    href="#deliveryaddress" (click)="getExistingAddr()" role="tab" aria-controls="deliveryaddress"
                    aria-selected="false">
                    {{this.language.mLparams[this.lang_dync].delv_add}}</a>

                <a class="nav-link dashboadrbtn" align="center" id="my-orders-tab" data-toggle="pill" href="#my-orders"
                    role="tab" (click)="getMyOrders()" aria-controls="my-orders"
                    aria-selected="false">{{this.language.mLparams[this.lang_dync].my_orders}}</a>

                <a class="nav-link dashboadrbtn" align="center" id="reset-password-tab" data-toggle="pill"
                     href="#reset-password" role="tab" aria-controls="reset-password"
                    aria-selected="false">{{this.language.mLparams[this.lang_dync].resetpwd}}</a>


            </div>
        </div>

        <div class="col-md-9" style="margin-top: 2%;">
            <!-- 1 -->

            <div class="tab-content" id="v-pills-tabContent">

                <div class="tab-pane fade show active" id="profileinformation" role="tabpanel"
                    aria-labelledby="profileinformation-tab">
                    <form [formGroup]="personalInfo" (ngSubmit)="updateProfileData()">
                        <div class="form-row">
                            <div class="form-group col-lg-4">
                                <label> {{this.language.mLparams[this.lang_dync].usrnme}}</label>
                                <input type="text" formControlName="email" class="form-control personalInfo"
                                    [ngClass]="{ 'is-invalid': submitted && pf.email.errors }" readonly
                                    [value]="username" />
                                <div *ngIf="submitted && pf.email.errors" class="invalid-feedback">
                                    <div *ngIf="pf.email.errors.required">
                                        {{this.language.mLparams[this.lang_dync].emailreq}}</div>
                                    <div *ngIf="pf.email.errors.email || pf.email.errors.pattern">
                                        {{this.language.mLparams[this.lang_dync].validemail}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label> {{this.language.mLparams[this.lang_dync].emil}}</label>
                                <input type="text" formControlName="email" class="form-control personalInfo"
                                    [ngClass]="{ 'is-invalid': submitted && pf.email.errors }" readonly
                                    [value]="email" />
                                <div *ngIf="submitted && pf.email.errors" class="invalid-feedback">
                                    <div *ngIf="pf.email.errors.required">
                                        {{this.language.mLparams[this.lang_dync].emailreq}}</div>
                                    <div *ngIf="pf.email.errors.email || pf.email.errors.pattern">
                                        {{this.language.mLparams[this.lang_dync].validemail}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">

                            <div class="form-group col-lg-4">
                                <label>{{this.language.mLparams[this.lang_dync].firstname}}</label>
                                <input autofocus type="text" formControlName="firstName"
                                    class="form-control personalInfo" [ngClass]="{
                            'is-invalid': submitted && pf.firstName.errors
                          }" [readonly]="isReadonly" [value]="firstName" />
                                <div *ngIf="submitted && pf.firstName.errors" class="invalid-feedback">
                                    <div *ngIf="pf.firstName.errors.required">
                                        {{this.language.mLparams[this.lang_dync].requiredfield}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-lg-4">
                                <label>{{this.language.mLparams[this.lang_dync].lastname}}</label>
                                <input autofocus type="text" formControlName="lastName"
                                    class="form-control personalInfo" [ngClass]="{
                            'is-invalid': submitted && pf.lastName.errors
                          }" [readonly]="isReadonly" [value]="lastName" />
                                <div *ngIf="submitted && pf.lastName.errors" class="invalid-feedback">
                                    {{this.language.mLparams[this.lang_dync].requiredfield}}
                                </div>
                            </div>




                            <!-- <label>{{this.language.mLparams[this.lang_dync].gender}}</label> -->
                            <!-- <div class="genderContainer"> -->
                            <!-- <button class="male" (click)="!isReadonly; male()"
                                    [ngStyle]="{'backgroundColor': btnClickedMale ? '#0543a5': '#dddddd'}">M</button>
                                <button  class="female" (click)="!isReadonly; female()"
                                    [ngStyle]="{'backgroundColor': btnClickedFemale ? '#0543a5': '#dddddd'}">F</button> -->

                            <!-- <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label class="btn btn-secondary active">
                                        <input type="radio" name="gender" (change)="genderCheck($event)" value="male"
                                            autocomplete="off" checked> M
                                    </label>
                                    <label class="btn btn-secondary">
                                        <input type="radio" name="gender" value="female" (change)="genderCheck($event)"
                                            autocomplete="off"> F
                                    </label>
                                </div> -->

                            <!-- </div> -->


                        </div>
                        <div class="form-row">
                            <div class="form-group col-lg-4">
                                <label>{{this.language.mLparams[this.lang_dync].mbl}}</label>
                                <input type="tel" formControlName="mobile" class="form-control personalInfo"
                                    [ngClass]="{ 'is-invalid': submitted && pf.mobile.errors }" [readonly]="isReadonly"
                                    [value]="mobile" />
                                <div *ngIf="submitted && pf.mobile.errors" class="invalid-feedback">
                                    <div *ngIf="pf.mobile.errors.required">
                                        {{this.language.mLparams[this.lang_dync].requiredfield}}
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="row" *ngIf="profileUpdateSuccess">
                            <p class="text-success">{{this.language.mLparams[this.lang_dync].profileSuccess}}</p>
                        </div>
                        <div class="row" *ngIf="profileUpdateFailed">
                            <p class="text-danger">{{this.language.mLparams[this.lang_dync].profileFailed}}</p>
                        </div>
                        <div class="form-row ">
                            <div class="form-group col-4 d-flex">
                                <button type="button" class="btn " style="margin-right: 15px;" data-toggle="tooltip"
                                    title="Edit" (click)="toggleReadonly()"><img src="assets/edit-black-18dp.svg"
                                        height="25px" width="25px" /></button>
                                <button type="submit" [disabled]="isSaveDisabled"
                                    class="btn loginregbtn ml-1">{{this.language.mLparams[this.lang_dync].save}}</button>
                            </div>
                        </div>
                    </form>
                    <div style="height: 250px;"></div>
                </div>
                <!-- 1 -->
                <!-- 2 -->
                <div class="tab-pane fade" id="deliveryaddress" role="tabpanel" aria-labelledby="deliveryaddress-tab">
                    <div class="row" *ngIf="noAddress" style="margin: 1%;">
                        <h3>{{this.language.mLparams[this.lang_dync].noadrfnd}}</h3>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-md-9 col-sm-12 col-lg-5 addressCard m-3  wrapTxtWithinDiv"
                            *ngFor="let list of addressList">
                            <!-- <address> -->

                            <!-- <div class="radio_btn rdb"> -->
                            <!-- <label for="radio_{{list.address_id}}" style="padding: 0 10px 0 20px"> -->
                            <!-- <input type="radio" id="radio_{{list.address_id}}" name="radios"
                                    value="{{list.address_id}}" (change)="addressChange($event.target.value)"
                                    style="margin-right: 5px;">
                                    
                                    <b>{{language.mLparams[this.lang_dync].makedefault}}</b>
                                    
                                    <br><br> -->
                            <!-- </div> -->
                            <div style="margin-left: 10px;">
                                <p class="m-0 p-0"><b>{{list.address_name | titlecase}}</b></p>
                                <p class="m-0 p-0">{{list.address_phone}}</p>
                                <p class="m-0 p-0">{{list.address_line1}}</p>
                                <p class="m-0 p-0" *ngIf="showAddrLineTwo(list.address_line2)">{{list.address_line2}}
                                </p>
                                <p class="m-0 p-0">{{list.city}}</p>
                                <p class="m-0 p-0">{{list.region}}</p>
                                <p class="m-0 p-0">{{list.country}}</p>
                                <p class="m-0 p-0">{{list.postal_code}}</p>
                            </div>
                            <br>
                            <br>
                            <!-- </label> -->
                            <!-- </address> -->
                            <div class="radio_btn ml-2">
                                <button type="button" (click)="conformdeleteAddress(list,conformdelete)"
                                    class="btn btn-danger">{{language.mLparams[this.lang_dync].delete}}</button>
                                <button (click)="edit(editaddress,list)" class="btn btn-primary ml-2">Edit</button>


                            </div>
                        </div>
                        <div *ngIf="AddressAddBtn" class="col-md-3" style="padding: 8rem; margin-left: 0rem;">
                            <a>
                                <button class="btn cartplusbtn" (click)="onAddaddress()"> <i class="fa fa-plus buyplus"
                                        data-toggle="tooltip" data-placement="right"
                                        title="Add Address"></i></button></a>

                        </div>

                    </div>
                    <div class="row float-right no-gutters" *ngIf="!AddressAddBtn">
                        <button type="button" class="close" (click)="onAddaddress()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="row" id="address_me">
                        <div class="col-md-12 col-lg-12 addaddr" *ngIf="!AddressAddBtn">
                            <app-add-address (addrEmitter)="addAddress($event)" [showMobile]="true"
                                [formTitle]="'Add Address'">
                            </app-add-address>
                        </div>

                    </div>
                    <div style="height: 250px;"></div>

                </div>
                <!-- 2 -->
                <!-- 3 -->
                <div class="tab-pane fade" id="my-orders" role="tabpanel" aria-labelledby="orders-tab">
                    <!-- <div class="form-group form-inline">
                    Full text search: <input class="form-control ml-2" type="text" name="searchTerm" [(ngModel)]="tabservice.searchTerm"/>
                    <span class="ml-3" *ngIf="tabservice.loading$ | async">Loading...</span>
                </div> -->
                    <div class="table-responsive">
                        <table class="table table-striped" *ngIf="!emptyOrder">
                            <thead>
                                <tr>
                                    <th (sort)="onSort($event)">{{this.language.mLparams[this.lang_dync].invoiceId}}
                                    </th>
                                    <!-- <th (sort)="onSort($event)">{{this.language.mLparams[this.lang_dync].images}}</th>
                                <th (sort)="onSort($event)">{{this.language.mLparams[this.lang_dync].orderId}}</th> -->
                                    <!-- <th>{{this.language.mLparams[this.lang_dync].productName}}</th>
                                <th>{{this.language.mLparams[this.lang_dync].delv_add}}</th> -->
                                    <!-- <th>{{this.language.mLparams[this.lang_dync].orderStatus}}</th> -->
                                    <th>{{this.language.mLparams[this.lang_dync].paymentStauts}}</th>
                                    <th>{{this.language.mLparams[this.lang_dync].orderDate}}</th>
                                    <th>{{this.language.mLparams[this.lang_dync].total}}</th>
                                    <th>{{this.language.mLparams[this.lang_dync].viewdetails}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let orders of orderedProducts" style="font-size: 14px;">
                                    <td class="cat"  placement="bottom" ngbTooltip="{{orders.invoice_id}}" tooltipClass="my-custom-class">{{orders.invoice_id}}</td>
                                    <!-- <td><img src="{{serverUrl}}{{orders.image_url}}" height="50px" width="50px" /></td>
                                <td>{{orders.order_id}}</td>
                                <td>{{orders.product_name}}</td>
                                <td>{{orders.address_line1}}</td>
                                <td>{{orders.order_status}}</td> -->
                                    <td *ngIf="!partial_payment">{{orders.payment_status | titlecase}}</td>
                                    <td *ngIf="partial_payment">{{orders.payment_status | titlecase}}</td>
                                    <td>{{orders.purchase_date | date : "medium" }}</td>
                                    <td>{{orders.total}}</td>
                                    <td><button data-toggle="modal" (click)="getSingleOrderDetails(orders)"
                                            data-target="#viewdetailsModal"
                                            class="btn loginregbtn">{{this.language.mLparams[this.lang_dync].viewdetails}}</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="container" *ngIf="emptyOrder">
                        <h4><b>{{emptyOrderMsg}}</b></h4>
                    </div>
                    <div class="d-flex justify-content-between p-2" *ngIf="!emptyOrder">
                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                            (pageChange)="refreshOrders()">
                        </ngb-pagination>

                        <select class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                            (ngModelChange)="refreshOrders()">

                            <option [ngValue]="10">{{this.language.mLparams[lang_dync].itmprpage10}}</option>
                            <option [ngValue]="25">{{this.language.mLparams[lang_dync].itmprpage25}}</option>
                        </select>
                    </div>
                    <p class="text-muted" *ngIf="!emptyOrder">
                        {{this.language.mLparams[this.lang_dync].myorderdesclaimer}}
                    </p>
<div style="height: 500px;"></div>
                </div>


                <!-- 3 -->
                <!-- 4 -->
                <div class="tab-pane fade" id="reset-password" [ngClass]="{'disableDiv': is_bo_usr}" role="tabpanel"
                    aria-labelledby="reset-password-tab">
                    <h4>{{this.language.mLparams[lang_dync].rstpwd}}</h4>
                    <p>{{this.language.mLparams[lang_dync].pswreset}}</p>
                    <!-- <br> -->
                    <br>
                    <div class="alert alert-danger" role="alert" *ngIf="error_poup">
                        {{this.language.mLparams[this.lang_dync].error_poup}}
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="error_poup2">
                        {{this.language.mLparams[this.lang_dync].error_poup2}}
                    </div>
                    <div class="alert alert-success" role="alert" *ngIf="error_poup3">
                        {{this.language.mLparams[this.lang_dync].error_poup3}}
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="error_poup4">
                        {{this.language.mLparams[this.lang_dync].error_poup4}}
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf="error_poup5">
                        {{this.language.mLparams[this.lang_dync].error_poup4}}
                    </div>
                    <!-- <app-resetpassword></app-resetpassword> -->
                    <!-- <label>olde password</label>
                <input type="text" [(ngModel)]="Oldpassword"> {{Oldpassword}}
                <label>New password</label>
                <input type="text" [(ngModel)]="Newpassword"> {{Newpassword}}
                <button (click)="resetme()">submite</button> -->
                    <form [formGroup]="resetpassword" (ngSubmit)="resetme()">
                        <div class="form-row">
                            <div class="form-group col-lg-4">
                                <label><b>{{this.language.mLparams[lang_dync].oldpasswd}}</b></label>
                                <input autofocus type="password" formControlName="oldepassword"
                                    class="form-control loginFormInput"
                                    [ngClass]="{ 'is-invalid': confirmme1 &&  f.oldepassword.errors  }"
                                    (keyup)="Confirmme1()" (blur)="blurconfirm1()" />
                                <div *ngIf="f.oldepassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.oldepassword.errors.required">
                                        {{this.language.mLparams[this.lang_dync].pass_required}}
                                    </div>
                                    <div *ngIf="f.oldepassword.errors.pattern">
                                        {{this.language.mLparams[this.lang_dync].password_structure}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-lg-4">
                                <label><b> {{this.language.mLparams[this.lang_dync].nwpwd}}</b></label>
                                <input autofocus type="password" formControlName="password"
                                    class="form-control loginFormInput"
                                    [ngClass]="{ 'is-invalid':  confirmme2 && f.password.errors  }"
                                    (keyup)="Confirmme2()" (blur)="blurconfirm2()" />
                                <div *ngIf="f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">
                                        {{this.language.mLparams[this.lang_dync].pass_required}}
                                    </div>
                                    <div *ngIf="f.password.errors.pattern">
                                        {{this.language.mLparams[this.lang_dync].password_structure}}
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-lg-4">
                                <label><b> {{this.language.mLparams[this.lang_dync].cnpwd}}</b></label>
                                <input autofocus type="password" formControlName="confirmPassword"
                                    class="form-control loginFormInput"
                                    [ngClass]="{ 'is-invalid': confirmme && f.confirmPassword.errors.required || confirmme_ }"
                                    (blur)="Confirmme()" (keyup)="keyupme()" />

                                <div *ngIf="f.confirmPassword.errors" class="invalid-feedback">
                                    <div *ngIf="f.confirmPassword.errors.required">
                                        {{this.language.mLparams[this.lang_dync].cpass_required}}
                                    </div>
                                    <div *ngIf="confirmme_">
                                        {{this.language.mLparams[this.lang_dync].pwd_match}}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>
                            <input type="submit" class="btn loginregbtn float-center pr-5 pl-5" value="Submit"
                                [disabled]="processed" (click)="onSubmit()">
                        </div>
                    </form>
                    <br>
                    <h5 *ngIf="is_bo_usr"><b>Not Allowed:</b> Backoffice User account password can only be modified/changed from the backoffice portal <img src="assets/caution red.svg" width="25px" height="25px"></h5>
               <div style="height: 250px;"></div>
                </div>

                <!-- <div style="margin-bottom: 190px;"></div> -->

                <!-- 4 -->
                <!-- <div class="tab-pane fade" id="v-pills-allProducts" role="tabpanel" aria-labelledby="v-pills-settings-tab">

            </div>
            <div class="tab-pane fade" id="v-pills-allBuyers" role="tabpanel" aria-labelledby="v-pills-settings-tab">

            </div> -->
            </div>
        </div>
    </div>
</div>

<!-- view details modal -->
<div class="modal fade viewdetailsModal-modal-lg" data-backdrop="static" data-keyboard="false" id="viewdetailsModal"
    tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog  modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="viewdetailsModal">
                    {{this.language.mLparams[this.lang_dync].orderDetails}}
                </h5>
                <button type="button" class="close" id="closeOrderdata" data-dismiss="modal" aria-label="Close"
                    (click)="closeViewDetails()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="singleOrderData">
                <div>
                    <div class="row">
                        <div class="col-md-3">
                            <h5>{{this.language.mLparams[this.lang_dync].orderDate}}</h5>
                            <p>{{singleOrderData.purchase_date | date : "medium" }}</p>
                        </div>
                        <div class="col-md-5">
                            <h5>{{this.language.mLparams[this.lang_dync].invoiceId}}</h5>
                            <p>{{singleOrderData.invoice_id}}</p>
                        </div>
                        <div class="col-md-4">
                            <!-- <button
                                class="btn btn btn-outline-dark float-right">{{this.language.mLparams[this.lang_dync].helpandSupport}}</button> -->
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-striped" *ngIf="!emptyOrder">
                            <thead>
                                <tr>
                                    <th>{{this.language.mLparams[this.lang_dync].orderId}}</th>
                                    <th colspan="2">{{this.language.mLparams[this.lang_dync].product}}</th>
                                    <!-- <th></th> -->
                                    <th>{{this.language.mLparams[this.lang_dync].quantity}}</th>
                                    <th>{{this.language.mLparams[this.lang_dync].priceperunit}}({{this.language.mLparams[this.lang_dync].dollor}})
                                    </th>
                                    <!-- <th>{{this.language.mLparams[this.lang_dync].shippmentCost}}</th> -->
                                    <th>{{this.language.mLparams[this.lang_dync].shippmentStatus}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let orders of singleOrderData.products">
                                    <td>{{orders.order_id}}</td>
                                    <td><img src="{{serverUrl}}{{orders.image_url}}" height="50px" width="50px" /></td>
                                    <td>{{orders.product_name}}</td>
                                    <td>{{orders.qty}}</td>
                                    <td>{{orders.amount_payed  | number: '.1-3'}}</td>
                                    <td>{{orders.order_status | titlecase}}</td>
                                </tr>
                                <!-- <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td colspan="5">shippment Cost - {{singleOrderData.shipment_cost}}</td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                    <div class="container" style="text-align: right;">
                        {{this.language.mLparams[this.lang_dync].shippmentCost}} :
                        {{this.language.mLparams[this.lang_dync].dollor}} {{singleOrderData.shipment_cost}}
                    </div>
                    <hr>
                    <div class="container" style="text-align: center;">
                        <h3>{{this.language.mLparams[this.lang_dync].totalInvoiceValue}}-
                            {{this.language.mLparams[this.lang_dync].dollor}} {{singleOrderData.total}}
                        </h3>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-6">
                            <h6>{{this.language.mLparams[this.lang_dync].delvadd}}</h6>

                            <p class="m-0 p-0" *ngFor="let addr of delivery_address">{{addr}}</p>
                        </div>
                        <div class="col-md-6" *ngIf="!partial_payment">
                            <div class="row">
                                <div class="col">
                                    <h6>{{this.language.mLparams[this.lang_dync].pymntstus}}</h6>
                                </div>
                                <div class="col text-success">
                                    <h6 *ngIf="statusUpdate">{{this.language.mLparams[this.lang_dync].stsupdtdsucss}}
                                    </h6>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleOrderData.payall">
                                <div class="col-4">
                                    {{this.language.mLparams[this.lang_dync].pymnt}}
                                </div>
                                <div class="col-4 text-danger">
                                    {{this.language.mLparams[this.lang_dync].fild}}
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-outline-success btn-sm" (click)="bothRepay(singleOrderData)">
                                        <b>{{this.language.mLparams[this.lang_dync].rpay}}</b>
                                    </button>
                                </div>
                            </div>
                            <div class="row" *ngFor="let payment of singleOrderData.payments">
                                <div class="col-4" *ngIf="payment.payment_flag=='both'">
                                    {{this.language.mLparams[this.lang_dync].pymnt}}
                                </div>
                                <div class="col-4" *ngIf="payment.payment_flag=='payment'">
                                    {{this.language.mLparams[this.lang_dync].prdt}}
                                </div>
                                <div class="col-4" *ngIf="payment.payment_flag=='shipment'">
                                    {{this.language.mLparams[this.lang_dync].shpmnt}}
                                </div>
                                <div class="col-4">
                                    {{payment.status | titlecase}}
                                </div>
                                <div class="col-4" *ngIf="payment.status == 'pending'">
                                    <button (click)="paymentStatus(payment.ref_id)" data-toggle="tooltip"
                                        title="Refresh Payment Status" class="btn btn-outline-dark btn-sm mt-1"> <img
                                            src="assets/refresh.svg" height="15px" width="15px" /></button>
                                </div>
                                <div class="col-4" *ngIf="payment.status == 'cancelled' || payment.status == 'failed'">
                                    <button class="btn btn-outline-success btn-sm mt-1"
                                        (click)="bothRepay(singleOrderData)"><b>{{this.language.mLparams[this.lang_dync].rpay}}
                                        </b></button>
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="payall && singleOrderData.payments.length > 0">
                                <div class="col-8">
                                    {{this.language.mLparams[this.lang_dync].shppaymntmsg1}}
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-outline-success btn-sm"
                                        (click)="shipmentPay(singleOrderData)"><b>{{this.language.mLparams[this.lang_dync].pynw}}</b></button>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleOrderData.payments.length == 0 && !singleOrderData.payall">
                                <div class="col-8">
                                    {{this.language.mLparams[this.lang_dync].shppaymntmsg2}}
                                </div>
                                <button class="btn btn-outline-success btn-sm"
                                    (click)="gotopayment(singleOrderData)"><b>{{this.language.mLparams[this.lang_dync].pynw}}</b></button>
                            </div>
                        </div>
                        <div class="col-md-6 " *ngIf="partial_payment">


                            <div class="row">
                                <div class="col">
                                    <h6>{{this.language.mLparams[this.lang_dync].pymntstus}} by partial payment</h6>
                                </div>
                                <div class="col text-success">
                                    <h6 *ngIf="statusUpdate">{{this.language.mLparams[this.lang_dync].stsupdtdsucss}}
                                    </h6>
                                </div>
                            </div>
                            <div class="row" *ngIf="singleOrderData.payall">
                                <div class="col-4">
                                    {{this.language.mLparams[this.lang_dync].pymnt}}
                                </div>
                                <div class="col-4 text-danger">
                                    {{this.language.mLparams[this.lang_dync].fild}}
                                </div>
                                <div class="col-4">
                                    <button class="btn btn-outline-success btn-sm" (click)="bothRepay(singleOrderData)">
                                        <b>{{this.language.mLparams[this.lang_dync].rpay}}</b>
                                    </button>
                                </div>
                            </div>
                            <div class="row" *ngFor="let payment of singleOrderData.payments">
                                <div class="col-4" *ngIf="payment.payment_flag=='payment_1'">
                                    DUC payment
                                </div>
                                <div class="col-4" *ngIf="payment.payment_flag=='payment_2'">
                                    Fiat Payment
                                </div>
                                <div class="col-4" *ngIf="payment.payment_flag=='payment_3'">
                                    Crypto payment
                                </div>
                                <div class="col-4">
                                    {{payment.status | titlecase}}
                                </div>
                                <!-- <div class="col-4" *ngIf="payment.status == 'pending'">
                                    <button (click)="paymentStatus(payment.ref_id)" data-toggle="tooltip"
                                        title="Refresh Payment Status" class="btn btn-outline-dark btn-sm mt-1"> <img
                                            src="assets/refresh.svg" height="15px" width="15px" /></button>
                                </div> -->
                                <div class="col-4" *ngIf="payment.status == 'cancelled' || payment.status == 'failed'">
                                    <div *ngIf="singleOrderData.payments[0].payment_flag == 'payment_2'">
                                        <button class="btn btn-outline-success btn-sm mt-1"
                                            (click)="partial_pay(singleOrderData)"><b>{{this.language.mLparams[this.lang_dync].rpay}}sadasd
                                            </b></button>
                                    </div>
                                    <div *ngIf="singleOrderData.payments[0].payment_flag == 'payment_3'">
                                        <button class="btn btn-outline-success btn-sm mt-1"
                                            (click)="paymentStatus2(payment.ref_id,content_repay)"><b>{{this.language.mLparams[this.lang_dync].rpay}}
                                                wallet
                                            </b></button>
                                    </div>


                                </div>

                            </div>
                            <!-- <div><p>{{reference_id}}</p>
                            <p>{{wallet_id}}</p>
                            </div> -->
                            <ng-template #content_repay let-modal>
                                <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Repayment model</h4>
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body" style="text-align: center;">
                                    <div class="row scanpay">
                                        <div class="row col-lg-12">
                                            <div class="col">
                                                <p style="margin-left: 25px;">Scan to Pay Payment</p>

                                            </div>
                                        </div>
                                        <div class="col ">

                                            <ngx-qrcode *ngIf="!pend" [elementType]="elementType" [value]="wallet_id">
                                            </ngx-qrcode>
                                            <h6>Amount = {{crypto_Amount2}} {{crypto_pay}}</h6>
                                            <div class="form-group">
                                                <label
                                                    for="resource-id">{{this.language.mLparams[lang_dync].wltadrs}}</label>

                                                <div class=" col-12 ">

                                                    <div class=" input-group ">
                                                        <input id="resource-id" value="{{wallet_id}}" type="text"
                                                            class="form-control" name="id" placeholder="Payment Address"
                                                            readonly="readonly">
                                                        <button class="input-group-text" (click)="clicktocopy()"
                                                            [cdkCopyToClipboard]="wallet_id">{{copytext}}</button>
                                                    </div>

                                                </div>

                                            </div>
                                            <div>
                                                <button class="btn btn-success scanbtn">Confirm</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </ng-template>



                            <!-- <div class="row mt-2" *ngIf="payall && singleOrderData.payments.length > 0">
                                    <div class="col-8">
                                        {{this.language.mLparams[this.lang_dync].shppaymntmsg1}}
                                    </div>
                                    <div class="col-4">
                                        <button class="btn btn-outline-success btn-sm" (click)="shipmentPay(singleOrderData)"><b>{{this.language.mLparams[this.lang_dync].pynw}}</b></button>
                                    </div>
                                </div>
                                <div class="row" *ngIf="singleOrderData.payments.length == 0 && !singleOrderData.payall">
                                    <div class="col-8">
                                        {{this.language.mLparams[this.lang_dync].shppaymntmsg2}}
                                    </div>
                                    <button class="btn btn-outline-success btn-sm" (click)="gotopayment(singleOrderData)"><b>{{this.language.mLparams[this.lang_dync].pynw}}</b></button>
                                </div> -->
                            <!-- <div class="row mt-2" *ngIf="payall && singleOrderData.payments.length > 0">
                                    <div class="col-8">
                                        {{this.language.mLparams[this.lang_dync].shppaymntmsg1}}
                                    </div>
                                    <div class="col-4">
                                        <button class="btn btn-outline-success btn-sm" (click)="partial_pay(singleOrderData)"><b>{{this.language.mLparams[this.lang_dync].pynw}}</b></button>
                                    </div>
                                </div>-->
                            <div class="row" *ngIf="singleOrderData.payments.length == 0 && !singleOrderData.payall">
                                <div class="col-8">
                                    {{this.language.mLparams[this.lang_dync].shppaymntmsg2}}asasas
                                </div>asas
                                <button class="btn btn-outline-success btn-sm"
                                    (click)="gotopayment(singleOrderData)"><b>{{this.language.mLparams[this.lang_dync].pynw}}</b></button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- view details modal end-->


<!--    update address modal start-->
<ng-template #editaddress let-modal>
    <div class="modal-header">

        <h4 class="modal-title" id="modal-basic-title">{{this.language.mLparams[this.lang_dync].proupdt}}</h4>


        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="edit_address" (ngSubmit)="update_address(editaddress,edit_address)">
            <div class="form-group">
                <label><b>{{this.language.mLparams[lang_dync].name}}</b></label>
                <input type="text" class="form-control" formControlName="address_name">
                <label><b>{{this.language.mLparams[lang_dync].mbleno}}</b></label>
                <input class="form-control" formControlName="phone_no">
                <label><b>{{this.language.mLparams[lang_dync].adrsline1}}</b></label>
                <input class="form-control" formControlName="address_line1">
                <label><b>{{this.language.mLparams[lang_dync].adrsline2}}</b></label>
                <input class="form-control" formControlName="address_line2">
                <label><b>{{this.language.mLparams[lang_dync].city}}</b></label>
                <input class="form-control" formControlName="city">
                <label><b>{{this.language.mLparams[lang_dync].region}}</b></label>
                <input class="form-control" formControlName="region">
                <label><b>{{this.language.mLparams[lang_dync].country}}</b></label>
                <input class="form-control" formControlName="country">
                <label><b>{{this.language.mLparams[lang_dync].zipcode}}</b></label>
                <input class="form-control" formControlName="postal_code">
            </div>
            <div *ngIf="editSuccess" class="text-success">{{this.language.mLparams[lang_dync].editSuccess}}</div>
            <div *ngIf="editFailed" class="text-danger">{{this.language.mLparams[lang_dync].editFailed}}</div>
            <div class="modal-footer">
                <input type="submit" class="btn btn-outline-dark" value="Save">
            </div>
        </form>
    </div>

</ng-template>

<!--  update address model  status  end-->


<!-- conform delete modal -->
<ng-template #conformdelete let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title " ngbAutofocus>{{this.language.mLparams[lang_dync].dltadrs}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{this.language.mLparams[lang_dync].clickdltadrs}}</p>
        <h6 *ngIf="deletesuccess" class="text-success">{{this.language.mLparams[lang_dync].adrsdltsucs}}</h6>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger"
            (click)="deleteAddress()">{{this.language.mLparams[lang_dync].cnfrm}}</button>
    </div>
</ng-template>
<!--  update address model  status  end-->