import { Injectable } from '@angular/core';

import {
    HttpClient, HttpHeaders,
    HttpInterceptor, HttpRequest,
    HttpHandler, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { Encryption } from './encryption';
import { catchError } from 'rxjs/operators';
import { Config, APIs_Config, LocalstorageService } from '../sys_config/index';



@Injectable()
export class Request {
    constructor(
        private http: HttpClient,
        private config: Config,
        private apis_config: APIs_Config,
        private encryption: Encryption,
        private userStorage: LocalstorageService,
    
    ) { }

    
    payload_data: any;

    headers: any;
    token = '';
    server_url = this.config.cfg.protocol.default + "://" + this.config.cfg.host.default + "" + this.config.cfg.port.default + "/";

    async post(auth, key, json_data) {
        return new Promise((resolve: Function, reject: Function) => {
            var url = this.config.cfg.protocol.default + "://" + this.config.cfg.host.default + "" + this.config.cfg.port.default + "/";
            var path = this.apis_config.cfg[key].path;
            if (auth == 1) {
                this.token = this.userStorage.getUserInfo(this.userStorage.token); //localStorage.getItem('can01');
            }


            if (this.config.cfg.env_mode.default == 'production') {
                json_data = this.encryption.encode(JSON.stringify(json_data));
            }
            this.headers = {
                'Content-Type': 'application/json',
                // 'Authorization': ''
            };
            // console.log("test-->", 2);

            if (this.token) {
                this.headers.Authorization = this.token;
            }
            // console.log(this.headers)
            // console.log("test-->", 3, this.token);

            var options = {
                headers: new HttpHeaders(
                    this.headers)
            };

            var map = {
                "payload": json_data
            };
            var data: any = [];
            var json: any = [];

            // console.log("pass_0--> ",path, map,options);
            this.http.post(url + path, map, options).subscribe(
                (response) => {
                    console.log("414124124---> ",response);
                    // console.log(response, "req Res")
                    if (this.config.cfg.env_mode.default == 'production') {

                        response = JSON.parse(this.encryption.decode(response['response']))
                      console.log(response,typeof response)
                        // if(response == )
                        var response_alt = response;
                        // response=[];     response['response'] =response_alt;      
                        response = { "response": response };
                    }
                    console.log("pass_1---> ",response);
                    // console.log("414124124---> ",response);
                    resolve([200, response])
                },
                (error) => {
                    console.log(error, "req Err")
                    if (this.config.cfg.env_mode.default == 'production') {
                        error =  JSON.parse(this.encryption.decode(error["error"]['response']));
                        error = { "response": error };     //error['response'] =error_alt;   
                        
                        if(error.response.error ==  "session_timeout"){
                            console.log(error,"++++++++++++session_timeout++++++++++++")
                            this.userStorage.removeUserAllInfo();
                            window.location.reload();
                        }  
                    }
                    var error_msg = error['error']
                    if (error_msg == "jwt expired" || error == "session_timeout") {
                        this.userStorage.removeUserAllInfo();
                    }
                    console.log(error)
                    if(error.response.error ==  "session_timeout"){
                        console.log(error,"++++++++++++session_timeout++++++++++++")
                        this.userStorage.removeUserAllInfo();
                        window.location.reload();
                    }  
                    console.log(error,'llllll')
                    resolve([400, error])
                }

            )

        })
    }

    get_structure(key, operation) {
        return this.apis_config.cfg[key][operation];
    }
}

// this.http.post(url + path, map, options).subscribe(
//     (response) => {
//         resolve(response)
//     },
//     (error) => {
//         resolve(JSON.stringify(error.error.response))
//     }

// )