<footer class="footer footer-content footer_bg p-3">
    <div class="row no-gutters">
        <div class="container ">
            <div class="d-flex">
                <!-- <h5>footer works</h5> -->
                <img src="assets/facebook 2.svg" style="height: 30px;
            width: 30px; padding: 2px;">
                <br>
                <img src="assets/instagram white round.svg" style="height: 30px; padding: 2px;margin-left: 5px;;
            width: 30px;">
            </div>
            <hr class="line">
            <div class="row footertxt">
                <div class="col-md-4 no-gutters">
                    <a style="border: none; outline: none;" href="https://remusnation.com/"> <img src="assets/Remusnation updated logo 2 (white).png" height="70px" width="150px"></a>
                    <br>
                    <br>
                    <p class="footerline fline">&copy;{{year}} {{this.language.mLparams[this.lang_dync].site_name}} {{this.language.mLparams[this.lang_dync].allRightsReserved}}
                    </p>
                </div>
                <div class="col-md-1 ">
                    <p class="footer_heading">


                </div>
                <div class="col-md-2 ">
                    <p class="footer_heading">
                        {{this.language.mLparams[this.lang_dync].info}}
                    </p>
                    <a class="fline" style="color: white; text-decoration: none;">{{this.language.mLparams[this.lang_dync].about_us}}</a>
                </div>
                <div class="col-md-2 ">
                    <p class="footer_heading">{{this.language.mLparams[this.lang_dync].Policy}}</p>
                    <!-- <br> -->
                    <a class="footer_link fline" [routerLink]="['/cookies']">{{this.language.mLparams[this.lang_dync].Cookie_Policy}}</a>
                    <br>
                    <a class="footer_link fline" [routerLink]="['/legal']">{{this.language.mLparams[this.lang_dync].Legal}}</a>
                    <br>
                    <a class="footer_link fline" [routerLink]="['/deliverypolicy']">{{this.language.mLparams[this.lang_dync].Delivery_Policy}}</a>
                </div>
                <!-- <div class="col-md-2 ">
                    <p class="footer_heading">{{this.language.mLparams[this.lang_dync].Contact_with_Us}}</p>
                    <a>{{this.language.mLparams[this.lang_dync].Coming_Soon}}</a>
                </div> -->
                <div class="col-md-3 ">
                    <p class="footer_heading ">{{this.language.mLparams[this.lang_dync].Get_To_Know_Us}}</p>
                    <!-- <br> -->
                    <a href="mailto:contact@remusnation.com" class="footer_link fline">contact@remusnation.com</a>
                    <br>
                    <p style="margin-bottom: 0px;" class="fline">{{this.language.mLparams[this.lang_dync].remusAddress1}}</p>
                    <p class="fline">{{this.language.mLparams[this.lang_dync].remusAddress3}}</p>

                    <p class="mt-n3 fline">{{this.language.mLparams[this.lang_dync].remusAddress2}}<br>{{this.language.mLparams[this.lang_dync].remus_pincode}}</p>

                </div>
            </div>
        </div>
    </div>
</footer>