import { Component, OnInit } from '@angular/core';
import { Request } from '../_service/request'
import { Config } from '../sys_config/index';
import { LanguageService } from '../config/language/index';
import { LocalstorageService } from '../sys_config/localstorage.service';
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  providers: [LanguageService]
})
export class CategoryComponent implements OnInit {

  constructor(
    // private logservice: LoginService,
    private request: Request,
    private config: Config,
    private language: LanguageService,
    private userStorage: LocalstorageService
  ) { }
  category_type: any;
  catdata: any;
  productdata: any;
  authorization = 0;
  lang_dync = this.userStorage.current_language;
  server_url = this.request.server_url;
  async ngOnInit() {

    this.category_type = {
      "payload": {
        "product_id": 2
      }
    };

    // this.catdata = await this.logservice.get_api(this.category_type, 'bov3004/68319');
    var temp = this.request.get_structure('category_list', 'format');
    if (this.userStorage.getUserInfo(this.userStorage.token)) {
      this.authorization = 1;
    }
    // temp.product_id = 2;
    this.catdata = await this.request.post(0, 'category_list', temp);
    // this.productdata = await this.request.post(1, 'product_list', temp);
    this.productdata = {
      0: {},
      1: {
        "response": {
          "data": {
            "cart_count": 50,
            "products": [
              {
                "category_id": 2,
                "category_name": "available products",
                "products": [
                  {
                    "product_id": 24,
                    "product_name": "i phone",
                    "qty": 23,
                    "price": 1000,
                    "new_price": 950,
                    "image_url": "test/Z01iphone-1.4-(520x540).png"
                  },
                  {
                    "product_id": 25,
                    "product_name": "mac",
                    "qty": 27,
                    "price": 2000,
                    "new_price": 1960,
                    "image_url": "test/Z01Mackbook-1.1-(520x540).png"
                  },
                  {
                    "product_id": 24,
                    "product_name": "i phone",
                    "qty": 23,
                    "price": 1000,
                    "new_price": 950,
                    "image_url": "test/Z01iphone-1.4-(520x540).png"
                  },
                  {
                    "product_id": 25,
                    "product_name": "mac",
                    "qty": 27,
                    "price": 2000,
                    "new_price": 1960,
                    "image_url": "test/Z01Mackbook-1.1-(520x540).png"
                  },  {
                    "product_id": 24,
                    "product_name": "i phone",
                    "qty": 23,
                    "price": 1000,
                    "new_price": 950,
                    "image_url": "test/Z01iphone-1.4-(520x540).png"
                  },
                  {
                    "product_id": 25,
                    "product_name": "mac",
                    "qty": 27,
                    "price": 2000,
                    "new_price": 1960,
                    "image_url": "test/Z01Mackbook-1.1-(520x540).png"
                  }
                ]
              }
            ]
          },
          "error": "null"
        }
      }
    };


    
    if (this.catdata[1].response.data) {
      this.category = this.catdata[1].response.data;

    }
    if (this.productdata[1].response.data != null) {
      this.product = this.productdata[1].response.data.products;
      this.cat_iteration(this.product);
      // console.log(this.product_numbers);

    }
  }


  cat_iteration(prd_category) {
    var iterated_product_list = {};
    var i = 1;

    prd_category.forEach(value => {
      if (value.products) {
        this.product_iteration_custom(value.products);
      }
    });
  }


  product_iteration_custom(prdds) {
    prdds.forEach(value => {
      if (value.product_id) {
        this.product_numbers.push(value);
      }
    });

  }

  category = [1, 2, 3]
  // products =[1,2,3]
  category_title: string = "CONSUMABLES";
  product_numbers = new Array();
  product: any;
}
