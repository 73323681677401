<nav class="topnav">
    <div class="container">
        <div class="row top">
            <!-- <div class="col-md-2"></div> -->
            <div class="col-lg-6 col-md-6 col-sm-6">
                <a style="outline: none;box-shadow: none;"><img style="outline: none;box-shadow: none; cursor: pointer;"
                        [routerLink]="['/']" src="assets/dshop-white.png" class="img-fluid" alt="logo"></a>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 head-right">
                <button (click)="cartBtn()" class="cartnum" size="sm">{{cart_data.all_cart_count}}</button>
                <div>
                    <a [routerLink]="['/cart']"><img src="assets/two.svg" class="cartbag" height="50px"
                            width="50px" /></a>
                </div>
                <p class="cartprice"><a [routerLink]="['/cart']">{{this.language.mLparams[this.lang_dync].Your_Cart}}
                        <br>
                        $ {{cart_data.all_cart_value}}</a></p>
            </div>
        </div>
    </div>
</nav>
<hr class="horl_line">
<nav class="middlenav">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 mercen">
                <div class="dropdown p-2">
                    <button class="btn btn-default dropdown-toggle mblcat" style="color: #fff;" type="button"
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{catName}}
                    </button>
                    <!----------------------------------------------------------------main category only model start---------------------------------------------------------------------------------------->
                  
                    <!-- <div class="dropdown-menu catdropdown multi-level dropright" role="menu"
                        aria-labelledby="dropdownMenu">
                        <button style="outline: none;box-shadow: none;" class="dropdown-item catdropdown"
                            *ngFor="let items of category_data" (click)="getid1(items)">{{items.category_name}}
                            <ul class="dropdown-menu dropright">
                                <li class="dropdown-submenu" *ngFor="let smenu of items.array_to_json">
                                    <button class="dropdwn-item" style="color: black;"
                                        (click)="getid2(items)">{{smenu.category_name}}</button>
                                    <ul class="dropdown-menu dropright">
                                        <li class="dropdown-submenu" *ngFor="let dmenu of smenu.array_to_json">
                                            <button class="dropdown-item" style="color: black;"
                                                (click)="getid3(items)">{{dmenu.category_name}}</button>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </button>
                    </div> -->


                     <!----------------------------------------------------------------main category only model end ---------------------------------------------------------------------------------------->


                    <!--------------------------------------------------------------custom category model mega menu type start --------------------------------------------------------------------->
                    <!-- <div class="drop-head">
                        <div class="dropdown-menu catdropdown multi-level " role="menu" aria-labelledby="dropdownMenu">

                            <div class="row">
                                <div class="col-md-12 col-lg-4 ">
                                    <div class="colum12">
                                        <p class="cate_head">Category 1</p>
                                        <div *ngFor="let items of category_data">

                                            <button class="dropdown-item catdropdown" (click)="getid1(items)"
                                                (mouseenter)="need_cat(items)">{{items.category_name}} <img
                                                    *ngIf="items?.array_to_json" class="addPointer  right"
                                                    [src]="'assets/next icon.svg'" width="20px" height="20px" />
                                                <img *ngIf="!items?.array_to_json" class="addPointer  right"
                                                    [src]="'assets/check_circle-black-18dp.svg'" width="20px"
                                                    height="20px" /></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-4 ">
                                    <div class="colum12" >
                                        <p class="cate_head">Category 2</p>
                                        <div class="dropdown-submenu">
                                            <div *ngIf="second_cat">
                                                <div *ngFor="let items of second_cat">
                                                    <a class="dropdown-item catdropdown" (click)="getid2(items)"
                                                        (mouseenter)="need_cat2(items)">{{items.category_name}}
                                                        <img *ngIf="items?.array_to_json" class="addPointer  right"
                                                            [src]="'assets/next icon.svg'" width="20px" height="20px" />
                                                        <img *ngIf="!items?.array_to_json" class="addPointer  right"
                                                            [src]="'assets/check_circle-black-18dp.svg'" width="20px"
                                                            height="20px" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-4 ">
                                    <div class="colum12" *ngIf="second_cat2">
                                        <p class="cate_head">Category 3</p>
                                        <div *ngIf="second_cat2">
                                            <div *ngFor="let items of second_cat2">
                                                <a class="dropdown-item catdropdown"
                                                    (click)="getid3(items)">{{items.category_name}}
                                                    <img *ngIf="items?.array_to_json" class="addPointer  right"
                                                        [src]="'assets/next icon.svg'" width="20px" height="20px" />
                                                    <img *ngIf="!items?.array_to_json" class="addPointer  right"
                                                        [src]="'assets/check_circle-black-18dp.svg'" width="20px"
                                                        height="20px" />
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
 -->



                    <!--------------------------------------------------------------custom category model mega menu type end--------------------------------------------------------------------->

                    <!--------------------------------------------------------------custom category model multi-level  start--------------------------------------------------------------------->
                    <form>
                        <ul class="dropdown-menu catdropdown multi-level" style="width: max-content;" role="menu" aria-labelledby="dropdownMenu">
                            <li class="dropdown-submenu" *ngFor="let items of category_data">
                                <!-- |slice:0:8 -->
                                <button class="dropdown-item catdropdown " tabindex="-1" href="#"
                                    (click)="getid1(items)" (mouseenter)="need_cat(items)">{{items.category_name}} <img
                                        *ngIf="items?.array_to_json" class="addPointer  right"
                                        [src]="'assets/nexticon3.svg'" width="20px" height="20px" />
                                    <!-- <img *ngIf="!items?.array_to_json" class="addPointer  right"  
                                        [src]="'assets/check_circle-black-18dp.svg'" width="20px"
                                        height="20px" /> -->
                                    </button>

                                <ul class="dropdown-menu catdropdown" style="width: max-content;" *ngIf="second_cat">

                                    <li class="dropdown-submenu" *ngFor="let items of second_cat">
                                        <!-- |slice:0:7 -->
                                        <button class="dropdown-item catdropdown dropcatsize" tabindex="-1" href="#"
                                            (click)="getid2(items)"
                                            
                                            (mouseenter)="need_cat2(items)">{{items.category_name}}
                                            <img *ngIf="items?.array_to_json" class="addPointer  right"
                                                [src]="'assets/nexticon3.svg'" width="20px" height="20px"
                                                (click)="need_cat2(items)" />
                                            <!-- <img *ngIf="!items?.array_to_json" class="addPointer  right"
                                                [src]="'assets/check_circle-black-18dp.svg'" width="20px"
                                                height="20px" /> -->
                                        </button>
                                        <ul class="dropdown-menu catdropdown" style="width: max-content;" *ngIf="second_cat2">
                                            <li class="dropdown-submenu" *ngFor="let items of second_cat2">
                                                <!-- |slice:0:6 -->
                                                <button class="dropdown-item catdropdown dropcatsmallsize" tabindex="-1" href="#"
                                                    (click)="getid3(items)">{{items.category_name}}
                                                    <img *ngIf="items?.array_to_json" class="  right"
                                                        [src]="'assets/nexticon3.svg'" width="20px" height="20px" />
                                                    <!-- <img *ngIf="!items?.array_to_json" class="  right"
                                                        [src]="'assets/check_circle-black-18dp.svg'" width="20px"
                                                        height="20px" /> -->
                                                </button>
                                            </li>

                                        </ul>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                    </form>
                    <!--------------------------------------------------------------custom category model multi-level   end--------------------------------------------------------------------->

                </div>
            </div>
            <div class="div col-lg-6">
                <div class="input-group">
                    <input list="searchList" style="border-radius: 0;" type="text" #mySearch
                        (keyup)="result(mySearch.value)" (change)="fun(mySearch.value)"
                        (keypress)="eventHandler($event.keyCode)" [value]="searchInput" class="form-control"
                        placeholder="{{this.language.mLparams[this.lang_dync].srchitm}}" />
                    <div class="input-group-append">
                        <button style="background-color: #ff0000;" class="btn btn-secondary" type="button"
                            id="lp_search" (click)="singlepage(searchResults[0].product_id)">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                    <datalist id="searchList">
                        <option *ngFor="let search of searchResults " value="{{search.product_name}}">
                    </datalist>
                </div>

                <!-- <input id="typeahead-basic" type="text" class="form-control" [(ngModel)]="model" [ngbTypeahead]="search"/> -->
            </div>
            <div class="col-lg-3 my-account">
                <div *ngIf="isUserLogged" class="dropdown">
                    <div class="dropdown d-flex">
                        <button style="color: #fff;" class="btn btn-default dropdown-toggle mt-1 mblcat1" type="button"
                            id="dropdownButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{this.language.mLparams[this.lang_dync].myaccount}}
                        </button>
                        <div class="dropdown-menu accdropdown" aria-labelledby="dropdownMenuButton">
                            <button class="dropdown-item username" style="color: black;" disabled><i class="fa fa-user"
                                    aria-hidden="true"></i> Hi! {{nickname}}...</button>
                            <a *ngIf="!isprofile" class="dropdown-item"
                                [routerLink]="['/buyer']">{{this.language.mLparams[this.lang_dync].my_profile}}</a>
                            <div *ngIf="!isdashbode">
                                <a *ngIf="isAdmin" class="dropdown-item"
                                    [routerLink]="['/admin']">{{this.language.mLparams[this.lang_dync].admin_dash}}</a>
                            </div>
                            <!-- <a *ngIf="this.mode.config_mode.three" style="color: black;" class="dropdown-item btn" data-toggle="modal"
                data-target="#BecomeSellerModal">{{this.language.mLparams[this.lang_dync].become_seller}}</a> -->
                            <a *ngIf="!iscart" class="dropdown-item"
                                [routerLink]="['/cart']">{{this.language.mLparams[this.lang_dync].cart}}</a>
                            <a class="dropdown-item" [routerLink]="['/']"
                                (click)="logout()">{{this.language.mLparams[this.lang_dync].logout}}</a>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isUserLogged">
                    <button style="color: #fff;" type="button" class="btn btn-default  mt-1 mblcat" data-toggle="modal"
                        id="login_button" data-target="#loginModal" #loginButton>
                        <i class="fa fa-user"></i> {{this.language.mLparams[this.lang_dync].login}}
                    </button>
                </div>
                &nbsp;
                <div class="row locat" style="margin-bottom: 5px;">

                    <button *ngIf="!isUserLogged" style="color: #fff;" class="btn btn-default locmbl1" type="button"
                        data-toggle="modal" data-target=".bd-lp_location-modal-lg">
                        <i class="fa fa-map-marker"></i>
                    </button>
                    <button *ngIf="isUserLogged" style="color: #fff;" type="button"
                        class="btn btn-default mblcat1 locmbl" id="click_location" data-toggle="modal"
                        (click)="getExistingAddr()" data-target=".bd-location-modal-lg">
                        <i class="fa fa-map-marker"></i>
                        {{ location | slice:0:5 }}<span *ngIf="location">.</span>
                        <!-- {{postal_code}} -->
                    </button>
                </div>
            </div>
        </div>
    </div>

</nav>

<!-- Become seller Model -->


<div class="modal fade" id="BecomeSellerModal" tabindex="-1" role="dialog" aria-labelledby="BecomeSellerModalLabel"
    data-backdrop="static" data-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="BecomeSellerModalLabel">
                    {{this.language.mLparams[this.lang_dync].becomeSeller}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="onCloseBecomeSeller()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="getducwalletform" (ngSubmit)="onEnteredDucWalletaddr()">
                    <div class="form-group">
                        <label>{{this.language.mLparams[this.lang_dync].Enter_Your_Duc_Wallet_Address}}</label>
                        <input required formControlName="getducaddr" class="form-control" name="getducaddr">
                        <p class="text-muted">{{this.language.mLparams[this.lang_dync].paymentLossMsg}}</p>
                        <div *ngIf="logsubmitted && duc.getducaddr.errors">
                            <div *ngIf="duc.getducaddr.errors.required" class="text-danger">
                                {{this.language.mLparams[this.lang_dync].addresrequired}}
                            </div>
                            <div *ngIf="duc.getducaddr.errors.pattern" class="text-danger">
                                {{this.language.mLparams[this.lang_dync].invalidducaddr}}
                            </div>
                        </div>
                    </div>

                    <button type="submit"
                        class="btn btn-primary float-right">{{this.language.mLparams[this.lang_dync].Confirm}}</button>
                </form>
            </div>
        </div>
    </div>
</div>

<div *ngFor="let show of searchResult">
    <p> {{ show.name }}</p>
</div>

<!--- Modal --->
<div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <div *ngIf="islogin; else elseBlock">
                    <h5 class="modal-title" id="exampleModalLabel"> {{this.language.mLparams[this.lang_dync].login}}
                    </h5>
                </div>
                <ng-template #elseBlock>
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{this.language.mLparams[this.lang_dync].signup}}
                    </h5>
                </ng-template>

                <button type="button" #myDiv class="close close-btn" data-dismiss="modal" (click)="loginClose()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="successMsg" style="z-index: 1000;" class="alert alert-success alert-dismissible fade show"
                    role="alert">
                    <strong>{{activation_success}}</strong> {{signup_success}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="container p-2">
                    <div *ngIf="islogin">
                        <!--  #loginForm="ngForm"  -->
                        <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                            <div class="form-group">
                                <div *ngIf="error_login">
                                    <p class="alert alert-danger">{{ error_login }}</p>
                                </div>
                                <div *ngIf="bo_error">
                                    <p class="alert alert-danger">{{ bo_error }}</p>
                                </div>
                            </div>



                            <!--loging button -->

                            <button type="button" (click)="bouser()" class="btn btn-default bologin"><img
                                    src="assets/ducatus_icon.png" height="40px" width="40px">{{this.language.mLparams[this.lang_dync].lginwith}} {{account_name}} {{this.language.mLparams[this.lang_dync].account}}</button>

                            <div>
                                <p class="ortxt">{{this.language.mLparams[this.lang_dync].or}}</p>
                            </div>



                            <!--loging button -->



                            <!-- <div class="col-md-5 mt-2"> <hr></div> -->
                            <!-- </div> -->
                            <div class="form-group">
                                <label class="text-center" *ngIf="!boUser">{{
                                    this.language.mLparams[this.lang_dync].userName
                                    }}</label>
                                <label class="text-center" *ngIf="boUser">{{
                                    this.language.mLparams[this.lang_dync].boUserName
                                    }}</label>
                                <input type="text" formControlName="lemail" class="form-control loginFormInput"
                                    [ngClass]="{ 'is-invalid': logsubmitted && lo.lemail.errors }" autofocus />
                                <div *ngIf="logsubmitted && lo.lemail.errors" class="invalid-feedback">
                                    <div *ngIf="lo.lemail.errors.required">
                                        {{this.language.mLparams[this.lang_dync].requiredfield}}
                                    </div>
                                    <div *ngIf="lo.lemail.errors.email || lo.lemail.errors.pattern">
                                        {{this.language.mLparams[this.lang_dync].validemail}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label *ngIf="!boUser">{{
                                        this.language.mLparams[this.lang_dync].password
                                        }}</label>
                                    <label *ngIf="boUser">{{
                                        this.language.mLparams[this.lang_dync].bopassword
                                        }}</label>
                                    <input type="password" formControlName="lpassword"
                                        class="form-control loginFormInput" [ngClass]="{
                      'is-invalid': logsubmitted && lo.lpassword.errors
                    }" />
                                    <div *ngIf="logsubmitted && lo.lpassword.errors" class="invalid-feedback">
                                        <div *ngIf="lo.lpassword.errors.required">
                                            {{ this.language.mLparams[this.lang_dync].pass_required }}
                                        </div>
                                        <div *ngIf="lo.lpassword.errors.minlength">
                                            {{ this.language.mLparams[this.lang_dync].pass_length }}
                                        </div>
                                        <!-- <div *ngIf="lo.lpassword.errors.pattern">
                                            {{ this.language.mLparams[this.lang_dync] .password_struct }}
                                        </div> -->
                                    </div>
                                    <!-- <p class="text-muted display-5 text-left mt-2 mb-1 paragraph">{{ this.language.mLparams[this.lang_dync] .password_structure }}
                                    </p> -->
                                </div>
                            </div>

                            <div class="text-center">
                                <!-- the foll button needs to be commented out -->
                                <!-- <button
                  type="button"
                  class="btn btn-primary float-left"
                  (click)="changesignup()"
                >
                  sign up
                </button> -->
                                <!-- <button *ngIf="isUserLogged" type="button" class="btn btn-danger float-left" (click)="logout()">
                  logout
                </button> -->
                                <button *ngIf="!boLogin" type="submit"
                                    class="btn loginregbtn float-center pr-5 pl-5 mt-3">
                                    {{ this.language.mLparams[this.lang_dync] .login }}
                                </button>
                                <button *ngIf="boLogin" type="submit"
                                    class="btn loginregbtn float-center pr-5 pl-5 mt-3">
                                    {{ this.language.mLparams[this.lang_dync] .login }}
                                </button>

                            </div>
                        </form>
                        <div class="text-center">
                            <span class="d-block mt-4"> {{ this.language.mLparams[this.lang_dync] .notamemb }}
                                <a class="txt_mode_2" style="cursor: pointer;" (click)="changesignup()"> {{
                                    this.language.mLparams[this.lang_dync] .crtacc }}</a></span>
                            <span class="d-block mt-3">
                                <button class="btn txt_mode_3" (click)="forgetPassword()"> {{
                                    this.language.mLparams[this.lang_dync] .frgtpwd }}</button>
                            </span>
                        </div>
                    </div>

                    <div *ngIf="isSignup">
                        <div class="form-group">
                            <!-- <div *ngIf="signup_success">
                <p class="alert alert-success">{{signup_success}}</p>
              </div> -->
                            <div *ngIf="signup_fail">
                                <p class="alert alert-danger">{{signup_fail}}</p>
                            </div>
                        </div>
                        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                            <div class="form-row">
                                <div class="form-group col">
                                    <label>{{this.language.mLparams[this.lang_dync].signupUserName}}</label>
                                    <input autofocus type="text" formControlName="firstName"
                                        class="form-control loginFormInput" [ngClass]="{
                      'is-invalid': submitted && f.firstName.errors
                    }" (blur)="checkUserName()" (keyup)=reg_change() />
                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.required">
                                            {{this.language.mLparams[this.lang_dync].requiredfield}}
                                        </div>
                                        <div *ngIf="f.firstName.errors.pattern">
                                            {{this.language.mLparams[this.lang_dync].usernamepattern}}
                                        </div>
                                    </div>
                                    <p class="text-muted display-5 text-left mt-2 mb-1 paragraph">
                                        {{this.language.mLparams[this.lang_dync].Username_field}}</p>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{this.language.mLparams[this.lang_dync].eml}}</label>
                                <input type="text" formControlName="email" class="form-control loginFormInput"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (blur)="checkUserMail()"
                                    (keyup)=reg_change() />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">
                                        {{this.language.mLparams[this.lang_dync].requiredfield}}</div>
                                    <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                                        {{this.language.mLparams[this.lang_dync].validemail}}
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col">
                                    <label>{{this.language.mLparams[this.lang_dync].pswd}}</label>
                                    <input type="password" formControlName="password"
                                        class="form-control loginFormInput"
                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                        (keyup)=reg_change() />
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">
                                            {{this.language.mLparams[this.lang_dync].pass_required}}
                                        </div>
                                        <div *ngIf="f.password.errors.pattern">
                                            {{this.language.mLparams[this.lang_dync].password_struct}}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col">
                                    <label> {{this.language.mLparams[this.lang_dync].cpassword}}</label>
                                    <input type="password" formControlName="confirmPassword"
                                        class="form-control loginFormInput" (keyup)=reg_change() [ngClass]="{
                      'is-invalid': submitted && f.confirmPassword.errors
                    }" />
                                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.confirmPassword.errors.required">
                                            {{this.language.mLparams[this.lang_dync].cpass_required}}
                                        </div>
                                        <div *ngIf="f.confirmPassword.errors.mustMatch">
                                            {{this.language.mLparams[this.lang_dync].pwd_match}}
                                        </div>
                                    </div>
                                </div>
                                <p class="text-muted display-5 text-left mt-2 mb-1 paragraph">
                                    {{this.language.mLparams[this.lang_dync].password_structure}}</p>
                            </div>
                            <div class="form-group form-check">
                                <input type="checkbox" [checked]="AcceptAll" formControlName="acceptTerms"
                                    id="acceptTerms" class="form-check-input" [ngClass]="{
                    'is-invalid': submitted && f.acceptTerms.errors
                  }" (change)=tc_checkBox($event) />
                                <label for="acceptTerms"
                                    class="form-check-label">{{this.language.mLparams[this.lang_dync].acpt}} </label><a
                                    class="txt_mode_3 ml-1" target="_blank"
                                    [routerLink]="['/legal']">{{this.language.mLparams[this.lang_dync].trmndcnd}}</a>
                                <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
                                    {{this.language.mLparams[this.lang_dync].atcr}}

                                </div>
                            </div>
                            <div class="text-center">
                                <!-- <button
                  type="button"
                  class="btn btn-primary float-left"
                  (click)="changelogin()"
                >
                  Login
                </button> -->
                                <button type="submit" class="btn loginregbtn float-center pr-5 pl-5"
                                    [disabled]="buttonState">
                                    {{this.language.mLparams[this.lang_dync].register}}
                                </button>
                                <span class="d-block mt-4">{{this.language.mLparams[this.lang_dync].already_mem}}
                                    <a class="txt_mode_2" style="cursor: pointer;"
                                        (click)="changelogin()">{{this.language.mLparams[this.lang_dync].login}}</a></span>
                                <!-- <button class="btn btn-secondary " type="reset" (click)="onReset()">Cancel</button> -->
                            </div>
                        </form>

                        <!-- <button type="submit" class="btn btn-primary float-right" (click)="onSignupSubmit(signupForm.value)">Submit</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- lp_location -->
<!-- <div class="modal fade bd-lp_location-modal-sm" tabindex="-1" role="dialog" aria-labelledby="LocationModalLabel" data-backdrop="static" data-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{this.language.mLparams[lang_dync].select_location}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
               <h5>Current Location</h5>
               <p>abc</p>
               <p>abc</p>
               <p>abc</p>
               <p>abc</p>
               <hr>
               
            </div>
        </div>
    </div>
</div> -->

<!-- lp_location -->


<!-- location modal -->
<div class="modal fade bd-location-modal-lg" tabindex="-1" role="dialog" aria-labelledby="LocationModalLabel"
    data-backdrop="static" data-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg ">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLongTitle" >{{this.language.mLparams[lang_dync].select_location}}
                </h5> -->
                <h5 class="modal-title" id="exampleModalLongTitle" *ngIf="!noAddress">
                    {{this.language.mLparams[lang_dync].select_location}}
                </h5>
                <h5 class="modal-title" id="exampleModalLongTitle" *ngIf="noAddress"> Please add your Address Blow
                </h5>
                <button type="button" id="close_location" class="close" data-dismiss="modal" (click)="close_address()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="!noAddress">
                    <!-- <div class="container" style="border: 1px solid grey; -->
                    <!-- border-radius: 5px; margin: 10px; word-break: break-word" *ngFor="let list of addressList"> -->
                    <!-- <address style="margin-top: 1rem;">
                            <div class="radio_btn">

                                <input type="radio" [checked]="list.is_main"  id="radio_{{list.address_id}}" name="radios" value="{{list.address_id}}" (change)="addressChange($event.target.value)" (click)=" address_click()">
                                [checked]="list.is_main" 
                                <label style="margin-left:4px;" for="radio_{{list.address_id}}"><b>{{language.mLparams[this.lang_dync].makedefault}}</b></label>
                            </div> -->
                    <!-- <b>{{list.address_name | titlecase}}</b><br> -->
                    <!-- {{list.address_phone}}<br>
                  {{list.address_line1}}<br>
                  {{list.address_line2}}<br> -->
                    <!-- {{list.city | titlecase}}<br> -->
                    <!-- {{list.region}}<br>
                  {{list.country}}<br>
                  {{list.postal_code}}<br> -->
                    <!-- </address> -->


                    <!-- <div class="d-flex"> -->

                    <!-- <div class="radio_btn">
                <button type="button" (click)="deleteAddress(list)"
                  class="btn btn-warning">{{language.mLparams[this.lang_dync].delete}}</button>
              </div> -->
                    <!-- </div> -->

                    <!-- </div> -->


                    <!-- <div class="container" style="border: 1px solid grey; 
                 border-radius: 5px; margin: 10px; word-break: break-word" >
                <div class="dropdown">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Dropdown
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                      <button class="dropdown-item" *ngFor="let list of addressList" type="button" (click)="dropdown_loc(list)">{{list.address_name | titlecase}}</button>
                   
                    </div>
                  </div>
            </div> -->













                    <div class="container" *ngIf="!noAddress">
                        <div class="row" *ngIf="AddressAddBtn">
                            <div class="col-6" style="margin-left: auto; margin-right: auto;">
                                <div ngbDropdown>
                                    <button class="btn btn-outline-primary btn-block"
                                        style="margin-left: 7px; margin-right: 7px;" id="dropdownBasic1"
                                        ngbDropdownToggle>{{defalt_loc | titlecase}}</button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="btn-block">
                                        <button ngbDropdownItem *ngFor="let list of addressList"
                                            style="text-align: center;" type="button"
                                            (click)="dropdown_loc(list)">{{list.address_name | titlecase}}</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div *ngIf="AddressAddBtn" class="col-md-3" class="btnhead">
                                <a>
                                    <button class="btn cartplusbtn" (click)="onAddaddress()"> <i
                                            class="fa fa-plus buyplus" data-toggle="tooltip" data-placement="right"
                                            title="Add Address"></i></button></a>

                            </div>
                        </div>


                        <div class="row" id="address_me">
                            <div class="row float-right no-gutters" *ngIf="!AddressAddBtn">
                                <button type="button" class="close" (click)="onAddaddress()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="col-md-12 col-lg-12 addaddr" *ngIf="!AddressAddBtn">
                                <app-add-address (addrEmitter)="addAddress($event)" [showMobile]="true"
                                    [formTitle]="'Add Address'">
                                </app-add-address>
                            </div>

                        </div>

                        <div class="border border-light" *ngIf="AddressAddBtn">
                            <div class="text-center">
                                <p style="color: #ff0000;" *ngIf="error_location">Please select has make defalte</p>
                                <button class="btn loginregbtn " (click)="save_lo()" style="margin: 10px;">Save</button>

                            </div>
                        </div>
                    </div>

                    <br>

                    <!-- </div> -->

                </div>
                <div class="container" *ngIf="noAddress">

                    <p>Add address</p>


                    <div *ngIf="AddressAddBtn" class="col-md-3" class="btnhead">
                        <a>
                            <button class="btn cartplusbtn" (click)="onAddaddress()"> <i class="fa fa-plus buyplus"
                                    data-toggle="tooltip" data-placement="right" title="Add Address"></i></button></a>

                    </div>
                </div>


                <div class="row" id="address_me" *ngIf="noAddress">
                    <div class="row float-right no-gutters" *ngIf="!AddressAddBtn">
                        <button type="button" class="close" (click)="onAddaddress()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="col-md-12 col-lg-12 addaddr" *ngIf="!AddressAddBtn">
                        <app-add-address (addrEmitter)="addAddress($event)" [showMobile]="true"
                            [formTitle]="'Add Address'">
                        </app-add-address>
                    </div>

                </div>
            </div>
            <!-- <div class="container" *ngIf="noAddress">
                    <h5 class="modal-title" id="exampleModalLongTitle" *ngIf="noAddress"> {{this.language.mLparams[lang_dync].plsaddinmyprof}}
                    </h5> 
                </div> -->
        </div>
    </div>
</div>
<!-- location modal end -->