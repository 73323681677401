<div class="container mt-4">
    <div style="padding-bottom: 1rem;">
        <img class="pointer" src="assets/homeimg.svg" (click)="back()" width="50px" height="50px">

    </div>
    <!-- The foll shows All Warehouses as Cards -->
    <div *ngIf="showWareHouses">
        <div class="row">
            <div class="col-md-3" *ngFor="let warehouse of wareHouses" style="margin-bottom: 20px;">
                <div class="card text-dark bg-light">
                    <div class="card-body text-center" style="font-size: 20px; margin-top: 15px;">
                        <p>{{ warehouse.warehouse_name | slice:0:14 | titlecase}}</p>



                        
                            
                            <div class="dropdown">
                                <button class="btn loginregbtn" (click)="handleManageWareHouse(warehouse)">
                                    {{ language.mLparams[lang_dync].manageWareHouse.manage }}
                                </button>
                               &nbsp;
                              

                                    <img class="cat12" style="cursor: pointer;"  placement="bottom" ngbTooltip="Shippit activated, products delivered all over world" tooltipClass="my-custom-class" *ngIf="warehouse.shippit_enabled " [src]="'assets/baseline_local_shipping_black_18dp.png'"
                                    id="dropdownMenuButton" data-toggle="dropdown" width="20px"
                                    height="20px" />
                               
                     
                                    <img class="cat12" style="cursor: pointer;" placement="bottom" ngbTooltip="Please select type of shipping here, shipping cost added based on your selection " tooltipClass="my-custom-class"  *ngIf="!warehouse.shippit_enabled" [src]="'assets/baseline_arrow_drop_down_black_18dp.png'"
                                   id="dropdownMenuButton" data-toggle="dropdown" width="20px"
                                height="20px" />
                              
                            
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <button class="dropdown-item"
                                        (click)="Shipt_Changes2(warehouse,Shipt_confirm)" *ngIf="!warehouse.shippit_enabled " style="outline: none;box-shadow: none;">Activate Shippit</button>
                                    <button class="dropdown-item" (click)="Shipt_Changes2(warehouse,'none')"*ngIf="warehouse.shippit_enabled " style="outline: none;box-shadow: none;" >Cancel Shippit</button>
                                 
                                </div>
                            </div>

                        <!-- <p style="font-size: 12px;" *ngIf="warehouse.shippit_enabled">Delivery by Shippit</p> -->
                    </div>
                </div>
            </div>
            <div class="col-md-3 addwarehouse">
                <div class="card addPointer text-dark bg-light" (click)="handleAddWareHouse()">
                    <div class="card-body text-center" style="border: 1px solid #D4AE1A;">
                        <img src="assets/warehouse.svg" width="50px" height="73px">
                        <p>
                            {{ language.mLparams[lang_dync].manageWareHouse.addWareHouse }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- -------------------------------- -->

    <!-- The foll shows Form to Add New WareHouse -->
    <div *ngIf="showAddWareHouseForm">
        <app-add-address (addrEmitter)="receiveAddr($event)" [showMobile]="showMobile" [showDelivery]="Delivery_team"
            [formTitle]="language.mLparams[lang_dync].manageWareHouse.addWareHouse"></app-add-address>

        <div *ngIf="showAddResMsg">
            <p class="text-center text-success">{{ addResMsg }}</p>
        </div>
    </div>
    <!-- --------------------- -->

    <!-- The foll Shows an Individual Warehouse Page -->

    <div *ngIf="showManageWareHouse">
        <nav ngbNav #nav="ngbNav" class="nav-pills" animation="true" (activeIdChange)="onNavChange($event)">
            <ng-container ngbNavItem="1">
                <a class="" ngbNavLink>{{
                    language.mLparams[lang_dync].manageWareHouse.logPartnerTabHead
                    }}</a>

                <ng-template ngbNavContent>
                    <!--################### Content of Logistic Partners ##################-->

                    <!-- Logistic Partners Displayed as Table -->
                    <div>
                        <div class="row">
                            <div class="col-md-12">
                                <a class="btn loginregbtn float-right" data-toggle="modal"
                                    data-target="#AddLogisticPartnerModal" style="margin-bottom: 30px;">
                                    {{
                                    language.mLparams[lang_dync].manageWareHouse
                                    .addLogisticPartner
                                    }}</a>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">
                                                {{ language.mLparams[lang_dync].name }}
                                            </th>
                                            <th scope="col">
                                                {{ language.mLparams[lang_dync].manageWareHouse .trackingUrlLabel }}
                                            </th>
                                            <th scope="col">
                                                {{ language.mLparams[lang_dync].manageWareHouse.action }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let partner of logisticPartners; index as i">
                                            <th scope="row">{{ i + 1 }}</th>
                                            <td>
                                                {{ partner.logistic_partner_name | titlecase}}
                                            </td>
                                            <td>{{ partner.tracking_url }}</td>
                                            <td>
                                                <!-- <img
                        (click)="editLogisticPartner(partner)"
                        class="mr-2 addPointer"
                        [src]="'assets/edit.svg'"
                        width="20px"
                        height="20px"
                      /> -->
                                                <img (click)="openConfLogPartnerDelModal(partner)" class="addPointer"
                                                    [src]="'assets/delete.svg'" width="50px" height="20px" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- Add Logistic Partner Modal -->

                    <div class="modal fade" id="AddLogisticPartnerModal" tabindex="-1" role="dialog" aria-hidden="true"
                        data-backdrop="static" data-keyboard="false">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="BecomeSellerModalLabel">
                                        {{ language.mLparams[lang_dync].manageWareHouse .addLogisticPartner }}
                                    </h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                        id="logisticPartnerModalClose">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div class="modal-body">
                                    <form [formGroup]="getLogisticPartnerForm" (ngSubmit)="onLogPartnerFormSubmit()">
                                        <div class="form-group">
                                            <label>{{
                                                language.mLparams[lang_dync].addrForm.namePlcHolder
                                                }}</label>
                                            <input required formControlName="trackingName" class="form-control"
                                                [ngClass]="{
                        'is-invalid':
                          isLogFormSubmitted && url.trackingName.errors
                      }" autofocus />
                                            <div *ngIf="isLogFormSubmitted && url.trackingName.errors">
                                                <div *ngIf="url.trackingName.errors.required" class="text-danger">
                                                    {{ language.mLparams[lang_dync].manageWareHouse.nameErr }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>{{
                                                language.mLparams[lang_dync].manageWareHouse
                                                .trackingUrlLabel
                                                }}</label>
                                            <input required formControlName="trackingUrl" class="form-control"
                                                name="getducaddr" [ngClass]="{
                        'is-invalid':
                          isLogFormSubmitted && url.trackingUrl.errors
                      }" />
                                            <div *ngIf="isLogFormSubmitted && url.trackingUrl.errors">
                                                <div *ngIf="url.trackingUrl.errors.required" class="text-danger">
                                                    {{ language.mLparams[lang_dync].manageWareHouse.urlErr }}
                                                </div>
                                            </div>
                                        </div>
                                        <p class="text-muted">
                                            {{ language.mLparams[lang_dync].manageWareHouse.urlInfo }}
                                        </p>
                                        <button type="submit" [disabled]="isLogFormSubmitted"
                                            class="btn btn-success float-right">
                                            {{ language.mLparams[lang_dync].Confirm }}
                                        </button>
                                    </form>
                                    <div *ngIf="showLPAddRespMsg">
                                        <p class="text-center text-success">
                                            {{ language.mLparams[lang_dync].manageWareHouse.lpRespMsg }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ------------------------------- -->

                    <!-- ##############################################3333 -->
                </ng-template>
            </ng-container>

            <ng-container ngbNavItem="2">
                <a ngbNavLink>{{
                    language.mLparams[lang_dync].manageWareHouse.prdctGrpTabHead
                    }}</a>
                <ng-template ngbNavContent>
                    <!-- ###################Content of Product Groups######################## -->

                    <!-- List all Product Groups as cards -->

                    <div>
                        <div class="row">
                            <div class="col-md-12">
                                <a class="btn loginregbtn float-right" data-toggle="modal"
                                    data-target="#AddProductGroupModal" style="margin-bottom: 30px;">
                                    {{
                                    language.mLparams[lang_dync].manageWareHouse.addProductGroup
                                    }}</a>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">
                                                {{ language.mLparams[lang_dync].name }}
                                            </th>
                                            <th scope="col"></th>
                                            <th scope="col"></th>
                                            <!-- <th scope="col">
                      {{
                        language.mLparams[lang_dync].manageWareHouse
                          .trackingUrlLabel
                      }}
                    </th> -->
                                            <th scope="col">
                                                {{ language.mLparams[lang_dync].manageWareHouse.action }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let product of productGroups; index as i">
                                            <th scope="row">{{ i + 1 }}</th>
                                            <td>
                                                {{ product.product_group_name | titlecase}}
                                            </td>
                                            <td scope="col"></td>
                                            <td scope="col"></td>
                                            <td>
                                                <!-- <img
                        (click)="editLogisticPartner(product)"
                        class="mr-2 addPointer"
                        [src]="'assets/edit.svg'"
                        width="20px"
                        height="20px"
                      />  -->
                                                <img (click)="openConfLogProductGroupModal(product)" class="addPointer"
                                                    [src]="'assets/delete.svg'" width="50px" height="20px" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!--   workinggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg    -->

                    <div class="modal fade" id="AddProductGroupModal" tabindex="-1" role="dialog" aria-hidden="true"
                        data-backdrop="static" data-keyboard="false">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="BecomeSellerModalLabel">
                                        {{ language.mLparams[lang_dync].manageWareHouse .addProductGroup }}
                                    </h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                        id="logisticPartnerModalClose">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div class="modal-body">
                                    <form [formGroup]="getProductGroupForm" (ngSubmit)="onLogProductGroupSubmit()">
                                        <div class="form-group">
                                            <label>{{
                                                language.mLparams[lang_dync].addrForm.namePlcHolder
                                                }}</label>
                                            <input required formControlName="productName" class="form-control"
                                                [ngClass]="{
                        'is-invalid':
                          isLogFormSubmitted && url.trackingName.errors
                      }" autofocus />
                                            <div *ngIf="isLogFormSubmitted && url.trackingName.errors">
                                                <div *ngIf="url.trackingName.errors.required" class="text-danger">
                                                    {{ language.mLparams[lang_dync].manageWareHouse.nameErr }}
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="form-group">
                    <label>{{
                      language.mLparams[lang_dync].manageWareHouse
                        .trackingUrlLabel
                    }}</label>
                    <input
                      required
                      formControlName="trackingUrl"
                      class="form-control"
                      name="getducaddr"
                      [ngClass]="{
                        'is-invalid':
                          isLogFormSubmitted && url.trackingUrl.errors
                      }"
                    />
                    <div *ngIf="isLogFormSubmitted && url.trackingUrl.errors">
                      <div
                        *ngIf="url.trackingUrl.errors.required"
                        class="text-danger"
                      >
                        {{
                          language.mLparams[lang_dync].manageWareHouse.urlErr
                        }}
                      </div>
                    </div>
                  </div> -->
                                        <p class="text-muted">
                                            {{ language.mLparams[lang_dync].manageWareHouse.productInfo }}
                                        </p>
                                        <button type="submit" class="btn btn-success float-right">
                                            {{ language.mLparams[lang_dync].Confirm }}
                                        </button>
                                    </form>
                                    <div *ngIf="showPGAddRespMsg">
                                        <p class="text-center text-success">
                                            {{ language.mLparams[lang_dync].manageWareHouse .addPGRespMsg }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- ############################################ -->
                </ng-template>
            </ng-container>

            <!-- <ng-container ngbNavItem>
      <a ngbNavLink>{{
        language.mLparams[lang_dync].manageWareHouse.prdctGrpTabHead
      }}</a>
      <ng-template ngbNavContent>
        ###################Content of Product Groups########################

        List all Product Groups as cards

        <div>
          <div class="row">
            <div class="col-md-12">
              <a
                class="btn btn-primary float-right"
                data-toggle="modal"
                data-target="#AddLogisticPartnerModal"
              >
                {{
                  language.mLparams[lang_dync].manageWareHouse
                    .addLogisticPartner
                }}</a
              >
            </div>
          </div>
        </div>

        ############################################
      </ng-template>
    </ng-container> -->

            <ng-container ngbNavItem="3">
                <a ngbNavLink>{{
                    language.mLparams[lang_dync].manageWareHouse.locGrpTabHead
                    }}</a>
                <ng-template ngbNavContent>
                    <!-- ###################Content of Location Groups######################## -->

                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <a class="btn loginregbtn float-right" data-toggle="modal"
                                    data-target="#AddLocGroupModal" (click)="handleAddNewLocGroup()"
                                    style="margin-bottom: 30px;">
                                    {{
                                    language.mLparams[lang_dync].manageWareHouse.addLocGroup
                                    }}</a>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">
                                                {{ language.mLparams[lang_dync].name }}
                                            </th>
                                            <th scope="col">
                                                {{ language.mLparams[lang_dync].manageWareHouse.countries }}
                                            </th>
                                            <th scope="col">
                                                {{ language.mLparams[lang_dync].manageWareHouse.regions }}
                                            </th>
                                            <th scope="col" class="text-center">
                                                {{ language.mLparams[lang_dync].manageWareHouse.action }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let locGrp of allLocGrps; index as i">
                                            <th scope="row">{{ i + 1 }}</th>
                                            <td>
                                                {{ locGrp.location_group_name | titlecase}}
                                            </td>
                                            <td>
                                                <ul *ngFor="let country of locGrp.country_names">
                                                    <li>{{ country }}</li>
                                                </ul>
                                            </td>
                                            <td>
                                                <!-- <img
                          (click)="editLogisticPartner(partner)"
                          class="mr-2 addPointer"
                          [src]="'assets/edit.svg'"
                          width="20px"
                          height="20px"
                        /> -->
                                                <ul *ngFor="let region of locGrp.region_name">
                                                    <li>{{ region }}</li>
                                                </ul>
                                            </td>
                                            <td scope="col" class="text-center">
                                                <!-- <img
                          (click)="editLogisticPartner(partner)"
                          class="mr-2 addPointer"
                          [src]="'assets/edit.svg'"
                          width="20px"
                          height="20px"
                        /> -->
                                                <img (click)="openConfLocGrpDelModal(locGrp)" class="addPointer"
                                                    [src]="'assets/delete.svg'" width="50px" height="20px" />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!--------------------------- Add Location Group Modal ---------------------------------------->

                    <div class="modal fade" id="AddLocGroupModal" tabindex="-1" role="dialog" aria-hidden="true"
                        data-backdrop="static" data-keyboard="false">
                        <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">
                                        {{ language.mLparams[lang_dync].manageWareHouse.addLocGroup }}
                                    </h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                        id="addLocGrpModClose">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div class="modal-body">
                                    <!-- <div *ngIf="showLPAddRespMsg">
                                                  <p class="text-center text-success">
                                                    {{
                                                      language.mLparams[lang_dync].manageWareHouse.lpRespMsg
                                                    }}
                                                  </p>
                                                </div> -->
                                    <div class="container">
                                        <!-- instruction row -->
                                        <div class="row justify-content-start">
                                            <div class="col-md-12">
                                                <p>
                                                    {{ language.mLparams[lang_dync].manageWareHouse .addLocInst1 }}
                                                </p>
                                                <p >
                                                   <b>Important : </b>{{ language.mLparams[lang_dync].manageWareHouse .addLocInst2 }}
                                                </p>
                                            </div>
                                        </div>

                                        <!-- end of instructions -->

                                        <!-- Country & Regions Selection  -->
                                        <div class="row justify-content-center">
                                            <div class="col-md-5 col-lg-5 border border-dark rounded no-gutters">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <p>
                                                            <strong>{{
                                                                language.mLparams[lang_dync].manageWareHouse
                                                                .chooseCountries
                                                                }}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center">
                                                    <div class="col-md-5 no-gutters">
                                                        <p>
                                                            <b>{{
                                                                language.mLparams[lang_dync].manageWareHouse
                                                                .avlblCountries
                                                                }}</b>
                                                        </p>
                                                        <!-- <input type="text"
                                                        placeholder="Location"
                                                        class="text"
                                                        
                                                       
                                                        [(ngModel)]="Location"
                                                        name="Location"
                                                      
                                                        (input)="Filter($event.target.value)"> -->
                                                        <div class="countryListDiv border border-secondary rounded p-1 m-1 scroll">

                                                        <h6 *ngFor="let country of avlblCountries"
                                                            class="border border-secondary rounded"
                                                            (click)="handleAvlblCountryClick(country)">
                                                            {{ country.country_name }}
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="col-md-1 no-gutters text-center align-self-center"></div>
                                                <div class="col-md-5 no-gutters">
                                                    <p>
                                                        <b>{{
                                                            language.mLparams[lang_dync].manageWareHouse
                                                            .chosenCountries
                                                            }}</b>
                                                    </p>
                                                    <div
                                                        class="countryListDiv border border-secondary rounded p-1 m-1 scroll">
                                                        <h6 *ngFor="let country of selCountries"
                                                            class="border border-secondary rounded"
                                                            (click)="handleSelCountryClick(country)">
                                                            {{ country.country_name }}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-1 no-gutters text-center align-self-center"></div>
                                        <!-- <div class="col-md-5 no-gutters border border-dark rounded p-1">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <strong>
                            <label for="countrySelectAddLocGroup">{{
                              language.mLparams[lang_dync].manageWareHouse
                              .chooseRegions
                              }}</label></strong>
                                                        <select class="form-control" id="countrySelectAddLocGroup" (change)="onCountryForRegionSelect($event)">
                            <option>
                              {{ language.mLparams[lang_dync].Choose }}
                            </option>
                            <option [value]="country.country_name" *ngFor="let country of avlblCountries">
                              {{ country.country_name }}
                            </option>
                          </select>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 col-lg-12 no-gutters">
                                                        <div class="row justify-content-center">
                                                            <div class="col-md-5 no-gutters">
                                                                <p>
                                                                    <b>{{
                                  language.mLparams[lang_dync].manageWareHouse
                                  .avlblCountries
                                  }}</b>
                                                                </p>
                                                                <div class="countryListDiv border border-dark rounded p-1 scroll2">
                                                                    <h6 *ngFor="let region of avlblRegions" class="border border-secondary rounded" (click)="handleAvlblRegionClick(region)">
                                                                        {{ region.regions }}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-1 no-gutters text-center align-self-center"></div>
                                                            <div class="col-md-5 no-gutters">
                                                                <p>
                                                                    <b>{{
                                  language.mLparams[lang_dync].manageWareHouse
                                  .chosenCountries
                                  }}</b>
                                                                </p>
                                                                <div class="countryListDiv p-1 scroll2">
                                                                    <h6 *ngFor="let region of selRegions" class="border border-secondary rounded" (click)="handleSelRegionsClick(region)">
                                                                        {{ region.regions }}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                         </div> 
                                    <div class="text-left">
                                        <small *ngIf="
                                                            selCountries.length === 0 && selRegions.length === 0
                                                          " class="form-text text-danger ml-1 text-left">{{
                                            language.mLparams[lang_dync].manageWareHouse
                                            .emptyLocErr
                                            }}
                                        </small>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="m-1 p-1">
                                                <strong>
                                                    <label for="locGrpName">{{
                                                        language.mLparams[lang_dync].manageWareHouse
                                                        .locGroupNameLbl
                                                        }}</label></strong>
                                                <input #locGrpNameValue [placeholder]="
                                                                language.mLparams[lang_dync].manageWareHouse
                                                                  .locGroupNamePlcHolder
                                                              " type="text" class="form-control" id="locGrpName"
                                                    (change)="onLocGrpNameChange($event)"
                                                    (focus)="onLocGrpNameFocus($event)"
                                                    (blur)="onLocGrpNameBlur(locGrpNameValue.value)"
                                                    [value]="locGrpName" />
                                                <small *ngIf="locGrpNameErr" class="form-text text-danger ml-1">{{
                                                    language.mLparams[lang_dync].manageWareHouse
                                                    .locGrpNameErr
                                                    }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-5 p-1 m-1 text-left">
                                            <div *ngIf="showLocGrpRespMsg">
                                                <strong>
                                                    <p class="text-success">
                                                        {{
                                                        language.mLparams[lang_dync].manageWareHouse
                                                        .locGrpSucMsg
                                                        }}
                                                    </p>
                                                </strong>
                                            </div>
                                        </div>
                                        <div class="col-md-1"></div>
                                        <div class="col-md-5 p-1 m-1 text-right">
                                            <button class="btn btn-success" [disabled]="isLocGroupSubmitted">
                                                <span aria-hidden="true" (click)="saveLocGroup()">{{
                                                    language.mLparams[lang_dync].save
                                                    }}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

    </div>

    <!-- ###################################------------Shipping cost----------------------------------#########-->

    </ng-template>
    </ng-container>
    <ng-container ngbNavItem="4">
        <a ngbNavLink>{{
            language.mLparams[lang_dync].manageWareHouse.shippingCostTab
            }}</a>
        <ng-template ngbNavContent>
            <div class="container">
                <!-- <div class="row">
            <div class="col-md-12">
              <a class="btn btn-primary float-right" data-toggle="modal" data-target="#AddShippingCostsModal">
                {{
                language.mLparams[lang_dync].manageWareHouse.shippingRules
                }}</a>
             <button (click)="testShpngCost()">ADD</button> 
              
            </div>
            <br>
            <br>
          </div> -->

                <br>
                <br>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col-2" class="text-center ">#</th>
                                    <th scope="col-3" class="text-center ">
                                        {{ language.mLparams[lang_dync].manageWareHouse .prdctGrpName }}
                                    </th>
                                    <th scope="col-3" class="text-center ">
                                        {{ language.mLparams[lang_dync].manageWareHouse .locGrpName }}
                                    </th>
                                    <th scope="col-1" class="text-center ">
                                        {{ language.mLparams[lang_dync].manageWareHouse .shippingCost }}
                                    </th>
                                    <th scope="col-2" class="text-center">
                                        {{ language.mLparams[lang_dync].manageWareHouse .action }}
                                    </th>
                                </tr>
                            </thead>
                            <!-------------------------------------------------------------on working now -->
                            <tbody>
                                <tr *ngFor=" let cost of cost_of_Product;  index as i" [index]="i">

                                    <td scope="col-2" class="text-center ">{{i + 1}}</td>
                                    <td scope="col-3" class="text-center">{{cost.product_group_name | titlecase}}</td>
                                    <td scope="col-3" class="text-center">{{cost.location_group_name | titlecase}}</td>
                                    <td scope="col-1" class="text-center" *ngIf="!cost.Edit">{{cost.shippment_cost ||
                                        addcost}} </td>
                                    <td scope="col-1" class="text-center" *ngIf="cost.Edit && !shipt_changes"
                                        style="width: 170px;"><input type="number" min="0" class="form-control "
                                            [(ngModel)]="cost.shippment_cost "></td>
                                    <td scope="col-1" class="text-center" *ngIf="cost.Edit && shipt_changes"
                                        style="width: 170px;"><input [(ngModel)]="cost.shippment_cost "></td>
                                    <!-- <td scope="col-1" class="text-center" *ngIf="!cost.shippment_cost == null">Add Cost</td> -->
                                    <td scope="col-3" class="text-center">

                                        <div class="dropdown">
                                            <img (click)="editfunction(cost)" class="addPointer"
                                                [src]="'assets/edit-black-18dp.svg'" width="20px" height="20px" />
                                            &nbsp;
                                            <img (click)="savefunction(cost)" class="addPointer"
                                                [src]="'assets/save-black-18dp.svg'" width="20px" height="20px" />
                                            &nbsp;
                                            <!-- <img [src]="'assets/baseline_more_vert_black_18dp.png'"
                                                id="dropdownMenuButton" data-toggle="dropdown" width="20px"
                                                height="20px" /> -->
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item"
                                                    (click)="Shipt_Changes(cost,Shipt_confirm)">Shippit</a>
                                                <a class="dropdown-item" (click)="Shipt_Changes(cost,'none')">None</a>
                                                <a class="dropdown-item">Other</a>
                                            </div>
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <br>
                        <!-- <div class="row">
                <div class="col-md-5">
                </div>
                <button class="btn btn-primary col-md-2" [disabled]="!savecost" (click)="saveall()"> save </button>
                <div class="col-md-5">
                </div>
              </div> -->

                        &nbsp; &nbsp;
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="modal fade" id="AddShippingCostsModal" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static"
                            data-keyboard="false">
                            <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="BecomeSellerModalLabel">
                                            {{ language.mLparams[lang_dync].manageWareHouse .shippingRules }}
                                        </h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                            id="shippingCostModalClose">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <table class="table table-striped">
                                                        <thead>
                                                            <th scope="col">#</th>
                                                            <th scope="col">
                                                                {{ language.mLparams[lang_dync]
                                                                .manageWareHouse.shippingRules }}
                                                            </th>
                                                            <th scope="col">
                                                                {{ language.mLparams[lang_dync]
                                                                .manageWareHouse.shippingRules }}
                                                            </th>
                                                            <th scope="col">
                                                                {{ language.mLparams[lang_dync]
                                                                .manageWareHouse.shippingRules }}
                                                            </th>
                                                        </thead>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <!-- footer Content -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Add New Shipping Cost Modal -->

            <!-- End of Shipping Cost Modal -->
        </ng-template>
    </ng-container>



    <!-----------------------------------------------------All Product------------------------------>

    <ng-container ngbNavItem="5">
        <a class="" ngbNavLink>{{
            language.mLparams[lang_dync].addProduct.AllProduct
            }}</a>

        <ng-template ngbNavContent>
            <br>
            <br>
            <app-all-product-list [warehouse]="selWareHouse"></app-all-product-list>

        </ng-template>
    </ng-container>


    <!-------------------------------------------Categorie------------------------------------->

    <ng-container ngbNavItem="6">
        <a class="" ngbNavLink><b>{{
                language.mLparams[lang_dync].addProduct.categorie
                }}</b></a>

        <ng-template ngbNavContent>
            <br>

            <app-add-product [warehous_Id]="selWareHouse" (IdEmitter)=geting_category($event)></app-add-product>
        </ng-template>
    </ng-container>
    </nav>


    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>

<modal #testModal [modalConfig]="confLogPartnerDelModalConfig">
    <p>
        {{ language.mLparams[lang_dync].manageWareHouse.delWarning1 }}
        <span *ngIf="checkSelLogPartner()">
            {{ selLogPartner.logistic_partner_name }}</span> {{ language.mLparams[lang_dync].manageWareHouse.delWarning2
        }}
    </p>
    <p class="text-danger">
        {{ language.mLparams[lang_dync].manageWareHouse.delWarning3 }}
    </p>
    <p class="text-center text-success" *ngIf="showLPDelRespMsg">
        {{ language.mLparams[lang_dync].manageWareHouse.logPrtnrDelRespMsg }}
    </p>
</modal>

<!-- //////////////////////////////////////////////////////////////// -->

<modal #productDel [modalConfig]="confLogProductDelModalConfig">
    <p>
        {{ language.mLparams[lang_dync].manageWareHouse.delWarning1 }}
        <span *ngIf="checkSelPrdctGrpr()">
            {{ selPrdctGrp.product_group_name }}</span> {{ language.mLparams[lang_dync].manageWareHouse.delWarning2 }}
    </p>
    <p class="text-danger">
        {{ language.mLparams[lang_dync].manageWareHouse.delWarning3 }}
    </p>
    <p class="text-center text-success" *ngIf="showPGDelResMsg">
        {{ language.mLparams[lang_dync].manageWareHouse.productGrpDelRespMsg }}
    </p>
</modal>

<!-- ///////////////////////////////////////////////// -->


<modal #LocationDel [modalConfig]="confLogLocationDelModalConfig">
    <p>
        {{ language.mLparams[lang_dync].manageWareHouse.delLocation }}
        <!-- <span *ngIf="checkSelPrdctGrpr()">
      {{ selPrdctGrp.product_group_name }}</span>  -->
        {{ language.mLparams[lang_dync].manageWareHouse.delWarning2 }}
    </p>
    <p class="text-danger">
        {{ language.mLparams[lang_dync].manageWareHouse.delWarning3 }}
    </p>
    <p class="text-center text-success" *ngIf="showPGDelResMsg">
        {{ language.mLparams[lang_dync].manageWareHouse.productGrpDelRespMsg }}
    </p>
</modal>
<!-------------------------------------------- -->

</div>

<ng-template #Shipt_confirm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Shippit charges</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label>Please confirm the charges will be take care by Shippit</label>
                <div class="input-group">
                    <!-- <input id="dateOfBirth" class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #dp="ngbDatepicker"> -->



                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')"
            (click)="Shipt_Changes3('shipit')">Change to Shippit</button>
    </div>
</ng-template>



<!-- Container End-->
<div style="height: 15px;"></div>