import { Component, OnInit } from '@angular/core';
import { FormGroup,FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-addprouct',
  templateUrl: './addprouct.component.html',
  styleUrls: ['./addprouct.component.scss']
})
export class AddprouctComponent implements OnInit {
myForm: FormGroup;
  constructor(private formbuilder: FormBuilder) { }
  disabled=false;
  showFilters=false;
  limitSelection=false;
  products: any;
  selectedItems:any=[];
  dropdownSettings:any = {};
  ngOnInit(): void {

    this.products = [
      {item_id:1,item_text: 'iphone'},
      {item_id:2,item_text: 'iphone2'},
      {item_id:3,item_text: 'iphone3'},
      {item_id:4,item_text: 'iphone4'},
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item-id',
      textField: 'item-text',
      SelectAllText: 'Select All',
      unSelectAllText: 'Unslect All',
      itemsShowLimit: '3',
      allowSearchFilter: this.showFilters,
    }

    this.myForm= this.formbuilder.group({
      products:[this.selectedItems]
    }); 
  }
  onitemSelect(items: any){
    console.log('onItemSelect',items);
  }
  onSelectAll(items: any){
    console.log('onSelectAll', items);
  }

}
