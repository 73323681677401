import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Request } from '../../_service/request';
import { APIs_Config, LocalstorageService } from '../../sys_config/';
import { LanguageService } from './../../config/language/index'
import { ModeServices } from '../../config/modes/index';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-repayment',
  templateUrl: './repayment.component.html',
  styleUrls: ['./repayment.component.scss'],
  providers: [LanguageService, ModeServices],
})
export class RepaymentComponent implements OnInit {

  constructor(
    private request: Request,
    private userStorage: LocalstorageService,
    public language: LanguageService,
    private modalService: NgbModal,
    private router: Router,
    private config :APIs_Config

  ) { }
  closeWarning = false;
  lang_dync = this.userStorage.current_language;
  pending = true;
  isContinueDisabled = true;
  isXdisabled = false;
  isducChecked = false;
  iscryptoChecked = false;
  isCryptoDisabled = true;
  iscryptoConformDisabled = true;
  paymentMethods: any = []
  paymentMethod: any = '';
  paymentCurrencyCode: any = '';
  walletAddress: any = '';
  paymentAmount: any = '';
  url: any = '';
  paymentReferenceId: any
  currencies: any = []
  txnNote: any = '';
  type: any = ''; // PAYMENT, SHIPMENT , BOTH
  bankCode: any = '';
  paymenttype: any;
  cryptobody1 = true;
  SelectCrypto = true;
  shippingDetails: any;
  prdamount: any;
  pay_type:any
  parcial_pay = false
  createPaymentObject: any = {
    paymentDetails: {

    },
    isFormPost: false,
    url: '',
    referenceId: '',
    checkStatusFrequently: ''
  }

  ngOnInit(): void {
    this.paymenttype = localStorage.getItem('pay_type')
    this.getPayment();
    this.getShippingCost();
    console.log(this.paymenttype)
    this.pay_type= localStorage.getItem('pay_type1')
    if(this.pay_type == 'paypal_parcialpayment_method1' ){
      this.parcial_pay = true;
    }
    var data2 = localStorage.getItem('pay_type')
    if(data2 == "payment_2"){
      this.parcial_pay = true;
    }
   this.get_single_order();
  }
  clicktocopy() {
    this.copytext = "copied";
    setTimeout(() => {
    this.copytext = "copy";
    }, 15000)
    }
    
    copytext: string = "copy";

  async getPayment() {
    try {
      let temp = this.request.get_structure('get_payment_method', 'format');
      let paymentDetails = await this.request.post(1, 'get_payment_method', temp);
      paymentDetails = this.getResponse(paymentDetails)
      this.paymentMethoOrder(paymentDetails)
    } catch (error) {
      console.log(error)
    }
  }
  reference_id:any;
  async getShippingCost() {
    let temp = this.request.get_structure('get_single_order', 'format');
    temp.invoice_id = this.userStorage.getUserInfo('tid').toString();
    console.log(temp);
    let data = await this.request.post(1, 'get_single_order', temp);
    console.log(data);
 
    if(this.pay_type ==	"paypal_parcialpayment_method1"){
      this.parcial_pay = true
    console.log(this.shippingDetails,"{{{_")
    this.shippingDetails = data[1].response.data.payments[0].amount;
    this.reference_id = data[1].response.data.payments[0].ref_id;
    this.prdamount = data[1].response.data.amount_payed;
  }else{
    this.shippingDetails = data[1].response.data.shipment_cost;
    this.prdamount = data[1].response.data.amount_payed;
  }
    if(  this.shippingDetails >= 1){
      this.shipingcost = true;
   }else{
    this.shipingcost = false;
   }
  }
  paymentpending= false
  shipingcost = true;

async get_single_order(){
  let temp = this.request.get_structure('get_single_order', 'format');
  temp.product_id = this.config.site_id
  temp.invoice_id = this.userStorage.getUserInfo('tid').toString();
  let data = await this.request.post(1, 'get_single_order', temp);
  console.log(data,"::::::::")
}
  paymentMethoOrder(result) {
    let res = {}
    for (let entry of result.paymentMethods) {
      let currencies = []
      res[entry.payment_gateway_name] = {
        shipment: entry.shipment
      }
      for (let currency of entry.currencies) {
        currencies.push(currency.currency_code)
      }
      res[entry.payment_gateway_name].currencies = currencies
    }
    this.paymentMethods = res
    // console.log(this.paymentMethods)
  }


  getResponse(responseBody) {
    if (responseBody[0] == 200)
      return responseBody[1]['response']['data']
    else
      throw new Error(responseBody[1]['response']['error'])
  }

  async chooseCurrency(currencyCode) {
    this.paymentCurrencyCode = currencyCode;
    this.SelectCrypto = false;
    await this.createPayment('payment')
  }

  async createPayment(type) {
    console.log()
    try {
      this.pending = true;
      this.walletAddress = ''
      this.paymentAmount = ''
      let temp = this.request.get_structure('create_payment', 'format');
      temp.paymentMethod = this.paymentMethod;
      console.log(temp.paymentMethod)
      temp.paymentCurrencyCode = this.paymentCurrencyCode
      temp.txnNote = this.txnNote
      temp.bankCode = this.bankCode
      temp.type = localStorage.getItem('pay_type')
      temp.order_id = this.userStorage.getUserInfo('tid');
      console.log(temp);
      let createPayment = await this.request.post(1, 'create_payment', temp);
      let ref_id = createPayment[1].response.data.referenceId;
      ref_id = this.userStorage.updateUserInfo('ref_id', ref_id);
      console.log(ref_id);
      this.createPaymentObject = this.getResponse(createPayment)
      console.log(createPayment, "response");
      if (createPayment[0] == 200) {
        this.isContinueDisabled = false;
        this.iscryptoConformDisabled = false;
      } else if (createPayment[0] == 400) {
        this.isContinueDisabled = true;
      }
      this.pending = false;
      if (this.paymentMethod == 'duc' || this.paymentMethod == 'aaplus') {
        this.iscryptoChecked = false;
        if (this.paymentMethod == 'duc') {
          localStorage.setItem('pay_type1', 'DUC');
          localStorage.removeItem('pay_type2');
          this.walletAddress = this.createPaymentObject.paymentDetails.walletAddress
          // this.paymentAmount = Math.round(this.createPaymentObject.paymentDetails.amount)
          let payAmount = this.createPaymentObject.paymentDetails.amount;
          console.log(payAmount);
          this.paymentAmount = parseFloat(payAmount).toFixed(2);
          console.log(this.paymentAmount);
        } else {
          localStorage.setItem('pay_type1', 'Crypto');
          localStorage.removeItem('pay_type2');
          this.walletAddress = this.createPaymentObject.paymentDetails.walletAddress
          this.paymentAmount = this.createPaymentObject.paymentDetails.amount
        }
      } else if (this.paymentMethod == 'paypal' || this.paymentMethod == 'quantumClearance') {
        localStorage.removeItem('pay_type1');
        if (this.paymentMethod == 'paypal') {
          localStorage.setItem('pay_type2', 'Paypal');
        } else {
          localStorage.setItem('pay_type2', 'quantumClearance');
        }
        this.url = this.createPaymentObject.url
        window.location.href = this.url
      }
    } catch (error) {
      this.pending = true;
      console.log(error)
    }
  }


  async createPayment2(data){
    console.log(data,"LL:")
  try {
      this.pending = true;
      this.walletAddress = ''
      this.paymentAmount = ''
      this.paymentpending = true;
      let temp = this.request.get_structure('create_duc_payment', 'format');
      temp.paymentMethod = data;
      temp.amount = this.shippingDetails;
      console.log(temp.paymentMethod)
      temp.paymentCurrencyCode = this.paymentCurrencyCode
      temp.txnNote = this.txnNote
      temp.bankCode = this.bankCode
      temp.type = localStorage.getItem('pay_type')
      temp.order_id = this.userStorage.getUserInfo('tid');
      console.log(temp);
      let createPayment = await this.request.post(1, 'create_duc_payment', temp);
      console.log(createPayment)
      if(createPayment){
        localStorage.setItem('pay_type1', 'paypal_parcialpayment_method1');
      }













      let ref_id = createPayment[1].response.data.referenceId;
      ref_id = this.userStorage.updateUserInfo('ref_id', ref_id);
      console.log(ref_id);
      this.createPaymentObject = this.getResponse(createPayment)
      console.log(createPayment, "response");
      if (createPayment[0] == 200) {
        this.isContinueDisabled = false;
        this.iscryptoConformDisabled = false;
      } else if (createPayment[0] == 400) {
        this.isContinueDisabled = true;
      }
      this.pending = false;
      // if (this.paymentMethod == 'duc' || this.paymentMethod == 'aaplus') {
      //   this.iscryptoChecked = false;
      //   if (this.paymentMethod == 'duc') {
      //     localStorage.setItem('pay_type1', 'DUC');
      //     localStorage.removeItem('pay_type2');
      //     this.walletAddress = this.createPaymentObject.paymentDetails.walletAddress
      //     // this.paymentAmount = Math.round(this.createPaymentObject.paymentDetails.amount)
      //     let payAmount = this.createPaymentObject.paymentDetails.amount;
      //     console.log(payAmount);
      //     this.paymentAmount = parseFloat(payAmount).toFixed(2);
      //     console.log(this.paymentAmount);
      //   } else {
      //     localStorage.setItem('pay_type1', 'Crypto');
      //     localStorage.removeItem('pay_type2');
      //     this.walletAddress = this.createPaymentObject.paymentDetails.walletAddress
      //     this.paymentAmount = this.createPaymentObject.paymentDetails.amount
      //   }
     if (this.paymentMethod == 'paypal' || this.paymentMethod == 'quantumClearance') {
        
        if (this.paymentMethod == 'paypal') {
          localStorage.setItem('pay_type2', 'Paypal');
        } else {
          localStorage.setItem('pay_type2', 'quantumClearance');
        }
        this.url = this.createPaymentObject.url
        window.location.href = this.url
      }
    } catch (error) {
      this.pending = true;
      console.log(error)
    }
  
  }

  selectPayment() {
    this.paymentCurrencyCode = ''
    this.currencies = this.paymentMethods[this.paymentMethod]['currencies']
    console.log(this.currencies)
  }

  triggerCloseWarning() {
    this.closeWarning = true;
    this.isXdisabled = true;
    setTimeout(() => {
      this.closeWarning = false;
      this.isXdisabled = false;
    }, 8000);
  }
  notcloseModal() {
    this.closeWarning = false;
    this.isXdisabled = false;
  }

  async closeModal() {
    let temp = this.request.get_structure('cancel_payment', 'format');
    temp.referenceId = this.userStorage.getUserInfo('ref_id');
    console.log(temp);
    let cancel = await this.request.post(1, 'cancel_payment', temp);
    console.log(cancel);
    this.paymentMethod = "",
      this.iscryptoChecked = false;
    this.iscryptoConformDisabled = true;
    this.SelectCrypto = true;
    // this.shippingPayment = true;
    // this.cryptoshipping = false;
    this.walletAddress = '';
    this.pending = true;
    // this.amount = '';
    // this.values = '';
    this.paymentCurrencyCode = ''
    this.cryptobody1 = true
    this.isXdisabled = false;
    this.isContinueDisabled = true;
    this.isducChecked = false;
    // this.shippingPayment = true;
    this.paymentAmount = '';
    // this.cryptobody1 = !this.cryptobody1;
    // this.ducProcessErr = true;
    this.userStorage.removeUserInfo('ref_id');
    setTimeout(() => {
      this.closeWarning = false;
    }, 500);
  }


  async onducConform() {
    let temp = this.request.get_structure('conform_payment_status', 'format');
    temp.referenceId = this.userStorage.getUserInfo('ref_id');
    console.log(temp);
    let duc_check = await this.request.post(1, 'conform_payment_status', temp);
    console.log(duc_check);
    if (duc_check[0] == 200) {
      this.isducChecked = true
      this.isContinueDisabled = true;
    } else if (duc_check[0] == 400) {
      // this.ducProcessErr = true;
      this.isContinueDisabled = true;
    }
  }

  async oncryptoConform() {
    console.log('crypto conform');
    let temp = this.request.get_structure('conform_payment_status', 'format');
    temp.referenceId = this.userStorage.getUserInfo('ref_id');
    console.log(temp);
    let crypto_status = await this.request.post(1, 'conform_payment_status', temp);
    console.log(crypto_status);
    this.iscryptoChecked = true;
    // this.cryptoshipping = !this.cryptoshipping;
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg', backdrop: 'static', keyboard: false })
  }

  onDucComplete() {
    this.router.navigateByUrl("/payment_confirmation/" + this.userStorage.getUserInfo('ref_id'))
  }

  oncryptoComplete() {
    this.router.navigateByUrl("/payment_confirmation/" + this.userStorage.getUserInfo('ref_id'))
  }
}
