import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { LoginService } from '../backend/login.service';
import { LanguageService } from '../config/language';
import { Request } from '../_service/request'
import { APIs_Config } from '../sys_config/api_path_config';
import { AddAddressFormData } from '../models/addAddrFormData'

@Component({
  selector: 'app-seller-reg',
  templateUrl: './seller-reg.component.html',
  styleUrls: ['./seller-reg.component.scss'],
  providers: [LanguageService, APIs_Config]

})
export class SellerRegComponent implements OnInit {


  constructor(private formBuilder: FormBuilder,
    private language: LanguageService,
    private logservice: LoginService,
    private request: Request,

  ) { }



  sellerregForm: FormGroup;
  logsubmitted = false;
  field1 = true;
  field2 = true;
  field3 = false;
  field4 = false;
  selectedFile: File;
  upload_data: any;
  basedata: any;
  imgbase64: any;
  fileencoded: any;
  first_image: string;
  second_image: string;
  third_image: string;
  combined_json: JSON;
  combined_val = [];
  error_message: string;
  span_one = false;
  span_two = false;
  span_three = false;
  country: any;
  countryList: any;
  country_state: any;
  state_city: any;
  city_postal: any;
  selectedcategory: string = "Select Product Category"
  categorylist: Array<{ name: string }> = [{
    name: "consumables"
  },
  { name: "food" }]


  ngOnInit(): void {
    this.sellerregForm = this.formBuilder.group({
      storename: ['', [Validators.required]],
      addressname1: ['', [Validators.required]],
      addressname2: ['', [Validators.required]],
      country: ['', [Validators.required]],
      State: ['', [Validators.required]],
      city: ['', [Validators.required]],
      p_code: ['', [Validators.required]],
    });
    this.initialCountry();
  }

  async initialCountry() {
    var temp = this.request.get_structure('country_api', 'format');
    // temp.product_id = 4;
    this.country = await this.request.post(1, 'country_api', temp);
    // console.log(this.country);
    // console.log(this.country[1].response.data.countries);
    if (this.country[0] == 200) {
      this.countryList = this.country[1].response.data;
      this.countryList.sort(function (a, b) {
        return a.country_name.localeCompare(b.country_name);
      });
    }

    // console.log(this.country[1].response.data);
  }

  onSelectCategory(data) {
    console.log(data);
    this.selectedcategory = data
  }

  async modelChanged(e) {
    if (e.target.value) {
      var temp = this.request.get_structure('country_api', 'format');
      temp.option = 3;
      temp.find = e.target.value;
      this.country_state = await this.request.post(1, 'country_api', temp);
      if (this.country_state[0] == 200) {
        this.country_state = this.country_state[1].response.data;
        this.country_state.sort(function (a, b) {
          return a.regions.localeCompare(b.regions);
        });
      }
      this.state_city = [];
      this.city_postal = [];
    }
  }
  async modelCity(e) {
    if (e.target.value) {
      var temp = this.request.get_structure('country_api', 'format');
      temp.option = 4;
      temp.find = e.target.value;
      this.state_city = await this.request.post(1, 'country_api', temp);
      if (this.state_city[0] == 200) {
        this.state_city = this.state_city[1].response.data;
        this.state_city.sort(function (a, b) {
          return a.city.localeCompare(b.city);
        });
      }
      this.city_postal = [];
    }
  }
  async modelPin(e) {
    if (e.target.value) {
      var temp = this.request.get_structure('country_api', 'format');
      temp.option = 5;
      temp.find = e.target.value;
      this.city_postal = await this.request.post(1, 'country_api', temp);
      console.log(this.city_postal);
      if (this.city_postal[0] == 200) {
        this.city_postal = this.city_postal[1].response.data;
        this.city_postal.sort(function (a, b) {
          return a.postal_code.localeCompare(b.postal_code);
        });
      }
    }
  }

  get f() { return this.sellerregForm.controls; }

  onEnterStoreDetails() {
    console.log('here');
    this.field2 = false;
    this.field3 = true;
  }

  onEnterTaxDetails(data) {
    console.log(data);
    this.selectedcategory = data
    this.field3 = !this.field3;
    this.field4 = !this.field4;
  }


  uploadthefile(event) {
    var selectedFile = <File>event.target.files[0];
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.onload = function () { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsDataURL(selectedFile);
    });

  }
  async onFileSelected(event: any, position: string) {
    console.log(event);

    let document_file = (<HTMLInputElement>document.getElementById(position)).files[0];

    var is_valid_file = false;
    console.log(document_file);
    if (typeof (document_file) == 'undefined') {
      if (position == 'one') {
        this.span_one = false;
      }
      else if (position == 'two') {
        this.span_two = false;
      }
      else if (position == 'three') {
        this.span_three = false;
      }
      return;
    }
    if (document_file.type == 'application/pdf' || document_file.type == 'image/jpeg' || document_file.type == 'image/png') {
      is_valid_file = true;
      if (position == 'one') {
        this.span_one = true;

      }
      else if (position == 'two') {
        this.span_two = true;
      }
      else if (position == 'three') {
        this.span_three = true;
      }
      this.imgbase64 = await this.uploadthefile(event);
    }
    else {
      this.error_message = "Accepted file formats are PNG, JPEG, PDF";
      return;
    }

    // console.log(this.imgbase64);
    if (is_valid_file == true) {
      var encoded = this.imgbase64.split(',');
      this.fileencoded = encoded[1];

      var temp = this.request.get_structure('document_upload', 'format');
      temp.file_name = document_file.name;
      temp.file_base64 = this.fileencoded;
      let fileuploading: any = await this.request.post(1, 'document_upload', temp);
      // console.log(fileuploading);
      this.combined_val.push(fileuploading[1].response.data.file_path);
      // console.log(this.combined_val);

    }


  }

  async fileUploadToServer() {
    this.combined_json;
    this.upload_data = {
      "payload": {
        "product_name": "demo1",
        "file_name": "demo1232.jpg",
        "image_name": "side view",
        "is_main": true,
        "file_base64": this.selectedFile

      }
    }

    this.basedata = await this.logservice.get_api(this.upload_data, 'bov3006/48310');
  }

  receiveAddress(addr: AddAddressFormData) {
    console.log(addr);
  }
}
