import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  message: any;
  give_cat_to_head: any;
  get_cat_name: any;

  private _teacher = new Subject<string>();
  private english_teacher = new Subject<string>();
  private finance_teacher = new Subject<string>();
  private science_teacher = new Subject<string>();
  private deliver_man = new Subject<string>();
  private your_location = new Subject<string>();

  teachermessage$ = this._teacher.asObservable();
  english_teachermessage$ = this.english_teacher.asObservable();
  finance_teachermessage$ = this.finance_teacher.asObservable();
  science_teachermessage$ = this.science_teacher.asObservable();
  deliver_manmessage$ = this.deliver_man.asObservable();
  your_locationmessage$ = this.your_location.asObservable();

  constructor() { }

  sendmessage(message: string) {
    setTimeout(() => {
      this._teacher.next(message)
    }, 1);
  }



  recive_method(data) {
    this.message = data
    console.log(" recive_method")
    localStorage.setItem('Category_id', data)
    console.log(data + 'stored')
  }

  give_method() {

    this.message = localStorage.getItem('Category_id')
    return this.message;

  }

  give_cat(data) {

    this.give_cat_to_head = data
  }


  set_cat_name(data){
      this.english_teacher.next(data) 
  }  

  payment_init(data){

    this.finance_teacher.next(data) 
 }  

 cart_for_now(data){

  this.science_teacher.next(data) 
 }

 Deliver_location_required(data){

  this.deliver_man.next(data) 
 }

 your_location_city(data){
   console.log(data,"/////////")
  this.your_location.next(data) 
 }
}
