<nav class="navbar navbar-expand-md navbar-dark bg-dark bg-dk">
    <div class="container">

        <a *ngIf="!Dshop_footer" class="navbar-brand n-brnd" routerLink="/">
            <img class="logo" src="assets\images\logo\Remus Nation - Logo (new).svg" alt="logo">
        </a>
        <a *ngIf="Dshop_footer" class="navbar-brand n-brnd" routerLink="/">
            <img class="logo" src="assets\images\logo\Remus Nation - Logo (new).svg" alt="logo">
        </a>
    </div>
</nav>
<nav class="navbar-expand-md bg-light navbar-dark bg-lyt">

    <div class="container-fluid">
        <div class="navrow nave-rowline">
            <div class="container">
                <ul class="navbar-nav">
                    <li>
                        <!-- <a class="nav-link nav-lnk" href="#about">ABOUT US</a> -->
                        <a *ngIf="!Dshop_footer" class="homebutton1" routerLink="/">{{this.language.mLparams[this.lang_dync].hme}}</a>
                        <a *ngIf="Dshop_footer" class="homebutton1" routerLink="/">{{this.language.mLparams[this.lang_dync].hme}}</a>
                    </li>
                    <!-- <li>
                    <a class="h-line pl-2 pl-md-0 ml-0 ml-md-2"></a>
                </li> -->
                    <li class="nav-item pl-2 pl-md-0 ml-0 ml-md-2">
                        <!-- <a class="nav-link nav-lnk nv-lk2" href="#about">CONTACT US</a> -->
                    </li>
                </ul>
            </div>

        </div>

    </div>

</nav>
<div class="row banner">
    <div class="container">
    <div class="text-white" style="font-weight:600; font-size: 2.5rem; top: 86%; position: absolute;">{{this.language.mLparams[this.lang_dync].legl}}</div>
    </div>
    </div>

<!-- <section class="banner">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
            </div>
        </div>
    </div>
    <div class="container">
        <div class="contents text-center text-md-left">
            <h1 class="text-white" style="margin-top: 34%;font-weight:600;">{{this.language.mLparams[this.lang_dync].legl}}</h1>
        </div>
    </div>
</section> -->



<!-- <section id="note" style="margin-top:15px;">
      <div class="container">
          <h2 class="note-head" style="color:red;font-weight:700;font-size:20px;">Note:</h2>
          <p style="color:#333;font-weight:600;font-size:18px;">
             For information purposes, this is a translation of contents originally written in English. In case of a discrepancy, the original English content will prevail.
          </p>
      </div>
  </section> -->


<div class="container" style="margin-bottom: 20px;">
    <p class="par" style="padding-top: 20px; padding-bottom: 20px;"><b>{{this.language.mLparams[this.lang_dync].lstupdt}}</b></p>
</div>
<div class="container">
   
    <div class="row">
        <ul class="nav nav-tabs legal-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#impressum" role="tab">{{this.language.mLparams[this.lang_dync].imprsm}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#privacy-policy" role="tab">{{this.language.mLparams[this.lang_dync].prpolicy}}</a>
            </li>

            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#terms" role="tab">{{this.language.mLparams[this.lang_dync].trmcndtn}}</a>
            </li>
        </ul>
    </div>
</div>

<!-- Tab panes -->
<div class="tab-content">
    <div class="tab-pane show active" id="impressum" role="tabpanel">
        <section class="connect-section pt-4 pt-md-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline1}}</p>
                      

                        <!--<p><b>Ducatus Global Pte. Ltd.</b></p>-->
                        <!--<p>160 Robinson Road #23-08<br>SBF Center, Singapore 068914<br>Phone:<br><a href="tel:6563865117">65 6386 5117</a><br>Website:<br><a href="https://alpha.ducatus.com/coins/">www.ducatus.net</a></p>-->

                        <p class="par"><b>{{this.language.mLparams[this.lang_dync].legalline2}}</b></p>

                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline3}}</p>
                        <!-- <p class="par"><b>https://remusnation.com/</b></p> -->
                        <p class="par"><b><a href="mailto:contact@remusnation.com">contact@remusnation.com</a></b></p>
                        <!--<p>Chief Executive Officer: Ronny Tome  </p>-->
                        <!--<p>For any questions you may have regarding our Company, send us an email: <a href="mailto:myorder@remusnation.com">myorder@remusnation.com </a>.</p>-->


                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline4}} <br>{{this.language.mLparams[this.lang_dync].legalline5}}</p>

                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline6}} <br>{{this.language.mLparams[this.lang_dync].legalline7}}</p>
                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline8}} </p>
                        <p class="par"> {{this.language.mLparams[this.lang_dync].legalline9}} </p>
                        <!-- <p class="par"><b> {{this.language.mLparams[this.lang_dync].legl1}}</b></p>
                        <p class="par"> {{this.language.mLparams[this.lang_dync].legl2}} <a href="https://www.remusnation.com">https://www.remusnation.com</a></p>
                     
                        <p class="par"> {{this.language.mLparams[this.lang_dync].legl3}} <a href="https://www.remusnation.com">https://www.remusnation.com</a>, {{this.language.mLparams[this.lang_dync].legl4}} </p> -->
      
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="tab-pane" id="privacy-policy" role="tabpanel">
        <div class="tab-pane" id="privacy-policy" role="tabpanel">
            <section class="connect-section pt-4 pt-md-5 common">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>{{this.language.mLparams[this.lang_dync].legalline10}}</h2>

                            <p class="par">{{this.language.mLparams[this.lang_dync].legalline11}}</p>

                            <p class="par">{{this.language.mLparams[this.lang_dync].legalline12}} </p>

                            <p class="par">{{this.language.mLparams[this.lang_dync].legalline13}} </p>

                            <p class="par"><a href="mailto:contact@remusnation.com"><b>privacy@remusnation.com</b></a></p>

                            <p class="par"><b>{{this.language.mLparams[this.lang_dync].legalline14}}</b> {{this.language.mLparams[this.lang_dync].legalline15}}</p>

                            <p class="par">{{this.language.mLparams[this.lang_dync].legalline16}}</p>

                            <h2>{{this.language.mLparams[this.lang_dync].legalline17}}</h2>

                            <p class="par"><b>{{this.language.mLparams[this.lang_dync].legalline18}}</b> {{this.language.mLparams[this.lang_dync].legalline19}}</p>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    </div>




    <div class="tab-pane " id="terms" role="tabpanel">
        <section class="connect-section pt-4 pt-md-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline20}} <a href="https://www.remusnation.com">https://www.remusnation.com</a></p>

                        <!--<p><b>Ducatus Global Pte. Ltd.</b></p>-->
                        <!--<p>160 Robinson Road #23-08<br>SBF Center, Singapore 068914<br>Phone:<br><a href="tel:6563865117">65 6386 5117</a><br>Website:<br><a href="https://alpha.ducatus.com/coins/">www.ducatus.net</a></p>-->

                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline21}} <a href="https://www.remusnation.com">https://www.remusnation.com</a> {{this.language.mLparams[this.lang_dync].legalline22}} </p>

                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline23}}</p>

                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline24}}</p>

                        <h2>{{this.language.mLparams[this.lang_dync].legalline25}}</h2>


                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline26}} (https://www.remusnation.com),{{this.language.mLparams[this.lang_dync].legalline27}} https://www.remusnation.com {{this.language.mLparams[this.lang_dync].legalline28}} https://www.remusnation.com
                            {{this.language.mLparams[this.lang_dync].legalline29}} <a href="https://www.remusnation.com">https://www.remusnation.com</a> {{this.language.mLparams[this.lang_dync].legalline30}} </p>

                        <h2>{{this.language.mLparams[this.lang_dync].legalline31}}</h2>
                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline32}} <a href="https://www.remusnation.com">https://www.remusnation.com</a></p>

                        <h2>{{this.language.mLparams[this.lang_dync].legalline33}}</h2>
                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline34}}</p>


                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline35}}</p>


                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline36}} <a href="https://www.remusnation.com">https://www.remusnation.com</a> {{this.language.mLparams[this.lang_dync].legalline37}} <a href="https://www.remusnation.com">https://www.remusnation.com</a>                            {{this.language.mLparams[this.lang_dync].legalline38}} </p>


                        <p class="par"><a href="https://www.remusnation.com"><b>https://www.remusnation.com</b></a> {{this.language.mLparams[this.lang_dync].legalline39}} </p>



                        <ol type="a">
                            <li class="cooky">{{this.language.mLparams[this.lang_dync].legalline40}}</li>
                            <li class="cooky">{{this.language.mLparams[this.lang_dync].legalline41}}</li>
                            <li class="cooky">{{this.language.mLparams[this.lang_dync].legalline42}}</li>
                            <li class="cooky">{{this.language.mLparams[this.lang_dync].legalline43}}</li>
                        </ol>

                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline44}} <a href="https://www.remusnation.com">https://www.remusnation.com</a> {{this.language.mLparams[this.lang_dync].legalline45}}</p>

                        <h2>{{this.language.mLparams[this.lang_dync].legalline46}}</h2>

                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline47}}<a href="https://www.remusnation.com"> https://www.remusnation.com</a> {{this.language.mLparams[this.lang_dync].legalline48}} 
                        </p>


                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline50}}</p>

                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline51}}</p>

                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline52}} </p>

                        <h2>{{this.language.mLparams[this.lang_dync].legalline53}}</h2>
                        <p class="par">{{this.language.mLparams[this.lang_dync].legalline54}}</p>

                    </div>
                </div>
            </div>
        </section>
    </div>

</div>
<app-footer *ngIf="Dshop_footer"></app-footer>