import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModeServices } from 'src/app/config/modes';
import { LanguageService } from "../../config/language/index"
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Request } from '../../_service/request'
import { APIs_Config, Config } from '../../sys_config/';
import { LocalstorageService } from '../../sys_config/index';
import { ShareResource } from '../../_service/interface'
import { MustMatch } from '../../_helpers/must-match.validator'
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LanguageService, ModeServices]
})
export class LoginComponent implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  @ViewChild('loginButton') loginButton: ElementRef<HTMLElement>;
  constructor(
    private formBuilder: FormBuilder,
    public language: LanguageService,
    public mode: ModeServices,
    private sanitizer: DomSanitizer,
    private router: Router,
    private request: Request,
    private api_config: APIs_Config,
    private userStorage: LocalstorageService,
    private share: ShareResource, el: ElementRef,
    private config: Config,
  ) { }

  type: any;
  name: any;
  model: any;
  logsubmitted = false;
  getducwalletform: FormGroup;
  lang_dync = this.userStorage.current_language;
  userType = this.userStorage.getUserInfo('userType');
  isSeller = this.userStorage.getUserInfo('is_seller');
  cart_product: number = this.share.all_cart_count;
  cart_price: any = this.share.all_cart_value;
  islogin = true;
  isSignup = false;
  signup_data: any;
  login_data: any;
  logindata: any;
  signupdata: any;
  jp: any;
  logoutdata: any;
  logoutload: any;
  loginForm: FormGroup;
  registerForm: FormGroup;
  submitted = false;
  error_login: any;
  category_data: any;
  button_check: any;
  html: SafeHtml;
  isUserLogged: boolean = false;
  jQuery: any;
  signup_success: any;
  signup_fail: any;
  country: any;
  countryList: any;
  country_state: any;
  trig_buyNow: any;
  selectCountryList: any;
  myProfile = "";
  addressList: any;
  location: any;
  postal_code: any;
  address_id: any;
  buttonState = true;
  noAddress = false;
  boUser = false;
  boLogin = false;
  baseUrl = ''
  nickname: any;
  AcceptAll = false;
  buildSource = new Config().cfg.buildSource.default
  account_name = this.language.mLparams[this.lang_dync].backoffice;
  secretKey = this.config.cfg.prdt_key.prdt ;
  cart_data: any = {
    "all_cart_count": this.userStorage.getUserInfo(this.userStorage.cart_count) ? this.userStorage.getUserInfo(this.userStorage.cart_count) : 0,
    "all_cart_value": this.userStorage.getUserInfo(this.userStorage.cart_value) ? this.userStorage.getUserInfo(this.userStorage.cart_value) : 0,
  };
  selectLocations: FormGroup;
  ngOnInit(): void {
    this.baseUrl = (this.buildSource) ? new Config().cfg.baseUrlProd.default : ''
    this.getducwalletform = this.formBuilder.group({
      getducaddr: ['', [Validators.required, Validators.pattern('^(?!.* )[a-zA-Z0-9\s]{34}')]],
    });

    this.registerForm = this.formBuilder.group({

      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z]{6,32}$')]],

      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9.]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*[@$!%*?&])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$')]],
      // ^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$
      confirmPassword: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    this.selectLocations = this.formBuilder.group({

      cstate: [''],
      wcountry: [''],
    })
    this.loginForm = this.formBuilder.group({

      lemail: ['', [Validators.required]],
      lpassword: ['', [Validators.required]],
    });
    // console.log(this.userStorage.removeUserInfo('token'));
    if (this.userStorage.getUserInfo(this.userStorage.token)) {
      this.isUserLogged = true;

      // this.getExistingAddr();
    }

    this.get_nickName();

    // this.button_check = this.comps.button_call('Test', 'button', 'btn btn-primary', 'onSubmit()');
    // this.html = this.sanitizer.bypassSecurityTrustHtml(this.button_check);
    // this.getCategories();

    // this.selectLocations = this.formBuilder.group({
    //   wcountry: [''],
    // })
    // this.share.addToCart(this.userStorage.cart_count, this.userStorage.cart_value);
    // this.cart_data = this.share.getItems();
    // this.trig_buyNow = this.share.get_buyNow();
    // this.triggerloginClick();



    // this.get_search_products();

  }

  get_nickName() {
    if (this.isUserLogged) {
      console.log("called");
      this.nickname = localStorage.getItem('nickname');
    }
  }

  setUserType(type, seller) {
    if (type == '4' && seller == true) {
      this.myProfile = 'seller';
    }
    else if (type == '4' && seller == false) {
      this.myProfile = 'buyer';
    }
    else if (type == 1) {
      this.myProfile = 'admin';
    }
    if (this.config.cfg.env_mode.default == 'production') {
      var encrypteddata = CryptoJS.AES.encrypt(JSON.stringify(this.myProfile), this.secretKey).toString();
      console.log(encrypteddata)
      localStorage.setItem('type', JSON.stringify(encrypteddata));
    } else if (this.config.cfg.env_mode.default == 'development') {
      localStorage.setItem('type', this.myProfile);
    }
    // this.myProfiles();
  }

  // myProfiles() {
  //   this.myProfile = localStorage.getItem('type')
  //   return this.myProfile;
  // }

  async checkUserName() {
    var temp = this.request.get_structure('_username', 'format');
    temp.username = this.registerForm.value.firstName;
    var check_username = await this.request.post(0, '_username', temp);
    this.signup_fail = temp.username ? '' : this.signup_fail;
    if (check_username[0] == 400) {
      this.signup_fail = this.language.mLparams[this.lang_dync][check_username[1].response.error];
    }
  }

  async checkUserMail() {

    var temp = this.request.get_structure('_email', 'format');
    temp.email = this.registerForm.value.email;
    this.signup_fail = temp.email ? '' : this.signup_fail;
    var check_useremail = await this.request.post(0, '_email', temp);

    if (check_useremail[0] == 400) {
      this.signup_fail = this.language.mLparams[this.lang_dync][check_useremail[1].response.error];
    }
  }
  tc_checkBox(event) {
    this.AcceptAll = true
    this.buttonState = true;
    console.log(event);
    if (event.target.checked) {
      this.submitted = true;

      if (this.registerForm.invalid) {
        this.buttonState = true;
        return;
      }
      else {
        this.buttonState = false;
      }

    }
  }
  reg_change(event) {
    console.log(event)
    console.log(this.AcceptAll)


    if (this.registerForm.invalid) {
      this.AcceptAll = false;
      this.buttonState = true;
      return;
    }
    else {
      this.AcceptAll = true;
      this.buttonState = false;
    }
  }
  triggerloginClick() {

    if (this.trig_buyNow != false) {
      let el: HTMLElement = this.loginButton.nativeElement;
      console.log('here');
      console.log(this.loginButton.nativeElement);
      console.log('here');
      el.click();
    }
    else {
      // console.log(' not here');
    }
  }
  triggerFalseClick() {
    let el: HTMLElement = this.myDiv.nativeElement;
    el.click();
    this.loginForm.reset();
  }


  get duc() { return this.getducwalletform.controls; }
  get f() { return this.registerForm.controls; }
  get lo() { return this.loginForm.controls; }

  onEnteredDucWalletaddr() {
    this.logsubmitted = true;
    console.log(this.getducwalletform.value.getducaddr);
    if (this.getducwalletform.invalid) {
      return;
    }
    else {
      console.log("true")
    }
  }

  // onCloseBecomeSeller() {
  //   this.logsubmitted = false;
  //   this.getducwalletform = this.formBuilder.group({
  //     getducaddr: ['', [Validators.required]],
  //   })
  // }
  async onLoginSubmit() {
    this.logsubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    else {
      this.login_data = {
        "payload": {
          "username": this.loginForm.value.lemail,
          "password": this.loginForm.value.lpassword,
          "product_id": 2
        }
      }
      // this.logindata = await this.logservice.get_api(this.login_data, 'bov3001/98310');
     if(this.boLogin == true){
        var temp = this.request.get_structure('bo_login', 'format');
        temp.username = this.loginForm.value.lemail;
        temp.password = this.loginForm.value.lpassword;
        this.logindata = await this.request.post(1, 'bo_login', temp);
        if (this.logindata[0] == 400) {
          console.log(this.logindata)
          if (this.logindata[1].response.error == "invalid_credentials") {
            this.error_login = this.language.mLparams[this.lang_dync].invalid_credentials;
          }
          // else if(this.logindata[1].response.error == "server_under_maintenance"){
          //   console.log(this.logindata[1].response.error)

          //   this.error_login = this.language.mLparams[this.lang_dync].server_under_maintenance;
          // }
          
          else if(this.logindata[1].response.error == "server_under_maintenance"){

              console.log(this.logindata[1].response.error)

              this.error_login = this.language.mLparams[this.lang_dync].server_under_maintenance;
            }
         
          else {
            this.error_login = this.language.mLparams[this.lang_dync].invalid_credentials;
          }
  
          setTimeout(() => this.error_login = false, 5000);
        }
      }else if(this.boLogin == false){
        var temp = this.request.get_structure('login_2', 'format');
        temp.username = this.loginForm.value.lemail;
        temp.password = this.loginForm.value.lpassword;
        this.logindata = await this.request.post(1, 'login_2', temp);
      }
      if (this.logindata[0] == 400) {
        if (this.logindata[1].response.error == "wrong_combination") {
          this.error_login = this.language.mLparams[this.lang_dync].invalid_credentials;
        } 
        else if(this.logindata[1].response.error == "email_not_verified"){
          console.log("email_not_verifi")
          this.error_login = this.language.mLparams[this.lang_dync].email_not_verified;
        }
        // else {
        //   this.error_login = this.language.mLparams[this.lang_dync].invalid_credentials;
        // }

        setTimeout(() => this.error_login = false, 5000);
      }

      else if (this.logindata[0] == 200) {
        
        var userData = {
          token: this.logindata[1].response.data.token,
          userType: this.logindata[1].response.data.user_type_id,
          is_seller: this.logindata[1].response.data.is_seller == "t" ? true : false,
          siteId: 2,
          lang_id: this.logindata[1].response.data.lang_id,
          username: this.logindata[1].response.data.username,
        }
       
        let nickname = userData.username.slice(0, 7);
        localStorage.setItem('nickname', nickname);
        this.nickname = localStorage.getItem('nickname');
        console.log(this.nickname);

        setTimeout(() => {
          if (localStorage.getItem('dbuy_tag') == 'true') {
            this.router.navigate(['/direct']);
            console.log('called');
          } else {
            this.router.navigate([''])
          }
        }, 200);
        
        console.log(userData.userType);
        this.setUserType(userData.userType, userData.is_seller);
        
        var is_userset = this.userStorage.setUserinfo(userData);
        
        var temp = this.request.get_structure('my_cart', 'format');
        temp.product_id = this.userStorage.getUserInfo('siteId');
        let cartData = await this.request.post(1, 'my_cart', temp);
        
        this.userStorage.updateUserInfo('cart_value', cartData[1].response.data.overall_total);
        this.userStorage.updateUserInfo('cart_count', cartData[1].response.data.overall_count);
        this.share.addToCart(cartData[1].response.data.overall_count, cartData[1].response.data.overall_total);
        this.triggerFalseClick();
        
        if (is_userset) {
          this.isUserLogged = true;
        }
        if (this.userStorage.buynow) {
          this.userStorage.buynow = false;
          this.router.navigate(['/cart']);
        }
      }
    }
  }

  // async logout() {
  //   var temp = this.request.get_structure('logout', 'format');
  //   // temp.product_id = 2;
  //   if (this.isUserLogged) {
  //     this.logoutdata = await this.request.post(1, 'logout', temp);
  //     this.share.addToCart(0, 0);
  //     this.cart_data = this.share.getItems();
  //     console.log(this.logoutdata);
  //     if (this.logoutdata[1].response.data == true) {
  //       this.userStorage.removeUserAllInfo();
  //       this.isUserLogged = false;
  //     }
  //   }
  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 100);
  // }

  changesignup() {
    this.islogin = false;
    this.isSignup = true;
  }
  async onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {

      return;
    }
    else {
      this.login_data = {
        "payload": {
          "sponsor": 0,                  	//default 0
          "account_type": 1,             	//default 1
          "username": this.registerForm.value.firstName,
          "password": this.registerForm.value.password,
          "email": this.registerForm.value.email,
          "country": "null",
          "product_id": 2,
          "company_name": "null",        	//default "null"
          "user_type": 4
        }
      }

      // this.signupdata = await this.logservice.get_api(this.login_data, 'bov3002/88314');
      var temp = this.request.get_structure('sign_up1', 'format');
      // temp.product_id = 1;
      temp.username = this.registerForm.value.firstName;
      temp.password = this.registerForm.value.password;
      temp.email = this.registerForm.value.email;
      temp.country = "null",
        // temp.product_id = 2,
        temp.company_name = "null",        	//default "null"
        temp.user_type = 4
      this.signupdata = await this.request.post(0, 'sign_up1', temp);


      if (this.signupdata[0] == 400) {
        var err = this.signupdata[1].response.error;

        if (err == 'user_name_exists') {
          this.signup_fail = this.language.mLparams[this.userStorage.current_language].user_name_exists;
        }
        else if (err == 'email_exists') {
          this.signup_fail = this.language.mLparams[this.userStorage.current_language].email_exists;
        }
        else {
          this.signup_fail = 'Err! Try Again';
        }
        setTimeout(() => this.signup_fail = false, 5000);

      }
      else if (this.signupdata[0] == 200) {

        this.signup_success = this.language.mLparams[this.userStorage.current_language].signup_success;
        this.changelogin();
        this.registerForm.reset();
        this.submitted = false;

      }
    }
  }
  forgetPassword() {
    this.router.navigate(['/resetpass']);
  }

  changelogin() {
    this.islogin = true;
    this.isSignup = false;
  }


  // onSelectLocation() {
  //   console.log(this.selectLocations.value);
  // }


  // async selectLocation() {
  //   var temp = this.request.get_structure('country_api', 'format');
  //   temp.option = 3;
  //   this.selectCountryList = await this.request.post(1, 'country_api', temp);
  //   console.log(this.selectCountryList);
  //   if (this.country_state[0] == 200) {
  //     this.country_state = this.country_state[1].response.data;
  //     this.country_state.sort(function (a, b) {
  //       return a.regions.localeCompare(b.regions);
  //     });
  //   }
  // }


  // async initialCountry() {
  //   var temp = this.request.get_structure('country_api', 'format');
  //   // temp.product_id = 4;
  //   this.country = await this.request.post(1, 'country_api', temp);
  //   // console.log(this.country);
  //   // console.log(this.country[1].response.data.countries);
  //   if (this.country[0] == 200) {
  //     this.countryList = this.country[1].response.data;
  //     this.countryList.sort(function (a, b) {
  //       return a.country_name.localeCompare(b.country_name);
  //     });
  //   }

  //   // console.log(this.country[1].response.data);
  // }
  // async getCategories() {
  //   // console.log("header cat");
  //   var temp = this.request.get_structure('get_category', 'format');
  //   // temp.product_id = 2;
  //   this.category_data = await this.request.post(0, 'get_category', temp);
  //   this.category_data = this.category_data[1].response.data;
  //   // console.log(this.category_data);
  // }

  loginClose() {
    this.loginForm = this.formBuilder.group({
      lemail: [''],
      lpassword: [''],
    }),
      this.registerForm = this.formBuilder.group({
        firstName: [''],
        email: [''],
        password: [''],
        confirmPassword: [''],
      }),
      this.islogin = true;
    this.isSignup = false;
    this.boUser = false;
    this.account_name = this.language.mLparams[this.lang_dync].backoffice;
  }

  // onlocationClose() {
  //   this.selectLocations = this.formBuilder.group({
  //     wcountry: [''],
  //   })
  // }

  // async getExistingAddr() {
  //   var temp = this.request.get_structure('address_info', 'format');
  //   // temp.product_id = 2;
  //   temp.order_status = "null";
  //   var get_address = await this.request.post(1, 'address_info', temp);
  //   // console.log(get_address);
  //   if (get_address[1].response.data.length > 0) {
  //     this.noAddress = false;
  //     this.addressList = get_address[1].response.data;
  //     this.addressList.forEach(value => {
  //       if (value.is_main == true) {
  //         this.location = value.city;
  //         this.postal_code = value.postal_code;
  //         this.address_id = value.address_id;
  //         this.userStorage.updateUserInfo('dId', this.address_id);
  //         // console.log(value.address_id);
  //       }
  //     });
  //   } else {
  //     this.noAddress = true;
  //   }
  // }

  // async addressChange(value) {
  //   var temp = this.request.get_structure('address_default', 'format');
  //   // temp.product_id = 2;
  //   temp.address_id = parseInt(value);
  //   var get_address = await this.request.post(1, 'address_default', temp);
  //   // this.isCheckoutDisabled = false;
  //   console.log(get_address);
  //   this.addressList.forEach(add_value => {
  //     if (add_value.address_id == value) {
  //       this.location = add_value.city;
  //       this.postal_code = add_value.postal_code;
  //     }
  //   });

  // }

  bouser() {
    this.boUser = !this.boUser;
    console.log(this.boUser);
    if (this.boUser == false) {
      this.account_name = this.language.mLparams[this.lang_dync].backoffice;
      this.boLogin = false;
    } else if (this.boUser == true) {
      this.boLogin = true;
      this.account_name = this.language.mLparams[this.lang_dync].remus
    }
  }



  sortStringInJson(a, b, key) {
    // console.log(a, b, key)
    let nameA = a[key].toUpperCase();
    let nameB = b[key].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // if this line is seen, names must be equal
    return 0;
  }




  /** Single Product page redirect with data*/
  // singlepage(product) {
  //   console.log(product);
  //   this.router.navigateByUrl("/product?pid=" + product);
  //   setTimeout(() => {
  //     location.reload();
  //   }, 200);
  // }
  /** Single Product page redirect with data*/

  eventHandler(keyvalue) {
    console.log(keyvalue);
    if (keyvalue == 13) {
      // this.singlepage()
    }
  }


}
