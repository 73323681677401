<div class="container mb-5" style="align-items: center;" *ngIf="!paymentpending">
    <div class="row">
        <div *ngIf="!parcial_pay">
            <div class="col-md-10 mt-5 mb-2 contbg" style="border: 1px solid rgb(209, 208, 208);
        border-radius: 5px;" *ngIf="paymenttype == 'shipment'">
                <h5 class="mt-2"> {{this.language.mLparams[lang_dync].repyline1}}
                    <b>{{this.language.mLparams[lang_dync].repyline2}} {{shippingDetails | number:'.1-2'}}</b>
                    {{this.language.mLparams[lang_dync].repyline3}}</h5>
            </div>
            <div class="col-md-10 mt-5 mb-2 contbg" style="border: 1px solid rgb(209, 208, 208);
        border-radius: 5px;" *ngIf="paymenttype == 'payment'">
                <h5 class="mt-2"> {{this.language.mLparams[lang_dync].repyline1}}
                    <b>{{this.language.mLparams[lang_dync].repyline2}} {{prdamount}}</b>
                    {{this.language.mLparams[lang_dync].repyline3}}</h5>
            </div>
        </div>
        <div *ngIf="parcial_pay">
            <div class="col-md-10 mt-5 mb-2 contbg" style="border: 1px solid rgb(209, 208, 208);
        border-radius: 5px;">
                <h5 class="mt-2"> {{this.language.mLparams[lang_dync].repyline1}}
                    <b>{{this.language.mLparams[lang_dync].repyline2}} {{shippingDetails | number:'.1-2'}}</b> towards
                    Partial payment
                </h5>
            </div>
        </div>


    </div>
    <div class="row">
        <div class="col-md-10 pt-1 pb-5 contbg" style="border: 1px solid rgb(209, 208, 208);
        border-radius: 5px;">
            <div class="form-check paymentselect" *ngIf="paymentMethods.duc && paymenttype == 'payment'">
                <label class="form-check-label">
                    <!-- <input type="radio" class="form-check-input" name="pg" 
                        data-toggle="modal" data-target="#ducpayment"
                         [(ngModel)]="paymentMethod"> -->

                    <input type="radio" class="form-check-input" name="pg" (click)="open(content1)"
                        [(ngModel)]="paymentMethod" value="duc" (change)="createPayment()">
                    <img src="assets/duc.svg" height="50px" width="50px" />
                    {{this.language.mLparams[lang_dync].chooseTopayInduc}}
                </label>
            </div>
            <br>
            <div *ngIf="paymentMethods.aaplus && paymenttype  == 'payment'">
                <div class="form-check paymentselect " placement="bottom"
                    ngbTooltip="Crypto Payment link is down for the moment for Code upgradation... We will resume soon, Kindly try other payment options">
                    <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="pg" (click)="open(content)" value="aaplus"
                            [ngClass]="{'disableDiv': isCryptoDisabled}" [(ngModel)]="paymentMethod"
                            (change)="selectPayment()">
                        <img src="assets/logo.png" height="50px" width="120px" />
                        {{this.language.mLparams[lang_dync].chooseToPayCrypto}}
                    </label>
                </div>
            </div>
            <div *ngIf="!parcial_pay">

                <!-- <div class="col-md-10 mt-5 mb-2 contbg" style="border: 1px solid rgb(209, 208, 208);
        border-radius: 5px;" *ngIf="paymenttype == 'shipment'">
                <h5 class="mt-2"> {{this.language.mLparams[lang_dync].repyline1}}vcvbvbvb
                    <b>{{this.language.mLparams[lang_dync].repyline2}} {{shippingDetails}}</b> towards Parcial payment
                </h5>
            </div> -->
                <div *ngIf="shipingcost">
                    <br>
                    <!-- <div class="form-check paymentselect" *ngIf="paymentMethods.quantumClearance"
                        style="margin-bottom: 10px;">
                        <label class="form-check-label">
                            <input type="radio" class="form-check-input " name="pg" value="quantumClearance"
                                height="60px" width="180px" [(ngModel)]="paymentMethod" (change)="createPayment()"
                                style="margin-top: 10px;"><img src="assets/Mastecard, visa and amex 2.svg"
                                height="80px" />
                        </label>
                    </div> -->
                    <br>
                </div>

                <!-- <div class="form-check paymentselect">
                    <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="pg" data-toggle="modal"
                            data-target="#bopaymentmodal" (change)="paymentType($event)" value="bo">
                        <img src="../../../assets/bopayment.png" height="50px" width="50px" /> Utilize your Backoffice
                        Shopping Account Balance
                    </label>
                </div>
                <br> -->
                <div class="form-check paymentselect" *ngIf="paymentMethods.paypal ">
                    <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="pg" value="paypal"
                            [(ngModel)]="paymentMethod" (change)="createPayment()" style="margin-top: 10px;">
                        <img src="assets/PayPal.svg" height="40px" />
                    </label>
                </div>
            </div>
            <div *ngIf="parcial_pay">
                <!-- <div *ngIf="shipingcost">
                    <br>
                    <div class="form-check paymentselect" *ngIf="paymentMethods.quantumClearance"
                        style="margin-bottom: 10px;">
                        <label class="form-check-label">
                            <input type="radio" class="form-check-input " name="pg" value="quantumClearance"
                                height="60px" width="180px" [(ngModel)]="paymentMethod"
                                (change)="createPayment2('quantumClearance')" style="margin-top: 10px;"><img
                                src="assets/Mastecard, visa and amex 2.svg" height="80px" />
                        </label>
                    </div> -

                </div>
                <hr> -->
                <!-- <div class="form-check paymentselect">
                    <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="pg" data-toggle="modal"
                            data-target="#bopaymentmodal" (change)="paymentType($event)" value="bo">
                        <img src="../../../assets/bopayment.png" height="50px" width="50px" /> Utilize your Backoffice
                        Shopping Account Balance
                    </label>
                </div>
                <br> -->
                <div class="form-check paymentselect" *ngIf="paymentMethods.paypal ">
                    <label class="form-check-label">
                        <input type="radio" class="form-check-input" name="pg" value="paypal"
                            [(ngModel)]="paymentMethod" (change)="createPayment2('paypal')" style="margin-top: 10px;">
                        <img src="assets/PayPal.svg" height="40px" />
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- DUC Payment Modal -->


<ng-template #content1 let-modal>
    <div>
        <div c>
            <div class="modal-header">
                <h5 class="modal-title" id="ducpayment">
                    {{this.language.mLparams[lang_dync].DUCPayment}}
                </h5>
                <button type="button" class="close" (click)="triggerCloseWarning()" [disabled]="isXdisabled">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row ">
                    <div *ngIf="closeWarning" class="container mb-2 " style="text-align: center;">
                        <div class="m-3 p-3 border border-danger rounded shadow-lg ">
                            <p> {{this.language.mLparams[lang_dync].areYouSureToCancelTransaction}}</p><button
                                class="btn btn-danger btn-sm m-1 " data-dismiss="modal" (click)="closeModal()"
                                (click)="modal.dismiss('Cross click')"
                                aria-label="Close">{{this.language.mLparams[lang_dync].yes}}</button>
                            <button class="btn btn-success btn-sm m-1" data-dismiss="modal" (click)="notcloseModal()"
                                aria-label="Close">{{this.language.mLparams[lang_dync].no}}</button>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                        <p class="text-danger text-small">{{this.language.mLparams[lang_dync].noteTxt}}
                        </p>
                        <div class="container" align="center">
                            <p>{{this.language.mLparams[lang_dync].scanQRtoMake}} <b>DUC</b>
                                {{this.language.mLparams[lang_dync].payment}}
                            </p>
                            <section *ngIf="pending">
                                <!--Big blue-->
                                <div class="row" style="margin: 20%;">
                                    <div class="col-md-5 col-sm-2"></div>
                                    <div class="col-md-2 col-sm-2">
                                        <div class="spinner-border text-primary" style="text-align: center;"></div>
                                    </div>
                                    <div class="col-md-5 col-sm-2"></div>
                                </div>
                            </section>
                            <ngx-qrcode *ngIf="!pending" [elementType]="elementType" [value]="walletAddress">
                            </ngx-qrcode>
                            <p>{{this.language.mLparams[lang_dync].sendingOtherDigitalAssetswillResultInPaymentLoss}}
                            </p>
                            <p><b>{{this.language.mLparams[lang_dync].pleaseEnsureThatYouEnteredTheFullAmountof}} <b
                                        class="text-danger">DUC {{paymentAmount}}</b>
                                    {{this.language.mLparams[lang_dync].whileMakingthePaymentFromTheApp}}
                                </b>
                            </p>
                            <div class="form-group">
                                <label for="resource-id">{{this.language.mLparams[lang_dync].walletAddress}}</label>
                                <div class="input-group">
                                    <input id="resource-id" [(ngModel)]="walletAddress" type="text" class="form-control"
                                        name="id" placeholder="Payment Address" readonly="readonly">
                                    <button class="input-group-text" (click)="clicktocopy()"
                                        [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                </div>
                            </div>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="ducPayment" [checked]="isducChecked"
                                [disabled]="isContinueDisabled" (change)="onducConform()">
                            <label class="form-check-label"
                                for="ducPayment">{{this.language.mLparams[lang_dync].iConfirmThatIhaveMadeFullPaymentOfDUC}}{{paymentAmount}}
                                {{this.language.mLparams[lang_dync].toTheAboveAddress}}</label>
                        </div>
                        <div class="text-success" *ngIf="isducChecked">
                            <p>{{this.language.mLparams[lang_dync].tnkforpymnt}}
                                {{this.language.mLparams[lang_dync].recvline}}</p>
                        </div>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="float-right">
                    <button class="btn btn-primary" [disabled]="!isducChecked" (click)="onDucComplete()"
                        (click)="modal.dismiss('Cross click')">{{this.language.mLparams[lang_dync].cntnue}}</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Duc Payment Modal End -->


<!-- crypto payment Modal -->

<ng-template #content let-modal>
    <div>
        <div>
            <div class="modal-header">
                <h5 class="modal-title" id="modal-basic-title">{{this.language.mLparams[lang_dync].repyline4}}</h5>
                <button type="button" class="close" (click)=triggerCloseWarning() [disabled]="isXdisabled">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row ">
                    <div *ngIf="closeWarning" class="container mb-2 " style="text-align: center;">
                        <div class="m-3 p-3 border border-danger rounded shadow-lg ">
                            <p> {{this.language.mLparams[lang_dync].areYouSureToCancelTransaction}}</p><button
                                class="btn btn-danger btn-sm m-1 " data-dismiss="modal" (click)="closeModal()"
                                (click)="modal.dismiss('Cross click')"
                                aria-label="Close">{{this.language.mLparams[lang_dync].yes}}</button>
                            <button class="btn btn-success btn-sm m-1" data-dismiss="modal" (click)="notcloseModal()"
                                aria-label="Close">{{this.language.mLparams[lang_dync].no}}</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="cryptobody1">
                    <div class="row">
                    </div>
                    <div *ngIf="SelectCrypto">
                        <div class="dropdown show d-flex flex-row justify-content-center">
                            <a class="btn btn-secondary dropdown-toggle" role="button" data-toggle="dropdown"
                                aria-haspopup="true">
                                {{this.language.mLparams[lang_dync].selectAsset}}
                            </a>

                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <a class="dropdown-item" *ngFor="let asset of currencies"
                                    (click)="chooseCurrency(asset)">{{asset}}</a>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!SelectCrypto">
                        <div class="row no-gutters">
                            <div class="col-md-2"></div>
                            <div class="col-md-8">
                                <p class="text-danger text-small">{{this.language.mLparams[lang_dync].noteTxt}}</p>
                                <p>{{this.language.mLparams[lang_dync].scanQRtoMake}} <b>{{paymentCurrencyCode}}</b>
                                    {{this.language.mLparams[lang_dync].payment}}
                                </p>
                                <div class="container" align="center">
                                    <p>{{this.language.mLparams[lang_dync].repyline5}} <b>{{paymentCurrencyCode}}:
                                            {{paymentAmount}}</b></p>
                                    <p>{{this.language.mLparams[lang_dync].scnqrcodeonly}}
                                        <b>{{paymentCurrencyCode}}</b> {{this.language.mLparams[lang_dync].pymnt}}</p>
                                    <section *ngIf="pending">
                                        <!--Big blue-->
                                        <div class="row" style="margin: 20%;">
                                            <div class="col-md-5 col-sm-2"></div>
                                            <div class="col-md-2 col-sm-2">
                                                <div class="spinner-border text-primary" style="text-align: center;">
                                                </div>
                                            </div>
                                            <div class="col-md-5 col-sm-2"></div>
                                        </div>
                                    </section>
                                    <ngx-qrcode *ngIf="!pending" [elementType]="elementType" [value]="walletAddress">
                                    </ngx-qrcode>

                                    <div class="form-group">
                                        <label for="resource-id">{{this.language.mLparams[lang_dync].wtadrs}}</label>
                                        <div class="input-group">
                                            <input id="resource-id" value="{{walletAddress}}" type="text"
                                                class="form-control" name="id" placeholder="Payment Address"
                                                readonly="readonly">
                                            <button class="input-group-text" (click)="clicktocopy()"
                                                [cdkCopyToClipboard]="walletAddress">{{copytext}}</button>
                                        </div>
                                    </div>
                                    <p class="text-danger justify-content-left">
                                        {{this.language.mLparams[lang_dync].repyline6}}</p>
                                    <div class="form-check">
                                        <label class="form-check-label" for="ducPayment">
                                            <input type="checkbox" class="form-check-input" id="ducPayment"
                                                [checked]="iscryptoChecked" (click)="oncryptoConform()"
                                                [disabled]="iscryptoConformDisabled">
                                            {{this.language.mLparams[lang_dync].repyline7}}</label>
                                    </div>
                                    <div class="text-success" *ngIf="iscryptoChecked">
                                        <p>{{this.language.mLparams[lang_dync].repyline8}} </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                    <div class="modal-footer" *ngIf="iscryptoChecked">
                        <div class="float-right">
                            <button class="btn btn-primary" [disabled]="!iscryptoChecked" (click)="oncryptoComplete()"
                                (click)="modal.dismiss('Cross click')">{{this.language.mLparams[lang_dync].cntue}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- crypto payment end -->


<div style="height: 200px;"></div>

<section *ngIf="paymentpending">
    <!--Big blue-->
    <div class="row" style="margin: 20%;">
        <div class="col-md-5 col-sm-2"></div>
        <strong class="text-primary" style="text-align: left; padding-top: 5px;">Please Wait You Will Be Redirected
            Shortly...</strong>
        <div class="col-md-2 col-sm-2">
            <div class="spinner-border text-primary" style="text-align: center;"></div>
        </div>
        <div class="col-md-5 col-sm-2"></div>
    </div>
</section>