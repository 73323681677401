import { Injectable } from '@angular/core';

@Injectable()
export class ModeServices {

    config_mode = {
        two : false,
        three : true,
        four : false,
    };

    banner_prd_id = {
        prd_fs : 245,
    }



}