import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';

@Injectable()
export class Encryption {

    encode(encode_data: any) {
        try {
            var k1, k2;
            var b1, b2;
            //randomkey password 
            const random = Math.floor(1000000000000000 + Math.random() * 9000000000000000);
            const pwd = random.toString();

            //find key
            k1 = pwd.substr(0, 8);
            k2 = pwd.substr(8, 8);

            //find bit
            b1 = k1.length;
            b2 = k2.length;

            //process
            let _key = CryptoJS.enc.Utf8.parse(pwd);
            let cipher = CryptoJS.AES.encrypt(
                encode_data, _key, {
                iv: _key
            });
            var encrypted = cipher.toString();
            //set format
            encrypted = k2 + b1 + k1 + b2 + encrypted;

            return encrypted;
        } catch (error) {
            return 'encryption_error';
        }
    }

    decode(decode_data: any) {
        try {
            var data = decode_data;
            var bit;
            var key, k1, k2;
            var b1, b2;
            var min, max;

            //find bit
            b1 = data.substr(8, 1);
            b2 = data.substr(17, 1);

            //find key
            k2 = data.substr(0, b2);
            k1 = data.substr((k2.length + b1.length), b1);
            key = k1 + k2;

            //find cryptodata
            bit = parseInt(b1) + parseInt(b2);
            min = parseInt(bit) + b1.length + b2.length;
            max = parseInt(data.length) - min;
            var cryptodata = data.substr(min, max);

            //process
            let _key = CryptoJS.enc.Utf8.parse(key);
            var decrypted = CryptoJS.AES.decrypt(
                cryptodata, _key, {
                iv: _key
            }).toString(CryptoJS.enc.Utf8);

            return decrypted;

        } catch (error) {
            return 'encryption_error';
        }

    }
}
