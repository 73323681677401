
<div class="container">

    <h4 class="m-5 text-center">{{ formTitle }}</h4>

   
    <!-- Name Field -->
    <div class="form-row mt-2">
        <div class="form-group col-md-5 col-sm-12" [ngClass]="showMobileField()">
            <label for="formName">{{
            language.mLparams[lang_dync].addrForm.name
          }}</label>
           <input  (focus)="onNameFocus()" (change)="onNameChange(addrFormName.value)" [value]="formInfo.name" (blur)="onNameBlur(addrFormName.value)" type="text" [placeholder]="language.mLparams[lang_dync].addrForm.namePlcHolder" class="form-control" id="formName"
                #addrFormName   appAutofocus/>
            <small *ngIf="formInfo.isNameErr" class="form-text text-danger ml-1">{{ language.mLparams[lang_dync].addrForm.nameErr }}
      </small>
        </div>

        <!-- Mobile# Field -->
        <div class="form-group col-md-3 col-sm-12" [ngClass]="hideDivWithMob()">
            <label for="formMobile">{{
            language.mLparams[lang_dync].addrForm.mobile
          }}</label>
            <input #addrFormMobile type="number"  [placeholder]="language.mLparams[lang_dync].addrForm.mobilePlcHolder" class="form-control" id="formMobile" (focus)="onMobileFocus()" (change)="onMobileChange(addrFormMobile.value)" [value]="formInfo.mobile" (blur)="onMobileBlur(addrFormMobile.value)"
            />
            <small *ngIf="formInfo.isMobileErr" class="form-text text-danger ml-1">{{ language.mLparams[lang_dync].addrForm.mobileErr }}
      </small>
        </div>

        <!-- <div class="form-group col-md-3 col-sm-12"  [ngClass]="hidedeliver()">
          <label for="formMobile">Delivery</label>

          
          


            <div>
                        <input  type="radio"  #addrFormdelivery  name="conditionRadio" class="form-control" id="formDelivery">
              Shippit
            
                        <input  type="radio"  #addrFormdelivery  name="conditionRadio" >
              None
            </div>
          
      </div> -->
        <div class="form-group col-md-4"></div>
    </div>

    <!-- Address 1 Field -->
    <div class="form-row justify-content-center">
        <div class="form-group col-md-5 col-sm-12">

            <label for="formAddr1">{{
            language.mLparams[lang_dync].addrForm.addr1
          }}</label>
            <input [placeholder]="language.mLparams[lang_dync].addrForm.addr1PlcHolder" type="text" class="form-control" id="formAddr1" #addrFormAddr1 (focus)="onAddr1Focus()" (change)="onAddr1Change(addrFormAddr1.value)" [value]="formInfo.addr1" (blur)="onAddr1Blur(addrFormAddr1.value)"
            />
            <small *ngIf="formInfo.isAddr1Err" class="form-text text-danger ml-1">{{ language.mLparams[lang_dync].addrForm.addr1Err }}
      </small>
        </div>

        <!-- Address 2 Field -->

        <div class="form-group col-md-5 col-sm-12">

            <label for="formAddr2">{{
            language.mLparams[lang_dync].addrForm.addr2
          }}</label>
            <input [placeholder]="language.mLparams[lang_dync].addrForm.addr2PlcHolder" type="text" class="form-control" id="formAddr2" #addrFormAddr2 [value]="formInfo.addr2" (change)="onAddr2Change(addrFormAddr2.value)" />
        </div>

        <!-- City Field -->
        <div class="form-group col-md-2 col-sm-12">

            <label for="formCity">{{
            language.mLparams[lang_dync].addrForm.city
          }}</label>
            <input [placeholder]="language.mLparams[lang_dync].addrForm.cityPlcHolder" type="text" class="form-control" id="formCity" #addrFormCity (focus)="onCityFocus()" (change)="onCityChange(addrFormCity.value)" [value]="formInfo.city" (blur)="onCityBlur(addrFormCity.value)"
            />
            <small *ngIf="formInfo.isCityErr" class="form-text text-danger ml-1">{{ language.mLparams[lang_dync].addrForm.cityErr }}
      </small>
        </div>
    </div>

    <!-- Country Select Field -->
    <div class="form-row justify-content-center">
        <div class="form-group col-md-4 col-sm-12">

            <label for="countrySelect">{{
            language.mLparams[lang_dync].addrForm.country
          }}</label>
            <select class="form-control" id="countrySelect" (change)="onCountrySelect($event)">
        <option>{{ language.mLparams[lang_dync].Choose }}</option>
        <option [value]="country.country_name" *ngFor="let country of countryList">
          {{ country.country_name }}
        </option>
      </select>

            <small *ngIf="formInfo.isCountryErr" class="form-text text-danger ml-1">{{ language.mLparams[lang_dync].addrForm.selectErr }}
      </small>
        </div>

        <!-- State Select Field -->

        <div class="form-group col-md-4 col-sm-12">

            <label for="stateSelect">{{
            language.mLparams[lang_dync].addrForm.state
          }}</label>
            <select class="form-control" id="stateSelect" (change)="onStateSelect($event)">
        <option>{{ language.mLparams[lang_dync].Choose }}</option>
        <option [value]="state.regions" *ngFor="let state of stateList">
          {{ state.regions }}
        </option>
      </select>

            <small *ngIf="formInfo.isStateErr" class="form-text text-danger ml-1">{{ language.mLparams[lang_dync].addrForm.selectErr }}
      </small>
        </div>

        <!-- Zipcode Field -->
        <div class="form-group col-md-4 col-sm-12">

            <label for="formZipCode">{{
            language.mLparams[lang_dync].addrForm.code
          }}</label>
            <input [placeholder]="language.mLparams[lang_dync].addrForm.zipPlcHolder" type="number" maxlength="5" class="form-control" id="formZipCode" #addrFormZip (change)="onCodeChange(addrFormZip.value)" (blur)="onCodeBlur(addrFormZip.value)" />
            <small *ngIf="formInfo.isZipErr && !formInfo.isZiplenErr" class="form-text text-danger ml-1">{{ language.mLparams[lang_dync].addrForm.zipErr }}
      </small>
      <small *ngIf="formInfo.isZiplenErr" class="form-text text-danger ml-1">{{ language.mLparams[lang_dync].addrForm.ziplenErr }}
      </small>
        </div>
    </div>

    <div class="text-left">
        <small class="form-text text-muted">{{
        language.mLparams[lang_dync].addrForm.conditionsText
      }}</small>
    </div>

    <div class="text-center" style="padding-bottom: 30px;">
        <button (click)="
          handleSaveWithMob(
            addrFormName.value,
            addrFormAddr1.value,
            addrFormAddr2.value,
            addrFormCity.value,
            addrFormZip.value,
            addrFormMobile.value
          )
        " class="btn addressavebtn" [ngClass]="hideDivWithMob()">
      {{ language.mLparams[lang_dync].addrForm.saveBtnText }}
    </button>
    </div>

    <div class="text-center" [ngClass]="hideDiv()">
        <button (click)="
          handleSaveWOMob(
            addrFormName.value,
            addrFormAddr1.value,
            addrFormAddr2.value,
            addrFormCity.value,
            addrFormZip.value
          )
        " class="btn addressavebtn">
      {{ language.mLparams[lang_dync].addrForm.saveBtnText }}
    </button>
    </div>
</div>