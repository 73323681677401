<!-- <div class="container"> -->

<div>
  <div class="container" style="padding-top: 20px;">
    <div  *ngIf="act_success">
       <p class="alert alert-success" style="text-align: center;">{{act_success | titlecase}} </p>
      </div>
      <div  *ngIf="act_error">
      <p class="alert alert-danger" style="text-align: center;">{{act_error | titlecase}}</p>  
      </div>
  </div>
    <div class="content">


        <h4 style="text-align: center; margin-bottom: 50px;" class="modal-title" id="modal-basic-title">Redeem Your DUC
            Using DSV
            Voucher Code</h4>




        <form [formGroup]="getVoucherDetails" (ngSubmit)="onsubmitVoucher()">
            <div class="form-group">
                <label>Voucher Code</label>

                <input required autofocus class="form-control" id="name" type="text"
                    placeholder="Enter Your Voucher Code" formControlName="input1"
                    [ngClass]="{ 'is-invalid':logsubmitted && voc.input1.errors }" autofocus>
                <div *ngIf="logsubmitted && voc.input1.errors">
                    <div *ngIf="voc.input1.errors.required" class="text-danger">
                        Voucher Code is Required
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Email ID</label>
                <input required class="form-control" type="email" placeholder="Enter Your valid Email ID"
                    formControlName="input2"   [ngClass]="{ 'is-invalid':logsubmitted && voc.input2.errors }">
                    <div *ngIf="logsubmitted && voc.input2.errors">
                        <div *ngIf="voc.input2.errors.required" class="text-danger">
                            Email is Required
                        </div>
                        <div *ngIf="voc.input2.errors.pattern" class="text-danger">
                            Please Enter Valid Email Address
                        </div>
                    </div>
            </div>


            <div style="text-align: center;">
                <!-- <p *ngIf="success" class="text-success" style=" margin-right: 50px;">Succesfully Saved</p> -->
                <input type="submit" class="btn btn-primary" value="Submit" >
                <!-- [disabled]="edit_address.invalid" -->
            </div>
        </form>


    </div>
    <!-- <div style="padding-top: 180px;"></div> -->
    </div>