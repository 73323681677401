import { Component, OnInit, PipeTransform } from '@angular/core';
import { LanguageService } from '../../config/language/index';
import { ModeServices } from '../../config/modes/index'
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl, FormGroup, Validator, Validators, FormBuilder } from '@angular/forms';
import { Request } from '../../_service/request';
import { APIs_Config, LocalstorageService } from '../../sys_config'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ServicesService } from 'src/app/_service/services.service';
import { Base64 } from 'src/app/_service/Base64_converter';
import { TemplateParseError } from '@angular/compiler';

@Component({
  selector: 'app-adminpanel',
  templateUrl: './adminpanel.component.html',
  styleUrls: ['./adminpanel.component.scss'],
  providers: [LanguageService, DecimalPipe, ModeServices]
})
export class AdminpanelComponent implements OnInit {
  addProdSuccss: any;
  cat_update_success = false;
  category_name: any;
  category_id: any;
  server_url = this.request.server_url;
  closeModal = "";
  count: any;
  purchase_data: any;
  prdDeleteSuccess = false
  editSuccessMsg = false;
  qty: any;
  selectedPrdid: any;
  sellerDeclineForm: FormGroup;
  productDeclineForm: FormGroup;
  shippingForm: FormGroup;
  seller_FAQ: FormGroup
  editProductForm: FormGroup;
  stockForm: FormGroup;
  logsubmitted = false;
  editProductId: any;
  pendingSeller = [1, 2, 3]
  date = "10/12/20";
  id: number = 2115;
  name: string = "DucatusPens";
  sender_email: string = "ab2@123.com";
  page = this.userStorage.page;
  pageSize = this.userStorage.pageSize;
  collectionSize: any;
  response: any;
  pending_sellers: any;
  sellers: any;
  all_products: any;
  allProductList: any;
  submit_qa = true;
  buyers: any;
  orders: any;
  category_data: any;
  lang_dync = this.userStorage.current_language;
  formula: string = "Formula 1";
  theCheckbox = true;
  productStatus = true;
  countries = []
  faq_data: any;
  data = []
  showWareHouse = false;;
  modalId = ""
  sellerProductsData: any;
  sellerOrderData: any;
  productDescription: any;
  productName: any;
  quantity_left: any;
  cat_list: any;
  shippingDetails = true;
  emptyPendingProducts: any;
  sellerOrderDataToBeSelected = {
    action: ['shipped', 'canceled', 'delivered', 'pending']
  };
  allorderoptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['Invoice id', 'Date', 'Buyer Id', 'Payment Status', 'Product id', 'Product Name', 'Seller id', 'Order value', 'Order Status'],
    showTitle: true,
    title: 'All order form',
    useBom: false,
    removeNewLines: true,
    keys: ['purchase_uuid', 'purchase_date', 'account_id', 'payment_status', 'unique_product_id', 'product_name', 'seller_id', 'amount_payed', 'order_status']
  };
  allProducts = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['Product id', 'Product Name', 'Category', 'Seller', 'Seller Email', 'Price', 'Approve Status'],
    showTitle: true,
    title: 'All Products form',
    useBom: false,
    removeNewLines: true,
    keys: ['product_id', 'product_name', 'category_name', 'user_name', 'email_id', 'new_price', 'admin_approved']
  };
  allBuyers = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['Buyer id', 'Buyer Name', 'Buyer Email', 'Last Active', '	Total Purchase Value'],
    showTitle: true,
    title: 'All Buyers form',
    useBom: false,
    removeNewLines: true,
    keys: ['user_id', 'user_name', 'email_id', 'login_time', 'total_purchase']
  };
  sellerOrders = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['Date', 'Product Id ', 'Product Name', 'Product Quantity', 'WareHouse Name', 'Delivery Address', 'Payment Status', 'Order Status'],
    showTitle: true,
    title: 'All your order form',
    useBom: false,
    removeNewLines: true,
    keys: ['purchase_date', 'product_id', 'product_name', 'qty', 'warehouse_name', 'address_data', 'payment_status', 'order_status']
  };
  sellerProducts1 = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    headers: ['Product Id ', 'Product Name', '	Category', 'Approved on', 'Inventory', '	Available Units'],
    showTitle: true,
    title: 'All Your Products form',
    useBom: false,
    removeNewLines: true,
    keys: ['product_id', 'product_name', 'category_name', 'approved_at', 'warehouse_name', 'qty_left']
  };
  orderUpdate: any;
  orderStatus = false;
  orderStatusMsg: any;
  alrtcls: any;
  ordersData: any;
  productsData: any;
  status: any;
  orderid: any;
  prdid: any;
  allBuyerList: any;
  emptyOrderMsg: any;
  emptyOrder = false;
  allorders: any;
  pendingProducts: any;
  statusModal: string = "";
  add_category = "add_new_cat"
  allordercsv = true;
  allproductcsv = true;
  allbuyerscsv = true;
  allsellerscsv = true;
  allsellerorderscsv = true;
  allsellerproductcsv = true;
  showcat = false;
  str: any;
  constructor(public language: LanguageService,
    public mode: ModeServices,
    public config: APIs_Config,
    private request: Request,
    private service: ServicesService,
    private userStorage: LocalstorageService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private base64: Base64) {
    // this.pendingSellersRefresh();
    // this.pendingProductRefresh();
    // this.allOrdersrefresh();
    // this.allSellersRefresh();
    // this.allProductsrefresh();
    // this.allBuyersrefresh();
  }

  logsubmitted = false;
  ngOnInit() {
    this.sellerDeclineForm = this.formBuilder.group({
      sellerdeclineTextArea: [''],
      sellerdeclineoption: ['', Validators.required],
    })

    this.seller_FAQ = this.formBuilder.group({
      seller_ANS: [''],

    })

    this.editProductForm = this.formBuilder.group({
      product_name: new FormControl(''),
      product_description: new FormControl(''),
      quantity_left: new FormControl('')
    })

    this.productDeclineForm = this.formBuilder.group({
      productdeclineTextArea: [''],
      productdeclineoption: ['', Validators.required],
    })

    this.shippingForm = this.formBuilder.group({

      serviceId: ['', Validators.required],
      url: ['', Validators.required],
    });

    // this.stockForm = this.formBuilder.group({
    //   stock: new FormControl('')
    // })

    // this.stockForm.controls['stock'].setValidators([Validators.pattern()])
    // this.pendingSellersRefresh();

    let seller = this.userStorage.getUserInfo('is_seller');
    let userType = this.userStorage.getUserInfo('userType');
    console.log(seller, userType);
    this.allOrdersrefresh();

    this.service.sendmessage("my_add")
  }

  get urls() { return this.shippingForm.controls; }

  async pendingSellersRefresh() {
    let temp = await this.request.post(1, 'get_seller', this.config.cfg.get_seller.format)
    this.pending_sellers = [...temp[1].response.data]
    return this.pending_sellers;

  }

  pendingSellersPage() { }

  async pendingProductRefresh() {
    this.pendingProducts = await this.request.post(1, 'get_product_aplications', this.config.cfg.get_seller.format)
    console.log(this.pendingProducts);
    this.pendingProducts = this.pendingProducts[1].response.data;
    console.log(this.pendingProducts);
    if (this.pendingProducts.length <= 0) {
      this.emptyPendingProducts = true;
    } else {
      this.emptyPendingProducts = false;
    }
  }


  view_all_details: any;
  view_all_image: any = [];
  view_all_pro: any = [];
  view_all_varient: any = [];
  view_image: any = [];
  final_try: any = [];

  async getproductdetails(data) {
    this.view_all_pro = []
    this.view_image = []
    this.view_all_varient = []
    this.view_all_image = []
    this.view_all_details = []
    this.final_try = []
    console.log(data);
    let temp = this.request.get_structure("view_details_admin", "format");
    temp.product_id = data;
    temp = await this.request.post(1, "view_details_admin", temp);
    console.log(temp);
    this.view_all_details = temp[1].response.data
    console.log(this.view_all_details)
    var k = 1
    var pn = 1
    for (let item of this.view_all_details?.product_variants) {

      var item1 = item
      this.view_all_pro.push(item1)
      await console.log(this.view_all_pro, "this.view_all_pro")


    }
    for (let image of this.view_all_pro) {

      var item2 = image.images
      this.view_all_image.push(item2)
      await console.log(this.view_all_image)

      // for(let image2 of this.view_all_image){
      //   await  console.log(image2,"|||")
      //   // console.log(image2[0]?.image_url,image2[1]?.image_url,image2[2]?.image_url,"||||")
      //   var item3 = [image2[0]?.image_url,image2[1]?.image_url,image2[2]?.image_url]

      // }
    }
    for (let item of this.view_all_image) {
      var images = item
      // JSON.stringify(images)
      // console.log(images,"aaaaaaaa")
      this.view_image.push(images)
      console.log(item, "________")
    }
  }

  async update_seller(verify: boolean, id: number) {
    var map = this.config.cfg.update_seller.format
    map.application_id = id
    map.admin_action = verify
    this.response = await this.request.post(1, 'update_seller', map)

  }
  prd_id: any;
  action: any;
  async update_product(verify: boolean, id: number) {
    if (verify == true) {
      var map = this.config.cfg.update_product.format
      console.log(map)
      map.application_id = id
      map.admin_action = verify
      this.response = await this.request.post(1, 'update_product', map)
      console.log(this.response)
      if (this.response[0] == 200) {
        this.pendingProductRefresh();
        this.addProdSuccss = true;
        setTimeout(() => {
          this.addProdSuccss = false;
        }, 3000);
      }
    } else {
      this.prd_id = id
      this.action = false;
    }
  }

  async onProductDeclineForm() {
    if (this.productDeclineForm.value.productdeclineoption != '') {
      this.logsubmitted = true;
      console.log(this.logsubmitted, this.productDeclineForm.value)
      let temp = this.request.get_structure('product_deline', 'format');
      temp.username = this.pendingProducts[0].user_name;
      temp.product_name = this.pendingProducts[0].product_name;
      temp.reason = this.productDeclineForm.value.productdeclineoption;
      temp.comments = this.productDeclineForm.value.productdeclineTextArea;
      temp.email_id = this.pendingProducts[0].email_id;
      console.log(temp, 'decline_temp');
      temp = await this.request.post(1, 'product_deline', temp);
      console.log(temp);
      if (temp[0] == 200) {
        var map = this.config.cfg.update_product.format
        map.application_id = this.prd_id
        map.admin_action = this.action
        console.log(map)
        this.response = await this.request.post(1, 'update_product', map)
        document.getElementById('ProductdeclinePopup').click();
        this.pendingProductRefresh()
      }
    }
  }

  async allOrdersrefresh() {
    this.orders = await this.request.post(1, 'get_order', this.config.cfg.get_order.format)
    if (this.orders[0] == 200) {
      if (this.orders.length <= 0) {
        this.allordercsv = false;
      }
      this.orders = this.orders[1].response.data
      console.log("orders", this.orders);
      this.collectionSize = this.orders.length;
      let totalData = this.orders;
      this.allorders = totalData
        .map((country, i) => ({ id: i + 1, ...country }))
        .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    } else if (this.orders[0] == 400) {
      this.emptyOrder = true;
      this.emptyOrderMsg = "No Orders Yet"
    }
  }

  allOrdersPage() {
    this.collectionSize = this.orders.length;
    let totalData = this.orders;
    this.allorders = totalData
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  async allSellersRefresh() {
    this.sellers = await this.request.post(1, 'get_sellers', this.config.cfg.get_sellers.format)
    this.sellers = this.sellers[1].response.data
    if (this.sellers.length <= 0) {
      this.allsellerscsv = false;
    }
    console.log("sellers", this.sellers)

  }

  async allProductsrefresh() {
    this.all_products = await this.request.post(1, 'get_product', this.config.cfg.get_sellers.format)
    this.all_products = this.all_products[1].response.data['products']
    if (this.all_products.length <= 0) {
      this.allproductcsv = false;
    }
    console.log("products", this.all_products);
    this.collectionSize = this.all_products.length;
    this.allProductList = this.all_products.map((allProduct, i) => ({ id: i + 1, ...allProduct }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);


  }

  allProductPage() {
    console.log("called");
    this.collectionSize = this.all_products.length;
    this.allProductList = this.all_products.map((allProduct, i) => ({ id: i + 1, ...allProduct }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }


  async allBuyersrefresh() {
    this.buyers = await this.request.post(1, 'get_buyers', this.config.cfg.get_sellers.format)
    this.buyers = this.buyers[1].response.data
    if (this.buyers.length <= 0) {
      this.allbuyerscsv = false;
    }
    console.log("buyers", this.buyers)
    this.collectionSize = this.buyers.length;
    this.allBuyerList = this.buyers.map((allbuyer, i) => ({ id: i + 1, ...allbuyer }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  allBuyersPage() {
    this.collectionSize = this.buyers.length;
    let totalData = this.buyers;
    this.allBuyerList = totalData
      .map((allbuyer, i) => ({ id: i + 1, ...allbuyer }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }


  get f() { return this.sellerDeclineForm.controls; }
  get s() { return this.productDeclineForm.controls; }

  onSellerDecline() {
    this.logsubmitted = true;
    console.log(this.logsubmitted, this.sellerDeclineForm.value)
  }



  toggleSellerstatus() {
    this.theCheckbox = !this.theCheckbox
  }

  toggleproductStatus() {
    this.productStatus = !this.productStatus;
  }

  async sellerGetOrders() {
    let temp = this.request.get_structure('seller_get_order_data', 'format');
    this.sellerOrderData = await this.request.post(1, 'seller_get_order_data', temp);
    console.log(this.sellerOrderData);
    if (this.sellerOrderData[0] == 200) {
      if (this.sellerOrderData[1].response.data.length <= 0) {
        this.allsellerorderscsv = false;
      }
      this.ordersData = this.sellerOrderData[1].response.data;
      this.collectionSize = this.ordersData.length;
      this.sellerOrderData = this.ordersData.map((country, i) => ({ id: i + 1, ...country })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    }
    else {
      this.sellerOrderData = [];
    }
  }

  async sellerProducts() {
    let temp = this.request.get_structure('seller_products', 'format');
    temp.seller_id = 1;
    this.sellerProductsData = await this.request.post(1, 'seller_products', temp);
    console.log(this.sellerProductsData)
    if (this.sellerProductsData[0] == 200) {
      if (this.sellerProductsData[1].response.data.products.length <= 0) {
        this.allsellerproductcsv = false;
      }
      this.productsData = this.sellerProductsData[1].response.data.products;
      this.collectionSize = this.productsData.length;
      this.sellerProductsData = this.productsData.map((country, i) => ({ id: i + 1, ...country })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    }
    else {
      this.sellerProductsData = [];
    }
  }

  async updateStatus(status, orderid, prdid, content, order) {
    console.log(status, orderid, prdid);
    this.group = order;
    console.log(this.group)
    if (status === "shipped") {
      // let temp = this.request.get_structure('check_shipment', 'format');
      // temp.product_id_fs = prdid;
      // temp.order_id = orderid;
      // let response = await this.request.post(1, 'check_shipment', temp);
      // console.log(response);
      // if (response[0] == 200) {
      //   if (response[1].response.data.status == false) {
      this.shippingDetails = true;
      this.status = status;
      this.orderid = orderid;
      this.prdid = prdid;
      this.statusModal = "statusModal";
      this.open(content)
      console.log(this.statusModal);

      //   } else if (response[1].response.data.status == true) {

      //     return;
      //   }
      // } else if (response[0] == 400) {

      //   return
      // }
    } else {
      this.statusModal = "statusModal";
      console.log(this.statusModal);
      this.shippingDetails = false;
      this.status = status;
      this.orderid = orderid;
      this.prdid = prdid;
      this.open(content)


    }
  }

  async updateStatusMyOrder(data) {
    this.logsubmitted = true;
    if (this.shippingForm.invalid) {
      this.sellerGetOrders();
      return;
    } else {
      console.log(this.shippingForm.value);
      let temp = this.request.get_structure('seller_update_status', 'format');
      temp.order_id = this.orderid;
      temp.product_id_fs = this.prdid;
      temp.status = this.status;
      temp.sevice_name = this.logistic_partner;
      temp.sevice_id = this.shippingForm.value.serviceId;
      temp.track_url = this.shippingForm.value.url;
      console.log(temp);
      this.orderUpdate = await this.request.post(1, 'seller_update_status', temp);
      console.log(this.orderUpdate);
      if (this.orderUpdate[0] == 200) {
        this.logsubmitted = false;
        this.orderUpdate = this.orderUpdate[1].response.data;
        this.orderStatus = true;
        this.orderStatusMsg = this.language.mLparams[this.lang_dync].oupSuccess;
        this.alrtcls = "success";
        document.getElementById("confirmModalClose").click();
        this.modalService.dismissAll()
      }
      else {
        this.orderStatus = true;
        this.orderStatusMsg = this.language.mLparams[this.lang_dync].oupFailed;
        this.alrtcls = "danger";
        document.getElementById("confirmModalClose").click();
      }
      this.sellerGetOrders();
    }
  }

  async updatestatus() {
    let temp = this.request.get_structure('seller_update_status', 'format');
    temp.order_id = this.orderid;
    temp.product_id_fs = this.prdid;
    temp.status = this.status;
    console.log(temp);
    this.orderUpdate = await this.request.post(1, 'seller_update_status', temp);
    console.log(this.orderUpdate);
    if (this.orderUpdate[0] == 200) {
      document.getElementById("confirmModalClose").click();
    } else {
      // this.closeModal = ""
    }
    this.sellerGetOrders();
  }


  oupBtn() {
    this.orderStatus = false;
  }

  onClickManageWarehouse(value) {
    if (value == 'wareHouse') {
      this.showWareHouse = true;
    }
    else {
      this.showWareHouse = false;
    }
  }


  onClickManageCategory(value) {
    if (value == 'showcat') {
      this.showcat = true;
    }
    else {
      this.showcat = false;
    }
  }
  refreshOrders() {
    this.collectionSize = this.ordersData.length;
    let totalData = this.ordersData;
    this.sellerOrderData = totalData.map((country, i) => ({ id: i + 1, ...country })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

  }
  refreshProducts() {
    this.collectionSize = this.productsData.length;
    let totalData = this.productsData;
    this.sellerProductsData = totalData.map((country, i) => ({ id: i + 1, ...country })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

  }

  statusModalClose() {
    this.shippingForm = this.formBuilder.group({
      // serviceName: [''],
      serviceId: [''],
      url: [''],
    })
  }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false })
  }
  reset() {
    this.sellerGetOrders();
    this.logsubmitted = false;
    this.tracking_url = "";
    this.logistic_partner = "";
    this.shippingForm = this.formBuilder.group({
      // serviceName: ['', Validators.required],
      serviceId: [''],
      url: ['', Validators.required],
    });
  }

  tracking_url: any;
  logistic_partner: any
  group: any = []
  confirm_button = false;
  Select_Partner(partner) {

    console.log(partner)
    console.log(partner.target.value)
    var content = partner.target.value
    var res = content.split(",");
    console.log(res)
    this.tracking_url = res[0]
    this.logistic_partner = res[1]
    console.log(this.tracking_url)
    console.log(this.logistic_partner)
    if (this.tracking_url && this.logistic_partner) {
      this.confirm_button = true;
    } else {
      this.confirm_button = false;
    }
  }

  Travking_Url_update(event) {
    console.log(event)
  }

  no_faq = false
  async onClickqueries() {
    console.log("queries");
    let temp = this.request.get_structure('get_faq', 'format')
    console.log(temp);
    let data = await this.request.post(1, 'get_faq', temp);
    if( data[1]['response']['data'].length > 0){
      this.faq_data = data[1]['response']['data']
      this.no_faq = false;
    }else{
      this.no_faq = true;
    }
    
    
    console.log(this.faq_data);
  }

  answer: '';
  // seller_anqa(i){
  //   console.log(this.seller_FAQ.value.seller_ANS)
  //   if(this.seller_FAQ.value.seller_ANS.length >= 10){

  //     this.submit_qa[i] = false;
  //   }else
  //   {
  //     this.submit_qa[i] = true;
  //   }
  // }
  async submitAnswer(data) {

    // console.log(data)
    let temp = this.request.get_structure('submit_faq', 'format');

    temp.faq_id = data.faq_id;
    // temp.product_id=data.product_id;
    temp.body = data.answer;
    // temp.question=data.question;
    // temp.user_id=data.user_id;
    // temp.is_delete= false;

    console.log(temp);
    temp = await this.request.post(1, 'submit_faq', temp);
    console.log(temp);

    this.seller_FAQ = this.formBuilder.group({
      seller_ANS: [''],

    })
  }
  async delete_faq(data) {
    console.log(data);
    let temp = this.request.get_structure('delete_faq', 'format');
    temp.product_fs = data.product_id;
    temp.faq_id = data.faq_id;
    temp.is_delete = true;
    console.log(temp);
    temp = await this.request.post(1, 'delete_faq', temp);
    console.log(temp);
    if (temp[0] == 200) {
      this.onClickqueries();
    }
  }

  async productDelete(data, deletePrdModal, conformDeleteModal) {
    console.log(data);
    this.selectedPrdid = data;
    let temp = this.request.get_structure('product_delete', 'format');
    temp.product_id = data;
    temp = await this.request.post(1, 'product_delete', temp);
    console.log(temp);
    if (temp[1].response.data.cart_count > 0 && temp[1].response.data.purchase_data != []) {
      this.count = temp[1].response.data.cart_count;
      this.purchase_data = temp[1].response.data.purchase_data;
      this.qty = temp[1].response.data.stock_data[0].qty_left;
      this.modalService.open(deletePrdModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'mg', backdrop: 'static', keyboard: false, scrollable: true })
      console.log('called')
    } else {
      console.log('empty');
      this.modalService.open(conformDeleteModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'mg', backdrop: 'static', keyboard: false, scrollable: true })

    }
  }

  async forcedelete() {
    console.log('force delete called')
    let temp = this.request.get_structure('product_delete', 'format');
    temp.product_id = this.selectedPrdid;
    temp.is_delete = true;
    console.log(temp);
    temp = await this.request.post(1, 'product_delete', temp);
    console.log(temp);
    if (temp[1].response.data == true) {
      console.log('called');
      this.prdDeleteSuccess = true;
      this.allProductsrefresh();
      setTimeout(() => {
        this.prdDeleteSuccess = false;
        this.modalService.dismissAll();
      }, 2000);
    }
  }

  async editProduct(prod, editprodModal) {
    this.edit_image = []
    console.log(prod, "sfbvvh")
    this.category_name = prod.category_name;
    this.get_product_variant(prod)
    this.get_category_list()
    let temp = this.request.get_structure('single_page', 'format')
    temp.product_id_fs = prod.product_id;
    this.editProductId = prod.product_id;
    temp.variant_id = prod.variant_id;
    temp = await this.request.post(1, 'single_page', temp);
    console.log(temp, 'sdjbdfhihf');
    this.productDescription = temp[1]['response']['data']['products']['description'];
    this.productName = temp[1]['response']['data']['products']['product_name'];
    this.quantity_left = temp[1]['response']['data']['products']['qty_left'];
    this.editProductForm.controls['product_name'].setValue(this.productName);
    this.editProductForm.controls['product_description'].setValue(this.productDescription);
    this.editProductForm.controls['quantity_left'].setValue(this.quantity_left)
    this.modalService.open(editprodModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg', backdrop: 'static', keyboard: false, scrollable: true })
  }

  async onEditsave() {
    console.log(this.editProductForm.value)
    let temp = this.request.get_structure('product_edit', 'format');
    temp.product_id = this.editProductId;
    temp.product_name = this.editProductForm.value.product_name;
    temp.discription = this.editProductForm.value.product_description;
    temp = await this.request.post(1, 'product_edit', temp);
    console.log(temp);
    if (temp[0] == 200) {
      this.editSuccessMsg = true;
      this.sellerProducts();
      setTimeout(() => {
        this.modalService.dismissAll()
        this.editSuccessMsg = false;
      }, 2000);
    }
  }

  edit_image = []
  product_name = '';
  description_name = '';
  categ_id: any;
  varient: any;
  temper_data: any;


  async get_product_variant(prod) {
    this.temper_data = []
    this.temper_data = prod
    let temp = this.request.get_structure('product_variant_edit', 'format');
    temp.product_id = prod.product_id;
    console.log(temp, 'data');
    temp = await this.request.post(1, 'product_variant_edit', temp);
    console.log(temp, "ppppppppp");

    if (temp[0] == 200) {
      this.product_name = '';
      this.description_name = ''
      this.categ_id = ''
      this.product_name = temp[1].response.data.products[0].product_name;
      this.description_name = temp[1].response.data.products[0].description;
      this.categ_id = temp[1].response.data.products[0].category_id;
      for (var temp2 of temp[1].response.data.products[0].variants) {
        for (var i = 0; i < temp2.images.length; i++) {
          console.log(i, "ooooo")
          temp2.images[i]['old_image_url'] = temp2.images[i]['url']

        }
        this.edit_image.push(temp2)
      }
      // this.edit_image =  temp[1].response.data.products[0].variants
      console.log('************************************************************')
      console.log(this.edit_image)
      // for(let i of this.edit_image  )
      // this.varient = {
      //   index: var_number +1
      // }
      //     for(let _img of i.images ){
      //       console.log("LLLLLLLLLLLLLL",_img.image_url)

      //     }
    }

  }

  async get_category_list() {
    let temp = this.request.get_structure('category_list', 'format');
    temp = await this.request.post(0, 'category_list', temp);
    console.log(temp);
    if (temp[0] == 200) {
      this.cat_list = temp[1]['response']['data']
    }
  }

  cat_data(data) {
    console.log(data);
    this.category_name = data.category_name;
    this.category_id = data.category_id;
  }


  async update_cat() {
    let temp = this.request.get_structure('update_category', 'format');
    temp.product_id = this.editProductId;
    temp.category_id = this.category_id;
    console.log(temp);
    temp = await this.request.post(1, 'update_category', temp);
    console.log(temp);
    if (temp[0] == 200) {
      this.cat_update_success = true;
      this.sellerProducts();
      setTimeout(() => {
        this.cat_update_success = false;
      }, 2000);
    }
  }

  url1 = "https://livecon.remusnation.com/Leather Cardholder/1613119592903Leather card holder.jpg"
  productimage1: any
  url2: any
  productimage2: any
  url3: any
  productimage3: any
  var_img: any

  get_id(event, data) {
    console.log(event, data)
    var result = this.edit_image


  }
  img: any
  old_image: any
  image_url_from_server: any
  varient_id: any
  async choose2_0(id, img) {
    console.log(id, img)
    this.old_image = ''
    this.varient_id = ''
    this.varient_id = id
    this.old_image = img
  }
  img_spinner = false;
  async choose(event, unique_product_id, image_url) {
    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg") {
      this.img_spinner = true;
      console.log(image_url, 'Pasasasasas')
      this.img = []
      console.log(unique_product_id.unique_product_id)
      console.log(image_url.image_url)
      console.log(this.edit_image)
      for (var i = 0; i < this.edit_image.length; i++) {
        console.log(this.edit_image[i].unique_product_id)
        console.log(unique_product_id)


        if (this.edit_image[i].unique_product_id == this.varient_id) {
          for (var i2 = 0; i2 < this.edit_image[i].images.length; i2++) {
            if (this.edit_image[i].images[i2].url == this.old_image) {
              console.log(this.edit_image[i].images[i2], "lllllllllllasasas")
              // if (event.target.files) {
              //   var render = new FileReader();
              //   render.readAsDataURL(event.target.files[0])
              //   render.onload = (event: any) => {
              //     this.url1 = event.target.result;
              //   }
              //   this.productimage1 = event.target.files[0]
              // }
              console.log("imgggggg")
              this.img = await this.base64.uploadthefile(event)
              console.log(this.img, "imgggggg")
              var encoded = this.img.split(',');
              console.log("QQQQQQQQQQQQQQQQQQQQqqqqqqqqqqqqqqqqqq")
              var fileencoded = encoded[1];
              console.log(fileencoded + 'this.fileencoded')
              this.edit_image[i].images[i2].image_name = true;
              this.image_url_from_server = await this.get_image_url(fileencoded)
              console.log(this.image_url_from_server, "QQQQQQQqqqqqqqqqqqq")

              // let { format } = this.config.cfg.product_edit_fun;



              // format.file_name = 'edit_img';
              // format.image_name = 'side view';
              // format.product_name = this.product_name;
              // format.file_base64 = fileencoded;
              // format.is_main = false;



              // format["product_id"] = unique_product_id;
              // format['image_url'] = this.image_url_from_server;
              // format['old_image_url'] =this.old_image;
              // console.log(format ,'sending date from client')
              // let temp = await this.request.post(1, 'product_edit_fun', format);
              // console.log(temp[1].response.data.file_path,"oopppoo")

              if (this.image_url_from_server) {
                this.edit_image[i].images[i2].url = this.image_url_from_server


                console.log(this.edit_image[i].images[i2], "ppppp")

                this.edit_image[i].images[i2].image_name = false;

              }



              // console.log(this.edit_image, "url")



              // console.log(format + 'sending date from client')
              // let temp = await this.request.post(1, 'file_upload', format);
              // console.log( temp[1].response.data.file_path)
              // this.edit_image[i].images[i2].image_url = temp[1].response.data.file_path
              // console.log(this.edit_image, "url")


            }
          }
        }
      }

    } else {
      this.file_validatore = true;
      setTimeout(() => {
        this.file_validatore = false
      }, 3000);
    }
  }
  file_validatore = false
  async get_image_url(fileencoded) {
    try {
      let { format } = this.config.cfg.file_upload;
      format.file_name = 'D-Shop';
      format.image_name = 'side view123654';
      format.product_name = this.product_name;
      format.file_base64 = fileencoded;
      format.is_main = true
      console.log(format)
      let temp = await this.request.post(1, 'file_upload', format);
      console.log(temp)
      console.log(temp[1].response.data.file_path)
      return temp[1].response.data.file_path;
    } catch (e) {

    }
  }

  choose2(event) {
    console.log(event)
    if (event.target.files) {
      var render = new FileReader();
      render.readAsDataURL(event.target.files[0])
      render.onload = (event: any) => {
        this.url2 = event.target.result;
      }
      this.productimage2 = event.target.files[0]
    }
  }

  choose3(event) {
    console.log(event)
    if (event.target.files) {
      var render = new FileReader();
      render.readAsDataURL(event.target.files[0])
      render.onload = (event: any) => {
        this.url3 = event.target.result;
      }
      this.productimage3 = event.target.files[0]
    }
  }

  array_of_data: any
  uploaded_button = false;

  async image_edit(id) {
    this.uploaded_button = false;
    console.log(this.edit_image, id, "olddd")
    for (let i of this.edit_image) {
      if (i.unique_product_id == id) {
        for (let i2 of i?.images) {

          if (i2.url != i2.old_image_url) {
            console.log(i.unique_product_id)
            console.log(i2.url)
            console.log(i2.old_image_url)

            let { format } = this.config.cfg.product_edit_fun;
            format["product_id"] = i.unique_product_id;
            format['image_url'] = i2.url;
            format['old_image_url'] = i2.old_image_url;
            console.log(format, 'sending date from client')
            let temp = await this.request.post(1, 'product_edit_fun', format);
            console.log(temp)
            if (temp[0] == 200) {
              this.uploaded_button = true;
              i2.old_url = 'uploaded'
              console.log(this.edit_image)
              setTimeout(() => {

                this.edit_image = []
                this.get_product_variant(this.temper_data)
                this.uploaded_button = false;
              }, 2000);

            }

          }
        }
      }

    }


    // let { format } = this.config.cfg.add_multi_product;
    // format.proudcts.product_id_fs = null;
    // format.proudcts.product_id = this.config.site_id;
    // format.proudcts.category_id = this.categ_id;
    // format.proudcts.seller_id = null;
    // format.proudcts.product_group_id = null;
    // format.proudcts.product_name = this.product_name
    // format.proudcts.discription = this.description_name
    // format.proudcts.bullet_pts = null
    // format.proudcts.is_used = null
    // format.proudcts.weight = null
    // format.proudcts.admin_approved = null;
    // format.proudcts.delete = null;
    // format.proudcts.variants = this.edit_image;
    // console.log(format, "jjjjjjj")
    // let temp = await this.request.post(1, 'add_multi_product', format);
    // console.log(temp, "as")
  }

  second_cat2: any;
  second_cat: any;
  need_cat(i) {

    console.log(i)
    this.second_cat2 = '';
    this.second_cat = '';
    this.second_cat = i.array_to_json
    console.log(this.second_cat, "cccccccccccccccccccccccccccccccccc")
    //     if (this.second_cat != null){
    //       this.hidediv= true;
    //     }else if (this.second_cat == null){
    // this. hidediv =false
    //     }
  }


  need_cat2(i) {
    console.log(i)
    this.second_cat2 = '';
    this.second_cat2 = i.array_to_json
  }
  products_qty: any;

  async get_qty() {
    console.log(this.editProductId)
    let temp = this.request.get_structure('get_set_product_qty', 'format');
    temp.product_id = this.editProductId;
    temp.qty = 100022;
    temp = await this.request.post(1, 'get_set_product_qty', temp)
    console.log(temp);
    if (temp[0] == 200)
      this.products_qty = temp[1]['response']['data']['products']
  }


  count: any;
  unique_product_id = 0;
  onQtyupdate(i, event: any) {
    console.log(i);
    this.unique_product_id = i.unique_product_id;
    this.count = event.target.value;
  }
  stock_update_success = false;
  async update_qty() {
    if (this.unique_product_id != 0 && this.count >= 0) {
      let temp = this.request.get_structure('get_set_product_qty', 'format');
      temp.product_id = this.unique_product_id;
      temp.qty = parseInt(this.count);
      console.log(temp);
      temp = await this.request.post(1, 'get_set_product_qty', temp)
      console.log(temp, "updated_data");
      if (temp[0] == 200) {
        this.unique_product_id = 0;
        this.stock_update_success = true;
        setTimeout(() => {
          this.stock_update_success = false;
          this.sellerProducts();
        }, 3000);
      }
    } else {
      return;
    }
  }





  ngOnDestroy() {
    this.service.sendmessage('')
  }

}