import { Component, Input, OnInit, Output , EventEmitter} from '@angular/core';

@Component({
  selector: 'app-input-rows',
  templateUrl: './input-rows.component.html',
  styleUrls: ['./input-rows.component.scss']
})
export class InputRowsComponent implements OnInit {

  @Input()
  index;

  @Input()
  data;


  @Output()
  addEmit = new EventEmitter();

  @Output()
  delEmit = new EventEmitter();

  @Output()
  blurEmit = new EventEmitter();

  initValues:any;

  constructor() { 
    console.log("index", this.index)
    this.initValues={
      // isAddDisabled:true,
      // isDelDisabled:true,
      data:""
    }
  }

  ngOnInit(): void {
    console.log("index", this.index)
    // this.initValues.data=this.data;
  }

  onInputBlur(value){
    if(value){
      this.blurEmit.emit({data:value, index:this.index})
    }


  }

  handleAddRow(data){
    console.log(data);
    if(data){
      this.addEmit.emit({data, index:this.index})
      console.log(this.index, "index on Add")
    }
  }

  handleInputChange(data){
    if(data)
    {this.initValues.data=data;
      // this.changeEmit.emit({data:data,index:this.index})
    }
  //   this.initValues.isAddDisabled=false
  // this.initValues.isDelDisabled=false
  }

  handleDelRow(){
    
      this.delEmit.emit(this.index)

    

  }



}
