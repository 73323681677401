import { Injectable } from '@angular/core';
import { LocalstorageService } from '../sys_config/localstorage.service';

@Injectable({
    providedIn: 'root'
})
export class ShareResource {
    constructor(
        private userStorage: LocalstorageService
    ) { }
    temp1: any;
    temp2 = 10;
    all_cart_value: any;
    all_cart_count: any;
    buy_now_click = false;
    cart: any = {
        "all_cart_count": 0,
        "all_cart_value": 0,
    }

    addToCart(product, amount) {
        this.all_cart_value = amount;
        this.all_cart_count = product;
        this.getItems();
    }

    getItems() {
        this.cart.all_cart_count = this.all_cart_count;
        this.cart.all_cart_value = this.all_cart_value;
        return this.cart;
    }

    clearCart() {
        this.cart.all_cart_count = 0;
        this.cart.all_cart_value = 0;
        return this.cart;
    }
    set_buyNow() {

        this.buy_now_click = true;

        this.get_buyNow();
    }
    get_buyNow() {
        return this.buy_now_click;
    }

}
