import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../config/language/index';
import { LocalstorageService } from '../sys_config/localstorage.service';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  providers:[LanguageService],
})
export class LandingPageComponent implements OnInit {

  lang_dync = this.userStorage.current_language;
  constructor(
    private router: Router,
    public language : LanguageService,
    private userStorage: LocalstorageService
  ) { }

  ngOnInit(): void {
  }

  toDshop() {
    this.router.navigate(['/dshop']);
  }
  toDbay() {
    this.router.navigate(['/dbay']);
  }
  toDmarket() {
    this.router.navigate(['/dmarket']);
  }

}
