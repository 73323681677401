import { Component, OnInit } from '@angular/core';

 
export class CommonsComponent  {

  constructor() { }

 

  button_call(label:string, type:string, css_class :string, condition:any ){
    return `<button type="`+type+`" class="`+css_class+`" (click)="`+condition+`">`+label+`</button>`;
  }

}
