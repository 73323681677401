import { Injectable } from '@angular/core';

@Injectable()
export class Config {
    cfg = {
        host: {
            env: 'APP_HOST',
            type: 'string',
            //  default: '192.168.0.104',    
            // default: '127.0.0.1',
            // default: '192.168.1.133',
            // default: 'iqnetwork.ddns.net',
            // default: 'con.remusnation.com',
            default: 'livecon.remusnation.com',
            // default: 'ed9616a4e237.ngrok.io ',
            required: true
        },
        port: {
            env: 'APP_PORT',
            type: 'integer',
            // default: ':5000',
            // default: ':5110',
            // default: ':5003',
            default: '',
            required: true
        },
        protocol: {
            env: 'PROTOCOL',
            type: 'string',
            default: 'https',
            required: true
        },
        env_mode: {
            env: 'NODE_ENV',
            type: 'string',
            default: 'production',
            values: ['development', 'production', 'test'],
            required: true
        },
        baseUrlProd: {
            env: 'BASE_URL',
            type: 'string',
            default: 'http://localhost/remus-v1',
            required: true
        },
        buildSource: {
            env: 'BUILD_SOURCE',
            type: 'string',
            default: false,
            required: true
        },
        prdt_key: {
            prdt: "(kdr<Hb}[W^UMy<2*?>_#31{b/58[<",
        }
    }
}
