import { Component, OnInit } from '@angular/core';
import { LanguageService } from "../../config/language/index";
import { LocalstorageService } from '../../sys_config/localstorage.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-d-shop-footer',
  templateUrl: './d-shop-footer.component.html',
  styleUrls: ['./d-shop-footer.component.scss'],
  providers: [LanguageService]
})
export class DShopFooterComponent implements OnInit {

  lang_dync = this.userStorage.current_language;
  constructor(public language : LanguageService,private userStorage: LocalstorageService , private activeRoute: ActivatedRoute ,private router:Router) { }
  year: any;
  animal:any
  ngOnInit(): void {

    this.year =(new Date()).getFullYear();

    // setTimeout(() => {
    //   this.router.events.subscribe((event) => {
    //     //     console.log(this.activeRoute.pathFromRoot);
    //     console.log(this.activeRoute.snapshot.routeConfig.path);
    //     //     // console.log(this.activeRoute.snapshot.params);
    //     //     // console.log(this.activeRoute.snapshot.children);
    //    })
    // }, 1000);

  }
}
