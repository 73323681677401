<br>
<br>
<div *ngIf="!cat_pending">
<div class="container product" *ngIf="product">
  <div class="row" *ngIf="sub_cat_name != ''">
    <div class="col text-muted" style="font-size: small;">
      {{cat_name}} > {{sub_cat_name}}
    </div>
  </div>
    <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-12 spacing producthover" *ngFor="let cat of product | paginate: { id: 'foo',
        itemsPerPage: Items,
        currentPage: page,
        totalItems: totalrecode }">
                <div >
                  <!-- class="tags many" data-awards="" -->
            <div (click)="singlepage(cat)">
                <img src="{{server_url}}{{cat.image_url}}" class="img-fluid samesize catsize" alt="product">
                <div class="cat" placement="bottom" ngbTooltip="{{cat.product_name}}" tooltipClass="my-custom-class">{{cat.product_name}}</div>
                <p class="price">$ {{cat.new_price}}</p>
            </div>
            <ngb-rating [max]="5" [(rate)]="prd_star" [readonly]="true"></ngb-rating>
            <div (click)="singlepage(cat)" class="btn btn-primary pl-buynow">{{this.language.mLparams[lang_dync].vew}}</div>
            <br>

        </div>
        </div>

    </div>
    <!-- <pagination-controls id="foo" (pageChange)="page = $event" maxSize="8" directionLinks="true" autoHide="true" responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page">
    </pagination-controls> -->
    <!-- <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li class="page-item" *ngFor= 'let data of getpadination(product.length) ;index as i' ><a class="page-link"  (click)='updateindex(i)'>{{i+1}}</a></li>
          <li class="page-item">
            <a class="page-link" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav> -->


</div>
<div class="container">
  <div class="row no-gutters" >
    <pagination-controls  id="foo" (pageChange)="page = $event" maxSize="8" directionLinks="true" autoHide="true"
      responsive="true" previousLabel="Previous" nextLabel="Next" screenReaderPaginationLabel="Pagination"
      screenReaderPageLabel="page" screenReaderCurrentLabel="You're on page" >
    </pagination-controls>
    </div>
</div>
<div class="container mb-5" style="align-items: center;" *ngIf="emptyprdMsg">
    <div class="" style=" background-color: #F9F9F9; margin-top: -35px;">
        <!-- <h3 style="text-align: center; padding-top: 35px;">{{this.language.mLparams[lang_dync].noPrds}}</h3> -->
        <img src="assets/mlion.svg" style="display: block; margin-left: auto; margin-right: auto; margin-top: 30px; width: 30%; padding-top: 25px;"><br>
        <p style="color: #848484; font-size: 27px; text-align: center; padding-bottom: 25px;"> {{this.language.mLparams[lang_dync].replenshcat}}<br> {{this.language.mLparams[lang_dync].cntnueshpngcat}}</p>
        <h2 style="font-size: 37px; font-weight: 500; text-align: center; color: #4F4F4F; padding-bottom: 20px;">{{this.language.mLparams[lang_dync].tnq}}</h2>
        <!-- <div class="row"> <button style="align-items: center;" class="loginregbtn">Continue Shopping</button></div> -->
    </div>
    <div class="border border-light" style="margin-top: 33px;">
        <div class="text-center" style="margin-bottom: -23px;">
            <button type="button" class="btn loginregbtn" style="font-size: 20px;" [routerLink]="['/']">{{this.language.mLparams[lang_dync].cntueshpng}}</button>
        </div>
    </div>
</div>
<div *ngIf="emptycat" style="height: 400px;"></div>
</div>

<section *ngIf="cat_pending">
  <!--Big blue-->
  <div class="row" style="margin: 20%;">
      <div class="col-md-5 col-sm-2"></div>
      <strong class="text-primary" style="text-align: left; padding-top: 5px;">Exploring Products...</strong>
      
      <div class="col-md-2 col-sm-2">
          <div class="spinner-border text-primary" style="text-align: center;"></div>
      </div>
      <div class="col-md-5 col-sm-2"></div>
  </div>
</section>