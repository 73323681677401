<br>






<div class="container" *ngIf="!tablevalue">

    <app-categories *ngIf="conditions" (IdEmitter)="receiveAddress($event)" (NameEmitter)="receiveName($event)">
    </app-categories>
    <br>
    <!-- Product Title -->
    <div *ngIf="!conditions">
        <form [formGroup]="addProductDetails">
            <div class="form-group row justify-content-start">


                <div class="col-md-3">
                    <strong>
            <label for="productTitle">{{this.language.mLparams[this.lang_dync].categoryname}}</label></strong>
                </div>
                <div class="col-8">
                    <strong>{{categoryName}}</strong>
                </div>
                <br>
                <br>
                <div class="col-md-3">
                    <strong>
            <label for="productTitle">{{
              language.mLparams[lang_dync].addProduct.title
              }}</label></strong>
                </div>


                <div class="col-md-8 m-1 p-1">
                    <input type="text" class="form-control" id="productTitle" formControlName="productName" name="productTitle" (change)="handleTitleChange($event)" [value]="title" />
                    <div *ngIf="!Productname" class="text-danger">
                        {{ language.mLparams[lang_dync].Product_Title }}
                    </div>
                </div>

            </div>
            <!-- Product Description -->

            <div class="form-group row justify-content-start">
                <div class="col-md-3">
                    <strong>
            <label for="productDescription">{{
              language.mLparams[lang_dync].addProduct.description
              }}</label></strong>
                </div>

                <div class="col-md-8 m-1 p-1">
                    <textarea (change)="handleDescriptionChange($event)" class="form-control" formControlName="productDescription" id="productDescription" rows="5" [value]="description"></textarea>
                    <div *ngIf="!ProductDis" class="text-danger">
                        {{ language.mLparams[lang_dync].addProduct.product_descriptionReq }}
                    </div>
                </div>
            </div>

            <!-- Product Specs -->

            <div class="form-group row justify-content-start">
                <div class="col-md-3">
                    <strong>
            <label for="productSpecs">{{
              language.mLparams[lang_dync].addProduct.specifications
              }}</label>
              
            </strong>
                </div>

                <div class="col-md-8">
                    <app-input-rows *ngFor="let spec of specs; index as i" [index]="i" [data]="specsData[i]" (blurEmit)="handleBlur($event)" (addEmit)="handleAddRow($event)" (delEmit)="handleDelRow($event)"></app-input-rows>


                    <div *ngIf="!key[0]" class="text-danger">
                        {{ language.mLparams[lang_dync].addProduct.specifications_erro }}
                    </div>

                </div>
            </div>

            <!-- user or New -->

            <div class="form-group row justify-content-start" [ngClass]="showProductCondition()" style="display: none;">
                <div class="col-md-3">
                    <strong>{{
            language.mLparams[lang_dync].addProduct.itemCondition
            }}</strong>
                </div>

                <div class="col-md-8" [disabled]="disabled2">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="conditionRadio" id="usedCondition" [checked]="isUsed" (change)="handleIsUsed($event)" [disabled]="disabled2" />
                        <label class="form-check-label" for="usedCondition">
              {{ language.mLparams[lang_dync].addProduct.used }}
            </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="conditionRadio" id="conditionNew" [checked]="!isUsed" (change)="handleIsUsed($event)" [disabled]="disabled2" />
                        <label class="form-check-label" for="conditionNew">
              {{ language.mLparams[lang_dync].addProduct.new }}
            </label>
                    </div>
                </div>
            </div>

            <!----------product tag-------->
<!-- 
            <div class="form-group row justify-content-start ">
              <div class="col-md-3">
                <strong> Seting a Tag :</strong>
               
              </div>
              &nbsp;&nbsp;&nbsp;
              <div class="col-md-3"> 
                
                <div class="row">
                  <div class="col">
                    <div ngbDropdown class="d-inline-block">
                      <button class="btn btn-outline-primary" id="dropdownBasic1"   ngbDropdownToggle>{{tag_value}}</button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="set_a_tag($event)" id = "Exclucive">Exclucive</button>
                        <button ngbDropdownItem  (click)="set_a_tag($event)" id = "Limited edition">Limited edition</button>
                        <button ngbDropdownItem  (click)="set_a_tag($event)" id = "Best Sales">Best Sales</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- weight of the product -->

            <div class="form-group row justify-content-start ">
                <div class="col-md-3">
                    <strong>{{ language.mLparams[lang_dync].addProduct.weight }}</strong>
                </div>

                <div class="col-md-3">
                    <div class=" input-group mb-3">
                        <input class="form-control" type="number" placeholder="Weight" min="0" formControlName="Weight" aria-describedby="basic-addon1" />
                        <span class="input-group-text" id="basic-addon1">{{this.language.mLparams[this.lang_dync].kg}}</span>
                    </div>


                    <div *ngIf="!weight" class="text-danger ">
                        {{ language.mLparams[lang_dync].addProduct.weight1 }}
                    </div>
                </div>


            </div>


            <!-- Sets or Units -->
            <!-- 
    <div class="form-group row justify-content-start">
      <div class="col-md-3">
        <strong>{{ language.mLparams[lang_dync].addProduct.isSet }}</strong>
      </div>

      <div class="col-md-8">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="unitsOrSets" id="isSet" [checked]="isInSet"
            (change)="handleIsInSet($event)" />
          <label class="form-check-label" for="isSet">
            {{ language.mLparams[lang_dync].addProduct.set }}
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" name="unitsOrSets" id="units" [checked]="!isInSet"
            (change)="handleIsInSet($event)" />
          <label class="form-check-label" for="units">
            {{ language.mLparams[lang_dync].addProduct.unit }}
          </label>
        </div>
      </div>
    </div> -->

            <!-- Units Per Set -->

            <!-- <div class="form-group row justify-content-start" [ngClass]="displayUnitsPerSet()">
      <div class="col-md-3">
        <strong>{{ language.mLparams[lang_dync].addProduct.unitsPerSet }}</strong>
      </div>

      <div class="col-md-3">
        <input class="form-control" type="number" placeholder="" min="0" />
      </div>
    </div> -->

            <!-- Variants Info  - choose whether product has single variant or multiple variants-->

            <div class="form-group row justify-content-start">
                <div class="col-md-3">
                    <strong>{{
            language.mLparams[lang_dync].addProduct.variantsInfo
            }}</strong>
                </div>

                <div class="col-md-8">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="variantsInfo" id="hasVarnts" [checked]="hasVariants" (change)="handleHasVariants($event)" />
                        <label class="form-check-label" for="hasVarnts">
              {{ language.mLparams[lang_dync].addProduct.hasVariants }}
            </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="variantsInfo" id="noVarnts" [checked]="!hasVariants" (change)="handleHasVariants($event)" />
                        <label class="form-check-label" for="noVarnts">
              {{ language.mLparams[lang_dync].addProduct.noVariants }}
            </label>
                    </div>
                </div>
            </div>

            <!-- ------------------------------------------------------------------------------------- -->

            <!-- Show Variant Input Boxes with Button  ///Only Applicable when Variants are added-->
            <div class="form-group row justify-content-start" [ngClass]="showVariantInputBoxes()">
                <div class="col-md-3">
                    <strong>{{
            language.mLparams[lang_dync].addProduct.variantDetails
            }}</strong>
                </div>

                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-4 mb-2">
                            <input class="form-control" type="text" [placeholder]="
              language.mLparams[lang_dync].addProduct.variantName1PlcHoldr
            " (change)="handleVarDetlsChange($event)" [value]="variantDetails[0].name" id="variant1Name" />

                        </div>
                        <div class="col-md-4 mb-2">
                            <input class="form-control" type="text" [placeholder]="
              language.mLparams[lang_dync].addProduct.variant1ValPlcHoldr
            " (change)="handleVarDetlsChange($event)" [value]="variantDetails[0].values" id="variant1Vals" />

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4 mb-2">
                            <input class="form-control" type="text" [placeholder]="
              language.mLparams[lang_dync].addProduct.variantName2PlcHoldr
            " (change)="handleVarDetlsChange($event)" [value]="variantDetails[1].name" id="variant2Name" />
                            <p class="text-muted " style="font-size: 10px;">
                              {{ language.mLparams[lang_dync].addtypevarient}}</p>
                        </div>
                        <div class="col-md-4 mb-2">
                            <input class="form-control" type="text" [placeholder]="
              language.mLparams[lang_dync].addProduct.variant2ValPlcHoldr
            " (change)="handleVarDetlsChange($event)" [value]="variantDetails[1].values" id="variant2Vals" />
                            <p class="text-muted " style="font-size: 10px;">
                              {{ language.mLparams[lang_dync].useseprtvarient}} </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8">
                            <button class="btn btn-primary" (click)='tableList()' [disabled]="tableCreated">
                {{ language.mLparams[lang_dync].addProduct.createPrdctList }}
              </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="row">
      <div class="col-md-8">
        <div>
          <input type="file" (change)=file1($event)>
        </div>
         <div>
          <input type="file" (change)=file2($event)>
        </div>
        <div>
          <input type="file" (change)=file3($event)>
        </div> 
        <br>
        <input type="submit" (click)="sub()">
        <span *ngIf="span_three" class=" text-success">
          <svg _ngcontent-gwf-c108="" width="2rem" height="2rem" viewBox="0 0 16 16" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg" class="bi bi-check2-circle">
            <path _ngcontent-gwf-c108="" fill-rule="evenodd"
              d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z">
            </path>
            <path _ngcontent-gwf-c108="" fill-rule="evenodd"
              d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z">
            </path>
          </svg>
        </span>
      </div>
          
    </div> -->








            <br>
            <div [ngClass]="showVariantNotInputBoxes()">


                <!-------------------Add product Grouip------------->
                <div class="form-group row justify-content-start">
                    <div class="col-md-3">
                        <strong>{{ language.mLparams[lang_dync].addProduct.ProductGroup }} :</strong>

                    </div>

                    <div class="col-md-3">
                        <select class="custom-select" aria-label="Default select example" formControlName="ProductID">
              
              <option *ngFor="let Product of NoVarientProductGroup" value="{{Product.product_group_id}}">
                {{Product.product_group_name}}</option>
            </select>

                        <div *ngIf="!group" class="text-danger">
                            {{ language.mLparams[lang_dync].Product_group }}
                        </div>
                    </div>
                </div>

                <!-------Add Price and Stack -------->
                <div class="form-group row justify-content-start">
                    <div class="col-md-3">
                        <strong>{{ language.mLparams[lang_dync].addProduct.price }} :</strong>

                    </div>
                    <div class="col-md-2">
                        <input class="form-control" type="number" placeholder="{{language.mLparams[lang_dync].addProduct.price}} " formControlName="Price" min="0" />

                    </div>
                    <div class="col-md-2">
                        <input class="form-control" type="number" formControlName="DiscountValue" placeholder="Discount Price" min="0" />

                    </div>

                    <div class="col-md-2">
                        <select class="custom-select" aria-label="Default select example" formControlName="DiscountType" (change)="onStateSelectnoVariants($event)" title="Types">
                           <option value="none" selected >{{this.language.mLparams[this.lang_dync].none}}</option>
                           <option value="perc">{{this.language.mLparams[this.lang_dync].discountpercent}}</option>
                           <option value="flat">{{this.language.mLparams[this.lang_dync].flaterate}}</option>

                        </select>

                    </div>
                    <div class="col-md-3"></div>
                </div>

                <!----------Total Price ----------------->
                <div class="form-group row justify-content-start ">

                    <div class="col-md-3">
                        <strong> {{this.language.mLparams[this.lang_dync].totalprice}}</strong>

                    </div> &nbsp; &nbsp;
                    <div class="col-md-3">
                        <h3>{{Totalprice_of_Product}}</h3>
                        <div *ngIf="!TotalPricePro" class="text-danger">
                            {{ language.mLparams[lang_dync].Product_price }}
                        </div>

                    </div>
                </div>

                <!----------Add Stack-------->

                <div class="form-group row justify-content-start ">
                    <div class="col-md-3">
                        <strong>{{ language.mLparams[lang_dync].addProduct.AvailableStock}} :</strong>

                    </div>
                    <div class="col-md-3">
                        <input class="form-control" type="number" placeholder="{{language.mLparams[lang_dync].addProduct.AvailableStock}}" min="0" formControlName="AvailableStock" />
                        <div *ngIf="!Stock" class="text-danger">
                            {{ language.mLparams[lang_dync].Product_stack }}
                        </div>
                        <br>
                    </div>
                </div>


                <!-------Add image -------->
                <div class="form-group row justify-content-start ">
                    <div class="col-md-3">
                        <strong>{{ language.mLparams[lang_dync].addProduct.AddImage }}</strong>
                    </div>

                    <div class="col-md-3 ">
                        <div *ngIf="!image[0]" class="text-danger ">
                            {{ language.mLparams[lang_dync].addProduct.ImageReq }}
                        </div>
                        <div>



                            <div class="custom-file input-group">
                                <input type="file" class="custom-file-input" id="NovarImag1" aria-describedby="inputGroupFileAddon01" (change)='file1($event)'>
                                <label class="custom-file-label" for="NovarImag1">{{this.language.mLparams[this.lang_dync].setasmain}} <img *ngIf="span_three"  [src]="'assets/Image upload 2.svg'" width="28px" height="28px">
                                </label>
                                <span *ngIf="span_three" class=" text-success input-group-text">
                                  <svg _ngcontent-gwf-c108="" width="2rem" height="2rem" viewBox="0 0 16 16" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg" class="bi bi-check2-circle">
                                    <path _ngcontent-gwf-c108="" fill-rule="evenodd"
                                      d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z">
                                    </path>
                                    <path _ngcontent-gwf-c108="" fill-rule="evenodd"
                                      d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z">
                                    </path>
                                  </svg>
                                </span>
                            </div>

                            <!-- <label for="formFile" class="form-label">
              Set has Display Image *
              <input class="form-control" type="file" id="NovarImag1" (change)=file1($event)>
              <div *ngIf="!image" class="text-danger ">
                {{
                language.mLparams[lang_dync].addProduct.ImageReq
                }}
              </div>
              <span *ngIf="span_three" class=" text-success">
                <svg _ngcontent-gwf-c108="" width="2rem" height="2rem" viewBox="0 0 16 16" fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg" class="bi bi-check2-circle">
                  <path _ngcontent-gwf-c108="" fill-rule="evenodd"
                    d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z">
                  </path>
                  <path _ngcontent-gwf-c108="" fill-rule="evenodd"
                    d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z">
                  </path>
                </svg>
              </span>
            </label> -->

                        </div>





                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="NovarImag2" aria-describedby="inputGroupFileAddon01" (change)='file1($event)'>
                            <label class="custom-file-label" for="NovarImag2">{{this.language.mLparams[this.lang_dync].addimage}}<img  *ngIf="span_three2" [src]="'assets/Image upload 2.svg'"width="28px" height="28px"></label>
                        </div>

                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="NovarImag3" aria-describedby="inputGroupFileAddon01" (change)='file1($event)'>
                            <label class="custom-file-label" for="NovarImag3">{{this.language.mLparams[this.lang_dync].addimage}}<img *ngIf="span_three3"  [src]="'assets/Image upload 2.svg'"width="28px" height="28px"></label>
                        </div>



                        <br>
                        <br>
                        <!-- <div class="align-middle">
            <input class="btn btn-primary" type="submit" (click)="sub()" [disabled]="!submit">
          </div> -->
                    </div>
                </div>
                <div class="alert alert-danger" role="alert" *ngIf="addproduct">
                    {{this.language.mLparams[this.lang_dync].plsaddtxt}}
                </div>
                <div class="alert alert-danger" role="alert" *ngIf="file_validatore">
                    {{this.language.mLparams[this.lang_dync].file_validatore}}
                </div>
            </div>





















































            <!-- ---------------------------------------------------------------------------------- -->

            <!-- Collect Single Variant Product Details -->
            <!-- <div class="form-group row justify-content-start">
      <div class="col-md-3">
        <strong>{{ language.mLparams[lang_dync].addProduct.sku }}</strong>
      </div>

      <div class="col-md-3">
        <input class="form-control" type="text" [value]="singleVariantDtls.sku" id="sku" />
      </div>
    </div>

    <div class="form-group row justify-content-start">
      <div class="col-md-3">
        <strong>{{ language.mLparams[lang_dync].addProduct.price }}</strong>
      </div>

      <div class="col-md-3">
        <input class="form-control" type="text" id="addProdPrice" />
      </div>
    </div>

    <div class="form-group row justify-content-start">
      <div class="col-md-3">
        <strong>{{ language.mLparams[lang_dync].addProduct.sku }}</strong>
      </div> -->
            <!-- 
      <div class="col-md-3">
        <input class="form-control" type="text" id="sku" />
      </div> 
    </div>-->
            <br>

            <div class="alert alert-success" role="alert" *ngIf="valide_success_messge">
                {{language.mLparams[lang_dync].addProduct.Sucessfully_add}}
            </div>
            <div class="row" *ngIf='!confirm'>
                <div class="col-md-4"></div>

                <div class="col-md-4">
                    <button class="btn btn-primary" (click)="confirmProduct()" [disabled]="confirm_hide">
            {{ language.mLparams[lang_dync].confirm}}
          </button>
                </div>
                <div class="col-md-4"></div>
                <br>
                <br>
                <br>
                <br>
            </div>
        </form>
    </div>
</div>



<div *ngIf='tablevalue'>

    <div class="alert alert-danger" role="alert" *ngIf="addproduct">
        {{this.language.mLparams[this.lang_dync].plsaddtxt}}
    </div>
    <div class="card " style="border-color:#9E9D9D ;">

        <table class="table table-striped" [disabled]="spinnerneed">
            <thead>
                <tr>
                    <th scope="col" class="text-center">#</th>
                    <th scope="col" class="text-center">{{language.mLparams[lang_dync].addProduct.name}}</th>
                    <th scope="col" class="text-center">{{color}}</th>
                    <th scope="col" class="text-center">{{size}}</th>
                    <th scope="col" class="text-center">{{language.mLparams[lang_dync].notes}}</th>
                    <th scope="col" class="text-center">{{language.mLparams[lang_dync].addProduct.price}}</th>
                    <th scope="col" class="text-center">{{language.mLparams[lang_dync].addProduct.discount}}</th>
                    <th scope="col" class="text-center">{{language.mLparams[lang_dync].addProduct.type}}</th>
                    <th scope="col" class="text-center">{{language.mLparams[lang_dync].addProduct.DiscountedPrice}}</th>
                    <th scope="col" class="text-center">{{language.mLparams[lang_dync].addProduct.ProductGroup}}</th>
                    <th scope="col" class="text-center">{{language.mLparams[lang_dync].addProduct.AvailableStock}}</th>
                    <th scope="col" class="text-center">{{this.language.mLparams[this.lang_dync].image}}</th>
                    <th class="text-center"><button (click)='closeTable()' class="btn btn"><img (click)="delVariance(vari)"
                class="addPointer" [src]="'assets/cancel-black-18dp.svg'" width="30px" height="30px" /></button></th>

                </tr>

            </thead>
            <tbody>
                <tr *ngFor="let vari of variance; index as i" [index]="i">
                    <td scope="col" class="text-center">{{vari.index}}</td>
                    <td scope="col" class="text-center">{{vari.Name}}</td>
                    <td scope="col" class="text-center">{{vari.Type1}}</td>
                    <td scope="col" class="text-center">{{vari.Type2}}</td>
                    <td scope="col" class="text-center"><input class=" form-control" type="text" [(ngModel)]="vari.note"></td>
                    <td scope="col" class="text-center"><input class=" form-control" type="number" [(ngModel)]="vari.Price" placeholder="Price Value" min="0"></td>
                    <td scope="col" class="text-center"><input class=" form-control" type="number" [(ngModel)]="vari.Discount" placeholder="Discount Value" min="0"></td>
                    <td scope="col" class="text-center">
                        <!--   <div class="row">
                <div class="col">
                  <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{typeDiscount}}</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button ngbDropdownItem (click)="typesDis( true )" (click)="PriceDel(vari)" >Discount %</button>
                      <button ngbDropdownItem (click)="typesDis( false)" (click)="PriceDel(vari)" >Flat rate</button>
                    </div>
                  </div>
                </div>
              </div> -->

                        <select class="custom-select" aria-label="Default select example" (click)="onStateSelect(vari,$event)" style="width: 90px;">
            
              <option selected  value="none" >{{this.language.mLparams[this.lang_dync].none}}</option>
              <option value="perc">{{this.language.mLparams[this.lang_dync].discountpercent}}</option>
              <option value="flat">{{this.language.mLparams[this.lang_dync].flaterate}}</option>

            </select>
                    </td>
                    <td scope="col" class="text-center">{{vari.total}}</td>
                    <td>
                        <select class="custom-select" aria-label="Default select example" [(ngModel)]="vari.ProductGroup">
              <option selected><b class="text-danger">{{this.language.mLparams[this.lang_dync].select}}</b></option>
              <option *ngFor="let Product of productGroups" value="{{Product.product_group_id}}">
                {{Product.product_group_name}}</option>


            </select>
                        <!--  <div class="row">
                <div class="col">
                  <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle >Product List</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdownlist">
                      <button ngbDropdownItem  *ngFor="let Product of productGroups">{{Product.product_group_name}}</button>
                      

                    </div>
                  </div>
                </div>
              </div> -->

                    </td>


                    <td scope="col" class="text-center"><input class=" form-control" type="number" [(ngModel)]="vari.AvailableStock" (change)="AvailableStock(vari,$event)" min="0"></td>

                    <td scope="col">

                        <div class="custom-file ">

                            <input type="file" class="custom-file-input" id="ima1" aria-describedby="inputGroupFileAddon01" (change)="varient1(vari,$event)">
                            <label class="custom-file-label" for="ima1"><span class="hideme">{{this.language.mLparams[this.lang_dync].choosefile}} </span><img *ngIf='vari?.imagepath[0]' src="assets/Image upload 2.svg " style="width:28px; height:28px;"></label>

                        </div>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="ima2" aria-describedby="inputGroupFileAddon01" (change)="varient1(vari,$event)">
                            <label class="custom-file-label" for="ima2"><span class="hideme">{{this.language.mLparams[this.lang_dync].choosefile}} </span><img *ngIf='vari?.imagepath[1]' src="assets/Image upload 2.svg " style="width:28px; height:28px;"></label>
                        </div>
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" id="ima3" aria-describedby="inputGroupFileAddon01" (change)="varient1(vari,$event)">
                            <label class="custom-file-label" for="ima3"><span class="hideme">{{this.language.mLparams[this.lang_dync].choosefile}} </span><img *ngIf='vari?.imagepath[2]' src="assets/Image upload 2.svg " style="width:28px; height:28px;"></label>
                        </div>


                        <!-- <div>
                            <p class="font">Set has 1th Image *</p>
                            <label for="varient1" class="form-label input-group">
              
              <input class="custom-file-input"  type="file" id="ima1" (change)="varient1(vari,$event)">
              <img *ngIf='vari?.imagepath[0]' src="assets/Image upload 2.svg " class="input-group-img" style="width:36px; height:36px;" ></label>
                        </div>
                        <div class="input-group">
                            <input class="custom-file-input" id="ima2" type="file" (change)="varient1(vari,$event)">
                            <img *ngIf='vari?.imagepath[1]' src="assets/Image upload 2.svg" class="input-group-img" style="width:36px; height:36px;">
                        </div>
                        <div class="input-group">
                            <input class="custom-file-input" id="ima3" type="file" (change)="varient1(vari,$event)">
                            <img *ngIf='vari?.imagepath[2]' src="assets/Image upload 2.svg " class="input-group-img" style="width:36px; height:36px;">
                        </div> -->

                    </td>
                    <td scope="col" class="text-center"><img (click)="delVariance(vari)" class="addPointer" [src]="'assets/delete.svg'" width="20px" height="20px" /></td>
                </tr>

            </tbody>
        </table>
    </div>
    <br>
    <br>
    <div class="container">


        <div class="alert alert-danger" role="alert" *ngIf="valide_Price">
            {{language.mLparams[lang_dync].addProduct.Price_error}}
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="valide_image">
            {{language.mLparams[lang_dync].addProduct.Image_error}}
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="valide_stack">
            {{language.mLparams[lang_dync].addProduct.Stock_error}}
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="valide_product_group">
            {{language.mLparams[lang_dync].addProduct.Group_error}}
        </div>
        <div class="alert alert-danger" role="alert" *ngIf="valide_failed">
            {{language.mLparams[lang_dync].addProduct.valide_error}}
        </div>

        <div class="alert alert-success" role="alert" *ngIf="valide_success_messge">
            {{language.mLparams[lang_dync].addProduct.Sucessfully_add}}
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4" *ngIf='confirm'>

                <button class="btn btn-primary" (click)="confirmProduct2()" [disabled]="spinnerneed">
         <div class="spinner-border" role="status" *ngIf="spinnerneed" >
          <span class="sr-only">{{this.language.mLparams[this.lang_dync].loading}}</span>
        </div>
          {{ language.mLparams[lang_dync].confirm}}
        </button>
                <br>
                <br>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</div>

<!-- <div>
  <input [(ngModel)]="price"><br>
  <input [(ngModel)]="discount"><br>
  <input type="submit" (click)="getPrice()">
  <input [(ngModel)]="total">
</div> -->