import { Component, Input, OnInit } from '@angular/core';
import { Request } from '../../_service/request'
import { Config } from '../../sys_config/index';
import { LanguageService } from '../../config/language/index';
import { LocalstorageService } from '../../sys_config/localstorage.service';
import { Layouts } from '../../sys_config/index';
import { ServicesService } from 'src/app/_service/services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-categorylist',
  templateUrl: './categorylist.component.html',
  styleUrls: ['./categorylist.component.scss'],
  providers: [LanguageService, Layouts]
})
export class CategorylistComponent implements OnInit {

  constructor(
    private request: Request,
    private config: Config,
    public language: LanguageService,
    private userStorage: LocalstorageService,
    private layout:Layouts,
    private service:ServicesService,
    private routers:Router,

  ) { }

  @Input() categorylimit: number;
  category_type: any;
  catdata: any;
  gridlimit: any;

  lang_dync = this.userStorage.current_language;
  category = [1, 2, 3]
  // products =[1,2,3]
  category_title: string = "CONSUMABLES";
  server_url : any;


  async ngOnInit() {



    this.gridlimit = this.layout.limits.grids.category;
    var temp = this.request.get_structure('category_list', 'format');
    this.catdata = await this.request.post(0, 'category_list', temp);
    
    if (this.catdata[1].response.data) {
      // console.log(this.categorylimit);
      if (this.categorylimit) {
        if (this.catdata[0] == 200) {
          this.catdata[1].response.data = this.catdata[1].response.data.slice(0, this.categorylimit);
          this.server_url =  this.request.server_url;
        }
      }    
      else if (this.gridlimit) {
        if (this.catdata[0] == 200) {
          this.server_url =  this.request.server_url;
          this.catdata[1].response.data = this.catdata[1].response.data.slice(0, this.gridlimit);
        }
      }   
      this.category = this.catdata[1].response.data;
    }


  }

  category_ls(i){
    console.log(i)
    
    setTimeout(() => {
    window.location.reload();
    }, 1);
    
    this.service.recive_method(i.category_id)
    this.routers.navigate(['/category_product']);
    
    
    
    }


}
