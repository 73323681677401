<div class="container" *ngIf="!pending">
    <div class="col-md-2"></div>
    <div class="col-md-8 col-sm-12 contbg" style="margin-top: 1rem;">
        <div class="row m-1 p-1" *ngIf="!emptyCartMsg ">
            <div class="col-md-12 table-responsive">
                <table class="table responsive">
                    <thead>
                        <tr>
                            <th scope="col">{{this.language.mLparams[this.lang_dync].images}}</th>
                            <th scope="col">{{this.language.mLparams[this.lang_dync].productName}}</th>
                            <th scope="col">{{this.language.mLparams[this.lang_dync].price}}</th>
                            <th scope="col">{{this.language.mLparams[this.lang_dync].quantity}}</th>
                            <th scope="col">
                                {{this.language.mLparams[this.lang_dync].total}}({{this.language.mLparams[this.lang_dync].dollor}})
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- class="glow_product" -->
                        <tr *ngFor="let carts of cart; index as i">
                            <td>
                                <img src="{{serverUrl}}{{carts.image_url}}" class="mr-2" style="width:50px">
                            </td>
                            <td>
                                {{ carts.product_name}}
                            </td>
                            <td>{{ carts.new_price }}</td>
                            <td>
                                <div class="quantity">
                                    <div class="row">
                                        <div class="col-6 q-wb d-flex">
                                            <!-- <button class="dec" (click)="deleteProd(i)">-</button>
                                            <span class="mt-1">{{carts.qty}}</span>
                                            <button class="inc"  (click)="addProd(i)">+</button> -->
                                            <img src="assets/Remove.svg" alt="" class="" (click)="deleteProd(i)"
                                                width="25px" height="25px" style="margin: 5px; cursor: pointer;">
                                            <span class="m-1">{{carts.qty}}</span>
                                            <img src="assets/Add.svg" alt="" class="" (click)="addProd(i)" width="25px"
                                                height="25px" style="margin: 5px; cursor: pointer;">
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>{{ carts.row_total }}</td>
                            <td>
                                <img (click)="bulkDelete(carts)" class="addPointer" [src]="'assets/delete.svg'"
                                    width="50px" height="20px" style="cursor: pointer;" />
                                </td>
                          
                            <td>
                                <i  *ngIf="carts.warehouse_id == false" class="fa fa-exclamation-triangle" ngbTooltip="Not Deliverable"
                                    placement="bottom" tooltipClass="my-class"
                                    style="color: red; font-size: 25px; "></i>
                            </td> 
                            <!-- <td></td> -->
<!-- 
                            <td *ngIf="carts.warehouse_id == 57 && !product_avaiable  || !location">
                                <i class="fa fa-exclamation-triangle" ngbTooltip="Not Deliverable"
                                    placement="bottom" tooltipClass="my-class"
                                    style="color: red; font-size: 25px; "></i>
                            </td> -->

                            <!-- <td><img src="assets/delete.svg" width="50px" height="20px" /></td> -->
                            <!-- <td><button size="sm" (click)="bulkDelete(carts)">{{this.language.mLparams[this.lang_dync].remove}}</button></td> -->
                        </tr>

                        <tr>
                            <td colspan="3">
                                <!-- <b>Cart Total:{{totalCartValue}}</b> -->
                            </td>
                            <th scope="row" class="text-right txt_mode_2">
                                {{this.language.mLparams[this.lang_dync].cartTotal}}:
                            </th>
                            <th scope="row" class="txt_mode_2">
                                {{cartTotalInFloat()}}
                            </th>
                            <td></td>
                            <td></td>

                        </tr>
                    </tbody>
                </table>
                <!-- <div class="glow_product"> dsfgdfgsdfg</div> -->
            </div>
        </div>
        <div class="row m-1 p-1" *ngIf="emptyCartMsg">
            <div class="col-md-12">
                <p class="text-danger" style="margin:1%; font-size: 20px;">
                    {{this.language.mLparams[this.lang_dync].cartIsEmpty}}</p>
            </div>
        </div>

        <div class="alert alert-danger" *ngIf="checkmsgtxt" role="alert">
            <h4 class="alert-heading">We are SORRY!!</h4>
            <!-- <p>Some of your selected product's not deliverable to your selected location.</p> -->
            <p>Delivery for some of your selected products is not available to the selected Location. To continue your purchase either remove the  &nbsp;
            <!-- <hr> -->
            
            <!-- <p class="mb-0">Please change your location or remove the particular product to proceed to checkout.</p> -->
            <!-- ngbTooltip="  Deliverable" -->
            <i  class="fa fa-exclamation-triangle" 
            placement="bottom" tooltipClass="my-class"
            style="color: red; font-size: 25px; "></i> &nbsp;marked product from your cart or try changing your delivery location to proceed.</p>
          </div>


        <div class="row">
            <div class="col-md-12">
                <p class="text-muted ml-3 no-gutters" *ngIf="addrMsg1">
                    {{this.language.mLparams[this.lang_dync].addAddrDesc}}
                </p>
                <p class="text-muted ml-3" *ngIf="addrMsg2">{{this.language.mLparams[this.lang_dync].selectAddrDesc}}
                </p>

                <!-- <div  class="d-flex  flex-sm-column flex-md-row  flex-md-wrap flex-lg-wrap " *ngIf="showAddress">

                    <div class="d-flex flex-sm-column flex-sm-fill">
                    <div class="div d-flex flex-column addressCard m-1 p-1  " *ngFor="let f1 of address_temp" >
                        <div class="mb-auto">
                            <label style="padding: 0 5px 0 20px"    >
                                <address>
                                   
    
                                    <input type="radio" name="f1.address_id" [(ngModel)]="addressModeStatus"
                                    [value]="f1.address_id"
                                    (click)="handleAddrChange(f1.address_id)"><b  class="ml-1">{{language.mLparams[this.lang_dync].delv_add}}</b> <br><br>
                                   
                                   <p class="m-0 p-0"> <b>{{f1.address_name}}</b> </p>
                                   <p class="m-0 p-0 wrapTxtWithinDiv"> {{f1.address_line1}}</p>
                                   <p class="m-0 p-0 wrapTxtWithinDiv" *ngIf> {{f1.address_line2}}</p>
                                  
                                    {{f1.address_line2}}<br>
                                    {{f1.city}}<br>
                                    {{f1.region}}<br>
                                    {{f1.country}}<br>
                                    {{f1.postal_code}}<br>
                                
                                </address>
                                
                            </label>
                        </div>
                        <div>
                            <div class="radio_btn" style="margin-left: 18px;">
                                <button type="button" (click)="deleteAddress(f1.address_id)"
                                    class="btn btn-secondary">{{language.mLparams[this.lang_dync].delete}}</button>
                            </div>

                        </div>

                    </div> 
                </div>
                    <div  *ngIf="!ifAddressAdded" class=" m-1 p-1 align-self-center">
                    
                        <button class="btn cartplusbtn" data-toggle="tooltip" data-placement="top" title="{{language.mLparams[this.lang_dync].addDeliveryAddress}}" (click)="onAddAddress()"> <i
                                class="fa fa-plus buyplus"></i></button>
                    </div>

                </div> -->

                <div class="row no-gutters" *ngIf="showAddress">
                    <div class="col-md-9 col-sm-12 col-lg-5 addressCard m-1 p-1 wrapTxtWithinDiv"
                        *ngFor="let f1 of address_temp">
                        <label style="padding: 0 10px 0 20px">

                            <!-- <address> -->


                            <input type="radio" name="f1.address_id" class="mt-2" [(ngModel)]="addressModeStatus"
                                [value]="f1.address_id" (click)="handleAddrChange(f1.address_id, f1)">
                            <b class="ml-1 ">{{language.mLparams[this.lang_dync].delvAddrNote}}</b>

                            <br><br>

                            <p class="m-0 p-0"><b>{{f1.address_name}}</b></p>
                            <p class="m-0 p-0">{{f1.address_phone}}</p>
                            <p class="m-0 p-0"> {{f1.address_line1}}</p>
                            <p class="m-0 p-0" *ngIf="showAddrLineTwo(f1.address_line2)"> {{f1.address_line2}}</p>
                            <p class="m-0 p-0"> {{f1.city}}</p>
                            <p class="m-0 p-0"> {{f1.region}}</p>
                            <p class="m-0 p-0"> {{f1.country}}</p>
                            <p class="m-0 p-0"> {{f1.postal_code}}</p>
                            <br>
                            <br>


                            <!-- <b></b><br> -->
                            <!-- {{f1.address_line1}}<br>
                                {{f1.address_line2}}<br>
                                {{f1.city}}<br>
                                {{f1.region}}<br>
                                {{f1.country}}<br>
                                {{f1.postal_code}}<br>
                                <br>
                                <br> -->
                            <!-- </address> -->
                            <!-- <input type="radio" name="f1.address_id" [(ngModel)]="addressModeStatus"
                                [value]="f1.address_id"
                                (click)="handleAddrChange(f1.address_id)"> {{language.mLparams[this.lang_dync].delv_add}}  -->
                        </label>
                        <div class="radio_btn" style="margin-left: 18px;">
                            <button type="button" (click)="conformdeleteAddress(f1.address_id,conformdelete)"
                                class="btn btn-danger">{{language.mLparams[this.lang_dync].delete}}</button>
                        </div>



                    </div>
                    <div class="col-md-6">
                        <div class="container mt-5" style="padding: 3rem; margin-left: 3rem;" *ngIf="!ifAddressAdded">
                            <a id="#add_adress"><button class="btn cartplusbtn" data-toggle="tooltip"
                                    data-placement="top"
                                    title="{{language.mLparams[this.lang_dync].addDeliveryAddress}}"
                                    (click)="onAddAddress()"> <i class="fa fa-plus buyplus"></i></button></a>
                        </div>
                    </div>
                </div>
                <div class="row float-right no-gutters" *ngIf="ifAddressAdded">
                    <button type="button" class="close" (click)="onAddAddress()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="row" id="add_adress">
                    <div class="col-md-12 " *ngIf="ifAddressAdded">
                        <app-add-address (addrEmitter)="receiveAddress($event)" [showMobile]="true"
                            [formTitle]="this.language.mLparams[this.lang_dync].addNewShmtAddr">
                        </app-add-address>
                        <!-- <button type="button" class="btn btn-secondary" (click)="onAddAddress()">close</button> -->
                    </div>
                </div>
            </div>
        </div>
        <div [ngClass]="{'disableDiv': !addressModeStatus}">
            <div class="col-md-12 col-sm-12" *ngIf="!emptyCartMsg" style="margin-top: 5%;">
                <div class="form-check">
                    <p *ngIf="not_deliveriable" style="color: red;">Some of the product are not deliverable to your location, Please 
                    Delete the product from your cart</p>
                    <label class="form-check-label" for="ducPayment">
                        <input type="checkbox" class="form-check-input" id="ducPayment" [disabled]="is_notdelivarable" (change)="dandt($event)"
                            [checked]="is_dandt_checked"  data-toggle="modal"
                            data-target="#tand">
                        <b>
                            <!-- [disabled]="is_dandt_checked" -->
                            <p>{{this.language.mLparams[lang_dync].argmntmsg1}}</p>
                        </b>
                        <!-- <div class="container-fluid" *ngIf="checkmsgtxt">
                            <div class="row">
                        
                                <div class="col-md-10 col-md-offset-1">
                                    <div class="alert alert-warning alert-dismissible fade in text-center"  role="alert">
                                        <strong>Holy guacamole!</strong> Best check yo self, you're not looking too good.
                                    </div>
                                </div>
                              
                          </div>
                        </div> -->
                      

                    </label>
                    <b>
                        <p style="font-size: small;">{{this.language.mLparams[lang_dync].argmntmsg2}}<button
                                class="termcndclr" (click)="open(tandcpayment)" data-toggle="modal"
                                data-target="#tandcpayment">{{this.language.mLparams[lang_dync].argmntmsg4}}</button>{{this.language.mLparams[lang_dync].argmntmsg5}}
                        </p>
                    </b>

                </div>
            </div>


            <div class="row m-1 p-1">
                <div class="col-md-12 col-sm-12 text-right">
                    <!-- *ngIf="product_avaiable  || location" -->
                    <div>
                        <button [disabled]="is_proceedEnabled " type="button" class="btn loginregbtn" *ngIf="!pending"
                            (click)="proceedCheckOut()">{{this.language.mLparams[this.lang_dync].proceedTOCheckout}}</button>
                    </div>
                   
                </div>
            </div>
        </div>

    </div>

    <div class="col-md-2">

    </div>
</div>


<section *ngIf="pending">
    <!--Big blue-->
    <div class="row" style="margin: 20%;">
        <div class="col-md-5 col-sm-2"></div>
        <strong class="text-primary" style="text-align: left; padding-top: 5px;">Please Wait You Will Be Redirected
            Shortly...</strong>
        <div class="col-md-2 col-sm-2">

            <div class="spinner-border text-primary" style="text-align: center;"></div>
        </div>
        <div class="col-md-5 col-sm-2"></div>
    </div>
</section>

<div *ngIf="address_temp" style="margin-bottom: 300px;"></div>
<div *ngIf="!address_temp" style="margin-bottom: 200px;"></div>


<!-- conform delete modal -->

<ng-template #conformdelete let-modal>
    <div class="modal-header">
        <h4 class="modal-title" ngbAutofocus id="modal-basic-title">
            {{this.language.mLparams[this.lang_dync].dltaddress}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{this.language.mLparams[this.lang_dync].clkdltaddress}}</p>
        <h6 *ngIf="deletesuccess" class="text-success">{{this.language.mLparams[this.lang_dync].addrsdltsucss}}</h6>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger"
            (click)="deleteAddress()">{{this.language.mLparams[this.lang_dync].cnfrm}}</button>
    </div>
</ng-template>

<!-- conform delete modal end-->



<!---------------------------terms and condition for payent model start---------------------------->
<ng-template #tandcpayment let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" ngbAutofocus>Terms and Condition</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <form>
            <div>
                <b>
                    <p>{{this.language.mLparams[lang_dync].argmntmsg3}}</p>
                </b>
            </div>
        </form>
    </div>

</ng-template>
<!---------------------------terms and condition for payent model end---------------------------->





<!-- <ng-template #tand let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" ngbAutofocus>Terms and Condition</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <form>
            <div>
                <b>
                    <p>{{this.language.mLparams[lang_dync].argmntmsg3}}</p>
                </b>
            </div>
        </form>
    </div>

</ng-template> -->