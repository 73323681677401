<div class="row no-gutters">
    <div class="col-md-2 no-gutters "></div>
    <div class="col-md-8" align="center">
        <div class="  no-gutters mt-5">
            <div class="row no-gutters category">
                <div class="col-md-4 col-sm-2 no-gutters box" *ngFor="let cat of category">

                    <img src="{{cat.image_url_1}} " class="img" alt="{{cat.category_name}}">
                    <div class="strip">
                        <h4 class="sizeoem">{{cat.category_name}}</h4>
                    </div>
                    <div class=""><button class="btn btns primec"
                            size="sm">{{this.language.mLparams[this.lang_dync].shopnow}}</button></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-2"></div>
</div>

<br>
<div class="row no-gutters">
    <div class="col-md-2 no-gutters"></div>
    <div class="col-md-8" align="center">
        <div class="container no-gutters ">
            <div class="row no-gutters  ">
                <div class="col-md-3 p-1 col-sm-2 no-gutters" *ngFor="let cat of product_numbers">
                    <img src="{{server_url}}{{cat.image_url}}" class="img prdimg" alt="product">
                    <div class="cat">{{cat.product_name}}</div>
                    <b>${{cat.price}}</b>
                    <br />
                    <div class="btn btn-primary" (click)="buyNow(cat.id)">{{this.language.mLparams[this.lang_dync].buynow}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-2"></div>
</div>