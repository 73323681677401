import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/config/language';
import { Layouts, LocalstorageService } from '../../sys_config/index';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModeServices } from '../../config/modes/index';
@Component({
  selector: 'app-organised',
  templateUrl: './organised.component.html',
  styleUrls: ['./organised.component.scss'],
  providers:[Layouts,LanguageService,ModeServices]
})
export class OrganisedComponent implements OnInit {

  constructor(
    private layout :Layouts,
    private userStorage: LocalstorageService,
    public language: LanguageService,
    private router: Router,
    private modeService : ModeServices,
    
  ) { }
  public productlimit=3;
  public categorylimit=3;
  public prd_site_id=true;
  public cat_site_id=true;
  // productlimit:number =;
  lang_dync = this.userStorage.current_language;
  

  ngOnInit(): void {

    this.productlimit = this.layout.limits.landing.product;
    this.categorylimit = this.layout.limits.landing.category;
  }

    /** Single Product page redirect with data*/
    singlepage() {
      console.log(this.modeService.banner_prd_id.prd_fs);
      this.router.navigateByUrl("/product?pid="+this.modeService.banner_prd_id.prd_fs);
    }
    /** Single Product page redirect with data*/
}
