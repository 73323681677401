import { Component, OnInit } from '@angular/core';
import { FormGroup, Validator, FormBuilder, Validators } from '@angular/forms'
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  constructor(
    private formBuilder : FormBuilder,
  ) { }
  logsubmitted = false;
  getTrackingUrlform: FormGroup;
  ngOnInit(): void {
    this.getTrackingUrlform = this.formBuilder.group({
      trackingName: ['', [Validators.required]],
      trackingUrl: ['', [Validators.required]],
    })
  }

  get url() { return this.getTrackingUrlform.controls; }

  onEnteredTrackingUrl(){
    this.logsubmitted = true;
    if(this.getTrackingUrlform.invalid){
      return;
    }else{
      console.log(this.getTrackingUrlform.value);
    }
  }

}
