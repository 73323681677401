import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { LanguageService } from '../../config/language/index';
import { FormControl, FormGroup, Validator, Validators, FormBuilder } from '@angular/forms';
import { Request } from '../../_service/index';
import { APIs_Config, LocalstorageService } from '../../sys_config'
import { AddAddressFormData } from '../../models/addAddrFormData'
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { MustMatch } from '../../_helpers/must-match.validator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { data } from 'jquery';
import { ServicesService } from 'src/app/_service/services.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-buyerdashboard',
  templateUrl: './buyerdashboard.component.html',
  styleUrls: ['./buyerdashboard.component.scss'],
  providers: [LanguageService, DecimalPipe]
})
export class BuyerdashboardComponent implements OnInit , OnDestroy{


  constructor(
    private formBuilder: FormBuilder,
    public language: LanguageService,
    // private service :ServicesService,
    private request: Request,
    public config: APIs_Config,
    private router: Router,
    private userStorage: LocalstorageService,
    private modalService: NgbModal,
    private service:ServicesService
  ) {

  }
  profileId: any;
  gpInfo: any;
  firstName: any;
  lastName: any;
  email: any;
  username: any
  mobile: any;
  gender: any;
  serverUrl = this.request.server_url;
  personalInfo: FormGroup;
  addressInfo: FormGroup;
  edit_address: FormGroup;
  submitted = false;
  is_address = false;
  lang_dync = this.userStorage.current_language;
  btnClickedMale = true;
  btnClickedFemale = false;
  isReadonly = true;
  addReadonly = true;
  userpersonalinfo: any;
  addressList: any;
  emptyOrderMsg: any;
  emptyOrder = false;
  orderedProducts: any;
  AddressAddBtn = true;
  noAddress = false;
  page = this.userStorage.page;
  pageSize = this.userStorage.pageSize;
  collectionSize: any;
  countries: any;
  ordersData: any;
  profileUpdateSuccess = false;
  profileUpdateFailed = false;
  isSaveDisabled = true;
  singleOrderData: any;
  paymentorderData: any;
  bothPayment = false;
  payall = false;
  statusUpdate = false;
  delivery_address = [];
  Oldpassword: string;
  Newpassword: string;
  resetpassword: FormGroup;
  address_name: any;
  editSuccess = false;
  editFailed = false;
  selected_address:any;
  deletesuccess = false;
  active_key = 'active';
  active_key2 = '';
  is_bo_usr: any;
  ngOnInit(): void {

    this.service.finance_teachermessage$.subscribe(data=>{
      console.log(":::::::::::::::")
      if(data == 'paymentInitiated'){
        console.log(":::::::::::::::")
        
        this.active_key2 = 'active';
        this.active_key = '';
        console.log(this.active_key2 ,"active_key",  this.active_key ,":::::::::::::::")
      }else{
        console.log(":::::::::::::::.....")
        this.active_key = 'active';
        this.active_key2 = '';
      }
    })

    let bo = localStorage.getItem('isbousr')
    if(bo == 'true'){
      this.is_bo_usr = true
    }else if(bo == 'false'){
      this.is_bo_usr = false
    }
    this.personalInfo = this.formBuilder.group({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl(''),
      mobile: new FormControl(''),
      gender: new FormControl(''),

    });

    this.personalInfo.controls['firstName'].setValidators([Validators.required]);
    this.personalInfo.controls['lastName'].setValidators([Validators.required]);
    this.personalInfo.controls['mobile'].setValidators([Validators.required]);

    this.getProfileData();

    this.edit_address = this.formBuilder.group({
      address_name : [''],
    })


    this.resetpassword = this.formBuilder.group({
      oldepassword: ['', [Validators.required, Validators.pattern('^(?=.*[@$!%*?&])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$')]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*[@$!%*?&])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$')]],
      // ^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });


    this.service.sendmessage("my_dashbode")
    
    
  }


  get f() { return this.resetpassword.controls; }

  confirmme = false;
  confirmme_ = false;
  confirmme1 = false;
  confirmme2 = false;
  processed = true;


  onSubmit() {
    console.log("asas")
    console.log(this.resetpassword.value.oldepassword)
    console.log(this.resetpassword.value.password)
    console.log(this.resetpassword.value.confirmPassword)
  }


  Confirmme1() {

    if (this.resetpassword.invalid) {
      this.confirmme1 = true;
      // setTimeout(() => {
      //   this.confirmme1 = false;
      //  }, 5000);
      return;
    }
    else {
      this.confirmme1 = false;
    }
  }


  blurconfirm1() {

    if (this.resetpassword.valid) {

      return this.confirmme1 = false;
    } else {
      // this.confirmme1 = true;
      // setTimeout(() => {
      //   this.confirmme1 = false;
      // }, 5000);
    }

  }

  Confirmme2() {
    if (this.resetpassword.invalid) {
      this.confirmme2 = true;
      // setTimeout(() => {
      //   this.confirmme2 = false
      //  }, 5000);
      return;
    }
    else {
      this.confirmme2 = false
    }
  }

  blurconfirm2() {

    if (this.resetpassword.valid) {

      return this.confirmme2 = false;
    } else {
      // this.confirmme2 = true;
      // setTimeout(() => {
      //   this.confirmme2 = false;
      // }, 5000);
    }

  }


  Confirmme() {
    // this.confirmme = true;
    // console.log("asas")
    // setTimeout(() => {
    //   this.confirmme = false;
    // }, 3000);

    if (this.resetpassword.valid) {

      return this.confirmme = false;
    } else {
      // this.confirmme = true;
      // setTimeout(() => {
      //   this.confirmme = false;
      // }, 5000);
    }

  }

  keyupme() {

    if (this.resetpassword.value.password == this.resetpassword.value.confirmPassword) {
      console.log("correct")
      this.confirmme_ = false;
      this.processed = false;
    } else if (this.resetpassword.value.password != this.resetpassword.value.confirmPassword) {
      this.confirmme_ = true
      this.processed = true;
      // setTimeout(() => {
      //   this.confirmme_ = false;
      // }, 2000);
    }
  }


  showAddrLineTwo(addrLine2) {
    // console.log("show called")
    if (addrLine2.length === 0)
      return false
    else return true;

  }

  get pf() { return this.personalInfo.controls; }

  male() {
    console.log('male clicked')
    if (this.isReadonly) {
      this.btnClickedMale = true;
      this.btnClickedFemale = false;
      this.personalInfo.value.gender = 'M';
    }
  }

  female() {
    console.log('female clicked')
    if (this.isReadonly) {
      this.btnClickedMale = false;
      this.btnClickedFemale = true;
      this.personalInfo.value.gender = 'F';
    }
  }
  toggleReadonly() {
    this.isReadonly = !this.isReadonly;
    this.isSaveDisabled = false;
  }
  addressReadonly() {
    // this.addReadonly = !this.addReadonly;
    this.isReadonly = !this.isReadonly;
  }


  async addAddress(addr: AddAddressFormData) {
    console.log(addr);
    let temp = this.request.get_structure('address_add', 'format');
    temp.address_id = 0;
    temp.address_name = addr.name;
    temp.address1 = addr.addr1;
    temp.address2 = addr.addr2;
    temp.country = addr.country;
    temp.region = addr.state;
    temp.phone_no = parseInt(addr.mobile);
    temp.city = addr.city;
    temp.postal_code = addr.zip;
    console.log(temp);
    let address_add_info = await this.request.post(1, 'address_add', temp);
    console.log(address_add_info);
    this.getExistingAddr();
    this.onAddaddress();
  }

  onAddaddress() {
    this.AddressAddBtn = !this.AddressAddBtn;
    document.body.scrollTop = 250;
  }


  async getMyOrders() {
    var temp = this.request.get_structure('get_orders', 'format');
    // temp.product_id = 2;
    var getOrderData = await this.request.post(1, 'get_orders', temp);
    console.log(getOrderData);

    if (getOrderData[0] == 200) {
      if (getOrderData[1].response.data.length > 0) {
        this.ordersData = getOrderData[1].response.data;

        this.collectionSize = this.ordersData.length;
        this.orderedProducts = this.ordersData.map((country, i) => ({ id: i + 1, ...country })).slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
        // this.orderedProducts = getOrderData[1].response.data;

      } else {
        this.emptyOrder = true;
        this.emptyOrderMsg = "No Orders Yet"
      }
    }
    else if (getOrderData[0] == 400) {
      this.emptyOrder = true;
      this.emptyOrderMsg = "No Orders Yet"
    }
  }
  refreshOrders() {
    this.collectionSize = this.ordersData.length;
    let totalData = this.ordersData;
    this.orderedProducts = totalData
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

  }


  async getExistingAddr() {
    var temp = this.request.get_structure('address_info', 'format');
    // temp.product_id = 2;
    temp.order_status = "pending";
    var get_address = await this.request.post(1, 'address_info', temp);
    console.log(get_address);
    if (get_address[1].response.data.length >= 1) {
      this.addressList = get_address[1].response.data;
      this.noAddress = false;
      console.log(this.addressList);
    } else {
      this.addressList = []
      this.noAddress = true;
    }
  }

  async addressChange(value) {
    console.log(value);
    var temp = this.request.get_structure('address_default', 'format');
    // temp.product_id = 2;
    temp.address_id = value;
    var get_address = await this.request.post(1, 'address_default', temp);
    console.log(get_address);
  }


  conformdeleteAddress(address,conformdelete){
    this.modalService.open(conformdelete, { keyboard: false, backdrop: 'static' ,size: 'sm'})
    this.selected_address = address
  }


  async deleteAddress(conformdelete) {
    console.log(this.selected_address);
    var temp = this.request.get_structure('delete_address', 'format');
    // temp.product_id = 2;
    temp.address_name = this.selected_address.address_name;
    temp.address_id = this.selected_address.address_id;
    temp.address1 = this.selected_address.address_line1;
    temp.address2 = this.selected_address.address_line2;
    temp.country = this.selected_address.country;
    temp.region = this.selected_address.region;
    temp.city = this.selected_address.city;
    temp.phone_no = parseInt(this.selected_address.address_phone);
    temp.postal_code = this.selected_address.postal_code;
    temp.delete = true;
    console.log(temp);
    var get_address = await this.request.post(1, 'delete_address', temp);
    // console.log(temp);
    console.log(get_address);
    if(get_address[0] == 200){
      this.deletesuccess = true
      setTimeout(() => {
        this.deletesuccess = false;
        this.modalService.dismissAll(conformdelete)
      }, 2000);
    }
    this.getExistingAddr();
  }

  async getProfileData() {
    let temp = this.request.get_structure('get_profile_data', 'format');
    // temp.product_id = 2;
    let getProfile = await this.request.post(1, 'get_profile_data', temp);
    console.log(getProfile);



    // this.personalInfo.value.firstName;




    this.profileId = getProfile[1].response.data.profile_id;
    this.firstName = getProfile[1].response.data.first_name;
    this.lastName = getProfile[1].response.data.last_name;
    this.email = getProfile[1].response.data.email_id;
    this.username = getProfile[1].response.data.user_name;
    this.mobile = getProfile[1].response.data.phone_number;
    console.log(this.profileId);


    this.personalInfo.controls['firstName'].setValue(this.firstName);
    this.personalInfo.controls['lastName'].setValue(this.lastName)
    this.personalInfo.controls['mobile'].setValue(this.mobile)
  }

  genderCheck(event) {
    console.log("clicked");
    if (event.target.value == "male") {
      this.gender = "male"
    } else if (event.target.value == "female") {
      this.gender = "female"
    }
    console.log(this.gender);
  }

  /* 
  
  this.personalInfo.controls['firstName'].setValue(this.firstName);
      this.personalInfo.controls['lastName'].setValue(this.lastName)
      this.personalInfo.controls['mobile'].setValue(this.mobile)
  
  */
  async updateProfileData() {

    console.log(this.personalInfo.value.firstName, this.personalInfo.value['lastName'], this.personalInfo.value['mobile'])
    this.submitted = true;

    this.submitted = false;
    console.log("called", this.personalInfo.value.firstName, this.personalInfo.value.firstName);
    let temp = this.request.get_structure('update_profile_data', 'format');
    temp.profile_id = this.profileId;
    temp.first_name = this.personalInfo.value['firstName']
    temp.middle_name = "123";
    temp.last_name = this.personalInfo.value.lastName;
    temp.gender = "male";
    temp.date_of_birth = "2016-08-27";
    temp.phone_no = parseInt(this.personalInfo.value.mobile);
    console.log(temp);
    let updateProfile = await this.request.post(1, 'update_profile_data', temp);
    console.log(updateProfile);
    this.isReadonly = !this.isReadonly;
    if (updateProfile[0] == 200) {
      this.profileUpdateSuccess = true;
      this.isSaveDisabled = true;
      setTimeout(() => {
        this.profileUpdateSuccess = false;
      }, 5000);
    } else if (updateProfile[0] == 400) {
      this.profileUpdateFailed = true;
      this.isSaveDisabled = true;
      setTimeout(() => {
        this.profileUpdateFailed = false;
      }, 5000);
    }
    this.getProfileData();
    // }
  }
  partial_payment = false
  async getSingleOrderDetails(data) {
    console.log(data)
    let temp = this.request.get_structure('get_single_order', 'format');
    temp.invoice_id = data.purchase_id.toString();
    console.log(temp);
    let order = await this.request.post(1, 'get_single_order', temp);
    // console.log(JSON.stringify(order),":::::::::::::::::::::::")
    this.singleOrderData = order[1].response.data;
    
    this.partial_payment =  this.singleOrderData.partial_pay
    console.log(this.singleOrderData,"ooooo");
    this.delivery_address = [...this.singleOrderData.delivery_address.split('\\n')]
    // console.log(this.delivery_address)
    if (this.singleOrderData.payall == true) {
      this.singleOrderData.payments = []
    } else if (this.singleOrderData.shipment == false) {
      this.payall = true
    }
  }

  closeViewDetails(){
    this.payall = false;
  }


  shipmentPay(data) {
    console.log(data.purchase_id)
    document.getElementById('closeOrderdata').click();
    this.userStorage.updateUserInfo('tid', data.purchase_id);
    localStorage.setItem('pay_type', 'shipment')
    this.router.navigate(['/repayment']);
  }

  partial_pay(data) {
    console.log(data.purchase_id)
    console.log(data.payments[0].payment_flag)
    if(data.payments[0].payment_flag == "payment_2" || data.payments[0].payment_flag == "payment_3" ){
      document.getElementById('closeOrderdata').click();
      this.userStorage.updateUserInfo('tid', data.purchase_id);
      localStorage.setItem('pay_type', data.payments[0].payment_flag)
      this.router.navigate(['/repayment']);
    }
    
    
  }
  reference_id:any;

  repay1(data) {
    console.log(data.purchase_id);
    document.getElementById('closeOrderdata').click();
    this.userStorage.updateUserInfo('tid', data.purchase_id);
    localStorage.setItem('pay_type', 'payment')
    this.router.navigate(['/repayment']);
  }

  repay2(data) {
    console.log(data.purchase_id)
    document.getElementById('closeOrderdata').click();
    this.userStorage.updateUserInfo('tid', data.purchase_id);
    localStorage.setItem('pay_type', 'shipment')
    this.router.navigate(['/repayment']);
  }

  gotopayment(data) {
    console.log(data);
    this.userStorage.updateUserInfo('tid', data.purchase_id);
    this.userStorage.updateUserInfo('tc', data.total - data.shipment_cost);
    this.userStorage.updateUserInfo('sc', data.shipment_cost);
    setTimeout(() => {
      this.router.navigate(['/payment'])
    }, 500);
    document.getElementById('closeOrderdata').click()
  }

  bothRepay(payment) {
    console.log(payment);
    document.getElementById('closeOrderdata').click()
    this.userStorage.updateUserInfo('tid', payment.purchase_id);
    this.userStorage.updateUserInfo('tc', payment.amount_payed);
    this.userStorage.updateUserInfo('sc', payment.shipment_cost);
    if (this.singleOrderData.payall == true)
      this.router.navigate(['/payment'])
    else if (payment.payments[0].payment_flag == 'both')
      this.router.navigate(['/payment'])
    else if (payment.payments[0].payment_flag == 'payment') {
      localStorage.setItem('pay_type', 'payment')
      this.router.navigate(['/repayment'])
    }
    else if (payment.payments[0].payment_flag == 'shipment') {
      localStorage.setItem('pay_type', 'shipment')
      this.router.navigate(['/repayment'])
    }
  }

  async paymentStatus(data) {
    // console.log(data);
    let temp = this.request.get_structure('get_payment_status', 'format');
    temp.referenceId = data;
    let paymentDetails = await this.request.post(1, 'get_payment_status', temp);
    console.log(paymentDetails);
    if (paymentDetails[0] == 200) {
      this.statusUpdate = true;
      setTimeout(() => {
        this.statusUpdate = false;
      }, 2000);
      for (let payment of this.singleOrderData.payments) {
        if (payment.ref_id == data) {
          payment.status = paymentDetails[1].response.data.paymentDetails.status;
        }
      }
    }
  }
  elementType = 'url';
  wallet_id:any;
  crypto_Amount2:any;
  crypto_pay:any;
  copytext="Copy";
  clicktocopy() {
    this.copytext = "Copied";
    setTimeout(() => {
    this.copytext = "Copy";
    }, 15000)
  }
  async paymentStatus2(data,content_repay){
    let temp = this.request.get_structure('get_payment_status', 'format');
    temp.referenceId = data;
    this.reference_id = data;
    let paymentDetails = await this.request.post(1, 'get_payment_status', temp);
    console.log(paymentDetails[1].response.data.paymentDetails.wallet_address);
    this.wallet_id = paymentDetails[1].response.data.paymentDetails.wallet_address
    this.crypto_Amount2 = paymentDetails[1].response.data.paymentDetails.payment_amount
    this.crypto_pay = paymentDetails[1].response.data.paymentDetails.payment_gateway
    this.modalService.open(content_repay,{centered: true, size: 'sm' , backdrop: 'static', keyboard: false})


  }

  error_poup = false
  error_poup2 = false
  error_poup3 = false
  error_poup4 = false
  error_poup5 = false
  async resetme() {
    if (this.resetpassword.valid) {
      if (this.resetpassword.value.oldepassword == '') {
        this.error_poup2 = true;
        setTimeout(() => {
          this.error_poup2 = false;
        }, 4000);
      } else {
        if (this.resetpassword.value.oldepassword == this.resetpassword.value.password) {
          console.log("same")
          this.error_poup = true;
          setTimeout(() => {
            this.error_poup = false;
          }, 4000);
        } else if (this.resetpassword.value.oldepassword != this.resetpassword.value.password) {
          let { format } = this.config.cfg.reset_password;
          format.new_password = this.resetpassword.value.password
          format.old_password = this.resetpassword.value.oldepassword
          console.log(format);
          let temp = await this.request.post(1, 'reset_password', format);
          console.log(temp)
          if (temp[0] == 200) {
            this.error_poup3 = true
            setTimeout(() => {
              this.error_poup3 = false
            }, 4000);
            this.resetpassword = this.formBuilder.group({
              oldepassword: ['', [Validators.required, Validators.pattern('^(?=.*[@$!%*?&])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$')]],
              password: ['', [Validators.required, Validators.pattern('^(?=.*[@$!%*?&])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$')]],
              confirmPassword: ['', Validators.required],
            });
            this.confirmme1 = false
            this.confirmme2 = false
          } else if (temp[0] == 400) {
            this.error_poup4 = true
            setTimeout(() => {
              this.error_poup4 = false
            }, 4000);
          }
        }
      }


    } else {
      this.error_poup2 = true
      setTimeout(() => {
        this.error_poup2 = false
      }, 4000);
      return;
    }

  }
  // ngOnDestroy(){
  //   this.confirmme1 = false
  //   this.confirmme2 = false
  // }

  address_list: any;

  edit(editaddress, data) {
    // console.log(data)
    this.address_list = data
    this.edit_address = this.formBuilder.group({
      address_id: [this.address_list.address_id],
      address_name : [this.address_list.address_name],
      phone_no: [this.address_list.address_phone],
      address_line1: [this.address_list.address_name],
      address_line2: [this.address_list.address_line2],
      city: [this.address_list.city],
      region: [this.address_list.region],
      country: [this.address_list.country],
      postal_code: [this.address_list.postal_code],
    })
    this.modalService.open(editaddress, { keyboard: false, backdrop: 'static' })
  }

  async update_address(editaddress,data) {
    // console.log(data.value)
    let address = data.value;
    let temp = this.request.get_structure('address_edit','format');
    temp.address_name = address.address_name;
    temp.address_id = address.address_id;
    temp.address1 = address.address_line1;
    temp.address2 = address.address_line2;
    temp.country = address.country;
    temp.region = address.region;
    temp.city = address.city;
    temp.phone_no = parseInt(address.phone_no);
    temp.postal_code = address.postal_code;
    // console.log(temp);
    let edited_data = await this.request.post(1,'address_edit',temp);
    console.log(edited_data);
    if(edited_data[0] == 200){
      this.editSuccess = true;
      this.getExistingAddr();
      setTimeout(() => {
        this.editSuccess = false;
        this.modalService.dismissAll(editaddress)
      }, 3000);
    }else{
      this.editFailed = true;
      setTimeout(() => {
        this.editFailed = false;
      }, 2000);
    }
  }


  ngOnDestroy(){
    this.service.sendmessage("")
   
  }

}
