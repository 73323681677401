import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenubarComponent } from './menubar/menubar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { DummydashComponent } from './dummydash/dummydash.component';
import { CommonsComponent } from './layouts/inputs/inputs.component';
import { RegistrationComponent } from './others/registration/registration.component';
import { CategoryComponent } from './category/category.component';
import { SellerRegComponent } from './seller-reg/seller-reg.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SamplebodyComponent } from './layouts/samplebody/samplebody.component';

import { Request, Encryption } from './_service/index';
import { Config, APIs_Config } from './sys_config/index';
import { AdminpanelComponent } from './panels/adminpanel/adminpanel.component';
import { HeaderComponent } from './layouts/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PagenotfoundComponent } from './layouts/pagenotfound/pagenotfound.component'
import { Angular2CsvModule } from 'angular2-csv';
import { LocalstorageService } from '../app/sys_config/localstorage.service';
import { CartComponent } from './cart/cart/cart.component';
import { PaymentComponent } from './payment/payment/payment.component';
import { CategorylistComponent } from './pages/categorylist/categorylist.component';
import { ProductlistComponent } from './pages/productlist/productlist.component';
import { OrganisedComponent } from './pages/organised/organised.component';
import { BuyerdashboardComponent } from './panels/buyerdashboard/buyerdashboard.component';
import { AddAddressComponent } from './custom/add-address/add-address.component';
import { ShareResource } from './_service/interface';
import { PpPaymentComponent } from './layouts/pp-payment/pp-payment.component';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddprouctComponent } from './layouts/addProduct/addprouct/addprouct.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { SingleproductComponent } from './pages/singleproduct/singleproduct.component';
import { FormComponent } from './testform/form/form.component';
import { VerificationProcessComponent } from './others/verification-process/verification-process.component';
import { DirectbuyComponent } from './custom/directbuy/directbuy.component'

import { ManageWarehouseComponent } from './pages/manage-warehouse/manage-warehouse.component';
import { ModalComponent } from './custom/yes-or-no-modal/yes-or-no-modal.component';
import { LoginComponent } from './pages/login/login.component';
import { CategoryProductsListComponent } from './pages/category-products-list/category-products-list.component';
// import { ClipboardModule } from 'ngx-clipboard';
import { NgxPaginationModule } from 'ngx-pagination';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PaymentMessageComponent } from './layouts/payment-message/payment-message.component';
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { CookiePolicyComponent } from './layouts/cookie-policy/cookie-policy.component';
import { LegalComponent } from './layouts/legal/legal.component';
import { DeliveryPolicyComponent } from './layouts/delivery-policy/delivery-policy.component';
import { AllProductListComponent } from './custom/add-products/all-product-list/all-product-list.component';
import { RepaymentComponent } from './payment/repayment/repayment.component';
import { AddProductComponent } from './custom/add-products/add-product.component/add-product.component';
import { CategoriesComponent } from './custom/add-products/categories/categories.component';
import { InputRowsComponent } from './custom/input-rows/input-rows.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; 
// import { LocationStrategy,HashLocationStrategy } from '@angular/common';
import { DShopFooterComponent } from './layouts/d-shop-footer/d-shop-footer.component';
import { PartpaymentComponent } from './payment/partpayment/partpayment.component';
import { VoucherComponent } from './pages/voucher/voucher.component';
import { AutofocusDirective } from './autofocus.directive';

@NgModule({
  declarations: [
    AppComponent,
    MenubarComponent,
    ResetpasswordComponent,
    DummydashComponent,
    RegistrationComponent,
    CategoryComponent,
    SellerRegComponent,
    FooterComponent,
    SamplebodyComponent,
    AdminpanelComponent,
    HeaderComponent,
    PagenotfoundComponent,
    CartComponent,
    PaymentComponent,
    CategorylistComponent,
    ProductlistComponent,
    OrganisedComponent,
    BuyerdashboardComponent,
    AddAddressComponent,
    PpPaymentComponent,
    AddprouctComponent,
    SingleproductComponent,
    FormComponent,
    VerificationProcessComponent,
    DirectbuyComponent,
    ManageWarehouseComponent,
    ModalComponent,
    LoginComponent,
    CategoryProductsListComponent,
    LandingPageComponent,
    PaymentMessageComponent,
    MainDashboardComponent,
    CookiePolicyComponent,
    LegalComponent,
    DeliveryPolicyComponent,
    AddprouctComponent,
    CategoryComponent,
    AllProductListComponent,
    RepaymentComponent,
    AddProductComponent,
    CategoriesComponent,
    InputRowsComponent,
    DShopFooterComponent,
    PartpaymentComponent,
    VoucherComponent,
    AutofocusDirective,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    Angular2CsvModule,
  
    NgxQRCodeModule,
    NgxPaginationModule,
    ClipboardModule,
    NgIdleKeepaliveModule.forRoot(),
    // NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [CommonsComponent,
    Request,  
    Encryption,
    Config,
    APIs_Config,
    LocalstorageService,
    ShareResource,
    // {provide:LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }   


// test check