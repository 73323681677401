
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient) { }

  private productss = new BehaviorSubject([]);
  products = this.productss.asObservable();
  private categoriess = new BehaviorSubject([]);
  categories = this.categoriess.asObservable();
  private packagess = new BehaviorSubject([]);
  packages = this.packagess.asObservable();
  options: any;
  header_json: any;
  data = []


  async get_api(json: any, path: string) {
    return new Promise((resolve: Function, reject: Function) => {
      // const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

      var url = 'http://192.168.1.104:5004/' + path;
      this.header_json = {
        'Content-Type': 'application/json',
      };
      if (localStorage.getItem("can01") != null) {
        this.header_json.Authorization = localStorage.getItem("can01");
      }
      else if (localStorage.getItem("cantok") != null) { //for password reset
        this.header_json.Authorization = localStorage.getItem("cantok");
      }
      
      this.options = {
        headers: new HttpHeaders(this.header_json)
      };
      var response: any = [];

      this.http.post(url, json, this.options).subscribe((res) => {

        response = res;
        resolve(response)
      }, err => {
        response = err;
        resolve(response)

      });
    })
  }
  // async manage_store(json: any, path: string) {
  // return new Promise((resolve: Function, reject: Function) => {
  // const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  // var url = 'http://192.168.1.104:5002/'+path

  // var response: any = [];
  // this.http.post(url, json, options).subscribe(res => {
  // response = res;
  // this.productss.next(response.response.data.products)
  // this.categoriess.next(response.response.data.categories)
  // this.packagess.next(response.response.data.packages)
  // resolve(response)
  // });


  // })
  // }

}