import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-message',
  templateUrl: './payment-message.component.html',
  styleUrls: ['./payment-message.component.scss']
})
export class PaymentMessageComponent implements OnInit {

  constructor(
    private router : Router,
  ) { }
  type1: any;
  type2: any;
  ngOnInit(): void {
    this.type1 = localStorage.getItem('pay_type1');
    this.type2 = localStorage.getItem('pay_type2');
  }

  cleartype(){
    localStorage.removeItem('pay_type1')
    localStorage.removeItem('pay_type2')
    this.router.navigate(['/']);
  }
}
