<div [ngClass]="color">
    <button class="btn" style="display: none;" (click)="open(sessionLogout)" id="autologout"></button>

    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <ng-template #sessionLogout let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Session Timeout</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            Your session is expired {{idleState}}
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
        </div>
    </ng-template>
</div>