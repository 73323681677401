
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class Base64 {

    constructor() { }




  uploadthefile(event) {
    var selectedFile = <File>event.target.files[0];
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.onload = function () { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsDataURL(selectedFile);

    });
  }
}