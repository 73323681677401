
<!-- <section *ngIf='html' [innerHTML]='html' > 

</section> -->

<nav class="navbar navbar-expand-lg navbar-dark bg-primary rounded">
  <a class="navbar-brand" href="#">Navbar</a>
  <ul class="navbar-nav mr-auto">
    <li class="nav-item active">
      <a class="nav-link">Left Link 1</a>
    </li>
    <li class="nav-item">
      <a class="nav-link">Left Link 2</a>
    </li>
  </ul>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item">
      <button *ngIf="isUserLogged" type="button" class="btn btn-danger float-left" (click)="logout()">
        logout
      </button>
      <button *ngIf="!isUserLogged"  type="button" class="btn btn-primary" data-toggle="modal" data-target="#loginModal">
        Launch demo modal
      </button>
    </li>
  </ul>
</nav>

<!-- Modal -->
<div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
  data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <div *ngIf="islogin; else elseBlock">
          <h5 class="modal-title" id="exampleModalLabel">Login</h5>
        </div>
        <ng-template #elseBlock>
          <h5 class="modal-title" id="exampleModalLabel">
            Signup
          </h5>
        </ng-template>
        
        <button type="button" class="close close-btn" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container p-2">
          <div *ngIf="islogin">
            <!--  #loginForm="ngForm"  -->
            <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
              <div class="form-group">
                <div *ngIf="error_login">
                  <p class="alert alert-danger">{{ error_login }}</p>
                </div>
              </div>
              <div class="form-group">
                <label class="text-center">{{
                  this.language.mLparams[this.lang_dync].userName
                }}</label>
                <input type="text" formControlName="lemail" class="form-control loginFormInput"
                  [ngClass]="{ 'is-invalid': logsubmitted && lo.lemail.errors }" autofocus />
                <div *ngIf="logsubmitted && lo.lemail.errors" class="invalid-feedback">
                  <div *ngIf="lo.lemail.errors.required">
                    {{this.language.mLparams[lang_dync].emailreq}}
                  </div>
                  <div *ngIf="lo.lemail.errors.email || lo.lemail.errors.pattern">
                    {{this.language.mLparams[lang_dync].emailmustvalid}} 
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col">
                  <label>{{
                    this.language.mLparams[this.lang_dync].password
                  }}</label>
                  <input type="password" formControlName="lpassword" class="form-control loginFormInput" [ngClass]="{
                      'is-invalid': logsubmitted && lo.lpassword.errors
                    }" />
                  <div *ngIf="logsubmitted && lo.lpassword.errors" class="invalid-feedback">
                    <div *ngIf="lo.lpassword.errors.required">
                      {{ this.language.mLparams[this.lang_dync].pass_required }}
                    </div>
                    <div *ngIf="lo.lpassword.errors.minlength">
                      {{ this.language.mLparams[this.lang_dync].pass_length }}
                    </div>
                    <div *ngIf="lo.lpassword.errors.pattern">
                      {{
                        this.language.mLparams[this.lang_dync]
                          .password_structure
                      }}
                    </div>
                  </div>
                  <p class="text-muted display-5 text-center mt-2 mb-1 paragraph">Password must be at least 8 characters long, 1 special character,1 Uppercase, 1 lowercase and no empty spaces</p>
                </div>
              </div>

              <div class="text-center">
                <!-- the foll button needs to be commented out -->
                <!-- <button
                  type="button"
                  class="btn btn-primary float-left"
                  (click)="changesignup()"
                >
                  sign up
                </button> -->
                <!-- <button *ngIf="isUserLogged" type="button" class="btn btn-danger float-left" (click)="logout()">
                  logout
                </button> -->
                <button type="submit" class="btn btn-primary float-center pr-5 pl-5 mt-3">
                  Login
                </button>
                
              </div>
            </form>
            <div class="text-center">
                <span class="d-block mt-4">Not a member?
                  <a href="#" class="" (click)="changesignup()">Create an account</a></span>
                <span class="d-block mt-3">
                  <button class="btn" (click)="forgetPassword()" >Forgot Password</button>
                </span>
              </div>
           </div>

          <div *ngIf="isSignup">
            <div class="form-group">
              <div *ngIf="signup_success">
                <p class="alert alert-success">{{signup_success}}</p>
              </div>
              <div *ngIf="signup_fail">
                <p class="alert alert-danger">{{signup_fail}}</p>
              </div>
            </div>
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
              <div class="form-row">
                <div class="form-group col">
                  <label>{{
                    this.language.mLparams[this.lang_dync].userName
                  }}</label>
                  <input autofocus type="text" formControlName="firstName" class="form-control loginFormInput"
                    [ngClass]="{
                      'is-invalid': submitted && f.firstName.errors
                    }" />
                  <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">
                      First Name is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Email</label>
                <input type="text" formControlName="email" class="form-control loginFormInput"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                    Email must be a valid email address
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col">
                  <label>Password</label>
                  <input type="password" formControlName="password" class="form-control loginFormInput"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">
                      Password is required
                    </div>
                    <div *ngIf="f.password.errors.pattern">
                      {{
                        this.language.mLparams[this.lang_dync]
                          .password_structure
                      }}
                    </div>
                  </div>
                </div>
                <div class="form-group col">
                  <label>Confirm Password</label>
                  <input type="password" formControlName="confirmPassword" class="form-control loginFormInput"
                    [ngClass]="{
                      'is-invalid': submitted && f.confirmPassword.errors
                    }" />
                  <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">
                      Confirm Password is required
                    </div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">
                      Passwords must match
                    </div>
                  </div>
                </div>
                <p class="text-muted display-5 text-center mt-2 mb-1 paragraph">Password must be at least 8 characters long, 1 special character,1 Uppercase, 1 lowercase and no empty spaces</p>
              </div>
              <div class="form-group form-check">
                <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input"
                  [ngClass]="{
                    'is-invalid': submitted && f.acceptTerms.errors
                  }" />
                <label for="acceptTerms" class="form-check-label">Accept Terms & Conditions</label>
                <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
                  Accept T&C is required
                </div>
              </div>
              <div class="text-center">
                <!-- <button
                  type="button"
                  class="btn btn-primary float-left"
                  (click)="changelogin()"
                >
                  Login
                </button> -->
                <button type="submit" class="btn btn-primary float-center pr-5 pl-5">
                  Register
                </button>
                <span class="d-block mt-4">Already a member?
                  <a href="#" class="" (click)="changelogin()">Login</a></span>
                <!-- <button class="btn btn-secondary " type="reset" (click)="onReset()">Cancel</button> -->
              </div>
            </form>

            <!-- <button type="submit" class="btn btn-primary float-right" (click)="onSignupSubmit(signupForm.value)">Submit</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>