import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Request } from '../../_service/request'
import * as $ from 'jquery';
import { LocalstorageService, Layouts } from 'src/app/sys_config';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareResource } from 'src/app/_service/interface';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LanguageService } from '../../config/language/index';
import { stringify } from '@angular/compiler/src/util';
import * as CryptoJS from 'crypto-js';
import { Config } from '../../sys_config/config';
import { ServicesService } from 'src/app/_service/services.service';

@Component({
  selector: 'app-singleproduct',
  templateUrl: './singleproduct.component.html',
  styleUrls: ['./singleproduct.component.scss'],
  providers: [LanguageService, Layouts]

})
export class SingleproductComponent implements OnInit, OnDestroy {

  tab = 1;
  currentRate = 4;
  reviewRate = 0;

  setTab(num: number) {
    this.tab = num;
  }

  isSelected(num: number) {
    return this.tab === num;
  }

  constructor(
    private http: HttpClient,
    private service: ServicesService,
    private request: Request,
    private userStorage: LocalstorageService,
    private layout: Layouts,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private share: ShareResource,
    private formBuilder: FormBuilder,
    public language: LanguageService,
    private config: Config,


  ) {

  }
  notes = '';
  prd_star: any = 3;
  show = false;
  autohide = true;
  tag = 0;
  gridlimit: any;
  qanda: any;
  qandalimit: any;
  reviewdata: any;
  reviewlimit: any;
  out_of_stack = true;
  product_data: any;
  lang_dync = this.userStorage.current_language;
  server_url = this.request.server_url;
  actualInput: any;
  pid: any;
  keys_pair = [];
  description: any;
  counterValue = 1;
  images: any;
  prob_list: any;
  call_class: any;
  is_filtered: any;
  reponse_data: any;
  is_disable = true;
  is_available: any;
  is_available_ck: any = [];
  filterArray: any = [];
  _available = 'btc';
  _seleceted = 'buttonsuccess';
  _unavail = 'btn-warning';
  selected: any = [];
  faqForm: FormGroup;
  reviewForm: FormGroup;
  faqMsg: any;
  out_of_stack2 = true;
  faqsubmitted: any = false;
  is_login = false;
  data: any;
  atc_tag = true;
  related_products: any;
  secretKey = this.config.cfg.prdt_key.prdt;
  tag_rs_0 = 0;
  tag_rs_1 = 0;
  isreviewdisabled = true;
  logsubmitted = false;
  reviewSubmitSuccess = false;
  can_add_review = false;
  can_add_prod = false;
  get counter() {
    return this.counterValue;
  }

  set counter(value) {
    this.counterValue = value;
  }

  decrement() {
    if (this.counter < 2) { return; }
    this.counter--;
  }

  increment() {
    if (this.counter >= this.product.products.qty_left) { return; }
    this.counter++;
  }

  tabs = ["block", "none", "none"]
  buttons = ["active", "", ""]
  open_tab(idx: number) {
    let i: number
    for (i = 0; i < this.tabs.length; i++) {
      this.tabs[i] = "none"
    }
    for (i = 0; i < this.buttons.length; i++) {
      this.buttons[i] = ""
    }
    this.tabs[idx] = "block"
    this.buttons[idx] = "active"
  }


  ngOnInit() {

    setTimeout(() => {
      this.out_of_stack = false;
    }, 1000);

    $(document).ready(function () {

      //carousel-control

      $("#myCarousel").mouseover(function () {
        $("#myCarousel .carousel-control").show();
      });

      $("#myCarousel").mouseleave(function () {
        $("#myCarousel .carousel-control").hide();
      });

      //active thumbnail

      $("#thumbCarousel .thumb").on("click", function () {
        $(this).addClass("active");
        $(this).siblings().removeClass("active");

      });

      //carousel slides

      $('#myCarousel').on('slid.bs.carousel', function () {
        var index = $('.carousel-inner .item.active').index();

        var thumbnailActive = $('#thumbCarousel .thumb[data-slide-to="' + index + '"]');
        thumbnailActive.addClass('active');
        $(thumbnailActive).siblings().removeClass("active");

      });
    });


    this.faqForm = this.formBuilder.group({

      faqinput: ['', [
        Validators.required,
        Validators.minLength(6)]
      ],
    });

    this.reviewForm = this.formBuilder.group({
      textArea: ['', [Validators.required, Validators.maxLength(100)]]
    })

    this.is_login = this.userStorage.getUserInfo(this.userStorage.token);

    (async () => {
      await this.singleProduct('');
    })();
    this.gridlimit = this.layout.limits.related_products.product;
    this.reviewlimit = this.layout.limits.review;
    this.qandalimit = this.layout.limits.faq.qanda;


    // console.log(this.userStorage.getUserInfo('cart_count') ,"PPPPPPPPPPPPPPPPPPP")
    if (this.userStorage.getUserInfo('cart_count') > 0) {
      this.cart_count_more = true;
    } else {
      this.cart_count_more = false;
    }

  }

  uniq_pro:any
  get faq() { return this.faqForm.controls; }
  async singleProduct(var_id) {

    this.activeRoute.queryParams.subscribe(params => {
      this.pid = params['pid'] ? params['pid'] : '0';
    });


    if(this.userStorage.getUserInfo('token')){
      console.log("logedinnnnnnnnnnnnnnnn",)
      let temp = this.request.get_structure('single_page2', 'format');
      console.log(temp);
      temp.product_id_fs = parseInt(this.pid);
      temp.log_is = true;
      // let product_data:any;
      // console.log(var_id);
      if (var_id) {
        temp.variant_id = var_id;
      }
      var product_data = await this.request.post(0, 'single_page2', temp);
      console.log(product_data)
    }else {
      console.log("not logedinnnnnnnnnnnnnnnn")
      let temp = this.request.get_structure('single_page', 'format');
      console.log(temp);
      temp.product_id_fs = parseInt(this.pid);
      temp.log_is = true;
      // let product_data:any;
      // console.log(var_id);
      if (var_id) {
        temp.variant_id = var_id;
      }
      var product_data = await this.request.post(0, 'single_page', temp);
      console.log(product_data)
    }
    // console.log(product_data)
    if (product_data[1].response.data.related_products.length > 0) {
      this.uniq_pro = product_data[1].response.data.related_products
      this.related_products = product_data[1].response.data.related_products.slice(0, this.gridlimit);
    } else if (product_data[1].response.data.related_products.length == 0) {
      this.relatedProducts();
    }
    if (product_data[1].response.data.products) {
      this.notes = product_data[1].response.data.products.notes
    }

    if (product_data[1].response.data.products.qty_left <= 0) {
      this.out_of_stack2 = false
      this.notes = "";
    }
    if (product_data[1].response.data.products.qty_left >= 0) {
      this.counter = 1;
      this.is_disable = false;
      this.out_of_stack2 = true
      if (product_data[1].response.data.products.qty_left <= 0) {
        this.out_of_stack2 = false
  
      }
      if (!var_id) {
        if (Object.keys(product_data[1].response.data.variants).length > 1) {
          for (let f1 in product_data[1].response.data.variants) {
            var key = Object.keys(product_data[1].response.data.variants[f1])
            for (let f2 in Object.keys(product_data[1].response.data.variants[f1][key[1]])) {

              var map_tag1 = product_data[1].response.data.variants[f1][key[1]][f2]['variant_type']
              var map_tag2 = product_data[1].response.data.variants[f1][key[1]][f2]['value']
              if (map_tag1.length != 0 && map_tag2.length != 0) {
                this.tag = 1;
                // 
              } else {
                this.tag = 0;
              }

            }

          }
          this.is_disable = false;
        }
      }
      if (product_data[1].response.data.products.review_data !== null && product_data[1].response.data.products.review_data.length > 0) {
        this.reviewdata = product_data[1].response.data.products.review_data.slice(0, this.reviewlimit)
      }
      if (product_data[1].response.data.products.faq_data !== null && product_data[1].response.data.products.faq_data.length > 0) {
        this.qanda = product_data[1].response.data.products.faq_data.slice(0, this.qandalimit)
      }
      if (product_data[1].response.data.related_products.length > 0) {
        this.related_products = product_data[1].response.data.related_products.slice(0, this.gridlimit);
      } else if (product_data[1].response.data.related_products.length == 0) {
        this.relatedProducts();
      }
      this.can_add_review = product_data[1].response.data.products.can_add_review;
      this.can_add_prod = product_data[1].response.data.products.can_add_to_cart;
    }
    else {
      this.is_disable = false;
    }
    console.log(product_data[1], "product_data[1]")
    this.reponse_data = product_data[1];

    this.parseResponse(var_id);

  }

  async relatedProducts() {
    let temp = this.request.get_structure('related_products', 'format');
    console.log(temp);
    temp.product_id_fs = parseInt(this.pid);
    let related_prds = await this.request.post(0, 'related_products', temp);
    // console.log(related_prds);
    this.related_products = related_prds[1].response.data.products.slice(0, this.gridlimit);
  }

  staus_tag_load = 0
  product: any;
  product_variants: any;
  all_variants: any = {};
  selected_variant: any = {};
  custom_all_variants = {};
  all_variants_two: any = {};
  sub_cat: any;
  main_cat: any;
  parseResponse(var_id) {
    this.product_variants = this.reponse_data.response.data.variants;// this.actualInput.response.data.other_details;
    this.is_filtered = var_id;
    // delete this.actualInput.response.data.other_details;
    this.product = this.reponse_data.response.data;
    var category_name = this.product.products.main_category;
    if (this.product.products.main_category != this.product.products.sub_category) {
      this.sub_cat = this.product.products.sub_category;
      this.main_cat = this.product.products.main_category;
    } else {
      this.sub_cat = "";
    }
    this.service.set_cat_name(category_name)
    this.discount_condition();
    Object.assign(this.product, this.product_variants);
    for (const variant of this.product_variants) {
      for (const single_variant of variant.variants) {
        if (Object.keys(this.all_variants).includes(single_variant.variant_type)) {
          if (!this.all_variants[single_variant.variant_type].includes(single_variant.value)) {
            this.all_variants[single_variant.variant_type].push(single_variant.value);
          }
          // this.all_variants[single_variant.variant_type].push(single_variant.value);
        } else {
          Object.assign(this.all_variants, {
            [single_variant.variant_type]: [],
          });
          this.all_variants[single_variant.variant_type].push(single_variant.value);
        }
      }
    }
    Object.keys(this.all_variants).forEach((key) => {
      Object.assign(this.selected_variant, {
        [key]: this.all_variants[key][0],
      });
    });

    if (this.staus_tag_load == 0) {
      (async () => {
        await this.sync_flow();
      })();
      this.staus_tag_load = 1;
    }


  }

  async sync_flow() {
    // console.log('gg-->', JSON.stringify(this.all_variants), Object.keys(this.all_variants));
    var key = Object.keys(this.all_variants)
    for (var f1 in key) {
      (async () => {
        await this.updateVariant(key[f1], this.all_variants[key[f1]][0])
      })();

    }
  }

  checkFilter(key, value) {

    this._available = 'btc';
    this._seleceted = 'buttonsuccess';
    this._unavail = 'btn-warning';


    if (this.is_available_ck[key] == value) {
      return this._seleceted;
    }
    if (!this.is_available) {
      return this._available;
    }
    // if (this.is_available.includes(value)) {
    //   return this._available;
    // } 
    else {
      return this._available;
    }

  }

  async updateVariant(keypair, valuepair) {

    this.is_available_ck[keypair] = valuepair;
    this.selected_variant[keypair] = valuepair;
    let keys_only = Object.keys(this.selected_variant)


    this.keys_pair[keypair] = valuepair;
    var result = await this.calculation(this.keys_pair);
    var result_data = []
    result_data = Object.values(result);
    var resultc = [];
    resultc = result_data.shift().filter(function (v) {
      return result_data.every(function (a) {
        return a.indexOf(v) !== -1;
      });
    });

    if (resultc[0]) {

      this.singleProduct(resultc[0]);
    }
    else {
      this.counter = 0;
      // this.is_disable = true;
      this.product.products.qty_left = 0;
    }


  }

  async calculation(data) {

    let keys = Object.keys(data);
    let values = Object.values(data);
    let f1 = 0;
    let v1 = 0;
    let variant = this.reponse_data.response.data;
    let vlength = Object.keys(variant).length;
    let iv;
    var single = {};
    var name = '';
    for (var i = 0; i < keys.length; i++) {
      name = keys[i];
      if (!single[name]) {
        single[name] = [];
      }
    }
    for (v1 = 0; v1 < vlength; v1++) {
      for (f1 = 0; f1 < keys.length; f1++) {
        let ivlength = Object.keys(variant[f1].variants).length;
        for (iv = 0; iv < ivlength; iv++) {
          if (variant[v1] != null) {
            if (data[variant[v1].variants[iv].variant_type] == variant[v1].variants[iv].value) {
              if (!single[variant[v1].variants[iv].variant_type].includes(variant[v1].variant_id)) {
                single[variant[v1].variants[iv].variant_type].push(variant[v1].variant_id);
              }
            }
          }
        }
      }
    }
    return single;
  }
  cart_count_more = false;
  async buyNow(productId) {
    try {
      console.log('pass_11')
      localStorage.setItem('dbuy_tag', "false");
      let address_id = 0;
      var temp_address = this.request.get_structure('address_info', 'format');
      temp_address.product_id = 2;
      var is_logged = this.userStorage.getUserInfo(this.userStorage.token);
      console.log(this.counter, "counter");
      this.userStorage.updateUserInfo('buycart_value', this.product.products.new_price * this.counter);
      this.userStorage.updateUserInfo('buycart_count', this.counter);
      this.userStorage.updateUserInfo('pid', this.product.products.product_id);
      this.userStorage.updateUserInfo('upid', this.product.products.unique_product_id);
      localStorage.setItem('dbuy_tag', "true");
      var direct = {
        price: this.product.products.new_price,
        count: this.counter,
        upid: this.product.products.unique_product_id,
        pid: this.product.products.product_id,
      }
      console.log(this.product);
      console.log(direct);
      // localStorage.setItem('direct_data', JSON.stringify(direct));

      // if(this.config.cfg.env_mode.default == 'development'){
      // localStorage.setItem('direct_data', JSON.stringify(direct));
      // }else if(this.config.cfg.env_mode.default == 'production'){
      //   let encryptedData = CryptoJS.AES.encrypt(JSON.stringify(direct), this.secretKey).toString();
      localStorage.setItem('direct_data', JSON.stringify(direct));
    } catch (error) {
      console.log("temp_error");

    }
    // }


    if (is_logged) {
      console.log("pass_1")
      localStorage.setItem('dbuy_tag', "true");
      this.router.navigate(['/direct']);
    }
    else {
      console.log("pass_1")
      localStorage.setItem('dbuy_tag', "true");
      this.router.navigate(['/login']);
    }
  }

  location = true;
  addressList: any;

  async addToCart(productId) {

    var temp = this.request.get_structure('add_cart', 'format');
    temp.product_id_fs = this.product.products.product_id;;
    // temp.product_id = 2; 

    temp.qty = this.counter;
    temp.variant_id = this.product.products.variant_id;
    var is_logged = this.userStorage.getUserInfo(this.userStorage.token);
    if (is_logged) {



      this.location = false;
      var temp2 = this.request.get_structure('address_info', 'format');
      // temp.product_id = 2;
      temp2.order_status = "null";
      var get_address = await this.request.post(1, 'address_info', temp2);

      console.log(get_address);
      if (get_address[1].response.data.length > 0) {

        this.addressList = get_address[1].response.data;
        console.log(this.addressList, " ?????? ")
        for (let i of this.addressList) {
          console.log(i.is_main, "ppppp")

          if (i.is_main == true) {
            this.location = true;
            console.log("location is available")
          }
        }
        if (this.location != true) {
          console.log("location is not updated")
          this.service.Deliver_location_required("location is not updated")
        }
      } else {
        // document.getElementById('click_location').click();
        console.log("location is not updated")
        this.service.Deliver_location_required("location is not updated")
      }






      if (this.location == true) {

        this.is_login = true;
        console.log(temp, "////////")
        let addcart = await this.request.post(1, 'add_cart', temp);
        console.log(addcart, "////////")
        if (addcart[0] == 200) {
          let c_cnt = addcart[1].response.data.overall_product_count;
          let c_amt = addcart[1].response.data.overall_total;
          console.log(c_cnt, c_amt)
          if (c_cnt > 0) {
            this.cart_count_more = true;
          }
          this.service.cart_for_now(c_cnt)
          this.userStorage.updateUserInfo('cart_count', c_cnt);
          this.userStorage.updateUserInfo('cart_value', c_amt);
          this.share.addToCart(c_cnt, c_amt);
          this.tost();
        }
      } else {
        // $('#login_button').click();
        console.log('Not logged', temp);
        this.data = {
          pid_fs: temp.product_id_fs,
          v_id: temp.variant_id,
          qty: temp.qty,
        }
        localStorage.setItem('atc_tag', "false");
        localStorage.setItem('atc_price', JSON.stringify(this.data));
      }


    }
    else {
      $('#login_button').click();
      console.log('Not logged');
    }
  }

  tost() {
    console.log('called');
    this.show = true;
    setTimeout(() => {
      this.show = false;
    }, 2000);

  }

  changeState() {

    this.faqMsg = '';
  }

  async faqSubmit() {

    this.faqsubmitted = true;
    // stop here if form is invalid
    if (this.faqForm.invalid) {
      return;
    }
    else {
      var temp = this.request.get_structure('faq', 'format');
      temp.body = this.faqForm.value.faqinput;
      temp.product_fs = parseInt( this.product.products.product_id);
      console.log(temp);
      let addFaq = await this.request.post(1, 'faq', temp);
      console.log(addFaq);
      this.faqsubmitted = false;
      if (addFaq[0] == 200) {
        this.faqMsg = this.language.mLparams[this.lang_dync].faqsucc;
        this.faqForm = this.formBuilder.group({
          faqinput: [''],
        });
        setTimeout(() => {
          this.faqMsg = ""
        }, 5000);
      }
      else {
        this.faqMsg = this.language.mLparams[this.lang_dync].faqerr;
        this.faqForm = this.formBuilder.group({
          faqinput: [''],
        });
        setTimeout(() => {
          this.faqMsg = ""
        }, 5000);
      }
    }
  }

  singlepage(data) {
    console.log(data)
    this.router.navigateByUrl("/product?pid=" + data.product_id);
    setTimeout(() => {
      location.reload();
    }, 200);
  }

  get_condition = true;


  discount_condition() {
    if (this.product.products.new_price == this.product.products.price) {
      this.get_condition = false;
    } else {
      this.get_condition = true;
    }
  }


  getstar(data) {
    console.log(this.reviewRate);
    if (this.reviewRate == 0) {
      this.isreviewdisabled == true;
    } else (this.isreviewdisabled = false);
  }

  get url() { return this.reviewForm.controls; }

  async submitReview() {
    this.logsubmitted = true;
    let temp = this.request.get_structure('add_review', 'format');
    temp.body = this.reviewForm.value.textArea,
      temp.stars = this.reviewRate
    temp.product_id = parseInt(this.pid)
    console.log(temp)
    temp = await this.request.post(1, 'add_review', temp);
    console.log(temp);

    if (temp[0] == 200) {
      this.reviewSubmitSuccess = true;
      setTimeout(() => {
        this.reviewSubmitSuccess = false;
        window.location.reload();
      }, 3000);
    }
  }


  ngOnDestroy() {
    this.service.set_cat_name('Categories')
  }
}