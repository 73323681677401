import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModeServices } from 'src/app/config/modes';
import { LanguageService } from "../../config/language/index"
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonsComponent } from '../../layouts/inputs/inputs.component'
import { MustMatch } from '../../_helpers/must-match.validator';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Request } from '../../_service/request'
import { APIs_Config, Config } from '../../sys_config/';
import { Layouts, LocalstorageService } from '../../sys_config';
import { ShareResource } from '../../_service/interface'
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { textChangeRangeIsUnchanged } from 'typescript';
import { ServicesService } from 'src/app/_service/services.service';
import * as CryptoJS from 'crypto-js';
import { AddAddressFormData } from 'src/app/models/addAddrFormData';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [LanguageService, ModeServices, Layouts]
})
export class HeaderComponent implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  @ViewChild('loginButton') loginButton: ElementRef<HTMLElement>;
  constructor(
    private formBuilder: FormBuilder,
    public language: LanguageService,
    public mode: ModeServices,
    private comps: CommonsComponent,
    private sanitizer: DomSanitizer,
    private router: Router,
    private request: Request,
    private api_config: APIs_Config,
    private userStorage: LocalstorageService,
    private share: ShareResource, el: ElementRef,
    private service: ServicesService,
    private config: Config,
    private layout: Layouts,
  ) { }
  Product_id: any;
  nickname: any;
  searchResultCount = this.userStorage.searchCount;
  searchInput: String = '';
  searchResult;
  searchProducts: any;
  searchResults = [];
  type: any;
  name: any;
  model: any;
  logsubmitted = false;
  getducwalletform: FormGroup;
  lang_dync = this.userStorage.current_language;
  userType = this.userStorage.getUserInfo('userType');
  isSeller = this.userStorage.getUserInfo('is_seller');
  cart_product: number = this.share.all_cart_count;
  cart_price: any = this.share.all_cart_value;
  islogin = true;
  isSignup = false;
  signup_data: any;
  login_data: any;
  logindata: any;
  signupdata: any;
  jp: any;
  logoutdata: any;
  logoutload: any;
  loginForm: FormGroup;
  registerForm: FormGroup;
  submitted = false;
  error_login: any;
  category_data: any;
  button_check: any;
  html: SafeHtml;
  isUserLogged: boolean = false;
  jQuery: any;
  signup_success: any;
  activation_success: any;
  signup_fail: any;
  country: any;
  countryList: any;
  country_state: any;
  trig_buyNow: any;
  selectCountryList: any;
  myProfile = "";
  isAdmin = false;
  addressList: any;
  location: any;
  postal_code: any;
  bo_error:any
  address_id: any;
  buttonState = true;
  noAddress = false;
  boUser = false;
  boLogin = false;
  baseUrl = ''
  successMsg = false;
  AcceptAll = false;
  secretKey = this.config.cfg.prdt_key.prdt;
  buildSource = new Config().cfg.buildSource.default
  account_name = this.language.mLparams[this.lang_dync].backoffice;
  catName = 'Categories';
  catlimit: any;
  cart_data: any = {
    "all_cart_count": this.userStorage.getUserInfo(this.userStorage.cart_count) ? this.userStorage.getUserInfo(this.userStorage.cart_count) : 0,
    "all_cart_value": this.userStorage.getUserInfo(this.userStorage.cart_value) ? this.userStorage.getUserInfo(this.userStorage.cart_value) : 0,
  };
  selectLocations: FormGroup;
  ngOnInit(): void {
    console.log("pass__>> 1");
    // this.userStorage.updateUserInfo('cart_value', 0);
    // this.userStorage.updateUserInfo('cart_count', 0);

    if (localStorage.getItem('tag_load') == undefined) {
      localStorage.setItem('tag_load', 'false')
    }



    this.baseUrl = (this.buildSource) ? new Config().cfg.baseUrlProd.default : ''
    this.getducwalletform = this.formBuilder.group({
      getducaddr: ['', [Validators.required, Validators.pattern('^(?!.* )[a-zA-Z0-9\s]{34}')]],
    });

    this.registerForm = this.formBuilder.group({

      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,32}$')]],

      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9.]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.pattern('^(?=.*[@$!%*?&])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$')]],
      // ^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$
      confirmPassword: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
    this.selectLocations = this.formBuilder.group({

      cstate: [''],
      wcountry: [''],
    })
    this.loginForm = this.formBuilder.group({

      lemail: ['', [Validators.required]],
      lpassword: ['', [Validators.required]],
    });
    // console.log(this.userStorage.removeUserInfo('token'));
    if (this.userStorage.getUserInfo(this.userStorage.token)) {
      this.isUserLogged = true;

      // this.getExistingAddr();
    }

    this.catlimit = this.layout.limits.catlimit;

    this.button_check = this.comps.button_call('Test', 'button', 'btn btn-primary', 'onSubmit()');
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.button_check);

    this.getCategories();

    this.selectLocations = this.formBuilder.group({
      wcountry: [''],
    })
    this.share.addToCart(this.userStorage.cart_count, this.userStorage.cart_value);
    this.cart_data = this.share.getItems();
    this.trig_buyNow = this.share.get_buyNow();
    this.triggerloginClick();



    this.get_search_products();
    this.get_nickName();

    if (localStorage.getItem('clear_data_tag') == '1') {
      setTimeout(() => {
        window.location.reload();
        // this.ngOnInit();
      }, 100)
    }
    localStorage.setItem("clear_data_tag", '0');
    localStorage.setItem('atc_tag', "true");
    if (localStorage.getItem('d_tag') == 'true') {
      this.isAdmin = true
    } else if (localStorage.getItem('d_tag') == 'false') {
      this.isAdmin = false;
    }

    // if (localStorage.getItem('pay_type1') ) {
    //   localStorage.removeItem('pay_type1');
    // } 
    //  if (localStorage.getItem('pay_type2') ) {
    //   localStorage.removeItem('pay_type2');
    // }
    this.address_click();

    this.service.teachermessage$.subscribe(data => {
      if (data == "") {
        this.isprofile = ''
        this.iscart = ''
        this.isdashbode = ''
      } else if (data == "my_cart") {
        this.isprofile = ''
        this.isdashbode = ''
        this.iscart = data
      } else if (data == "my_dashbode") {
        this.isprofile = data
        this.iscart = ''
        this.isdashbode = ''
      } else if (data == "my_add") {
        this.isdashbode = data
        this.iscart = ''
        this.isprofile = ''
      }

    })

    this.service.english_teachermessage$.subscribe(data => {
      this.catName = data;
    })
    if (this.isUserLogged) {
      this.get_man_address();
    }

    this.service.deliver_manmessage$.subscribe(data => {
      if (data == 'location is not updated') {
        document.getElementById('click_location').click();
      }
    })

    this.service.your_locationmessage$.subscribe(data => {
      if (data) {
        this.location = data;
      }
    })
  }




  iscart: any
  isprofile: any
  isdashbode: any

  address_click() {
    console.log("qweeeeeeeee")
    if (this.userStorage.getUserInfo('location') == '') {

    } else if (this.userStorage.getUserInfo('location') != '') {
      this.location = this.userStorage.getUserInfo('location')
    }
  }

  loginClose() {


    this.loginForm = this.formBuilder.group({
      lemail: [''],
      lpassword: [''],
    }),
      this.registerForm = this.formBuilder.group({
        firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,32}$')]],

        email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9.]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        password: ['', [Validators.required, Validators.pattern('^(?=.*[@$!%*?&])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[a-zA-Z0-9]).{8,}$')]],
        confirmPassword: ['', Validators.required],
        acceptTerms: [false, Validators.requiredTrue]
      }, {
        validator: MustMatch('password', 'confirmPassword')

      }),
      this.submitted = false;
    this.islogin = true;
    this.isSignup = false;
    this.boUser = false;
    this.account_name = this.language.mLparams[this.lang_dync].backoffice;
    this.successMsg = false;
  }

  get_nickName() {
    if (this.isUserLogged) {
      console.log("called");
      this.nickname = localStorage.getItem('nickname');
    }

  }

  setUserType(type, seller) {
    if (type == '4' && seller == true) {
      this.myProfile = 'seller';
    }
    else if (type == '4' && seller == false) {
      this.myProfile = 'buyer';
    }
    else if (type == 1) {
      this.myProfile = 'admin';
    }
    if (this.config.cfg.env_mode.default == 'production') {
      var encrypteddata = CryptoJS.AES.encrypt(JSON.stringify(this.myProfile), this.secretKey).toString();
      console.log(encrypteddata)
      localStorage.setItem('type', JSON.stringify(encrypteddata));
      let userdata = localStorage.getItem('type');
      var parsed_data = JSON.parse(userdata);
      let bytes = CryptoJS.AES.decrypt(parsed_data, this.secretKey)
      var obj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      console.log(obj);

    } else if (this.config.cfg.env_mode.default == 'development') {
      localStorage.setItem('type', this.myProfile);
    }
    // this.myProfiles();
  }

  myProfiles() {
    this.myProfile = localStorage.getItem('type')
    return this.myProfile;
  }

  async checkUserName() {
    var temp = this.request.get_structure('_username', 'format');
    temp.username = this.registerForm.value.firstName;
    var check_username = await this.request.post(0, '_username', temp);
    this.signup_fail = temp.username ? '' : this.signup_fail;
    if (check_username[0] == 400) {
      this.signup_fail = this.language.mLparams[this.lang_dync][check_username[1].error.response.error];
    }
  }

  async checkUserMail() {

    var temp = this.request.get_structure('_email', 'format');
    temp.email = this.registerForm.value.email;
    this.signup_fail = temp.email ? '' : this.signup_fail;

    if (this.registerForm.value.email.valid) {
      var check_useremail = await this.request.post(0, '_email', temp);

      if (check_useremail[0] == 400) {
        this.signup_fail = this.language.mLparams[this.lang_dync][check_useremail[1].error.response.error];
      }
    }
  }
  tc_checkBox(event) {
    this.AcceptAll = true
    this.buttonState = true;
    console.log(event);
    if (event.target.checked) {
      this.submitted = true;

      if (this.registerForm.invalid) {
        this.buttonState = true;
        return;
      }
      else {
        this.buttonState = false;
      }

    }
  }
  reg_change() {
    if (this.registerForm.invalid) {
      this.AcceptAll = false
      this.buttonState = true;
      return;
    }
    else {
      this.AcceptAll = true;
      this.buttonState = false;
    }
  }
  triggerloginClick() {

    if (this.trig_buyNow != false) {
      let el: HTMLElement = this.loginButton.nativeElement;
      console.log('here');
      console.log(this.loginButton.nativeElement);
      console.log('here');
      el.click();
    }
    else {
      // console.log(' not here');
    }
  }
  triggerFalseClick() {
    let el: HTMLElement = this.myDiv.nativeElement;
    el.click();
    this.loginForm.reset();
  }


  get duc() { return this.getducwalletform.controls; }
  get f() { return this.registerForm.controls; }
  get lo() { return this.loginForm.controls; }

  onEnteredDucWalletaddr() {
    this.logsubmitted = true;
    console.log(this.getducwalletform.value.getducaddr);
    if (this.getducwalletform.invalid) {
      return;
    }
    else {
      console.log("true")
    }
  }

  onCloseBecomeSeller() {
    this.logsubmitted = false;
    this.getducwalletform = this.formBuilder.group({
      getducaddr: ['', [Validators.required]],
    })
  }
  async onLoginSubmit() {
    this.logsubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    else {
      this.login_data = {
        "payload": {
          "username": this.loginForm.value.lemail,
          "password": this.loginForm.value.lpassword,
          "product_id": 2
        }
      }
      // this.logindata = await this.logservice.get_api(this.login_data, 'bov3001/98310');
      if (this.boLogin == true) {
        var temp = this.request.get_structure('bo_login', 'format');
        temp.username = this.loginForm.value.lemail;
        temp.password = this.loginForm.value.lpassword;
        this.logindata = await this.request.post(1, 'bo_login', temp);
        localStorage.setItem('isbousr', 'true');
        if (this.logindata[0] == 400) {
          if (this.logindata[1].response.error == "invalid_credentials") {
            console.log('invalid_credentials')
            this.error_login = this.language.mLparams[this.lang_dync].invalid_credentials;
          }
          
        
          else if(this.logindata[1].response.error == "server_under_maintenance"){

              console.log(this.logindata[1].response.error)

              this.error_login = this.language.mLparams[this.lang_dync].server_under_maintenance;
            }
     
          else {

            this.error_login = this.language.mLparams[this.lang_dync].invalid_credentials;
          }

          setTimeout(() => this.error_login = false, 5000);
        }
      } else if (this.boLogin == false) {
        localStorage.setItem('isbousr', 'false')
        var temp = this.request.get_structure('login_2', 'format');
        temp.username = this.loginForm.value.lemail;
        temp.password = this.loginForm.value.lpassword;
        this.logindata = await this.request.post(1, 'login_2', temp);
      }

      if (this.logindata[0] == 400) {
        if (this.logindata[1].response.error == "wrong_combination") {
          this.error_login = this.language.mLparams[this.lang_dync].invalid_credentials;
         }
         else if(this.logindata[1].response.error == "email_not_verified"){
          console.log("email_not_verifi")
          this.error_login = this.language.mLparams[this.lang_dync].email_not_verified;
        }
        // else {
        //   this.error_login = this.language.mLparams[this.lang_dync].invalid_credentials;
        // }

        setTimeout(() => this.error_login = false, 5000);
      }

      else if (this.logindata[0] == 200) {

        var userData = {
          token: this.logindata[1].response.data.token,
          userType: this.logindata[1].response.data.user_type_id,
          // is_seller: this.logindata[1].response.data.is_seller ? this.logindata[1].response.data.is_seller : false,
          is_seller: this.logindata[1].response.data.is_seller == 't' ? true : false,
          siteId: 2,
          lang_id: this.logindata[1].response.data.lang_id,
          username: this.logindata[1].response.data.username,
          location: '',
        }
        let nickname = userData.username.slice(0, 7);
        localStorage.setItem('nickname', nickname);
        this.nickname = localStorage.getItem('nickname');
        console.log(this.nickname);
        console.log(userData);
        if (userData.userType == 1) {
          localStorage.setItem
          this.isAdmin = true
          localStorage.setItem('d_tag', 'true');
        } else {
          this.isAdmin = false;
          localStorage.setItem('d_tag', 'false');
        }
        this.setUserType(userData.userType, userData.is_seller);
        var is_userset = this.userStorage.setUserinfo(userData);


        if (localStorage.getItem('atc_tag') == "false") {
          let atc_data = JSON.parse(localStorage.getItem('atc_price'));
          console.log(atc_data);
          var atc = this.request.get_structure('add_cart', 'format');
          atc.product_id_fs = atc_data.pid_fs;
          // atc.product_id = 2; 
          atc.qty = atc_data.qty;
          atc.variant_id = atc_data.v_id;
          console.log(temp);
          console.log("test-->", 1);
          let addcart = await this.request.post(1, 'add_cart', atc);
          console.log(addcart);
          localStorage.setItem('atc_tag', "true");
        }
        var temp = this.request.get_structure('my_cart', 'format');
        temp.product_id = await this.userStorage.getUserInfo('siteId');
        let cartData = await this.request.post(1, 'my_cart', temp);
        this.userStorage.updateUserInfo('cart_value', cartData[1].response.data.overall_total);
        this.userStorage.updateUserInfo('cart_count', cartData[1].response.data.overall_count);
        this.share.addToCart(cartData[1].response.data.overall_count, cartData[1].response.data.overall_total);
        this.triggerFalseClick();
        if (is_userset) {
          this.isUserLogged = true;
          this.get_man_address();
          this.getExistingAddr();
        }
        // window.location.reload()
        if (this.userStorage.buynow) {
          this.userStorage.buynow = false;
          this.router.navigate(['/cart']);
        }
        window.location.reload();
      }
    }
  }

  async get_man_address() {
    var temp = this.request.get_structure('address_info', 'format');
    // temp.product_id = 2;
    temp.order_status = "null";
    var get_address = await this.request.post(1, 'address_info', temp);

    console.log(get_address);
    if (get_address[1].response.data.length > 0) {
      this.noAddress = false;
      this.addressList = get_address[1].response.data;
      console.log(this.addressList, " ?????? ")
      this.location_save = false
      for (let i of this.addressList) {
        // console.log(i.is_main, "ppppp")
        if (i.is_main == true) {
          this.location = i.city
          console.log("true:::::::")
        }
      }
    } else {
      // document.getElementById('click_location').click();
    }
  }


  async logout() {
    var temp = this.request.get_structure('logout', 'format');
    // temp.product_id = 2;
    if (this.isUserLogged) {
      this.logoutdata = await this.request.post(1, 'logout', temp);
      this.share.addToCart(0, 0);
      this.cart_data = this.share.getItems();
      console.log(this.logoutdata);
      if (this.logoutdata[1].response.data == true) {
        this.router.navigate(['/'])
        this.userStorage.removeUserAllInfo();
        localStorage.setItem('tag_load', 'false')
        this.isUserLogged = false;
        localStorage.removeItem('nickname');
        localStorage.removeItem('direct_data');
        localStorage.removeItem('type');
        localStorage.removeItem('Category_id');
        localStorage.removeItem('userdata');
        localStorage.setItem('dbuy_tag', 'false')
        // localStorage.removeItem('atc_price');
        localStorage.removeItem('pay_type2');
        localStorage.removeItem('cc');
        localStorage.removeItem('pay_type1');
        localStorage.removeItem('pay_type');
        localStorage.removeItem('dbuy_tag');
        localStorage.removeItem('clear_data_tag');
        localStorage.removeItem('isbousr');
      }
    }
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  changesignup() {
    this.islogin = false;
    this.isSignup = true;
  }
  async onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {

      return;
    }
    else {
      this.login_data = {
        "payload": {
          "sponsor": 0,                  	//default 0
          "account_type": 1,             	//default 1
          "username": this.registerForm.value.firstName,
          "password": this.registerForm.value.password,
          "email": this.registerForm.value.email,
          "country": "null",
          "product_id": 2,
          "company_name": "null",        	//default "null"
          "user_type": 4
        }
      }

      // this.signupdata = await this.logservice.get_api(this.login_data, 'bov3002/88314');
      var temp = this.request.get_structure('sign_up1', 'format');
      // temp.product_id = 1;
      temp.username = this.registerForm.value.firstName;
      temp.password = this.registerForm.value.password;
      temp.email = this.registerForm.value.email;
      temp.country = "null",
        // temp.product_id = 2,
        temp.company_name = "null",        	//default "null"
        temp.user_type = 4
      this.signupdata = await this.request.post(0, 'sign_up1', temp);

      console.log(this.signupdata)
      if (this.signupdata[0] == 400) {
        var err = this.signupdata[1].response.error;

        if (err == 'user_name_exists') {
          this.signup_fail = this.language.mLparams[this.userStorage.current_language].user_name_exists;
        }
        else if (err == 'email_exists') {
          this.signup_fail = this.language.mLparams[this.userStorage.current_language].email_exists;
        }
        else {
          this.signup_fail = 'Err! Try Again';
        }
        setTimeout(() => this.signup_fail = false, 5000);

      }
      else if (this.signupdata[0] == 200) {

        this.registerForm.reset();
        this.successMsg = true;
        this.activation_success = this.language.mLparams[this.userStorage.current_language].activation_success;
        this.signup_success = this.language.mLparams[this.userStorage.current_language].signup_success;
        this.changelogin();
        this.submitted = false;

      }
    }
  }
  forgetPassword() {
    this.triggerFalseClick();
    this.router.navigate(['/resetpass']);
  }

  changelogin() {
    this.islogin = true;
    this.isSignup = false;
  }


  // onSelectLocation() {
  //   console.log(this.selectLocations.value);
  // }


  // async selectLocation() {
  //   var temp = this.request.get_structure('country_api', 'format');
  //   temp.option = 3;
  //   this.selectCountryList = await this.request.post(1, 'country_api', temp);
  //   console.log(this.selectCountryList);
  //   if (this.country_state[0] == 200) {
  //     this.country_state = this.country_state[1].response.data;
  //     this.country_state.sort(function (a, b) {
  //       return a.regions.localeCompare(b.regions);
  //     });
  //   }
  // }


  async initialCountry() {
    var temp = this.request.get_structure('country_api', 'format');
    // temp.product_id = 4;
    this.country = await this.request.post(1, 'country_api', temp);
    // console.log(this.country);
    // console.log(this.country[1].response.data.countries);
    if (this.country[0] == 200) {
      this.countryList = this.country[1].response.data;
      this.countryList.sort(function (a, b) {
        return a.country_name.localeCompare(b.country_name);
      });
    }

    // console.log(this.country[1].response.data);
  }
  async getCategories() {
    console.log("header cat");
    var temp = this.request.get_structure('get_category', 'format');
    // temp.product_id = 2;
    console.log(temp)
    this.category_data = await this.request.post(0, 'get_category', temp);
    console.log(this.category_data, "lkdjufhfkidurgvkiufdxvukhf");
    this.category_data = this.category_data[1].response.data.slice(0, this.catlimit);

  }



  // onlocationClose() {
  //   this.selectLocations = this.formBuilder.group({
  //     wcountry: [''],
  //   })
  // }

  location_save = false;
  defalt_loc = "select location"
  async getExistingAddr() {
    var temp = this.request.get_structure('address_info', 'format');
    // temp.product_id = 2;
    temp.order_status = "null";
    var get_address = await this.request.post(1, 'address_info', temp);

    console.log(get_address);
    if (get_address[1].response.data.length > 0) {
      this.noAddress = false;
      this.addressList = get_address[1].response.data;
      console.log(this.addressList, " ?????? ")
      this.location_save = false
      for (let i of this.addressList) {
        console.log(i.is_main, "ppppp")

        if (i.is_main == true) {
          this.location_save = true

          this.defalt_loc = i.address_name
          console.log("true:::::::")
        }
      }
      this.addressList.forEach(value => {
        if (value.is_main == true) {
          // this.location = value.city;
          this.postal_code = value.postal_code;
          this.address_id = value.address_id;
          this.userStorage.updateUserInfo('dId', this.address_id);
          this.userStorage.updateUserInfo('location', value.city);
          this.location = this.userStorage.getUserInfo('location');

          // console.log(value.address_id);
        }
      });
    } else {
      this.noAddress = true;
    }
  }

  async addressChange(value) {
    var temp = this.request.get_structure('address_default', 'format');
    // temp.product_id = 2;
    temp.address_id = parseInt(value);
    var get_address = await this.request.post(1, 'address_default', temp);
    // this.isCheckoutDisabled = false;
    console.log(get_address);
    this.getExistingAddr()
    this.addressList.forEach(add_value => {
      if (add_value.address_id == value) {
        // this.location = add_value.city;address_namec
        this.location = add_value.city;
        this.postal_code = add_value.postal_code;
      }
    });

  }

  bouser() {
    this.boUser = !this.boUser;
    console.log(this.boUser);
    if (this.boUser == false) {
      this.account_name = this.language.mLparams[this.lang_dync].backoffice;
      this.boLogin = false;
    } else if (this.boUser == true) {
      this.boLogin = true;
      this.account_name = this.language.mLparams[this.lang_dync].remus
    }
  }

  async get_search_products() {
    let temp = this.request.get_structure('lp_get_search_products', 'format');
    // temp.product_id = 2;
    console.log(temp)
    this.searchProducts = await this.request.post(0, 'lp_get_search_products', temp);
    console.log(this.searchProducts)
    this.searchProducts = this.searchProducts[1].response.data;
    console.log()
    this.searchProducts.forEach(e => {
      // console.log(e.product_name);
      this.name = e.product_name;
    });
    // console.log(this.searchProducts);
    // console.log(this.name);
    this.userStorage.updateUserInfo('sp', this.searchProducts);
  }

  sortStringInJson(a, b, key) {
    // console.log(a, b, key)
    let nameA = a[key].toUpperCase();
    let nameB = b[key].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // if this line is seen, names must be equal
    return 0;
  }

  result(value) {
    if (value) {
      this.searchInput = value;
      this.searchResults = [...this.searchProducts]
      this.searchResults = this.searchResults.filter(data => {
        if (data.product_name.toUpperCase().includes(value.toUpperCase())) {
          return data
        }
      }).sort((a, b) => { return this.sortStringInJson(a, b, "product_name") }).filter((data, index) => {
        if (index < this.searchResultCount)
          return data;
      })
      console.log(this.searchResults[0].product_id, this.searchResults[0].product_name)
    }
    // this.singlepage(this.searchResults[0].product_id)
  }


  /** Single Product page redirect with data*/

  singlepage(product) {
    console.log(product);
    this.router.navigateByUrl("/product?pid=" + product);
    setTimeout(() => {
      location.reload();
    }, 100);
  }
  /** Single Product page redirect with data*/


  eventHandler(keyvalue) {
    console.log(keyvalue);
    if (keyvalue == 13) {
      document.getElementById('lp_search').click();
    }
  }


  cartBtn() {
    this.router.navigate(['/cart']);
  }

  second_cat = '';
  second_cat2 = '';


  getid1(i) {

    console.log(i)
    if (i.array_to_json == null) {
      setTimeout(() => {
        window.location.reload();
      }, 1);
      this.service.recive_method(i.category_id)
      this.router.navigate(['/category_product']);

    } else {
      return
    }
  }


  async service_cat() {
    this.catName = await this.service.give_cat_to_head
  }


  // clearcat() {
  //   if (this.router.routerState.snapshot.url == "/dshop") {
  //     localStorage.removeItem('catname');
  //     window.location.reload();
  //   }
  // }
  // hidediv =false
  need_cat(i) {

    console.log(i)
    this.second_cat2 = '';
    this.second_cat = '';
    this.second_cat = i.array_to_json
    console.log(this.second_cat, "cccccccccccccccccccccccccccccccccc")
    //     if (this.second_cat != null){
    //       this.hidediv= true;
    //     }else if (this.second_cat == null){
    // this. hidediv =false
    //     }
  }


  need_cat2(i) {
    console.log(i)
    this.second_cat2 = '';
    this.second_cat2 = i.array_to_json
  }

  getid2(i) {

    if (i.array_to_json == null) {
      if (this.router.routerState.snapshot.url == "/category_product") {
        this.service.recive_method(i.category_id)
        this.router.navigate(['/category_product']);
        window.location.reload();
      } else {
        this.service.recive_method(i.category_id)
        this.router.navigate(['/category_product']);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      // window.location.reload();
    } else {
      return
    }
  }

  ID = true;

  getid3(i) {

    if (i.array_to_json == null) {
      if (this.router.routerState.snapshot.url == "/category_product") {
        this.service.recive_method(i.category_id)
        this.router.navigate(['/category_product']);
        window.location.reload();
      } else {
        this.service.recive_method(i.category_id)
        this.router.navigate(['/category_product']);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
      // window.location.reload();
    } else {
      return
    }
  }

  refresh_but() {
    console.log("aaaaaaaa")
    if (this.category_data) {
      this.second_cat2 = '';
      this.second_cat = '';
    }



  }
  fun(value) {
    if (value) {
      this.searchInput = value;
      this.searchResults = [...this.searchProducts]
      this.searchResults = this.searchResults.filter(data => {
        if (data.product_name.toUpperCase().includes(value.toUpperCase())) {
          return data
        }
      }).sort((a, b) => { return this.sortStringInJson(a, b, "product_name") }).filter((data, index) => {
        if (index < this.searchResultCount)
          return data;
      })
      console.log(this.searchResults[0].product_id, this.searchResults[0].product_name)
      this.Product_id = this.searchResults[0].product_id
      console.log(this.Product_id)
      this.router.navigateByUrl("/product?pid=" + this.Product_id);
      setTimeout(() => {
        location.reload();
      }, 1);

    }

  }

  loc: any;
  dropdown_loc(event) {
    this.loc = ''
    console.log(event)
    this.defalt_loc = event.address_name
    this.loc = event
  }
  error_location = false


  async save_lo() {
    console.log(this.loc)

    if (this.loc) {
      this.addressChange(this.loc.address_id)
      document.getElementById('close_location').click();
      if (localStorage.getItem('atc_tag') == "false") {
        let atc_data = JSON.parse(localStorage.getItem('atc_price'));
        console.log(atc_data);
        var atc = this.request.get_structure('add_cart', 'format');
        atc.product_id_fs = atc_data.pid_fs;
        // atc.product_id = 2; 
        atc.qty = atc_data.qty;
        atc.variant_id = atc_data.v_id;
        console.log(temp);
        console.log("test-->", 1);
        let addcart = await this.request.post(1, 'add_cart', atc);
        console.log(addcart);
        localStorage.setItem('atc_tag', "true");
      }
      var temp = this.request.get_structure('my_cart', 'format');
      temp.product_id = await this.userStorage.getUserInfo('siteId');
      let cartData = await this.request.post(1, 'my_cart', temp);
      this.userStorage.updateUserInfo('cart_value', cartData[1].response.data.overall_total);
      this.userStorage.updateUserInfo('cart_count', cartData[1].response.data.overall_count);
      this.share.addToCart(cartData[1].response.data.overall_count, cartData[1].response.data.overall_total);
      this.triggerFalseClick();


    } else {
      this.error_location = true;
      setTimeout(() => {
        this.error_location = false;
      }, 3000);
    }


  }

  
  close_address() {
    this.AddressAddBtn = true;
  }

  AddressAddBtn = true;
  async addAddress(addr: AddAddressFormData) {
    console.log(addr);
    let temp = this.request.get_structure('address_add', 'format');
    temp.address_id = 0;
    temp.address_name = addr.name;
    temp.address1 = addr.addr1;
    temp.address2 = addr.addr2;
    temp.country = addr.country;
    temp.region = addr.state;
    temp.phone_no = parseInt(addr.mobile);
    temp.city = addr.city;
    temp.postal_code = addr.zip;
    console.log(temp);
    let address_add_info = await this.request.post(1, 'address_add', temp);
    console.log(address_add_info);
    this.getExistingAddr();
    this.onAddaddress();
  }

  onAddaddress() {
    this.AddressAddBtn = !this.AddressAddBtn;

  }




}
