import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { APIs_Config } from 'src/app/sys_config';

import { IfStmt, THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Request } from 'src/app/_service';
import { LanguageService } from 'src/app/config/language';
import { ModeServices } from 'src/app/config/modes';
import { removeData } from 'jquery';


export interface PeriodicElement {
  name: string;
  value: number;

}



@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
  providers: [LanguageService, ModeServices],
})


export class AddProductComponent implements OnInit {
  dataSource: PeriodicElement[];
  lang_dync = 'english';
  specs: any[];
  specsData: any[];
  title = '';
  description = '';
  condition = '';
  variance = [];
  var_image1 = false;
  var_image2 = false;
  var_image3 = false;
  addproduct = false;
  wareHouses = [];
  selWareHouse: any;
  isUsed = false;
  isInSet = false;
  diserr = false;
  tablevalue = false;
  productGroups = [];
  unitsPerSet = '';
  hasVariants = false;
  Disabled = false;
  variantDetails = [
    { name: '', values: '' },
    { name: '', values: '' },
  ];
  singleVariantDtls = { note: "", price: "", disc: "", discType: "" };
  isTitleErr = false;
  isDescErr = false;
  showUnitsPerSet = false;
  showVariantsDtls = false;
  nameerr = false;
  typeDiscount = 'select Type';


  addProductDetails: FormGroup;

  constructor(
    public language: LanguageService,
    public mode: ModeServices,
    private request: Request,
    private formBuilder: FormBuilder,
    public config: APIs_Config,
  ) { }


  @Input()
  warehous_Id: any;

  @Output()
  IdEmitter = new EventEmitter<any>();

  ngOnInit(): void {
    this.specs = [1];
    this.specsData = [''];
    this.getWarehouses();


    this.addProductDetails = this.formBuilder.group({
      productName: ['', [Validators.required]],
      productDescription: ['', [Validators.required]],
      Weight: ['', [Validators.required]],
      Price: ['', [Validators.required]],
      DiscountValue: [Number, [Validators.required]],
      DiscountType: ['', [Validators.required]],
      TotalPrice: [Number, [Validators.required]],
      ProductID: [Number, [Validators.required]],
      AvailableStock: ['', [Validators.required]]

    });

  }


  ///////////////////////////////////////////////////kevin///////////////////////////////////////////


  SpecificationsDetails() {
    return this.formBuilder.group({
      Colour: [''],
      size: ['']
    })
  }









  ////////////////////////////////////////////////kevin/////////////////////////////////////////////////






  handleAddRow(data) {
    let allowNewRow = true;

    for (let i = 0; i < this.specs.length; i++) {
      if (!this.specsData[i]) {
        allowNewRow = false;
      }
    }
    if (allowNewRow) {
      this.specs.push(1);
      this.specsData.push('');
    }
    console.log(this.specs, this.specsData);
  }

  handleDelRow(rowIndex) {
    console.log(rowIndex, this.specs, this.specsData);

    if (this.specs.length > 1) {
      if (this.specsData.length === rowIndex) {
        this.specs.pop();
      } else {
        this.specsData.splice(rowIndex, 1);
        this.specs.pop();
      }
    }

    console.log(this.specs, this.specsData);
  }

  handleBlur(data) {
    if (this.specs.length >= 1) {
      this.specsData[data.index] = data.data;
      console.log(this.specsData + 'handleBlur');
    }
  }


  handleTitleChange(e) {
    let temp = e.target.value;
    if (temp) {
      this.title = temp;
      console.log(this.title);
    }
  }

  handleDescriptionChange(e) {
    let temp = e.target.value;
    if (temp) {
      this.description = temp;
      console.log(this.description);
    }
  }

  handleIsUsed(e) {
    if (e.target.id === 'conditionNew') {
      this.isUsed = false;
    }
    if (e.target.id === 'usedCondition') {
      this.isUsed = true;
    }
  }

  showProductCondition() {
    if (!this.mode.config_mode.three) return 'hideDiv';
    else return '';
  }

  displayUnitsPerSet() {
    if (!this.isInSet) return 'hideDiv';
    else return '';
  }

  handleIsInSet(e) {
    if (e.target.id === 'isSet') {
      this.isInSet = true;
    }
    if (e.target.id === 'units') {
      this.isInSet = false;
    }
  }

  confirm: boolean

  handleHasVariants(e) {
    if (e.target.id === 'noVarnts') {
      this.hasVariants = false;
      this.tablevalue = false;
      this.confirm = false

    }
    if (e.target.id === 'hasVarnts') {
      this.hasVariants = true;

      this.confirm = true;
      this.tableCreated = false
    }
  }

  showVariantInputBoxes() {
    if (!this.hasVariants) {
      this.tablevalue = false;
      return 'hideDiv'
    }
    else return '';
  }
  showVariantNotInputBoxes() {
    if (this.hasVariants) { return 'hideDiv' }
    else return '';
  }

  color: any;
  size: any;

  handleVarDetlsChange(e) {
    if (e.target.value) {
      let data = e.target.value;

      if (e.target.id === 'variant1Name') {
        this.variantDetails[0].name = data;
      }
      if (e.target.id === 'variant1Vals') {
        this.variantDetails[0].values = data;
      }
      if (e.target.id === 'variant2Name') {
        this.variantDetails[1].name = data;
      }
      if (e.target.id === 'variant2Vals') {
        this.variantDetails[1].values = data;
      }
    }
    console.log(this.variantDetails)
    this.color = this.variantDetails[0].name
    this.size = this.variantDetails[1].name
  }

  showCollectSingleVariant() {
    if (this.hasVariants) return 'hideDiv';
    else return '';

  }



  //////////////////////////////////////////////////////////////////////////////CREATE TABLE LIST //////////////////////////////////////////





  tableList() {


    this.Productname = this.addProductDetails.value.productName;
    this.ProductDis = this.addProductDetails.value.productDescription;
    this.weight = this.addProductDetails.value.Weight;
    this.key = this.specsData;
    setTimeout(() => {
      this.key = ['key']
      this.weight = 'weight';
      this.ProductDis = 'ProductDis';
      this.Productname = 'Productname';

    }, 3000);
    if (this.Productname && this.ProductDis && this.weight && this.Stock && this.key) {

      this.tablevalue = true;
      this.Disabled = true;
      this.tableCreated = true;
      this.var_image1 = false;
      this.var_image2 = false;
      this.var_image3 = false;
      this.getProductDetails();
      var str = this.variantDetails[0].values;
      var colour = str.split(",");

      var str = this.variantDetails[1].values;
      var size = str.split(",");

      console.log({ colour: colour })
      console.log({ size: size })

      console.log(this.addProductDetails.value.productName)

      // this.color = {colour:colour,size:size}
      this.variance = []
      let i = 0;
      let k = '';
      let d = '';
      let t = '';
      let a = '';
      let s: string;
      let type1name: string;
      let type2name: string;
      let p: string;
      let path: any[];
      let imagename = [];

      let totaldis = 0;

      for (let col of colour) {
        for (let siz of size) {

          this.variance.push({
            index: ++i, Name: this.addProductDetails.value.productName,

            note: s, Type1: col, Type2: siz, Price: k, ProductGroup: p,
            Discount: d, total: totaldis, DisType: t, AvailableStock: a,
            imagepath: []
          })

        }
      }
      console.log(this.variance)
    }
  }

  tableCreated = false;

  closeTable() {
    this.tablevalue = false
    this.valide_Price = false
    this.valide_image = false;
    this.valide_stack = false;
    this.valide_product_group = false;
    this.valide_success_messge = false;
    this.valide_failed = false;
    this.valide_success_messge = false;
    this.tableCreated = false;
    this.variance = []
  }


  delVariance(data) {
    for (let varian of this.variance) {
      if (data.index == varian.index) {

        let index = this.variance.indexOf(varian)
        console.log(index)
        this.variance.splice(index, 1)
        console.log(this.variance)
      }
    }
  }

  typesDiscount: boolean;


  PriceDel(data) {

    if (this.typesDiscount == true) {
      this.typeDiscount = 'Discount %';
      console.log(data.Price)
      console.log(data.Discount)
      var discount = data.Price * data.Discount / 100
      var totldis = data.Price - discount;
      console.log(discount + 'discount')
      let index = this.variance.indexOf(data);
      this.variance[index].total = totldis;
      console.log(this.variance[index])
      console.log(this.variance[index].total)
    } else if (this.typesDiscount == false) {

      this.typeDiscount = 'Flat rate';
      var Flat = data.Price - data.Discount;
      console.log(Flat + 'Discount %')
      let index = this.variance.indexOf(data);
      this.variance[index].total = Flat;
      console.log(this.variance[index])
      console.log(this.variance[index].total)
    } else {
      console.log('not')
    }

  }




  typesDis(data: boolean) {

    // this.typeDiscount = 'Discount %';
    // var discount = data.Price * data.Discount / 100
    // var totldis = data.Price - discount;
    // console.log(totldis + 'Discount %')
    // let index = this.variance.indexOf(data);
    // this.variance[index].total = totldis;
    // console.log(this.variance[index])
    // console.log(this.variance[index].total)
    if (data) {
      this.typesDiscount = true;
    } else {
      this.typesDiscount = false;
    }

  }

  typesFlat(data) {

    this.typeDiscount = 'Flat rate';
    var Flat = data.Price - data.Discount;
    console.log(Flat + 'Discount %')
    let index = this.variance.indexOf(data);
    this.variance[index].total = Flat;
    console.log(this.variance[index])
    console.log(this.variance[index].total)
  }


  Totalprice_of_Product = 0;

  onStateSelectnoVariants(data) {
    if (data.target.value == 'perc') {


      var discount = this.addProductDetails.value.Price * this.addProductDetails.value.DiscountValue / 100;
      var totldis = this.addProductDetails.value.Price - discount;
      console.log(totldis + '  discount')
      this.Totalprice_of_Product = totldis

    }
    else if (data.target.value == 'flat') {


      var Flat = this.addProductDetails.value.Price - this.addProductDetails.value.DiscountValue;
      console.log(Flat + '   Flate')
      this.Totalprice_of_Product = Flat

    } else if (data.target.value == 'none') {
      return this.Totalprice_of_Product = this.addProductDetails.value.Price;
    }
  }

  onStateSelect(i, data) {
    console.log(i)
    console.log(data.target.value)
    let index = this.variance.indexOf(i);
    this.variance[index].DisType = data.target.value;
    console.log(this.variance[index])
    console.log(this.variance[index].DisType)

    if (data.target.value == 'perc') {

      console.log(i.Price)
      console.log(i.Discount)
      var discount = i.Price * i.Discount / 100
      var totldis = i.Price - discount;
      console.log(discount + 'discount')
      let index = this.variance.indexOf(i);
      this.variance[index].total = totldis;
      console.log(this.variance[index])
      console.log(this.variance[index].total)


    } else if (data.target.value == 'flat') {


      var Flat = i.Price - i.Discount;
      console.log(Flat + 'Discount %')
      let index = this.variance.indexOf(i);
      this.variance[index].total = Flat;
      console.log(this.variance[index])
      console.log(this.variance[index].total)

    } else if (data.target.value == 'none') {
      let index = this.variance.indexOf(i);
      this.variance[index].total = i.Price;
      console.log(this.variance[index].total)
    }


  }







  //////////////////////////////////////////////////////////////////////
  async getWarehouses() {

    console.log('getWarehouses')
    let temp = await this.request.post(
      1,
      'get_warehouse',
      this.config.cfg.get_warehouse.format
    );
    console.log(temp, 'get wh reply');

    if (temp[0] == 200) {
      this.wareHouses = temp[1].response.data;
      for (let ware of this.wareHouses) {

        this.selWareHouse = ware.warehouse_id
        console.log(this.selWareHouse)
      }
    }
  }


  NoVarientProductGroup: any[];

  async getProductDetails() {
    let { format } = this.config.cfg.get_product_group;

    format.warehouse_id = this.warehous_Id.warehouse_id;

    console.log(this.warehous_Id.warehouse_id)
    console.log('child recived')
    console.log(format, 'Product List');

    let temp = await this.request.post(1, 'get_product_group', format);
    console.log([{ temp }], 'Product temp');
    if (temp) {

      this.NoVarientProductGroup = [...temp[1].response.data];
      this.productGroups = [...temp[1].response.data];
      console.log(this.productGroups + 'this.productGroups')
      console.log(this.NoVarientProductGroup + 'this.NoVarientProductGroup')
    }
  }


  AvailableStock(vari, event) {
    console.log(vari)
    console.log(event.target.value);


  }

  image1: any
  image2: any
  image3: any
  allimage: any[] = [];
  submit = false;
  span_three = false;
  imagecondition: any;
  span_three2 = false;
  span_three3 = false;
  file_validatore = false
  tag_value = "Set a Tag"
  async file1(event) {

    console.log(event.target.files[0].type)

    if (event.target.files[0].type == "image/png" || event.target.files[0].type == "image/jpeg") {

      var image = event.target.files[0]

      console.log(image)

      var name = event.target.files[0].name

      this.image1 = await this.uploadthefile(event)
      console.log(this.image1)
      var encoded = this.image1.split(',');
      console.log(encoded + ' this.encoded')
      var fileencoded = encoded[1];
      console.log(fileencoded + '  this.fileencoded')
      let { format } = this.config.cfg.file_upload;
      format.file_name = name;
      format.image_name = 'side view';
      format.product_name = this.addProductDetails.value.productName;
      format.file_base64 = fileencoded;
      format.is_main = true
      console.log(format)
      let temp = await this.request.post(1, 'file_upload', format);
      this.submit = true;
      this.confirm_hide = true;
      console.log(temp)
      if (temp[0] == 400) {
        this.addproduct = true;
        this.confirm_hide = false;
        setTimeout(() => {
          this.addproduct = false;

        }, 3000);
        return;

      } else if (temp[0] == 200) {

        if (event.target.id == "NovarImag1") {
          this.allimage[0] = { url: temp[1].response.data.file_path, is_main: true }
          this.span_three = true
          this.confirm_hide = false;
        }
        else if (event.target.id == "NovarImag2") {
          this.allimage[1] = { url: temp[1].response.data.file_path, is_main: false }
          this.confirm_hide = false;
          this.span_three2 = true
        }
        else if (event.target.id == "NovarImag3") {
          this.allimage[2] = { url: temp[1].response.data.file_path, is_main: false }
          this.confirm_hide = false;
          this.span_three3 = true
        }

      } else {
        this.confirm_hide = false;
      }
    } else {
      setTimeout(() => {
        this.file_validatore = false;
      }, 3000);
      this.file_validatore = true;
    }

  }




  // async file2(event) {
  //   console.log(event.target.files[0])
  //   var image = event.target.files[0]
  //   console.log(image)
  //   var name = event.target.files[0].name
  //   this.span_three = false;
  //   this.image2 = await this.uploadthefile(event)
  //   console.log(this.image2)
  //   var encoded = this.image2.split(',');
  //   console.log(encoded + ' this.encoded')
  //   var fileencoded = encoded[1];
  //   console.log(fileencoded + '  this.fileencoded')
  //   let { format } = this.config.cfg.file_upload;
  //   format.file_name = name;
  //   format.image_name = 'side view';
  //   format.product_name = this.addProductDetails.value.productName;
  //   format.file_base64 = fileencoded;
  //   format.is_main = false;
  //   console.log(format)
  //   let temp = await this.request.post(1, 'file_upload', format);

  //   console.log(temp)
  //   console.log(temp[1].response.data.file_path)
  //   this.image2 = temp[1].response.data.file_path
  // }

  // async file3(event) {
  //   console.log(event.target.files[0])
  //   var image = event.target.files[0]
  //   console.log(image)
  //   var name = event.target.files[0].name
  //   this.span_three = false;
  //   this.image3 = await this.uploadthefile(event)
  //   console.log(this.image3)
  //   var encoded = this.image3.split(',');
  //   console.log(encoded + ' this.encoded')
  //   var fileencoded = encoded[1];
  //   console.log(fileencoded + '  this.fileencoded')
  //   let { format } = this.config.cfg.file_upload;
  //   format.file_name = name;
  //   format.image_name = 'side view';
  //   format.product_name = this.addProductDetails.value.productName;
  //   format.file_base64 = fileencoded;
  //   format.is_main = false;
  //   console.log(format)
  //   let temp = await this.request.post(1, 'file_upload', format);

  //   console.log(temp)
  //   console.log(temp[1].response.data.file_path)
  //   this.image3 = temp[1].response.data.file_path
  // }




  // reader.onload = this._handleReaderLoaded.bind(this);
  // reader.readAsBinaryString(file);


  // format.discription = data.productDescription;
  // format.bullet_pts = this.specsData;
  // format.is_set = this.isInSet;

  // // for (let varian of this.variance) {
  // //   console.log(varian.index+"varian.ProductGroup")
  // // }
  //  console.log(this.variance)
  //  console.log(format, 'Add Product Format');
  // // let temp = await this.request.post(1, ' add_product', format);


  uploadthefile(event) {
    var selectedFile = <File>event.target.files[0];
    return new Promise(function (resolve, reject) {
      var reader = new FileReader();
      reader.onload = function () { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsDataURL(selectedFile);

    });
  }

  varientAllImage: any

  imagesofvariencs1: string;
  imagesofvariencs2: string;
  imagesofvariencs3: string;

  async varient1(i, event) {
    console.log(event.target.id)
    console.log('777777777777777')
    this.imagesofvariencs1 = '';
    var FileNmae = event.target.files[0].name;
    this.varientAllImage = await this.uploadthefile(event)
    console.log(this.varientAllImage)
    var encoded = this.varientAllImage.split(',');
    var fileencoded = encoded[1];
    console.log(fileencoded + '  this.fileencoded')
    let { format } = this.config.cfg.file_upload;
    format.file_name = FileNmae;
    format.image_name = 'side view';
    format.product_name = this.addProductDetails.value.productName;
    format.file_base64 = fileencoded;
    format.is_main = false;
    this.spinnerneed = true;
    console.log(format + 'sending date from client')
    let temp = await this.request.post(1, 'file_upload', format);
    if (temp[0] == 400) {
      this.addproduct = true;
      this.spinnerneed = false;
      setTimeout(() => {
        this.addproduct = false;

      }, 3000);
      return;

    } else if (temp[0] == 200) {
      this.addproduct = false;
      console.log(temp + "Image date from Backend temp")
      console.log(temp[1].response.data.file_path + "Image Path_name from Backend1111111111111111111")
      let index = this.variance.indexOf(i);

      this.varientAllImage = FileNmae
      this.spinnerneed = true;
      if (event.target.id == 'ima1') {
        await console.log('8888888')
        this.variance[index].imagepath[0] = temp[1].response.data.file_path
        console.log(this.variance)
        this.var_image1 = true;
        this.spinnerneed = false;

      }
      else if (event.target.id == 'ima2') {
        await console.log('8888888')
        this.variance[index].imagepath[1] = temp[1].response.data.file_path
        console.log(this.variance)
        this.var_image2 = true;
        this.spinnerneed = false;
      }
      else if (event.target.id == 'ima3') {
        await console.log('8888888')
        this.variance[index].imagepath[2] = temp[1].response.data.file_path
        console.log(this.variance)
        this.var_image3 = true;
        this.spinnerneed = false;

      }
    }

    // this.variance[index].imagepath = [this.imagesofvariencs1, this.imagesofvariencs2, this.imagesofvariencs3];
    console.log(this.variance)
    console.log('this.variance')
  }

  // async varient2(i, event) {
  //   this.imagesofvariencs2 = '';
  //   var FileNmae = event.target.files[0].name;
  //   this.varientAllImage = await this.uploadthefile(event)
  //   console.log(this.varientAllImage)
  //   var encoded = this.varientAllImage.split(',');
  //   var fileencoded = encoded[1];
  //   console.log(fileencoded + '  this.fileencoded')
  //   let { format } = this.config.cfg.file_upload;
  //   format.file_name = FileNmae;
  //   format.image_name = 'side view';
  //   format.product_name = this.addProductDetails.value.productName;
  //   format.file_base64 = fileencoded;
  //   format.is_main = false;
  //   console.log(format + 'sending date from client')
  //   let temp = await this.request.post(1, 'file_upload', format);
  //   console.log(temp + "Image date from Backend")
  //   console.log(temp[1].response.data.file_path + "Image Path_name from Backend")
  //   let index = this.variance.indexOf(i);

  //   this.varientAllImage = FileNmae
  //   this.imagesofvariencs2 = temp[1].response.data.file_path
  //   this.variance[index].imagepath['url2'] = temp[1].response.data.file_path
  //   // this.variance[index].imagepath = [this.imagesofvariencs1, this.imagesofvariencs2, this.imagesofvariencs3];
  //   console.log(this.variance)
  // }


  // async varient3(i, event) {
  //   this.imagesofvariencs3 = '';
  //   var FileNmae = event.target.files[0].name;
  //   this.varientAllImage = await this.uploadthefile(event)
  //   console.log(this.varientAllImage)
  //   var encoded = this.varientAllImage.split(',');
  //   var fileencoded = encoded[1];
  //   console.log(fileencoded + '  this.fileencoded')
  //   let { format } = this.config.cfg.file_upload;
  //   format.file_name = FileNmae;
  //   format.image_name = 'side view';
  //   format.product_name = this.addProductDetails.value.productName;
  //   format.file_base64 = fileencoded;
  //   format.is_main = false;
  //   console.log(format + 'sending date from client')
  //   let temp = await this.request.post(1, 'file_upload', format);
  //   console.log(temp + "Image date from Backend")
  //   console.log(temp[1].response.data.file_path + "Image Path_name from Backend")
  //   let index = this.variance.indexOf(i);

  //   this.varientAllImage = FileNmae
  //   this.imagesofvariencs3 = temp[1].response.data.file_path
  //   this.variance[index].imagepath['url3'] =temp[1].response.data.file_path
  //   // this.variance[index].imagepath = [this.imagesofvariencs1, this.imagesofvariencs2, this.imagesofvariencs3];
  //   console.log(this.variance)
  // }




  // async varient4(i, event){
  //   this.imagesofvariencs1 = '';
  //   this.imagesofvariencs1 = '';
  //   this.imagesofvariencs1 = '';
  //   var FileNmae = event.target.files[0].name;
  //   this.varientAllImage = await this.uploadthefile(event)
  //   console.log(this.varientAllImage)
  //   var encoded = this.varientAllImage.split(',');
  //   var fileencoded = encoded[1];
  //   console.log(fileencoded + '  this.fileencoded')
  //   let { format } = this.config.cfg.file_upload;
  //   format.file_name = 'tp';
  //   format.image_name = 'side view';
  //   format.product_name = this.addProductDetails.value.productName;
  //   format.file_base64 = fileencoded;
  //   format.is_main = false;
  //   console.log(format + 'sending date from client')
  //   let temp = await this.request.post(1, 'file_upload', format);
  //   console.log(temp + "Image date from Backend temp")
  //   console.log(temp[1].response.data.file_path + "Image Path_name from Backend1111111111111111111")
  //   let index = this.variance.indexOf(i);

  //   this.varientAllImage = FileNmae
  //   // this.imagesofvariencs1 = temp[1].response.data.file_path;

  //   this.variance[index].imagepath = [{url1:temp[1].response.data.file_path}]

  //   // this.variance[index].imagepath = [];
  //   // this.variance[index].imagepath = [this.imagesofvariencs1, this.imagesofvariencs2, this.imagesofvariencs3];
  //   // console.log(this.variance)
  //   console.log(this.variance)
  //   console.log('this.variance')
  // }
























  ///////////////////////////////////////////////////////////////////////////api configration /////////////////////////////////////////////////////////////


  TotalPricePro = 2;
  Productname = 'Productname';
  ProductDis = 'ProductDis';
  weight = 'weight';
  imagecon = 'image';
  Stock = 1
  key: any = ['key'];
  group = '0';
  image = ['in']

  confirmProduct() {
    // if (this.addProductDetails.value.productName == '') {
    //   this.nameerr = true;
    //   this.diserr = false;
    //   return
    // } else if (this.addProductDetails.value.productDescription == '') {
    //   this.diserr = true;
    //   return this.nameerr = false
    // }
    // else {
    //   this.nameerr = false
    //   this.diserr = false
    //   this.Disabled = true;

    //   console.log(this.addProductDetails.value)
    //   // console.log(this.specsData)
    //   this.addProductDel(this.addProductDetails.value)

    // }

    this.Productname = this.addProductDetails.value.productName;
    this.ProductDis = this.addProductDetails.value.productDescription;
    this.weight = this.addProductDetails.value.Weight;
    this.Stock = this.addProductDetails.value.AvailableStock;
    this.key = this.specsData;
    this.image = this.allimage
    setTimeout(() => {
      this.image = ['in'];
      this.key = ['key']
      this.Stock = 1
      this.weight = 'weight';
      this.ProductDis = 'ProductDis';
      this.Productname = 'Productname';
      this.group = '0';

    }, 3000);

    if (this.addProductDetails.value.ProductID >= 0) {
      this.group = this.addProductDetails.value.ProductID
    } else {
      this.group = '';
      return
    }

    if (this.Productname && this.ProductDis && this.weight && this.Stock && this.key && this.group && this.image[0]) {
      this.TotalPricePro = 0;

      if (this.Totalprice_of_Product >= 1) {

        this.TotalPricePro = this.Totalprice_of_Product

        this.addProductDetails.value.TotalPrice = this.TotalPricePro
        this.addProductDel(this.addProductDetails.value)
        this.span_three = true

      }

    }
  }

  apivarience: any[] = [];

  productID_varience: number;


  async confirmProduct2() {
    this.Productname = this.addProductDetails.value.productName;
    this.ProductDis = this.addProductDetails.value.productDescription;
    this.weight = this.addProductDetails.value.Weight;
    this.key = this.specsData;
    console.log(this.variance)
    this.apivarience = []

    if (this.Productname && this.ProductDis && this.weight && this.key) {

      var varian = this.variance;

      for (let vari of varian) {

        this.productID_varience = vari.ProductGroup;

        var imagearray: any[] = [];
        var variants_var: any[] = [];


        if (this.color && this.size) {
          variants_var = [{
            variant_type: this.color, value: vari.Type1
          }, {
            variant_type: this.size, value: vari.Type2
          }]
          await console.log(variants_var);
        } else if (this.color) {
          variants_var = [{
            variant_type: this.color, value: vari.Type1
          }]
          await console.log(variants_var);
        } else if (this.size) {
          variants_var = [{
            variant_type: this.size, value: vari.Type2
          }]
          await console.log(variants_var);
        }


        let k = 0
        for (let i of vari.imagepath) {
          if (i) {
            imagearray.push({
              url: i, is_main: (k == 0 ? true : false)
            })
          }

          ++k
        }
        await this.apivarience.push({
          unique_product_id: null,
          limited_stock: false,
          qty_left: vari.AvailableStock,
          price: vari.Price,
          notes: vari.note,
          images: imagearray,
          variants: variants_var,
          offer_type: vari.DisType,
          offer_val: vari.Discount,
          offer_begin: 'null',
          offer_end: 'null',
          edit: false
        })

      }
      // console.log(this.variance)
      // console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
      console.log(this.apivarience)
      console.log("da")
      await this.addProductDel_withVarience(this.addProductDetails.value)
    }

  }


  confirm_hide = false;


  async addProductDel(data) {


    var Product_ID = data.ProductID
    Product_ID = Number(Product_ID)
    let { format } = this.config.cfg.add_multi_product;
    format.proudcts.product_id_fs = 0;
    format.proudcts.product_id = 2;
    format.proudcts.category_id = this.catagoryID;
    format.proudcts.seller_id = 118099;
    format.proudcts.product_group_id = Product_ID;
    format.proudcts.product_name = data.productName;
    format.proudcts.discription = data.productDescription;
    format.proudcts.bullet_pts = this.specsData;
    format.proudcts.is_used = this.isUsed;
    format.proudcts.weight = data.Weight;
    format.proudcts.admin_approved = false;
    format.proudcts.delete = false;
    format.proudcts.variants = [
      {
        "unique_product_id": null,
        "limited_stock": false,
        "qty_left": data.AvailableStock,
        "price": data.Price,
        "notes": data.note,
        "images": this.allimage,
        "variants": [
          {
            "variant_type": 'null',
            "value": 'null',
          }
        ],
        "offer_type": data.DiscountType,
        "offer_val": data.DiscountValue,
        "offer_begin": "null",
        "offer_end": "null",
        "edit": false
      },

    ]
    // format.delete = false;
    // format.discription = data.productDescription;
    // format.bullet_pts = this.specsData;
    // format.is_set = this.isInSet;
    // format.category_id = this.catagoryID;

    // for (let varian of this.variance) {
    //   console.log(varian.index+"varian.ProductGroup")
    // }

    console.log(format);
    let temp = await this.request.post(1, 'add_multi_product', format);
    console.log(temp, "no variant prod")
    console.log('no variente')

    if (temp[0] === 400) {

    } else if (temp[0] === 200) {

      this.valide_success_messge = true
      setTimeout(() => {
        this.conditions = true
        this.valide_success_messge = false
        this.addProductDetails = this.formBuilder.group({
          productName: ['', [Validators.required]],
          productDescription: ['', [Validators.required]],
          Weight: ['', [Validators.required]],
          Price: ['', [Validators.required]],
          DiscountValue: [Number, [Validators.required]],
          DiscountType: ['', [Validators.required]],
          TotalPrice: [Number, [Validators.required]],
          ProductID: [Number, [Validators.required]],
          AvailableStock: ['', [Validators.required]]
        });
        this.specsData = [''];
        this.Totalprice_of_Product = 0;
        this.span_three = false;
        this.span_three3 = false;
        this.span_three2 = false;
      }, 4000);

    } else {
      return console.log("error")
    }


  }

  spinnerneed = false;
  valide_Price = false;
  valide_image = false;
  valide_stack = false;
  valide_product_group = false;
  valide_success_messge = false;
  valide_failed = false




  async addProductDel_withVarience(data) {

    this.valide_Price = false
    this.valide_image = false;
    this.valide_stack = false;
    this.valide_product_group = false;
    this.valide_success_messge = false;
    this.valide_failed = false;
    this.valide_success_messge = false;


    this.spinnerneed = true;
    var Product_ID2 = this.productID_varience
    Product_ID2 = Number(Product_ID2)
    let { format } = this.config.cfg.add_multi_product;
    format.proudcts.product_id_fs = 0;
    format.proudcts.product_id = 2;
    format.proudcts.category_id = this.catagoryID;
    format.proudcts.seller_id = 118099;
    format.proudcts.product_group_id = Product_ID2;
    format.proudcts.product_name = data.productName;
    format.proudcts.discription = data.productDescription;
    format.proudcts.bullet_pts = this.specsData;
    format.proudcts.is_used = this.isUsed;
    format.proudcts.weight = data.Weight;
    format.proudcts.admin_approved = false;
    format.proudcts.delete = false;
    format.proudcts.variants = this.apivarience;




    // format.delete = false;
    // format.discription = data.productDescription;
    // format.bullet_pts = this.specsData;
    // format.is_set = this.isInSet;
    // format.category_id = this.catagoryID;

    // for (let varian of this.variance) {
    //   console.log(varian.index+"varian.ProductGroup")
    // }

    console.log(format, 'No Variance Full Details');



    for (let col of this.variance) {
      await console.log(col)
      if (col.total <= 1) {
        this.spinnerneed = false;
        console.log("Variance failed")
        return this.valide_Price = true;
      } else if (col.total >= 1) {
        await console.log("Variance valide ")
        this.valide_Price = false;

        if (col.imagepath[0]) {
          await console.log("image Variance valide")
          this.valide_image = false;

          if (col.AvailableStock >= 1) {
            await console.log("Variance stack valide ")
            this.valide_stack = false;
            if (col.ProductGroup >= 0) {
              await console.log("Variance ProductGroup valide ")
              this.valide_product_group = false;
              let temp = await this.request.post(1, 'add_multi_product', format);
              if (temp[0] == 200) {
                this.spinnerneed = false;
                this.valide_success_messge = true
                setTimeout(() => {
                  this.conditions = true
                  this.valide_success_messge = false
                  this.tablevalue = false
                  this.hasVariants = false;
                  this.addProductDetails = this.formBuilder.group({
                    productName: ['', [Validators.required]],
                    productDescription: ['', [Validators.required]],
                    Weight: ['', [Validators.required]],
                    Price: ['', [Validators.required]],
                    DiscountValue: [Number, [Validators.required]],
                    DiscountType: ['', [Validators.required]],
                    TotalPrice: [Number, [Validators.required]],
                    ProductID: [Number, [Validators.required]],
                    AvailableStock: ['', [Validators.required]]

                  });
                  this.specsData = [''];
                  this.Totalprice_of_Product = 0;
                  this.span_three = false;
                  this.span_three3 = false;
                  this.span_three2 = false;
                  this.variance = [];
                  this.color = '';
                  this.size = '';
                  this.variantDetails = [
                    { name: '', values: '' },
                    { name: '', values: '' },
                  ];
                }, 3000);


                return this.valide_success_messge = true;
              } else if (temp[0] == 400) {
                this.spinnerneed = false;

                return this.valide_failed = true;

              }
            } else {
              await console.log("Variance ProductGroup failed ")
              this.spinnerneed = false;
              return this.valide_product_group = true;

            }

          } else if (col.AvailableStock == '') {
            this.spinnerneed = false;
            await console.log("Variance stack failed ")
            return this.valide_stack = true;
          }
        } else {
          this.spinnerneed = false;
          await console.log("image Variance failed")
          return this.valide_image = true;
        }
      }
    }


  }

  disabled2 = true;
  catagoryID: any;
  categoryName: any;

  conditions = true;


  receiveAddress(event) {

    this.catagoryID = event;
    console.log(this.catagoryID + 'parante recived')
    this.IdEmitter.emit(this.catagoryID);
  }

  receiveName(event) {
    this.categoryName = event
    this.getProductDetails();
    console.log(this.categoryName + 'parante recived categoryName')
    if (this.categoryName) return this.conditions = false;

  }

  gum() {
    console.log(this.variance)
    console.log("gum")
  }

  set_a_tag(event){
    console.log('event')
    console.log(event.target.id)
    this.tag_value = event.target.id

  }
}