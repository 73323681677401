import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/config/language';
import { AddAddressFormData } from 'src/app/models/addAddrFormData';
import { APIs_Config, LocalstorageService } from 'src/app/sys_config';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Request } from '../../_service/index';
import * as CryptoJS from 'crypto-js';
import { Config } from '../../sys_config/config';
import { ServicesService } from 'src/app/_service/services.service';

@Component({
  selector: 'app-directbuy',
  templateUrl: './directbuy.component.html',
  styleUrls: ['./directbuy.component.scss'],
  providers: [LanguageService]

})
export class DirectbuyComponent implements OnInit , OnDestroy{

  constructor(
    private formBuilder: FormBuilder,
    public language: LanguageService,
    private request: Request,
    public config: APIs_Config,
    public userStorage: LocalstorageService,
    private router: Router,
    private modalService: NgbModal,
    private configs: Config,
    private service: ServicesService
  ) { }
  shippmentCost: any;
  addressList: any;
  addressInfo: FormGroup;
  noAddress = false;
  lang_dync = this.userStorage.current_language;
  AddressAddBtn = true;
  is_invalid = false;
  is_disabled = true;
  is_validdirect = false;
  pending = false;
  is_dandt_checked = false;
  is_proceedEnabled = true;
  isCheckoutDisabled = true;
  address_temp: any[];
  addressModeStatus: string;
  tag_log = 'false'
  secretKey = this.configs.cfg.prdt_key.prdt;

  ngOnInit(): void {
    if (localStorage.getItem('dbuy_tag') == 'false') {
      this.router.navigate(['/']);
    }
    console.log(localStorage.getItem('tag_load'))
    if (this.tag_log == localStorage.getItem('tag_load')) {
      localStorage.setItem('tag_load', 'true')
      console.log(localStorage.getItem('tag_load'))
      location.reload();

    }
    // 
    this.getExistingAddr();

  }

  showAddrLineTwo(addrLine2) {
    // console.log("show called")
    if (addrLine2.length === 0)
      return false
    else return true;

  }


  async addAddress(addr: AddAddressFormData) {
    console.log(addr);
    let temp = this.request.get_structure('address_add', 'format');
    temp.address_id = 0;
    temp.address_name = addr.name;
    temp.address1 = addr.addr1;
    temp.address2 = addr.addr2;
    temp.country = addr.country;
    temp.region = addr.state;
    temp.city = addr.city;
    temp.phone_no = parseInt(addr.mobile);
    temp.postal_code = addr.zip;

    let address_add_info = await this.request.post(1, 'address_add', temp);
    console.log(address_add_info);
    if(address_add_info[0] == 200){
      console.log('calledfogvnidvi')
      this.is_dandt_checked = false;
    }
    this.getExistingAddr();
    this.onAddaddress();
  }

  addressModeStatus_is_true = false;
  async getExistingAddr() {
    var temp = this.request.get_structure('address_info', 'format');
    temp.product_id = 2;
    temp.order_status = "pending";
    var get_address = await this.request.post(1, 'address_info', temp);
   
    if (get_address[1].response.data.length > 0) {
      this.addressList = get_address[1].response.data;
      this.noAddress = false;
      console.log(this.addressList);
      this.address_temp = get_address[1]['response']['data']
      
      var add_id = this.addressList;

      for(let i of add_id){
       
        if(i.is_main == true){
          console.log(i.address_id,"MMMMMMMmmm")
          this.addressModeStatus_is_true = i.address_id;
          this.addressModeStatus = i.address_id;
        }
      }
      // for(let i of add_id){
       
      //   if(i.is_main == true){
      //     console.log(i.address_id,"MMMMMMMmmm")
      //     this.addressModeStatus = i.address_id;
      //     this.isCheckoutDisabled = false;
      //     this.is_dandt_checked = false;
      //   }else if(i.is_main == false){
      //     this.is_dandt_checked = true;
      //   }
        
      // }

      this.address_temp.forEach((element, index) => {
        if (element.is_main == true) {
          this.isCheckoutDisabled = true;
          this.is_dandt_checked = false
        }
      });

     
      if (this.addressModeStatus == undefined) {
        this.is_disabled = true;
      } else {
        this.is_disabled = false;
      }
      // }
      // UPDATE SOON...!
      // for (let f1 in this.address_temp) {
      //   if (this.address_temp[f1].is_main == true) {
      //   this.addressModeStatus = this.address_temp[f1].address_id;
      //   }
      // }
    } else {
      this.noAddress = true;
      this.isCheckoutDisabled = true;
      this.is_dandt_checked = true;
    }
  }

  find_address(id) {
    for (var f1 in this.address_temp) {
      if (this.address_temp[f1]['address_id'] == id) {
        return this.address_temp[f1]
      }
    }
  }

  async addressChange(id) {
 
    var value = this.find_address(id)
    console.log(value);
    this.service.your_location_city(value.city)
    var temp = this.request.get_structure('address_default', 'format');
    temp.product_id = 2;
    temp.address_id = parseInt(value.address_id);
    var get_address = await this.request.post(1, 'address_default', temp);
    console.log(get_address);
    this.is_invalid = false;
    this.is_disabled = false;
    this.is_proceedEnabled = true;
    this.is_dandt_checked=true;
    this.getExistingAddr()
    // this.getshippingCost(value);
  }

  async getshippingCost(value) {

    // console.log(value);
    let temp = this.request.get_structure('check_shipment_cost', 'format');
    temp.country = value.country;
    temp.postcode = value.postal_code;
    temp.product_id_fs = 0;
    temp.variant_id = 0;
    temp.qty = this.userStorage.getUserInfo('buycart_count');
    console.log(temp.qty);
    let shippmentCost = await this.request.post(1, 'check_shipment_cost', temp);
    console.log(shippmentCost);
    if (shippmentCost[0] == 200) {
      this.shippmentCost = shippmentCost[1].response.data.price;
      console.log(this.shippmentCost);
      this.userStorage.updateUserInfo('sc', this.shippmentCost);
    } else if (shippmentCost[0] == 400) {
      console.log("shippmentCostError");
    }
  }

  onAddaddress() {
    this.AddressAddBtn = !this.AddressAddBtn;
  }

  temp_moed :any
  check_box = true;

  async proceedtocheckout(data_model) {
    try {
      console.log("pass_22")
      let address_id = 0;
      this.pending = true;
      console.log("+++++++++")
      var temp = this.request.get_structure('direct_buy', 'format');
      // temp.product_id = 2;
      console.log('-->>>>', localStorage.getItem('direct_data'));
      // if(this.configs.cfg.env_mode.default == 'development'){
      var direct_data = JSON.parse(localStorage.getItem('direct_data'));
      // }else if(this.configs.cfg.env_mode.default == 'production'){
      //   var userdata = localStorage.getItem('direct_data');
      //   var parsed_data = JSON.parse(userdata);
      //   let bytes = CryptoJS.AES.decrypt(parsed_data, this.secretKey)
      //   var direct_data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      //   console.log(direct_data);
      // }
      // var direct_data = JSON.parse(localStorage.getItem('direct_data'));
      temp.address_id = this.addressModeStatus;
      temp.qty = direct_data.count;
      temp.overall_total = direct_data.price;//this.product.products.price * this.counter;
      temp.unique_product_id = direct_data.upid;
      temp.pproduct_id = direct_data.pid;

      console.log(temp);
      let direct_buy = await this.request.post(1, 'direct_buy', temp);
      console.log(direct_buy);
      if (direct_buy[0] == 200) {
        if(direct_buy[1].response.data.shipment_cost == 0){
          this.temp_moed  = ''
          this.temp_moed = data_model 
          this.modalService.open(data_model,{centered: true, size: 'mg', backdrop: 'static', keyboard: false })   
          return;
        }else{

        
        direct_data["sc"] = direct_buy[1].response.data.shipment_cost;
        // if(this.configs.cfg.env_mode.default == 'development'){
        localStorage.setItem('direct_data', JSON.stringify(direct_data));
        // }else if(this.configs.cfg.env_mode.default == 'production'){
        //   let encryptedData = CryptoJS.AES.encrypt(JSON.stringify(direct_data), this.secretKey).toString();
        //   localStorage.setItem('direct_data', JSON.stringify(encryptedData));
        // }
        // localStorage.setItem('direct_data', JSON.stringify(direct_data));
        this.userStorage.updateUserInfo('tid', direct_buy[1].response.data.order_id);
        this.router.navigate(['/payment']);
        console.log("+++++++++")
        this.pending = false;
      }
      }
      else {
        this.is_validdirect = true;
      }
    } catch (error) {
      console.log("temp_error",error);

    }
    // }
  }

  async check_direct(address_id) {


  }

  off_pending(close){
    this.pending = false;
    this.is_validdirect = true;
    this.modalService.dismissAll(close)
    setTimeout(() => {
      this.is_validdirect = false;
    }, 2000);
    console.log('**********')
  }
  open(tandcpayment)
  {
    this.modalService.open(tandcpayment, {ariaLabelledBy: 'modal-basic-title'})
  }
   
  dandt(event){
    console.log(event.target.checked )

    if(event.target.checked == true){
      if(this.isCheckoutDisabled == false)
      this.is_dandt_checked = true;
      this.is_proceedEnabled = false
    }else if(event.target.checked == false){
      this.is_dandt_checked = false;
      this.is_proceedEnabled = true;
    }
    }
   
    ngOnDestroy(){
      this.modalService.dismissAll(this.temp_moed)
    }

}
