<div class="container">
    <div class="row">
        <div class="col-md-2 adminSidebar" style="margin-top: 1%; height: 600px;">
            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <!-- <a class="nav-link active dashboadrbtn" align="center" id="v-pills-pendingSellers-tab" data-toggle="pill" href="#v-pills-pendingSellers" role="tab" aria-controls="v-pills-pendingSellers" (click)="pendingSellersRefresh()" aria-selected="true">{{this.language.mLparams[lang_dync].pendingSeller}}</a> -->
                <a class="nav-link dashboadrbtn" align="center" id="v-pills-pendingProducts-tab" data-toggle="pill"
                    href="#v-pills-pendingProducts" role="tab" aria-controls="v-pills-pendingProducts"
                    (click)="pendingProductRefresh()"
                    aria-selected="false">{{this.language.mLparams[lang_dync].pendingProduct}}</a>
                <a class="nav-link active  dashboadrbtn" align="center" id="v-pills-allOrders-tab" data-toggle="pill"
                    href="#v-pills-allOrders" role="tab" aria-controls="v-pills-allOrders" (click)="allOrdersrefresh()"
                    aria-selected="false">{{this.language.mLparams[lang_dync].allOrders}}</a>
                <!-- <a class="nav-link dashboadrbtn" align="center" id="v-pills-allSellers-tab" data-toggle="pill" href="#v-pills-allSellers" role="tab" aria-controls="v-pills-allSellers" (click)="allSellersRefresh()" aria-selected="false">{{this.language.mLparams[lang_dync].allSellers}}</a> -->
                <a class="nav-link dashboadrbtn" align="center" id="v-pills-allProducts-tab" data-toggle="pill"
                    href="#v-pills-allProducts" role="tab" aria-controls="v-pills-allProducts"
                    (click)="allProductsrefresh()"
                    aria-selected="false">{{this.language.mLparams[lang_dync].allProducts}}</a>
                <a class="nav-link dashboadrbtn" align="center" id="v-pills-allBuyers-tab" data-toggle="pill"
                    href="#v-pills-allBuyers" role="tab" aria-controls="v-pills-allBuyers" (click)="allBuyersrefresh()"
                    aria-selected="false">{{this.language.mLparams[lang_dync].allBuyers}}</a>
                <a class="nav-link dashboadrbtn" align="center" id="v-pills-allBuyers-tab" data-toggle="pill"
                    href="#v-pills-sellerOrders" role="tab" aria-controls="v-pills-sellerOrders"
                    (click)="sellerGetOrders()"
                    aria-selected="false">{{this.language.mLparams[lang_dync].yourOrders}}</a>
                <a class="nav-link dashboadrbtn" align="center" id="v-pills-allBuyers-tab" data-toggle="pill"
                    href="#v-pills-sellerProducts" role="tab" aria-controls="v-pills-sellerProducts"
                    (click)="sellerProducts()"
                    aria-selected="false">{{this.language.mLparams[lang_dync].yourProducts}}</a>
                <a class="nav-link dashboadrbtn" align="center" id="v-pills-allBuyers-tab" data-toggle="pill"
                    href="#v-pills-manageWarehouse" role="tab" aria-controls="v-pills-manageWarehouse"
                    (click)="onClickManageWarehouse('wareHouse')"
                    aria-selected="false">{{this.language.mLparams[lang_dync].manageWareHouse.manageWarehouse}}</a>
                <a class="nav-link dashboadrbtn" align="center" id="v-pills-allBuyers-tab" data-toggle="pill"
                    href="#v-pills-manageCategory" role="tab" aria-controls="v-pills-manageWarehouse"
                    (click)="onClickManageCategory('showcat')" aria-selected="false">Add product category name</a>


                <a class="nav-link dashboadrbtn" align="center" id="v-pills-queries-tab" data-toggle="pill"
                    href="#v-pills-queries" role="tab" aria-controls="v-pills-queries" (click)="onClickqueries()"
                    aria-selected="false">{{this.language.mLparams[lang_dync].queries}}</a>
            </div>
        </div>
        <div class="col-md-10" >
            <!-- 1 -->
            <div class="tab-content" id="v-pills-tabContent">
                <div class="tab-pane fade table-responsive" id="v-pills-pendingSellers" role="tabpanel"
                    aria-labelledby="v-pills-pendingSellers-tab">
                    <table class="table table-striped">
                        <thead style="font-size: 14px;">
                            <tr>
                                <th scope="col">{{this.language.mLparams[lang_dync].sellerId}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].sellerName}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].sellerEmail}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].appliedOn}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].viewDetails}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].action}}</th>
                            </tr>
                        </thead>
                        <tbody style="font-size: 14px;">
                            <tr *ngFor="let seller of pending_sellers">
                                <th scope="row">{{ seller.user_id }}</th>
                                <td>
                                    {{ seller.user_name }}
                                </td>
                                <td>{{ seller.email_id}}</td>
                                <td>{{ seller.applied_at | date : "medium" }}</td>
                                <td><a data-toggle="modal" data-target=".bd-sellerviewDetailsModal-modal-lg">{{
                                        seller.user_id }}</a></td>
                                <td>
                                    <button class="btn btn-success"
                                        (click)="update_seller(true, seller.application_id)">{{this.language.mLparams[lang_dync].accept}}</button>
                                    <button class="btn btn-danger" type="button" data-toggle="modal"
                                        data-target="#sellerdeclinePopup"
                                        (click)="update_seller(false, seller.application_id)">{{this.language.mLparams[lang_dync].decline}}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                        <!-- <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                        (pageChange)="pendingSellersPage()">
                    </ngb-pagination> -->

                        <!-- <select class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                        (ngModelChange)="pendingSellersRefresh()">
                        <option [ngValue]="2">2 items per page</option>
                        <option [ngValue]="4">4 items per page</option>
                        <option [ngValue]="6">6 items per page</option>
                    </select> -->
                    </div>
                    <!-- <angular2csv [data]="data" filename="test.csv" [options]="options"></angular2csv> -->
                </div>
                <!-- 1 -->
                <!-- 2 -->
                <div class="tab-pane fade table-responsive" id="v-pills-pendingProducts" role="tabpanel"
                    aria-labelledby="v-pills-pendingProducts-tab">
                    <div *ngIf="addProdSuccss" class="row no-gutters">
                        <h4 class="col no-gutters text-success">{{this.language.mLparams[lang_dync].addPrdSuccess}}</h4>
                    </div>
                    <div *ngIf="emptyPendingProducts" class="row no-gutters">
                        <h4 class="col no-gutters">{{this.language.mLparams[lang_dync].noPendingProduct}}</h4>
                    </div>
                    <table *ngIf="!emptyPendingProducts" class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{{this.language.mLparams[lang_dync].productId}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].productName}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].sellerEmail}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].appliedOn}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].viewDetails}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].action}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let product of pendingProducts">
                                <th scope="row">{{ product?.product_id }}</th>
                                <td>
                                    {{ product?.product_name }}
                                </td>
                                <td>{{ product?.email_id}}</td>
                                <td>{{ product?.created_at }}</td>
                                <td><button class="btn btn-primary" data-toggle="modal"
                                        data-target=".bd-ProductviewDetailsModal-modal-lg"
                                        (click)="getproductdetails(product.product_id)">view</button></td>
                                <td>
                                    <button class="btn btn-success"
                                        (click)="update_product(true, product.product_id)">Accept</button>
                                    <button class="btn btn-danger" type="button" data-toggle="modal"
                                        data-target="#ProductdeclinePopup"
                                        (click)="update_product(false, product.product_id)">{{this.language.mLparams[lang_dync].decline}}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="d-flex justify-content-between p-2">
                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                        (pageChange)="pendingProductRefresh()">
                    </ngb-pagination>

                    <select required class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                        (ngModelChange)="pendingProductRefresh()">
                        <option [ngValue]="2">2 items per page</option>
                        <option [ngValue]="4">4 items per page</option>
                        <option [ngValue]="6">6 items per page</option>
                    </select>
                </div> -->
                </div>
                <!-- 2 -->
                <!-- 3 -->
                <div class="tab-pane fade show active table-responsive" id="v-pills-allOrders" role="tabpanel"
                    aria-labelledby="v-pills-messages-tab">
                    <table class="table table-striped">
                        <thead style="font-size: 14px;">
                            <tr>
                                <th scope="col">{{this.language.mLparams[lang_dync].invoiceid}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].dateandtime}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].buyerid}}</th>
                                <!-- <th scope="col">{{this.language.mLparams[lang_dync].buyeremail}}</th> -->
                                <!-- <th scope="col" *ngIf="this.mode.config_mode.three">Invoice(DUC)</th>
                            <th scope="col" *ngIf="!this.mode.config_mode.three">Invoice(USD)</th> -->
                                <th scope="col">{{this.language.mLparams[lang_dync].paymentstatus}}</th>
                                <!-- <th scope="col">{{this.language.mLparams[lang_dync].orderid}}</th> -->
                                <th scope="col">{{this.language.mLparams[lang_dync].prodid}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].prodname}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].sellid}}</th>
                                <!-- <th scope="col">{{this.language.mLparams[lang_dync].sellname}}</th> -->
                                <th scope="col">{{this.language.mLparams[lang_dync].odrvalue}}</th>
                                <!-- <th scope="col" *ngIf="this.mode.config_mode.two">Shipping Cost</th> -->
                                <th scope="col">{{this.language.mLparams[lang_dync].orderStatus}}</th>
                            </tr>
                        </thead>
                        <tbody style="font-size: 14px;">
                            <tr *ngFor="let order of allorders">
                                <th scope="row">{{ order.purchase_uuid }}</th>
                                <td>{{ order.purchase_date | date : "medium" }}</td>
                                <td>{{ order.account_id}}</td>
                                <!-- <td>{{ order.buyer_email }}</td> -->
                                <!-- <td *ngIf="this.mode.config_mode.three">{{ order.invoiceDuc}}</td>
                            <td *ngIf="!this.mode.config_mode.three">{{ order.invoiceUSD }}</td> -->
                                <td>{{ order.payment_status }}</td>
                                <!-- <td>{{ order.purchase_id }}</td> -->
                                <th>{{ order.product_id}}</th>
                                <th>{{ order.product_name}}</th>
                                <th>{{ order.seller_id}}</th>
                                <!-- <th>{{ order.seller_name}}</th> -->
                                <th>{{ order.amount_payed}}</th>
                                <!-- <td *ngIf="!this.mode.config_mode.three">{{ order.shippingCost }}</td> -->
                                <th>{{ order.order_status}}</th>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                            (pageChange)="allOrdersPage()">
                        </ngb-pagination>
                        <!-- <select class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                        (ngModelChange)="allOrdersrefresh()">
                        <option [ngValue]="2">2 items per page</option>
                        <option [ngValue]="4">4 items per page</option>
                        <option [ngValue]="6">6 items per page</option>
                    </select> -->
                        <angular2csv *ngIf="allordercsv" [data]="orders" filename="Allorders"
                            [options]="allorderoptions"></angular2csv>
                    </div>
                </div>
                <!-- 3 -->
                <!-- 4 -->
                <div class="tab-pane fade table-responsive" id="v-pills-allSellers" role="tabpanel"
                    aria-labelledby="v-pills-settings-tab">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{{this.language.mLparams[lang_dync].sellid}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].sellname}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].sellemail}}</th>
                                <!-- <th scope="col">{{this.language.mLparams[lang_dync].aprveon}}</th> -->
                                <th scope="col">{{this.language.mLparams[lang_dync].aprvstatus}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].sendmaillnk}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let seller of sellers">
                                <th scope="row">{{ seller.user_id }}</th>
                                <td>{{ seller.user_name }}</td>
                                <td>{{ seller.email_id}}</td>
                                <!-- <td>{{ seller.approved_at | date : "medium" }}</td> -->
                                <td>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" [(ngModel)]="theCheckbox" data-md-icheck
                                            (change)="toggleSellerstatus()" />
                                    </div>
                                </td>
                                <td>
                                    <button
                                        class="btn btn-primary">{{this.language.mLparams[lang_dync].sndmail}}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="d-flex justify-content-between p-2">
                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                        (pageChange)="pendingProductRefresh()">
                    </ngb-pagination>

                    <select required class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                        (ngModelChange)="pendingProductRefresh()">
                        <option [ngValue]="2">2 items per page</option>
                        <option [ngValue]="4">4 items per page</option>
                        <option [ngValue]="6">6 items per page</option>
                    </select>
                </div> -->

                </div>
                <!-- 4 -->
                <!-- 5 -->
                <div class="tab-pane fade table-responsive" id="v-pills-allProducts" role="tabpanel"
                    aria-labelledby="v-pills-settings-tab">
                    <div class="text-danger" *ngIf="prdDeleteSuccess">Product Deleted Succesfully</div>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{{this.language.mLparams[lang_dync].prodid}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].prodname}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].catgry}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].sellr}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].sellemail}}</th>
                                <th scope="col">
                                    {{this.language.mLparams[lang_dync].pric}}({{this.language.mLparams[lang_dync].dollor}})
                                </th>
                                <th scope="col">{{this.language.mLparams[lang_dync].aprvstatus}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].delete}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let productList of allProductList">
                                <td>{{ productList.product_id }}</td>
                                <td>{{ productList.product_name }}</td>
                                <td>{{ productList.category_name}}</td>
                                <td>{{ productList.user_name}}</td>
                                <td>{{ productList.email_id}}</td>
                                <td>{{ productList.new_price}}</td>
                                <td *ngIf="productList.admin_approved" style="color: rgb(84, 84, 247);">
                                    <!-- <div class="custom-control custom-switch">
                                    <input type="checkbox" [(ngModel)]="theCheckbox" data-md-icheck (change)="toggleproductStatus()" />
                                </div> -->
                                    <b>{{this.language.mLparams[lang_dync].aprvd}}</b>
                                </td>
                                <td *ngIf="!productList.admin_approved" style="color: rgb(224, 114, 41);">

                                    <b>{{this.language.mLparams[lang_dync].pndng}}</b>
                                </td>
                                <td>
                                    <img (click)="productDelete(productList.product_id,deletePrdModal,conformDeleteModal)"
                                        class="addPointer" [src]="'assets/delete.svg'" width="50px" height="20px"
                                        style="cursor: pointer;" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                            (pageChange)="allProductPage()">
                        </ngb-pagination>

                        <!-- <select required class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                        (ngModelChange)="pendingProductRefresh()">
                        <option [ngValue]="2">2 items per page</option>
                        <option [ngValue]="4">4 items per page</option>
                        <option [ngValue]="6">6 items per page</option>
                    </select> -->
                        <angular2csv *ngIf="allproductcsv" [data]="all_products" filename="Allproducts"
                            [options]="allProducts"></angular2csv>
                    </div>
                </div>
                <!-- 5 -->
                <div class="tab-pane fade table-responsive" id="v-pills-allBuyers" role="tabpanel"
                    aria-labelledby="v-pills-settings-tab">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{{this.language.mLparams[lang_dync].buyerid}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].buyrname}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].buyeremail}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].lactive}}</th>
                                <th scope="col" class="text-center">
                                    {{this.language.mLparams[lang_dync].totalpvalue}}({{this.language.mLparams[lang_dync].dollor}})
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let buyer of allBuyerList">
                                <td>{{ buyer.user_id }}</td>
                                <td>{{ buyer.user_name }}</td>
                                <td>{{ buyer.email_id}}</td>
                                <td>{{ buyer.login_time | date : "medium" }}</td>
                                <td class="text-center">{{ buyer.total_purchase}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                            (pageChange)="allBuyersPage()">
                        </ngb-pagination>

                        <!-- <select required class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                        (ngModelChange)="allBuyersPage()">
                        <option [ngValue]="2">2 items per page</option>
                        <option [ngValue]="4">4 items per page</option>
                        <option [ngValue]="6">6 items per page</option>
                    </select> -->
                        <angular2csv *ngIf="allbuyerscsv" [data]="buyers" filename="Allbuyers"
                            [options]="allBuyers"></angular2csv>
                    </div>
                </div>
                <!-- 5 -->
                <!-- 6 -->
                <div class="tab-pane fade table-responsive" id="v-pills-sellerOrders" role="tabpanel"
                    aria-labelledby="v-pills-setting-tab">
                    <div class="alert alert-{{alrtcls}} alert-dismissible fade show" role="alert" *ngIf="orderStatus">
                        <strong>Info!</strong> {{orderStatusMsg}}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="oupBtn()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <!-- <th scope="col">{{this.language.mLparams[lang_dync].orderid}}</th>

                            <th scope="col">{{this.language.mLparams[lang_dync].sellemail}}</th>
                            <th scope="col">{{this.language.mLparams[lang_dync].ordron}}</th>
                            <th scope="col">{{this.language.mLparams[lang_dync].odrvalue}}</th>
                            <th scope="col">{{this.language.mLparams[lang_dync].pymntmthod}}</th>
                            <th scope="col">{{this.language.mLparams[lang_dync].ordrstatus}}</th> -->
                                <th scope="col">{{this.language.mLparams[lang_dync].dte}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].ordtails}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].pyumntstus}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].ordtus}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let orders of sellerOrderData">
                                <!-- <td>{{orders.purchase_id}}</td>
                            <td>{{orders.email_id}}</td>
                            <td>{{orders.purchase_date | date : "medium" }}</td>
                            <td>{{orders.amount_payed}}</td>
                            <td>{{orders.payment_status}}</td> -->
                                <!-- <td>{{orders.order_status}}</td> -->
                                <td>{{orders.purchase_date | date :'MMM d,y, h:mm a'}}</td>
                                <td>
                                    <ul>
                                        <li>{{this.language.mLparams[lang_dync].prodid}}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {{orders.product_id}}
                                        </li>
                                        <li>{{this.language.mLparams[lang_dync].prodname}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                                            {{orders.product_name}}
                                        </li>
                                        <li>{{this.language.mLparams[lang_dync].pqnty}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                                            {{orders.qty}}</li>
                                        <li>{{this.language.mLparams[lang_dync].whousename}}&nbsp; :
                                            {{orders.warehouse_name}}</li>
                                        <!-- <li>Product variants&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {{orders.variants.value || "No variants"}}</li> -->
                                        <li>{{this.language.mLparams[lang_dync].delvadd}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                                            &nbsp;<span
                                                style=" font-size: small;">{{orders.address_data.address_phone}}</span>

                                            <div style="margin-left: 170px; font-size: small;">
                                                {{orders.address_data.address_name}}
                                                <br> {{orders.address_data.address_line1}}
                                                <br> {{orders.address_data.region}} \
                                                {{orders.address_data.country}} \
                                                {{orders.address_data.postal_code}}
                                            </div>
                                            <!-- <ul style="font-size:small ;">
                                            <li>{{orders.address.address_name}}</li>
                                            <li>{{orders.address.address_line1}}</li>
                                            <li>{{orders.address.region}} \ {{orders.address.country}} \ {{orders.address.postal_code}}</li>
                                        </ul> -->
                                            <!-- </li> -->
                                    </ul>
                                </td>
                                <td>{{orders.payment_status | titlecase}}</td>
                                <td>
                                    <span
                                        *ngIf="orders.order_status=='delivered';else sltBlock">{{this.language.mLparams[lang_dync][orders.order_status]}}</span>
                                    <ng-template #sltBlock>
                                        <select class="custom-select"
                                            (change)="updateStatus($event.target.value,orders.purchase_id,orders.product_id,content,orders)"
                                            [disabled]="orders.order_status=='delivered'">
                                            <option class="dropdown-item" value="{{actions}}"
                                                *ngFor="let actions of sellerOrderDataToBeSelected.action"
                                                [selected]="actions==orders.order_status">
                                                {{this.language.mLparams[lang_dync][actions]}}
                                            </option>
                                        </select>
                                    </ng-template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                            (pageChange)="refreshOrders()">
                        </ngb-pagination>

                        <select class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                            (ngModelChange)="refreshOrders()">

                            <option [ngValue]="10">{{this.language.mLparams[lang_dync].itmprpage10}}</option>
                            <option [ngValue]="25">{{this.language.mLparams[lang_dync].itmprpage25}}</option>
                        </select>
                        <angular2csv *ngIf="allsellerorderscsv" [data]="sellerOrderData" filename="Sellerorders"
                            [options]="sellerOrders"></angular2csv>
                    </div>
                </div>
                <!-- 6 -->
                <!-- 7 -->
                <div class="tab-pane fade table-responsive" id="v-pills-sellerProducts" role="tabpanel"
                    aria-labelledby="v-pills-setting-tab">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">{{this.language.mLparams[lang_dync].prodid}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].prodname}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].catgry}}</th>
                                <!-- <th scope="col">{{this.language.mLparams[lang_dync].aprveon}}</th> -->
                                <th scope="col">{{this.language.mLparams[lang_dync].aprvstatus}}</th>
                                <th scope="col">{{this.language.mLparams[lang_dync].invntry}}</th>
                                <!-- <th scope="col">{{this.language.mLparams[lang_dync].avilbleunit}}</th> -->
                                <th scope="col">{{this.language.mLparams[lang_dync].edit}}</th>
                                <!-- <th scope="col">{{this.language.mLparams[lang_dync].lstmodified}}</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prod of sellerProductsData">
                                <td>{{prod.product_id}}</td>
                                <td>{{prod.product_name}}</td>
                                <td>{{prod.category_name}}</td>
                                <!-- <td>{{prod.approved_at}}</td> -->
                                <td>{{prod.admin_approved}}</td>
                                <td>{{prod.warehouse_name}}</td>
                                <!-- <td>{{prod.qty_left}}</td> -->
                                <td class="text-center"><img (click)="editProduct(prod,editprodModal)"
                                        class="addPointer" [src]="'assets/edit-black-18dp.svg'" width="20px"
                                        height="20px" /></td>
                                <!-- <td>{{prod.updated_at}}</td> -->
                            </tr>
                        </tbody>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                            (pageChange)="refreshProducts()">
                        </ngb-pagination>

                        <select class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                            (ngModelChange)="refreshProducts()">

                            <option [ngValue]="10">{{this.language.mLparams[lang_dync].itmprpage10}}</option>
                            <option [ngValue]="25">{{this.language.mLparams[lang_dync].itmprpage25}}</option>
                        </select>
                        <angular2csv *ngIf="allsellerproductcsv" [data]="productsData"
                            filename="Sellerproducts" [options]="sellerProducts1"></angular2csv>
                    </div>
                </div>
                <!-- 8 -->
                <div class="tab-pane fade" id="v-pills-manageWarehouse" role="tabpanel"
                    aria-labelledby="v-pills-setting-tab">
                    <app-manage-warehouse *ngIf="showWareHouse"></app-manage-warehouse>
                </div>
                <!-- 8 -->
                <div class="tab-pane fade" id="v-pills-manageCategory" role="tabpanel"
                    aria-labelledby="v-pills-setting-tab">
                    <app-categories *ngIf="showcat" [add_cat]="add_category"></app-categories>
                </div>
                <!-- 9 -->
                <div class="tab-pane fade" id="v-pills-queries" role="tabpanel" aria-labelledby="v-pills-queries-tab">

                    <br>
                    <div *ngIf="no_faq">
                        <h4> No FAQ </h4>
                    </div>
                    <form [formGroup]="seller_FAQ" *ngIf="!no_faq">
                        
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">{{this.language.mLparams[lang_dync].faq_id}}</th>
                                    <th scope="col">{{this.language.mLparams[lang_dync].buyerName}}</th>
                                    <th scope="col">{{this.language.mLparams[lang_dync].prodid}}</th>
                                    <th scope="col">{{this.language.mLparams[lang_dync].question}}</th>
                                    <th scope="col">{{this.language.mLparams[lang_dync].answer}}</th>
                                    <th scope="col">{{this.language.mLparams[lang_dync].action}}</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let prod of faq_data">
                                    <td>{{prod.faq_id}}</td>
                                    <td>{{prod.buyer_name}}</td>
                                    <td>{{prod.product_id}}</td>
                                    <td>{{prod.question}}</td>
                                    <td><textarea required class="form-Control " cols="25" rows="3"
                                            placeholder="More than 10 Characters needed" [(ngModel)]="prod.answer"
                                            formControlName="seller_ANS"></textarea>
                                        <!-- (keyup)="seller_anqa()" -->
                                    </td>
                                    <!-- [disabled]="submit_qa" -->
                                    <td><button class="btn btn-primary" (click)="submitAnswer(prod)">Submit</button>
                                        <button style="margin-left: 10px;" class="btn btn-danger"
                                            (click)="delete_faq(prod)">Delete</button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </form>
                </div>
                <!-- 9 -->
            </div>
        </div>
    </div>
</div>

<!--seller view details modal  -->

<div class="modal fade bd-sellerviewDetailsModal-modal-lg" tabindex="-1" role="dialog"
    aria-labelledby="sellerviewDetailsModal" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{this.language.mLparams[lang_dync].viewDetails}}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                ...
            </div>
        </div>
    </div>
</div>

<!--seller view details modal end -->


<!-- Seller Decline popup -->
<div class="modal fade" id="sellerdeclinePopup" tabindex="-1" role="dialog" aria-labelledby="sellerdeclinePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    {{this.language.mLparams[lang_dync].reasonforDecline}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="sellerDeclineForm" (ngSubmit)="onSellerDecline()">
                    <label>{{this.language.mLparams[lang_dync].chooseReason}}</label>
                    <select required formControlName="sellerdeclineoption" class="custom-select" name="declineoption"
                        id="inputGroupSelect01">
                        <option selected>{{this.language.mLparams[lang_dync].Choose}}</option>
                        <option value="inappropriateDetails">
                            {{this.language.mLparams[lang_dync].inappropriateDetails}}
                        </option>
                        <option value="photoNotMatching">{{this.language.mLparams[lang_dync].photoNotMatching}}
                        </option>
                        <option value="notProperlyScanned">{{this.language.mLparams[lang_dync].notProperlyScanned}}
                        </option>
                        <option value="others">{{this.language.mLparams[lang_dync].others}}</option>
                    </select>
                    <label>{{this.language.mLparams[lang_dync].comments}}</label>
                    <textarea formControlName="sellerdeclineTextArea" class="form-control"
                        placeholder="{{this.language.mLparams[lang_dync].writeCustomReason}}"
                        name="declineTextArea"></textarea>
                    <button type="submit">{{this.language.mLparams[lang_dync].confirm}}</button>
                </form>
            </div>
        </div>
    </div>
</div>

<!--Seller Decline Popup End -->


<!--Product view details modal  -->

<div class="modal fade bd-ProductviewDetailsModal-modal-lg" tabindex="-1" role="dialog"
    aria-labelledby="ProductviewDetailsModal" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{{this.language.mLparams[lang_dync].viewDetails}}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="margin-top: 20px;">
                <!---single product view-->
                <br>
                <div class="desc-amt" class="col-md-12" style="margin: 10px; text-align: center;">
                    
                    <h5 class="product_description">
                        Product Name &nbsp;&nbsp;{{view_all_details?.product_name | titlecase}}
                    </h5>
                    <h5 class="product_description">
                        Product description &nbsp;:&nbsp;{{view_all_details?.description | titlecase}}
                    </h5>
                    <!-- <h5 class="product_description">
                        Key specification &nbsp;:&nbsp; {{view_all_details?.description | titlecase}}


                    </h5> -->
                </div>
                <div>
                    <div>


                        <div class="col-md-12">
                            <div>
                                <h5 style="text-align: center;"><u>Variants :</u></h5>

                                <div id="myCarousel" class="carousel slide" data-interval="false">

                                    <div *ngFor="let pro of view_all_pro">

                                        <div *ngFor="let pro2 of pro?.images" class="" style="text-align: center;">
                                            <div class="">

                                                <img src="{{server_url}}{{pro2.image_url}}" style="margin-bottom: 30px;"
                                                    class="img-fluid samesize">

                                            </div>

                                        </div>

                                        <div class="">
                                            <div style="text-align: center;">
                                                <h3>Price :{{pro.new_price}}$</h3>
                                                <div *ngFor="let i of pro?.variants">
                                                    <h3 *ngIf="i.variant_type != 'null'">
                                                        {{i.variant_type | titlecase}}&nbsp;:{{i.value}}</h3>
                                                </div>
                                                <BR>
                                                <p *ngIf="pro.notes">Notes : {{pro.notes}}</p>
                                            </div>

                                        </div>

                                        <!-- <hr> -->
                                        <!-- </div> -->


                                        <!-- <div class="carousel-inner" role="listbox">
                                <div class="carousel-item active">
                                <div *ngFor="let pro of view_all_pro">
                                    
                                   
                                    <div class="  col-10" *ngFor="let  view_all_image of view_image">
                                        <div *ngFor="let i of view_all_image">
                                            <img src="{{server_url}}{{i.image_url}}" width="100%" height="150px">

                                        </div>
                                        <br>
                                        <hr>
                                        <div >
                                            <h1>price : {{pro.price}}</h1>
                    
                                        </div>
                                         <ngb-carousel  >
                                          <ng-template >
                                            <h1>{{view_all_image[0].image_url}}</h1>    
                                                <div  *ngFor="let i of view_all_image">       
                                                    <h1>{{i.image_url}}</h1>                               
                                                  <img  class="image" src="{{server_url}}{{i.image_url}}"  alt="Random first slide" width="100%" height="150px">
                                                </div>
                                                <hr>
                                                <br>
                                          </ng-template> -->



                                        <!-- </ngb-carousel> -->
                                        <!-- </div>
                                </div>
                                    
                                </div>
                            </div> -->
                                    </div>
                                    <div class="row">

                                        <!-- <div class="col-sm-12">
                                <div id="thumbCarousel" *ngFor="let item of product?.products.images;let index = index">
                                    <div data-target="#myCarousel" [attr.data-slide-to]="index" class="thumb active">
                                        <img src="{{server_url}}{{item.image_url}}" alt="XZ"
                                            style="width: 50px; height: 50px">
                                    </div>
                                </div>


                            </div> -->

                                    </div>
                                </div>

                            </div>

                        </div>
                        <!-- <div class="col-sm-6">
                    
                          <div class="desc-amt">
                            <p class="product_description">
                              {{product?.products.description }}
                            </p>
                            <div class="dolar">
                              <h3> {{product?.products.product_name | titlecase}} <span> </span></h3>
                              <span class=d-ll>{{product?.products.new_price}} {{this.language.mLparams[lang_dync].dollor}}  </span>
                              <span class=d-l2 *ngIf="get_condition"><s>
                                  {{product?.products.price}} {{this.language.mLparams[lang_dync].dollor}}</s> </span><br>
                            </div>
                    
                    
                    
                    
                            <div class="checkbox">
                              <div class="row quan">
                                <div class="col-sm-4 pd-i">
                                  <input type="checkbox" class="i-p c-b" id="Unchecked" [checked]="product?.products.qty_left"
                                    [disabled]="true">&nbsp;&nbsp;
                                  <p *ngIf="product?.products.qty_left">{{this.language.mLparams[this.lang_dync].instock}}</p>
                                  <div *ngIf="!product?.products.qty_left">
                                    <p class="text-danger" *ngIf="!out_of_stack">
                                      <b>OUT OF STOCK</b></p>
                                    <div class="spinner-border" role="status" *ngIf="out_of_stack">
                                      <span class="visually-hidden"></span>
                                    </div>
                    
                                  </div>
                                </div>
                              </div>
                            </div>
                    
                            <div *ngIf="tag == 1">
                              <div *ngFor="let item of all_variants | keyvalue">
                                <div class="set" *ngIf="all_variants[item.key]">
                                  <span>{{item.key | titlecase}}:</span>
                                  <ul class="size-list" *ngFor="let vari of all_variants[item.key];">
                                    <li><button class="set_tag_{{item.key }} btn" id="{{item.key}}_{{vari}}"
                                        [ngClass]="checkFilter(item.key,vari)" (click)="updateVariant(item.key,vari)">{{vari }}</button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <p *ngIf="!product?.products.qty_left" class=""><b>{{this.language.mLparams[this.lang_dync].note}}</b> {{this.language.mLparams[this.lang_dync].choseodrvrint}}</p>
                    
                            <ngb-rating [max]="5" [(rate)]="currentRate" [readonly]="true"></ngb-rating>
                            <div class=" quantity">
                              <div class="col-12">
                    
                                <div class="row">
                                  <div class="col-6" style="margin-left: -20px">
                    
                                    <div class="q-wb">
                                      <h6 class="qty">{{this.language.mLparams[this.lang_dync].quantity}}</h6>&nbsp;&nbsp;
                                   
                                      <img src="assets/Dbay Remove.svg" alt="" class="" (click)="decrement()" width="25px" height="25px"
                                        style="margin: 5px; cursor: pointer;">
                                      <span>{{counter}}</span>
                                   
                                      <img src="assets/Dbay Add.svg" alt="" class="" (click)="increment()" width="25px" height="25px"
                                        style="margin: 5px; cursor: pointer;">
                                    </div>
                    
                                  </div>
                             
                                  <div class="col-6">
                    
                                    <button class="btn contactsell" (click)="open()" style="margin-left: 20px">{{CONTACTSELLER}}</button>
                                  </div>
                    
                                </div>
                              </div>
                            </div>
                    
                            <br>
                    
                            <div class="row buttons">
                              <div class="col-12">
                                <div class="row">
                                  <div class="col-6">
                                    <button class="btn  ac" (click)="buyNow(product.product_id)" [disabled]='is_disable'>{{this.language.mLparams[this.lang_dync].bynow}}</button>
                                  </div>
                                  <div class="col-6">
                                    <button class="btn  btn-lg bn" (click)="addToCart(product)" [disabled]='is_disable'>{{this.language.mLparams[this.lang_dync].add2crt}}</button>
                                  </div>
                                  <div class="col-12 note" style="margin-top: 25px;" *ngIf="notes">
                    
                                    <p><b>{{this.language.mLparams[this.lang_dync].ppline24}}  </b>    {{notes}}</p>
                    
                                  </div>

                                </div>
                    
                              </div>
                    
                    
                            </div>
                        -->
                    </div>
                    <br>
                    <br>
                    <br>
                    <br>
                    <!-- <div class="row sucesscart"> -->
                    <ngb-toast [delay]="1500">
                        <div style="text-align:center;">{{this.language.mLparams[this.lang_dync].ppline35}}</div>
                    </ngb-toast>
                </div>

            </div>
        </div>
    </div>
</div>

<!--Product view details modal end -->


<!-- Product Decline popup -->

<div class="modal fade" id="ProductdeclinePopup" tabindex="-1" role="dialog" aria-labelledby="ProductdeclinePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    {{this.language.mLparams[lang_dync].reasonforDecline}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="ProductdeclinePopup">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="productDeclineForm" (ngSubmit)="onProductDeclineForm()">
                    <label>{{this.language.mLparams[lang_dync].chooseReason}}</label>
                    <select required formControlName="productdeclineoption" class="custom-select" name="declineoption"
                        id="inputGroupSelect02">
                        <option value={{this.language.mLparams[lang_dync].productPhotoDoesNotMeetStandards}}>
                            {{this.language.mLparams[lang_dync].productPhotoDoesNotMeetStandards}}
                        </option>
                        <option value={{this.language.mLparams[lang_dync].notEnoughProductInformation}}>
                            {{this.language.mLparams[lang_dync].notEnoughProductInformation}}</option>
                        <option value={{this.language.mLparams[lang_dync].inAppropriateCategory}}>
                            {{this.language.mLparams[lang_dync].inAppropriateCategory}}
                        </option>
                        <option value="others">{{this.language.mLparams[lang_dync].others}}</option>
                    </select>
                    <label>{{this.language.mLparams[lang_dync].comments}}</label>
                    <textarea formControlName="productdeclineTextArea" class="form-control"
                        placeholder="{{this.language.mLparams[lang_dync].writeCustomReason}}"
                        name="declineTextArea"></textarea>
                    <button class="btn btn-primary float-right"
                        type="submit">{{this.language.mLparams[lang_dync].confirm}}</button>
                </form>
            </div>
        </div>
    </div>
</div>

<!--Product Decline Popup End -->


<!-- shipping Form -->

<!-- <div class="modal fade" id="statusModal" tabindex="-1" role="dialog" aria-labelledby="statusModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                    {{this.language.mLparams[lang_dync].orderStatus}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="confirmModalClose" (click)="statusModalClose()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="shippingDetails">
                <form [formGroup]="shippingForm" (ngSubmit)="updateStatusMyOrder()">
                    <div class="form-group">
                        <label>Service Name</label>
                        <input required formControlName="serviceName" class="form-control" name="servicename" [ngClass]="{ 'is-invalid': logsubmitted && urls.serviceName.errors }" autofocus>
                        <div *ngIf="logsubmitted && urls.serviceName.errors">
                            <div *ngIf="urls.serviceName.errors.required" class="text-danger">
                                Service Name is Required
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Service Id</label>
                        <input required formControlName="serviceId" class="form-control" name="serviceid" [ngClass]="{ 'is-invalid': logsubmitted && urls.serviceId.errors }">
                        <div *ngIf="logsubmitted && urls.serviceId.errors">
                            <div *ngIf="urls.serviceId.errors.required" class="text-danger">
                                Service Id is Required
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Track URL</label>
                        <input required formControlName="url" class="form-control" name="url" [ngClass]="{ 'is-invalid': logsubmitted && urls.url.errors }">
                        <div *ngIf="logsubmitted && urls.url.errors">
                            <div *ngIf="urls.url.errors.required" class="text-danger">
                                URL is Required
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary float-right" (click)="updateStatusMyOrder()">Confirm</button>
                </form>
            </div>
            <div class="modal-body" *ngIf="!shippingDetails">
                Click Confirm to change status <b>{{status}}</b>
                <button type="submit" class="btn btn-primary float-right" (click)="updatestatus()">Confirm</button>
            </div>
        </div>
    </div>
</div> -->


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{this.language.mLparams[lang_dync].proupdt}}</h4>
        <a type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')" id="confirmModalClose"
            (click)="reset()">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body" *ngIf="shippingDetails">
        <form [formGroup]="shippingForm" (ngSubmit)="updateStatusMyOrder()">
            <div class="form-group">
                <!-- <label>Service Name</label>
                <input required formControlName="serviceName" class="form-control" name="servicename" [ngClass]="{ 'is-invalid': logsubmitted && urls.serviceName.errors }" autofocus>
                <div *ngIf="logsubmitted && urls.serviceName.errors">
                    <div *ngIf="urls.serviceName.errors.required" class="text-danger">
                        Service Name is Required
                    </div>
                </div> -->
                <div>
                    <select required class="custom-select" name="declineoption" id="inputGroupSelect01"
                        (change)="Select_Partner($event)">

                        <option selected value=",">{{this.language.mLparams[lang_dync].Choose}}</option>
                        <option *ngFor="let order of group.logistic_partners"
                            value="{{order.tracking_url}},{{order.logistic_partner_name}}">

                            {{order.logistic_partner_name}}
                        </option>

                    </select>
                    <div *ngIf="logsubmitted && !logistic_partner">
                        <div *ngIf="!logistic_partner" class="text-danger">
                            {{this.language.mLparams[lang_dync].lpartnerreq}}
                        </div>
                    </div>



                </div>
            </div>
            <div class="form-group">
                <label>{{this.language.mLparams[lang_dync].trkngurl}}</label>
                <input required class="form-control" name="url" [(ngModel)]="tracking_url" formControlName="url">
                <!-- <div *ngIf="logsubmitted && urls.url.errors">
                    <div *ngIf="urls.url.errors.required" class="text-danger">
                        Service Id is Required
                    </div>
                </div> -->
                <div *ngIf="logsubmitted && urls.url.errors">
                    <div *ngIf="urls.url.errors.required" class="text-danger">
                        {{this.language.mLparams[lang_dync].urlreq}}
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Tracking Id</label>
                <input required formControlName="serviceId" class="form-control" name="serviceid">

                <div *ngIf="logsubmitted && urls.serviceId.errors">
                    <div *ngIf="urls.serviceId.errors.required" class="text-danger">
                        {{this.language.mLparams[lang_dync].serviceidreq}}
                    </div>
                </div>
            </div>
            <!-- <div class="form-group">
                <label>Track URL</label>
                <input required formControlName="url" class="form-control" name="url" [ngClass]="{ 'is-invalid': logsubmitted && urls.url.errors }">
                <div *ngIf="logsubmitted && urls.url.errors">
                    <div *ngIf="urls.url.errors.required" class="text-danger">
                        URL is Required
                    </div>
                </div>
            </div> -->
            <button type="submit" class="btn btn-primary float-right"
                (click)="updateStatusMyOrder()">{{this.language.mLparams[lang_dync].confirm}}</button>
        </form>
    </div>
    <div class="modal-body" *ngIf="!shippingDetails">
        {{this.language.mLparams[lang_dync].clickcnfchngest}} <b>{{status}}</b>
        <button type="submit" class="btn btn-primary float-right" (click)="modal.dismiss('Cross click')"
            (click)="updatestatus()">{{this.language.mLparams[lang_dync].confirm}}</button>
    </div>
</ng-template>
<!-- <div style="margin-bottom: 200px;"></div> -->


<ng-template #deletePrdModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <h6>Cart count: {{count}}</h6>
                <h6>Quantity Left: {{qty}}</h6>
                <div class="listTable">
                    <table class=" table table-striped ">
                        <thead>
                            <tr>
                                <th>Purchase ID</th>
                                <th>Order Status</th>
                                <th>Quantity</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prd of purchase_data">
                                <td>{{prd.purchase_id}}</td>
                                <td>{{prd.order_status}}</td>
                                <td>{{prd.qty}}</td>
                                <td>{{prd.total}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="text-danger" *ngIf="prdDeleteSuccess">Product Deleted Succesfully</div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger" (click)="forcedelete()">Force Delete</button>
    </div>
</ng-template>

<ng-template #conformDeleteModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Product Delete</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <p>Are you sure you want to <b>Delete</b> the selected Product?</p>
            </div>
        </div>
        <div class="text-danger" *ngIf="prdDeleteSuccess">Product Deleted Succesfully</div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary float-left" (click)="modal.dismiss('Cross click')" ngbAutofocus>Cancel</button>
        <button class="btn btn-danger" (click)="forcedelete()">Confirm</button>
    </div>
</ng-template>



<ng-template #editprodModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title" ngbAutofocus>Edit Product</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="height: 430px;">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="name-tab" data-toggle="tab" role="tab"
                    aria-controls="name" aria-selected="true" href="#name">Name Edit</a></li>
            <li class="nav-item"><a class="nav-link" id="image-tab" data-toggle="tab" role="tab" aria-controls="image"
                    aria-selected="false" href="#image">Image Edit</a></li>
            <li class="nav-item"><a class="nav-link" id="category-tab" data-toggle="tab" role="tab"
                    aria-controls="category" aria-selected="false" href="#category">Category Edit</a></li>
            <li class="nav-item"><a class="nav-link" id="stock-tab" data-toggle="tab" role="tab" aria-controls="stock"
                    aria-selected="false" href="#stock" (click)="get_qty()">Stock Edit</a></li>
        </ul>
        <div class="tab-content" id="myTabContent" style="margin-top: 1rem;">
            <div class="tab-pane fade show active" id="name" role="tabpanel" aria-labelledby="home-tab">
                <form [formGroup]="editProductForm" (ngSubmit)="onEditsave()">
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <label>Product Name</label>
                            <input type="text" formControlName="product_name" class="form-control" value="productName">
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-6">
                            <label>Product Description</label>
                            <textarea required class="text-area" rows="3" cols="50" formControlName="product_description" class="form-control proddesc"
                                value="productDescription"></textarea>
                        </div>
                    </div>
                   
                    <!-- <div class="form-row">
                <div class="form-group col-lg-4">
                    <label>Product Quantity</label>
                    <input type="text-area" formControlName="quantity_left" class="form-control" value="productQuantity">
                </div>
            </div> -->
                    <h6 class="text-success" *ngIf="editSuccessMsg">Product Edited Succesfully</h6>
                    <button class="btn btn-outline-success" type="submit">Save</button>
                </form>
            </div>
            <div class="tab-pane fade" id="image" role="tabpanel" aria-labelledby="image-tab">
                <!-- image edit temp commented -->
                <br>
                <div class="alert alert-danger" role="alert" *ngIf="file_validatore">
                    {{this.language.mLparams[this.lang_dync].file_validatore}}
                </div>
                <div class="row">
                    

                    <div *ngFor="let varient of edit_image ; index as i" [index]="i">
                        <br>
                        <p style="text-align: center; margin-top: -40px;">Varient {{i + 1}}</p>
                        <div *ngFor="let _img of varient?.images ; index as k" [index]="k">
                            <!-- <img src="{{server_url}}{{_img.image_url}}" style="margin-bottom: 30px;" class="img-fluid samesize"
                        width="100px" height="100px"> -->
                            <div class="col-md-3 image">
                               
                                <label for="productfile1">
                                    <div *ngIf="_img.image_name == true" class="spinner-border text-danger " role="status" >
                                        <span class="sr-only">Loading...</span>
                                    </div> 
                                    
                                    
                                    
                                    <div >
                                        <img src="{{server_url}}{{_img.url}}" (click)="choose2_0(varient.unique_product_id ,_img.url )"
                                            class="rounded border border-muted" alt="Cinque Terre" width="200px" height="200px">
                                    </div>
                                    <br></label>
                                    <input required.change type="file" (change)="choose($event, varient.unique_product_id ,_img.url )" id="productfile1"
                                        value="save" style="display: none;">
                                    <br>
                                    
                                    <!-- <button class="btn btn-success float-right" (click)="image_edit(varient.unique_product_id )">Save all</button> -->
                                    <div>
                                        <button class="btn btn-outline-success btn-sm " (click)="image_edit(varient.unique_product_id )" style="text-align: center;">
                                            <span>Upload</span><span *ngIf="_img.url != _img.old_image_url && uploaded_button">ed</span>
                                        </button>

                                </div>
                                <br>
                                <!-- (click)="choose2_0(_img.url )" -->
                            </div>

                        </div>


                    </div>
                    <div class="col-md-3 image">
                        <!-- <label for="productfile1"><img [src]="url1" class="rounded border border-muted" alt="Cinque Terre"
                        width="100%" height="100px"><br></label>
                <input required.change type="file" id="productfile1" (change)="choose($event)" value="save"
                    style="display: none;"> -->
                    </div>
                    <!-- <div class="col-md-3 image">

                <label for="productfile2"><img [src]="url2" class="rounded border border-muted" alt="Cinque Terre"
                        width="100%" height="100px"><br></label>
                <input type="file" id="productfile2" (change)="choose2($event)" value="save">
            </div> -->
                    <!-- <div class="col-md-3 image">
                    <label for="productfile3"><img [src]="url3" class="rounded border border-muted" alt="Cinque Terre"
                            width="100%" height="100px"><br></label>
                    <input type="file" id="productfile3" (change)="choose3($event)" value="save">
                </div> -->
                    <!-- <div>
            </div> -->
                </div>

                <!-- image edit temp commented -->
            </div>
            <div class="tab-pane fade" id="category" role="tabpanel" aria-labelledby="category-tab">
                <form>
                    <label for='category'>Category</label>
                    <br>
                    <div class="d-flex">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{category_name}}
                            </button>
                            <!-- <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" (click)="cat_data(cat)"
                                    *ngFor="let cat of cat_list">{{cat.category_name}}</a>
                            </div> -->
                            <form>
                                <ul class="dropdown-menu catdropdown multi-level" style="width: max-content;"
                                    role="menu" aria-labelledby="dropdownMenu">
                                    <li class="dropdown-submenu" *ngFor="let items of cat_list">
                                        <!-- |slice:0:8 -->
                                        <button class="dropdown-item catdropdown " tabindex="-1" href="#"
                                            (click)="cat_data(items)"
                                            (mouseenter)="need_cat(items)">{{items.category_name}} <img
                                                *ngIf="items?.array_to_json" class="addPointer  right"
                                                [src]="'assets/nexticon3.svg'" width="20px" height="20px" />
                                            <!-- <img *ngIf="!items?.array_to_json" class="addPointer  right"
                                                [src]="'assets/check_circle-black-18dp.svg'" width="20px"
                                                height="20px" /> -->
                                        </button>

                                        <ul class="dropdown-menu catdropdown" style="width: max-content;"
                                            *ngIf="second_cat">

                                            <li class="dropdown-submenu" *ngFor="let items of second_cat">
                                                <!-- |slice:0:7 -->
                                                <button class="dropdown-item catdropdown dropcatsize" tabindex="-1"
                                                    href="#" (click)="cat_data(items)"
                                                    (mouseenter)="need_cat2(items)">{{items.category_name}}
                                                    <img *ngIf="items?.array_to_json" class="addPointer  right"
                                                        [src]="'assets/nexticon3.svg'" width="20px" height="20px"
                                                        (click)="need_cat2(items)" />
                                                    <!-- <img *ngIf="!items?.array_to_json" class="addPointer  right"
                                                        [src]="'assets/check_circle-black-18dp.svg'" width="20px"
                                                        height="20px" /> -->
                                                </button>
                                                <ul class="dropdown-menu catdropdown" style="width: max-content;"
                                                    *ngIf="second_cat2">
                                                    <li class="dropdown-submenu" *ngFor="let items of second_cat2">
                                                        <!-- |slice:0:6 -->
                                                        <button class="dropdown-item catdropdown dropcatsmallsize"
                                                            tabindex="-1" href="#"
                                                            (click)="cat_data(items)">{{items.category_name}}
                                                            <img *ngIf="items?.array_to_json" class="  right"
                                                                [src]="'assets/nexticon3.svg'" width="20px"
                                                                height="20px" />
                                                            <!-- <img *ngIf="!items?.array_to_json" class="  right"
                                                                [src]="'assets/check_circle-black-18dp.svg'" width="20px"
                                                                height="20px" /> -->
                                                        </button>
                                                    </li>

                                                </ul>
                                            </li>

                                        </ul>
                                    </li>
                                </ul>
                            </form>
                        </div>
                        <button type="submit" class="btn btn-outline-success btn-sm ml-2"
                            (click)="update_cat()">Update</button>
                    </div>
                    <h6 class="text-success" *ngIf="cat_update_success">Category updated Succesfully</h6>
                </form>
            </div>
            <div class="tab-pane fade" id="stock" role="tabpanel" aria-labelledby="stock-tab" style="width: 200px;">
                <div *ngFor="let qty of products_qty; index as k ">
                    <div *ngFor="let varient of qty.variants; index as i ">
                        <div *ngIf="varient.variant_type != 'null' && varient.value != 'null'" class="flex d-flex" style="margin-top: 1rem;">
                            <p>{{varient.variant_type}}</p>&nbsp; :&nbsp; <p>{{varient.value}}</p>
                        </div>
                    </div>
                    <div class="input-group mt-1 mb-2">
                        <input type="number" class="form-control" placeholder="Stock..." aria-label="Stock"
                            aria-describedby="basic-addon2" value="{{qty.qty_left}}" (keyup)="onQtyupdate(qty,$event)">
                        <div class="input-group-append">
                            <button class="btn btn-outline-success" type="button" style="margin-left: 9px;"
                                (click)="update_qty(qty)">update</button>
                        </div>
                    </div>
                </div>
                <div class="text-success" *ngIf="stock_update_success">Stock updated Succesfully</div>
            </div>
        </div>
    </div>

</ng-template>