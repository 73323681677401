import { Injectable } from '@angular/core';

@Injectable()
export class APIs_Config {
    site_id = 2;
    cfg = {

        sign_up1: {
            env: 'SIGN_UP',
            path: 'bov3002/88314',
            format: {
                "username": "",
                "password": "",
                "product_id": this.site_id,
                "sponsor": 0,                  	//default 0
                "account_type": 1,             	//default 1              
                "email": "",
                "country": "null",
                "company_name": "null",        	//default "null"
                "user_type": 4
            },
            required: true
        },

        login_2: {
            env: 'DSHOP_LOGIN',
            path: 'bov3001/98310',
            format: {
                "username": "",
                "password": "",
                "product_id": this.site_id,
            },
            required: true
        },

        bo_login: {
            env: 'BO_LOGIN',
            path: 'bov3001/98315',
            format: {
                "username": "",
                "password": "",
                "product_id": this.site_id,
            }
        },
        login_3: {
            env: 'DBAY_LOGIN',
            path: 'bov3001/98310',
            format: {
                "username": "",
                "password": "",
                "product_id": this.site_id,
            },
            required: true
        },
        login_4: {
            env: 'DMARKET_LOGIN',
            path: 'bov3001/98310',
            format: {
                "username": "",
                "password": "",
                "product_id": this.site_id,
            },
            required: true
        },
        _username: {
            env: 'USERNAME_EXIST',
            path: 'bov3002/88311',
            format: {
                "username": ""
            },
            required: true
        },
        _email: {
            env: 'EMAIL_EXIST',
            path: 'bov3002/88312',
            format: {
                "email": ""
            },
            required: true
        },
        logout: {
            env: 'PATH_LOGOUT',
            path: 'bov3005/58310',
            format: {

                "product_id": this.site_id,
            },
            required: true
        },
        email_activation: {
            env: 'EMAIL_VERIF',
            path: 'bov3002/88316',
            format: {
                "product_id": this.site_id,
                "token": ""
            },
            required: true
        },
        category_list: {
            env: 'lp_get_categories',
            // path: 'bov3004/68319',
            path: 'bov5001/797310',
            format: {
                "product_id": this.site_id,
            },
            required: true
        },
        product_list: {
            env: 'lp_product_list',
            path: 'bov5001/797311',
            format: {
                "product_id": this.site_id,
                // "category_id": 1
            },
            required: true
        },
        document_upload: {
            env: 'DMART_DOCUPLD',
            path: 'bov3006/48313',
            format: {
                "username": "testing",
                "file_name": "",
                "file_base64": "",
                "category_name": "kyc",
                "product_id": this.site_id,
            },
            required: true
        },
        country_api: {
            env: 'COUNTRY_API',
            path: 'bov5001/797314',
            format: {
                "product_id": this.site_id,
                "option": 2,
                "find": "null"
            },
            required: true
        },

        forget_password: {
            env: 'FORGOT_PASSWORD',
            path: 'bov3001/98311',
            format: {
                "product_id": this.site_id,
                "email": "email"
            },
            required: true
        },
        send_otp: {
            env: 'SEND_OTP',
            path: 'bov3001/98312',
            format: {
                "product_id": this.site_id,
                "email": "email",
                "otp": ""
            },
            required: true
        },
        reset_pwd: {
            env: 'RESET_PASSWORD',
            path: 'bov3001/98313',
            format: {
                "product_id": this.site_id,
                "email": "email",
                "otp": "",
                "new_password": ""
            },
            required: true
        },

        get_seller: {
            env: 'DMART_DOCUPLD',
            path: 'bov5000/597310',
            format: {
                "product_id": this.site_id,
            },
            required: true
        },
        update_seller: {
            env: 'DMART_DOCUPLD',
            path: 'bov5000/597311',
            format: {
                "product_id": this.site_id,
                "application_id": 0,
                "admin_action": true
            },
            required: true
        },
        get_product: {
            env: 'DMART_DOCUPLD',
            path: 'bov5000/597315',
            format: {
                "product_id": this.site_id,
            },
            required: true
        },
        get_product_aplications: {
            env: 'GET_PRODUCT_APPLICANTS',
            path: 'bov5000/597312',
            format: {
                "product_id": this.site_id,
            }
        },
        update_product: {
            env: 'DMART_DOCUPLD',
            path: 'bov5000/597313',
            format: {
                "product_id": this.site_id,
                "application_id": 0,
                "admin_action": true
            },
            required: true
        },
        shell: {
            env: 'DMART_DOCUPLD',
            path: 'bov5000/597313',
            format: {
                "username": "testing",
                "password": "Eg@email.com1",
                "my_query": "select get_country();"
            },
            required: true
        },
        get_buyers: {
            env: 'DMART_DOCUPLD',
            path: 'bov5000/597317',
            format: {
                "product_id": this.site_id,
            },
            required: true
        },
        get_sellers: {
            env: 'DMART_DOCUPLD',
            path: 'bov5000/597316',
            format: {
                "product_id": this.site_id,
            },
            required: true
        },
        get_order: {
            env: 'DMART_DOCUPLD',
            path: 'bov5000/597314',
            format: {
                "product_id": this.site_id,
            },
            required: true
        },
        add_cart: {
            env: 'ADD_CART',
            path: 'bov3004/68311',
            format: {
                "product_id": this.site_id,
                "product_id_fs": 0,
                "variant_id": 0,
                "qty": 0
            },
            required: true
        },
        // direct_buyprocess:{
        //     env: 'ADD_CART',
        //     path: 'bov3004/68318',
        //     format: {
        //         "product_id": this.site_id,
        //         "product_id_fs": 23,
        //         "coupon_code": [
        //             "null"
        //         ],
        //         "overall_total": 74770,

        //     },
        //     required: true
        // },
        cart_option: {
            env: 'CART_OPTION',
            path: 'bov3004/68311',
            format: {
                "product_id": this.site_id,
                "product_id_fs": 0,
                "variant_id": 0,
                "qty": 0
            },
            required: true
        },
        get_category: {
            env: "GET_CATEGORY",
            path: 'bov5001/797310',
            format: {
                "product_id": this.site_id,
            },
            required: true
        },
        my_cart: {
            env: "MY_CART",
            path: 'bov3004/68314',
            format: {
                "product_id": this.site_id,
            },
            required: true
        },


        my_payment: {
            env: "my_payment",
            path: 'bov3004/68314',
            format: {
                "product_id": this.site_id,
            },
            required: true
        },


        bo_process: {
            env: 'BACKOFFICE_PMT',
            path: 'bov3010/697311',
            format:
            {
                "product_id": this.site_id,
                "amount": 0,
            }
        },
        address_add: {
            env: 'ADDRESS_PROCESS',
            path: 'bov3004/68316',
            format:
            {
                "product_id": this.site_id,
                "address_id": 0,
                "address1": "101/,abc",
                "address2": "101/,abc",
                "country": "India",
                "region": "IN",
                "phone_no": 0,
                "city": "erode",
                "postal_code": "623424",
                "delete": false,
            }
        },
        address_edit: {
            env: 'ADDRESS_PROCESS',
            path: 'bov3004/68316',
            format:
            {
                "product_id": this.site_id,
                "address_id": 0,
                "address1": "101/,abc",
                "address2": "101/,abc",
                "country": "India",
                "region": "IN",
                "phone_no": "IN",
                "city": "erode",
                "postal_code": "623424",
                "delete": false,
            }
        },
        remove_update: {
            env: 'BACKOFFICE_PMT',
            path: 'bov3004/68316',
            format:
            {
                "product_id": this.site_id,
                "address_id": 0,
                "address1": "101/,abc",
                "address2": "101/,abc",
                "country": "India",
                "region": "IN",
                "city": "erode",
                "postal_code": "623424",
                "delete": false,
            }
        },
        delete_address: {
            env: 'BACKOFFICE_PMT',
            path: 'bov3004/68316',
            format:
            {
                "product_id": this.site_id,
                "address_id": "null",
                "address1": "101/,abc",
                "address2": "101/,abc",
                "country": "India",
                "region": "IN",
                "city": "erode",
                "postal_code": "623424",
                "delete": true
            }
        },
        update_address: {
            env: 'BACKOFFICE_PMT',
            path: 'bov3004/68316',
            format:
            {
                "product_id": this.site_id,
                "address_id": "null",
                "address1": "101/,abc",
                "address2": "101/,abc",
                "country": "India",
                "region": "IN",
                "city": "erode",
                "postal_code": "623424",
                "delete": false,
            }
        },
        pp_payment: {
            env: 'BACKOFFICE_PMT',
            path: 'bov3010/697310',
            // "trans_id": 7,
            format:
            {
                "product_id": this.site_id,
                "amount": {
                    "total": 5.99,
                    "currency": "USD"
                },
                "order_id": 7,
                "return_url": "http://127.0.0.1:5000/true",
                "cancel_url": "http://127.0.0.1:5000/false"
            }
        },
        pp_status: {
            env: 'PAYPAL_PMT_STS',
            path: 'bov3010/697313',
            format: {
                "product_id": this.site_id,
                "payment_id": "PAYID-L7JCYOA9PW89341BP6597135",
                "token": "EC-9KB55290LM548641A&PayerID=L8TMJELLUXYLJ",
                "payer_id": "L8TMJELLUXYLJ",
                "trans_id": 7
            }
        },
        aaplus: {
            env: 'AAPLUS',
            path: 'bov3010/697314',
            "trans_id": 7,
            format:
            {
                "product_id": this.site_id,
                "instrument": "BTC",
                "amount": 50,
                "trans_id": 7
            }
        },
        address_info: {
            env: 'ADDRESS_INFO',
            path: 'bov5003/9973110',
            format:
            {
                "product_id": this.site_id,
                "order_status": "null"
            }
        },
        address_default: {
            env: 'ADDRESS_INFO',
            path: 'bov3004/68317',
            format:
            {
                "product_id": this.site_id,
                "address_id": 0
            }
        },
        duc_payment: {
            env: 'DUC_PAYMENT',
            path: 'bov3010/697312',
            "order_id": 7,
            format: {
                "product_id": this.site_id,
                "amount": 100
            }
        },
        buyprocess: {
            env: 'BUY_PROCESS',
            path: 'bov3004/683113',
            format: {
                "product_id": this.site_id,
                "coupon_code": [
                    "null"
                ],
                "overall_total": 0,
                "address_id": 1
            }
        },


        direct_buy: {
            env: 'DIRECT_PAYMENT',
            path: 'bov3004/68318',
            format: {
                "product_id": this.site_id,
                "pproduct_id": 0,
                "unique_product_id": 24,
                "qty": 1,
                "coupon_code": [
                    "null"
                ],
                "overall_total": 74770,
                "address_id": 1
            }
        },
        single_page: {
            env: 'SINGLE_PRODUCT',
            path: 'bov5001/797312',
            format: {
                "product_id": this.site_id,
                "product_id_fs": 46,
                "variant_id": 1,
                "log_is":Boolean,
            }
        },

        single_page2: {
            env: 'SINGLE_PRODUCT',
            path: 'bov3003/7832122',
            format: {
                "product_id": this.site_id,
                "product_id_fs": 46,
                "variant_id": 1,
                "log_is":Boolean,
            }
        },
        get_orders: {
            env: 'GET_ORDERDATA',
            path: 'bov3004/683110',
            format: {
                "product_id": this.site_id,
                "order_status": "null",
                "row_limit": 30,
                "off_set": 0
            }
        },
        check_duc_payment: {
            env: 'CHECK_DUC_PAYMENT',
            path: 'bov3010/697315',
            format: {
                "product_id": this.site_id,
                "cart_id": 13213246,
                "trans_id": 7,
            }
        },
        lp_get_search_products: {
            env: 'LP_SEARCH_PRODUCT',
            path: 'bov5001/797315',
            format: {
                "product_id": this.site_id,
            }
        },
        check_duc_exchange_rate: {
            env: 'DUC_EXCHANGE_RATE',
            path: 'bov3010/697316',
            format: {
                "product_id": this.site_id,
            }
        },
        get_profile_data: {
            env: 'GET_PROFILE_DATA',
            path: 'bov3003/78318',
            format: {
                "product_id": this.site_id,
            }
        },
        reset_password: {
            env: 'RESET PASSWORD',
            path: 'bov3003/78316',
            format: {
                "product_id": this.site_id,
                "old_password": "Eg@email.com1",
                "new_password": "Ng@email.com1"
            }

        },

        update_profile_data: {
            env: 'UPDATE_PROFILE_DATA',
            path: 'bov3003/78319',
            format: {
                "product_id": this.site_id,
                "profile_id": 5623,
                "first_name": "test",
                "middle_name": "123",
                "last_name": "456",
                "gender": "male",
                "date_of_birth": "2016-08-27",
                "phone_no": 9988774455,
                "delete": false,
            },
        },
        check_shipment_cost: {
            env: "CHECK_SHIPMENT_COST",
            path: 'bov5002/897311',
            format: {
                "product_id": this.site_id,
                "country": "Singapore",
                "postcode": "049908",
                "product_id_fs": 12,
                "variant_id": 4665,
                "qty": 2
            }
        },
        create_seller: {
            env: "CREATE_SELLER",
            path: 'bov5003/997310',
            format: {
                "product_id": this.site_id,
                "warehouse_id": 0,
                "warehouse_name": "117448",
                "address_line1": "100/,abclsfajksdfjashdkjfahlsdjkfhlasjdh",
                "address_line2": "100/,abc",
                "country": "India",
                "region": "IN",
                "city": "erode",
                "postal_code": "623424",
                "delete": false
            }
        },
        seller_get_order_data: {
            env: "SELLER_GET_ORDER_DATA",
            path: 'bov5002/897313',
            format: {
                "product_id": this.site_id,
            }
        },
        get_warehouse: {
            env: 'GET_WAREHOUSE',
            path: 'bov5003/997311',
            format: {
                product_id: this.site_id,
                warehouse_id: 0,
            },
        },
        add_warehouse: {
            env: 'Add_WAREHOUSE',
            path: 'bov5003/997310',
            format: {
                product_id: this.site_id,
                warehouse_id: 0,
                warehouse_name: '117448',
                address_line1: '100/,abclsfajksdfjashdkjfahlsdjkfhlasjdh',
                address_line2: '100/,abc',
                country: 'India',
                region: 'IN',
                city: 'erode',
                 postal_code: '0',
                shippit_enabled:false,
                delete: false,
            },
        },
        get_product_group: {
            env: 'Get Product Group',
            path: 'bov5003/997316',
            format: {
                product_id: this.site_id,
                warehouse_id: 3,
            },
        },

        get_location_group: {
            env: 'Get Location Group',
            path: 'bov5003/997312',
            format: {
                product_id: this.site_id,
                warehouse_id: this.site_id,
            },
        },
        create_logistic_partner: {
            env: 'Manage Logistics Partners',
            path: 'bov5003/9973111',
            format: {
                product_id: this.site_id,
                logistic_partner_id: 12,
                warehouse_id: 1,
                logistic_partner_name: 'testing123',
                tracking_url: 'http://www.test.com/demo',
                delete: false,
            },
        },

        get_logistic_partner: {
            env: 'Get Logistic Partners',
            path: 'bov5003/9973113',
            format: {
                product_id: this.site_id,
                warehouse_id: 2,
            },
        },
        create_product_group: {
            env: 'Manage Product Groups',
            path: 'bov5003/997313',
            format: {
                "product_id": 2,
                "product_group_id": 0,
                "warehouse_id": 117448,
                "product_group_name": "abc",
                "products_id_list": [1, 2, 3],
                "delete": false
            },
        },
        get_product_data_of_seller: {
            env: 'Manage Product Groups',
            path: 'bov5003/9973112',
            format: {
                product_id: this.site_id,
                seller_id: 0,
            },
        },
        create_location_group: {
            env: 'Create Location Group',
            path: 'bov5003/997314',
            format: {
                product_id: this.site_id,
                location_product_id: 0,
                warehouse_id: 117448,
                location_group_name: 'abc',
                countries_list: [1, 3, 5],
                regions_list: [4, 8, 5],
                delete: false,
            },
        },
        get_payment_method: {
            env: 'GET_PAYMENT_METHOD',
            path: 'bov3011/800002',
            format: {
                product_id: this.site_id,
                referenceId: ""
            },
        },
        get_payment_status: {
            env: 'GET_PAYMENT_STATUS',
            path: 'bov3011/800001',
            format: {
                product_id: this.site_id,
                referenceId: "",
                status: ""
            },
        },
        conform_payment_status: {
            env: 'conform_PAYMENT_STATUS',
            path: 'bov3011/800003',
            format: {
                product_id: this.site_id,
                referenceId: ""
            },
        },
        cancel_payment: {
            env: 'CANCEL_PAYMENT',
            path: 'bov3011/800004',
            format: {
                product_id: this.site_id,
                referenceId: ""
            },
        },
        create_payment: {
            env: 'CREATE_PAYMENT',
            path: 'bov3011/800000',
            format: {
                product_id: this.site_id,
                paymentMethod: "",
                order_id: 0,
                bankCode: "",
                type: "",
                paymentCurrencyCode: "BTC",
                txnNote: ""
            },
        },

        create_paASAS: {
            env: 'CREATE_PAYMENT',
            path: 'bov3011/800046',
            format: {

                paymentMethod: "DUC",
                order_id: 0,
                cost_of_pro: 0,
                type: ""
            },
        },


        create_duc_payment: {
            env: 'CREATE_PAYMENT',
            path: 'bov3011/800047',
            format: {

                product_id: this.site_id,
                paymentMethod: "",
                order_id: 0,
                bankCode: '',
                paymentCurrencyCode: '',
                txnNote: '',
                type: '',
                amount: 0,
                referenceid: ""
            },
        },


        create_duc_payment2_method: {
            env: 'CREATE_PAYMENT',
            path: 'bov3011/800048',
            format: {

                product_id: 0,
                paymentMethod: "",
                order_id: 0,
                bankCode: '',
                paymentCurrencyCode: '',
                txnNote: '',
                type: '',
                amount: 0
            },
        },

        seller_products: {
            env: 'SELLER_PRODUCTS',
            path: 'bov5003/9973112',
            format: {
                product_id: this.site_id,
                seller_id: 1
            },
        },
        seller_update_status: {
            env: 'ORDER_UPDATE',
            path: 'bov5002/897314',
            format: {
                product_id: this.site_id,
                product_id_fs: 0,
                order_id: 0,
                status: "",
                sevice_name: "null",
                sevice_id: "null",
                track_url: "null"
            },
        },
        faq: {
            env: 'FAQ',
            path: 'bov5002/897312',
            format: {
                "faq_id": 0,
                "product_id": this.site_id,
                "body": "text needed",
                "is_delete": false

            }
        },
        submit_faq: {
            env: 'FAQ',
            path: 'bov5002/897312',
            format: {
                faq_id: 0,
                product_id: this.site_id,
                body: '',
                is_delete: false,
            }
        },
        delete_faq: {
            env: 'FAQ',
            path: 'bov5002/897312',
            format: {
                faq_id: 0,
                product_id: this.site_id,
                body: "text needed",
                is_delete: true,
                product_fs: 0
            }
        },
        check_shipment: {
            env: 'CHECK_SHIPMENT',
            path: 'bov5002/897315',
            format: {
                product_id: this.site_id,
                product_id_fs: 3,
                order_id: 3
            }
        },
        product_list2: {
            env: 'product_list',
            // path: 'bov3004/68310',
            path: 'bov5001/797313',
            format: {
                "product_id": this.site_id,
                "category_id": 84
            }
        },
        related_products: {
            env: 'RELATED_PRODUCTS',
            path: 'bov5001/797316',
            format: {
                "product_id": this.site_id,
                "product_id_fs": 2,
                "variant_id": 1
            }
        },

        delete_product_group: {
            env: 'Delete Product Group',
            path: 'bov5003/997313',
            format: {
                product_id: 2,
                product_group_id: 1148,
                warehouse_id: 117448,
                product_group_name: 'abc',
                products_id_list: [1, 2, 3],
                delete: true,
            },
        },
        get_shipping_costs: {
            env: 'get shipping costs',
            path: 'bov5003/997317',
            format: {
                product_id: 2,
                warehouse_id: 3,
            },
        },
        create_shipping_costs: {
            env: 'create shipping costs',
            path: 'bov5003/9973114',
            // format: {
            //     "product_id": 2,
            //     "shipping_cost_id": 1,
            //     "shippment_config": {
            //         "product_group_id": 1,
            //         "location_group_id": 112,
            //         "shippment_cost": 1.23
            //     },
            format: {
                "product_id": 2,
                "shipping_cost_id": 1,
                "shipping_costs": 20
            }
        },
        get_single_order: {
            env: 'GET_SINGLE_ORDERS',
            path: 'bov3004/683111',
            format: {
                "product_id": this.site_id,
                "invoice_id": 654654
            }
        },
        add_multi_product: {
            env: 'ADD MULTI PRODUCT',
            path: 'bov5003/9973115',
            format: {
                "proudcts": {
                    "product_id_fs": 0,
                    "product_id": 2,
                    "category_id": 1,
                    "seller_id": 118099,
                    "product_group_id": 1,
                    "product_name": "test",
                    "discription": "test",
                    "bullet_pts": [
                        "t1",
                        "t2",
                        "t3"
                    ],
                    "is_used": true,
                    "weight": 1,
                    "admin_approved": false,
                    "delete": false,
                    "variants": [
                        {
                            "unique_product_id": null,
                            "limited_stock": false,
                            "qty_left": 10,
                            "price": 1.5,
                            "notes": '',
                            "images": [
                                {
                                    "url": "string",
                                    "is_main": false,
                                    "old_image_url": null,
                                }
                            ],
                            "variants": [
                                {
                                    "variant_type": "string",
                                    "value": "string"
                                }
                            ],
                            "offer_type": "none",
                            "offer_val": 50,
                            "offer_begin": "05/12/2020",
                            "offer_end": "06/12/2020",
                            "edit": false
                        }

                    ]
                }
            }
        },

        file_upload: {
            env: 'File_Upload',
            path: 'bov3006/48310',
            format: {
                "product_name": "demo1",
                "file_name": "demo1232.jpg",
                "image_name": "side view",
                "is_main": true,
                "file_base64": ""
            },

        },


        add_category: {
            env: 'File_Upload',
            path: 'bov4000/497314',
            format: {
                "system_product_id": this.site_id,
                "master_category_id": 3,
                "category_name": "character varying",
                "image_url": "aa",
            },

        },
        get_faq: {
            env: 'GET_FAQ',
            path: 'bov4000/497315',
            format: {
                "product_id": this.site_id,
            }
        },
        add_review: {
            env: "ADD_REVIEW",
            path: 'bov3003/78320',
            format: {
                "review_id": 0,
                "product_id": 0,
                "body": "",
                "stars": 1,
                "is_delete": false,
            }
        },
        get_voucher: {
            env: 'GET_VOUCHER',
            path: 'bov3012/66888',
            format: {
                "act_key": "987456231",
            }
        },
        product_delete: {
            env: "DELETE_PRODUCT",
            path: 'bov5000/597321',
            format: {
                "product_id": 0,
                "is_delete": false,
            }
        },
        product_edit: {
            env: "EDIT_PRODUCT",
            path: 'bov5003/9973120',
            format: {
                "product_id": 0,
                "product_name": "",
                "discription": "",
            }
        },
        product_variant_edit: {
            env: "EDIT_PRODUCT",
            path: 'bov5003/9973121',
            format: {
                "product_id": this.site_id,
            }
        },


        available_cart_details: {
            env: "DELETE_PRODUCT",
            path: 'bov3003/78321',
            format: {
                "product_id": this.site_id,
                "cart__id": '',
                "address__id": '',
            }
        },
        view_details_admin: {
            env: "ADMIN_VIEW",
            path: 'bov5000/597318',
            format: {
                "product_id": 0,
            }
        },
        update_category: {
            env: "UPDATE_CATEGORY",
            path: 'bov5003/9973122',
            format: {
                "product_id": 0,
                "category_id": 0,
            }
        },
        product_deline: {
            env: "PRODUCT_DECLINE",
            path: 'bov5000/597322',
            format: {
                "product_id": this.site_id,
                "username": "",
                "product_name": "",
                "reason": "",
                "comments": "",
                "email_id": "",
            }
        },
        get_set_product_qty: {
            env: "GET_SET_PRODUCT_QTY",
            path: "bov4000/497316",
            format: {
                product_id: 0,
                qty: 0,
            }
        },
        product_edit_fun: {
            enc: "PRODUCT_EDIT_FUN",
            path: 'bov5003/9973123',
            format: {
                "product_id": 0,
                "old_image_url": '',
                "image_url": '',
            }
        },

        quantum_credit_card: {
            enc: "PRODUCT_EDIT_FUN",
            path: 'hooks/quantum-credit-card',
            format: {
                "product_id": 0,
                "type": 'Charge',
                "data": {'id':'1e556e799e967de1509342325fd8a0fd'},
            }
        }
    }
}